import PropTypes from 'prop-types'
import { useContext, useRef, useState, useEffect } from 'react'
import { UserContext } from 'contexts/UserContext'
import { MdOutlineUploadFile } from 'react-icons/md'
import { Formik, Form, Field } from 'formik'
import Select from 'react-select'

const Maintenance = ({
  handleSubmit,
  handleChange,
  fields,
  users,
  uploadDocument,
  renderTableData,
  documentsLength,
  isNew,
  machines,
  mtList
}) => {
  const { user } = useContext(UserContext)
  const hiddenFileInput = useRef(null)

  // Sol·licitat per (camp fields.RequestUserId)
  const [userOptions, setUserOptions] = useState([])
  const [defaultUser, setDefaultUser] = useState({})

  // Arregla (camp fields.TechnicalUserId)
  const [defaultTecnic, setDefaultTecnic] = useState({})

  // Maquina Teixiduria (camp fields.Machine)
  const [machineOptions, setMachineOptions] = useState([])
  const [machineOption, setMachineOption] = useState({})
  const [machineDescrip, setMachineDescrip] = useState({})

  // Altres Equips (camp fields.IdEquipment)
  const [mtOptions, setMtOptions] = useState([])
  const [defaultMt, setDefaultMt] = useState({})

  const [defaultArea, setDefaultArea] = useState({})
  const [defaultShed, setDefaultShed] = useState({})

  useEffect(() => {
    setMtOptions(mtList)
    setDefaultMt(
      mtOptions.find((option) => option.value === fields.IdEquipment)
    )
  }, [mtList])

  useEffect(() => {
    setMachineOptions(machines)
    setMachineOption(
      machineOptions.find((option) => option.value === fields.Machine)
    )
    const machDesc = machineOptions.find(
      (option) => option.value === fields.Machine
    )
    // grabar en STATE el valor Descripción de la Machine, si no tiene, graba null
    setMachineDescrip(machDesc ?? null)
  }, [machines])

  useEffect(() => {
    setUserOptions(users)
    if (fields.RequestUserId !== null) {
      setDefaultUser(
        userOptions.find((option) => option.value === fields.RequestUserId)
      )
    }
    if (fields.TechnicalUserId !== null) {
      setDefaultTecnic(
        userOptions.find((option) => option.value === fields.TechnicalUserId)
      )
    } else {
      setDefaultTecnic('Selecciona...')
    }
  }, [users])

  useEffect(() => {
    setDefaultArea(areaOptions.find((option) => option.value === fields.Area))
    setDefaultShed(shedOptions.find((option) => option.value === fields.Shed))
  }, [fields])

  const handleClick = () => {
    hiddenFileInput.current.click()
  }
  const allFields = [
    // { label: 'Sol·licitat per', name: 'RequestUserId', type: 4 },

    // { label: 'Data de la sol·licitud', name: 'RequestDate', type: 1 },
    // {
    //   label: 'Data del correctiu: ',
    //   name: 'CorrectiveDate',
    //   type: 1,
    //   isHidden: true,
    //   value: fields.CorrectiveDate
    // },
    // {
    //   label: 'Nau magatzem: ',
    //   name: 'Shed',
    //   type: 6,
    //   isHidden: true,
    //   options: ['Selecciona...','Cetriko','4KF'],
    //   value: fields.Shed
    // },
    // {
    //   label: 'Extern: ',
    //   name: 'External',
    //   type: 1,
    //   isHidden: true,
    //   value: fields.External
    // },
    // {
    //   label: "COM ES TROBA L'EQUIP?",
    //   name: 'WhatStatus',
    //   type: 5,
    //   isHidden: false,
    //   value: fields.WhatStatus
    // },
    // { label: "COM S'ARREGLA?",
    //   name: 'WhatDoes',
    //   type: 5,
    //   isHidden: false,
    //   value: fields.WhatDoes
    // },
    // { label: 'RECANVIS GASTATS',
    //   name: 'SpentPart',
    //   type: 5,
    //   isHidden: false,
    //   value: fields.SpentPart
    // },
    {
      label: 'hores :',
      name: 'CorrectiveTimeHours',
      type: 6,
      isHidden: false,
      options: [0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 36, 48, 72, 96],
      time: ' h'
    },
    // { label: 'Màq. teix.', name: 'Machine', type: 1 },
    // { label: 'Altres equips', name: 'IdEquipment', type: 1 },
    {
      label: ': minuts',
      name: 'CorrectiveTimeMinutes',
      type: 6,
      isHidden: false,
      options: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      time: ' min'
    },

    // { label: 'Què fa?', name: 'WhatDoes', type: 1, isHidden: true },
    {
      label: 'Recomanacions',
      name: 'Recommendations',
      type: 1,
      isHidden: false,
      value: fields.Recommendations
    },
    {
      label: 'Cost Repar',
      name: 'RepairCost',
      type: 3,
      isHidden: true,
      value: fields.RepairCost
    }
    // { label: 'Eines', name: 'Tools', type: 1, isHidden: true },
    // {
    //   label: 'Temps correctiu',
    //   name: 'CorrectiveTime',
    //   type: 3,
    //   isHidden: true
    // },
    // { label: 'Descripció', name: 'Description', type: 1 },
    // { label: 'Pèrdua de producció', name: 'isLostProduction', type: 3 },

    // { label: 'Urgent', name: 'IsUrgent', type: 2, isHidden: true }
  ]
  const operatorFields = allFields.filter((field) => !field.isHidden)
  const getFields = () => (user.Role.Id === 1 ? allFields : operatorFields)
  const areaOptions = [
    { label: 'Recepció', value: 'Recepció' },
    { label: 'Teixiduria', value: 'Teixiduria' },
    { label: 'Tintura', value: 'Tintura' },
    { label: 'Acabats', value: 'Acabats' },
    { label: 'Expedició', value: 'Expedició' },
    { label: 'Magatzem', value: 'Magatzem' },
    { label: 'Repàs', value: 'Repàs' },
    { label: 'Manteniment', value: 'Manteniment' },
    { label: 'Instal·lacions', value: 'Instal·lacions' },
    { label: 'Vigilància', value: 'Vigilància' },
    { label: 'Laboratori', value: 'Laboratori' },
    { label: 'Responsables / Tècnics', value: 'Responsables / Tècnics' },
    { label: 'Direcció', value: 'Direcció' },
    { label: 'Administració', value: 'Administració' },
    { label: 'Compres i Finances', value: 'Compres i Finances' },
    { label: 'Neteja', value: 'Neteja' },
    { label: 'Qualitat', value: 'Qualitat' },
    { label: 'Rentat', value: 'Rentat' }
  ]

  const shedOptions = [
    { label: 'Cetriko', value: 'Cetriko' },
    { label: '4KF', value: '4KF' }
  ]

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        // validationSchema={DescriptionSchema}

        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col items-center print:bg-white">
            <div className="container relative grid grid-cols-6">
              {/* {(defaultUser || isNew) && ( */}
              <div className="w-full col-span-2 bg-gray-200 py-2 px-3 items-center print:bg-white">
                <label
                  className="block  mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                  htmlFor="RequestUserId"
                >
                  Sol·licitat per
                </label>

                {/* DEBUGGER - NO BORRAR */}
                {/* {fields.RequestUserId}                  
                {fields.RequestUserName}                  
                {console.log('fields.RequestUserId...',fields.RequestUserId)}
                {console.log('userOptions...',userOptions)}
                {console.log('defaultUser...',defaultUser)}  */}

                <Select
                  className="rounded text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('RequestUserId', e.value)
                    setFieldValue('RequestUserName', e.label)
                  }}
                  name="RequestUserId"
                  placeholder="Selecciona..."
                  options={userOptions}
                  defaultValue={defaultUser ?? ''}
                  value={userOptions.find(
                    (option) => option.value === values.RequestUserId
                  )}
                />
              </div>
              {/* )} */}

              {/* {(defaultArea || isNew) && ( */}
              <div
                className={`w-full col-span-2 bg-gray-200 py-2 px-3 items-center print:bg-white`}>
                <label
                  className="block mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                  htmlFor="RequestUserId"
                >
                  Àrea / procès
                </label>
                {/* {fields.Area} */}
                <Select
                  className="rounded text-grey-darker"
                  onChange={(e) => setFieldValue('Area', e.value)}
                  name="Area"
                  placeholder="Selecciona..."
                  options={areaOptions}
                  // defaultValue= {areaOption}
                  value={areaOptions.find(
                    (option) => option.value === values.Area
                  )}
                />
              </div>

              {/* NAU */}
              <div
                className={`w-full col-span-1 bg-gray-200 py-2 px-3 items-center block p-2 print:bg-white`}
              >
                <label
                  className="block mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                  htmlFor="RequestUserId"
                >
                  Nau/magatzem
                </label>

                <Select
                  className="rounded w-66 text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('Shed', e.value)
                  }}
                  name="Shed"
                  placeholder="Selecciona..."
                  options={shedOptions}
                  defaultValue={defaultShed ?? ''}
                  // value       = {defaultShed ?? ''}
                  value={shedOptions.find(
                    (option) => option.value === values.Shed
                  )}
                />

                {/* <Field                  
                      className   = {`pl-2  shadow  rounded  h-24 w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                      onChange    = {(e) => { handleChange(e) }}
                      as          = "text"
                      name        = "Shed"
                      placeholder = "escriu..."
                      type        = "text"     
                      readOnly    = {false}
                      disabled    = {false}
                  /> */}
              </div>

              {/* Millora Màquina e Imprés */}
              <div
                className={`col-span-1 bg-gray-200 py-2 px-3 items-center block p-2 print:bg-white`}
              >
                <label
                  className="block mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                  htmlFor="IsMachineImprovement"
                >
                  Millora Màquina
                </label>

                <Field
                // disabled={readOnly || !canEditTeixi}
                  as="select"
                  name="IsMachineImprovement"
                  onChange={(e) => {
                    handleChange(e)
                    // setTimeout(submitForm, 0)
                  }}
                  className="form-select block h-8 border rounded ml-16 2xl:ml-0 border-gray-300 mr-1"
                >
                  <option
                    selected={values.IsMachineImprovement === false && 'selected'}
                    value={false}
                  >
                    No
                  </option>
                  <option
                    selected={values.IsMachineImprovement === true && 'selected'}
                    value={true}
                  >
                    Si
                  </option>
                </Field>
                
                <label
                  className="block mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                  htmlFor="IsPrinted"
                >
                  Imprès
                </label>

                <Field
                // disabled={readOnly || !canEditTeixi}
                  as="select"
                  name="IsPrinted"
                  onChange={(e) => {
                    handleChange(e)
                    // setTimeout(submitForm, 0)
                  }}
                  className="form-select block h-8 border rounded ml-16 2xl:ml-0 border-gray-300 mr-1"
                >
                  <option
                    selected={values.IsPrinted === false && 'selected'}
                    value={false}
                  >
                    No
                  </option>
                  <option
                    selected={values.IsPrinted === true && 'selected'}
                    value={true}
                  >
                    Si
                  </option>
                </Field>
              </div>

              <div
                className={` ${
                  user.Role.Id === 2
                    ? 'static bg-gray-200'
                    : 'absolute w-48'
                }  col-span-3 -top-20 right-0 items-center 
                   block
                   p-2`}
              >
                <label
                  className="block   text-grey-darker text-md font-ms-semi  col-start-full"
                  htmlFor="type"
                >
                  Data de la sol·licitud
                </label>

                <Field
                  disabled
                  className={`shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                  name="RequestDate"
                  type="text"
                />
              </div>

              <div className="bg-gray-200 col-span-3 print:bg-white">
                <p className="border-b-4 border-charcoal mt-2 mx-2 font-ms-semi print:border-b-2">
                  MÀQUINA EN QUE ES TROBA L&#39; ANOMALIA
                </p>

                {/* {(machineOption || isNew) && ( */}
                <div className="w-full flex py-2 px-3 items-center">
                  <label
                    className="block  w-4/12 mr-2 text-grey-darker text-md font-ms-semi  col-start-full"
                    htmlFor="Machine"
                  >
                    Màq. Teix.
                  </label>

                  {/* DEBUGGER - NO BORRAR */}
                  {/* {console.log('en select machineOptions vale: ',machineOptions)}
                   */}
                  {/* {console.log('en select fields vale: ', fields)} */}
                  {/* {console.log('en select fields.MachineName vale: ',fields.MachineName)} */}
                  {/* {console.log('en select fields.descr vale: ', fields.descr)}  */}

                  <Select
                    className="w-52 pr-9 rounded w-8/12 text-grey-darker"
                    onChange={(e) => {
                      setFieldValue('Machine', e.value)
                      setFieldValue('MachineName', e.value + ' ' + e.descr)                      
                      setMachineDescrip(e)  // e.label)
                    }}
                    name="Machine"
                    placeholder="Selecciona..."
                    options={machineOptions}
                    defaultValue={machineOption ?? ''}
                    value={machineOptions.find(
                      (option) => option.value === values.Machine
                    )}
                  />
                  <Field
                    disabled
                    type="text"
                    name="MachineName"
                    // value={values.MachineName !== 'undefined' ? values.MachineName : ''}
                    value={machineDescrip?.descr ? machineDescrip.descr : ''} 
                    autoComplete="MachineName"
                    className="appearance-none print:ml-20  rounded print:w-28 border border-gray-300 px-2 py-1 w-36 2xl:w-full leading-tight focus:outline-none focus:shadow-outline mr-1"
                  />
                </div>
                {/* )} */}

                {/* {(defaultMt || isNew) && ( */}
                <div className="w-full col-span-3 bg-gray-200 flex py-2 px-3 items-center print:bg-white">
                  {/* ocultamos Altres Equips si es parte manteniment Maq Teixiduria */}
                  <p>&nbsp;</p>

                  {/* 
                  <label
                    className="block w-4/12 mr-2 text-grey-darker text-md font-ms-semi  col-start-full"
                    htmlFor="IdEquipment"
                  >
                    Altres equips
                  </label>
                  <Select
                    className="rounded  mt-1 w-8/12 text-grey-darker"
                    onChange={(e) => {
                      setFieldValue('IdEquipment', e.value)
                    }}
                    name="IdEquipment"
                    placeholder="Selecciona..."
                    options={mtOptions}
                    defaultValue={defaultMt ?? ''}
                    value={mtOptions.find(
                      (option) => option.value === values.IdEquipment
                    )}
                  /> */}
                </div>
                {/* )} */}
              </div>

              <div
                className={`w-full ${user.Role.Id === 2 ? 'col-span-6' : 'col-span-3'} 
                bg-gray-200 items-center block p-2 print:bg-white`}
              >
                <p className="border-b-4  mb-2  border-charcoal font-ms-semi print:border-b-2">
                  DESCRIPCIÓ ANOMALIA
                </p>

                {/* {console.log(allFields)}
                {console.log(user.Role)} */}

                <Field
                  className={`pl-2  shadow  rounded  h-24 w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                  onChange={(e) => {
                    // console.log('pre-onChange textarea...')
                    handleChange(e)
                    // console.log('post-onChange textarea...')
                  }}
                  as="textarea"
                  name="Description"
                  placeholder="escriu..."
                  type="text"
                  // readOnly    = {false}
                  // disabled    = {false}
                />
              </div>

              {(defaultTecnic || isNew) && (
                <div
                  className={`mt-3 w-full col-span-3 bg-blue-100 py-2 px-3 items-center 
                  col-start-1 print:bg-white`}
                >
                  <label
                    className="block mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                    htmlFor="TechnicalUserName"
                  >
                    Arregla:
                  </label>

                  {/* DEBUGGER - NO BORRAR */}
                  {/* {fields.TechnicalUserId} 
                {console.log('fields.TechnicalUserId...',fields.TechnicalUserId)}
                {console.log('userOptions...',userOptions)}
                {console.log('defaultTecnic...',defaultTecnic)}  */}

                  <Select
                    className="rounded h-6 w-full text-grey-darker"
                    onChange={(e) => {
                      setFieldValue('TechnicalUserName', e.label)
                      setFieldValue('TechnicalUserId', e.value)
                    }}
                    name="RequestUserId"
                    placeholder="Selecciona..."
                    options={userOptions}
                    defaultValue={defaultTecnic ?? ''}
                    value={userOptions.find(
                      (option) => option.value === values.defaultTecnic
                    )}
                  />
                </div>
              )}

              <div
                className={`mt-3 w-full col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}
              >
                <p className="border-b-4 font-ms-semi print:border-b-2">Data del correctiu:</p>
                <Field
                  className={`pl-2 rounded w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker print:border-b-0`}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  // as          = "textarea"
                  name="CorrectiveDate"
                  placeholder="escriu..."
                  type="date"
                  readOnly={false}
                  disabled={false}
                />
              </div>

              <div
                className={`w-full col-span-6 bg-blue-100 items-center block p-2 print:bg-white`}
              >
                <p className="border-b-4 font-ms-semi print:border-b-2">Extern:</p>
                <Field
                  className={`pl-2  shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2 print:border-b-0`}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  // as          = "textarea"
                  name="External"
                  placeholder="escriu..."
                  type="text"
                  readOnly={false}
                  disabled={false}
                />
              </div>

              <div
                className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
              >
                <p className="border-b-4  mb-2  border-charcoal font-ms-semi print:border-b-2">
                  COM ES TROBA L&#39;EQUIP ?
                </p>
                <Field
                  className={`pl-2  shadow  rounded  h-24 w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  as="textarea"
                  name="WhatStatus"
                  placeholder="escriu..."
                  type="text"
                  readOnly={false}
                  disabled={false}
                />
              </div>

              <div
                className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
              >
                <p className="border-b-4  mb-2  border-charcoal font-ms-semi print:border-b-2">
                  COM S&#39;ARREGLA ?
                </p>
                <Field
                  className={`pl-2  shadow  rounded  h-24 w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  as="textarea"
                  name="WhatDoes"
                  placeholder="escriu..."
                  type="text"
                  readOnly={false}
                  disabled={false}
                />
              </div>

              <div
                className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
              >
                <p className="border-b-4  mb-2  border-charcoal font-ms-semi print:border-b-2">
                  RECANVIS GASTATS
                </p>
                <Field
                  className={`pl-2  shadow  rounded  h-24 w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  as="textarea"
                  name="SpentPart"
                  placeholder="escriu..."
                  type="text"
                  readOnly={false}
                  disabled={false}
                />
              </div>

              {/* Campo Shed - tipo 6 (combo - select) */}
              {/* {console.log('getFields...',getFields)} */}
              {/* {console.log('allFields...',allFields)} */}

              {getFields().map(
                ({ label, name, type, options, time, value }, i) => (
                  <div
                    key={i}
                    className={` ${
                      label === 'Nau magatzem: '
                        ? 'col-span-3'
                        : type === 5
                        ? 'col-span-2'
                        : label === 'Extern'
                        ? 'col-span-6'
                        : type === 6
                        ? 'col-span-1 pr-0 pl-0'
                        : user.Role.Id === 2 && label === 'Recomanacions'
                        ? 'col-span-4'
                        : user.Role.Id === 2 && label === 'Recomanacions'
                        ? 'col-span-2'
                        : label === 'Cost Repar'
                        ? 'col-span-2'
                        : 'col-span-3'
                    } bg-blue-100 ${i === 0 && 'mt-0'}  items-center ${
                      type === 2 ? 'flex ' : 'block'
                    }  p-2 print:bg-white print:border-b-2`}
                  >
                    <label
                      // TEMPS: hores - minuts (if hores ... text-right)
                      className={`${
                        label === 'hores :'
                          ? 'block text-grey-darker text-md font-ms-semi mb-2 col-start-full border-b-4 border-charcoal mr-0 ml-2 text-right print:border-b-2'
                          : 'block text-grey-darker text-md font-ms-semi mb-2 col-start-full border-b-4 border-charcoal mr-1 print:border-b-2'
                      }`}
                      htmlFor="type"
                    >
                      {label}
                    </label>

                    {type === 2 ? (
                      <Field
                        className={`pl-2  shadow  rounded w-5 h-5 py-2 px-3 text-grey-darker`}
                        name={name}
                        type="checkbox"
                        // placeholder="escriu..."
                        onChange={(e) => handleChange(e)}
                      />
                    ) : type === 5 ? (
                      <Field
                        as="textarea"
                        // className={`pl-2 shadow rounded w-full py-2 px-3 text-grey-darker`}
                        className={`pl-2 shadow rounded w-full py-2 px-3 bg-red-300 true`}
                        name={name}
                        value={value}
                        type="text"
                        onChange={(e) => handleChange(e)}
                        placeholder="escriu..."
                        // readOnly   = {false}
                        // disabled   = {false}
                      />
                    ) : type === 6 ? (
                      <div className="relative ">
                        <Field
                          as="select"
                          className={`ml-0 pl-2 shadow  rounded  w-full  border border-gray-300 py-2 px-3 text-grey-darker `}
                          name={name}
                          type={'number'}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        >
                          {options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                              {time}
                            </option>
                          ))}
                        </Field>
                      </div>
                    ) : (
                      <Field
                        className={`pl-2 shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                        name={name}
                        type={type === 1 ? 'text' : 'number'}
                        placeholder="escriu..."
                        // readOnly   = {false}
                        // disabled   = {false}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                      />
                    )}
                  </div>
                )
              )}
            </div>

            <button
              className=" ml-auto 2xl:mr-20  block transition duration-300 
              border-primary hover:border-blue-200 bg-primary text-white hover:bg-blue-200 hover:text-primary
              font-ms-semi py-3 px-10 border   h-1/2 mt-2 rounded 
              print:hidden"
              type="submit"
            >
              GUARDAR
            </button>
            {/* <div className='mt-12'>.</div> */}
          </Form>
        )}
      </Formik>
      <table
        className={` bg-white mt-3 w-full mr-10 border shadow-md rounded-lg border-gray-300 ${
          user.Role.Id === 2 && 'hidden'
        }`}
      >
        <thead>
          <tr>
            <th
              className="print:hidden font-ms-bold text-sm h-3 w-100vw relative text-left px-4 bg-blue-900 text-white"
              colSpan={documentsLength}
            >
              <button
                className={` right-2  bottom-2 align-right p-1 font-ms-semi m-3 px-1 rounded-lg  transition-colors duration-300 focus:shadow-outline
                  text-white bg-primary hover:bg-blue-200 hover:text-primary`}
                onClick={handleClick}
              >
                <MdOutlineUploadFile size={25} />
              </button>
              <a className="text-lg">FITXERS ADJUNTS</a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>{renderTableData()}</tr>
        </tbody>
      </table>
      <input
        ref={hiddenFileInput}
        accept=".pdf, .xlsx"
        type="file"
        name="file"
        multiple="multiple"
        className=" h-1/6   hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
        onChange={(e) => uploadDocument(e)}
      />
    </>
  )
}

Maintenance.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isUpdate: PropTypes.any,
  users: PropTypes.array,
  uploadDocument: PropTypes.func,
  renderTableData: PropTypes.func,
  documentsLength: PropTypes.number,
  isNew: PropTypes.bool,
  machines: PropTypes.array,
  mtList: PropTypes.array
}

export default Maintenance
