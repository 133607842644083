import ListTable from 'components/NC/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

// localStorage.setItem('acumHours',0)
// localStorage.setItem('acumMinutes',0)

const NCList = () => {
  const { setTitle } = useContext(TitleContext)
  const [ncList, ncListAction] = useFetch()
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)
  const [userList, userListAction] = useFetch()

  useEffect(() => {
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    userListAction(userListAPIParams)
    // Set title
    const ncTitle = {
      name: 'Partes de no conformitat',
      buttons: [
        {
          name: 'Nova FT Teixiduria',
          link: `/no-conformitats/nou/`
        }
      ]
    }
    setTitle(ncTitle)

    // Fetch api FT List
    const ncListAPIParams = {
      url: `noconformitie`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    ncListAction(ncListAPIParams)

    //* Avoid bug when delete NC modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/no-conformitats/${item.Id}`)
  }
  return ncList.data && userList.data ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable
        users={userList.data}
        items={ncList.data}
        handleClick={handleClick}
      />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default NCList
