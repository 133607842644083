import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Nom',
      accessor: 'Treballadors_Nom',
      Filter: ColumnFilter
    },
    {
      Header: 'Empresa',
      accessor: 'Treballadors_Empresa',
      Filter: ColumnFilter
    },
    {
      Header: 'Nau',
      accessor: 'Treballadors_Nau',
      Filter: ColumnFilter
    },
    {
      Header: 'Àrea',
      accessor: 'Treballadors_AreaActual',
      Filter: ColumnFilter
    },
    {
      Header: '(%) Jornada',
      accessor: 'Treballadors_Jornada',
      Filter: ColumnFilter
    },
    {
      Header: 'ETT',
      accessor: 'Treballadors_Ett',
      Filter: ColumnFilter
    },
    {
      Header: 'Fixe',
      accessor: 'Treballadors_Fixe',
      Filter: ColumnFilter
    },
    {
      Header: 'Temporal',
      accessor: 'Treballadors_Temporal',
      Filter: ColumnFilter
    },
    {
      Header: 'Vigent',
      accessor: 'Treballadors_Vigent',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach(function (item, index) {
        if (item.IsRemoved === false){
          itemsData.push({
            Id:                      item.Id,
            Treballadors_Nom:        item.Name, 
            Treballadors_Tipu:       item.Tipu, 
            Treballadors_Ett:        item.Ett, 
            Treballadors_Nau:        item.Nau,	
            Treballadors_Vigent:     item.Vigent, 
            Treballadors_Fixe:       item.fixe, 
            Treballadors_Temporal:   item.Temporal, 
            Treballadors_Empresa:    item.Empresa_Id ? item.Empresa_Id.Name : null, 
            Treballadors_AreaActual: item.Area_Id ? item.Area_Id.Name : null, 
            Treballadors_JC:         item.JC, 
            Treballadors_Jornada:    item.Jornada && item.JC ? "(" + item.JC + "%) " + item.Jornada 
                                                             : item.Jornada && !item.JC ? item.Jornada
                                                             : !item.Jornada && item.JC ? "(" + item.JC + "%) "
                                                             : null
          })
        }
      })

      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 && (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}