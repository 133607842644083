import PropTypes from 'prop-types'

export default function PackagingForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-1 lg:gap-3 print:gap-2 print:auto-rows-auto">
      {/* TIPUS TUB */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus tub:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight rounded-sm overflow-ellipsis border text-gray-500 ${
            valuesBefore?.TubeSize !== valuesAfter?.TubeSize
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          }`}
        >
          {side === 'left' ? valuesBefore?.TubeSize : valuesAfter?.TubeSize}
        </div>
      </div>

      {/* COMENTARI TUB */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Comentari Tub:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.TubName !== valuesAfter?.TubName
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.TubName : valuesAfter?.TubName}
        </div>
      </div>

      {/* CINTA DOBLE CARA TUB */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Cinta doble cara tub:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.HasDoubleSidedTape !== valuesAfter?.HasDoubleSidedTape
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.HasDoubleSidedTape
            : valuesAfter?.HasDoubleSidedTape}
        </div>
      </div>

      {/* FIXACIÓ FI BOB */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Fixació fi bob:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.FinalCoilFixation !== valuesAfter?.FinalCoilFixation
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.FinalCoilFixation
            : valuesAfter?.FinalCoilFixation}
        </div>
      </div>

      {/* TIPUS PLÀSTIC */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Tipus plàstic:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.PlasticType !== valuesAfter?.PlasticType
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.PlasticType
            : valuesAfter?.PlasticType}
        </div>
      </div>

      {/* FILM POLIETILÈ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Film Polietilè:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.IsPolyethyleneFilm !== valuesAfter?.IsPolyethyleneFilm
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.IsPolyethyleneFilm
              ? 'Sí'
              : 'No'
            : valuesAfter?.IsPolyethyleneFilm
            ? 'Sí'
            : 'No'}
        </div>
      </div>

      {/* CANTONERES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Cantoneres:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.HasCorners !== valuesAfter?.HasCorners
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.HasCorners
              ? 'Sí'
              : 'No'
            : valuesAfter?.HasCorners
            ? 'Sí'
            : 'No'}
        </div>
      </div>

      {/* PRECINTE COLOR */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Precinte color:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.PrecinctColor !== valuesAfter?.PrecinctColor
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {(() => {
            switch (
              side === 'left'
                ? valuesBefore?.PrecinctColor
                : valuesAfter?.PrecinctColor
            ) {
              case 'no':
                return 'No'
              case 'blau':
                return 'Blau'
              case 'groc':
                return 'Groc'
              case 'verd':
                return 'Verd'
              case 'vermell':
                return 'Vermell'
              case 'marro':
                return 'Marró'
              case 'negre':
                return 'Negre'
              case 'taronja':
                return 'Taronja'
              case 'blaugroc':
                return 'Blau + Groc'
              case 'blauverd':
                return 'Blau + Verd'
              case 'blauvermell':
                return 'Blau + Vermell'
              case 'grocverd':
                return 'Groc + Verd'
              default:
                return '-'
            }
          })()}
        </div>
      </div>

      {/* ALTRES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Altres:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Others !== valuesAfter?.Others
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Others : valuesAfter?.Others}
        </div>
      </div>

      {/* ETIQUETES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Etiquetes:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TagNumber !== valuesAfter?.TagNumber
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.TagNumber : valuesAfter?.TagNumber}
        </div>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TagDescription !== valuesAfter?.TagDescription
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.TagDescription
            : valuesAfter?.TagDescription}
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.PackagingObservation !==
            valuesAfter?.PackagingObservation
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.PackagingObservation
            : valuesAfter?.PackagingObservation}
        </div>
      </div>
    </div>
  )
}

PackagingForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
