import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import ClientView from 'forms/clients/clientView'
import ClientCreate from 'forms/clients/clientCreate'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { AiOutlineHistory } from 'react-icons/ai'
import { CgSpinner } from 'react-icons/cg'
import { MdEdit } from 'react-icons/md'
import { useLocation, Link } from 'wouter'

export default function ClientDetail({ params }) {
  // Contextos:
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)

  // Fetch:
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallGetRequest, setApiCallGetRequest] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()

  // States
  const [canRequest, setCanRequest] = useState(false)
  const [canCheckHistory, setCanCheckHistory] = useState(false)
  const [users, setUsers] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  const isNew = params.id === 'nou'

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API:
  const [getFieldsState, setFieldsState] = useState({
    Id: 0,
    Name: '',
    Code: '',
    Tag: '',
    Position: '',
    Coil: 'NO',
    Notes: '',
    EditionNumber: 1,
    EditionDate: '',
    Manager: []
  })

  const formatDate = (dateString) => {
    const utcDate = new Date(dateString)
    const userTimeZoneOffset = new Date().getTimezoneOffset()
    const userLocalTime = new Date(
      utcDate.getTime() - userTimeZoneOffset * 60000
    )

    // Obtener los componentes de la fecha
    const day = ('0' + userLocalTime.getDate()).slice(-2)
    const month = ('0' + (userLocalTime.getMonth() + 1)).slice(-2)
    const year = userLocalTime.getFullYear()
    const hour = ('0' + userLocalTime.getHours()).slice(-2)
    const minute = ('0' + userLocalTime.getMinutes()).slice(-2)

    // Construir la cadena de fecha formateada
    const formattedLocalTime = `${day}/${month}/${year}, ${hour}:${minute} h`

    return formattedLocalTime
  }

  // Get all users if not new
  useEffect(() => {
    const title = {
      name: `Veure registre`,
      buttons: [
        {
          name: 'Tornar',
          link: `/clients`
        }
      ]
    }
    setTitle(title)

    const apiCallGetUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP!'
    }
    setApiCallGetUsers(apiCallGetUsers)
  }, [])

  // Get current user
  useEffect(() => {
    if (getApiCallGetUsers.data && !users) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get current client
  useEffect(() => {
    if (getApiCallGetUser.data && !currentUser && !isNew) {
      setCurrentUser(getApiCallGetUser.data)

      const apiCallGet = {
        url: `client/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  // Get last applied request on current client
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Id === 0) {
      const apiCallGetRequest = {
        url: `client/request/last/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetRequest(apiCallGetRequest)
    }
  }, [getApiCallGet.data, currentUser])

  // Set permissions and current client info
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Id === 0) {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))

      const managerTrim = apiData?.Manager?.replace(' ', '')
      const managerSplit = managerTrim?.split(',') ?? []
      apiData.Manager = managerSplit
      setFieldsState(apiData)

      // Permissions
      let allow = false
      const userRol = user.Role.Id
      const canRequestClient = currentUser?.CanCreateClientRequest

      if (userRol === 1) {
        if (canRequestClient) {
          allow = true
        } else {
          allow = false
        }
      } else {
        allow = false
      }

      setCanRequest(allow)

      // -----------------------------------

      let allowCheckHistory = false

      if (userRol === 1) {
        allowCheckHistory = true
      } else {
        allowCheckHistory = false
      }

      setCanCheckHistory(allowCheckHistory)
      // Permissions
    }
  }, [getApiCallGetRequest.data])

  // Post
  const handleSubmit = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))
    preparedValues.Manager = preparedValues.Manager.toString()

    const apiCallPost = {
      url: 'client',
      method: 'POST',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Get post/put response
  useEffect(() => {
    if (getApiCallPost.data) {
      setLocation('/clients')
    }
  }, [getApiCallPost.data])

  return (isNew && getFieldsState !== null) ||
    (!isNew && getFieldsState.Id !== 0) ? (
    <>
      {/* Options bar */}
      {getFieldsState.Id !== 0 && user.Role.Id === 1 && (
        <div
          className="sticky top-0 z-50 flex items-end justify-end w-full col-span-2 px-4 py-3 text-sm uppercase border-b-2 print:hidden border-charcoal"
          style={{ backdropFilter: 'blur(25px)' }}
        >
          {/* HISTORIAL / SOL·LICITAR */}
          <div className="inline-flex items-start space-x-2">
            {canCheckHistory && (
              <Link
                to={`/client/requests/${params.id}`}
                className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center uppercase transition duration-300 bg-white border-2 rounded d-flex text-amber-600 border-amber-600 text-md font-ms-bold hover:bg-amber-600 hover:text-white print:hidden"
              >
                <AiOutlineHistory className="xl:mr-2" size={22} />
                <span className="hidden xl:inline-flex">Historial</span>
              </Link>
            )}

            {canRequest && (
              <Link
                to={`/client/request-create/${params.id}`}
                className="relative inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-teal-500 uppercase transition duration-300 bg-white border-2 border-teal-500 rounded text-md font-ms-bold hover:bg-teal-500 hover:text-white print:hidden"
              >
                <MdEdit className="xl:mr-2" size={22} />
                <span className="hidden xl:inline-flex">Sol·licitar</span>
              </Link>
            )}
          </div>
        </div>
      )}

      <div className="w-full px-4 py-5 space-y-7">
        {isNew ? (
          <ClientCreate
            users={users}
            fields={getFieldsState}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ClientView users={users} fields={getFieldsState} />
        )}

        {/* LAST ACCEPTED REQUEST */}
        {getApiCallGetRequest.data &&
          getApiCallGetRequest.data?.RequestId !== 0 &&
          user.Role.Id === 1 && (
            <div
              className="print:hidden"
              style={{
                pageBreakBefore: 'always',
                pageBreakInside: 'avoid'
              }}
            >
              <div
                className="flex flex-col col-span-12 bg-white border-2 border-collapse border-blue-200 rounded shadow-md print:border print:shadow-none"
                style={{
                  pageBreakInside: 'avoid'
                }}
              >
                <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 print:py-1 print:px-1 font-ms-bold">
                  Última modificació
                </div>

                <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto">
                  {/* SOL·LICITUD */}
                  <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
                    Sol·licitud:
                  </div>
                  <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-9 xl:col-span-10">
                    <div className="w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
                      {'#' + getApiCallGetRequest.data?.RequestId}
                    </div>
                  </div>

                  {/* MOTIU */}
                  <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
                    Motiu:
                  </div>
                  <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-9 xl:col-span-10">
                    <div className="w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
                      {getApiCallGetRequest.data?.Reason}
                    </div>
                  </div>

                  {/* SOL·LICITANT */}
                  <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
                    Sol·licitant:
                  </div>
                  <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-9 xl:col-span-10">
                    <div className="w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
                      {getApiCallGetRequest.data?.Requester?.Name}
                    </div>
                  </div>

                  {/* REVISOR */}
                  <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
                    Revisor:
                  </div>
                  <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-9 xl:col-span-10">
                    <div className="w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
                      {getApiCallGetRequest.data?.Responder?.Name}
                    </div>
                  </div>

                  {/* DATA */}
                  <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
                    Data:
                  </div>
                  <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-9 xl:col-span-10">
                    <div className="w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
                      {formatDate(getApiCallGetRequest.data?.UpdatedAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

ClientDetail.propTypes = {
  params: PropTypes.any
}
