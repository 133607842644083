import corroEnrollatdorJMB2024 from 'assets/icons/corro-enrotllador-JMB-2024.png'
import corroEnrotllador from 'assets/icons/corro-enrotllador.png'
import pinyonsPlegador from 'assets/icons/pinyons-plegador.png'
import relacioPlegadorJMB2024 from 'assets/icons/relacio-plegador-JMB-2024.png'
import tensioPlegadorJMB2024 from 'assets/icons/tensio-plegador-JMB-2024.png'
import tensioPlegador from 'assets/icons/tensio-plegador.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function FolderForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  // Calcular los estilos
  const getFieldStyles = (field) => {
    const hasChanged =
      valuesBefore[field] !== valuesAfter[field] ||
      valuesBefore?.Machine?.slice(0, 3) !== valuesAfter?.Machine?.slice(0, 3)

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <div
      className={`${
        valuesBefore?.Machine?.slice(0, 3) !== valuesAfter?.Machine?.slice(0, 3)
          ? side === 'left'
            ? 'bg-red-50'
            : isAnswered
            ? 'bg-emerald-50'
            : 'bg-amber-50'
          : ''
      } grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min h-full`}
    >
      {/* Campos si 'Machine' contiene 'PAL': A. Calandra */}
      {/* Campos si 'Machine' contiene 'MCH': A. Calandra, Posició */}
      {/* Campos si 'Machine' contiene 'SNT': A. Calandra, Pinyons Plegador */}
      {/* Campos si 'Machine' contiene 'MYR': A. Calandra, Pinyons Plegador, Tensió */}
      {/* Campos si 'Machine' contiene 'TRT': A. Calandra, Posició, Torque */}
      {/* Campos si 'Machine' contiene 'JMB': A. Calandra, ?, Pinyons Plegador, Tensió Plegador */}
      {/* Campos si 'Machine' contiene 'PLT': A. Calandra, Delta, Pinyons Plegador, Tensió del Plegador, Corró enrotllador */}

      {/* A. Calandra */}
      {/* Se muestra en todos los tipos de 'Machine', en 'JMB', 'PLT', 'PAL', 'TRT', 'MCH', 'MYR' y 'SNT': */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        A. Calandra:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('Calandra')}
          value={
            side === 'left' ? valuesBefore?.Calandra : valuesAfter?.Calandra
          }
          disabled
        />
      </div>

      {/* Delta: */}
      {/* Se muestra si 'Machine' contiene 'PLT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT')) && (
        <>
          <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Delta:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              className="w-full block-input-disabled"
              style={getFieldStyles('Delta')}
              value={side === 'left' ? valuesBefore?.Delta : valuesAfter?.Delta}
              disabled
            />
          </div>
        </>
      )}

      {/* Piston JUM */}
      {/* Modificado 28/06/2024 - Ticket Zammad: #36078980 */}
      {/* Se muestra si 'Machine' contiene 'JMB': */}
      {/* TODO */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Relació Plegador:
          </label>

          <div className="relative w-56 col-span-4">
            <img
              src={relacioPlegadorJMB2024}
              alt="Relació Plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('PistonJUM_v2')}
                value={
                  side === 'left'
                    ? valuesBefore?.PistonJUM_v2
                    : valuesAfter?.PistonJUM_v2
                }
                disabled
              />

              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('PistonJUM2_v2')}
                value={
                  side === 'left'
                    ? valuesBefore?.PistonJUM2_v2
                    : valuesAfter?.PistonJUM2_v2
                }
                disabled
              />
            </div>
          </div>
        </div>
      )}

      {/* Pinyons Plegador (1, 2) */}
      {/* Se muestra si 'Machine' contiene 'MYR', 'SNT', 'PLT', 'JMB': */}
      {((side === 'left' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'TRT' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'PAL' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'MCH' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'JMB') ||
        (side === 'right' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'TRT' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'PAL' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'MCH' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'JMB')) && (
        <div className="col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Pinyons Plegador (1, 2):
          </label>

          <div className="relative w-40 col-span-4">
            <img
              src={pinyonsPlegador}
              alt="pinyons plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 flex flex-col items-end justify-around w-full h-full py-1 pr-1 right-2">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('FolderingPiston1')}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderingPiston1
                    : valuesAfter?.FolderingPiston1
                }
                disabled
              />

              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('FolderingPiston2')}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderingPiston2
                    : valuesAfter?.FolderingPiston2
                }
                disabled
              />
            </div>
          </div>
        </div>
      )}

      {/* --------------------------------------------------- */}

      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MCH') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MCH') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'TRT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'TRT') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MYR') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MYR') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Tensió Plegador (1, 2) */}
      {/* Se muestra si 'Machine' contiene 'PLT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador (1, 2):
          </label>

          <div className="grid w-full grid-cols-2 gap-x-2">
            <div className="flex flex-col justify-center col-span-1 space-y-2">
              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P1:
                </label>

                <Field
                  type="text"
                  className="w-9/12 block-input-disabled"
                  style={getFieldStyles('P1')}
                  value={side === 'left' ? valuesBefore?.P1 : valuesAfter?.P1}
                  disabled
                />
              </div>

              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P2:
                </label>

                <Field
                  type="text"
                  className="w-9/12 block-input-disabled"
                  style={getFieldStyles('P2')}
                  value={side === 'left' ? valuesBefore?.P2 : valuesAfter?.P2}
                  disabled
                />
              </div>

              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P3:
                </label>

                <Field
                  type="text"
                  className="w-9/12 block-input-disabled"
                  style={getFieldStyles('P3')}
                  value={side === 'left' ? valuesBefore?.P3 : valuesAfter?.P3}
                  disabled
                />
              </div>
            </div>

            <div className="relative w-full col-span-1">
              <img
                src={tensioPlegador}
                alt="Tensió Plegador"
                className="py-5 mx-auto w-28"
              />

              <div className="absolute bottom-0 left-0 flex flex-col items-center justify-between w-full h-full">
                <Field
                  type="text"
                  className="w-20 block-input-disabled"
                  style={getFieldStyles('FolderTension1')}
                  value={
                    side === 'left'
                      ? valuesBefore?.FolderTension1
                      : valuesAfter?.FolderTension1
                  }
                  disabled
                />

                <Field
                  type="text"
                  className="w-20 block-input-disabled"
                  style={getFieldStyles('FolderTension2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.FolderTension2
                      : valuesAfter?.FolderTension2
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tensió Plegador */}
      {/* Modificado 28/06/2024 - Ticket Zammad: #36078980 */}
      {/* Se muestra si 'Machine' contiene 'JMB': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador:
          </label>

          <div className="relative w-56 col-span-4">
            <img
              src={tensioPlegadorJMB2024}
              alt="Tensió Plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('FolderTension1_v2')}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderTension1_v2
                    : valuesAfter?.FolderTension1_v2
                }
                disabled
              />

              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('FolderTension2_v2')}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderTension2_v2
                    : valuesAfter?.FolderTension2_v2
                }
                disabled
              />
            </div>
          </div>
        </div>
      )}

      {/* Posició */}
      {/* Se muestra si 'Machine' contiene 'MCH': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MCH') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MCH')) && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Posició:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <Field
              type="text"
              className="w-full block-input-disabled"
              style={getFieldStyles('PositionMonarch')}
              value={
                side === 'left'
                  ? valuesBefore?.PositionMonarch
                  : valuesAfter?.PositionMonarch
              }
              disabled
            />
          </div>
        </div>
      )}

      {/* Posició y Torque */}
      {/* Se muestra si 'Machine' contiene 'TRT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'TRT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'TRT')) && (
        <div className="col-span-12">
          <div className="flex flex-row w-full">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Posició:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <Field
                type="text"
                className="w-full block-input-disabled"
                style={getFieldStyles('PositionTerrot1')}
                value={
                  side === 'left'
                    ? valuesBefore?.PositionTerrot1
                    : valuesAfter?.PositionTerrot1
                }
                disabled
              />
            </div>
          </div>

          <div className="flex flex-row w-full pt-2">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Torque:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <Field
                type="text"
                className="w-full block-input-disabled"
                style={getFieldStyles('PositionTerrot2')}
                value={
                  side === 'left'
                    ? valuesBefore?.PositionTerrot2
                    : valuesAfter?.PositionTerrot2
                }
                disabled
              />
            </div>
          </div>
        </div>
      )}

      {/* Tensió */}
      {/* Se muestra si 'Machine' contiene 'MYR': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MYR') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MYR')) && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Tensió:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <Field
              type="text"
              className="w-full block-input-disabled"
              style={getFieldStyles('TensionMayer')}
              value={
                side === 'left'
                  ? valuesBefore?.TensionMayer
                  : valuesAfter?.TensionMayer
              }
              disabled
            />
          </div>
        </div>
      )}

      {/* --------------------------------------------------- */}

      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Corró enrotllador */}
      {/* Se muestra si 'Machine' contiene 'PLT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi">
            Corró enrotllador:
          </label>

          <div className="relative w-full col-span-4">
            <img
              src={corroEnrotllador}
              alt="Tensió Plegador"
              className="mx-auto md:w-28 xl:w-32"
            />

            <div className="absolute bottom-0 flex flex-col items-start justify-around w-auto h-full md:left-0 lg:left-8 xl:left-20 2xl:left-32">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('RollingRoller1')}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller1
                    : valuesAfter?.RollingRoller1
                }
                disabled
              />

              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('RollingRoller3')}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller3
                    : valuesAfter?.RollingRoller3
                }
                disabled
              />
            </div>

            <div className="absolute bottom-0 flex flex-col items-end justify-between w-auto h-full md:right-0 lg:right-8 xl:right-20 2xl:right-32">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('RollingRoller2')}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller2
                    : valuesAfter?.RollingRoller2
                }
                disabled
              />

              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('RollingRoller4')}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller4
                    : valuesAfter?.RollingRoller4
                }
                disabled
              />
            </div>
          </div>
        </div>
      )}

      {/* Corró enrotllador */}
      {/* Creado 28/06/2024 - Ticket Zammad: #36078980 */}
      {/* Se muestra si 'Machine' contiene 'JMB': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
            Corró Enrotllador:
          </label>

          <div className="flex flex-row space-x-4">
            <div className="flex items-start justify-start pt-6">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('RollingRoller1_v2')}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller1_v2
                    : valuesAfter?.RollingRoller1_v2
                }
                disabled
              />
            </div>

            <img
              src={corroEnrollatdorJMB2024}
              alt="Corró Enrotllador"
              className="w-16"
            />

            <div className="flex items-end justify-end pb-6">
              <Field
                type="text"
                className="w-20 block-input-disabled"
                style={getFieldStyles('RollingRoller2_v2')}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller2_v2
                    : valuesAfter?.RollingRoller2_v2
                }
                disabled
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

FolderForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
