import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { AiOutlineCheck, AiOutlineClose, AiOutlineSmile, AiOutlineMeh, AiOutlineFrown } from 'react-icons/ai'

export default function ListTable({ items, handleClick, users }) {
  const [tableData, setTableData] = useState([])
  
  // ----------------------------------
  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +  
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')
  // ----------------------------------

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [    
    {
      Header: ' ',
      accessor: 'PreventiveWeavingOthers_Semafor',      
      Filter: ColumnFilter,      
      Cell: ({ value }) =>
        parseInt(value, 10) > 15 ? (
          <AiOutlineSmile
            color="green"
            // className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
            size={24}
          />
        ) : parseInt(value, 10) < 0 ? (
          <AiOutlineFrown            
            color="red"
            // className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
            size={24}
          />
        ) : (
          <AiOutlineMeh            
            color="orange"
            // className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
            size={24}
          />
        )
    },
    {
      Header: 'Dies Pdts',
      accessor: 'PreventiveWeavingOthers_PendingDays',
      Filter: ColumnFilter,            
    },   
    {
      Header: 'Màquina',
      accessor: 'PreventiveWeavingOthers_IdMaq',
      Filter: ColumnFilter
    },
    {
      Header: 'Equip',
      accessor: 'PreventiveWeavingOthers_EquipName',
      Filter: ColumnFilter
    }, 
    {
      Header: 'Dia previst',
      accessor: 'PreventiveWeavingOthers_NoticeDate',
      Filter: ColumnFilter      
    },    
    {
      Header: 'Nau',
      accessor: 'PreventiveWeavingOthers_Shed',
      Filter: ColumnFilter
    },
    {
      Header: 'Nivell',
      accessor: 'PreventiveWeavingOthers_Level',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Pàrquing',
    //   accessor: 'parking',
    //   Filter: ColumnFilter
    // },    
    {
      Header: 'Crític',
      accessor: 'PreventiveWeavingOthers_IsCritical',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    },
    // {
    //   Header: 'Responsable',
    //   accessor: 'userResponsableId',
    //   Filter: ColumnFilter
    // },
    {
      Header: 'Responsable',
      accessor: 'PreventiveWeavingOthers_UserResponsableName',
      Filter: ColumnFilter
    },
    {
      Header: 'Freq.mesos',
      accessor: 'PreventiveWeavingOthers_MonthFrequency',
      Filter: ColumnFilter
    },
    {
      Header: 'Freq',    // 'Estat i dies que falten',
      accessor: 'PreventiveWeavingOthers_Frequency',
      Filter: ColumnFilter
    },    
    {
      Header: 'Registre',
      accessor: 'PreventiveWeavingOthers_Record',
      Filter: ColumnFilter
    },    
    {
      Header: 'Descripció',
      accessor: 'PreventiveWeavingOthers_Information',
      Filter: ColumnFilter
    },            
    {
      Header: 'Comentari',
      accessor: 'PreventiveWeavingOthers_Comment',
      Filter: ColumnFilter
    },
    {
      Header: 'Edició',
      accessor: 'PreventiveWeavingOthers_LastModificationDate',
      Filter: ColumnFilter
    },         
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []
      items.forEach((item) => {

        // Calcular dias pendientes entre fecha preventivo y próxima generación (se calcula en función de la fecha sistema):
        const data1 = new Date(item.NoticeDate)
        const data2 = new Date(datetime)

        // passem de milisegons a dies (milisegons -> segons -> minuts -> hores -> dies)
        // const diff = (fecha2-fecha1)/(1000*60*60*24) 
        // toFixed(n) para mostrar n decimales
        const diff = ((data1-data2)/(86400000) ).toFixed(0)

        itemsData.push({          
          Id:                                           item.Id,
          PreventiveWeavingOthers_Comment:              item.Comment,
          PreventiveWeavingOthers_IsCritical:           item.IsCritical,
          PreventiveWeavingOthers_Shed:                 item.Shed,
          PreventiveWeavingOthers_Record:               item.Record,
          PreventiveWeavingOthers_UserResponsableId:    item.UserResponsableId,
          PreventiveWeavingOthers_UserResponsableName:  item.UserResponsableName,
          PreventiveWeavingOthers_MonthFrequency:       item.MonthFrequency,
          PreventiveWeavingOthers_Frequency:            item.Frequency,
          PreventiveWeavingOthers_NoticeDate:           item.NoticeDate.slice(0,10),
          PreventiveWeavingOthers_Information:          item.Description,
          PreventiveWeavingOthers_Level:                item.Level,
          PreventiveWeavingOthers_LastModificationDate: item.LastModificationDate && item.LastModificationDate.slice(0,10),
          PreventiveWeavingOthers_IdMaq:                item.MachineId ? item.MachineId : item.EquipmentId,      
          PreventiveWeavingOthers_EquipName:            item.MachineId ? item.MachineName : item.EquipmentName,    
          PreventiveWeavingOthers_Semafor:              diff,
          PreventiveWeavingOthers_PendingDays:          diff // Mostrara el resultado de calcular la diferencia entre dias
          // pendingDays: (diff < 0) ? // Mostrara el resultado de calcular la diferencia entre dias            
          //   diff
          // :
          //   diff  * (-1)
          // parking: item.Parking,
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 && (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  users: PropTypes.array,
  value: PropTypes.any
}