import SortIcon from 'assets/img/sort.png'
import FilterDown from 'components/icons/FilterDown'
import FilterUp from 'components/icons/FilterUp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { MdCheck, MdClose } from 'react-icons/md'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import MTableBar from './MTableBar'
import TableBar from './TableBar'

export default function Table({
  tableData,
  tableColumns,
  handleClick,
  isDashboard,
  isMaintenance,
  isMaintenanceOthers,
  isFinishedTS,
  downloadMatrixTableAsExcel
}) {
  const [getFilteredTreballadorValues, setFilteredTreballadorValues] = useState(
    []
  )
  const [getFilteredAreaValues, setFilteredAreaValues] = useState([])
  const [getFilteredValues, setFilteredValues] = useState([])
  const data = tableData
  const columns = tableColumns
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        filters: [
          { id: 'Jobs_IsActive', value: true },
          { id: 'Suggestions_IsActive', value: true }
        ]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    rows,
    prepareRow,
    filteredRows
  } = tableInstance

  const checkVersatilityLvl = (jobId, treballadorId) => {
    let versatilityLvl = null
    const foundVersatility = getFilteredValues.find(
      (v) =>
        v.Versatility_IdLlocTreball === jobId &&
        v.Versatility_IdTreb === treballadorId
    )

    foundVersatility !== null && foundVersatility !== undefined
      ? (versatilityLvl = foundVersatility.Versatility_Nivell)
      : (versatilityLvl = null)

    switch (versatilityLvl) {
      case '1':
        return 'I'
      // <div className="flex items-center justify-center">
      //   <p className="flex items-center justify-center w-6 h-6 font-bold border rounded-full text-md // border-emerald-300 bg-emerald-300"></p>
      // </div>
      case '2':
        return 'M'
      // <div className="flex items-center justify-center">
      //   <p className="flex items-center justify-center w-6 h-6 font-bold bg-green-600 border border-green-600 rounded-full text-md //">M</p>
      // </div>
      case '3':
        return 'F'
      // <div className="flex items-center justify-center">
      //   <p className="flex items-center justify-center w-6 h-6 font-bold border rounded-full text-md // border-amber-400 bg-amber-400">F</p>
      // </div>
      default:
        return ''
    }
  }

  const getRows = (rowsOuter) => {
    const rowsInner = rowsOuter
      .map((row) => row.original)
      .filter((row) => row.Versatility_IsRemoved)
      .sort((a, b) => a.Versatility_IdArea - b.Versatility_IdArea)

    setFilteredValues(rowsInner)

    if (rowsInner && rowsInner.length) {
      const uniqueTreballadorFilteredValues = Array.from(
        new Set(
          rowsInner.map((row) =>
            JSON.stringify({
              Id: row.Versatility_IdTreb,
              Name: row.Versatility_TrebName,
              IdArea: row.Versatility_TrebIdArea
            })
          )
        )
      ).map((item) => JSON.parse(item))
      uniqueTreballadorFilteredValues.sort((a, b) => a.IdArea - b.IdArea)

      let uniqueAreaFilteredValues = Array.from(
        new Set(
          rowsInner.map((row) =>
            JSON.stringify({
              Id: row.Versatility_IdArea,
              Name: row.Versatility_AreaName,
              Jobs: Object.values(
                rowsInner.reduce((jobs, row2) => {
                  if (
                    row2.Versatility_LlocTreballIdArea ===
                    row.Versatility_IdArea
                  ) {
                    jobs[row2.Versatility_LlocTreballName] = {
                      Id: row2.Versatility_IdLlocTreball,
                      Description: row2.Versatility_LlocTreballName
                    }
                  }
                  return jobs
                }, {})
              )
            })
          )
        )
      ).map((item) => JSON.parse(item))
      uniqueAreaFilteredValues = uniqueAreaFilteredValues.filter(
        (item) => item.Name !== null && item.Jobs.length > 0
      )
      uniqueAreaFilteredValues.sort((a, b) => a.IdArea - b.IdArea)

      setFilteredTreballadorValues(uniqueTreballadorFilteredValues)
      setFilteredAreaValues(uniqueAreaFilteredValues)
    } else {
      setFilteredTreballadorValues([])
      setFilteredAreaValues([])
    }
  }

  useEffect(async () => {
    if (
      getFilteredAreaValues !== null &&
      getFilteredAreaValues.length > 0 &&
      getFilteredTreballadorValues !== null &&
      getFilteredTreballadorValues.length > 0
    ) {
      const table = document.getElementById('versatility-table-to-xls')
      const rowsInner = table.querySelectorAll('tr.worker')
      const headerRow = table.querySelector('#header')
      const colsHeader = headerRow.querySelectorAll('th')
      const subHeaderRow = table.querySelector('#subHeader')
      const colsSubHeader = subHeaderRow.querySelectorAll('th')

      await downloadMatrixTableAsExcel(
        table,
        rowsInner,
        headerRow,
        colsHeader,
        subHeaderRow,
        colsSubHeader
      )

      setFilteredTreballadorValues([])
      setFilteredAreaValues([])
      setFilteredValues([])
    }
  }, [getFilteredAreaValues, getFilteredTreballadorValues])

  const { pageIndex, pageSize } = state

  return (
    <>
      <div className="flex flex-col">
        <div className="inline-block min-w-full align-middle">
          {/* Tabla: */}
          <table
            className="min-w-full border-2 border-red-800 table-auto"
            {...getTableProps()}
          >
            {/* Cabecera: */}
            <thead className="bg-red-800">
              {headerGroups.map((headerGroup) => (
                <tr
                  className="divide-x divide-red-700 divide-dashed"
                  key="header_key"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      className={`${
                        !column.disableFilters ? 'hover:bg-red-900' : ''
                      } px-2 py-2 text-white font-semibold text-sm font-ms text-left`}
                      key={column.id}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                    >
                      <div className="h-6 pt-1 print:border-0">
                        {column.render('Header')}

                        {column.isSorted &&
                          (!isDashboard ? (
                            column.isSortedDesc ? (
                              <FilterDown
                                width={10}
                                height={10}
                                className="inline-block ml-1 -mt-1"
                                fill="white"
                              />
                            ) : (
                              <FilterUp
                                width={10}
                                height={10}
                                className="inline-block ml-1 -mt-1"
                                fill="white"
                              />
                            )
                          ) : (
                            <img
                              className="inline-block w-5 h-5 ml-1 print:hidden"
                              src={SortIcon}
                              alt="arrows"
                            />
                          ))}
                      </div>

                      <div>{column.canFilter && column.render('Filter')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Cuerpo: */}
            <tbody
              className="bg-white divide-y divide-gray-200 divide-dashed border-x-2"
              {...getTableBodyProps()}
            >
              {page.map((row, index) => {
                prepareRow(row)

                return (
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                    className={` ${row.original.Read && 'bg-gray-100'}  
                      hover:bg-gray-200 cursor-pointer divide-x divide-dashed divide-gray-200
                    ${index % 2 === 0 && 'bg-gray-50'} `}
                  >
                    {row.cells.map((cell, index) => {
                      if (
                        cell.column.Header === 'ETT' ||
                        cell.column.Header === 'Fixe' ||
                        cell.column.Header === 'Vigent' ||
                        cell.column.Header === 'Temporal' ||
                        cell.column.Header === 'Respós' ||
                        cell.column.Header === 'Actiu' ||
                        cell.column.Header === 'Bobina'
                      ) {
                        return (
                          <td
                            className={`${
                              index === 0 ? 'font-lato-bold' : ''
                            } text-center align-middle`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {cell.value ? (
                              <div className="flex items-center justify-center">
                                <p className="flex items-center justify-center w-5 h-5 text-lg font-bold rounded-full bg-emerald-500">
                                  <MdCheck size={16} className="text-white" />
                                </p>
                              </div>
                            ) : (
                              <div className="flex items-center justify-center">
                                <p className="flex items-center justify-center w-5 h-5 text-lg font-bold bg-red-500 rounded-full">
                                  <MdClose size={16} className="text-white" />
                                </p>
                              </div>
                            )}
                          </td>
                        )
                      } else if (cell.column.Header === 'Nivell') {
                        return (
                          <td
                            className={`text-xs 2xl:text-sm pl-2 pr-2 py-2 print:py-1 whitespace-normal
                            ${cell.column.Header === 'Nom' && 'font-ms-semi'} 
                            ${
                              cell.column.Header === 'Especificacions bobina' &&
                              'whitespace-pre-wrap'
                            } 
                            print:text-xs print:whitespace-pre-wrap 
                            ${index === 0 ? 'font-lato-bold' : ''}`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {(() => {
                              switch (cell.value) {
                                case '1':
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-6 h-6 font-bold border-2 rounded-full text-md border-emerald-400 bg-emerald-300">
                                        I
                                      </p>
                                    </div>
                                  )
                                case '2':
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-6 h-6 font-bold bg-green-600 border-2 border-green-700 rounded-full text-md">
                                        M
                                      </p>
                                    </div>
                                  )
                                case '3':
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-6 h-6 font-bold border-2 rounded-full text-md border-amber-500 bg-amber-400">
                                        F
                                      </p>
                                    </div>
                                  )
                                default:
                                  return ''
                              }
                            })()}
                          </td>
                        )
                      } else if (cell.column.Header === '% Jornada') {
                        return (
                          <td
                            className={`text-xs 2xl:text-sm pl-2 pr-2 py-2 print:py-1 whitespace-normal 
                            ${cell.column.Header === 'Nom' && 'font-ms-semi'} 
                            ${
                              cell.column.Header === 'Especificacions bobina' &&
                              'whitespace-pre-wrap'
                            } 
                            print:text-xs print:whitespace-pre-wrap 
                            ${index === 0 ? 'font-lato-bold' : ''}`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')} %
                          </td>
                        )
                      } else if (cell.column.Header === 'Prioritat') {
                        return (
                          <td
                            className={`text-xs 2xl:text-sm pl-2 pr-2 py-2 print:py-1 whitespace-normal
                            ${cell.column.Header === 'Nom' && 'font-ms-semi'} 
                            ${
                              cell.column.Header === 'Especificacions bobina' &&
                              'whitespace-pre-wrap'
                            } 
                            print:text-xs print:whitespace-pre-wrap 
                            ${index === 0 ? 'font-lato-bold' : ''}`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {(() => {
                              switch (cell.value) {
                                case 1:
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-11/12 h-6 font-bold bg-red-200 border-2 border-red-300 rounded text-md">
                                        Alta
                                      </p>
                                    </div>
                                  )
                                case 2:
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-11/12 h-6 px-4 font-bold border-2 rounded text-md border-amber-300 bg-amber-200">
                                        Mitja
                                      </p>
                                    </div>
                                  )
                                case 3:
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-11/12 h-6 px-4 font-bold bg-green-200 border-2 border-green-300 rounded text-md">
                                        Baixa
                                      </p>
                                    </div>
                                  )
                                default:
                                  return ''
                              }
                            })()}
                          </td>
                        )
                      } else if (cell.column.Header === 'Estadi') {
                        return (
                          <td
                            className={`text-xs 2xl:text-sm pl-2 pr-2 py-2 print:py-1 whitespace-normal
                            ${cell.column.Header === 'Nom' && 'font-ms-semi'} 
                            ${
                              cell.column.Header === 'Especificacions bobina' &&
                              'whitespace-pre-wrap'
                            } 
                            print:text-xs print:whitespace-pre-wrap 
                            ${index === 0 ? 'font-lato-bold' : ''}`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {(() => {
                              switch (cell.value) {
                                case 1:
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-11/12 h-6 px-4 font-bold bg-green-200 border-2 border-green-300 rounded text-md">
                                        Obert
                                      </p>
                                    </div>
                                  )
                                case 2:
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-11/12 h-6 font-bold bg-red-200 border-2 border-red-300 rounded text-md">
                                        Tancat
                                      </p>
                                    </div>
                                  )
                                case 3:
                                  return (
                                    <div className="flex items-center justify-center">
                                      <p className="flex items-center justify-center w-11/12 h-6 px-4 font-bold border-2 rounded text-md border-amber-300 bg-amber-200">
                                        Pendent
                                      </p>
                                    </div>
                                  )
                                default:
                                  return ''
                              }
                            })()}
                          </td>
                        )
                      } else if (
                        cell.column.Header === 'Preu' ||
                        cell.column.Header === 'Hores'
                      ) {
                        return (
                          <td
                            className={`text-xs 2xl:text-sm pl-2 pr-2 py-2 print:py-1 whitespace-normal
                            ${cell.column.Header === 'Nom' && 'font-ms-semi'} 
                            ${
                              cell.column.Header === 'Especificacions bobina' &&
                              'whitespace-pre-wrap'
                            } 
                            print:text-xs print:whitespace-pre-wrap 
                            ${index === 0 ? 'font-lato-bold' : ''}`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {cell.value !== null && (
                              <div className="flex items-center justify-center">
                                <p className="flex items-center justify-center w-11/12 h-6 font-bold bg-gray-200 border-2 border-gray-300 rounded text-md">
                                  {cell.value}{' '}
                                  {cell.column.Header === 'Preu' ? '€' : 'h'}
                                </p>
                              </div>
                            )}
                          </td>
                        )
                      } else {
                        return (
                          <td
                            className={`text-xs 2xl:text-sm pl-2 pr-2 py-2 print:py-1 whitespace-normal 
                            ${cell.column.Header === 'Nom' && 'font-ms-semi'} 
                            ${
                              cell.column.Header === 'Especificacions bobina' &&
                              'whitespace-pre-wrap'
                            } 
                            print:text-xs print:whitespace-pre-wrap 
                            ${index === 0 ? 'font-lato-bold' : ''}`}
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>

            {/* Pie: */}
            <tfoot>
              <tr key="footer_key">
                <td colSpan={columns.length} className="p-0 m-0">
                  {isMaintenance ? (
                    <MTableBar
                      previousPage={previousPage}
                      canPreviousPage={canPreviousPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      nextPage={nextPage}
                      canNextPage={canNextPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      rows={rows}
                      isMaintenanceOthers={isMaintenanceOthers}
                    />
                  ) : !isFinishedTS ? (
                    <TableBar
                      previousPage={previousPage}
                      canPreviousPage={canPreviousPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      nextPage={nextPage}
                      canNextPage={canNextPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      rows={rows}
                      getRows={getRows}
                    />
                  ) : (
                    <TableBar
                      previousPage={previousPage}
                      canPreviousPage={canPreviousPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      nextPage={nextPage}
                      canNextPage={canNextPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                    />
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      {getFilteredAreaValues !== null &&
        getFilteredAreaValues.length > 0 &&
        getFilteredTreballadorValues !== null &&
        getFilteredTreballadorValues.length > 0 &&
        getFilteredValues !== null &&
        getFilteredValues.length > 0 && (
          <table
            id="versatility-table-to-xls"
            className="hidden w-full mt-10 table-auto"
          >
            <thead className="bg-red-200">
              <tr key="header_tr_empty_1"></tr>
              <tr key="header_tr_empty_2"></tr>
              <tr id="header" key="header_tr_full_1">
                <th key="header_tr_full_1_th_empty_1"></th>
                {getFilteredAreaValues !== null &&
                  getFilteredAreaValues !== undefined &&
                  getFilteredAreaValues.length > 0 &&
                  getFilteredAreaValues.map((area, i) => (
                    <th
                      key={'header_tr_full_1_th_' + area.Id + '_full_' + i}
                      colSpan={area.Jobs.length}
                      data-area={area.Id}
                    >
                      {area.Name}
                    </th>
                  ))}
              </tr>
              <tr id="subHeader" key="header_tr_full_2">
                <th key="header_tr_full_2_th_empty_1"></th>
                {getFilteredAreaValues !== null &&
                  getFilteredAreaValues !== undefined &&
                  getFilteredAreaValues.length > 0 &&
                  getFilteredAreaValues.map((area, i) => {
                    if (
                      area.Jobs !== null &&
                      area.Jobs !== undefined &&
                      area.Jobs[0] !== null &&
                      area.Jobs.length > 0
                    ) {
                      return area.Jobs.map((job, i2) => (
                        <th
                          key={
                            'header_tr_full_2_th_' +
                            area.Id +
                            '_' +
                            job.Id +
                            '_full_' +
                            i2
                          }
                          data-area={area.Id}
                        >
                          {job.Description}
                        </th>
                      ))
                    } else {
                      return (
                        <th
                          key={'header_tr_full_2_th_' + area.Id + '_full_' + i}
                          data-area={area.Id}
                        ></th>
                      )
                    }
                  })}
              </tr>
            </thead>

            <tbody>
              {getFilteredTreballadorValues !== null &&
                getFilteredTreballadorValues !== undefined &&
                getFilteredTreballadorValues.length > 0 &&
                getFilteredAreaValues !== null &&
                getFilteredAreaValues !== undefined &&
                getFilteredAreaValues.length > 0 &&
                getFilteredTreballadorValues.map((treballador, i) => (
                  <tr key={'body_tr_full_' + i} className="worker">
                    <td
                      id="workerName"
                      key={'body_tr_full_td_full' + i}
                      data-area={treballador.IdArea}
                    >
                      {treballador.Name}
                    </td>

                    {getFilteredAreaValues.map((area) => {
                      if (
                        area.Jobs !== null &&
                        area.Jobs !== undefined &&
                        area.Jobs[0] !== null &&
                        area.Jobs.length > 0
                      ) {
                        return area.Jobs.map((job, i) => (
                          <td key={'body_tr_full_td_full' + (i + 1)}>
                            {checkVersatilityLvl(job.Id, treballador.Id)}
                          </td>
                        ))
                      } else {
                        return <td></td>
                      }
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </>
  )
}

Table.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  isDashboard: PropTypes.bool,
  txtHours: PropTypes.any,
  txtMinutes: PropTypes.any,
  isMaintenance: PropTypes.bool,
  isMaintenanceOthers: PropTypes.bool,
  isFinishedTS: PropTypes.bool,
  downloadMatrixTableAsExcel: PropTypes.func
}
