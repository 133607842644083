import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import { Field } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { CgSpinner } from 'react-icons/cg'
import Select from 'react-select'
import ThreadTable from './threadTableCreate'

export default function DescriptionForm({
  setFieldValue,
  values,
  threads,
  allValues
}) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()
  const [getApiCallGetMachines, setApiCallMachines] = useFetch()
  const [getApiCallGetThreads, setApiCallThreads] = useFetch()

  // Vars
  const optionsYesNo = [
    { value: true, label: 'Si' },
    { value: false, label: 'No' }
  ]

  if (
    values?.SecurityStepDescription === undefined ||
    !values?.SecurityStepDescription
  )
    values.SecurityStepDescription = 'Segons cartell'

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    // CRUD: GET (Machines):
    const apiCallGetMachines = {
      url: `tsweaving/machines`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    // CRUD: GET (Threads):
    const apiCallGetThreads = {
      url: `thread`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([
      setApiCallSemiFinished(apiCallGetSemiFinished),
      setApiCallMachines(apiCallGetMachines),
      setApiCallThreads(apiCallGetThreads)
    ]).catch((e) => {
      console.log(e)
    })
  }, [])

  // -------------------------------

  const updateSheet = () => {
    // #################################################################################################
    // Inicio cálculos de 'Vueltas'
    // #################################################################################################
    const oldKgPart = allValues?.RequestArticle?.HistoryArticle?.KgPart

    // Recogemos todas las máquinas relacionadas con el 'id' especificado y sumamos los 'Kg' por 'Hora'
    // de todas las máquinas que se devuelvan, juntas:
    let sumKgH100 = 0
    allValues?.RequestMachineGroups?.forEach((machine, index) => {
      if (!machine.HasBeenRemoved) {
        const updatedMachineValue = updateMachine(machine, index)

        // Redondeamos a 10 para que todos los cálculos se hagan con 10 decimales:
        const roundedMachineGroupKgH = roundToDecimals(
          updatedMachineValue.KgH,
          10
        )
        sumKgH100 = roundToDecimals(sumKgH100 + roundedMachineGroupKgH, 10)
      }
    })

    // Recogemos y guardamos 'RPM' por si está en un formato incorrecto que afecte los cálculos.
    const rpmValue = allValues?.RequestArticle?.Rpm || '0'
    const rpm = parseFloat(rpmValue?.toString()?.replace(/,/g, '.') ?? 0)
    const formattedRpm = rpm % 1 === 0 ? rpm.toFixed(0) : rpm.toFixed(1)
    setFieldValue('RequestArticle.Rpm', formattedRpm)

    // Recogemos y guardamos 'KgPart' por si está en un formato incorrecto que afecte los cálculos:
    const partKgValue = allValues?.RequestArticle?.KgPart || '0'
    const partKg = roundToDecimals(parseFloat(partKgValue).toFixed(1), 1)
    setFieldValue('RequestArticle.KgPart', partKg)

    // Si sumKgH100 > 0, indica que hay máquinas especificadas en la tabla de 'Graduació del punt i consum', y que los campos 'RPM' y 'Kg peça' en 'Descripció' están definidos y son mayores que 0:
    if (sumKgH100 > 0) {
      // Convertimos la suma de 'Kg' por 'Hora' de las máquinas que se nos devuelve de la Base de Datos
      // a un número con 2 decimales y otro con 1 decimal:
      const roundedSumKgH10Decimals = roundToDecimals(sumKgH100, 10)

      if (rpm !== 0) {
        // Total 'Vueltas' por 'Kg' y por 'Pieza'
        const turnsPerKg = roundToDecimals(
          (rpm * 60) / roundedSumKgH10Decimals,
          10
        )
        const turnsPerPart = roundToDecimals(turnsPerKg * partKg, 10)

        setFieldValue(
          'RequestArticle.TurnsPerKg',
          roundToDecimals(
            Number(turnsPerKg?.toString().replace(/,/g, '.') ?? 0),
            0
          )
        )
        setFieldValue(
          'RequestArticle.TurnsPerPart',
          roundToDecimals(
            Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0),
            0
          )
        )

        if (oldKgPart !== allValues?.RequestArticle?.KgPart) {
          setFieldValue(
            'RequestArticle.TurnsPerPartByOperator',
            roundToDecimals(
              Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0),
              0
            )
          )
        }
      } else {
        setFieldValue('RequestArticle.TurnsPerKg', '0')
        setFieldValue('RequestArticle.TurnsPerPart', '0')
        setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
      }

      // #################################################################################################
      // Fin cálculos de 'Vueltas'
      // #################################################################################################

      // #################################################################################################
      // Inicio cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
      // #################################################################################################

      // Total 'Kg' por 'Hora' cuando la máquina está al 100% y redondeamos a 10 decimales:
      const totalKgH100 = roundToDecimals(roundedSumKgH10Decimals, 2)

      // Total 'Kg' por 'Hora' cuando la máquina está al 80% y redondeamos a 10 decimales:
      const totalKgH80 = roundToDecimals(roundedSumKgH10Decimals * 0.8, 2)

      // Total 'Kg' por 'Dia' cuando la máquina está al 100% y redondeamos a 10 decimales:
      const totalKgD100 = roundToDecimals(roundedSumKgH10Decimals * 24, 2)

      // Total 'Kg' por 'Dia' cuando la máquina está al 80% y redondeamos a 10 decimales:
      const totalKgD80 = roundToDecimals(roundedSumKgH10Decimals * 24 * 0.8, 2)

      setFieldValue('RequestArticle.KgH_100', totalKgH100.toString())
      setFieldValue('RequestArticle.KgH_80', totalKgH80.toString())
      setFieldValue('RequestArticle.KgD_100', totalKgD100.toString())
      setFieldValue('RequestArticle.KgD_80', totalKgD80.toString())

      // #################################################################################################
      // Fin cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
      // #################################################################################################
    }
    // Si sumKgH100 <= 0, indica que no hay máquinas en 'Graduació del punt i consum' o que los campos 'RPM' o 'Kg peça' en 'Descripció' no están definidos o son 0:
    else {
      setFieldValue('RequestArticle.TurnsPerKg', '0')
      setFieldValue('RequestArticle.TurnsPerPart', '0')
      setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
      setFieldValue('RequestArticle.KgH_100', '0')
      setFieldValue('RequestArticle.KgH_80', '0')
      setFieldValue('RequestArticle.KgD_100', '0')
      setFieldValue('RequestArticle.KgD_80', '0')
    }
  }

  const updateMachine = (currentMachineGroup, index) => {
    // Recogemos el número de 'Agujas':
    const needles = getNumber(allValues?.RequestArticle?.Needle || '0')
    setFieldValue('RequestArticle.Needle', needles.toString())

    // Recogemos y guardamos 'RPM' por si está en un formato incorrecto que afecte los cálculos.
    const rpmValue = allValues?.RequestArticle?.Rpm || '0'
    const rpm = parseFloat(rpmValue?.toString()?.replace(/,/g, '.') ?? 0)
    const formattedRpm = rpm % 1 === 0 ? rpm.toFixed(0) : rpm.toFixed(1)
    setFieldValue('RequestArticle.Rpm', formattedRpm)

    // Calculation LFA
    const LFA =
      currentMachineGroup?.Spending && needles
        ? roundToDecimals((currentMachineGroup.Spending / needles) * 100, 2)
        : 0

    // Recogemos los campos 'Title' y 'Heads'
    const numCabos = currentMachineGroup?.Heads
    const numTitle = getNumber(currentMachineGroup?.Title ?? 0)

    // Comprobamos la unidad de medida de la máquina (TEX, DTEX, DNS, NM, NE, NA o NB):
    const measureUnitTitle =
      currentMachineGroup?.Title?.split(' ').pop()?.toUpperCase() || ''

    // Fórmulas según Excel: Cálculo producciones (Tickets relacionados: #29473, #33208, #89001).
    // - Más información sobre las fórmulas y las conversiones en el Jedi: https://jedi.gestinet.com/books/cetriko/page/formules-de-produccio-fitxes-tecniques-teixiduria
    const conversionMap = {
      TEX: numTitle * numCabos,
      DTEX: (numTitle * numCabos) / 10,
      DNS: (numTitle * numCabos) / 9,
      NM: 1000 / (numTitle / numCabos),
      NE: (453.6 / 768) * (1000 / (numTitle / numCabos)),
      NA: (440 / 777.5) * (1000 / (numTitle / numCabos)),
      NB: (8.33 / 777.5) * (1000 / (numTitle / numCabos))
    }

    const convertToTex = roundToDecimals(
      conversionMap[measureUnitTitle?.toUpperCase()] || 0,
      10
    )

    // Ticket relacionat: #33208
    // Función nueva para calcular Kg/h y redondeamos a 10 decimales:
    const updateKgH =
      rpm !== 0
        ? roundToDecimals(
            (convertToTex *
              currentMachineGroup?.Spending *
              rpm *
              60 *
              currentMachineGroup?.Cones) /
              100000000,
            10
          )
        : 0

    currentMachineGroup.KgH = updateKgH
    currentMachineGroup.LFA = LFA

    allValues.RequestMachineGroups[index] = currentMachineGroup
    setFieldValue(
      'RequestArticle.RequestMachineGroups',
      allValues.RequestMachineGroups
    )

    return currentMachineGroup
  }

  const getNumber = (text) => {
    let b = '' // Initialize an empty string to collect digits
    let val = 0 // Initialize the return value to 0

    // Loop through each character in the input text
    for (let i = 0; i < text.length; i++) {
      // Check if the character is a digit
      if (/\d/.test(text[i])) {
        b += text[i] // Append digit to string `b`
      }
    }

    // If `b` contains any digits, parse it to an integer
    if (b.length > 0) {
      val = parseInt(b, 10)
    }

    return val // Return the parsed number
  }

  const roundToDecimals = (number, decimals) => {
    const factor = Math.pow(10, decimals)
    return Math.round(number * factor) / factor
  }

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Semielaborat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Semielaborat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.SemiFinished"
          className="w-full block-input"
        />
      </div>

      {/* Tipus lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus lligat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        {!getApiCallGetSemiFinished?.data ? (
          <div className="w-full px-2 py-1 bg-gray-100 border border-gray-300 rounded-sm">
            <CgSpinner size={20} className="text-charcoal animate-spin" />
          </div>
        ) : (
          <Field
            type="text"
            className="w-full block-input-disabled"
            value={
              getApiCallGetSemiFinished?.data?.find(
                (option) => option.semi === values.SemiFinished
              )?.ligado
            }
            disabled
          />
        )}
      </div>

      {/* Tabla: Ideal | Tol.Màx. */}
      <div className="grid grid-cols-12 col-span-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                Ideal
              </div>

              <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                Tol. Màx.
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Passades': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Passades:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Repetitions"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RepetitionsTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.RepetitionsV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RepetitionsToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Columnes': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Columnes:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Columns"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ColumnsTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.ColumnsV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ColumnsToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gramatge': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gramatge:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Weight"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WeightTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.WeightV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WeightToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>g/m2</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Ample': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Ample:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Width"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WidthTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.WidthV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WidthToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gruix': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gruix:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Thickness"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ThicknessTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.ThicknessV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ThicknessToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>mm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Rdt Nominal': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Rdt Nominal:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.RDTNominal"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RDTNominalTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.RDTNominalV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RDTNominalToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>m/kg</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Comentari': */}
        <div className="grid h-full grid-cols-12 col-span-12">
          <div className="block col-span-12 my-1 text-sm text-grey-darker font-ms-semi">
            Comentari:
          </div>

          <Field
            as="textarea"
            name="RequestArticle.LaboratoryComment"
            className="col-span-12 text-sm whitespace-pre-wrap resize-none block-input h-52"
          />
        </div>
      </div>

      {/* Lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Lligat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TiedDescription"
          className="w-full block-input"
        />
      </div>

      {/* Diagrama: */}
      <div className="flex col-span-12 rounded">
        <p className="mr-1 text-right">
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
          10
          <br />
          11
          <br />
          12
          <br />
        </p>

        <Field
          as="textarea"
          name="RequestArticle.EncryptedCode"
          className="w-full px-2 py-1 pt-0 overflow-hidden leading-6 whitespace-pre-line bg-blue-100 border border-gray-300 rounded-sm appearance-none resize-none focus:outline-none focus:shadow-outline faden hover:border-gray-400 focus:border-gray-400"
        />

        <div
          onClick={() =>
            handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
          }
          className="cursor-pointer"
          style={{ height: 'fit-content' }}
        >
          <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
        </div>
      </div>

      {/* Màquina */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Màquina:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        {!getApiCallGetMachines?.data ? (
          <div className="w-4/12 px-2 py-1 bg-gray-100 border border-gray-300 rounded-sm">
            <CgSpinner size={20} className="text-charcoal animate-spin" />
          </div>
        ) : (
          <Select
            className="flex-grow w-4/12 leading-tight text-grey-darker"
            onChange={(e) => {
              setFieldValue('RequestArticle.Machine', e.desc_sec)
              setFieldValue('RequestArticle.MachineCode', e.cod_maq)
              setFieldValue('RequestArticle.FoldType', e.plegador)
            }}
            name="RequestArticle.MachineCode"
            placeholder="Seleccionar..."
            options={getApiCallGetMachines?.data}
            getOptionLabel={(o) => o.cod_maq}
            getOptionValue={(o) => o.cod_maq}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: '0.125rem',
                cursor: 'pointer',
                boxShadow: 'none',
                minHeight: 'auto',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                '&:hover': {
                  borderColor: '#9CA3AF'
                }
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                height: '100%',
                padding: '0.25rem 0.5rem',
                position: 'static',
                cursor: 'pointer',
                rowGap: '0.25rem'
              }),
              multiValue: (baseStyles) => ({
                ...baseStyles,
                height: '100%',
                margin: '0 0.25rem 0 0',
                alignItems: 'center',
                cursor: 'pointer'
              }),
              input: (baseStyles) => ({
                ...baseStyles,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: '0',
                paddingTop: '0',
                paddingBottom: '0',
                cursor: 'pointer'
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                marginLeft: '0',
                marginRight: '0'
              }),
              indicatorsContainer: (baseStyles) => ({
                ...baseStyles,
                cursor: 'pointer'
              }),
              dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                padding: '0px 8px'
              })
            }}
            value={getApiCallGetMachines?.data?.find(
              (option) => option.cod_maq === values.MachineCode
            )}
          />
        )}

        {!getApiCallGetMachines?.data ? (
          <div className="w-8/12 px-2 py-1 bg-gray-100 border border-gray-300 rounded-sm">
            <CgSpinner size={20} className="text-charcoal animate-spin" />
          </div>
        ) : (
          <Field
            type="text"
            name="RequestArticle.Machine"
            className="w-8/12 block-input-disabled"
            disabled
          />
        )}
      </div>

      {/* Plegador */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Plegador:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FoldType"
          className="w-full block-input"
        />
      </div>

      {/* GG - ø */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        GG - ø:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.GG"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />

        <Field
          type="text"
          name="RequestArticle.O"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* Nº Jocs */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nº Jocs:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.NGame"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* Agulles */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Agulles:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Needle"
          onBlur={() => updateSheet()}
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* Rpm */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Rpm"
          onBlur={() => updateSheet()}
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* Rpm Mín. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm Mín.:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.RpmMin"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* V. / Kg */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Kg:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TurnsPerKg"
          className="w-full block-input-disabled"
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.TurnsPerKgTolerance"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* V. / Peça (Reals) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Reals):
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TurnsPerPart"
          className="w-full block-input-disabled"
          disabled
        />
      </div>

      {/* V. / Peça (Fitxa) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Fitxa):
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TurnsPerPartByOperator"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.TurnsPerPartByOperatorTolerance"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* Kg peça */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Kg peça:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.KgPart"
          onBlur={() => updateSheet()}
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.KgPartTolerance"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className="w-full block-input"
        />
      </div>

      {/* Pass. Seguretat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Pass. Seguretat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Select
          className={`flex-grow ${
            values?.SecurityStep === 'true' || values?.SecurityStep
              ? 'w-4/12'
              : 'w-full'
          } leading-tight text-grey-darker`}
          onChange={(e) => {
            setFieldValue('RequestArticle.SecurityStep', e.value)
          }}
          name="RequestArticle.SecurityStep"
          placeholder="Seleccionar..."
          options={optionsYesNo}
          getOptionLabel={(o) => o.label}
          getOptionValue={(o) => o.value}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: '0.125rem',
              cursor: 'pointer',
              boxShadow: 'none',
              minHeight: 'auto',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
              '&:hover': {
                borderColor: '#9CA3AF'
              }
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              padding: '0.25rem 0.5rem',
              position: 'static',
              cursor: 'pointer',
              rowGap: '0.25rem'
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              margin: '0 0.25rem 0 0',
              alignItems: 'center',
              cursor: 'pointer'
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '0',
              paddingBottom: '0',
              cursor: 'pointer'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              marginLeft: '0',
              marginRight: '0'
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              cursor: 'pointer'
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '0px 8px'
            })
          }}
          value={optionsYesNo?.find(
            (option) => option.value === values.SecurityStep
          )}
        />

        {(values?.SecurityStep === 'true' || values?.SecurityStep) && (
          <Field
            type="text"
            name="RequestArticle.SecurityStepDescription"
            className="w-8/12 block-input"
          />
        )}
      </div>

      {/* Tabla: Hilos */}
      <div className="w-full col-span-12">
        <ThreadTable
          threads={threads}
          values={values}
          threadOptions={getApiCallGetThreads?.data}
        />
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  threads: PropTypes.any,
  setRequestFieldsState: PropTypes.func,
  allValues: PropTypes.object
}
