import logoGRS from 'assets/img/logo-GRS.png'
import Block from 'components/FTAcabats/Details/Block/Block'
import CloneFTModal from 'components/FTAcabats/Details/CloneFTModal'
import Rams from 'components/FTAcabats/Details/Rams/Rams'
import Sign from 'components/FTAcabats/Details/Sign/Sign'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineHistory,
  AiOutlinePrinter
} from 'react-icons/ai'
import { FaTimes, FaCheck } from 'react-icons/fa'
import { CgSpinner } from 'react-icons/cg'
import { MdContentCopy, MdEdit } from 'react-icons/md'
import { Link, useLocation } from 'wouter'

export default function FTAcabatsDetail({ params }) {
  // Context:
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallGetRequest, setApiCallGetRequest] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()

  // State:
  const [canRequest, setCanRequest] = useState(false)
  const [canCopy, setCanCopy] = useState(false)
  const [canCheckHistory, setCanCheckHistory] = useState(false)
  const [getIsGRS, setIsGRS] = useState(false)
  const [options, setOptions] = useState([])
  const [users, setUsers] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // Vars
  const cloneFT = async (e, idToClone) => {
    e.preventDefault()
    const apiCallPost = {
      url: `technicalsheet/clone/${params.id}/${idToClone} `,
      method: 'POST',
      messageOk: 'FT clonada!',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    await setApiCallPost(apiCallPost)
    setLocation('/ft-acabats')
  }
  const [getFieldsState, setFieldsState] = useState({
    Id: 0,
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Descripció':
    Article: '',
    ArticleDescription: '',
    Status: '',
    Width: '',
    SemiFinished: '',
    SemiFinishedDescription: '',
    Client: '',
    Composition: '',
    IsDryer: false,
    ClientManager: '',
    EditionNumber: 1,
    Reason: '',
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Màquina d'obrir':
    Folding: '',
    OpenWith: '',
    IsOpenedReverse: false,
    Follower: '',
    ObservationMachine: '',
    SewingType: '',
    PowerUltrasound: '',
    SpeedUltrasound: '',
    PressureUltrasound: '',
    PowerUltrasoundTolerance: '',
    SpeedUltrasoundTolerance: '',
    PressureUltrasoundTolerance: '',
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Vores':
    Glue: '',
    GlueProportion: '',
    GlueColor: '',
    GlueMachine: '',
    HasToCutEdge: false,
    Cuters: '',
    AlignedSides: '',
    EdgeObservation: '',
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Acabats foulard':
    FoulardName: '',
    FoulardFormula: '',
    FoulardRecipeNumber: '',
    FoulardObservation: '',
    IsDosificated: false,
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Controls':
    ControlStyle: '',
    VMT: '',
    MAN: '',
    OMT: '',
    AUT: '',
    ControlTemperature: '',
    ControlTime: '',
    ControlObservation: '',
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Embalatge':
    TubeSize: '',
    TubName: '',
    HasDoubleSidedTape: false,
    FinalCoilFixation: '',
    PlasticType: '',
    IsPolyethyleneFilm: false,
    HasCorners: false,
    PrecinctColor: '',
    TagNumber: '',
    TagDescription: '',
    Others: '',
    PackagingObservation: '',
    // --------------------------------------------------------------------------------------
    // Campos apartado 'Característiques':
    OutputWidth: '',
    Performance: '',
    PerformanceTolerance: '',
    Weight: '',
    WeightTolerance: '',
    LinearWeight: '',
    Meters: '',
    MeterTolerance: '',
    IsAutomatedCut: false,
    IsReverseCoil: false,
    Seams: '',
    SeamsObservation: '',
    IsSeamsMarked: false,
    ReviewFrequence: '',
    ReviewFrequenceId: '',
    LabSample: '',
    ClientSmallCoil: 'NO',
    ClientNotes: '',
    FeaturesObservation: '',
    // --------------------------------------------------------------------------------------
    IsOperatorHidden: false,
    EditionDate: '',
    ReasonModification: '',
    TechnicalSheetRams: [],
    TechnicalSheetDocuments: []
  })
  const now = new Date()
  const day = now.getDate().toString().padStart(2, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const year = now.getFullYear()
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}h`

  // ###########################################

  // Get api calls
  useEffect(() => {
    const title = {
      name: `Fitxa Tècnica`,
      buttons: [
        {
          name: 'Tornar',
          link: '/ft-acabats'
        }
      ]
    }
    setTitle(title)

    // Save all technicalsheet article codes
    const localDataFT = JSON.parse(localStorage.getItem('ftAcabats') || '[]')
    if (localDataFT.length > 0) {
      setOptions(
        localDataFT.map((acabat) => {
          return {
            value: acabat.articleCode,
            label: acabat.description + ' ' + acabat.client
          }
        })
      )
    }

    const apiCallGetUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP!'
    }
    setApiCallGetUsers(apiCallGetUsers)
  }, [])

  // Get users api call response
  useEffect(() => {
    if (getApiCallGetUsers.data && !users) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get user api call response
  useEffect(() => {
    if (getApiCallGetUser.data && !currentUser) {
      setCurrentUser(getApiCallGetUser.data)

      const apiCallGet = {
        url: `technicalsheet/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  // Get technicalsheet api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Article === '') {
      const article = getApiCallGet.data?.Article

      const apiCallGetRequest = {
        url: `technicalsheet/request/last/${article}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetRequest(apiCallGetRequest)
    }
  }, [getApiCallGet.data, currentUser])

  // Get technicalsheet request api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Article === '') {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))

      const clientManagerTrim = apiData?.ClientManager?.replace(' ', '')
      const clientManagerSplit = clientManagerTrim?.split(',') ?? []
      apiData.ClientManager = clientManagerSplit
      setFieldsState(apiData)

      apiData?.ArticleDescription.includes('/FRC')
        ? setIsGRS(true)
        : setIsGRS(false)

      // Permissions
      let allow = false
      const userRol = user.Role.Id
      let articleEnterprice = null
      const canRequestCetriko = currentUser?.CanCreateFinishedRequestForCetriko
      const canRequestCervera = currentUser?.CanCreateFinishedRequestForCervera
      const isC = getApiCallGet.data?.IsFromCetriko
      const isQ = getApiCallGet.data?.IsFromCervera

      if (isC && !isQ) {
        articleEnterprice = 'C'
      } else if (!isC && isQ) {
        articleEnterprice = 'Q'
      } else {
        articleEnterprice = 'C + Q'
      }

      if (userRol === 1) {
        if (
          articleEnterprice === 'C + Q' &&
          (canRequestCetriko || canRequestCervera)
        ) {
          allow = true
        } else if (articleEnterprice === 'C' && canRequestCetriko) {
          allow = true
        } else if (articleEnterprice === 'Q' && canRequestCervera) {
          allow = true
        } else {
          allow = false
        }
      } else {
        allow = false
      }

      setCanRequest(allow)

      // -----------------------------------

      let allowCheckHistory = false

      if (userRol === 1) {
        allowCheckHistory = true
      } else {
        allowCheckHistory = false
      }

      setCanCheckHistory(allowCheckHistory)

      // -----------------------------------

      let allowCopy = false

      if (userRol === 1) {
        allowCopy = currentUser?.CanCopyFinishedTechnicalSheet
      } else {
        allowCopy = false
      }

      setCanCopy(allowCopy)
      // Permissions
    }
  }, [getApiCallGetRequest.data])

  return users !== null && options !== null && getFieldsState.Article ? (
    <>
      {/* Options bar */}
      <div
        className="sticky top-0 z-50 flex items-end justify-between w-full col-span-2 px-4 py-3 text-sm uppercase border-b-2 print:hidden border-charcoal"
        style={{ backdropFilter: 'blur(25px)' }}
      >
        {/* AMAGADA / VISIBLE */}
        <div className="inline-flex items-start space-x-2">
          {canRequest && (
            <div className="inline-flex items-center justify-between h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-blue-200 rounded w-fit text-md font-ms-bold">
              {getFieldsState.IsOperatorHidden ? (
                <AiOutlineEyeInvisible className="xl:mr-2" size={25} />
              ) : (
                <AiOutlineEye className="xl:mr-2" size={25} />
              )}
              {getFieldsState.IsOperatorHidden
                ? 'Amagada per Operaris'
                : 'Visible per Operaris'}
            </div>
          )}
        </div>

        {/* COPIAR / IMPRIMIR / HISTORIAL / SOL·LICITAR */}
        <div className="inline-flex items-start space-x-2">
          {canCopy && (
            <button
              type="button"
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
              onClick={() =>
                handleModal(
                  <CloneFTModal
                    cloneFT={cloneFT}
                    closeModal={() => handleModal('hide')}
                    options={options}
                    currentArticleCode={getFieldsState.Article}
                    currentArticleDescription={getFieldsState.ArticleDescription}
                  />
                )
              }
            >
              <MdContentCopy className="xl:mr-2" size={22} />
              <span className="hidden xl:inline-flex">Copiar</span>
            </button>
          )}

          <button
            type="button"
            className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
            onClick={() => window.print()}
          >
            <AiOutlinePrinter className="xl:mr-2" size={25} />
            <span className="hidden xl:inline-flex">Imprimir</span>
          </button>

          {canCheckHistory && (
            <Link
              to={`/ft-acabats/requests/${params.id}`}
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center uppercase transition duration-300 bg-white border-2 rounded d-flex text-amber-600 border-amber-600 text-md font-ms-bold hover:bg-amber-600 hover:text-white print:hidden"
            >
              <AiOutlineHistory className="xl:mr-2" size={22} />
              <span className="hidden xl:inline-flex">Historial</span>
            </Link>
          )}

          {canRequest && (
            <Link
              to={`/ft-acabats/request-create/${encodeURIComponent(
                getFieldsState.Article
              )}`}
              className="relative inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-teal-500 uppercase transition duration-300 bg-white border-2 border-teal-500 rounded text-md font-ms-bold hover:bg-teal-500 hover:text-white print:hidden"
            >
              <MdEdit className="xl:mr-2" size={22} />
              <span className="hidden xl:inline-flex">Sol·licitar</span>
              {/* <span className="absolute top-0 right-0 w-5 h-5 transform translate-x-1/2 -translate-y-1/2 bg-red-400 border-2 border-white rounded-full"></span> */}
            </Link>
          )}
        </div>
      </div>

      {/* Data */}
      <div className="p-4 space-y-2 print:p-0">
        {/* FITXA TÈCNICA */}
        <div
          style={{
            pageBreakAfter: 'always',
            pageBreakInside: 'avoid'
          }}
        >
          {/* VISIBLE EN IMPRESIÓN */}
          <h1 className="hidden w-full print:flex print:justify-around print:items-center print:pb-1 print:m-0">
            <span className="flex items-center justify-center h-full text-2xl font-ms-bold">
              FITXA TÈCNICA ACABATS
            </span>

            <span className="flex flex-row items-center justify-end h-full space-x-6">
              <span className="flex flex-col items-start justify-center h-full text-base">
                <span>{formattedDateTime}</span>
                <span>Format-IATF-013-B</span>
              </span>

              <span>
                {getIsGRS === true && (
                  <img src={logoGRS} width={90} height={90} />
                )}
              </span>
            </span>
          </h1>

          <div className="relative grid grid-flow-row grid-cols-12 gap-2 print:gap-1 auto-rows-max">
            {/* CETRIKO */}
            <div
              className={`col-span-6 inline-flex items-center justify-start p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-blue-200 rounded shadow-md w-fit text-sm font-ms-bold print:hidden`}
            >
              {getFieldsState.IsFromCetriko ? (
                <FaCheck className="text-teal-500 xl:mr-2" size={18} />
              ) : (
                <FaTimes className="text-red-500 xl:mr-2" size={18} />
              )}
              <span>Cetriko</span>
            </div>

            {/* CERVERA */}
            <div
              className={`col-span-6 inline-flex items-center justify-start p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-blue-200 rounded shadow-md w-fit text-sm font-ms-bold print:hidden`}
            >
              {getFieldsState.IsFromCervera ? (
                <FaCheck className="text-teal-500 xl:mr-2" size={18} />
              ) : (
                <FaTimes className="text-red-500 xl:mr-2" size={18} />
              )}
              <span>Cervera</span>
            </div>

            {/* DESCRIPCIÓ */}
            <Block
              title="Descripció"
              className="flex flex-col col-span-12"
              users={users}
              values={getFieldsState}
            />

            {/* CARACTERÍSTIQUES */}
            <Block
              title="Característiques"
              className="flex flex-col col-span-6"
              users={users}
              values={getFieldsState}
            />

            <div className={`col-span-6 flex flex-col space-y-2`}>
              {/* MÀQUINA D'OBRIR */}
              <Block
                title="Màquina d'obrir"
                className="flex flex-col flex-grow col-span-12"
                users={users}
                values={getFieldsState}
              />

              {/* MÀQUINA D'OBRIR */}
              <Block
                title="Reglatges ultrasons"
                className="flex flex-col col-span-12"
                users={users}
                values={getFieldsState}
              />
            </div>

            {/* ACABATS FOULARD */}
            <Block
              title="Acabats foulard"
              className="flex flex-col col-span-6"
              users={users}
              values={getFieldsState}
            />

            {/* VORES */}
            <Block
              title="Vores"
              className="flex flex-col col-span-6"
              users={users}
              values={getFieldsState}
            />

            {/* CONTROLS */}
            <Block
              title="Controls"
              className="flex flex-col col-span-6"
              users={users}
              values={getFieldsState}
            />

            {/* EMBALATGE */}
            <Block
              title="Embalatge"
              className="flex flex-col col-span-6"
              users={users}
              values={getFieldsState}
            />
          </div>

          {/* VISIBLE EN IMPRESIÓN */}
          <Sign />
        </div>

        {/* REGLATGES (RAMS) */}
        <div
          className="print:py-4"
          style={{
            pageBreakBefore: 'always',
            pageBreakInside: 'avoid'
          }}
        >
          <div className="relative grid grid-flow-row grid-cols-12 gap-2 auto-rows-max">
            {/* VISIBLE EN IMPRESIÓN */}
            <Block
              title="Descripció"
              className="flex-col hidden col-span-12 print:flex"
              users={users}
              values={getFieldsState}
            />

            <Rams values={getFieldsState} />
          </div>

          {/* VISIBLE EN IMPRESIÓN */}
          <Sign />
        </div>

        {/* ÚLTIMA SOL·LICITUD ACCEPTADA */}
        {getApiCallGetRequest.data.RequestId !== 0 && (
          <div
            className="print:hidden"
            style={{
              pageBreakBefore: 'always',
              pageBreakInside: 'avoid'
            }}
          >
            <div className="relative grid grid-flow-row grid-cols-12 gap-2 auto-rows-max">
              <Block
                title="Última modificació"
                className="flex flex-col col-span-12"
                users={users}
                values={getApiCallGetRequest.data}
              />
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

FTAcabatsDetail.propTypes = {
  params: PropTypes.any.isRequired
}
