import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import SuggestionForm from 'forms/RRHH/Suggestions/SuggestionForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function SuggestionDetail({ params }) {
  const { user } = useContext(UserContext)
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [client, saveClient] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetArea, setApiCallGetArea] = useFetch()
  const [getApiCallGetWarehouse, setApiCallGetWarehouse] = useFetch()
  const [getApiCallGet, setApiCallGet] = useFetch()

  const isNew = params.id === 'nou'

  const formatDate = (dateString) => {
    const utcDate = new Date(dateString)
    const userTimeZoneOffset = new Date().getTimezoneOffset()
    const userLocalTime = new Date(
      utcDate.getTime() - userTimeZoneOffset * 60000
    )

    // Obtener los componentes de la fecha
    const day = ('0' + userLocalTime.getDate()).slice(-2)
    const month = ('0' + (userLocalTime.getMonth() + 1)).slice(-2)
    const year = userLocalTime.getFullYear()
    const hour = ('0' + userLocalTime.getHours()).slice(-2)
    const minute = ('0' + userLocalTime.getMinutes()).slice(-2)

    // Construir la cadena de fecha formateada y no mostrar la hora en los registros en los que no se guardó la hora
    const formattedLocalTime =
      hour === '02'
        ? `${day}/${month}/${year}`
        : `${day}/${month}/${year}, ${hour}:${minute} h`

    return formattedLocalTime
  }

  const [fields, setFields] = useState({
    Id: 0,
    CreatedAt: formatDate(new Date()),
    UpdatedAd: '',
    RequesterId: user.Id,
    Requester: user,
    ResponderId: null,
    Responder: null,
    Reason: '',
    Shed: '',
    Area: '',
    Response: '',
    State: 'P',
    IsSuggestion: false,
    IsComplaint: false,
    IsFormation: false
  })

  // CRUD: Get
  useEffect(async () => {
    const apiCallGetUser = {
      url: `user/${user.Id}`,
      method: 'GET',
      messageKo: `Error al recuperar l'Usuari!`
    }
    setApiCallGetUser(apiCallGetUser)
  }, [])

  // Este useEffect() se hizo para recoger la información, que iria en los filtros del formulario, directamente de la base de datos:
  useEffect(async () => {
    if (getApiCallGetUser.data !== null) {
      // Llamada a la API para recoger todas las 'Areas' de la base de datos ('dbo.Areas'):
      const apiCallGetAreas = {
        url: `area`,
        method: 'GET',
        messageKo: 'Error al recuperar les Arees!'
      }

      // Llamada a la API para recoger todas las 'Naves' de la base de datos ('dbo.Warehouses'):
      const apiCallGetWarehouses = {
        url: `warehouse`,
        method: 'GET',
        messageKo: 'Error al recuperar les Naus!'
      }

      const apiCallGet = {
        url: `suggestion/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades'
      }

      // 'Promise' en este caso, se usa para hacer múltiples llamadas a la API en fila, una tras otra.
      // Mejora el tiempo de carga de la página. Si hacemos cada llamada a la API por separado,
      // provoca una carga más lenta de la página, para poner en contexto, el 'Promise' ahorra 3s de carga:
      await Promise.all([
        setApiCallGetArea(apiCallGetAreas),
        setApiCallGetWarehouse(apiCallGetWarehouses),
        params.id !== 'nou'
          ? setApiCallGet(apiCallGet)
          : setTitle({
              name: 'Crear registre ',
              buttons: [
                {
                  name: 'Tornar',
                  link: `/sugerencia`
                }
              ]
            })
      ]).catch((e) => {
        console.log(e)
      })
    }
  }, [getApiCallGetUser.data])

  useEffect(() => {
    if (getApiCallGet.data) {
      setTitle({
        name: params.id !== 'nou' ? 'Crear registre ' : 'Modificar registre ',
        buttons: [
          {
            name: 'Tornar',
            link: `/sugerencia`
          }
        ],
        stateBtn:
          params.id !== 'nou' &&
          getApiCallGetUser.data?.NotifyOnSuggestionChange &&
          user.Role.Id === 1,
        registerType: 'suggestion',
        registerId: params.id,
        registerState: getApiCallGet.data.IsActive
      })

      setFields({
        Id: getApiCallGet.data.Id,
        State: getApiCallGet.data.State ?? 'P',
        Reason: getApiCallGet.data.Reason,
        Shed: getApiCallGet.data.Shed ? getApiCallGet.data.Shed : null,
        Area: getApiCallGet.data.Area ? getApiCallGet.data.Area : null,
        IsSuggestion: getApiCallGet.data.IsSuggestion,
        IsComplaint: getApiCallGet.data.IsNotSatisfaction,
        IsFormation: getApiCallGet.data.IsFormation,
        Response: getApiCallGet.data.Response,
        CreatedAt:
          getApiCallGet.data.CreatedAt !== '' &&
          getApiCallGet.data.CreatedAt !== null
            ? formatDate(getApiCallGet.data.CreatedAt)
            : 'dd/mm/aaaa',
        UpdatedAt:
          getApiCallGet.data.UpdatedAt !== '' &&
          getApiCallGet.data.UpdatedAt !== null
            ? formatDate(getApiCallGet.data.UpdatedAt)
            : 'dd/mm/aaaa',
        RequesterId: getApiCallGet.data.RequesterId,
        Requester: getApiCallGet.data.Requester,
        ResponderId: getApiCallGet.data.ResponderId,
        Responder: getApiCallGet.data.Responder
      })
    }
  }, [getApiCallGet.data])

  // CRUD: Post
  const handleSubmit = async (values) => {
    let url = 'suggestion'
    let method = 'post'
    const preparedValues = { ...values }

    preparedValues.State = 'P'
    preparedValues.RequesterId = user.Id
    delete preparedValues.Requester
    delete preparedValues.Responder

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`

      preparedValues.State = 'R'
      preparedValues.ResponderId = user.Id
    }

    const clientApiParams = {
      url,
      method,
      body: preparedValues,
      messageKo: 'Error al recuperar les dades!',
      messageOk: 'Suggeriment actualizada!'
    }

    await saveClient(clientApiParams)
    if (!client.error) setLocation('/sugerencia')
  }

  return ((params.id !== 'nou' &&
    getApiCallGet.data !== null &&
    getApiCallGet.data !== undefined &&
    getApiCallGet.data[0] !== null) ||
    params.id === 'nou') &&
    getApiCallGetArea.data !== null &&
    getApiCallGetArea.data !== undefined &&
    getApiCallGetArea.data[0] !== null &&
    getApiCallGetWarehouse.data !== null &&
    getApiCallGetWarehouse.data !== undefined &&
    getApiCallGetWarehouse.data[0] !== null &&
    ((!isNew && fields.Id !== 0) || (isNew && fields.Id === 0)) ? (
    <div className="w-full p-4 bg-white">
      <SuggestionForm
        fields={fields}
        handleSubmit={handleSubmit}
        isNew={isNew}
        userInfo={getApiCallGetUser.data}
        user={user}
        areas={getApiCallGetArea.data}
        warehouses={getApiCallGetWarehouse.data}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

SuggestionDetail.propTypes = {
  params: PropTypes.any
}
