import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import Select from 'react-select'
import { useState, useEffect, useContext } from 'react'
import { ModalContext } from 'contexts/ModalContext'
import ChooseTypeModal from 'components/NC/ChooseTypeModal'

import NCFormMagatzem from 'components/NC/NCFormMagatzem'
import NCFormMag      from 'components/NC/NCFormMag'
import NCFormAca      from 'components/NC/NCFormAca'

export default function NCForm({
  handleSubmit,
  handleChange,
  handleSemiChange,
  handleClick,
  fields,
  users,
  semis,
  isNew,
  threads,
  user,
  machines,
  mtList,
  type,
  articles
}) {
  const { handleModal } = useContext(ModalContext)

  // const [article, setArticle] = useState({})

  const [options, setOptions] = useState([])
  const [defaultOption, setDefaultOption] = useState({})

  const [threadOptions, setThreadOptions] = useState([])
  const [defaultThreadOption, setDefaultThreadOption] = useState({})

  const [userOptions, setUserOptions] = useState([])
  const [defaultUserOption, setDefaultUserOption] = useState({})

  const [machineOption, setMachineOption] = useState({})
  const [machineOptions, setMachineOptions] = useState([])

  const [mtOptions, setMtOptions] = useState([])
  const [defaultMt, setDefaultMt] = useState({})

  const [defaultArticle, setDefaultArticle] = useState({})
  const [articleOptions, setArticleOptions] = useState([])
  
  const [defaultArea, setDefaultArea] = useState({})
  const areaOptions = [
    { label: 'Recepció', value: 'Recepció' },
    { label: 'Teixiduria', value: 'Teixiduria' },
    { label: 'Tintura', value: 'Tintura' },
    { label: 'Acabats', value: 'Acabats' },
    { label: 'Expedició', value: 'Expedició' },
    { label: 'Magatzem', value: 'Magatzem' },
    { label: 'Repàs', value: 'Repàs' },
    { label: 'Manteniment', value: 'Manteniment' },
    { label: 'Instal·lacions', value: 'Instal·lacions' },
    { label: 'Vigilància', value: 'Vigilància' },
    { label: 'Laboratori', value: 'Laboratori' },
    { label: 'Responsables / Tècnics', value: 'Responsables / Tècnics' },
    { label: 'Direcció', value: 'Direcció' },
    { label: 'Administració', value: 'Administració' },
    { label: 'Compres i Finances', value: 'Compres i Finances' },
    { label: 'Neteja', value: 'Neteja' },
    { label: 'Qualitat', value: 'Qualitat' },
    { label: 'Rentat', value: 'Rentat' }
  ]
  const [defaultShed, setDefaultShed] = useState({})
  const ShedOptions = [
    { label: 'Cetriko', value: 'Cetriko' },
    { label: '4KF', value: '4KF' }
  ]

  const [defaultHour, setDefaultHour] = useState({})
  const hoursOptions = [
    { label: '0 h', value: 0 },
    { label: '1 h', value: 1 },
    { label: '2 h', value: 2 },
    { label: '3 h', value: 3 },
    { label: '4 h', value: 4 },
    { label: '5 h', value: 5 },
    { label: '6 h', value: 6 },
    { label: '8 h', value: 8 },
    { label: '10 h', value: 10 },
    { label: '12 h', value: 12 },
    { label: '16 h', value: 16 },
    { label: '20 h', value: 20 },
    { label: '24 h', value: 24 },
    { label: '36 h', value: 36 },
    { label: '48 h', value: 48 },
    { label: '72 h', value: 72 },
    { label: '96 h', value: 96 }
  ]

  const [defaultMinute, setDefaultMinute] = useState({})
  const minutesOptions = [
    { label: '0 min', value: 0 },
    { label: '5 min', value: 5 },
    { label: '10 min', value: 10 },
    { label: '15 min', value: 15 },
    { label: '20 min', value: 20 },
    { label: '25 min', value: 25 },
    { label: '30 min', value: 30 },
    { label: '35 min', value: 35 },
    { label: '40 min', value: 40 },
    { label: '45 min', value: 45 },
    { label: '50 min', value: 50 },
    { label: '55 min', value: 55 }
  ]

  const currentdate = new Date()
  // const newDatetime =
  //   String(currentdate.getDate()).padStart(2, '0') 
  //   + '-' +  
  //   String(currentdate.getMonth() + 1).padStart(2, '0')
  //   + '-' +
  //   String(currentdate.getFullYear())
    
  // const datetime =
  //   String(currentdate.getFullYear()) +
  //   '-' +  
  //   String(currentdate.getMonth() + 1).padStart(2, '0') +
  //   '-' +
  //   String(currentdate.getDate()).padStart(2, '0') 

  const newDatetime =
    String(currentdate.getFullYear())  
    + '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0')
    + '-' +  
    String(currentdate.getDate()).padStart(2, '0') 
    
  useEffect(() => {
    setDefaultHour(hoursOptions.find((option) => option.value === fields.LostTimeHours))
    setDefaultMinute(minutesOptions.find((option) => option.value === fields.LostTimeMinutes))
  }, [])

  useEffect(() => {
    setDefaultHour(hoursOptions.find((option) => option.value === fields.LostTimeHours))
    setDefaultMinute(minutesOptions.find((option) => option.value === fields.LostTimeMinutes))
  }, [fields])

  useEffect(() => {
    // console.log('articleOptions...:',articleOptions)
    setArticleOptions(articles)
    setDefaultArticle(
      articleOptions.find((option) => option.value === fields.Article)
    )
  }, [articles])

  useEffect(() => {
    if (isNew) handleModal(<ChooseTypeModal handleClick={handleClick} />)
  }, [])

  useEffect(() => {
    setDefaultArea(areaOptions.find((option) => option.value === fields.Area))
  }, [])

  useEffect(() => {
    setDefaultShed(ShedOptions.find((option) => option.value === fields.Shed))
  }, [])

  useEffect(() => {
    setMtOptions(mtList)
    setDefaultMt(
      mtOptions.find((option) => option.value === fields.IdEquipment)
    )
  }, [mtList])

  useEffect(() => {
    setOptions(semis)
    setDefaultOption(
      options.find((option) => option.value === fields.SemiFinished)
    )
  }, [semis])

  useEffect(() => {
    setThreadOptions(threads)
    setDefaultThreadOption(
      threadOptions.find((option) => option.value === fields.IdThread)
    )
  }, [threads])

  useEffect(() => {
    setUserOptions(users)
    setDefaultUserOption(
      userOptions.find((option) => option.value === fields.DetectedUserId)
    )
  }, [users])

  useEffect(() => {
    setMachineOptions(machines)
    // console.log('NCForm - machineOptions: ',machineOptions)
    // console.log('NCForm - fields.Machine: ',fields.Machine)
    setMachineOption(
      machineOptions.find((option) => option.value === fields.Machine)
    )
  }, [machines])

  const allFields = [
    {
      name: 'IsMatter',
      type: 1,
      label: 'Mat. Primera',
      area: ['Magatzem', 'Teixiduria', 'Acabat']
    },
    {
      name: 'IsSemi',
      type: 1,
      label: 'Semielaborat',
      area: ['Magatzem', 'Tintura', 'Acabat']
    },
    {
      name: 'IsFinish',
      type: 1,
      label: 'Acabat',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    {
      name: 'ItemThread',
      type: 2,
      label: 'Partida Fil',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    {
      name: 'KilosNOK',
      type: 2,
      label: 'Quilos NOK (kg)',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    {
      label: 'Codi fil',
      area: ['Magatzem']
    },
    {
      label: 'Temps perdut',
      name: 'LostTimeHours',
      type: 4,
      placeholder: 'Hores',
      options: [
        { label: '0h', value: 0 },
        { label: '1h', value: 1 },
        { label: '2h', value: 2 },
        { label: '3h', value: 3 },
        { label: '4h', value: 4 },
        { label: '5h', value: 5 },
        { label: '6h', value: 6 },
        { label: '8h', value: 8 },
        { label: '10h', value: 10 },
        { label: '12h', value: 12 },
        { label: '16h', value: 16 },
        { label: '20h', value: 20 },
        { label: '24h', value: 24 },
        { label: '36h', value: 36 },
        { label: '48h', value: 48 },
        { label: '72h', value: 72 },
        { label: '96h', value: 96 }
      ],
      time: 'h',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    // { label: 'Màq. teix.', name: 'Machine', type: 1 },
    // { label: 'Altres equips', name: 'IdEquipment', type: 1 },
    {
      label: '.',
      name: 'LostTimeMinutes',
      type: 4,
      placeholder: 'Minuts',
      options: [
        { label: '0min', value: 0 },
        { label: '0min', value: 5 },
        { label: '10min', value: 10 },
        { label: '0min', value: 15 },
        { label: '20min', value: 20 },
        { label: '0min', value: 25 },
        { label: '30min', value: 30 },
        { label: '0min', value: 35 },
        { label: '40min', value: 40 },
        { label: '0min', value: 45 },
        { label: '50min', value: 50 },
        { label: '0min', value: 55 }
      ],
      time: 'min',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    // {
    //   name: 'N8D',
    //   type: 2,
    //   label: 'N8D',
    //   area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    // },
    {
      name: 'Machine',
      type: 4,
      label: 'Màquina',
      area: ['Teixiduria']
    },
    {
      name: 'IdEquipment',
      type: 4,
      label: 'Màquina',
      area: ['Tintura', 'Acabat']
    },
    {
      name: 'CoilNumber',
      type: 2,
      label: 'Nº bobina',
      area: ['Acabat']
    },
    {
      name: 'MetersNOK',
      type: 2,
      label: 'Metres NOK',
      area: ['Acabat']
    },
    {
      name: 'IsSample',
      type: 1,
      label: "S'agafa mostra",
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    {
      name: 'IsMaterialNonConforming',
      type: 1,
      label: 'Material no conforme',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    {
      name: 'IsSuspiciousMaterial',
      type: 1,
      label: 'Material sospitós',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    },
    {
      name: 'IsMaterialReoperate',
      type: 1,
      label: 'Reopero',
      area: ['Acabat']
    },
    {
      name: 'Description',
      type: 2,
      label: 'Descripció',
      area: ['Magatzem', 'Teixiduria', 'Tintura', 'Acabat']
    }
  ]

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        // validationSchema={DescriptionSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleClick,
          setFieldValue,
          submitForm
        }) => (
          <Form className=" bg-blue-100 grid grid-cols-4 mx-12 ">
            <h1 className="col-span-4 text-center mt-2 mb-2 text-2xl font-ms-bold">
              INFORME DE NC INTERNA -{' '}
              {values.Department
                ? values.Department.toUpperCase()
                : type.toUpperCase()}
            </h1>
            <h3 className="col-span-4 bg-blue-300 h-1 text-center mb- text-2xl font-ms-bold">
              
            </h3>            

            {/* {console.log('values.id ...:',values.Id)} */}

            {/* 
            <div>
              {
                {
                  'Magatzem': <NCFormMag />,
                  'Acabats ': <NCFormAca />                  
                }[values.Department]
              }
            </div> 
             */}

              {/* switch (values.Department.toUpperCase())  {              
                case    'MAGATZEM'  : <NCFormMag fields={fields}/>; break;
                case    'ACABATS'   : <NCFormAca fields={fields}/>; break;
                case    'TINTURA'   : <NCFormTin fields={fields}/>; break;
                default 'TEIXIDURIA': <NCFormTei fields={fields}/>;                             
              } */}            

            <div id="box-avis" className="grid grid-cols-2 col-span-2 p-2">

                <div id="box-avis-n8d" className="col-span-1 ml-1">
                  {/*  "w-300 mx-2 ml-300"> */}
                    <label
                      className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="N8D"
                    >
                      N8D:
                    </label>
                    <Field
                      className={`col-span-4 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                      name="N8D"
                      type="text"                      
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                </div>

                <div id="box-avis-avis" className='col-span-1'> 
                    <label
                      className={`col-span-4 block ml-4 mr-2 text-grey-darker text-md font-bold font-ms-semi`}
                      htmlFor=""
                    >
                      Nr.Avís :{' '}<span className="text-red-600 font-bold font-ms text-base ml-2">{values.Id}</span>                      
                    </label>
                </div>                             

                <div id="box-avis-fil" className="col-span-1 w-40 flex flex-row justify-end mb-3 mt-3 ">                

                {                  
                  (fields.Department === 'Tintura')
                  ?
                  <div> </div>
                  // no mostrar check ni FIL ni MatPrimera
                  : 
                      (fields.Department === 'Acabats') 
                      ?
                      <div className=" col-span-2 flex flex-row justify-end">

                          <label
                            className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                            htmlFor="IsThread"
                          >
                            Fil
                          </label>
                          <Field
                            className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                            name="IsThread"
                            type="checkbox"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                      </div>
                      :                 
                        <div className="col-span-2 flex flex-row justify-end">
                            <label
                              className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                              htmlFor="IsMatter"
                            >
                              Mat.Primera
                            </label>
                            <Field
                              className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                              name="IsMatter"
                              type="checkbox"
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                        </div>
                }
                </div>

                <div id="box-avis-fil-blanc" className='col-span-1'> 

                </div>                   

                <div id="box-avis-semielab" className="col-span-1 w-40 flex flex-row justify-end mb-3">
                  <label
                    className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                    htmlFor="IsSemi"
                  >
                    Semielaborat
                  </label>
                  <Field
                    className={`shadow rounded w-6 h-6  border border-gray-300 py-2 px-3 text-grey-darker`}
                    name="IsSemi"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e)
                    }}
                  />
                </div>

                <div id="box-avis-semielab-blanc" className='col-span-1'> 

                </div>

                <div id="box-avis-acabat" className="col-span-1 w-40 flex flex-row justify-end mb-3">
                {  
                  ((fields.Department === 'Teixiduria') || (type === 'Teixiduria')) 
                  ?
                  <div> </div>
                  // no mostrar check ACABAT
                  : 
                  <div className=" col-span-2 flex flex-row justify-end">

                      <label
                        className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                        htmlFor="IsFinish"
                      >
                        Acabat
                      </label>
                      <Field
                        className={`shadow rounded w-6 h-6  border border-gray-300 py-2 px-3 text-grey-darker`}
                        name="IsFinish"
                        type="checkbox"
                        onChange={(e) => {
                          handleChange(e)
                        }}
                      />
                  </div>                
                }
                </div>

                <div id="box-avis-acabat-blanc" className='col-span-1'> 

                </div>

            </div>            

            <div id="box-data" className="grid grid-cols-3 col-span-2 p-2">
                <div id="box-data-data" className="col-span-1 ">                    
                      <label
                        className={`col-span-1 block mr-2 text-grey-darker text-md font-ms-semi`}
                        htmlFor="DateNC"
                      >
                        Data
                      </label>
                      
                      <Field
                        className={`col-span-1 shadow rounded w-64 appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                        name="DateNC"                        
                        type="date"
                        disabled                        
                        onChange={(e) => {                          
                          handleChange(e)
                        }}
                      />                    
                </div>
                <div id="box-data-data" className="col-span-1 ">                    
                      <label
                        className={`col-span-1 block mr-2 text-grey-darker text-md font-ms-semi`}
                        htmlFor="HourNC"
                      >
                        Hora
                      </label>
                      
                      <Field
                        className={`col-span-1 shadow rounded w-64 appearance-none border border-gray-300 py-2 pr-2 text-grey-darker`}
                        name="HourNC"                        
                        type="text"
                        disabled                        
                      />
                    {/* </div> */}
                </div>  

                <div id="box-data-blanc" className='col-span-1'> 

                </div>

                <div id="box-data-detectat-per" className="col-span-2 mt-0">
                    <label
                      className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="DetectedUserId"
                    >
                      Detectat per:
                    </label>
                        <div className="flex flex-col mx-auto w-full ">                
                        {/* {console.log('userOptions...: ',userOptions)} */}
                          <Select
                            className="form-select block"
                            onChange={(e) => {
                              setFieldValue('DetectedUserId', e.value)
                              setFieldValue('DetectedUserName', e.label)
                            }}
                            name="DetectedUserId"
                            placeholder="Selecciona usuari"
                            options={userOptions}
                            defaultValue={defaultUserOption ?? ''}    
                            value={userOptions.find(
                              (option) => option.value === values.DetectedUserId
                            )}                        
                          />
                        </div>                      
                </div>

                <div id="box-data-detec-shed" className='col-span-1'>        
                    <label
                      className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="Shed"
                    >
                      Nau:
                    </label>                                 
                    <Select                        
                        className="col-span-2 shadow rounded w-full appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker"
                        onChange={(e) => setFieldValue('Shed', e.value)}
                        name="Shed"
                        placeholder="Selecciona..."
                        options={ShedOptions}
                        // defaultValue= {ShedOption}
                        value={ShedOptions.find(
                          (option) => option.value === values.Shed
                        )}
                    />                  
                </div>

                <div id="box-data-area" className="col-span-2 mt-0">
                    <label
                      className="block mr-2 text-grey-darker text-md font-ms-semi mb-2 col-start-full"
                      htmlFor="RequestUserId"
                    >
                      Àrea / procès
                    </label>
                    {/* {fields.Area} */}
                    <Select
                      className="rounded text-grey-darker"
                      onChange={(e) => setFieldValue('Area', e.value)}
                      name="Area"
                      placeholder="Selecciona..."
                      options={areaOptions}
                      // defaultValue= {areaOption}
                      value={areaOptions.find(
                        (option) => option.value === values.Area
                      )}
                    />
                </div>

            </div>

            <h3 className="col-span-4 bg-blue-300 h-1 text-center mb-0 text-2xl font-ms-bold">  
            </h3>
{/* comentari */}
            <div id="box-fil" className="grid grid-cols-2 col-span-2 p-2 h-auto">
                <div id="box-fil-partida" className='col-span-1 mt-12 mb-0 ml-1 h-8'>
                    <label
                      className={`col-span-1 block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="ItemThread"
                    >
                      Partida Fil:
                    </label>
                    <Field
                      className={`col-span-1 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                      name="ItemThread"
                      type="text"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                </div>
                <div id="bobina-fil" className='col-span-1 mt-3 ml-1 h-14'>
                    <label
                      className={`col-span-1 block mr-2 mt-2 mb-1 pt-6 text-grey-darker text-md font-ms-semi`}
                      htmlFor="CoilNumber"
                    >
                      Nº Bobina:
                    </label>
                    <Field
                      className={`col-span-1 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                      name="CoilNumber"
                      type="text"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                </div>
                
                <div id="box-fil-codi" className="col-span-2 p-0 ml-1 h-4 mt-3">

                {  
                  ((fields.Department === 'Magatzem') || (type === 'Magatzem')) 
                  ?
                  // mostrar campo CODI FIL, sino Semielaborat combo-select
                  <div className=" col-span-2">
                      <label
                        // className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                        className={`col-span-4 block mr-2 mt-2 mb-1 text-grey-darker text-md font-ms-semi`}
                        htmlFor="IdThread"
                      >
                        Codi Fil:
                      </label>

                      <Select
                        className="rounded text-grey-darker col-span-2"
                        onChange={(e) => setFieldValue('IdThread', e.value)}
                        name="IdThread"
                        placeholder="Selecciona..."
                        options={threadOptions}
                        // defaultValue= {areaOption}
                        value={threadOptions.find(
                          (option) => option.value === values.IdThread
                        )}
                      />
                  </div>  
                  : 
                    <div className=" col-span-2">

                      <label
                        className={`col-span-4 block mr-2 mt-2 mb-1 text-grey-darker text-md font-ms-semi`}
                        htmlFor="SemiFinished"
                      >
                        Semielaborat:
                      </label>
                      <Select
                        className="rounded text-grey-darker col-span-2"
                        onChange={(e) => setFieldValue('SemiFinished', e.value)}
                        name="SemiFinished"
                        placeholder="Selecciona..."
                        options={options}
                        // defaultValue= {areaOption}
                        value={options.find(
                          (option) => option.value === values.SemiFinished
                        )}
                      />
                    </div>
                }
                </div>

                <div id="box-fil-quilos" className='col-span-1 mt-3 ml-1 h-14'>
                    <label
                      className={`col-span-1 block mr-2 mt-2 mb-1 pt-6 text-grey-darker text-md font-ms-semi`}
                      htmlFor="KilosNOK"
                    >
                      Quilos NOK:
                    </label>
                    <Field
                      className={`col-span-1 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                      name="KilosNOK"
                      type="number"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                </div>
                <div id="box-fil-metres" className='col-span-1 mt-3 ml-1 h-14'>
                    <label
                      className={`col-span-1 block mr-2 mt-2 mb-1 pt-6 text-grey-darker text-md font-ms-semi`}
                      htmlFor="MetersNOK"
                    >
                      Metres NOK:
                    </label>
                    <Field
                      className={`col-span-1 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                      name="MetersNOK"
                      type="number"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                </div>

                <div id="box-fil-peces" className='col-span-2 ml-1 h-14 mt-6'>
                    <label
                      className={`col-span-4 block mr-2 mt-1 mb-1 pt-4 text-grey-darker text-md font-ms-semi`}
                      htmlFor="NumPieces"
                    >
                      Peces / màq.Teix:
                    </label>
                    <Field
                      className= {`col-span-4 h-14 shadow rounded w-full appearance-none border border-gray-300 mb-6 py-2 pl-2 pb-2 text-grey-darker`}
                      name     = "NumPieces"
                      type     = "text"
                      as       = "textarea"
                      onChange = {(e) => {
                        handleChange(e)
                      }}
                    />
                </div>

                <div id="box-fil-temps" className="grid grid-cols-2 col-span-2 ml-1 h-8 mt-4">
                    <div id="box-fil-temps-h" className="col-span-1">                      
                        <label
                          className="col-span-1 block mr-2 mt-1 mb-1 pt-4 text-grey-darker text-md font-ms-semi"
                          htmlFor="LostTimeHours"
                        >
                          Temps perdut, hores:
                        </label>
                        <Select
                          // as="select"
                          className   = "col-span-1 ml-0 mr-1 py-1 px-0 text-grey-darker"
                          onChange    = {(e) => setFieldValue('LostTimeHours', e.value)}
                          name        = "LostTimeHours"   
                          // type={allFields.type}                      
                          options     = {hoursOptions}
                          defaultValue= {defaultHour ?? ''}  
                          value       = {hoursOptions.find(
                            (option) => option.value === values.LostTimeHours
                          )}
                        >                     
                        </Select>
                                                     
                    </div>
                    <div id="box-fil-temps-m" className="col-span-1">
                        <label
                              className="col-span-1 block mr-2 mt-1 mb-1 pt-4 text-grey-darker text-md font-ms-semi"
                              htmlFor="LostTimeMinutes"
                            >
                              minuts:
                        </label>
                        <Select
                          // as="select"
                          className={`col-span-1 ml-0 mr-0 py-1 px-0 text-grey-darker `}
                          onChange    = {(e) => setFieldValue('LostTimeMinutes', e.value)}
                          name        = "LostTimeMinutes"   
                          // type={allFields.type}                      
                          options     = {minutesOptions}
                          defaultValue= {defaultMinute ?? ''}  
                          value       = {minutesOptions.find(
                            (option) => option.value === values.LostTimeMinutes
                          )}
                        >
                        </Select>                          
                    </div>
                </div>

            </div>

            <div id="box-acabat-mostra" className='grid grid-cols-2 col-span-2 p-2'>              

            {/* { console.log('type...',type) } */}

                <div id="box-acabat-mostra-shaacabat" className="col-span-1 w-40 flex flex-row justify-end mb-3 mt-3 ">
                {                  

                    ((fields.Department === 'Teixiduria') || (type === 'Teixiduria')) 
                    ||
                     ((fields.Department === 'Magatzem') || (type === 'Magatzem'))

                    // ((fields.Department === 'Teixiduria') || isNew) 
                    //       || ((fields.Department === 'Magatzem') || isNew)) 
                    ?
                    <div> </div>
                    // no mostrar check "s'ha acabat"
                    : 
                      <div className=" col-span-2 flex flex-row justify-end">

                        <label
                          className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="IsFinishTeix"
                        >
                          {`S'ha acabat teixit`}
                        </label>
                        <Field
                            className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                            name="IsFinishTeix"
                            type="checkbox"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                        />
                      </div>
                }
                </div>

                <div id="box-acabat-mostra-mostra" className="col-span-1 w-40 flex flex-row justify-end mb-3 mt-3 ">
                {  
                    (fields.Department === 'Magatzem') 
                    ?
                    <div> </div>
                    // no mostrar check "s'agafa mostra"
                    : 
                      <div className=" col-span-2 flex flex-row justify-end">

                          <label
                            className={`block mr-2 text-grey-darker text-md font-ms-semi`}
                            htmlFor="IsSample"
                          >
                            {`S'agafa mostra`}
                          </label>
                          <Field
                              className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                              name="IsSample"
                              type="checkbox"
                              onChange={(e) => {
                                handleChange(e)
                              }}
                          />
                      </div>
                }
                </div>

                <div id="box-acabat-altres" className="grid grid-cols-2 col-span-2 p-2">
                {  
                  ((fields.Department === 'Teixiduria') || (type === 'Teixiduria')) 
                  ?
                  <div className="grid grid-cols-2 col-span-2">
                    <label
                      className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="Machine"
                    >
                      Màquina Teix.:
                    </label>
                    {/* {console.log('machineOptions...: ',machineOptions)} */}
                    <Select
                      className  = "rounded text-grey-darker col-span-2"
                      onChange   = {(e) => setFieldValue('Machine', e.label)}
                      name       = "Machine"
                      placeholder= "Selecciona..."
                      options    = {machineOptions}
                      defaultValue= {machineOption?? ''}
                      value      = {machineOptions.find(
                        (option) => option.label === values.Machine
                      )}
                    /> 
                  </div>
                  :
                  <div className="grid grid-cols-2 col-span-2">
                    <label
                      className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="EquipmentName"
                    >
                      Màquina (altres equips):
                    </label>
                    {/* {console.log('mtOptions altres equips...: ',mtOptions)} */}
                    {/* {console.log('values de altres equips...: ',values)} */}
                    <Select
                      className  = "rounded text-grey-darker col-span-2"
                      onChange   = {(e) => setFieldValue('EquipmentName', e.label)} 
                      // setFieldValue('IdEquipment', e.value),                     
                      name       = "EquipmentName"
                      placeholder= "Selecciona..."
                      options    = {mtOptions}
                      defaultValue= {defaultMt ?? ''}
                      value       = {mtOptions.find(
                        (option) => option.label === values.EquipmentName
                      )}                      
                      // value      = {mtOptions.find(
                      //   (option) => option.value === values.IdEquipment
                      // )}
                    /> 
                  </div>  
                }              

                </div>     

                <div id="box-acabat-article" className="grid grid-cols-2 col-span-2 p-2">

                {  
                  ((fields.Department === 'Teixiduria') || (type === 'Teixiduria')) 
                  ||
                   ((fields.Department === 'Magatzem') || (type === 'Magatzem'))
                  ?
                  <div className='mt-6 pb-4'> </div>
                  // no mostrar check ACABAT
                  :                   
                  <div className=" col-span-2">
                      <label
                        className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                        htmlFor="Article"
                      >
                        Article:
                      </label>
                      {/* {console.log('articleOptions...: ',articleOptions)} */}
                      <Select
                        className  = "rounded text-grey-darker col-span-2"
                        onChange   = {(e) => {
                          setFieldValue('Article', e.label)
                          setFieldValue('Client', e.client)
                        }}
                        name       = "Article"
                        placeholder= "Selecciona..."
                        options    = {articleOptions}
                        defaultValue= {defaultArticle ?? ''}                        
                        value      = {articleOptions.find(
                          (option) => option.label === values.Article
                        )}                        
                        // value      = {articleOptions.find(
                        //   (option) => option.value === values.Article
                        // )}
                      />
                  </div>                  
                }
                </div>     
                {console.log("art", articleOptions)}

                <div id="box-acabat-of" className="grid grid-cols-2 col-span-2 p-2">

                {  
                  ((fields.Department === 'Teixiduria') || (type === 'Teixiduria')) 
                  ||
                   ((fields.Department === 'Magatzem') || (type === 'Magatzem'))
                  ?
                  <div className='mt-6 pb-4'> </div>
                  // no mostrar campo OF
                  : 
                  <div className=" col-span-2">

                    <label
                      className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                      htmlFor="OF"
                    >
                      OF:
                    </label>
                    <Field
                      className={`col-span-4 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                      name="OF"
                      type="text"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </div>                
                }                
                </div>          

                <div id="article-client">
                  {  
                    ((fields.Department === 'Teixiduria') || (type === 'Teixiduria')) 
                    ||
                    ((fields.Department === 'Magatzem') || (type === 'Magatzem'))
                    ?
                      <div className='mt-6 pb-4'> </div>
                      // no mostrar campo Client
                    : 
                      <label
                        className={`col-span-4 block ml-2 text-grey-darker text-md font-ms-semi`}
                        htmlFor="Client"
                      >                      
                      Client:{' '}<span className="font-bold font-ms text-base ml-2">{values.Client}</span>     
                    </label>
                  }   
                </div> 

                <div id="box-acabat-metres" className="grid grid-cols-2 col-span-2 p-2">
                {  
                    ((fields.Department !== 'Acabats') || (type !== 'Acabats'))                   
                    ?
                    <div className='mt-6 pb-4'> </div>
                    // no mostrar camp Metres NOK
                    : 
                    <div className='mt-6 pb-4'> </div>
                    // <div className=" col-span-2">

                    //   <label
                    //     className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                    //     htmlFor="MetersNOK"
                    //   >
                    //     Metres NOK:
                    //   </label>
                    //   <Field
                    //     className={`col-span-4 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                    //     name="MetersNOK"
                    //     type="number"
                    //     onChange={(e) => {
                    //       handleChange(e)
                    //     }}
                    //   />
                    // </div>
                }
                </div> 

                <div id="box-acabat-bobina" className="grid grid-cols-2 col-span-2 p-2">

                {  
                    ((fields.Department !== 'Acabats') || (type !== 'Acabats'))                   
                    ?
                    <div className='mt-6 py-8'> </div>
                    // no mostrar camp bobina
                    : 
                    <div className='mt-6 py-8'> </div>
                    // <div className=" col-span-2">
                    //     <label
                    //       className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                    //       htmlFor="CoilNumber"
                    //     >
                    //       Num.Bobina:
                    //     </label>
                    //     <Field
                    //       className={`col-span-4 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                    //       name="CoilNumber"
                    //       type="text"
                    //       onChange={(e) => {
                    //         handleChange(e)
                    //       }}
                    //     />
                    // </div>
                }
                </div>

            </div>
            <div id="separador" className='my-8'>
              &nbsp;
            </div>
            <div id="box-peu-checks" className="grid grid-cols-4 col-span-4 p-2">
                <p className={`ml-2 text-grey-darker text-md font-ms-semi`}>Estat del material ... :</p>
                <div id="box-peu-chkNonConf" className="col-span-1 w-40 flex flex-row justify-end mb-3 mt-3 ">
                  <label
                    className={`block mr-2 text-grey-darker text-sm font-ms-semi`}
                    htmlFor="IsMaterialNonConforming"
                  >
                    No Conforme (llençat)
                  </label>
                  <Field
                    className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                    name="IsMaterialNonConforming"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e)
                    }}
                  />
                </div>

                <div id="box-peu-chkSuspicious" className="col-span-1 w-40 flex flex-row justify-end mb-3 mt-3 ">
                  <label
                    className={`block mr-2 text-grey-darker text-sm font-ms-semi`}
                    htmlFor="IsSuspiciousMaterial"
                  >
                    Sospitos (apartat)
                  </label>
                  <Field
                    className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                    name="IsSuspiciousMaterial"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e)
                    }}
                  />
                </div>

                <div id="box-peu-chkReopero" className="col-span-1 w-40 flex flex-row justify-end mb-3 mt-3 ">
                {  
                  (fields.Department !== 'Acabats')
                  ?
                  <div> </div>
                  // no mostrar check REOPERO
                  : 
                    <div className="grid grid-cols-2 col-span-2">

                        <label
                          className={`block mr-2 text-grey-darker text-sm font-ms-semi`}
                          htmlFor="IsMaterialReoperate"
                        >
                          Reopero
                        </label>
                        <Field
                          className={`flex justify-end shadow rounded w-6 h-6 border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="IsMaterialReoperate"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                    </div>
                }
                </div>
            </div>

            <h3 className="col-span-4 bg-blue-300 h-1 text-center mb-0 text-2xl font-ms-bold">  
            </h3>                

            <div id="box-descrip" className="grid grid-cols-4 col-span-4 p-2">

                <div id="box-descrip-1" className='col-span-4 ml-1'>
                        <label
                          className={`col-span-4 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Description"
                        >
                          Descripcio:
                        </label>
                        <Field
                          className= {`col-span-4 shadow rounded w-full appearance-none border border-gray-300 py-2 pl-2 h-36 text-grey-darker`}
                          name     = "Description"
                          type     = "text"
                          as       = "textarea"
                          onChange = {(e) => {
                            handleChange(e)
                          }}
                        />
                </div>
            </div>

            {/* {console.log(fields)} */}

            <div id="ncform-guardar" className="col-span-4"> 
                <button
                  className="2xl:mr-20  block transition duration-300 
                  border-primary hover:border-blue-200 bg-primary text-white 
                  font-ms-semi 
                  mt-20 mb-6 mr-2 py-2 px-4 ml-auto
                  border h-12 rounded 
                  hover:bg-blue-200 hover:text-primary
                  print:hidden"
                  type="submit"             
                >                
                  GUARDAR
                </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
NCForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isUpdate: PropTypes.any,
  users: PropTypes.array,
  semis: PropTypes.any,
  handleSemiChange: PropTypes.func,
  isNew: PropTypes.bool,
  threads: PropTypes.object,
  user: PropTypes.object,
  machines: PropTypes.array,
  mtList: PropTypes.array,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  articles: PropTypes.array
}
