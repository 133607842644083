import { TitleContext } from 'contexts/TitleContext'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'

export default function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column
  const previousFilter = localStorage.getItem(column.id)
  const { title } = useContext(TitleContext)

  const acabatsColumns = [
    'semiFinished',
    'description',
    'client',
    'width',
    'status',
    'isOperatorHidden'
  ]

  const weavingColumns = [
    'SemiFinished',
    'Matter',
    'Machine',
    'GG',
    'Repetitions',
    'Columns',
    'Width',
    'Weight',
    'Thickness',
    'KgPart'
  ]

  // El siguiente 'useEffect()' que está comentado, lo que hace es guardar los valores de los filtros en 'localStorage'.
  // Antes provocaba problemas porque hay varias tablas en la aplicación que tenian filtros en las cabeceras con el mismo nombre,
  // y si usas un filtro que por ejemplo sea 'Descripció', el valor que has introducido se guarda y se muestra automáticamente
  // en todos los demás filtros que se llamen 'Descripció'. ¡Ahora está solucionado, esto es un aviso para tener cuidado al poner
  // los nombres en las columnas!
  // Más información en el ticket https://suport.gestinet.com/ticket/69335:
  useEffect(() => {
    localStorage.setItem(column.id, filterValue)
  }, [previousFilter, filterValue])

  useEffect(() => {
    if (previousFilter !== 'undefined') {
      setFilter(previousFilter)
    }
  }, [title])

  return (
    <div className="print:hidden">
      {column.Header === 'Estat' ? (
        <select
          className="w-16 py-1 pr-1 mt-2 text-gray-400 border border-gray-300 rounded-sm font-lato 2xl:w-32"
          onChange={(e) =>
            e.target.value === 'A' || e.target.value === 'P'
              ? setFilter(e.target.value)
              : setFilter('')
          }
          value={filterValue || ''}
          onClick={(e) => e.stopPropagation()}
        >
          <option className="" value="">
            Selecciona un estat
          </option>
          <option className="text-gray-900" value="A">
            Aprovat
          </option>
          <option className="text-gray-900" value="P">
            Prototip
          </option>
        </select>
      ) : column.Header === 'Prioritat' ? (
        <select
          className="w-full px-1 py-1 pr-1 mt-2 text-xs text-gray-400 border-2 border-gray-200 rounded-sm 2xl:text-sm focus:outline-none hover:border-red-300 font-lato"
          onChange={(e) => {
            e.target.value === '1' ||
            e.target.value === '2' ||
            e.target.value === '3'
              ? setFilter(e.target.value)
              : setFilter('')
          }}
          value={filterValue || ''}
          onClick={(e) => e.stopPropagation()}
        >
          <option className="" value="">
            Selecciona...
          </option>
          <option className="text-gray-900" value="1">
            Alta
          </option>
          <option className="text-gray-900" value="2">
            Mitja
          </option>
          <option className="text-gray-900" value="3">
            Baixa
          </option>
        </select>
      ) : column.Header === 'Estadi' ? (
        <select
          className="w-full px-1 py-1 pr-1 mt-2 text-xs text-gray-400 border-2 border-gray-200 rounded-sm 2xl:text-sm focus:outline-none hover:border-red-300 font-lato"
          onChange={(e) => {
            e.target.value === '1' ||
            e.target.value === '2' ||
            e.target.value === '3'
              ? setFilter(e.target.value)
              : setFilter('')
          }}
          value={filterValue || ''}
          onClick={(e) => e.stopPropagation()}
        >
          <option className="" value="">
            Selecciona...
          </option>
          <option className="text-gray-900" value="1">
            Obert
          </option>
          <option className="text-gray-900" value="2">
            Tancat
          </option>
          <option className="text-gray-900" value="3">
            Pendent
          </option>
        </select>
      ) : column.Header === 'Nivell' ? (
        <select
          className="w-full px-1 py-1 pr-1 mt-2 text-xs text-gray-400 border-2 border-gray-200 rounded-sm 2xl:text-sm focus:outline-none hover:border-red-300 font-lato"
          onChange={(e) =>
            e.target.value === '1' ||
            e.target.value === '2' ||
            e.target.value === '3'
              ? setFilter(e.target.value)
              : setFilter('')
          }
          value={filterValue || ''}
          onClick={(e) => e.stopPropagation()}
        >
          <option className="" value="">
            Selecciona...
          </option>
          <option className="text-gray-900" value="1">
            Independent
          </option>
          <option className="text-gray-900" value="2">
            Maestría
          </option>
          <option className="text-gray-900" value="3">
            Formació
          </option>
        </select>
      ) : column.Header === 'Dies Pdts' ? (
        <input
          type={`hidden`}
          className={`border-2 border-gray-200 text-xs 2xl:text-sm focus:outline-none hover:border-red-300 font-lato rounded-sm py-1`}
        />
      ) : column.Header === ' ' ? (
        <input
          type={`hidden`}
          className={`border-2 border-gray-200 text-xs 2xl:text-sm focus:outline-none hover:border-red-300 font-lato rounded-sm py-1`}
        />
      ) : column.Header === 'Amagada' ||
        column.Header === 'Bobina' ||
        column.Header === 'ETT' ||
        column.Header === 'Fixe' ||
        column.Header === 'Vigent' ||
        column.Header === 'Temporal' ||
        column.Header === 'Forat' ||
        column.Header === 'És una eina?' ||
        column.Header === 'Taques' ||
        column.Header === 'Linia' ||
        column.Header === 'Ok' ||
        column.Header === 'Millora' ||
        column.Header === 'Print' ||
        column.Header === 'Actiu' ||
        column.Header === 'Administrador' ||
        column.Header === 'Respós' ? (
        <select
          className="w-full px-1 py-1 pr-1 mt-2 text-xs text-gray-400 border-2 border-gray-200 rounded-sm 2xl:text-sm focus:outline-none hover:border-red-300 font-lato"
          onChange={(e) =>
            e.target.value === 'true' || e.target.value === 'false'
              ? setFilter(e.target.value)
              : setFilter('')
          }
          value={filterValue || ''}
          onClick={(e) => e.stopPropagation()}
        >
          <option value="">Tots</option>
          <option className="text-gray-900" value="true">
            Sí
          </option>
          <option className="text-gray-900" value="false">
            No
          </option>
        </select>
      ) : (
        <input
          required
          className={`columnFilter border-2 border-gray-200 text-xs 2xl:text-sm focus:outline-none hover:border-red-300 font-lato rounded-sm py-1 px-1 ${
            column.id === 'description'
              ? 'w-48 2xl:w-72'
              : column.id === 'client'
              ? 'w-40 2xl:w-64'
              : acabatsColumns.includes(column.id)
              ? 'w-16 2xl:w-32'
              : column.id === 'SemiFinished'
              ? 'w-32 2xl:w-60 '
              : column.id === 'Matter'
              ? 'w-36'
              : weavingColumns.includes(column.id)
              ? 'w-11 mr-4 2xl:w-24'
              : 'w-full'
          }  mt-2 text-black`}
          value={filterValue || ''}
          placeholder={`Filtrar...`}
          onChange={(e) => setFilter(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      )}
    </div>
  )
}

ColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
}
