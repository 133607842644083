import PropTypes from 'prop-types'
import ListItem from './ListItem'

export default function ListTable({ items, handleClick }) {
  return (
    <>
      {items.length > 0 ? (
        <div className="overflow-hidden border-b border-gray-200 rounded-sm shadow-md">
          <table className="min-w-full">
            <thead className="bg-primary">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Referència
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Motiu
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Creat
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Actualitzat
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Sol·licitant
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Revisor
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-sm tracking-wider text-left text-white uppercase font-ms-semi"
                >
                  Estat
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {items.map((item) => (
                <ListItem
                  key={item.RequestId}
                  item={item}
                  handleClick={(e) => handleClick(e, item)}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        'No hi ha sol·licituds de moment.'
      )}
    </>
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}
