import PropTypes from 'prop-types'

export default function StateModal({ handleState, closeModal }) {
  return (
    <div className="flex flex-col justify-between h-48">
      <h3 className="mt-1 text-lg font-lato-bold">Modificar registre</h3>

      <p className="text-base font-bold text-gray-500 font-la-light">
        Estàs segur que el vols canviar l&apos;estat d&apos;aquest registre?
      </p>

      <div className="flex justify-end">
        <button
          onClick={() => closeModal()}
          className="w-1/6 h-10 mx-4 font-medium text-black transition-colors duration-150 bg-white border border-gray-300 rounded-lg focus:shadow-outline hover:bg-gray-300 "
        >
          Cancelar
        </button>

        <button
          onClick={() => handleState()}
          className="w-1/6 h-10 font-medium text-white transition-colors duration-150 border rounded-lg bg-primary border-primary focus:shadow-outline hover:bg-secondary hover:text-white"
        >
          Canviar
        </button>
      </div>
    </div>
  )
}

StateModal.propTypes = {
  handleState: PropTypes.func,
  closeModal: PropTypes.func
}
