import corroEnrollatdorJMB2024 from 'assets/icons/corro-enrotllador-JMB-2024.png'
import corroEnrotllador from 'assets/icons/corro-enrotllador.png'
import pinyonsPlegador from 'assets/icons/pinyons-plegador.png'
import relacioPlegadorJMB2024 from 'assets/icons/relacio-plegador-JMB-2024.png'
import tensioPlegadorJMB2024 from 'assets/icons/tensio-plegador-JMB-2024.png'
import tensioPlegador from 'assets/icons/tensio-plegador.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function FolderForm({ machineCode }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Campos si 'machineCode' es 'PAL': A. Calandra */}
      {/* Campos si 'machineCode' es 'MCH': A. Calandra, Posició */}
      {/* Campos si 'machineCode' es 'SNT': A. Calandra, Pinyons Plegador */}
      {/* Campos si 'machineCode' es 'MYR': A. Calandra, Pinyons Plegador, Tensió */}
      {/* Campos si 'machineCode' es 'TRT': A. Calandra, Posició, Torque */}
      {/* Campos si 'machineCode' es 'JMB': A. Calandra, ?, Pinyons Plegador, Tensió Plegador */}
      {/* Campos si 'machineCode' es 'PLT': A. Calandra, Delta, Pinyons Plegador, Tensió del Plegador, Corró enrotllador */}

      {/* A. Calandra */}
      {/* Se muestra en todos los tipos de 'machineCode', en 'JMB', 'PLT', 'PAL', 'TRT', 'MCH', 'MYR' y 'SNT': */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        A. Calandra:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Calandra"
          className="w-full block-input"
        />
      </div>

      {/* Delta: */}
      {/* Se muestra si 'machineCode' es 'PLT': */}
      {machineCode === 'PLT' && (
        <>
          <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Delta:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name="RequestArticle.Delta"
              className="w-full block-input"
            />
          </div>
        </>
      )}

      {/* Piston JUM */}
      {/* Modificado 28/06/2024 - Ticket Zammad: #36078980 */}
      {/* Se muestra si 'machineCode' es 'JMB': */}
      {machineCode === 'JMB' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Relació Plegador:
          </label>

          <div className="relative w-56 col-span-4">
            <img
              src={relacioPlegadorJMB2024}
              alt="Relació Plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
              <Field
                type="text"
                name="RequestArticle.PistonJUM_v2"
                className="w-20 block-input"
              />

              <Field
                type="text"
                name="RequestArticle.PistonJUM2_v2"
                className="w-20 block-input"
              />
            </div>
          </div>
        </div>
      )}

      {/* Pinyons Plegador (1, 2) */}
      {/* Se muestra si 'machineCode' es 'MYR', 'SNT', 'PLT', 'JMB': */}
      {machineCode !== 'TRT' &&
        machineCode !== 'PAL' &&
        machineCode !== 'MCH' &&
        machineCode !== 'JMB' && (
          <div className="col-span-12">
            <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
              Pinyons Plegador (1, 2):
            </label>

            <div className="relative w-40 col-span-4">
              <img
                src={pinyonsPlegador}
                alt="pinyons plegador"
                className="w-full"
              />

              <div className="absolute bottom-0 flex flex-col items-end justify-around w-full h-full py-1 pr-1 right-2">
                <Field
                  type="text"
                  name="RequestArticle.FolderingPiston1"
                  className="w-20 block-input"
                />

                <Field
                  type="text"
                  name="RequestArticle.FolderingPiston2"
                  className="w-20 block-input"
                />
              </div>
            </div>
          </div>
        )}

      {/* --------------------------------------------------- */}

      {(machineCode === 'PLT' ||
        machineCode === 'JMB' ||
        machineCode === 'MCH' ||
        machineCode === 'TRT' ||
        machineCode === 'MYR') && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Tensió Plegador (1, 2) */}
      {/* Se muestra si 'machineCode' es 'PLT': */}
      {machineCode === 'PLT' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador (1, 2):
          </label>

          <div className="grid w-full grid-cols-2 gap-x-2">
            <div className="flex flex-col justify-center col-span-1 space-y-2">
              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P1:
                </label>

                <Field
                  type="text"
                  name="RequestArticle.P1"
                  className="w-9/12 block-input"
                />
              </div>

              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P2:
                </label>

                <Field
                  type="text"
                  name="RequestArticle.P2"
                  className="w-9/12 block-input"
                />
              </div>

              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P3:
                </label>

                <Field
                  type="text"
                  name="RequestArticle.P3"
                  className="w-9/12 block-input"
                />
              </div>
            </div>

            <div className="relative w-full col-span-1">
              <img
                src={tensioPlegador}
                alt="Tensió Plegador"
                className="py-5 mx-auto w-28"
              />

              <div className="absolute bottom-0 left-0 flex flex-col items-center justify-between w-full h-full">
                <Field
                  type="text"
                  name="RequestArticle.FolderTension1"
                  className="w-20 block-input"
                />

                <Field
                  type="text"
                  name="RequestArticle.FolderTension2"
                  className="w-20 block-input"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tensió Plegador */}
      {/* Modificado 28/06/2024 - Ticket Zammad: #36078980 */}
      {/* Se muestra si 'machineCode' es 'JMB': */}
      {machineCode === 'JMB' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador:
          </label>

          <div className="relative w-56 col-span-4">
            <img
              src={tensioPlegadorJMB2024}
              alt="Tensió Plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
              <Field
                type="text"
                name="RequestArticle.FolderTension1_v2"
                className="w-20 block-input"
              />

              <Field
                type="text"
                name="RequestArticle.FolderTension2_v2"
                className="w-20 block-input"
              />
            </div>
          </div>
        </div>
      )}

      {/* Posició */}
      {/* Se muestra si 'machineCode' es 'MCH': */}
      {machineCode === 'MCH' && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Posició:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <Field
              type="text"
              name="RequestArticle.PositionMonarch"
              className="w-full block-input"
            />
          </div>
        </div>
      )}

      {/* Posició y Torque */}
      {/* Se muestra si 'machineCode' es 'TRT': */}
      {machineCode === 'TRT' && (
        <div className="col-span-12">
          <div className="flex flex-row w-full">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Posició:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <Field
                type="text"
                name="RequestArticle.PositionTerrot1"
                className="w-full block-input"
              />
            </div>
          </div>

          <div className="flex flex-row w-full pt-2">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Torque:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <Field
                type="text"
                name="RequestArticle.PositionTerrot2"
                className="w-full block-input"
              />
            </div>
          </div>
        </div>
      )}

      {/* Tensió */}
      {/* Se muestra si 'machineCode' es 'MYR': */}
      {machineCode === 'MYR' && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Tensió:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <Field
              type="text"
              name="RequestArticle.TensionMayer"
              className="w-full block-input"
            />
          </div>
        </div>
      )}

      {/* --------------------------------------------------- */}

      {(machineCode === 'PLT' || machineCode === 'JMB') && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Corró enrotllador */}
      {/* Se muestra si 'machineCode' es 'PLT': */}
      {machineCode === 'PLT' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi">
            Corró enrotllador:
          </label>

          <div className="relative w-full col-span-4">
            <img
              src={corroEnrotllador}
              alt="Tensió Plegador"
              className="mx-auto md:w-28 xl:w-32"
            />

            <div className="absolute bottom-0 flex flex-col items-start justify-around w-auto h-full md:left-0 lg:left-8 xl:left-20 2xl:left-32">
              <Field
                type="text"
                name="RequestArticle.RollingRoller1"
                className="w-20 block-input"
              />

              <Field
                type="text"
                name="RequestArticle.RollingRoller3"
                className="w-20 block-input"
              />
            </div>

            <div className="absolute bottom-0 flex flex-col items-end justify-between w-auto h-full md:right-0 lg:right-8 xl:right-20 2xl:right-32">
              <Field
                type="text"
                name="RequestArticle.RollingRoller2"
                className="w-20 block-input"
              />

              <Field
                type="text"
                name="RequestArticle.RollingRoller4"
                className="w-20 block-input"
              />
            </div>
          </div>
        </div>
      )}

      {/* Corró enrotllador */}
      {/* Creado 28/06/2024 - Ticket Zammad: #36078980 */}
      {/* Se muestra si 'machineCode' es 'JMB': */}
      {machineCode === 'JMB' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
            Corró Enrotllador:
          </label>

          <div className="flex flex-row space-x-4">
            <div className="flex items-start justify-start pt-6">
              <Field
                type="text"
                name="RequestArticle.RollingRoller1_v2"
                className="w-20 block-input"
              />
            </div>

            <img
              src={corroEnrollatdorJMB2024}
              alt="Corró Enrotllador"
              className="w-16"
            />

            <div className="flex items-end justify-end pb-6">
              <Field
                type="text"
                name="RequestArticle.RollingRoller2_v2"
                className="w-20 block-input"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

FolderForm.propTypes = {
  machineCode: PropTypes.string
}
