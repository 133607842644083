import { TitleContext } from 'contexts/TitleContext'
import Inventory from 'forms/inventory/InventoryForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'
export default function InventoryDetail({ params }) {
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [client, saveClient] = useFetch()

  const [fields, setFields] = useState({
    Description: '',
    Location: '',
    IsTool: false
  })

  const titleEdit = {
    name: `Modificar registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/inventari`
      }
    ],
    deleteInventoryBtn: true,
    inventoryId: params.id
  }
  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/inventari`
      }
    ]
  }

  useEffect(() => {
    if (params.id !== 'nou') {
      setTitle(titleEdit)
      const findClientById = {
        url: `inventorymachine/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }

      saveClient(findClientById)
    } else setTitle(titleCreate)
  }, [])

  useEffect(() => {
    // Set initialValues

    if (client.data)
      setFields({
        Description: client.data.Description,
        Location: client.data.Location,
        IsTool: client.data.IsTool
      })
  }, [client.data])

  const handleChange = (e) => {
    const { name, value } = e.target

    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let url = `inventorymachine`

    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    // Send PUT //
    const clientApiParams = {
      url,
      method,
      body: fields,
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Inventari actualitzat!'
    }
    await saveClient(clientApiParams)
    if (!client.error) setLocation('/inventari')
  }

  console.log('console de fields... ', fields)

  return (
    <div className="w-full p-4 mb-3 bg-white">
      <Inventory
        fields={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

InventoryDetail.propTypes = {
  params: PropTypes.any
}
