// PropTypes
import PropTypes from 'prop-types'

export default function ControlsForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* V.A. (EVS) ESTIL */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V.A. (EVS) Estil:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.ControlStyle}
        </div>
      </div>

      {/* CONTROL Tª */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Control Tª:
      </div>
      <div className="flex items-center h-6 col-span-12 space-x-2 lg:h-full print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="grid items-center w-full grid-cols-12 gap-3 auto-rows-min">
          {/* VMT */}
          <div className="col-span-2 text-sm print:col-span-2">VMT</div>
          <div className="flex items-center w-full h-8 col-span-10 px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
            {values?.VMT}
          </div>

          {/* OMT */}
          <div className="col-span-2 text-sm print:col-span-2">OMT</div>
          <div className="flex items-center w-full h-8 col-span-10 px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
            {values?.OMT}
          </div>

          {/* MAN */}
          <div className="col-span-2 text-sm print:col-span-2">MAN</div>
          <div className="flex items-center w-full h-8 col-span-10 px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
            {values?.MAN}
          </div>

          {/* AUT */}
          <div className="col-span-2 text-sm print:col-span-2">AUT</div>
          <div className="flex items-center w-full h-8 col-span-10 px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
            {values?.AUT}
          </div>
        </div>
      </div>

      {/* TEMPS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Temps:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.ControlTime}
        </div>
        <span>{`"`}</span>
      </div>

      {/* TEMPERATURA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Temperatura:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.ControlTemperature}
        </div>
        <span>ºC</span>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.ControlObservation}
        </div>
      </div>
    </div>
  )
}

ControlsForm.propTypes = {
  values: PropTypes.object
}
