import { TitleContext } from 'contexts/TitleContext'
import ControlVisionForm from 'forms/Control/ControlVision/ControlVisionForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function ControlVisionDetail({ params }) {
  // Context
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()

  // useFetch()
  const [fetchData, setFetchData] = useFetch()
  const [fetchUsers, setFetchUsers] = useFetch()
  const [fetchEquipments, setFetchEquipments] = useFetch()

  // useFetch() - post
  const [postData, setPostData] = useFetch()

  // Var
  const isNew = !!(params.id === 'nou')

  // Estados
  const [fields, setFields] = useState({
    Id: 0,
    Date: '',
    UserId: '',
    UserName: '',
    OF: '',
    EquipmentId: '',
    EquipmentName: '',
    Verification1: false,
    Verification2: false,
    Verification3: false,
    Verification4: false,
    Description: '',
    Style: ''
  })

  useEffect(() => {
    // Establecer el título basado en si es un nuevo registro
    const title = {
      name: isNew ? `Crear registre` : `Modificar registre`,
      deleteControlVisionBtn: !isNew,
      controlvisionId: !isNew ? params.id : null,
      button: !isNew ? 'print' : null,
      buttons: [
        {
          name: 'Tornar',
          link: '/visioncontrol'
        }
      ]
    }
    setTitle(title)

    const fetchData = async () => {
      // Definir las llamadas a la API
      const fetchDataParams = {
        url: `visioncontrol/${params.id}`,
        method: 'GET',
        messageKo: "No s'han pogut recuperar les dades"
      }

      const fetchUsersParams = {
        url: `user`,
        method: 'GET',
        messageKo: "No s'han pogut recuperar els usuaris"
      }

      const fetchEquipmentsParams = {
        url: `inventorymachine/block/controlvisio`,
        method: 'GET',
        messageKo: "No s'ha pogut recuperar l'inventari"
      }

      // Ejecutar todas las solicitudes de fetch
      // - Si no es un nuevo registro, se hacen 3 llamadas: 1 para obtener el registro, 1 para usuarios y 1 para inventario.
      // - Si es un nuevo registro, se hacen 2 llamadas: 1 para usuarios y 1 para inventario.
      if (!isNew) {
        await Promise.all([
          setFetchData(fetchDataParams),
          setFetchUsers(fetchUsersParams),
          setFetchEquipments(fetchEquipmentsParams)
        ]).catch((e) => {
          console.log(e)
        })
      } else {
        await Promise.all([
          setFetchUsers(fetchUsersParams),
          setFetchEquipments(fetchEquipmentsParams)
        ]).catch((e) => {
          console.log(e)
        })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (fetchData?.data) {
      setFields({
        Id: fetchData?.data?.Id,
        Date: fetchData?.data?.Date,
        UserId: fetchData?.data?.UserId,
        UserName: fetchData?.data?.UserName,
        OF: fetchData?.data?.OF,
        EquipmentId: fetchData?.data?.EquipmentId,
        EquipmentName: fetchData?.data?.EquipmentName,
        Verification1: fetchData?.data?.Verification1,
        Verification2: fetchData?.data?.Verification2,
        Verification3: fetchData?.data?.Verification3,
        Verification4: fetchData?.data?.Verification4,
        Description: fetchData?.data?.Description,
        Style: fetchData?.data?.Style
      })
    }
  }, [fetchData?.data])

  const handleSubmit = async (values) => {
    let url = `visioncontrol`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    // Send PUT //
    const clientApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al recuperar dades dels Controls Visió',
      messageOk: 'Control Visió actualitzat!'
    }
    await setPostData(clientApiParams)
    if (!postData.error) setLocation('/visioncontrol')
  }

  return fetchUsers?.data !== null &&
    fetchUsers?.data !== undefined &&
    fetchEquipments?.data !== null &&
    fetchEquipments?.data !== undefined ? (
    <div className="w-full p-4 bg-white">
      <ControlVisionForm
        fields={fields}
        users={fetchUsers?.data}
        equipments={fetchEquipments?.data}
        handleSubmit={handleSubmit}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

ControlVisionDetail.propTypes = {
  params: PropTypes.any
}
