import { useContext, useEffect, useState } from 'react'

import useFetch from 'hooks/useFetch'

import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'

// Forms
import EditPasswordForm from 'forms/profile/editPassword'
import EditProfileForm from 'forms/profile/editProfile'

export default function Profile() {
  const [loading, setLoading] = useState(false)

  // Set Profile Title (title + buttons)
  const { setTitle } = useContext(TitleContext)
  useEffect(() => {
    const profileTitle = {
      name: 'Perfil',
      buttons: [{ name: 'Tancar sessió', link: '/logout' }]
    }
    setTitle(profileTitle)
  }, [])

  // Load user data from Context
  const { user, setUser } = useContext(UserContext)
  const readOnly = user.Role.Id === 2

  // Fetch update profile photo
  const [profile, profileAction] = useFetch()
  // Fetch update profile photo
  const [, profilePhotoAction] = useFetch()

  // Trigger profile.data changes (to upload userContext and redirect)
  useEffect(() => {
    if (profile.data) {
      // Delete token from object
      delete profile.data.Token
      // Update Context and Localstorage Values
      setUser((prevUser) => ({ ...prevUser, ...profile.data }))
    }
  }, [profile.data])

  // Function to edit profile info
  const handleSubmitProfile = async (values) => {
    setLoading(true)
    // If photo has been updated
    if (values.Photo) {
      // Update Photo
      await updateProfileImage(values.photo)
    }
    // Update profile info
    await updateProfile(values)
    setLoading(false)
  }

  // Function to edit password
  const handleSubmitPassword = async (values) => {
    setLoading(true)
    // Delete password confirm from object
    delete values.passwordConfirmation

    // Update profile password
    await updateProfile(values)
    setLoading(false)
  }

  // Function to update profile to API
  const updateProfile = async (values) => {
    // Delete photo from object
    delete values.photo

    // Fetch api Profile Update
    const profileAPIParams = {
      // url: `user/${user.Id}`,
      url: `user/changeprofile/${user.Id}`,
      method: 'PUT',
      body: values,
      messageOk: 'Perfil modificat correctament',
      messageKo: 'Error al modificar perfil. Les dades son incorrectes'
    }
    console.log('VALORS: ', values)
    await profileAction(profileAPIParams)
  }

  // Function to update and upload profile image to API
  const updateProfileImage = async (photo) => {
    // Fetch api Profile Photo Uplaod
    const formData = new FormData()
    formData.append('image', photo)

    const profilePhotoAPIParams = {
      url: `user/saveImage/${user.Id}`,
      method: 'PUT',
      formData: formData,
      messageKo: 'Error al modificar la foto de perfil'
    }
    await profilePhotoAction(profilePhotoAPIParams)
  }

  return (
    !readOnly && (
      <div className="flex flex-row w-full p-4 space-x-4 bg-white">
        <div className="flex flex-col w-full px-5 py-5 bg-gray-100 border-2 border-gray-300 rounded-sm">
          <EditProfileForm
            loading={loading}
            user={user}
            handleSubmit={handleSubmitProfile}
          />
        </div>

        <div className="flex flex-col w-full px-5 py-5 bg-gray-100 border-2 border-gray-300 rounded-sm">
          <EditPasswordForm
            loading={loading}
            handleSubmit={handleSubmitPassword}
          />
        </div>
      </div>
    )
  )
}
