// PropTypes
import PropTypes from 'prop-types'

export default function DescriptionForm({ users, values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* ARTICLE */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Article:{' '}
        {values?.Status === 'A' ? (
          <span className="text-green-600">(A - Aprovat)</span>
        ) : (
          <span className="text-red-600">(P - Prototip)</span>
        )}
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.ArticleDescription}
        </div>
      </div>

      {/* Ample */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Ample:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Width}
        </div>
      </div>

      {/* SEMIELABORAT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Semielaborat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.SemiFinishedDescription}
          <span className="mt-1 ml-2 text-sm italic text-gray-400">
            {values?.SemiFinished}
          </span>
        </div>
      </div>

      {/* COMPOSICIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Composició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Composition}
        </div>
      </div>

      {/* CLIENT */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Client:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Client}
        </div>
      </div>

      {/* SECADORA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Secadora:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.IsDryer ? 'Sí' : 'No'}
        </div>
      </div>

      {/* RESP. CLIENT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Resp. client:
      </div>
      <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm min-h-8 overflow-ellipsis">
          {values?.ClientManager?.length > 0
            ? values?.ClientManager?.map((managerId, index, array) => {
                const manager = users.find(
                  (user) => user.Id === Number(managerId)
                )
                const isLastItem = index === array.length - 1

                return isLastItem ? manager?.Name : manager?.Name + ', '
              })
            : ''}
        </div>
      </div>

      {/* EDICIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Edició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.EditionNumber}
        </div>
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  users: PropTypes.array,
  values: PropTypes.object
}
