import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import GamesTable from './gamesTableCompare'

export default function PointGraduationForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter,
  games
}) {
  // Calcular los estilos
  const getFieldStyles = (field) => {
    const hasChanged = valuesBefore[field] !== valuesAfter[field]

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <div className="grid w-full grid-cols-12 gap-2 p-2 xl:grid-cols-11">
      {/* Columna 1: */}
      {/* Campo 'Alçada cilindre (seccions)': */}
      <div className="grid items-center grid-cols-4 col-span-12">
        <label className="block col-span-4 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              className="w-16 block-input-disabled"
              style={getFieldStyles('CylinderHeight1')}
              value={
                side === 'left'
                  ? valuesBefore?.CylinderHeight1
                  : valuesAfter?.CylinderHeight1
              }
              disabled
            />

            <Field
              type="text"
              className="w-16 block-input-disabled"
              style={getFieldStyles('CylinderHeight2')}
              value={
                side === 'left'
                  ? valuesBefore?.CylinderHeight2
                  : valuesAfter?.CylinderHeight2
              }
              disabled
            />
          </div>
        </div>
      </div>

      {/* Columna 2: */}
      {/* Campo 'Posició Aro Platines': */}
      <div className="flex flex-col flex-1 col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Platines:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={getFieldStyles('PositionTurntables')}
          value={
            side === 'left'
              ? valuesBefore?.PositionTurntables
              : valuesAfter?.PositionTurntables
          }
          disabled
        />
      </div>

      {/* Columna 3: */}
      {/* Campo 'Posició Aro Guiafils': */}
      <div className="flex flex-col flex-1 col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Guiafils:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={getFieldStyles('PositionGuiafils')}
          value={
            side === 'left'
              ? valuesBefore?.PositionGuiafils
              : valuesAfter?.PositionGuiafils
          }
          disabled
        />
      </div>

      {/* Campo 'Tensiòmetre': */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Tensiòmetre:</div>

        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('PassTensiometer')}
          value={
            side === 'left'
              ? valuesBefore?.PassTensiometer
                ? 'Sí'
                : 'No'
              : valuesAfter?.PassTensiometer
              ? 'Sí'
              : 'No'
          }
          disabled
        />
      </div>

      {/* Tabla: Juegos */}
      <div className="w-full col-span-12 place-self-start">
        <GamesTable games={games} side={side} isAnswered={isAnswered} />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object,
  games: PropTypes.any
}
