import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import VersatilityForm from 'forms/RRHH/Versatility/VersatilityForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function VersatilityDetail({ params }) {
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [getClient, setGetClient] = useFetch()
  const [client, saveClient] = useFetch()
  const { handleModal } = useContext(ModalContext)
  const [deletedDocument, deleteDocumentAction] = useFetch()
  const [documentAction, saveDocumentAction] = useFetch()
  const [addDocumentsToTable, setAddDocumentsToTable] = useState([])

  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')
  const isNew = params.id === 'nou'

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API:
  const [fields, setFields] = useState({
    Id: 0,
    IdTreb: 0,
    TrebName: '',
    TipusTreb: '',
    Nau: '',
    Ett: false,
    Vigent: false,
    fixe: false,
    Temporal: false,
    Idproces: null,
    ProcesName: '',
    IdArea: null,
    AreaName: '',
    IdLlocTreball: null,
    LlocTreballName: '',
    LlocTreballFunctions: '',
    LlocTreballResponsabilities: '',
    LlocTreballCompetences: '',
    FunctionsResponsabilitiesValuationState: 3,
    FunctionsResponsabilitiesVerificationDate: '',
    IdFunctionsResponsabilitiesEvaluator: null,
    FunctionsResponsabilitiesEvaluator: '',
    CompetencesValuationState: 3,
    CompetencesVerificationDate: '',
    IdCompetencesEvaluator: null,
    CompetenceEvaluator: '',
    Nivell: null,
    Datact: datetime.slice(0, 10),
    IdCheck: 0,
    ValidatFaI: 1.0,
    IsRemoved: false,
    VersalityMatrixDocuments: []
  })

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // modificar un registro estableceremos como título de la página 'Modificar':
  const titleEdit = {
    name: `Modificar registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/polivalencies`
      }
    ],
    deleteVersatilityBtn: true,
    versatilityId: params.id
  }

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // crear un nuevo registro estableceremos como título de la página 'Crear':
  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/polivalencies`
      }
    ]
  }

  useEffect(() => {
    // Si el id del registro no es 'nou' significa que el usuario quiere editar un registro ya existente:
    if (params.id !== 'nou') {
      // Pondremos el título de editar:
      setTitle(titleEdit)

      // Llamamos a la API para recoger el registro que se quiere editar:
      const findClientById = {
        url: `VersatilityMatrix/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      setGetClient(findClientById)
    } else {
      // Pondremos el título de crear:
      setTitle(titleCreate)
    }
  }, [])

  // useEffect() para crear un objeto con los datos que nos llegan de la API, para eso se usa el useState() [fields, setFields]:
  useEffect(() => {
    if (getClient.data && !getClient.data.IsRemoved) {
      setFields({
        Id: getClient.data.Id,
        IdTreb: getClient.data.Idtreb_Id ? getClient.data.Idtreb_Id.Id : null,
        TrebName: getClient.data.Idtreb_Id
          ? getClient.data.Idtreb_Id.Name
          : null,
        TipusTreb: getClient.data.Idtreb_Id
          ? getClient.data.Idtreb_Id.Tipu
          : null,
        Nau: getClient.data.Idtreb_Id ? getClient.data.Idtreb_Id.Nau : null,
        Ett: getClient.data.Idtreb_Id ? getClient.data.Idtreb_Id.Ett : null,
        Vigent: getClient.data.Idtreb_Id
          ? getClient.data.Idtreb_Id.Vigent
          : null,
        fixe: getClient.data.Idtreb_Id ? getClient.data.Idtreb_Id.fixe : null,
        Temporal: getClient.data.Idtreb_Id
          ? getClient.data.Idtreb_Id.Temporal
          : null,
        Idproces: getClient.data.Idproces,
        ProcesName: getClient.data.IdProces_Id
          ? getClient.data.IdProces_Id.Name
          : null,
        IdArea:
          getClient.data.Idlloctreball_Id &&
          getClient.data.Idlloctreball_Id.Area
            ? getClient.data.Idlloctreball_Id.Area.Id
            : null,
        AreaName:
          getClient.data.Idlloctreball_Id &&
          getClient.data.Idlloctreball_Id.Area
            ? getClient.data.Idlloctreball_Id.Area.Name
            : null,
        IdLlocTreball: getClient.data.Idlloctreball_Id
          ? getClient.data.Idlloctreball_Id.Id
          : null,
        LlocTreballName: getClient.data.Idlloctreball_Id
          ? getClient.data.Idlloctreball_Id.Description
          : null,
        LlocTreballFunctions: getClient.data.Idlloctreball_Id
          ? getClient.data.Idlloctreball_Id.Functions
          : null,
        LlocTreballResponsabilities: getClient.data.Idlloctreball_Id
          ? getClient.data.Idlloctreball_Id.Responsibilities
          : null,
        LlocTreballCompetences: getClient.data.Idlloctreball_Id
          ? getClient.data.Idlloctreball_Id.Competences
          : null,
        FunctionsResponsabilitiesValuationState:
          getClient.data.FunctionsResponsabilitiesValuationState,
        FunctionsResponsabilitiesVerificationDate: getClient.data
          .FunctionsResponsabilitiesVerificationDate
          ? getClient.data.FunctionsResponsabilitiesVerificationDate.slice(
              0,
              10
            )
          : '',
        IdFunctionsResponsabilitiesEvaluator:
          getClient.data.IdFunctionsResponsabilitiesEvaluator,
        FunctionsResponsabilitiesEvaluator: getClient.data
          .FunctionsResponsabilitiesEvaluator
          ? getClient.data.FunctionsResponsabilitiesEvaluator.Name
          : '',
        CompetencesValuationState: getClient.data.CompetencesValuationState,
        CompetencesVerificationDate: getClient.data.CompetencesVerificationDate
          ? getClient.data.CompetencesVerificationDate.slice(0, 10)
          : '',
        IdCompetencesEvaluator: getClient.data.IdCompetencesEvaluator,
        CompetenceEvaluator: getClient.data.CompetenceEvaluator
          ? getClient.data.CompetenceEvaluator.Name
          : '',
        Nivell: getClient.data.Nivell,
        Datact: getClient.data.Datact
          ? getClient.data.Datact.slice(0, 10)
          : null,
        IdCheck: getClient.data.IdCheck,
        ValidatFaI: getClient.data.ValidFaI,
        IsRemoved: getClient.data.IsRemoved,
        VersalityMatrixDocuments: getClient.data.VersatilityMatrixDocuments
      })

      setAddDocumentsToTable(getClient.data.VersatilityMatrixDocuments)
    }
  }, [getClient.data])

  // Función para guardar el documento:
  const handleSubmit = async (values) => {
    const versatilityFieldsToSave = {
      Id: values.Id,
      Idtreb: values.IdTreb,
      Idproces: values.Idproces,
      Idarea: values.IdArea,
      IdllocTreball: values.IdLlocTreball,
      Nivell: values.Nivell,
      Datact: values.Datact,
      IdCheck: values.IdCheck,
      ValidatFaI: values.ValidatFaI,
      IsRemoved: values.IsRemoved,
      FunctionsResponsabilitiesValuationState:
        values.FunctionsResponsabilitiesValuationState,
      FunctionsResponsabilitiesVerificationDate:
        values.FunctionsResponsabilitiesVerificationDate,
      IdFunctionsResponsabilitiesEvaluator:
        values.IdFunctionsResponsabilitiesEvaluator,
      FunctionsResponsabilitiesEvaluator:
        values.FunctionsResponsabilitiesEvaluator,
      CompetencesValuationState: values.CompetencesValuationState,
      CompetencesVerificationDate: values.CompetencesVerificationDate,
      IdCompetencesEvaluator: values.IdCompetencesEvaluator,
      CompetenceEvaluator: values.CompetenceEvaluator,
      VersalityMatrixDocuments: null
    }

    let url = `VersatilityMatrix`
    let method = 'post'

    if (versatilityFieldsToSave.Id !== 0) {
      method = 'put'
      url += `/${versatilityFieldsToSave.Id}`
    }

    // Send PUT or POST //
    const clientApiParams = {
      url,
      method,
      body: versatilityFieldsToSave,
      messageKo: 'Error al actualitzar la polivalència!',
      messageOk: 'Polivalència actualitzada!'
    }
    await saveClient(clientApiParams)

    if (!client.error) setLocation('/polivalencies')
  }

  // Función para añadir un nuevo documento:
  const uploadDocument = async (e) => {
    const files = e.currentTarget.files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    // Llamada a la API para añadir el nuevo archivo:
    const documentSaveToDataBase = {
      url: `VersatilityMatrix/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Document guardat!',
      messageKo: 'Error al guardar el document!'
    }
    await saveDocumentAction(documentSaveToDataBase)
  }

  // useEffect() para la función uploadDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (documentAction.data) {
      const stringToJsonParseDocuments = JSON.parse(documentAction.data)
      setAddDocumentsToTable(stringToJsonParseDocuments)
    }
  }, [documentAction.data])

  // Función para eliminar un fichero de la tabla de ficheros adjuntos:
  const deleteDocument = async (id) => {
    // Llamada a la API para eliminar el documento seleccionado:
    const deleteDocumentParams = {
      url: `VersatilityMatrix/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Document eliminat!',
      messageKo: 'Error al eliminar el document'
    }
    await deleteDocumentAction(deleteDocumentParams)

    handleModal('hide')
  }

  // useEffect() para la función deleteDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (deletedDocument.data) {
      setAddDocumentsToTable(deletedDocument.data)
    }
  }, [deletedDocument.data])

  return (params.id === 'nou' && fields.Id === 0) ||
    (params.id !== 'nou' && fields.Id !== 0) ? (
    <div
      className="w-full p-4 bg-white rounded-lg borde-2"
      style={{ borderColor: 'whitesmoke' }}
    >
      <VersatilityForm
        handleSubmit={handleSubmit}
        fields={fields}
        uploadDocument={uploadDocument}
        deleteDocument={deleteDocument}
        addDocumentsToTable={addDocumentsToTable}
        params={params}
        isNew={isNew}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

VersatilityDetail.propTypes = {
  params: PropTypes.any
}
