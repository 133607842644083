import PropTypes from 'prop-types'
import Form1 from 'forms/ftacabatsrequest/descriptionCreate'
import Form2 from 'forms/ftacabatsrequest/featuresCreate'
import Form3 from 'forms/ftacabatsrequest/openMachineCreate'
import Form4 from 'forms/ftacabatsrequest/ultrasonicSettingsCreate'
import Form5 from 'forms/ftacabatsrequest/foulardsCreate'
import Form6 from 'forms/ftacabatsrequest/edgesCreate'
import Form7 from 'forms/ftacabatsrequest/controlsCreate'
import Form8 from 'forms/ftacabatsrequest/packagingCreate'

export default function Block({
  title,
  className,
  setFieldValue,
  users,
  values
}) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md border-blue-200`}
    >
      <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
        <span>{title}</span>
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return <Form1 users={users} values={values} />
          case 'Característiques':
            return <Form2 values={values} />
          case "Màquina d'obrir":
            return <Form3 />
          case 'Reglatges ultrasons':
            return <Form4 />
          case 'Acabats foulard':
            return <Form5 />
          case 'Vores':
            return <Form6 />
          case 'Controls':
            return <Form7 />
          case 'Embalatge':
            return <Form8 values={values} />
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  users: PropTypes.array,
  values: PropTypes.object
}
