import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Descripció',
      accessor: 'Jobs_Description',
      Filter: ColumnFilter
    },
    {
      Header: 'Dependència',
      accessor: 'Jobs_Dependence',
      Filter: ColumnFilter
    },
    {
      Header: 'Àrea',
      accessor: 'Jobs_Area',
      Filter: ColumnFilter
    }, 
    {
      Header: 'Actiu',
      accessor: 'Jobs_IsActive',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id: item.Id,
          Jobs_Description: item.Description,
          Jobs_Dependence: item.JobDependence
            ? item.JobDependence.Description
            : item.Id === item.JobDependenceId
            ? item.Description
            : '-',
          Jobs_Area: item.Area ? item.Area.Name : '-',
          Jobs_IsActive: !item.IsRemoved
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return (
    tableData.length > 0 && (
      <Table
        tableData={tableData}
        tableColumns={tableColumns}
        handleClick={handleClick}
      />
    )
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
