import { createContext, useState } from 'react'

import PropTypes from 'prop-types'

export const MTContext = createContext(null)

export const MTContextProvider = ({ children }) => {
  const [MT, setMT] = useState(0)

  return (
    <MTContext.Provider value={{ MT, setMT }}>
      {children}
    </MTContext.Provider>
  )
}
MTContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
