import { DriveEtaSharp } from '@mui/icons-material'
import PropTypes from 'prop-types'

import logoExcel from 'assets/img/logoExcel.png'

import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { useLocation } from 'wouter'

export default function TableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  rows,
  getRows
}) {
  const [location, setLocation] = useLocation()

  return (pageOptions.length > 1 || location.includes('polivalencies')) && (
    <div className={`border-t-2 border-red-800 text-center print:hidden ${!location.includes('polivalencies') ? 'py-5' : 'py-2' } px-2 bg-red-800 grid grid-cols-3`}>     
        <div
          className={`${!location.includes('polivalencies') && 'hidden'} float-left flex flex-row justify-start items-center font-bold text-base text-white rounded p-1 ml-0 mr-auto`}
        >
          <img src={logoExcel} 
            className='cursor-pointer mr-2' 
            width="65" 
            height="65" 
            title="Descarregar" 
            onClick={() => getRows(rows)}
          />
          {(rows !== null && rows !== undefined) && rows.length + " registres"}
        </div> 

      <div className="mx-auto flex flex-row justify-center items-center col-start-2">      
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="font-bold rounded-full text-white disabled:opacity-0 text-center align-middle flex justify-center items-center my-auto"
          style={{ width: '30px', marginRight: '7px', height: '24px' }}
        >
          <BsArrowLeftCircle size={40} className='text-red-300' />
        </button>

        <span className='text-white font-semibold'>
          <strong>
            {pageIndex + 1} - {pageOptions.length}
          </strong>
        </span>

        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="font-bold rounded-full text-white disabled:opacity-0 text-center align-middle flex justify-center items-center my-auto"
          style={{ width: '30px', marginLeft: '7px', height: '24px' }}
        >
          <BsArrowRightCircle size={40} className='text-red-300' />
        </button>
      </div>

      <select
        className={`float-right rounded border-2 border-red-300 p-1 ml-auto mr-0`}
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {[25, 50, 100, 200, 500].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize} registres
          </option>
        ))}
      </select>      
    </div>
  )
}

TableBar.propTypes = {
  previousPage:    PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex:       PropTypes.number,
  pageOptions:     PropTypes.array,
  nextPage:        PropTypes.func,
  canNextPage:     PropTypes.bool,
  pageSize:        PropTypes.number,
  setPageSize:     PropTypes.func,
  rows:            PropTypes.array,
  getRows:         PropTypes.func
}
