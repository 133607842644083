import { Field } from 'formik'
import PropTypes from 'prop-types'
import Select from 'react-select'

export default function ClientForm({ setFieldValue, users, values }) {
  const labelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const fieldStyles =
    'flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9'

  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* CODI */}
      <div className={labelStyles}>Codi:</div>
      <div className={`${fieldStyles} h-8`}>
        <Field
          type="text"
          name="Code"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* NOM */}
      <div className={labelStyles}>Nom:</div>
      <div className={`${fieldStyles} h-8`}>
        <Field
          type="text"
          name="Name"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* ETIQUETES */}
      <div className={labelStyles}>Etiquetes:</div>
      <div className={`${fieldStyles} h-8`}>
        <Field
          type="text"
          name="Tag"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* POSICIÓ */}
      <div className={labelStyles}>Posició:</div>
      <div className={`${fieldStyles} h-8`}>
        <Field
          type="text"
          name="Position"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* ESPECIFICACIONS BOBINA */}
      <div className={labelStyles}>Especificacions bobina:</div>
      <div className={`${fieldStyles} h-8`}>
        <Field
          type="text"
          name="Notes"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* BOBINA */}
      <div className={labelStyles}>Bobina:</div>
      <div className={`${fieldStyles} h-8`}>
        <Field
          className="w-5 h-5 text-gray-600 cursor-pointer"
          type="checkbox"
          name="Coil"
          onChange={(e) => {
            setFieldValue('Coil', e.target.checked ? 'SI' : 'NO')
          }}
          checked={values.Coil === 'SI'}
        />
      </div>

      {/* RESPONSABLES */}
      <div className={labelStyles}>Responsables:</div>
      <div className={`${fieldStyles} min-h-8`}>
        <Select
          className={`text-grey-darker w-full`}
          onChange={(e) => {
            values.Manager = []
            for (const manager of e) {
              values.Manager.push(manager.Id.toString())
            }
            setFieldValue(values.Manager)
          }}
          name="Manager"
          placeholder="Selecciona..."
          options={users}
          isMulti
          value={users.filter((option) =>
            values?.Manager?.includes(option.Id.toString())
          )}
          getOptionLabel={(o) => o.Name}
          getOptionValue={(o) => o.Id}
          closeMenuOnSelect={false}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: '0.125rem',
              minHeight: '2rem',
              cursor: 'pointer',
              boxShadow: 'none'
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              padding: '0.25rem 0.5rem',
              position: 'static',
              cursor: 'pointer',
              rowGap: '0.25rem'
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              margin: '0 0.25rem 0 0',
              alignItems: 'center',
              cursor: 'pointer'
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '0',
              paddingBottom: '0',
              cursor: 'pointer'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              marginLeft: '0',
              marginRight: '0'
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              height: '2rem',
              cursor: 'pointer'
            })
          }}
        />
      </div>
    </div>
  )
}

ClientForm.propTypes = {
  setFieldValue: PropTypes.func,
  users: PropTypes.array,
  values: PropTypes.object
}
