import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

// @todo Add Sort direction to table columns

// Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
// con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  const tableColumns = [
    {
      Header: 'Area Actual',
      accessor: 'MapaEmpreses_AreaActual',
      Filter: ColumnFilter
    },
    {
      Header: 'MapaEmpreses_Empresa',
      accessor: 'Empresa',
      Filter: ColumnFilter
    },
    {
      Header: 'Nau',
      accessor: 'MapaEmpreses_Nau',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom Treballador',
      accessor: 'MapaEmpreses_Nom',
      Filter: ColumnFilter
    },
    {
      Header: 'Jornada',
      accessor: 'MapaEmpreses_Jornada',
      Filter: ColumnFilter
    },
    {
      Header: '% Jornada',
      accessor: 'MapaEmpreses_JC',
      Filter: ColumnFilter
    },
    {
      Header: 'Ett',
      accessor: 'MapaEmpreses_Ett',
      Filter: ColumnFilter
    },
    {
      Header: 'Temporal',
      accessor: 'MapaEmpreses_Temporal',
      Filter: ColumnFilter
    },
    {
      Header: 'Fixe',
      accessor: 'MapaEmpreses_Fixe',
      Filter: ColumnFilter
    },
    // {
    //   // Only for debugging purpouses ( Vigent === false not shown )
    //   Header: 'Vigent',
    //   accessor: 'Vigent',
    //   Filter: ColumnFilter
    // },

  ]

  /** *************************************************************** */
  /** @todo Remove hardcoded array and get data from database instead */
  /** *************************************************************** */
  const areaOptions = [
    { value: 1, label: 'Recepció' },
    { value: 2, label: 'Teixiduria'},
    { value: 3, label: 'Tintura'},
    { value: 4, label: 'Acabats'},
    { value: 5, label: 'Expedició'},
    { value: 6, label: 'Magatzem'},
    { value: 7, label: 'Repàs'},
    { value: 8, label: 'Manteniment'},
    { value: 9, label: 'Instal·lacions'},
    { value: 10, label: 'Vigilància'},
    { value: 11, label: 'Laboratori'},
    { value: 12, label: 'Responsables / Tècnics'},
    { value: 13, label: 'Direcció'},
    { value: 14, label: 'Administració'},
    { value: 15, label: 'Compres i Finances'},
    { value: 16, label: 'Neteja'},
    { value: 17, label: 'Qualitat'},
    { value: 18, label: 'Rentat'},
  ]

  const empresaOptions = [
    { value: 1, label: 'CETRIKO, S.L.' },
    { value: 2, label: 'DOS KNITTING FABRICS, S.L.' },
    { value: 3, label: 'TRES KNITTING FABRICS, S.L.U.' },
    { value: 4, label: '4 KNITTING FABRICS, S.L.' },
    { value: 5, label: 'PIBASA SERVEIS, S.L.' },
  ]

  const tipuOptions = [    
    { label: 'Operari', value: 'Operari'},
    { label: 'Oficina', value: 'Oficina'},
  ]

  const jornadaOptions = [    
    { value: '', label: 'Cap'},
    { value: 'Matí', label: 'Matí'},
    { value: 'Tarda', label: 'tarda'},
    { value: 'Nit', label: 'Nit'},
    { value: 'Partida', label: 'Partida'},
  ]

  const nauOptions = [    
    { value: 'Cetriko', label: 'Cetriko'},
    { value: 'Dos', label: 'Dos'},
    { value: 'Quatre', label: 'Quatre'},
  ]
  /** *************************************************************** */
  /** @todo Remove hardcoded array and get data from database instead */
  /** *************************************************************** */


  // options values are replace at selector element
  for (const element of items) {
    areaOptions.find(o => o.areaactual === element.areaactual)

    for (const item of areaOptions) {
        if ( item.value === element.areaactual){
          element.areaactual=item.label
        }
    }

    for (const item of empresaOptions) {
      if ( item.value === element.Empresa){
        element.Empresa=item.label
      }
    }
    
  }

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []
      
      items.forEach(function (item, index) {
        if ((item.IsRemoved === false) && (item.Vigent=== true)) {
          itemsData.push({
            Id:                      item.Id,
            MapaEmpreses_Nom:        item.Name, 
            MapaEmpreses_Tipu:       item.Tipu, 
            MapaEmpreses_Ett:        item.Ett, 
            MapaEmpreses_Nau:        item.Nau,	
            MapaEmpreses_Vigent:     item.Vigent, 
            MapaEmpreses_Fixe:       item.fixe, 
            MapaEmpreses_Temporal:   item.Temporal, 
            MapaEmpreses_Empresa:    item.Empresa, 
            MapaEmpreses_AreaActual: item.areaactual, 
            MapaEmpreses_JC:         item.JC * 100, 
            MapaEmpreses_Jornada:    item.Jornada
          })
        }
      })

      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 &&  (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}