import ListTable from 'components/Control/CheckList/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const CheckListList = () => {
  // Context
  const { setTitle } = useContext(TitleContext)
  const { handleModal } = useContext(ModalContext)
  const [, setLocation] = useLocation()

  // Fetch
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallGetEquipments, setApiCallGetEquipments] = useFetch()

  useEffect(() => {
    const title = {
      name: 'Check List (RAM)',
      buttons: [
        {
          name: 'Nou check list',
          link: `/checklist/nou/`
        }
      ]
    }
    setTitle(title)

    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar Users del ECAP'
    }
    setApiCallGetUsers(userListAPIParams)

    const equipmentListAPIParams = {
      url: `inventorymachine/isTool`,
      method: 'GET',
      messageKo: 'Error al recuperar dades'
    }
    setApiCallGetEquipments(equipmentListAPIParams)

    const apiCallGet = {
      url: `checklist`,
      method: 'GET',
      messageKo: 'Error al recuperar dades'
    }
    setApiCallGet(apiCallGet)

    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/checklist/${item.Id}`)
  }

  return getApiCallGet.data ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable
        users={getApiCallGetUsers}
        equipments={getApiCallGetEquipments}
        items={getApiCallGet.data}
        handleClick={handleClick}
      />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default CheckListList
