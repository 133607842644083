// PropTypes
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Field } from 'formik'

// REACT ICONS
import { MdArrowDropDown } from 'react-icons/md'
import Select from 'react-select'

export default function DescriptionForm({ users, values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* ARTICLE */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Article:{' '}
        {values?.Status === 'A' ? (
          <span className="text-green-600">(A - Aprovat)</span>
        ) : (
          <span className="text-red-600">(P - Prototip)</span>
        )}
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.ArticleDescription}
        </div>
      </div>

      {/* Ample */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Ample:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 text-gray-500 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Width}
        </div>
      </div>

      {/* SEMIELABORAT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Semielaborat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.SemiFinishedDescription}
          <span className="mt-1 ml-2 text-sm italic text-gray-400">
            {values?.SemiFinished}
          </span>
        </div>
      </div>

      {/* COMPOSICIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Composició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Composition}
        </div>
      </div>

      {/* CLIENT */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Client:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Client}
        </div>
      </div>

      {/* SECADORA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Secadora:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 bg-white print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.IsDryer"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* RESP. CLIENT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Resp. client:
      </div>
      <div className="flex items-center col-span-12 space-x-2 min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
        {/* ¡NEW VERSION -> Client manager field has been disabled since managers are set in 'Clients' section! */}
        <div className="flex items-center w-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm min-h-8 overflow-ellipsis">
          {values?.ClientManager?.length > 0
            ? values?.ClientManager?.map((managerId, index, array) => {
                const manager = users.find(
                  (user) => user.Id === Number(managerId)
                )
                const isLastItem = index === array.length - 1

                return isLastItem ? manager?.Name : manager?.Name + ', '
              })
            : ''}
        </div>

        {/* ¡OLD VERSION -> Client manager field has been disabled since managers are set in 'Clients' section! */}
        {/* <Select
          className={`text-grey-darker w-full`}
          onChange={(e) => {
            values.ClientManager = []
            for (const manager of e) {
              values.ClientManager.push(manager.Id.toString())
            }
            setFieldValue(values.ClientManager)
          }}
          name="RequestArticle.ClientManager"
          placeholder="Selecciona..."
          options={users}
          isMulti
          value={users.filter((option) =>
            values?.ClientManager?.includes(option.Id.toString())
          )}
          getOptionLabel={(o) => o.Name}
          getOptionValue={(o) => o.Id}
          closeMenuOnSelect={false}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: '0.125rem',
              minHeight: '2rem',
              cursor: 'pointer'
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              padding: '0.25rem 0.5rem',
              position: 'static',
              cursor: 'pointer',
              rowGap: '0.25rem'
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              margin: '0 0.25rem 0 0',
              alignItems: 'center',
              cursor: 'pointer'
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '0',
              paddingBottom: '0',
              cursor: 'pointer'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              marginLeft: '0',
              marginRight: '0'
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              cursor: 'pointer'
            })
          }}
        /> */}
      </div>

      {/* EDICIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Edició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.EditionNumber}
        </div>
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  setFieldValue: PropTypes.func,
  users: PropTypes.array,
  values: PropTypes.object
}
