import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import GamesTable from './gamesTableCreate'

export default function PointGraduationForm({
  setFieldValue,
  threads,
  allValues
}) {
  return (
    <div className="grid w-full grid-cols-12 gap-2 p-2 xl:grid-cols-11">
      {/* Columna 1: */}
      {/* Campo 'Alçada cilindre (seccions)': */}
      <div className="grid items-center grid-cols-4 col-span-12">
        <label className="block col-span-4 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              name="RequestArticle.CylinderHeight1"
              className="w-16 block-input"
            />

            <Field
              type="text"
              name="RequestArticle.CylinderHeight2"
              className="w-16 block-input"
            />
          </div>
        </div>
      </div>

      {/* Columna 2: */}
      {/* Campo 'Posició Aro Platines': */}
      <div className="flex flex-col flex-1 col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Platines:
        </label>

        <Field
          as="textarea"
          name="RequestArticle.PositionTurntables"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input h-52"
        />
      </div>

      {/* Columna 3: */}
      {/* Campo 'Posició Aro Guiafils': */}
      <div className="flex flex-col flex-1 col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Guiafils:
        </label>

        <Field
          as="textarea"
          name="RequestArticle.PositionGuiafils"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input h-52"
        />
      </div>

      {/* Campo 'Tensiòmetre': */}
      <div className="flex items-center col-span-12 space-x-1 xl:col-span-11">
        <label
          className="block pr-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PassTensiometer"
        >
          Tensiòmetre
        </label>

        <Field
          type="checkbox"
          name="RequestArticle.PassTensiometer"
          className="px-2 py-0.5 border text-sm border-gray-300 rounded-sm outline-none focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400 w-6 h-6 ml-1"
        />
      </div>

      {/* Tabla: Juegos */}
      <div className="w-full col-span-12">
        <GamesTable
          threadOptions={threads}
          setFieldValue={setFieldValue}
          allValues={allValues}
        />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {
  setFieldValue: PropTypes.func,
  threads: PropTypes.any,
  allValues: PropTypes.object
}
