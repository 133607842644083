import ListTable from 'components/RRHH/TrainingPlans/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const trainingplanList = () => {
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)
  const [receivedApiParams, setReceivedApiParams] = useFetch()

  useEffect(() => {
    // Esteblecemos la información que se mostrará en la barra del título:
    const pageTitle = {
      name: 'Seguiment de Formacions',
      buttons: []
    }
    setTitle(pageTitle)

    // Llamada a la API para recoger todos los registros de la base de datos:
    const getApiParams = {
      url: `trainingplan`,
      method: 'GET',
      messageKo: 'Error al recuperar els Seguiments'
    }
    setReceivedApiParams(getApiParams)

    //* Avoid bug that when inventory is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/seguiment-formacio/${item.Id}`)
  }

  return receivedApiParams.data &&
    receivedApiParams.data !== undefined &&
    receivedApiParams.data[0] !== null ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={receivedApiParams.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default trainingplanList
