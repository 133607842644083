import { useContext, useEffect } from 'react'
import { TitleContext } from 'contexts/TitleContext'

import { useLocation } from 'wouter'
import { ModalContext } from 'contexts/ModalContext'

import useFetch from 'hooks/useFetch'
import ListTable from 'components/RRHH/MapaEmpresas/ListTable'

import loadingIco from 'components/ui/Logo/loading.gif'

const TreballadorsList = () => {
    const { setTitle } = useContext(TitleContext)
    const [jobList, jobListAction] = useFetch()
    const [, setLocation] = useLocation()
    const { handleModal } = useContext(ModalContext)

    useEffect(() => {
        // Set title
        const jobTitle = {
          name: 'Mapa Empresas',
          // buttons: [
          //   {
          //     name: 'Nou Treballador',
          //     link: `/treballadors/nou/`
          //   }
          // ],          
        }
        setTitle(jobTitle)
            
        const jobListAPIParams = {
          url: `treballador`,
          method: 'GET',
          messageKo: 'Error al recuperar dades dels treballadors'
        }
    
        jobListAction(jobListAPIParams)
       
          //* Avoid bug that when item is  deleted and user is redirected to this page, modal is still open
        handleModal('hide')
      }, [])

      const handleClick = (e, item) => {
        e.preventDefault()
        setLocation(`/treballadors/${item.Id}`)
      }
      return jobList.data ? (
        <div className="md:flex">
          <div className="w-full mb-3 md:mb-0  md:mr-1 bg-white">
            <div className="flex flex-col">
              <ListTable
                items={jobList.data} 
                handleClick={handleClick} 
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='h-screen grid align-middle'>
            <div className='grid grid-cols-3 content-center'>
                <div> </div>
                <div>
                    <p>Carregant dades ... 
                    <img src={loadingIco}></img>
                    </p>
                </div>
            </div>
        </div>
      )
}

export default TreballadorsList
