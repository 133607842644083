import { css } from '@emotion/react'
import DeleteModal from 'components/DeleteModal'
import CloneFTModal from 'components/FTAcabats/Details/CloneFTModal'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useState, useEffect } from 'react'
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineFileAdd,
  AiOutlineHistory,
  AiOutlinePrinter
} from 'react-icons/ai'
import { BsEye, BsEyeFill, BsEyeSlash, BsTrash } from 'react-icons/bs'
import { IoMdArrowBack } from 'react-icons/io'
import { IoDuplicateOutline, IoPersonAddOutline } from 'react-icons/io5'
import BarLoader from 'react-spinners/BarLoader'
import { Link, useLocation } from 'wouter'
import Notifications from '../Navbar/Notifications/Notifications'
import StateModal from 'components/StateModal'

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 3px;
  border-color: red;
`

export default function Title() {
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const { title } = useContext(TitleContext)
  const [, ftOperatorHiddenAction] = useFetch()
  const [ftClone, ftCloneAction] = useFetch()
  const [TSWeavingDuplicate, TSWeavingDuplicateAction] = useFetch()

  const [PreventiveAcabatsDuplicate, PreventiveAcabatsDuplicateAction] =
    useFetch()
  const [PreventiveTeixDuplicate, PreventiveTeixDuplicateAction] = useFetch()

  const [ftDelete, ftDeleteAction] = useFetch()
  const [getChangeStateApiCall, setChangeStateApiCall] = useFetch()
  const [clientDelete, clientDeleteAction] = useFetch()
  const print = () => window.print()

  const [operatorHidden, setOperatorHidden] = useState(title.ftHidden)
  const [getSaveRecordState, setSaveRecordState] = useFetch()
  const { handleModal } = useContext(ModalContext)

  //* Handle deleting inventory
  const deleteInventory = async () => {
    const disableAPIParams = {
      url: `inventorymachine/${title.inventoryId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Registre de Inventari eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/inventari')
  }

  const deleteNC = async () => {
    const disableAPIParams = {
      url: `noconformitie/${title.ncId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Registre de NC eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    setLocation('/no-conformitats')
  }

  const deletePT = async () => {
    const disableAPIParams = {
      url: `preventiveweaving/${title.codPTid}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Registre de Preventiu eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    setLocation('/preventeix')
  }

  const deletePA = async () => {
    const disableAPIParams = {
      url: `preventive/${title.codPAid}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Registre de Preventiu eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    setLocation('/prevenacabats')
  }

  const deleteMT = async () => {
    const disableAPIParams = {
      url: `maintenancepart/${title.mtId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Registre de Manteniment eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    setLocation('/mantteix')
  }

  //* Handle deleting job
  const deleteJob = async () => {
    const disableAPIParams = {
      url: `job/${title.jobId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Lloc de treball eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/lloc-treball')
  }

  //* Handle deleting treballador
  const deleteTreballador = async () => {
    const disableAPIParams = {
      url: `treballador/${title.jobId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Treballador eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/treballadors')
  }

  //* Handle deleting Versatility:
  const deleteVersatility = async () => {
    const disableAPIParams = {
      url: `VersatilityMatrix/${title.versatilityId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Polivalència eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/polivalencies')
  }

  //* Handle deleting encuesta:
  const deleteSatisfactionSurvey = async () => {
    const disableAPIParams = {
      url: `SatisfactionSurvey/${title.jobId}`,
      method: 'DELETE',
      messageKo: 'Error al recuperar dades de enquestes',
      messageOk: 'Enquesta eliminada!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/enquesta-satisfaccio')
  }

  //* Handle deleting ForecastTraining
  const deleteForecastTraining = async () => {
    const disableAPIParams = {
      url: `forecasttraining/${title.forecastTrainingId}`,
      method: 'DELETE',
      messageKo: 'Error al reliminar Pla',
      messageOk: 'Pla eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/pla-formacio')
  }

  //* Handle deleting TrainingPlan
  const deleteTrainingPlan = async () => {
    const apiCallDelete = {
      url: `trainingplan/${title.trainingplanId}`,
      method: 'DELETE',
      messageKo: 'Error al eliminar Seguiment!',
      messageOk: 'Seguiment eliminat!'
    }
    await setSaveRecordState(apiCallDelete)
    if (!getSaveRecordState.error) setLocation('/seguiment-formacio')
  }

  //* Handle deleting suggestion
  const deleteSuggestion = async () => {
    const disableAPIParams = {
      url: `suggestion/${title.suggestionId}`,
      method: 'DELETE',
      messageKo: 'Error al eliminar el suggeriment',
      messageOk: 'Suggeriment eliminada!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/sugerencia')
  }

  //* Handle deleting ControlVision
  const deleteControlVision = async () => {
    const disableAPIParams = {
      url: `visioncontrol/${title.controlvisionId}`,
      method: 'DELETE',
      messageKo: 'Error al eliminar el Control Visió',
      messageOk: 'Control Visió eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/visioncontrol')
  }

  //* Handle deleting Check List (RAM)
  const deleteCheckList = async () => {
    const disableAPIParams = {
      url: `checklist/${title.checklistId}`,
      method: 'DELETE',
      messageKo: 'Error al eliminar el Check List',
      messageOk: 'Check List eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/checklist')
  }

  //* Handle deleting Check List (MAQ)
  const deleteCheckListMaq = async () => {
    const disableAPIParams = {
      url: `checklistmaq/${title.checklistmaqId}`,
      method: 'DELETE',
      messageKo: 'Error al eliminar el Check List',
      messageOk: 'Check List eliminat!'
    }
    await setSaveRecordState(disableAPIParams)
    if (!getSaveRecordState.error) setLocation('/checklistmaq')
  }

  //* Handle cloning an FT
  const deleteFT = async (id) => {
    const ftDeleteAPIParams = {
      url: `tsweaving/${id}`,
      method: 'DELETE',
      messageOk: 'Registre de FT eliminat!',
      messageKo: 'Error al eliminar FT'
    }
    await ftDeleteAction(ftDeleteAPIParams)
    if (!ftDelete.error) setLocation('/ft-teixiduria')
  }

  //* Handle change state
  const updateState = () => {
    const apiParams = {
      url: `${title.registerType}/${title.registerId}`,
      method: 'DELETE',
      messageKo: 'Error al modificar el registre!',
      messageOk: title.registerState ? 'Registre inactiu!' : 'Registre actiu!'
    }

    setChangeStateApiCall(apiParams)
    title.registerState = !title.registerState
    handleModal('hide')
  }

  const cloneFT = async (e, idToClone) => {
    e.preventDefault()
    const ftCloneAPIParams = {
      url: `technicalsheet/clone/${title.ftArticleCode}/${idToClone} `,
      method: 'POST',
      messageOk: 'FT clonada!',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    await ftCloneAction(ftCloneAPIParams)
    if (!ftClone.error) setLocation('/ft-acabats')
  }

  const duplicateFT = async () => {
    const TSWeavingDuplicateAPIParams = {
      url: `tsweaving/clone/${title.id}`,
      method: 'POST',
      messageOk: 'FT duplicada!',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    await TSWeavingDuplicateAction(TSWeavingDuplicateAPIParams)

    if (!TSWeavingDuplicate.error) setLocation('/ft-teixiduria')
  }

  // Clonar Preventiu Acabats
  const duplicatePreventiveAcabats = async () => {
    const PreventiveAcabatsDuplicateAPIParams = {
      url: `preventive/clone/${title.codPAid}`,
      method: 'POST',
      messageOk: 'Preventiu duplicat!',
      messageKo: 'Error al intentar duplicar Preventiu'
    }
    await PreventiveAcabatsDuplicateAction(PreventiveAcabatsDuplicateAPIParams)
    if (!PreventiveAcabatsDuplicate.error) setLocation('/prevenacabats')
  }

  // Clonar Preventiu Teixiduria
  const duplicatePreventiveTeix = async () => {
    const PreventiveTeixDuplicateAPIParams = {
      url: `preventiveweaving/clone/${title.codPTid}`,
      method: 'POST',
      messageOk: 'Preventiu duplicat!',
      messageKo: 'Error al intentar duplicar Preventiu'
    }
    await PreventiveTeixDuplicateAction(PreventiveTeixDuplicateAPIParams)
    if (!PreventiveTeixDuplicate.error) setLocation('/preventeix')
  }

  async function changeVisibility() {
    if (title.hideFTOperaris) {
      const ftOperatorHiddenAPIParams = {
        url: `technicalsheet/${title.ftId}`,
        method: 'PUT',
        body: { IsOperatorHidden: !operatorHidden },
        messageOk: 'Actualitzat!',
        messageKo: 'Error al recuperar dades del ECAP'
      }

      // document.body.style.cursor = "progress"

      const btnBack = document.getElementById('backBtn')
      btnBack.disabled = true

      await ftOperatorHiddenAction(ftOperatorHiddenAPIParams)

      // document.body.style.cursor = "default"

      btnBack.disabled = false
    }

    setOperatorHidden((operariHidden) => !operariHidden)
  }

  return (
    <div className="flex items-center justify-start w-full h-8">
      <div className="flex items-center w-full px-4 mx-auto">
        <h1 className="mr-4 text-sm text-white 2xl:text-2xl font-ms-bold">
          {title.name?.toUpperCase()}
        </h1>

        {title.loading && (
          <div className="flex flex-col items-center mb-1 mr-6 text-white">
            <span className="text-xs ">Actualitzant...</span>
            <BarLoader color="white" css={override} width={100} />{' '}
          </div>
        )}

        <Notifications />
      </div>

      <div className="flex items-center justify-end w-full pr-2">
        {title.date && (
          <div className="flex items-center w-3/6 text-sm font-ms-bold ">
            <span className="hidden mr-2 text-sm text-white font-ms 2xl:block">
              Última modificació:
            </span>
            <span className="mr-12 text-white">
              {title.date.slice(8, 10)}
              {title.date.slice(4, 7)}-{title.date.slice(0, 4)}{' '}
              {title.date.slice(11, 16)}h
            </span>
            {/* <span className='text-white '>Edicio: {title.edition}</span> */}
          </div>
        )}

        {title.hideFTOperaris && user.Role.Id === 1 && (
          <div className="flex items-center h-10 px-3 py-1 rounded font-ms-semi 2xl:px-5">
            <label className="text-white " htmlFor="hideFTOperaris">
              <AiOutlineEyeInvisible size={25} />
            </label>
            <input
              type="checkbox"
              className="w-6 h-6 p-2 px-4 ml-2 cursor-pointer form-checkbox"
              name="hideFTOperaris"
              defaultChecked={title.ftHidden}
              onChange={() =>
                // setOperatorHidden((operatorHidden) => !operatorHidden)
                changeVisibility()
              }
            />
          </div>
        )}

        {/* Muevo el bloque de los botons en esta sección sino se ve mal en el FRONT */}
        {title.deleteMTBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteMT}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {title.deleteNCBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteNC}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* IMPRIMIR TABLA POLIVALÉNCIES */}
        {title.exportVersatilityTable && (
          <button
            ref={title.btnRef}
            title="Imprimir"
            // onClick={title.downloadMatrixTableAsExcel}
            className="h-10 px-3 ml-2 mr-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <AiOutlinePrinter size={25} />
          </button>
        )}

        {/* BORRAR TREBALLADOR */}
        {title.deleteTreballadorBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteTreballador}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR POLIVALÉNCIA */}
        {title.deleteVersatilityBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteVersatility}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR ENQUESTA DE SATISFACCIÓ */}
        {title.deleteSatisfactionSurveyBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteSatisfactionSurvey}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR LLOC TREBALL */}
        {title.deleteJobBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteJob}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR PLA FORMACIÓ */}
        {title.deleteForecastTrainingBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteForecastTraining}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR SEGUIMENT */}
        {title.deleteTrainingPlanBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteTrainingPlan}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR SUGGERIMENT */}
        {title.deleteSuggestionBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteSuggestion}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* ESTAT (ACTIU / INACTIU) */}
        {title.stateBtn && (
          <button
            title="Estat"
            onClick={() =>
              handleModal(
                <StateModal
                  handleState={updateState}
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className={`h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 rounded font-ms-semi focus:shadow-outline ${
              title.registerState
                ? 'text-emerald-500 border-emerald-500 hover:text-white hover:bg-emerald-500'
                : 'text-red-500 border-red-500 hover:text-white hover:bg-red-500'
            }`}
          >
            {title.registerState ? (
              <>
                <BsEye size={25} />
              </>
            ) : (
              <>
                <BsEyeSlash size={25} />
              </>
            )}
          </button>
        )}

        {/* BORRAR CONTROL VISIÓ */}
        {title.deleteControlVisionBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteControlVision}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR CHECKLIST (RAM) */}
        {title.deleteCheckListBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteCheckList}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {/* BORRAR CHECKLIST (MAQ) */}
        {title.deleteCheckListMaqBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteCheckListMaq}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {title.btnPTdel && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deletePT}
                  element="registre"
                  // closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {title.btnPAdel && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deletePA}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {title.deleteInventoryBtn && (
          <button
            title="Eliminar"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteInventory}
                  element="registre"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
          >
            <BsTrash size={25} />
          </button>
        )}

        {title.deleteBtn && user.Role.Id === 1 && (
          <button
            title="Eliminar"
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
            onClick={() =>
              handleModal(
                <DeleteModal
                  deleteUserOrFT={deleteFT}
                  id={title.id}
                  element="fitxa tècnica de teixiduria"
                  closeModal={() => handleModal('hide')}
                />
              )
            }
          >
            <BsTrash size={25} />
          </button>
        )}

        {title.cloneFTAcabat && user.Role.Id === 1 && (
          <button
            title="Clonar"
            className={`ml-2 h-10 px-3 2xl:px-5 text-charcoal font-ms-semi  transition-colors duration-150 bg-white border-2 border-gray-300 rounded focus:shadow-outline  btn-print cursor-pointer hover:bg-gray-400 hover:text-white`}
            onClick={() =>
              handleModal(
                <CloneFTModal
                  cloneFT={cloneFT}
                  closeModal={() => handleModal('hide')}
                  options={title.options}
                />
              )
            }
          >
            <IoDuplicateOutline size={25} />
          </button>
        )}

        {title.cloneBtn && user.Role.Id === 1 && (
          <button
            title="Duplicar"
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
            onClick={duplicateFT}
          >
            <IoDuplicateOutline size={25} />
          </button>
        )}

        {/* CLONAR PREVENTIU ACABATS */}
        {title.clonePreventiveAcabatsBtn && user.Role.Id === 1 && (
          <button
            title="Duplicar"
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
            onClick={duplicatePreventiveAcabats}
          >
            <IoDuplicateOutline size={25} />
          </button>
        )}

        {/* CLONAR PREVENTIU TEIXIDURIA */}
        {title.clonePreventiveTeixBtn && user.Role.Id === 1 && (
          <button
            title="Duplicar"
            className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white btn-print"
            onClick={duplicatePreventiveTeix}
          >
            <IoDuplicateOutline size={25} />
          </button>
        )}

        {title.button === 'print' && (
          <button
            title="Imprimir"
            onClick={print}
            className="h-10 px-3 ml-2 mr-4 transition-colors duration-150 bg-white border-2 border-gray-300 rounded text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white"
          >
            <AiOutlinePrinter size={25} />
          </button>
        )}

        {title.buttons &&
          title.buttons.length > 0 &&
          title.buttons.map((button) => {
            return button.name === 'Modificacions' ? (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  title="Modificacions"
                  className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded sm:text-xs lg:text-base 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white"
                >
                  <AiOutlineHistory size={25} />
                </button>
              </Link>
            ) : button.name === 'Tornar' ? (
              <Link to={button.link} key={button.name}>
                <button
                  id="backBtn"
                  type="button"
                  title="Tornar"
                  className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded sm:text-xs lg:text-base 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white"
                >
                  <IoMdArrowBack size={25} />
                </button>
              </Link>
            ) : button.name === 'Crear' ? (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  title="Crear"
                  className="h-10 px-3 ml-2 transition-colors duration-150 bg-white border-2 border-gray-300 rounded sm:text-xs lg:text-base 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white"
                >
                  <IoPersonAddOutline size={25} />
                </button>
              </Link>
            ) : button.name === 'Nova FT Teixiduria' ? (
              <Link to={button.link} key={button.name}>
                <button
                  title="Nova FT"
                  type="button"
                  className="h-10 px-3 transition-colors duration-150 bg-white border-2 border-gray-300 rounded sm:text-xs lg:text-base 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white"
                >
                  <AiOutlineFileAdd size={25} />
                </button>
              </Link>
            ) : (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  className="h-10 px-3 transition-colors duration-150 bg-white border-2 border-gray-300 rounded sm:text-xs lg:text-base 2xl:px-5 text-charcoal font-ms-semi focus:shadow-outline hover:bg-gray-400 hover:text-white"
                >
                  {button.name.toUpperCase()}
                </button>
              </Link>
            )
          })}
      </div>
    </div>
  )
}
