import RamForm from 'forms/ftacabatsrequest/ramCompare'
import PropTypes from 'prop-types'

export default function RamsVersion1({
  ramTab,
  setRamTab,
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  const compareRams = (x) => {
    const requestRam = valuesBefore?.find(
      (ram) => ram.RamNumber === x
    )
    const historyRamKeys = Object.keys(requestRam.HistoryRam)
    let isDifferent = false
    let i = 0

    while (i < historyRamKeys.length && !isDifferent) {
      const key = historyRamKeys[i]

      if (
        key !== 'RequestRamId' &&
        key !== 'HistoryRamId' &&
        key !== 'RamId' &&
        key !== 'RamNumber' &&
        key !== 'Article'
      ) {
        if (requestRam.HistoryRam[key] !== requestRam[key]) {
          isDifferent = true
        }
      }

      i++
    }

    return isDifferent ? (
      <>
        <a
          className={`${
            side === 'left'
              ? 'hover:bg-red-600 border-red-600'
              : isAnswered ? 'hover:bg-emerald-600 border-emerald-600' : 'hover:bg-amber-600 border-amber-600'
          } border-2 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
                  ${
                    ramTab === x
                      ? `text-white ${
                          side === 'left' ? 'bg-red-600' : isAnswered ? 'bg-emerald-600' : 'bg-amber-600'
                        }`
                      : `${
                          side === 'left' ? 'text-red-600' : isAnswered ? 'text-emerald-600' : 'text-amber-600'
                        } bg-white`
                  }
                `}
          onClick={(e) => {
            e.preventDefault()
            setRamTab(x)
          }}
          data-toggle="tab"
          href={`#link1${x}`}
          role="tablist"
        >
          RAM {x}
        </a>
        <span
          className={`w-4 h-4 ${
            side === 'left' ? 'bg-red-600' : isAnswered ? 'bg-emerald-600' : 'bg-amber-600'
          } p-0.5 rounded-full absolute left-auto -top-1 right-5 border-2 border-white`}
        ></span>
      </>
    ) : (
      <a
        className={`${
          side === 'left'
            ? 'hover:bg-gray-400 border-gray-400'
            : 'hover:bg-blue-300 border-blue-300'
        } border-2 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
              ${
                ramTab === x
                  ? `text-white ${
                      side === 'left' ? 'bg-gray-400' : 'bg-blue-300'
                    }`
                  : `${
                      side === 'left' ? 'text-gray-400' : 'text-blue-300'
                    } bg-white`
              }
            `}
        onClick={(e) => {
          e.preventDefault()
          setRamTab(x)
        }}
        data-toggle="tab"
        href={`#link1${x}`}
        role="tablist"
      >
        RAM {x}
      </a>
    )
  }

  return (
    <div
      className={`flex flex-col col-span-6 bg-white border-2 border-collapse ${
        side === 'left' ? 'border-gray-300' : 'border-blue-200'
      }  rounded shadow-md print:shadow-none`}
    >
      <div
        className={`flex items-center justify-between w-full px-2 py-2 text-sm uppercase ${
          side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
        } print:py-1 font-ms-bold`}
      >
        <span>REGLATGES RAM</span>
      </div>

      <div className="flex flex-wrap w-full">
        <ul className="flex flex-row w-full pt-4 pb-2 list-none" role="tablist">
          {/* Mostrar 'RAM 1': */}
          <li className="relative flex-auto mr-1 text-center">
            {compareRams(1)}
          </li>

          {/* Mostrar 'RAM 2': */}
          <li className="relative flex-auto mx-1 text-center">
            {compareRams(2)}
          </li>

          {/* Mostrar 'RAM 3': */}
          <li className="relative flex-auto mx-1 text-center">
            {compareRams(3)}
          </li>

          {/* Mostrar 'RAM 4': */}
          <li className="relative flex-auto mx-1 text-center">
            {compareRams(4)}
          </li>

          {/* Mostrar 'RAM 5': */}
          <li className="relative flex-auto ml-1 text-center">
            {compareRams(5)}
          </li>
        </ul>

        <div className="relative flex flex-col w-full min-w-0 p-2 break-words bg-white">
          <div className="tab-content tab-space">
            {/* Mostrar 'RAM 1': */}
            <div className={ramTab === 1 ? 'grid' : 'hidden'} id="link1">
              <RamForm
                ramNumber={1}
                ramIndex={
                  side === 'left'
                    ? valuesBefore?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 1
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '1'
                        }
                        return -1
                      })
                    : valuesAfter?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 1
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '1'
                        }
                        return -1
                      })
                }
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            </div>

            {/* Mostrar 'RAM 2': */}
            <div className={ramTab === 2 ? 'grid' : 'hidden'} id="link2">
              <RamForm
                ramNumber={2}
                ramIndex={
                  side === 'left'
                    ? valuesBefore?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 2
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '2'
                        }
                        return -1
                      })
                    : valuesAfter?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 2
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '2'
                        }
                        return -1
                      })
                }
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            </div>

            {/* Mostrar 'RAM 3': */}
            <div className={ramTab === 3 ? 'grid' : 'hidden'} id="link3">
              <RamForm
                ramNumber={3}
                ramIndex={
                  side === 'left'
                    ? valuesBefore?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 3
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '3'
                        }
                        return -1
                      })
                    : valuesAfter?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 3
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '3'
                        }
                        return -1
                      })
                }
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            </div>

            {/* Mostrar 'RAM 4': */}
            <div className={ramTab === 4 ? 'grid' : 'hidden'} id="link4">
              <RamForm
                ramNumber={4}
                ramIndex={
                  side === 'left'
                    ? valuesBefore?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 4
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '4'
                        }
                        return -1
                      })
                    : valuesAfter?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 4
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '4'
                        }
                        return -1
                      })
                }
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            </div>

            {/* Mostrar 'RAM 5': */}
            <div className={ramTab === 5 ? 'grid' : 'hidden'} id="link5">
              <RamForm
                ramNumber={5}
                ramIndex={
                  side === 'left'
                    ? valuesBefore?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 5
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '5'
                        }
                        return -1
                      })
                    : valuesAfter?.findIndex((ram) => {
                        if (typeof ram.RamNumber === 'number') {
                          return ram.RamNumber === 5
                        } else if (typeof ram.RamNumber === 'string') {
                          return Number(ram.RamNumber) === '5'
                        }
                        return -1
                      })
                }
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RamsVersion1.propTypes = {
  ramTab: PropTypes.number,
  setRamTab: PropTypes.func,
  side: PropTypes.string.isRequired,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.array,
  valuesAfter: PropTypes.array
}
