import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { UserContext } from 'contexts/UserContext'
import { Field, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaSearch, FaTrash } from 'react-icons/fa'
import { MdOutlineUploadFile } from 'react-icons/md'
import Select from 'react-select'
import { useLocation } from 'wouter'

const TreballadorForm = ({
  handleSubmit,
  fields,
  uploadDocument,
  deleteDocument,
  addDocumentsToTable,
  params,
  isNew
}) => {
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const hiddenFileInput = useRef(null)

  const { handleModal } = useContext(ModalContext)

  // useFetch() para las llamadas a la API:
  const [apiAreaOptions, setApiAreaOptions] = useFetch()
  const [apiBusinesses, setApiBusinesses] = useFetch()
  const [apiProfiles, setApiProfiles] = useFetch()
  const [apiShifts, setApiShifts] = useFetch()
  const [apiWarehouses, setApiWarehouses] = useFetch()

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  // Este useEffect() se hizo para recoger la información, que iria en los filtros del formulario, directamente de la base de datos:
  useEffect(async () => {
    // Llamada a la API para recoger todas las 'Areas' de la base de datos ('dbo.Areas'):
    const getApiAreaOptions = {
      url: `area`,
      method: 'GET',
      messageKo: 'Error al recuperar les Arees!'
    }

    // Llamada a la API para recoger todas las 'Empresas' de la base de datos ('dbo.Businesses'):
    const getApiBusinesses = {
      url: `business`,
      method: 'GET',
      messageKo: 'Error al recuperar les Empreses!'
    }

    // Llamada a la API para recoger todos los 'Tipos' de la base de datos ('dbo.Profiles'):
    const getApiProfiles = {
      url: `profile`,
      method: 'GET',
      messageKo: 'Error al recuperar els Tipus!'
    }

    // Llamada a la API para recoger todas las 'Jornadas' de la base de datos ('dbo.Shifts'):
    const getApiShifts = {
      url: `shift`,
      method: 'GET',
      messageKo: 'Error al recuperar les Jornades!'
    }

    // Llamada a la API para recoger todas las 'Naves' de la base de datos ('dbo.Warehouses'):
    const getApiWarehouses = {
      url: `warehouse`,
      method: 'GET',
      messageKo: 'Error al recuperar les Naus!'
    }

    // 'Promise' en este caso, se usa para hacer múltiples llamadas a la API en fila, una tras otra.
    // Mejora el tiempo de carga de la página. Si hacemos cada llamada a la API por separado,
    // provoca una carga más lenta de la página, para poner en contexto, el 'Promise' ahorra 3s de carga:
    await Promise.all([
      setApiAreaOptions(getApiAreaOptions),
      setApiBusinesses(getApiBusinesses),
      setApiProfiles(getApiProfiles),
      setApiShifts(getApiShifts),
      setApiWarehouses(getApiWarehouses)
    ]).catch((e) => {
      console.log(e)
    })
  }, [])

  // Función para cambiar de tab entr 'Polivalències' y 'Formacions':
  function openTab(evt, tabName) {
    let i = 0
    let tabcontent = []
    let tablinks = []

    tabcontent = document.getElementsByClassName('tabcontent')
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }

    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
      tablinks[i].className = tablinks[i].className.replace(' h-full', ' h-5/6')
      tablinks[i].className = tablinks[i].className.replace(
        ' bg-primary-dark',
        ' bg-primary'
      )
    }

    document.getElementById(tabName).style.display = 'block'
    evt.currentTarget.className += ' active'
    evt.currentTarget.className += ' h-full'
    evt.currentTarget.className += ' bg-primary-dark'
  }

  // El campo 'Nivell' está hardcodeado en 'VersatilityForm.js', por lo tanto aún no tenemos una tabla en la base de datos
  // para 'Nivell':
  const nivells = [
    { Id: '1', Name: 'Independent' },
    { Id: '2', Name: 'Maestría' },
    { Id: '3', Name: 'Formació' }
  ]

  // En el campo 'Nivell' se guarda el Id del 'Nivell' pero si mostramos el Id los usuarios no sabrán a que 'Nivell' nos referimos
  // por eso creamos esta función que con el Id que nos llega de la base de datos buscamos el 'Nivell' en un array hardcoded 'Nivells':
  function getNameNivell(nivellPolivalencia) {
    let selectedNivell = ''

    const getNivell = nivells.find((nivell) => nivell.Id === nivellPolivalencia)

    if (getNivell.Name) {
      selectedNivell = getNivell.Name
    }

    return selectedNivell
  }

  // Al hacer múltiples llamadas a la API, la información puede tardar en llegar, por eso ponemos estos condicionales que indican
  // que hasta que no nos lleguen todos los datos, no se muestre la tabla, porque nos puede dar problemas:
  return apiAreaOptions.data === undefined ||
    !apiAreaOptions.data ||
    apiBusinesses.data === undefined ||
    !apiBusinesses.data ||
    apiProfiles.data === undefined ||
    !apiProfiles.data ||
    apiShifts.data === undefined ||
    !apiShifts.data ||
    apiWarehouses.data === undefined ||
    !apiWarehouses.data ? (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        // validationSchema={DescriptionSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <div
            id="idTabGeneral"
            className="grid grid-cols-1 mb-4 rounded-lg gap-x-4"
          >
            <div id="idForm" className="rounded-lg shadow-md print:shadow-none">
              <Form
                className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded-lg"
                id="formTreballadorEdit"
              >
                <h1 className="col-span-4 py-2 text-2xl text-center bg-blue-200 print:hidden font-ms-bold">
                  {!isNew ? 'TREBALLADOR #' + values.Id : 'NOU TREBALLADOR'}
                </h1>

                {/* Contenedor con todos los inputs: */}
                <div className="grid grid-cols-5 px-5 py-5 mx-2 print:m-1 print:p-3 ">
                  {/* Información del trabajador: */}
                  <div className="py-2 pl-2 pr-14">
                    <div className="relative grid items-start h-full bg-white border-2 border-blue-200 rounded-lg">
                      <form action="#" className="h-full">
                        <div className="flex flex-col items-center justify-around h-full p-5 divide-y divide-blue-200 divide-dashed">
                          {/* Número de trabajador: */}
                          <div
                            className={`h-full flex flex-row justify-between w-full items-center px-2 py-3 print:bg-white`}
                          >
                            <label
                              className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                              htmlFor="Dependence"
                            >
                              Nº:
                            </label>
                            <div className="flex">{values.Id}</div>
                          </div>

                          {/* Check 'Vigente': */}
                          <div
                            className={`h-full flex flex-row justify-between w-full items-center px-2 py-3 print:bg-white`}
                          >
                            <label
                              className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                              htmlFor="Dependence"
                            >
                              Vigent:
                            </label>
                            <div className="flex rounded">
                              <input
                                type="checkbox"
                                // defaultValue="No"
                                value="Sí"
                                className="w-6 h-6 ml-1"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  })
                                  // setTimeout(submitForm, 0)
                                }}
                                name="Vigent"
                                checked={values.Vigent}
                              />
                            </div>
                          </div>

                          {/* Check 'ETT': */}
                          <div
                            className={`h-full flex flex-row justify-between w-full items-center px-2 py-3 print:bg-white`}
                          >
                            <label
                              className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                              htmlFor="Dependence"
                            >
                              ETT:
                            </label>
                            <div className="flex rounded">
                              <input
                                type="checkbox"
                                defaultValue="No"
                                className="w-6 h-6 ml-1"
                                onChange={(e) => {
                                  handleChange(e)
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  })
                                  // setTimeout(submitForm, 0)
                                }}
                                name="Ett"
                                checked={values.Ett}
                              />
                            </div>
                          </div>

                          {/* Check 'Temp': */}
                          <div
                            className={`h-full flex flex-row justify-between w-full items-center px-2 py-3 print:bg-white`}
                          >
                            <label
                              className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                              htmlFor="Dependence"
                            >
                              Temporal:
                            </label>
                            <div className="flex rounded">
                              <input
                                type="checkbox"
                                defaultValue="No"
                                className="w-6 h-6 ml-1"
                                onChange={(e) => {
                                  handleChange(e)
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  })
                                  // setTimeout(submitForm, 0)
                                }}
                                name="Temporal"
                                checked={values.Temporal}
                              />
                            </div>
                          </div>

                          {/* Check 'Fix': */}
                          <div
                            className={`h-full flex flex-row justify-between w-full items-center px-2 py-3 print:bg-white`}
                          >
                            <label
                              className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                              htmlFor="Dependence"
                            >
                              Fixe:
                            </label>
                            <div className="flex rounded">
                              <input
                                type="checkbox"
                                defaultValue="No"
                                className="w-6 h-6 ml-1"
                                onChange={(e) => {
                                  handleChange(e)
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  })
                                  // setTimeout(submitForm, 0)
                                }}
                                name="fixe"
                                checked={values.fixe}
                              />
                            </div>
                          </div>

                          {/* Check 'Borrat': */}
                          <div
                            className={` ${values.IsRemoved ? '' : 'hidden'}`}
                          >
                            <div className="p-2 py-1 text-sm text-red-600 font-ms-semi">
                              Borrat:
                            </div>
                            <div className="flex p-2 py-1 rounded ">
                              <input
                                type="checkbox"
                                defaultValue="No"
                                className="w-6 h-6 ml-1"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  })
                                  // setTimeout(submitForm, 0)
                                }}
                                name="IsRemoved"
                                checked={values.IsRemoved === true}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="container relative grid grid-cols-2 col-span-4 gap-x-12">
                    {/* Campo 'Nom i Cognoms': */}
                    <div
                      className={`w-full bg-blue-100 items-center block px-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Nom i cognoms
                      </label>
                      <Field
                        // className={`pl-2 shadow rounded w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                        className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                        text-grey-darker print:shadow-none print:border-none print:h-auto print:py-3.5`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="Name"
                        placeholder="Introdueix..."
                        type="text"
                      />
                    </div>

                    {/* Campo 'Tipus treballador': */}
                    <div
                      className={`w-full bg-blue-100 items-center block px-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Tipus treballador
                      </label>
                      <Select
                        className="rounded text-grey-darker"
                        onChange={(e) => setFieldValue('Tipu', e.Name)}
                        name="Tipu"
                        placeholder="Selecciona..."
                        options={apiProfiles.data}
                        getOptionLabel={(o) => o.Name}
                        getOptionValue={(o) => o.Name}
                        value={apiProfiles.data.find(
                          (option) => option.Name === values.Tipu
                        )}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: '2px solid #bfdbfe'
                          })
                        }}
                      />
                    </div>

                    {/* Campo 'Nau': */}
                    <div
                      className={`w-full bg-blue-100 items-center block px-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Nau
                      </label>
                      {/* {fields.Area} */}
                      <Select
                        className="rounded text-grey-darker"
                        onChange={(e) => setFieldValue('Nau', e.Name)}
                        name="Nau"
                        placeholder="Selecciona..."
                        options={apiWarehouses.data}
                        // defaultValue= {areaOption}
                        value={apiWarehouses.data.find(
                          (option) => option.Name === values.Nau
                        )}
                        getOptionLabel={(o) => o.Name}
                        getOptionValue={(o) => o.Id}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: '2px solid #bfdbfe'
                          })
                        }}
                      />
                    </div>

                    {/* Campo 'Empresa': */}
                    <div
                      className={`w-full bg-blue-100 items-center block px-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Empresa
                      </label>
                      {/* {fields.Dependence} */}
                      <Select
                        className="rounded text-grey-darker"
                        onChange={(e) => setFieldValue('Empresa', e.Id)}
                        name="Empresa"
                        placeholder="Selecciona..."
                        options={apiBusinesses.data}
                        getOptionLabel={(o) => o.Name}
                        getOptionValue={(o) => o.Id}
                        value={apiBusinesses.data.find(
                          (option) => option.Id === values.Empresa
                        )}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: '2px solid #bfdbfe'
                          })
                        }}
                      />
                    </div>

                    {/* Campo 'Àrea actual': */}
                    <div
                      className={`w-full bg-blue-100 items-center block px-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Àrea actual
                      </label>
                      {/* {fields.Area} */}
                      <Select
                        className="rounded text-grey-darker"
                        onChange={(e) => setFieldValue('areaactual', e.Id)}
                        name="areaactual"
                        placeholder="Selecciona..."
                        options={apiAreaOptions.data}
                        // defaultValue= {areaOption}
                        getOptionLabel={(o) => o.Name}
                        getOptionValue={(o) => o.Id}
                        value={apiAreaOptions.data.find(
                          (option) => option.Id === values.areaactual
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isFocused
                              ? '2px solid #bfdbfe'
                              : '2px solid #bfdbfe'
                          })
                        }}
                      />
                    </div>

                    {/* Campo 'Jornada' + Campo '% Jornada': */}
                    <div
                      className={`w-full bg-blue-100 items-center block px-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Jornada
                      </label>

                      <div className="grid grid-cols-6 gap-x-4">
                        <Select
                          className="col-span-5 rounded text-grey-darker"
                          onChange={(e) => setFieldValue('Jornada', e.Name)}
                          name="Jornada"
                          placeholder="Selecciona..."
                          options={apiShifts.data}
                          // defaultValue= {areaOption}
                          getOptionLabel={(o) => o.Name}
                          getOptionValue={(o) => o.Id}
                          value={apiShifts.data.find(
                            (option) => option.Name === values.Jornada
                          )}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: '2px solid #bfdbfe'
                            })
                          }}
                        />
                        <div className="flex flex-row items-center justify-center">
                          <Field
                            className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                            text-grey-darker print:shadow-none print:border-none print:h-auto print:py-3.5`}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            name="JC"
                            placeholder="Introdueix..."
                            type="number"
                          />
                          <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="preven-guardar"
                  className="flex flex-row justify-center col-span-1"
                >
                  <div>
                    <button
                      className="block h-10 px-4 py-1 mb-4 ml-auto text-white transition duration-300 border rounded 2xl:mr-5 border-primary hover:border-blue-200 bg-primary font-ms-semi w-28 hover:bg-blue-200 hover:text-primary print:hidden"
                      type="submit"
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </Form>
            </div>
            {/* fin idGeneral */}
          </div>
        )}
      </Formik>

      {/* Polivalències - Formacions */}
      <div className="w-full mt-3 mb-4 mr-10 overflow-hidden bg-white border-0 border-collapse rounded-lg shadow-md print:hidden border-spacing-0">
        {/* Botones Tab: */}
        <div className="border-b tab border-primary">
          <div className="relative text-sm text-left text-white print:hidden font-ms-bold">
            <div className="grid items-end h-full grid-cols-2 gap-x-1">
              <button
                className="flex items-center justify-center w-auto h-full px-5 py-5 rounded-t-lg bg-primary-dark hover:bg-primary-dark active tablinks"
                onClick={(e) => openTab(e, 'Polivalencies')}
              >
                <span className="text-lg leading-tight">POLIVALÈNCIES</span>
              </button>

              <button
                className="flex items-center justify-center w-auto px-5 py-5 rounded-t-lg bg-primary hover:bg-primary-dark tablinks h-5/6"
                onClick={(e) => openTab(e, 'Formacions')}
              >
                <span className="text-lg leading-tight">FORMACIONS</span>
              </button>
            </div>
          </div>
        </div>
        {/* Fin Polivalències - Formacions */}

        {/* Tab con la tabla de 'Polivalències': */}
        <div
          id="Polivalencies"
          className="px-10 py-5 tabcontent active active:bg-primary-dark"
        >
          <table className="w-full overflow-hidden bg-white border-0 border-collapse rounded-lg shadow table-fixed print:hidden border-spacing-0">
            <thead>
              <tr>
                <th
                  className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw"
                  colSpan={1}
                >
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg">Polivalència</a>
                    </div>
                  </div>
                </th>
                <th
                  className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw"
                  colSpan={1}
                >
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg">Nivell</a>
                    </div>
                  </div>
                </th>
                <th
                  className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw"
                  colSpan={1}
                >
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg"></a>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-dashed">
              {fields.Versatilities?.length > 0 ? (
                fields.Versatilities.map((doc) => (
                  <tr key={doc?.Id} className="border-gray-200">
                    <td>
                      <span className="block w-auto p-1 px-2 py-3 m-2 text-sm rounded cursor-default">
                        {doc?.Idlloctreball_Id?.Description}
                      </span>
                    </td>
                    <td>
                      <span className="block w-auto p-1 px-2 py-3 m-2 text-sm rounded cursor-default">
                        {doc?.Nivell && getNameNivell(doc?.Nivell)}
                      </span>
                    </td>
                    <td>
                      <button
                        className={`p-3 m-2 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                        onClick={() => setLocation('/polivalencies/' + doc?.Id)}
                      >
                        <FaSearch size={17} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border-primary-dark">
                  <td>
                    <p className="p-5 text-base">
                      No s&apos;han trobat polivalències.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Tab con la table de 'Formaciones': */}
        <div id="Formacions" className="hidden px-10 py-5 tabcontent">
          <table className="w-full overflow-hidden bg-white border-0 border-collapse rounded-lg shadow table-fixed print:hidden border-spacing-0">
            <thead>
              <tr>
                <th className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw">
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg">Formació</a>
                    </div>
                  </div>
                </th>
                <th className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw">
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg">Inici</a>
                    </div>
                  </div>
                </th>
                <th className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw">
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg">Final</a>
                    </div>
                  </div>
                </th>
                <th className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw">
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg">Verificació</a>
                    </div>
                  </div>
                </th>
                <th className="relative h-10 text-sm text-left bg-gray-300 print:hidden font-ms-bold w-100vw">
                  <div className="flex flex-row items-center jusitfy-center">
                    <div className="flex items-center w-auto h-full ml-4">
                      <a className="text-lg"></a>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-dashed">
              {fields?.TrainingPlansUser?.length > 0 ? (
                fields?.TrainingPlansUser?.map((doc) => (
                  <tr key={doc?.Id} className="border-gray-200">
                    <td>
                      <span className="block w-auto p-1 px-2 py-3 m-2 text-sm rounded cursor-default">
                        {doc?.TrainingPlanID_Id?.DetailCourse}
                      </span>
                    </td>
                    <td>
                      <span className="block w-auto p-1 px-2 py-3 m-2 text-sm rounded cursor-default">
                        {doc?.TrainingPlanID_Id?.StartDate}
                      </span>
                    </td>
                    <td>
                      <span className="block w-auto p-1 px-2 py-3 m-2 text-sm rounded cursor-default">
                        {doc?.TrainingPlanID_Id?.EndDate}
                      </span>
                    </td>
                    <td>
                      <span className="block w-auto p-1 px-2 py-3 m-2 text-sm rounded cursor-default">
                        {doc?.TrainingPlanID_Id?.VerificationDate}
                      </span>
                    </td>
                    <td>
                      <button
                        className={`py-3 px-2 m-2 ml-auto mr-2 w-auto block p-1 text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                        onClick={() =>
                          setLocation(
                            '/seguiment-formacio/' + doc?.TrainingPlanID_Id?.Id
                          )
                        }
                      >
                        <FaSearch size={17} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border-primary-dark">
                  <td>
                    <p className="p-5 text-base">
                      No s&apos;han trobat formacions.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Adjunts */}
      <table
        className={`print:hidden mt-3 shadow-md bg-white w-full mr-10 border-spacing-0 border-collapse rounded-lg border-0 overflow-hidden`}
      >
        <thead>
          <tr>
            <th
              className="relative text-sm text-left text-white print:hidden font-ms-bold h-14 w-100vw bg-primary"
              colSpan={2}
            >
              <div className="flex flex-row items-center jusitfy-center">
                <div className="px-5 py-3 bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={handleClick}
                  >
                    <MdOutlineUploadFile size={25} />
                  </button>
                </div>
                <div className="flex items-center w-auto h-full ml-4">
                  <a className="text-lg">
                    FITXERS ADJUNTS (CV, Firmes, Confidencialitat, Altres....)
                  </a>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del trabajador, en el campo 'TreballadorDocuments' nos llegan
          todos los documentos que tiene adjuntos: */}
          {addDocumentsToTable?.length > 0 ? (
            addDocumentsToTable.map((doc) => (
              <tr key={doc.Id} className="border-primary-dark">
                <td>
                  <button className="block w-auto p-3 m-2 text-sm rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
                    <a
                      className="text-base"
                      href={`https://apipreprod.cetriko.com/uploads/treballadors/${
                        params.id
                      }/documents/${encodeURIComponent(doc.Name)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.Name}
                    </a>
                  </button>
                </td>
                <td>
                  <button
                    className={`p-3 m-2 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteDocument}
                          element="fitxer"
                          id={doc.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat fitxers adjunts.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <input
        ref={hiddenFileInput}
        accept=".pdf, .xlsx"
        type="file"
        name="file"
        multiple="multiple"
        className="h-1/6 hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
        onChange={(e) => uploadDocument(e)}
      />
    </>
  )
}

TreballadorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  uploadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  addDocumentsToTable: PropTypes.array,
  params: PropTypes.object,
  isNew: PropTypes.bool
}

export default TreballadorForm
