import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { IoMailOpenOutline, IoTrashOutline } from 'react-icons/io5'

export default function ListTable({
  items,
  handleRead,
  handleDelete,
  handleClick
}) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Autor',
      accessor: 'Dashboard_Author',
      Filter: ColumnFilter
    },
    {
      Header: 'Data',
      accessor: 'Dashboard_Date',
      Filter: ColumnFilter
    },
    {
      Header: 'Secció',
      accessor: 'Dashboard_Section',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom',
      accessor: 'Dashboard_NotificationName',
      Filter: ColumnFilter
    },
    {
      Header: 'Descripció',
      accessor: 'Dashboard_Description',
      Filter: ColumnFilter
    },
    {
      Header: '',
      accessor: 'Dashboard_Read',
      Filter: '',
      Cell: ({ row }) => (
        <div className="flex items-center justify-end ">
          <IoMailOpenOutline
            onClick={(e) => {
              e.stopPropagation()
              return handleRead(e, row.original)
            }}
            title="Marcar com llegit"
            className="p-2 rounded-lg cursor-pointer hover:bg-white"
            size={40}
          />
          <IoTrashOutline
            onClick={(e) => {
              e.stopPropagation()
              return handleDelete(e, row.original)
            }}
            title="Eliminar"
            className="p-2 rounded-lg cursor-pointer hover:bg-white"
            size={40}
          />
        </div>
      )
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id: item.Id,
          Dashboard_Author: item.UserDone,
          Dashboard_Date: item.Date ? formatDate(item.Date) : null,
          Dashboard_Section: item.Section,
          Dashboard_Description: item.Description,
          Dashboard_NotificationName: item.NotificationName,
          Dashboard_Read: item.Read,
          Dashboard_Link: item.Link
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  const formatDate = (date) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = newDate.toLocaleDateString('es-ES', options)

    return formattedDate
  }

  return (
    tableData.length > 0 && (
      <Table
        handleClick={handleClick}
        tableData={tableData}
        tableColumns={tableColumns}
        isDashboard
      />
    )
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleRead: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any,
  row: PropTypes.any
}
