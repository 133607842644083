import OfficeLogin from 'components/login/OfficeLogin'
import OperatorLogin from 'components/login/OperatorLogin'
import Logo from 'components/ui/Logo/Logo'
import LogoGestinet from 'components/ui/Logo/LogoGestinet'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

export default function Login() {
  // Get user context
  const { setUser } = useContext(UserContext)

  // Router (to do redirects)
  const [, setLocation] = useLocation()

  // Form login data
  const [loginData, loginAction] = useFetch()

  // Form operator data
  const [loginOperatorData, loginOperatorAction] = useFetch()

  // Trigger login data (to set userContext and redirect)
  useEffect(() => {
    // If Login OK
    if (loginData.data) {
      // Set user info to User context (context + localstorage)
      setUser(loginData.data)

      // Redirect
      setLocation('/')
    }
    // If Login Operator OK
    if (loginOperatorData.data) {
      // Set user operator info to User context (context + localstorage)
      setUser(loginOperatorData.data)

      // Redirect
      setLocation('/')
    }
  }, [loginData, loginOperatorData])

  const handleSubmitOfficeLogin = async (values) => {
    const loginAPIParams = {
      url: 'auth',
      method: 'POST',
      body: values,
      messageKo: 'Usuari i/o contrasenya incorrectes'
    }
    // Fetch api Login
    loginAction(loginAPIParams)
  }

  const handleSubmitOperatorLogin = (pin) => {
    const loginOperatorAPIParams = {
      url: 'auth/operator',
      method: 'POST',
      body: { pin: pin },
      messageKo: 'PIN incorrecte'
    }
    // Fetch api Login Operator
    loginOperatorAction(loginOperatorAPIParams)
  }

  return (
    <div className="flex items-center justify-center bg-gray-50 py-7 md:h-screen">
      <div>
        <div className="mb-6 text-center">
          <Logo classes="m-auto" link="/login" />
        </div>

        <div className="flex flex-wrap items-center justify-center">
          <OfficeLogin
            loading={loginData.loading}
            error={loginData.error}
            handleSubmitOfficeLogin={handleSubmitOfficeLogin}
          />

          <OperatorLogin
            loading={loginOperatorData.loading}
            error={loginOperatorData.error}
            handleSubmitOperatorLogin={handleSubmitOperatorLogin}
          />
        </div>

        <div className="mt-6 text-center">
          <LogoGestinet classes="w-28 m-auto" />
        </div>

        {/* <div className="w-3/4 m-auto text-center pt-7">
          <div className="flex items-center p-2 leading-none text-red-100 bg-red-700 border-2 border-red-800 rounded lg:inline-flex" role="alert">
            <span className="flex justify-center items-center rounded bg-red-500 border-2 border-red-600 uppercase px-2 py-1.5 text-xs font-bold mr-3">
              <MdInfo size={30} />
            </span>
            <span className="flex-auto mr-2 text-lg text-left"><b>Tancament de sessió general!</b> En cas d&apos;accedir a aquesta aplicació amb Email i Contrasenya comproveu la safata d&apos;entrada del vostre correu personal, trobareu una contrasenya provisional. En cas de no haver rebut la contrasenya provisional o no recordar el codi ECAP contacteu amb la Miriam Pujol al correu <b>miriam.p@cetriko.com</b> i/o amb Gestinet al correu <b>suport@gestinet.com</b>!</span>
          </div>
        </div> */}
      </div>
    </div>
  )
}
