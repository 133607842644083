import alcades from 'assets/icons/alcades.png'

export default function HeightForm() {
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'

  return (
    <div className="grid items-center grid-cols-12 p-2 auto-rows-min md:gap-y-1 lg:gap-y-3 print:gap-y-1">
      {/* Sincronit. */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Sincronit.:</div>

        <input
          type="text"
          className={`${commonInputStyles} w-full`}
          style={{ backgroundColor: 'white', color: 'black' }}
          disabled
        />
      </div>

      {/* Formació */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Formació:</div>

        <input
          type="text"
          className={`${commonInputStyles} w-full`}
          style={{ backgroundColor: 'white', color: 'black' }}
          disabled
        />
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
          Alçada plat:
        </label>

        <div className="relative w-40 col-span-4 print:w-36">
          <img src={alcades} alt="pinyons plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1 print:px-0.5 print:pb-0.5">
            <input
              type="text"
              className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
          Desplaçament plat:
        </label>

        <div className="relative w-40 col-span-4 print:w-36">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1 print:px-0.5 print:pb-0.5">
            <input
              type="text"
              className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {}
