import ListTable from 'components/Clients/ListTable/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

export default function ClientsList() {
  // Context
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const [, setLocation] = useLocation()

  // Fetch
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGet, setApiCallGet] = useFetch()

  // ###########################################

  useEffect(async () => {
    const apiCallGetUser = {
      url: `user/${user.Id}`,
      method: 'GET',
      messageKo: `Error al recuperar l'Usuari!`
    }
    setApiCallGetUser(apiCallGetUser)
  }, [])

  useEffect(() => {
    if (getApiCallGetUser.data !== null) {
      const title = {
        name: 'Clients',
        button: 'print',
        buttons: getApiCallGetUser.data.CanCreateClientRequest && user.Role.Id === 1
          ? [{ name: 'Crear', link: `/client/nou` }]
          : null
      }
      setTitle(title)

      const clientsApiParams = {
        url: `client`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      setApiCallGet(clientsApiParams)
    }
  }, [getApiCallGetUser.data])

  // ###########################################

  // 'handleClick' para cada elemento de la tabla:
  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/client/${item.Id}`)
  }

  // ###########################################

  return getApiCallGet.data && getApiCallGet.data.length > 0 ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={getApiCallGet.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}
