import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { BsExclamationTriangle } from 'react-icons/bs'
import { useLocation } from 'wouter'

export default function DeleteModal({ id, closeModal }) {
  const [getApiCallDelete, setApiCallDelete] = useFetch()
  const [, setLocation] = useLocation()

  return (
    <div>
      <BsExclamationTriangle
        className="absolute p-2 bg-red-100 rounded-full text-primary"
        size={40}
      />

      <div className="flex flex-col justify-between h-48 pl-12">
        <h3 className="mt-1 text-lg  font-lato-bold">Eliminar</h3>
        <p className="text-base font-bold text-gray-500  font-la-light">
          Estàs segur que vols eliminar la fitxa tècnica actual? Aquesta acció
          desactiva la fitxa tècnica, seguirà existint però no la podràs veure.
        </p>

        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="w-1/6 h-10 mx-4 font-medium text-black transition-colors duration-150 bg-white border border-gray-300 rounded-lg focus:shadow-outline hover:bg-gray-300 "
          >
            Cancelar
          </button>

          <button
            onClick={async () => {
              const apiParams = {
                url: `tsweaving/${id}`,
                method: 'DELETE',
                messageOk: 'FT eliminada!',
                messageKo: 'Error al recuperar dades del ECAP'
              }
              await setApiCallDelete(apiParams)

              setLocation('/ft-teixiduria')
            }}
            className="w-1/6 h-10 font-medium text-white transition-colors duration-150 border rounded-lg bg-primary border-primary focus:shadow-outline hover:bg-secondary hover:text-white"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  )
}

DeleteModal.propTypes = {
  deleteUserOrFT: PropTypes.func,
  id: PropTypes.any,
  trainingPlanId: PropTypes.any,
  element: PropTypes.any,
  closeModal: PropTypes.any
}
