import Menu from 'components/layout/Menu/Menu'
import Logo from 'components/ui/Logo/Logo'

import LogoGestinet from 'components/ui/Logo/LogoGestinet'

export default function Sidebar() {
  return (
    <aside id="sidebar-multi-level-sidebar" className="fixed top-0 left-0 z-40 flex flex-col w-64 h-screen transition-transform -translate-x-full divide-y divide-blue-600 print:hidden divide-dashed sm:translate-x-0 bg-charcoal" aria-label="Sidebar">
      <div className="flex items-center justify-center w-full py-6 bg-darkCharcoal">
        <Logo classes="h-8 object-contain m-auto" link="/" color="white" />
      </div>
      
      <div id="sidebar-menu" className="flex-grow w-full px-3 py-4 overflow-y-auto h-14 scroll-smooth">
        <Menu device="desktop" />
      </div>

      {/* Logo Gestinet: */}
      <div className='w-full bg-darkCharcoal'>
        <LogoGestinet classes="w-20 py-4 m-auto" />
      </div>
    </aside>
  )
}
