import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { Field, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { MdOutlineUploadFile } from 'react-icons/md'
import Select from 'react-select'

const JobForm = ({
  handleSubmit,
  fields,
  uploadDocument,
  deleteDocument,
  addDocumentsToTable,
  params,
  isNew
}) => {
  const hiddenFileInput = useRef(null)
  const { handleModal } = useContext(ModalContext)

  // useFetch() para las llamadas a la API:
  const [apiAreaOptions, setApiAreaOptions] = useFetch()
  const [apiJobs, setApiJobs] = useFetch()

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  // Este useEffect() se hizo para recoger la información, que iria en los filtros del formulario, directamente de la base de datos:
  useEffect(async () => {
    // Llamada a la API para recoger todas las 'Areas' de la base de datos ('dbo.Areas'):
    const getApiAreaOptions = {
      url: `area`,
      method: 'GET',
      messageKo: 'Error al recuperar les Arees!'
    }

    // Llamada a la API para recoger todas las 'Dependencias' de la base de datos ('dbo.Jobs'):
    const getApiJobs = {
      url: `job`,
      method: 'GET',
      messageKo: 'Error al recuperar els Llocs de Treball!'
    }

    // 'Promise' en este caso, se usa para hacer múltiples llamadas a la API en fila, una tras otra.
    // Mejora el tiempo de carga de la página. Si hacemos cada llamada a la API por separado,
    // provoca una carga más lenta de la página, para poner en contexto, el 'Promise' ahorra 3s de carga:
    await Promise.all([
      setApiAreaOptions(getApiAreaOptions),
      setApiJobs(getApiJobs)
    ]).catch((e) => {
      console.log(e)
    })
  }, [])

  const formatDate = (date) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = newDate.toLocaleDateString('es-ES', options)

    return formattedDate
  }

  // Al hacer múltiples llamadas a la API, la información puede tardar en llegar, por eso ponemos estos condicionales que indican
  // que hasta que no nos lleguen todos los datos, no se muestre la tabla, porque nos puede dar problemas:
  return apiAreaOptions.data === undefined ||
    !apiAreaOptions.data ||
    apiJobs.data === undefined ||
    !apiJobs.data ? (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form
            className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md print:border-0 print:bg-white print:m-0 print:shadow-none"
            style={{ fontSize: '16px !important' }}
          >
            <h1 className="col-span-4 py-2 text-2xl text-center bg-blue-200 font-ms-bold print:bg-white print:border-b-2 print:border-black">
              {!isNew ? 'LLOC DE TREBALL #' + values.Id : 'NOU LLOC DE TREBALL'}
            </h1>

            {/* Contenedor con todos los inputs: */}
            <div className="grid grid-cols-4 px-5 py-5 mx-2 gap-x-5 gap-y-4 print:m-1 print:p-3 print:px-0 print:pt-7">
              {/* Campo 'Edició' + 'Data': */}
              <div className="grid grid-cols-2 col-span-2">
                {/* Campo 'Edició': */}
                <div className={`h-full w-full items-center block px-2 py-2`}>
                  <label
                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Edició
                  </label>

                  <div className="flex py-2">
                    {values.Edition ? values.Edition : 'N/A'}
                  </div>
                </div>

                {/* Campo 'Data': */}
                <div className={`h-full w-full items-center block px-2 py-2`}>
                  <label
                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Data
                  </label>

                  <div className="flex w-auto py-2">
                    {values.Date ? formatDate(values.Date) : 'dd/mm/aaaa'}
                  </div>
                </div>
              </div>

              {/* Campo 'Àrea': */}
              <div
                className={`h-full w-full items-center block px-2 py-2 col-span-2`}
              >
                <label
                  className="block col-span-2 mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="Dependence"
                >
                  Àrea
                </label>

                <Select
                  className="rounded text-grey-darker print:hidden"
                  onChange={(e) => setFieldValue('AreaId', e.Id)}
                  name="AreaId"
                  placeholder="Selecciona..."
                  options={apiAreaOptions.data}
                  getOptionLabel={(o) => o.Name}
                  getOptionValue={(o) => o.Id}
                  value={apiAreaOptions.data.find(
                    (option) => option.Id === values.AreaId
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: state.isFocused
                        ? '2px solid #bfdbfe'
                        : '2px solid #bfdbfe'
                    })
                  }}
                  isRequired
                />

                <Field
                  className={`hidden print:flex pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                  text-grey print:shadow-none`}
                  placeholder="Introdueix..."
                  type="text"
                  value={
                    apiAreaOptions.data.find(
                      (option) => option.Id === values.AreaId
                    )?.Name
                  }
                />
              </div>

              {/* Campo 'Lloc de treball': */}
              <div
                className={`h-full w-full items-center block px-2 py-2 col-span-2`}
              >
                <label
                  className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="Dependence"
                >
                  Lloc de treball
                </label>
                <Field
                  className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                  text-grey print:shadow-none print:h-auto`}
                  name="Description"
                  placeholder="Introdueix..."
                  type="text"
                />
              </div>

              {/* Campo 'Dependència': */}
              <div
                className={`h-full w-full items-center block px-2 py-2 col-span-2`}
              >
                <label
                  className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="Dependence"
                >
                  Dependència
                </label>

                <Select
                  className="rounded text-grey-darker print:hidden"
                  onChange={(e) => {
                    setFieldValue('JobDependenceId', e.Id)
                  }}
                  name="JobDependenceId"
                  placeholder="Selecciona..."
                  options={[{ Id: null, Description: '-' }, ...apiJobs.data]}
                  getOptionLabel={(o) => o.Description}
                  getOptionValue={(o) => o.Id}
                  value={
                    values.JobDependenceId !== null
                      ? apiJobs.data.find(
                          (option) => option.Id === values.JobDependenceId
                        )
                      : { Id: null, Description: '-' }
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: state.isFocused
                        ? '2px solid #bfdbfe'
                        : '2px solid #bfdbfe'
                    })
                  }}
                  isRequired
                />

                <Field
                  className={`hidden print:flex pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                  text-grey print:shadow-none`}
                  placeholder="Introdueix..."
                  type="text"
                  value={
                    values.JobDependenceId !== null
                      ? apiJobs.data.find(
                          (option) => option.Id === values.JobDependenceId
                        )?.Description
                      : '-'
                  }
                />
              </div>

              {/* Campo 'Funcions': */}
              <div
                className={`h-full w-full items-center block px-2 py-2 col-span-2 print:col-span-4`}
              >
                <label
                  className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="Dependence"
                >
                  Funcions
                </label>

                {/* TODO: When I try to print the page being inside a textarea it changes the whole page text size and I don't know how to solve it. */}
                <Field
                  type="text"
                  as="textarea"
                  name="Functions"
                  placeholder="Introdueix..."
                  className="h-44 pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none text-grey resize-none print:hidden"
                />

                <pre className="w-full printJobTextAreas hidden print:block bg-white rounded pl-2 py-1.5 px-3 p-2 border-2 border-blue-200 outline-none">
                  {values.Functions}
                </pre>
              </div>

              {/* Campo 'Responsabilitats': */}
              <div
                className={`h-full w-full items-center block px-2 py-2 col-span-2 print:col-span-4`}
              >
                <label
                  className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="Dependence"
                >
                  Responsabilitats
                </label>

                {/* TODO: When I try to print the page being inside a textarea it changes the whole page text size and I don't know how to solve it. */}
                <Field
                  type="text"
                  as="textarea"
                  name="Responsibilities"
                  placeholder="Introdueix..."
                  className="h-44 pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none text-grey resize-none print:hidden"
                />

                <pre className="w-full printJobTextAreas hidden print:block bg-white rounded pl-2 py-1.5 px-3 p-2 border-2 border-blue-200 outline-none">
                  {values.Responsibilities}
                </pre>
              </div>

              {/* Campo 'Competències': */}
              <div
                className={`h-full col-span-4 w-full items-center block px-2 print:py-2`}
              >
                <label
                  className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="Dependence"
                >
                  Competències
                </label>

                {/* TODO: When I try to print the page being inside a textarea it changes the whole page text size and I don't know how to solve it. */}
                <Field
                  type="text"
                  as="textarea"
                  name="Competences"
                  placeholder="Introdueix..."
                  className="h-44 pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none text-grey resize-none print:hidden"
                />

                <pre className="w-full printJobTextAreas hidden print:block bg-white rounded pl-2 py-1.5 px-3 p-2 border-2 border-blue-200 outline-none">
                  {values.Competences}
                </pre>
              </div>
            </div>

            <div className="flex flex-row justify-center w-full">
              <button
                className="block h-10 px-4 py-1 text-white transition duration-300 border rounded border-primary hover:border-blue-200 bg-primary font-ms-semi mb-7 w-28 hover:bg-blue-200 hover:text-primary print:hidden"
                type="submit"
              >
                GUARDAR
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* FITXERS ADJUNTS */}
      <table
        className={`print:hidden shadow-md bg-white w-full mr-10 mt-4 border-spacing-0 border-collapse rounded-lg border-0 overflow-hidden`}
      >
        <thead>
          <tr>
            <th
              className="relative text-sm text-left text-white print:hidden font-ms-bold h-14 w-100vw bg-primary"
              colSpan={2}
            >
              <div className="flex flex-row items-center jusitfy-center">
                <div className="px-5 py-3 bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={handleClick}
                  >
                    <MdOutlineUploadFile size={25} />
                  </button>
                </div>
                <div className="flex items-center w-auto h-full ml-4">
                  <a className="text-lg">
                    FITXERS ADJUNTS (CV, Firmes, Confidencialitat, Altres....)
                  </a>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del sitio de trabajo, en el campo 'JobDocuments' nos llegan
          todos los documentos que tiene adjuntos: */}
          {addDocumentsToTable?.length > 0 ? (
            addDocumentsToTable.map((doc) => (
              <tr key={doc.Id} className="border-primary-dark">
                <td>
                  <button className="block w-auto p-3 m-2 text-base rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
                    <a
                      className="text-md"
                      href={`https://apipreprod.cetriko.com/uploads/job/${
                        params.id
                      }/documents/${encodeURIComponent(doc.Name)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.Name}
                    </a>
                  </button>
                </td>
                <td>
                  <button
                    className={`p-3 m-2 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteDocument}
                          element="fitxer"
                          id={doc.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat fitxers adjunts.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <input
        ref={hiddenFileInput}
        accept=".pdf, .xlsx"
        type="file"
        name="file"
        multiple="multiple"
        className="h-1/6 hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
        onChange={(e) => uploadDocument(e)}
      />
      {/* FITXERS ADJUNTS */}
    </>
  )
}

JobForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  uploadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  addDocumentsToTable: PropTypes.array,
  params: PropTypes.object,
  isNew: PropTypes.bool
}

export default JobForm
