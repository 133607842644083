import NotificationsIcon from 'components/icons/NotificationsIcon'
import PropTypes from 'prop-types'
import { UserContext } from 'contexts/UserContext'
import {useEffect,useState, useContext} from 'react'
import useFetch from 'hooks/useFetch'
import {  Link } from 'wouter'

export default function Notifications({ device }) {
  const [notifications, getNotificationsAction] = useFetch()
  const [unreadNotis, setUnreadNotis] = useState([])
  const {user} = useContext(UserContext)
  // useEffect(() => {
  //   const notificationsAPIParams = {
  //     url: `notification/viewnotifications/${user.Id}`,
  //     method: 'GET',    
  //   }
  //   // Fetch api notifications
  //   getNotificationsAction(notificationsAPIParams)
    
  // }, [])
  // useEffect(() => {
  //   if(notifications.data)setUnreadNotis(notifications.data.filter(noti => !noti.Read))
  // }, [notifications.data]);
  if (device === 'mobile') {
    return (
      <button className="flex-shrink-0 p-1 ml-auto text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        <span className="sr-only">View notifications</span>
        <NotificationsIcon className="w-5 h-5" />
      </button>
    )
  } else if(unreadNotis?.length > 0){
    return (
      <Link href='/'>
      <button className="p-1 pt-3 mr-3 text-white rounded-full hover:text-white focus:outline-none">
        <span className="sr-only">View notifications</span>

        <span className="relative inline-block">
          <NotificationsIcon className="w-5 h-5 fill-current" />
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-primary">{unreadNotis?.length}</span>
        </span>
      </button>
      
      </Link>
    )
  } else return ''
}

Notifications.propTypes = {
  device: PropTypes.any
}
