import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function ObservationsForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Observació */}
      <div className="flex flex-col col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Observació:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={{
            backgroundColor: '#F2F2F2',
            color: '#999999'
          }}
          value={values?.Obseravtion}
          disabled
        />
      </div>

      {/* NOTA IMPORTANT */}
      <div className="flex flex-col col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          NOTA IMPORTANT:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={{
            backgroundColor: '#F2F2F2',
            color: '#999999'
          }}
          value={values?.DestacableText}
          disabled
        />
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {
  values: PropTypes.object
}
