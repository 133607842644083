import InferiorIcon from 'assets/icons/inferior.png'
import SuperiorIcon from 'assets/icons/superior.png'
import PropTypes from 'prop-types'

// REACT ICONS

export default function RamsView({ ramNumber, ramIndex, values }) {
  return (
    <div className="flex flex-col col-span-12 bg-white border-2 border-collapse border-blue-200 rounded shadow-md print:shadow-none">
      <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
        <span>REGLATGE RAM {values?.RamNumber ?? '?'}</span>
      </div>

      <div className="grid w-full grid-cols-12 gap-2 p-2 auto-rows-min">
        {/* AMPLE CADENA */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>AMPLE CADENA (cm)</span>
          </div>

          <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-fr print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
            {/* Campo 'Ample 0': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Ample 0:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Width0}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Ample 1': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Ample 1:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Width1}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Ample 2': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Ample 2:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Width2}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Ample 3': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Ample 3:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Width3}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Ample 4': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Ample 4:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Width4}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Ample 5': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Ample 5:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Width5}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Ample 6': */}
            {ramNumber !== 3 && (
              <>
                <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                  Ample 6:
                </div>
                <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                  <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                    {values?.Width6}
                  </div>
                  <span>&plusmn;&nbsp;5</span>
                </div>
              </>
            )}

            {/* Campo 'Ample 7': */}
            {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
              <>
                <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                  Ample 7:
                </div>
                <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                  <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                    {values?.Width7}
                  </div>
                  <span>&plusmn;&nbsp;5</span>
                </div>
              </>
            )}
          </div>
        </div>

        {/* TEMPERATURA */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>TEMPERATURA (ºC)</span>
          </div>

          <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
            {/* Campo 'Camp 1': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Camp 1
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Temperature1}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Camp 2': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Camp 2
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Temperature2}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Camp 3': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Camp 3
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Temperature3}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Camp 4': */}
            <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
              Camp 4
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Temperature4}
              </div>
              <span>&plusmn;&nbsp;5</span>
            </div>

            {/* Campo 'Camp 5': */}
            {ramNumber !== 3 && (
              <>
                <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                  Camp 5
                </div>
                <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                  <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                    {values?.Temperature5}
                  </div>
                  <span>&plusmn;&nbsp;5</span>
                </div>
              </>
            )}

            {/* Campo 'Camp 6': */}
            {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
              <>
                <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                  Camp 6
                </div>
                <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                  <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                    {values?.Temperature6}
                  </div>
                  <span>&plusmn;&nbsp;5</span>
                </div>
              </>
            )}
          </div>
        </div>

        {/* VENTILACIÓ */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>VENTILACIÓ (%)</span>
          </div>

          <div className="grid items-center grid-cols-12 p-3 auto-rows-fr print:p-2 gap-x-3 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
            {/* Campo 'Input 1': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              {ramNumber !== 3 && (
                <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                  {values?.TopVentilationPercentage}
                </div>
              )}
            </div>

            {/* Campo 'Imagen SUP': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <img className="m-auto" src={SuperiorIcon} alt="sup" width={25} />
            </div>

            {/* Campo 'Input 2': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                {values?.BottomVentilation1}
              </div>
            </div>

            {/* Campo 'Input 3': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                {values?.TopVentilation1}
              </div>
            </div>

            {/* Campo 'Input 4': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                {values?.BottomVentilation2}
              </div>
            </div>

            {/* Campo 'Input 5': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                {values?.TopVentilation2}
              </div>
            </div>

            {/* Campo 'Input 6': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                {values?.BottomVentilation3}
              </div>
            </div>

            {/* Campo 'Input 7': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                {values?.TopVentilation3}
              </div>
            </div>

            {/* Campo 'Imagen INF': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              <img className="m-auto" src={InferiorIcon} alt="inf" width={25} />
            </div>

            {/* Campo 'Input 8': */}
            <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
              {ramNumber === 3 && (
                <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 cursor-not-allowed overflow-ellipsis">
                  {
                    values?.TechnicalSheetRams[ramIndex]
                      ?.BottomVentilationPercentage
                  }
                </div>
              )}
            </div>
          </div>
        </div>

        {/* SOBREALIMENTACIÓ */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>SOBREALIMENTACIÓ (%)</span>
          </div>

          {/* Campo 'SUP / INF': */}
          <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-2 print:gap-y-2 print:auto-rows-auto">
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              SUP/INF:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.OverFeeding}
              </div>
            </div>
          </div>
        </div>

        {/* SORTIDA */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>SORTIDA</span>
          </div>

          <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
            {/* Campo 'Tensió': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Tensió:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Tension}
              </div>
              <span>&plusmn;</span>
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.TensionTolerance}
              </div>
            </div>

            {/* Campo 'Potenciòm.': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Potenciòm.:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Potential}
              </div>
              <span>&plusmn;</span>
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.PotentialTolerance}
              </div>
            </div>

            {/* Campo 'Refrigeració': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Refrigeració:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.IsRefrigeration}
              </div>
            </div>
          </div>
        </div>

        {/* ALTRES */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>ALTRES</span>
          </div>

          <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
            {/* Campo 'Pot. encoladors': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Pot. encoladors:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.GlueEnhancer}
              </div>
              <span>&plusmn;</span>
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.GlueEnhancerTolerance}
              </div>
            </div>

            {/* Campo 'Pot. raspalls': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Pot. raspalls:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.BrushEnhancer}
              </div>
              <span>&plusmn;</span>
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.BrushEnhancerTolerance}
              </div>
            </div>

            {/* Campo 'Pressió Foul.': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Pressió Foul.:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.FoulardTension}
              </div>
              <span>&plusmn;</span>
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.FoulardTensionTolerance}
              </div>
            </div>

            {/* Campo 'Vaporitzador': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Vaporitzador:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.HasVaporizer}
              </div>
            </div>

            {/* Campo 'Posar drap': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              Posar drap:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.HasRag}
              </div>
            </div>
          </div>
        </div>

        {/* VELOCITAT (m/min) + EXTRACCIÓ */}
        <div className="grid grid-cols-1 col-span-12 gap-y-2">
          <div className="flex flex-col col-span-4 bg-white border border-collapse border-blue-200 rounded">
            <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
              <span>VELOCITAT (m/min)</span>
            </div>

            <div className="grid grid-cols-12 p-3 gap-y-2">
              {/* Campo 'MIN': */}
              <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-2 font-ms-semi print:mt-0">
                MIN:
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
                <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                  {values?.MinSpeed}
                </div>
              </div>

              {/* Campo 'MAX': */}
              <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-2 font-ms-semi print:mt-0">
                MAX:
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
                <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                  {values?.MaxSpeed}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-4 bg-white border border-collapse border-blue-200 rounded">
            <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
              <span>EXTRACCIÓ</span>
            </div>

            <div className="grid items-center grid-cols-12 p-3 gap-y-3">
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed">
                <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                  {values?.ExtractionType}
                </div>
                <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                  {values?.Extraction}
                </div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>

        {/* OBSERVACIONS */}
        <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>OBSERVACIONS</span>
          </div>

          <div className="w-full p-3">
            {/* Campo 'Observació': */}
            <div className="flex items-center w-full h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="flex items-center w-full h-full px-2 py-1.5 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.Observation}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RamsView.propTypes = {
  ramNumber: PropTypes.number,
  ramIndex: PropTypes.number,
  values: PropTypes.object
}
