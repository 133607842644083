import InferiorIcon from 'assets/icons/inferior.png'
import SuperiorIcon from 'assets/icons/superior.png'
import PropTypes from 'prop-types'

export default function RamForm({ ramNumber, ramIndex, values }) {
  return (
    <div className="grid w-full grid-cols-12 gap-2 auto-rows-min">
      {/* AMPLE CADENA */}
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-red-200 rounded lg:col-span-3">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          AMPLE CADENA (cm)
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-fr print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Ample 0': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 0:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Width0}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Ample 1': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 1:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Width1}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Ample 2': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 2:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Width2}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Ample 3': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 3:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Width3}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Ample 4': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 4:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Width4}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Ample 5': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 5:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Width5}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Ample 6': */}
          {ramNumber !== 3 && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Ample 6:
              </div>
              <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                  {values?.TechnicalSheetRams[ramIndex]?.Width6}
                </div>
                <span>&plusmn;&nbsp;5</span>
                <div className="border border-gray-800 ft-check"></div>
              </div>
            </>
          )}

          {/* Campo 'Ample 7': */}
          {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Ample 7:
              </div>
              <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                  {values?.TechnicalSheetRams[ramIndex]?.Width7}
                </div>
                <span>&plusmn;&nbsp;5</span>
                <div className="border border-gray-800 ft-check"></div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* TEMPERATURA */}
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-red-200 rounded lg:col-span-3">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          TEMPERATURA (ºC)
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Camp 1': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 1
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Temperature1}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Camp 2': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 2
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Temperature2}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Camp 3': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 3
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Temperature3}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Camp 4': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 4
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Temperature4}
            </div>
            <span>&plusmn;&nbsp;5</span>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Camp 5': */}
          {ramNumber !== 3 && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Camp 5
              </div>
              <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                  {values?.TechnicalSheetRams[ramIndex]?.Temperature5}
                </div>
                <span>&plusmn;&nbsp;5</span>
                <div className="border border-gray-800 ft-check"></div>
              </div>
            </>
          )}

          {/* Campo 'Camp 6': */}
          {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Camp 6
              </div>
              <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                  {values?.TechnicalSheetRams[ramIndex]?.Temperature6}
                </div>
                <span>&plusmn;&nbsp;5</span>
                <div className="border border-gray-800 ft-check"></div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* VENTILACIÓ */}
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-red-200 rounded lg:col-span-3">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          VENTILACIÓ (%)
        </div>

        <div className="grid items-center grid-cols-12 p-3 auto-rows-fr print:p-2 gap-x-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Input 1': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            {ramNumber !== 3 && (
              <>
                <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                  {
                    values?.TechnicalSheetRams[ramIndex]
                      ?.TopVentilationPercentage
                  }
                </div>
                <div className="border border-gray-800 ft-check"></div>
              </>
            )}
          </div>

          {/* Campo 'Imagen SUP': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <img className="m-auto" src={SuperiorIcon} alt="sup" width={25} />
          </div>

          {/* Campo 'Input 2': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.BottomVentilation1}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Input 3': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.TopVentilation1}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Input 4': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.BottomVentilation2}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Input 5': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.TopVentilation2}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Input 6': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.BottomVentilation3}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Input 7': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.TopVentilation3}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Imagen INF': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            <img className="m-auto" src={InferiorIcon} alt="inf" width={25} />
          </div>

          {/* Campo 'Input 8': */}
          <div className="flex items-center col-span-6 space-x-2 cursor-not-allowed min-h-8 print:h-full">
            {ramNumber === 3 && (
              <>
                <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                  {
                    values?.TechnicalSheetRams[ramIndex]
                      ?.BottomVentilationPercentage
                  }
                </div>
                <div className="border border-gray-800 ft-check"></div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* SOBREALIMENTACIÓ */}
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-red-200 rounded lg:col-span-3">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          SOBREALIMENTACIÓ (%)
        </div>

        {/* Campo 'SUP / INF': */}
        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            SUP/INF:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.OverFeeding}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>
        </div>
      </div>

      {/* SORTIDA */}
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-red-200 rounded lg:col-span-4">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          SORTIDA
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Tensió': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Tensió:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Tension}
            </div>
            <span>&plusmn;</span>
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.TensionTolerance}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Potenciòm.': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Potenciòm.:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.Potential}
            </div>
            <span>&plusmn;</span>
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.PotentialTolerance}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Refrigeració': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Refrigeració:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.IsRefrigeration
                ? 'Sí'
                : 'No'}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>
        </div>
      </div>

      {/* ALTRES */}
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-red-200 rounded lg:col-span-4">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          ALTRES
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Pot. encoladors': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pot. encoladors:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.GlueEnhancer}
            </div>
            <span>&plusmn;</span>
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.GlueEnhancerTolerance}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Pot. raspalls': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pot. raspalls:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.BrushEnhancer}
            </div>
            <span>&plusmn;</span>
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.BrushEnhancerTolerance}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Pressió Foul.': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pressió Foul.:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.FoulardTension}
            </div>
            <span>&plusmn;</span>
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.FoulardTensionTolerance}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Vaporitzador': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Vaporitzador:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.HasVaporizer ? 'Sí' : 'No'}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>

          {/* Campo 'Posar drap': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Posar drap:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
              {values?.TechnicalSheetRams[ramIndex]?.HasRag ? 'Sí' : 'No'}
            </div>
            <div className="border border-gray-800 ft-check"></div>
          </div>
        </div>
      </div>

      {/* VELOCITAT (m/min) + EXTRACCIÓ */}
      <div className="grid grid-cols-2 col-span-12 gap-2 lg:gap-0 lg:grid-cols-1 lg:col-span-4 gap-y-2">
        <div className="flex flex-col bg-white border-2 border-collapse border-red-200 rounded">
          <div className="flex items-center justify-start w-full py-2 pl-2 text-sm bg-red-200 print:py-1 font-ms-bold">
            VELOCITAT (m/min)
          </div>

          <div className="grid items-center grid-cols-12 p-3 gap-y-2">
            {/* Campo 'MIN': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              MIN:
            </div>
            <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                {values?.TechnicalSheetRams[ramIndex]?.MinSpeed}
              </div>
              <div className="border border-gray-800 ft-check"></div>
            </div>

            {/* Campo 'MAX': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
              MAX:
            </div>
            <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
              <div className="w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
                {values?.TechnicalSheetRams[ramIndex]?.MaxSpeed}
              </div>
              <div className="border border-gray-800 ft-check"></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col bg-white border-2 border-collapse border-red-200 rounded">
          <div className="flex items-center justify-start w-full col-span-3 py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
            EXTRACCIÓ
          </div>

          <div className="grid items-center grid-cols-12 p-3 gap-y-3">
            <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full">
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {(() => {
                  switch (
                    values?.TechnicalSheetRams[ramIndex]?.ExtractionType
                  ) {
                    case 1:
                      return 'Automàtic'
                    case 2:
                      return 'Manual'
                    default:
                      return '-'
                  }
                })()}
              </div>
              <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                {values?.TechnicalSheetRams[ramIndex]?.Extraction}
              </div>
              <span>%</span>
              <div className="border border-gray-800 ft-check"></div>
            </div>
          </div>
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="flex flex-col col-span-12 bg-white border-2 border-collapse border-red-200 rounded">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-red-200 print:py-1 font-ms-bold">
          OBSERVACIONS
        </div>

        <div className="w-full p-3">
          {/* Campo 'Observació': */}
          <div className="flex items-center w-full col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
              {values?.TechnicalSheetRams[ramIndex]?.Observation}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RamForm.propTypes = {
  ramNumber: PropTypes.number,
  ramIndex: PropTypes.number,
  values: PropTypes.object
}
