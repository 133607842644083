import PropTypes from 'prop-types'
import { useState } from 'react'
import Select from 'react-select'

export default function CloneFTModal({
  cloneFT,
  options,
  closeModal,
  currentArticleCode,
  currentArticleDescription
}) {
  const [articleCode, setArticleCode] = useState('')
  const [articleDescription, setArticleDescription] = useState('')

  const confirmAction = (e) => {
    const confirmed = confirm(
      "Estás segur/a que vols copiar el contingut de la fitxa tècnica actual de l'article " +
        currentArticleCode +
        ' (' +
        currentArticleDescription +
        ") a la fitxa tècnica de l'article que has seleccionat en el desplegable " +
        articleCode +
        ' (' +
        articleDescription +
        ')?'
    )

    if (confirmed) {
      cloneFT(e, articleCode)
    }

    closeModal()
  }

  const warningAction = () => {
    alert('Has de seleccionar una fitxa tècnica en el desplegable.')
  }

  return (
    <>
      {options ? (
        <div className="w-full h-full">
          <div className="relative w-full h-full">
            <div className="relative bg-white">
              <div className="flex items-center justify-between pb-2 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Copiar Fitxa Tècnica
                </h3>
              </div>

              <div className="px-4 py-6 space-y-2">
                <p className="text-base leading-relaxed text-gray-500">
                  Copiaràs el contingut de la fitxa tècnica de l&apos;article actual{' '}
                  <b>{currentArticleCode}</b> (
                  <b>{currentArticleDescription}</b>) a la fitxa tècnica de l&apos;article que
                  seleccionis en el següent desplegable:
                </p>

                <div className="flex flex-col items-center justify-center w-full">
                  <Select
                    autoFocus
                    name="article"
                    className="w-full leading-tight text-grey-darker"
                    onChange={(e) => {
                      setArticleCode(e.value)
                      setArticleDescription(e.label)
                    }}
                    options={options}
                    placeholder="Seleccionar..."
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: '2px solid #d1d5db',
                        boxShadow: state.isFocused ? 'none' : 'none',
                        '&:hover': {
                          border: '2px solid #9ca3af'
                        },
                        margin: '0px',
                        minHeight: 'fit-content',
                        cursor: 'pointer'
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#333333',
                        backgroundColor: state.isFocused && '#d1d5db',
                        '&:hover': {
                          backgroundColor: '#f3f4f6'
                        }
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '0px 8px',
                        margin: '0px'
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '2px 8px'
                      })
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row-reverse items-center pt-4 border-t border-gray-200">
                <button
                  onClick={closeModal}
                  className="h-10 px-3 ml-2 font-medium text-black transition-colors duration-150 bg-white border border-gray-300 rounded focus:shadow-outline hover:bg-gray-300"
                >
                  Cancelar
                </button>

                <button
                  type="submit"
                  onClick={(e) =>
                    articleCode === '' ? warningAction() : confirmAction(e)
                  }
                  className="h-10 px-4 font-medium text-white transition-colors duration-150 border rounded bg-primary border-primary focus:shadow-outline hover:bg-secondary hover:text-white"
                >
                  Clonar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        'Carregant articles...'
      )}
    </>
  )
}

CloneFTModal.propTypes = {
  cloneFT: PropTypes.func,
  options: PropTypes.array,
  closeModal: PropTypes.func,
  currentArticleCode: PropTypes.string,
  currentArticleDescription: PropTypes.string
}
