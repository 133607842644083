// FORMIK HOOK
import { Field } from 'formik'

// REACT ICONS
import { MdArrowDropDown } from 'react-icons/md'

export default function EdgesForm() {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:auto-rows-auto print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2">
      {/* NOM COLA */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nom cola:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Glue"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* PROPORCIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Proporció:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.GlueProportion"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* COLOR */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Color:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.GlueColor"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value="">-</option>
          <option value={1}>Incolor</option>
          <option value={2}>Blau</option>
          <option value={3}>Vermell</option>
          <option value={4}>Marró</option>
          <option value={5}>Verd</option>
          <option value={6}>Groc</option>
          <option value={7}>Mixte</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* ENCOLADOR */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Encolador:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.GlueMachine"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value="">-</option>
          <option value={1}>Estret</option>
          <option value={2}>Ample</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* TALLAR VORES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Tallar Vores:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.HasToCutEdge"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* TALLADORS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Talladors:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Cuters"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* LATERALS ALINEATS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Laterals alineats:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.AlignedSides"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.EdgeObservation"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>
    </div>
  )
}

EdgesForm.propTypes = {}
