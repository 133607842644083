import { Field } from 'formik'

export default function ObservationsForm() {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Observació */}
      <div className="flex flex-col col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Observació:
        </label>

        <Field
          as="textarea"
          name="RequestArticle.Obseravtion"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input h-52"
        />
      </div>

      {/* NOTA IMPORTANT */}
      <div className="flex flex-col col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          NOTA IMPORTANT:
        </label>

        <Field
          as="textarea"
          name="RequestArticle.DestacableText"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input h-52"
        />
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {}
