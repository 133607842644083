import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Nom',
      accessor: 'Users_Name',
      Filter: ColumnFilter,
      Cell: (cell) => (
        <div className='flex items-center'>
          <img src={!cell.row.original.Users_Photo ? 'https://apipreprod.cetriko.com/uploads/user/profile/default_image.jpg' : 'https://apipreprod.cetriko.com' + cell.row.original.Users_Photo} className='w-8 h-8 rounded-full' alt='' />
          {/* <img src={!cell.row.original.Users_Photo ? process.env.REACT_APP_BASE_UPLOADS_URL + '/uploads/user/profile/default_image.jpg' : 'https://apipreprod.cetriko.com' + cell.row.original.Users_Photo} className='w-8 h-8 rounded-full' alt='' /> */}
          <span className="px-2 py-1 rounded-full font-ms-bold ">
            {cell.row.original.Users_Name}
          </span>
        </div>
      )
    },
    {
      Header: 'Email',
      accessor: 'Users_Email',
      Filter: ColumnFilter
    },
    {
      Header: 'Pin',
      accessor: 'Users_Pin',
      Filter: ColumnFilter
    },
    {
      Header: 'Vigent',
      accessor: 'Users_Removed',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id:            item.Id,
          Users_Name:    item.Name?.toUpperCase(),
          Users_Email:   item.Email,
          Users_Pin:     item.Pin,
          Users_Photo:   item.Photo ? item.Photo : '',
          Users_Removed: !item.IsRemoved
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 && (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  Photo: PropTypes.any,
  value: PropTypes.any
}
