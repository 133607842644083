import PropTypes from 'prop-types'

const InventoryForm = ({ handleSubmit, handleChange, fields}) => {
  
  const { Description, Location, IsTool } = fields

  return (
    <>
      <form className="container flex flex-wrap" onSubmit={handleSubmit}>
    

        <div className="w-1/2 p-2">
          <label
            className="block text-grey-darker text-md font-ms-semi mb-2 col-start-full"
            htmlFor="Description"
          >
            Descripció
          </label>
          <input
            className="pl-7 shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
            name="Description"
            type="text"
            value={Description}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="w-1/2 p-2">
          <label
            className="block text-grey-darker text-md font-ms-semi mb-2 col-start-full"
            htmlFor="Position"
          > 
            Lloc
          </label>
          <select
            className="pl-7 shadow appearance-none border border-gray-300 rounded w-full py-2  px-3 text-grey-darker"
            name="Location"
            type="text"
            value={Location}
            onChange={(e) => handleChange(e)}
          >
            <option value="">Escull</option>
            <option value="CETRIKO">Cetriko</option>
            <option value="4KF">4KF</option>
          </select>
        </div>
        <div className="w-1/2 flex items-center p-2">
        <label
            className="block text-grey-darker text-md font-ms-semi col-start-full"
            htmlFor="IsTool"
          >
            És una eina?
          </label>
          <input
            type="checkbox"
            className="w-6 h-6  ml-1"
            onClick={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked
                }
              })
            }}
            name="IsTool"
            
            checked={IsTool}
          />
        </div>
        <button
          className="bg-transparent hover:bg-secondary text-primary font-ms-semi hover:text-white mr-2 py-2 px-4 border border-primary hover:border-transparent h-1/2 mt-2 rounded ml-auto"
          type="submit"
        >
          GUARDAR
        </button>
      </form>
    </>
  )
}

InventoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isUpdate: PropTypes.any
}

export default InventoryForm
