import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { AiOutlineCheck, AiOutlineClose, AiOutlineUnlock, AiOutlineLock } from 'react-icons/ai'

export default function ListTableOthers({ items, handleClick }) {

  const [totalHours,setTotalHours] = useState([])
  const [totalMinutes,setTotalMinutes] = useState([])

  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: ' ',
      accessor: 'MaintenanceOthers_State',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineLock
          color="#be0043green"
          className="2xl:ml-1 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={30}
        />
      ) : (
        <AiOutlineUnlock
          className="2xl:ml-1 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="green"
          size={30}
        />
      )
    },
    {
      Header: 'Equip',
      accessor: 'MaintenanceOthers_EquipmentName',
      Filter: ColumnFilter
    },    
    {
      Header: 'Data Solic.',
      accessor: 'MaintenanceOthers_RequestDate',
      Filter: ColumnFilter
    },
    {
      Header: 'Data Corr.',
      accessor: 'MaintenanceOthers_CorrectiveDate',
      Filter: ColumnFilter
    },       
    {
      Header: 'Num',
      accessor: 'MaintenanceOthers_Id',
      Filter: ColumnFilter
    },
    {
      Header: 'Àrea',
      accessor: 'MaintenanceOthers_Area',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom',
      accessor: 'MaintenanceOthers_Usuari',
      Filter: ColumnFilter
    },
    {
      Header: 'Nau',
      accessor: 'MaintenanceOthers_Shed',
      Filter: ColumnFilter
    },
    {
      Header: 'Descripció',
      accessor: 'MaintenanceOthers_Information',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Arregla',
    //   accessor: 'repair',
    //   Filter: ColumnFilter
    // },
    {
      Header: "Com s'arregla",
      accessor: 'MaintenanceOthers_WhatDoes',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Recanvis',
    //   accessor: 'spentpart',
    //   Filter: ColumnFilter
    // },
    // {
    //   Header: 'Màq.Teix.',
    //   accessor: 'machine',
    //   Filter: ColumnFilter
    // },
    {
      Header: 'Hores',
      accessor: 'MaintenanceOthers_CorrectiveTimeHours',
      Filter: ColumnFilter
    },
    {
      Header: 'Min.',
      accessor: 'MaintenanceOthers_CorrectiveTimeMinutes',
      Filter: ColumnFilter
    },
    {
      Header: 'Millora',
      accessor: 'MaintenanceOthers_IsMachineImprovement',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    },
    {
      Header: 'Print',
      accessor: 'MaintenanceOthers_IsPrinted',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id:                                      item.Id,
          MaintenanceOthers_Id:                    item.Id,
          MaintenanceOthers_State:                 item.CorrectiveDate,
          MaintenanceOthers_Usuari:                item.RequestUserName,
          MaintenanceOthers_CorrectiveDate:        item.CorrectiveDate,
          MaintenanceOthers_RequestDate:           item.RequestDate,
          MaintenanceOthers_Information:           item.Description,
          MaintenanceOthers_IsSemi:                item.IsSemi,
          MaintenanceOthers_IsFinish:              item.IsFinish,
          MaintenanceOthers_IsOthers:              item.IsOthers,
          MaintenanceOthers_SemiFinished:          item.SemiFinished,
          MaintenanceOthers_Article:               item.Article,
          MaintenanceOthers_UserId:                item.DetectedUserId,
          MaintenanceOthers_Area:                  item.Area,
          MaintenanceOthers_N8D:                   item.N8D,
          MaintenanceOthers_Machine:               item.Machine,          
          MaintenanceOthers_Shed:                  item.Shed,
          MaintenanceOthers_Repair:                item.TechnicalUserName,
          MaintenanceOthers_WhatDoes:              item.WhatDoes,
          MaintenanceOthers_SpentPart:             item.SpentPart,
          MaintenanceOthers_CorrectiveTimeHours:   item.CorrectiveTimeHours,
          MaintenanceOthers_CorrectiveTimeMinutes: item.CorrectiveTimeMinutes,
          MaintenanceOthers_IdEquipment:           item.IdEquipment? item.IdEquipment : '',
          MaintenanceOthers_EquipmentName:         item.EquipmentName? item.EquipmentName : '',
          MaintenanceOthers_IsMachineImprovement:  item.IsMachineImprovement,     
          MaintenanceOthers_IsPrinted:             item.IsPrinted,      
        })     
      })

      setTableData(itemsData.reverse())
    }
  }, [items])
  
  return tableData.length > 0 && (
    <Table
      tableData           = {tableData}
      tableColumns        = {tableColumns}
      handleClick         = {handleClick}
      txtHours            = {totalHours}
      txtMinutes          = {totalMinutes}
      isMaintenance       = {true}
      isMaintenanceOthers = {true}
    />
  )
}

ListTableOthers.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
