import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const EditPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Introdueixi una contrasenya')
    .min(6, 'La contrasenya és massa curta. 6 caràcters mínim'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Les contrasenyes no coincideixen')
    .required('Confirma la contrasenya')
    .min(6, 'La contrasenya és massa curta. 6 caràcters mínim')
})

export default function EditPasswordForm({ loading, handleSubmit }) {
  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={EditPasswordSchema}
      // onSubmit={async (values, { resetForm }) => {
      onSubmit={async (values) => {
        // console.log("RF: ", resetForm)
        // console.log("val: ", values)
        // await handleSubmit(values)
        // resetForm()
        const type = "PASSWORD"
        await handleSubmit(values, type)
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="password"
            >
              Contrasenya
            </label>
            <div className="relative inline-block w-full text-gray-700">
              <Field
                type="password"
                name="password"
                autoComplete="password"
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
              />
            </div>
            {touched.password && errors.password && (
              <p className="text-red-500 text-xs italic">{errors.password}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="passwordConfirmation"
            >
              Confirmar contrasenya
            </label>
            <div className="relative inline-block w-full text-gray-700">
              <Field
                type="password"
                name="passwordConfirmation"
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
                autoComplete="passwordConfirmation"
              />
            </div>
            {touched.passwordConfirmation && errors.passwordConfirmation && (
              <p className="text-red-500 text-xs italic">
                {errors.passwordConfirmation}
              </p>
            )}
          </div>

          <div className="mb-4">
            <button
              type="submit"
              className="h-10 px-5 my-2 w-full text-white transition-colors duration-150 bg-primary rounded-lg focus:shadow-outline hover:bg-secondary"
              disabled={loading && 'disabled'}
            >
              Canviar contrasenya
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

EditPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  user: PropTypes.object
}
