import ListTable from 'components/FTAcabats/ListTable/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

export default function FTAcabatsList() {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const { handleModal } = useContext(ModalContext)

  // useFetch()
  const [getApiCallGet, setApiCallGet] = useFetch()

  // Estados:
  const [loading, setLoading] = useState(true)

  // ###########################################

  const title = {
    name: 'Fitxes Tècniques Acabats',
    loading: loading,
    buttons: []
  }

  // Modificar el título en cada 'loading':
  useEffect(() => {
    setTitle(title)
  }, [loading])

  // ###########################################

  // CRUD: GET
  useEffect(async () => {
    if (loading) {
      const ftListAPIParams = {
        url: `technicalsheet/role/${user.Role.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      await setApiCallGet(ftListAPIParams)

      handleModal('hide')
    }
  }, [])

  // Recoger datos de la llamada a la API:
  useEffect(() => {
    if (getApiCallGet.data) {
      localStorage.setItem('ftAcabats', JSON.stringify(getApiCallGet.data))
      setLoading(false)
    }
  }, [getApiCallGet.data])

  // ###########################################

  // 'handleClick' para cada elemento de la tabla:
  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/ft-acabats/${item.FTAcabats_ArticleCode}`)
  }

  // ###########################################

  return getApiCallGet.data && getApiCallGet.data.length > 0 ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={getApiCallGet.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}
