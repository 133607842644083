export default function ToolTipIcon(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 512 512"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.217 498.794C4.693 490.271 0 478.939 0 466.886s4.693-23.385 13.217-31.908l329.861-329.861c5.857-5.858 15.355-5.857 21.213 0l42.604 42.603a15 15 0 010 21.212L77.033 498.794c-8.797 8.797-20.353 13.195-31.908 13.195s-23.111-4.398-31.908-13.195z"
        fill="#464b52"
      />
      <path
        d="M406.895 147.72a15 15 0 010 21.212L77.033 498.794c-8.797 8.797-20.353 13.195-31.908 13.195s-23.111-4.398-31.908-13.195l372.376-372.376z"
        fill="#292b2f"
      />
      <circle cx={316.25} cy={195.761} fill="#76cefb" r={195.75} />
      <path
        d="M454.601 57.409C490.047 92.855 512 141.792 512 195.761c0 107.937-87.813 195.75-195.75 195.75-53.968 0-102.906-21.953-138.351-57.399z"
        fill="#6897f4"
      />
      <circle cx={316.25} cy={195.761} fill="#e1faff" r={135.5} />
      <path
        d="M316.25 331.261c-37.357 0-71.232-15.196-95.768-39.732L412.018 99.993c24.536 24.536 39.732 58.411 39.732 95.768 0 74.715-60.785 135.5-135.5 135.5z"
        fill="#c7f5ff"
      />
    </svg>
  )
}
