import ListTable from 'components/PreventiveWeaving/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const PrevenTeixList = () => {
  const { setTitle } = useContext(TitleContext)
  const [preventiveList, preventiveListAction] = useFetch()
  const [, setLocation] = useLocation()
  // const { handleModal } = useContext(ModalContext)
  const [userList, userListAction] = useFetch()

  useEffect(() => {
    // Set title
    const PrevenTeixTitle = {
      name: 'Fitxes preventiu teixiduria',
      buttons: [
        {
          name: 'Nou Preventiu Teixiduria',
          link: `/preventeix/nou`
        }
      ]
    }
    setTitle(PrevenTeixTitle)

    // GET user
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    userListAction(userListAPIParams)

    // Fetch api FT List
    const preventiveListAPIParams = {
      url: `preventiveweaving`,
      method: 'GET',
      messageKo: 'Error cercant Preventius al ECAP'
    }
    preventiveListAction(preventiveListAPIParams)
    //* Avoid bug when delete Preventive modal is still open
    // handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/preventeix/${item.Id}`)
  }

  // console.log('preventiveList: ',preventiveList.data)

  return preventiveList.data && userList.data ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable
        users={userList.data}
        items={preventiveList.data}
        handleClick={handleClick}
      />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default PrevenTeixList
