import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Formador',
      accessor: 'TrainingPlan_Entity',
      Filter: ColumnFilter
    },
    {
      Header: 'Curs',
      accessor: 'TrainingPlan_DetailCourse',
      Filter: ColumnFilter
    },
    {
      Header: 'Inici',
      accessor: 'TrainingPlan_StartDate',
      Filter: ColumnFilter
    },
    {
      Header: 'Final',
      accessor: 'TrainingPlan_EndDate',
      Filter: ColumnFilter
    },
    {
      Header: 'Hores',
      accessor: 'TrainingPlan_Hours',
      Filter: ColumnFilter
    },
    {
      Header: 'Verificació',
      accessor: 'TrainingPlan_CourseModifiedStateDate',
      Filter: ColumnFilter
    },
    {
      Header: 'Observacions',
      accessor: 'TrainingPlan_Observations',
      Filter: ColumnFilter
    },
    {
      Header: 'Estadi',
      accessor: 'TrainingPlan_CourseState',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id: item.Id ? item.Id : null,
          TrainingPlan_Id: item.Id ? item.Id : null,
          TrainingPlan_DetailCourse: item.DetailCourse
            ? item.DetailCourse
            : null,
          TrainingPlan_Observations: item.Description ? item.Description : null,
          TrainingPlan_Entity: item.Entity ? item.Entity : null,
          TrainingPlan_Hours: item.Hours ? item.Hours : null,
          TrainingPlan_Price: item.Price ? item.Price : null,
          TrainingPlan_StartDate: item.StartDate
            ? formatDate(item.StartDate)
            : null,
          TrainingPlan_EndDate: item.EndDate ? formatDate(item.EndDate) : null,
          TrainingPlan_CourseModifiedStateDate: item.CourseModifiedStateDate
            ? formatDate(item.CourseModifiedStateDate)
            : null,
          TrainingPlan_ValuationState: item.ValuationState
            ? item.ValuationState
            : null,
          TrainingPlan_CourseState: item.CourseState
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  const formatDate = (date) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = newDate.toLocaleDateString('es-ES', options)

    return formattedDate
  }

  return (
    tableData.length > 0 && (
      <Table
        tableData={tableData}
        tableColumns={tableColumns}
        handleClick={handleClick}
      />
    )
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
