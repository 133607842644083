import DescriptionForm from 'forms/ftteixiduria/description'
import FolderForm from 'forms/ftteixiduria/folder'
import HeightForm from 'forms/ftteixiduria/height'
import LastAcceptedRequest from 'forms/ftteixiduria/lastAcceptedRequest'
import ObservationsForm from 'forms/ftteixiduria/observations'
import PointGraduationForm from 'forms/ftteixiduria/pointGraduation'
import SprocketsForm from 'forms/ftteixiduria/sprockets'
import PropTypes from 'prop-types'

export default function Block({ title, className, values }) {
  return (
    <div
      className={`${className && className} 
        bg-white rounded border-2 print:border border-blue-200 border-collapse shadow-md print:shadow-none`}
      style={{
        pageBreakInside: 'avoid'
      }}
    >
      <div className="flex flex-row items-center justify-between w-full text-sm uppercase bg-blue-200 font-ms-bold">
        <span className="py-2 pl-2 print:py-0.5 print:px-1">{title}</span>

        {title !== 'Descripció' && (
          <span className="hidden print:inline mb-0.5 mr-0.5 w-6 h-6 border border-black bg-white"></span>
        )}
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return <DescriptionForm fields={values} />
          case 'Plegador / Calandra':
            return (
              <FolderForm
                fields={values}
                machineCode={values?.Machine?.slice(0, 3)}
              />
            )
          case 'Pinyons / Politja':
            return (
              <SprocketsForm
                fields={values}
                machineCode={values?.Machine?.slice(0, 3)}
              />
            )
          case 'Graduació del punt i consum':
            return <PointGraduationForm fields={values} />
          case 'Alçada i sincronisme':
            return <HeightForm fields={values} />
          case 'Observacions / Notes':
            return <ObservationsForm fields={values} />
          case 'Última modificació':
            return <LastAcceptedRequest fields={values} />
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  values: PropTypes.object
}
