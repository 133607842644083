import { Field } from 'formik'
import PropTypes from 'prop-types'
import { ImCheckmark, ImCross } from 'react-icons/im'

export default function ThreadTable({ threads }) {
  return (
    <table className="relative w-full px-4 border table-fixed border-primary">
      <thead className="w-full text-white bg-primary-dark">
        <tr className="w-full border-b divide-x divide-primary divide-solid border-primary">
          <th className="w-1/12 text-sm"></th>
          <th className="w-6/12 text-sm">Matèria</th>
          <th className="w-1/12 text-sm">GRS</th>
          <th className="w-2/12 text-sm">Nº Cons</th>
          <th className="w-2/12 py-1.5 text-sm">Percentatge</th>
        </tr>
      </thead>

      <tbody className="w-full divide-y divide-primary divide-solid">
        {threads?.length > 0 &&
          threads
            .filter((thread) => thread.HistoryThread)
            .map((thread, index) => (
              <tr
                className="w-full h-16 divide-x divide-primary divide-solid"
                key={index}
              >
                {/* Campo 'Fil x': */}
                <td className="w-1/12 text-xs text-center">Fil {index}</td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 text-xs">
                  <div className="flex items-center">
                    <div className="w-full px-0.5 py-1 border-gray-300 whitespace-normal">
                      {thread?.HistoryThread?.ThreadMatter}
                    </div>
                  </div>
                </td>

                {/* Campo 'GRS': */}
                <td className="w-2/12 text-center">
                  {thread?.HistoryThread?.GRS ? (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-green-200 border-2 border-green-300 rounded-full">
                        <ImCheckmark size={12} />
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-red-200 border-2 border-red-300 rounded-full">
                        <ImCross size={12} />
                      </p>
                    </div>
                  )}
                </td>

                {/* Campo 'Nº Cons': */}
                <td className="w-2/12 text-xs text-center">
                  <Field
                    type="text"
                    className="w-full table-input-disabled"
                    value={thread?.HistoryThread?.NCones}
                    disabled
                  />
                </td>

                {/* Campo 'Percentatge': */}
                <td className="w-2/12 text-xs text-center">
                  <Field
                    type="text"
                    className="w-full table-input-disabled"
                    value={
                      thread?.HistoryThread?.NCones !== 0 &&
                      thread?.HistoryThread?.NCones &&
                      threads
                        ? (
                            (thread?.HistoryThread?.NCones /
                              threads?.filter((thread) => thread.HistoryThread)
                                ?.length) *
                            100
                          )
                            .toFixed()
                            .toString() + ' %'
                        : 0 + ' %'
                    }
                    disabled
                  />
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

ThreadTable.propTypes = {
  threads: PropTypes.any
}
