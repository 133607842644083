import PropTypes from 'prop-types'

export default function ChooseTypeModal({ handleClick }) {
  const types = [
    { name: 'Magatzem', color: 'red' },
    { name: 'Teixiduria', color: 'blue' },
    { name: 'Tintura', color: 'green' },
    { name: 'Acabat', color: 'yellow' }
  ]
  return (
    <div className="grid gap-2 m-6 grid-cols-2">
      <p className="col-span-2 text-center font-ms-semi text-xl mb-4">
        Selecciona el tipus
      </p>
      {types.map(({ name, color }, i) => (
        <button
          onClick={() => handleClick(name)}
          className={`h-64 w-full text-3xl font-ms-bold flex items-center justify-center bg-${color}-200 hover:bg-${color}-300 `}
          key={i}
        >
          {name}
        </button>
      ))}
    </div>
  )
}

ChooseTypeModal.propTypes = {
  handleClick: PropTypes.func
}
