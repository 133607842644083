import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { BsBoxArrowInRight } from 'react-icons/bs'
import { CgSpinner } from 'react-icons/cg'
import Select from 'react-select'
import { useLocation } from 'wouter'
import * as Yup from 'yup'

const ForecastTrainingForm = ({
  handleSubmit,
  activateMonitoring,
  fields,
  isNew
}) => {
  const [, setLocation] = useLocation()

  // El campo 'Priority' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'Priority':
  const priorities = [
    { Id: 1, Name: 'Alta' },
    { Id: 2, Name: 'Mitja' },
    { Id: 3, Name: 'Baixa' }
  ]

  const validationSchema = Yup.object().shape({
    Entity: Yup.string().required('*').min(2, '*').max(100, '*'),
    DetailCourse: Yup.string().required('*').min(2, '*').max(100, '*'),
    Hours: Yup.number()
      .required('*')
      .integer("* El camp 'Hores' no pot ser decimal")
      .min(0, "* El camp 'Hores' ha de ser mínim 0"),
    Minutes: Yup.number()
      .required('*')
      .integer("* El camp 'Minuts' no pot ser decimal")
      .min(0, "* El camp 'Minuts' ha de ser mínim 0")
      .max(59, "* El camp 'Minuts' ha de ser máxim 59"),
    Price: Yup.number().required('*').integer('*'),
    PrevisionDate: Yup.string().required('*'),
    Priority: Yup.number().required('*').positive('*')
  })

  return (!isNew && fields.Id !== 0) || (isNew && fields.Id === 0) ? (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{ ...fields }}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm
      }) => (
        <Form className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md">
          <div className="flex flex-row items-center justify-between w-full text-sm uppercase bg-blue-200 font-ms-bold">
            <span className="py-2 pl-2">
              {!isNew ? 'PLA DE FORMACIÓ #' + values.Id : 'NOU PLA DE FORMACIÓ'}
            </span>
          </div>

          <div className="p-3 space-y-3">
            {values.CourseState === 2 && (
              <div
                className="flex items-center p-3 mx-auto text-white bg-red-500 border border-red-600 rounded-sm w-12/12"
                role="alert"
              >
                <svg
                  className="inline w-4 h-4 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>

                <span className="sr-only">Info</span>

                <div>
                  El seguiment està tancat. No es pot modificar el pla de
                  formació.
                </div>
              </div>
            )}

            <div className="grid w-full grid-cols-12 gap-y-3 gap-x-9">
              {/* FORMADOR ('Entitat') */}
              <div className="w-full col-span-6">
                <div className="flex flex-row">
                  <label className="mr-2 text-sm font-ms-semi" htmlFor="Entity">
                    Formador
                  </label>

                  <ErrorMessage
                    name="Entity"
                    render={(message) => (
                      <span className="text-sm text-red-600">{message}</span>
                    )}
                  />
                </div>

                <Field
                  type="text"
                  name="Entity"
                  placeholder={values.CourseState === 2 ? '' : 'Introdueix...'}
                  className={`w-full ${
                    values.CourseState === 2
                      ? 'input-disabled'
                      : touched.Entity && errors.Entity
                      ? 'input-editable-standard border-red-300 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'input-editable-interactive'
                  }`}
                  autoComplete="off"
                  disabled={values.CourseState === 2}
                />
              </div>

              {/* CURS */}
              <div className="w-full col-span-6">
                <div className="flex flex-row">
                  <label
                    className="mr-2 text-sm font-ms-semi"
                    htmlFor="DetailCourse"
                  >
                    Curs
                  </label>

                  <ErrorMessage
                    name="DetailCourse"
                    render={(message) => (
                      <span className="text-sm text-red-600">{message}</span>
                    )}
                  />
                </div>

                <Field
                  type="text"
                  name="DetailCourse"
                  placeholder={values.CourseState === 2 ? '' : 'Introdueix...'}
                  className={`w-full ${
                    values.CourseState === 2
                      ? 'input-disabled'
                      : touched.DetailCourse && errors.DetailCourse
                      ? 'input-editable-standard border-red-300 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'input-editable-interactive'
                  }`}
                  autoComplete="off"
                  disabled={values.CourseState === 2}
                />
              </div>

              {/* HORES */}
              <div className="w-full col-span-6">
                <div className="flex flex-row">
                  <label className="mr-2 text-sm font-ms-semi" htmlFor="Hours">
                    Duració
                  </label>

                  {(errors.Hours || errors.Minutes) && (
                    <span className="text-sm text-red-600">
                      {errors.Hours || errors.Minutes}
                    </span>
                  )}
                </div>

                <div className="flex flex-row w-full space-x-3">
                  <div className="flex flex-row items-center w-full">
                    <Field
                      type="number"
                      name="Hours"
                      placeholder={
                        values.CourseState === 2 ? '' : 'Introdueix...'
                      }
                      className={`w-full ${
                        values.CourseState === 2
                          ? 'input-disabled'
                          : touched.Hours && errors.Hours
                          ? 'input-editable-standard border-red-300 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'input-editable-interactive'
                      }`}
                      autoComplete="off"
                      disabled={values.CourseState === 2}
                    />
                    <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                      h
                    </label>
                  </div>

                  <div className="flex flex-row items-center w-full">
                    <Field
                      type="number"
                      name="Minutes"
                      placeholder={
                        values.CourseState === 2 ? '' : 'Introdueix...'
                      }
                      className={`w-full ${
                        values.CourseState === 2
                          ? 'input-disabled'
                          : touched.Minutes && errors.Minutes
                          ? 'input-editable-standard border-red-300 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'input-editable-interactive'
                      }`}
                      autoComplete="off"
                      disabled={values.CourseState === 2}
                    />
                    <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                      min
                    </label>
                  </div>
                </div>
              </div>

              {/* PRIORITAT */}
              <div className="w-full col-span-6">
                <div className="flex flex-row">
                  <label
                    className="mr-2 text-sm font-ms-semi"
                    htmlFor="Priority"
                  >
                    Prioritat
                  </label>

                  <ErrorMessage
                    name="Priority"
                    render={(message) => (
                      <span className="text-sm text-red-600">{message}</span>
                    )}
                  />
                </div>

                <Select
                  name="Priority"
                  placeholder={values.CourseState === 2 ? '' : 'Seleccionar...'}
                  className="w-full leading-tight"
                  onChange={(e) => {
                    setFieldValue('Priority', e.Id)
                  }}
                  options={priorities}
                  getOptionLabel={(o) => o.Name}
                  getOptionValue={(o) => o.Id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused
                        ? errors.Priority && touched.Priority
                          ? '#f87171'
                          : '#9CA3AF'
                        : errors.Priority && touched.Priority
                        ? '#fecaca'
                        : '#D1D5DB',
                      '&:hover': {
                        borderColor:
                          errors.Priority && touched.Priority
                            ? '#f87171'
                            : '#9CA3AF'
                      },
                      backgroundColor:
                        values.CourseState === 2
                          ? '#f2f2f2'
                          : errors.Priority && touched.Priority
                          ? '#fef2f2'
                          : '#ffffff'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: values.CourseState === 2 ? '#6b7280' : '#000000'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={priorities.find(
                    (option) => option.Id === values.Priority
                  )}
                  isDisabled={values.CourseState === 2}
                />
              </div>

              {/* PREU */}
              <div className="w-full col-span-6">
                <div className="flex flex-row">
                  <label className="mr-2 text-sm font-ms-semi" htmlFor="Price">
                    Preu
                  </label>

                  <ErrorMessage
                    name="Price"
                    render={(message) => (
                      <span className="text-sm text-red-600">{message}</span>
                    )}
                  />
                </div>

                <div className="flex flex-row items-center justify-center">
                  <Field
                    type="number"
                    name="Price"
                    placeholder={
                      values.CourseState === 2 ? '' : 'Introdueix...'
                    }
                    className={`w-full ${
                      values.CourseState === 2
                        ? 'input-disabled'
                        : touched.Price && errors.Price
                        ? 'input-editable-standard border-red-300 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'input-editable-interactive'
                    }`}
                    autoComplete="off"
                    disabled={values.CourseState === 2}
                  />
                  <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                    €
                  </label>
                </div>
              </div>

              {/* ESTADI */}
              <div className="w-full col-span-6">
                <label className="flex text-sm font-ms-semi">Estadi</label>

                <div
                  className={`w-full leading-normal rounded-sm appearance-none px-2 py-0.5 border outline-none text-grey text-white text-center
                    ${
                      values.CourseState === 1
                        ? 'bg-green-500 border-green-600 col-span-3'
                        : ''
                    }
                    ${
                      values.CourseState === 2
                        ? 'bg-red-500 border-red-600 col-span-3'
                        : ''
                    }
                    ${
                      values.CourseState === 3
                        ? 'bg-amber-500 border-amber-600 col-span-3'
                        : ''
                    }`}
                >
                  {values.CourseState === 1 && 'Obert'}
                  {values.CourseState === 2 && 'Tancat'}
                  {values.CourseState === 3 && 'Pendent'}
                </div>
              </div>

              {/* PREVISIÓ */}
              <div className="w-full col-span-6">
                <div className="flex flex-row">
                  <label
                    className="mr-2 text-sm font-ms-semi"
                    htmlFor="PrevisionDate"
                  >
                    Previsió
                  </label>

                  <ErrorMessage
                    name="PrevisionDate"
                    render={(message) => (
                      <span className="text-sm text-red-600">{message}</span>
                    )}
                  />
                </div>

                <Field
                  type="date"
                  name="PrevisionDate"
                  placeholder={values.CourseState === 2 ? '' : 'Introdueix...'}
                  className={`w-full leading-snug ${
                    values.CourseState === 2
                      ? 'input-disabled'
                      : touched.PrevisionDate && errors.PrevisionDate
                      ? 'input-editable-standard border-red-300 bg-red-50 hover:border-red-400 focus:border-red-400 cursor-text'
                      : 'input-editable-interactive cursor-text'
                  }`}
                  autoComplete="off"
                  disabled={values.CourseState === 2}
                />
              </div>

              {/* SEGUIMENT */}
              <div className="grid w-full grid-cols-12 col-span-6 gap-x-3">
                <label className="flex col-span-12 text-sm font-ms-semi">
                  Seguiment
                </label>

                <div
                  className={`leading-normal text-white rounded-sm appearance-none px-2 py-0.5 border outline-none text-center
                    ${
                      values.MonitoringActivated
                        ? 'bg-green-500 border-green-600 col-span-8'
                        : 'bg-amber-500 border-amber-600 col-span-12'
                    }`}
                >
                  {values.MonitoringActivated ? 'Activat' : 'Pendent'}
                </div>

                <button
                  type="button"
                  className={`col-span-4 leading-normal text-white rounded-sm appearance-none px-2 py-0.5 border outline-none flex justify-center border-green-600 bg-green-500 cursor-pointer hover:bg-blue-200 hover:text-green-600 ${
                    values.MonitoringActivated ? '' : 'hidden'
                  }`}
                  onClick={(e) => {
                    setLocation('/seguiment-formacio/' + values.TrainingPlan_Id)
                  }}
                >
                  <BsBoxArrowInRight size={24} />
                </button>
              </div>

              {/* IMPLICATS */}
              <div className="w-full col-span-12">
                <label className="flex text-sm font-ms-semi" htmlFor="Involved">
                  Implicats
                </label>

                <Field
                  as="textarea"
                  name="Involved"
                  placeholder={values.CourseState === 2 ? '' : 'Introdueix...'}
                  className={`h-40 w-full align-top ${
                    values.CourseState === 2
                      ? 'input-disabled'
                      : 'input-editable-interactive'
                  }`}
                  autoComplete="off"
                  disabled={values.CourseState === 2}
                />
              </div>

              {/* OBSERVACIONS */}
              <div className="w-full col-span-12">
                <label
                  className="flex text-sm font-ms-semi"
                  htmlFor="Description"
                >
                  Observacions
                </label>

                <Field
                  as="textarea"
                  name="Description"
                  placeholder={values.CourseState === 2 ? '' : 'Introdueix...'}
                  className={`h-40 w-full align-top ${
                    values.CourseState === 2
                      ? 'input-disabled'
                      : 'input-editable-interactive'
                  }`}
                  autoComplete="off"
                  disabled={values.CourseState === 2}
                />
              </div>
            </div>

            {/* GUARDAR / ACTIVAR: */}
            {values.CourseState !== 2 && (
              <div className="flex flex-row justify-center col-span-1">
                <div>
                  <button
                    type="submit"
                    className={`transition duration-300 text-white font-ms-semi py-1 px-4 ml-auto border rounded-sm h-10 w-28 border-primary bg-primary hover:bg-blue-200 hover:text-primary ${
                      values.MonitoringActivated ? '' : 'mr-2'
                    }`}
                  >
                    GUARDAR
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className={`transition duration-300 text-white font-ms-semi py-1 px-4 ml-auto border rounded-sm h-10 w-28 border-green-600 bg-green-600 hover:bg-blue-200 hover:text-green-600 ${
                      isNew || values.MonitoringActivated ? 'hidden' : ''
                    }`}
                    onClick={(e) => {
                      activateMonitoring(values)
                    }}
                  >
                    ACTIVAR
                  </button>
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

ForecastTrainingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  activateMonitoring: PropTypes.func,
  fields: PropTypes.object,
  params: PropTypes.object,
  isNew: PropTypes.bool
}

export default ForecastTrainingForm
