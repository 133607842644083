import Form1 from 'forms/clientsrequest/clientCreate'
import PropTypes from 'prop-types'

export default function Block({
  title,
  className,
  setFieldValue,
  users,
  values
}) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md border-blue-200`}
    >
      <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
        <span>{title}</span>
      </div>

      <Form1 setFieldValue={setFieldValue} users={users} values={values} />
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  users: PropTypes.array,
  values: PropTypes.object
}
