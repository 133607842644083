import InferiorIcon from 'assets/icons/inferior.png'
import SuperiorIcon from 'assets/icons/superior.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

// REACT ICONS
import { MdArrowDropDown } from 'react-icons/md'

export default function RamForm({ ramNumber, ramIndex }) {
  return (
    <div className="grid w-full grid-cols-12 gap-2 auto-rows-min">
      {/* AMPLE CADENA */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>AMPLE CADENA (cm)</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-fr print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Ample 0': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 0:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Width0`}
              className={`w-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                  hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 1': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 1:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Width1`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 2': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 2:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Width2`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 3': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 3:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Width3`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 4': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 4:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Width4`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 5': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 5:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Width5`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 6': */}
          {ramNumber !== 3 && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Ample 6:
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <Field
                  type="text"
                  name={`RequestRams[${ramIndex}].Width6`}
                  className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                    hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
                />
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}

          {/* Campo 'Ample 7': */}
          {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Ample 7:
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <Field
                  type="text"
                  name={`RequestRams[${ramIndex}].Width7`}
                  className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                    hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
                />
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}
        </div>
      </div>

      {/* TEMPERATURA */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>TEMPERATURA (ºC)</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Camp 1': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 1
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Temperature1`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 2': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 2
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Temperature2`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 3': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 3
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Temperature3`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 4': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 4
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Temperature4`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 5': */}
          {ramNumber !== 3 && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Camp 5
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <Field
                  type="text"
                  name={`RequestRams[${ramIndex}].Temperature5`}
                  className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                    hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
                />
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}

          {/* Campo 'Camp 6': */}
          {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Camp 6
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <Field
                  type="text"
                  name={`RequestRams[${ramIndex}].Temperature6`}
                  className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                    hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
                />
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}
        </div>
      </div>

      {/* VENTILACIÓ */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>VENTILACIÓ (%)</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 auto-rows-fr print:p-2 gap-x-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Input 1': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            {ramNumber !== 3 && (
              <Field
                type="text"
                name={`RequestRams[${ramIndex}].TopVentilationPercentage`}
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                    hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            )}
          </div>

          {/* Campo 'Imagen SUP': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <img className="m-auto" src={SuperiorIcon} alt="sup" width={25} />
          </div>

          {/* Campo 'Input 2': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].BottomVentilation1`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Input 3': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].TopVentilation1`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Input 4': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].BottomVentilation2`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Input 5': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].TopVentilation2`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Input 6': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].BottomVentilation3`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Input 7': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].TopVentilation3`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Imagen INF': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            <img className="m-auto" src={InferiorIcon} alt="inf" width={25} />
          </div>

          {/* Campo 'Input 8': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
            {ramNumber === 3 && (
              <Field
                type="text"
                name={`RequestRams[${ramIndex}].BottomVentilationPercentage`}
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                  hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            )}
          </div>
        </div>
      </div>

      {/* SOBREALIMENTACIÓ */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>SOBREALIMENTACIÓ (%)</span>
        </div>

        {/* Campo 'SUP / INF': */}
        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-2 print:gap-y-2 print:auto-rows-auto">
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            SUP/INF:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].OverFeeding`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>
        </div>
      </div>

      {/* SORTIDA */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>SORTIDA</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Tensió': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Tensió:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Tension`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;</span>
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].TensionTolerance`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Potenciòm.': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Potenciòm.:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Potential`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;</span>
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].PotentialTolerance`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Refrigeració': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Refrigeració:
          </div>
          <div className="relative flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              as="select"
              name={`RequestRams[${ramIndex}].IsRefrigeration`}
              className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
            >
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </Field>
            <MdArrowDropDown
              size={25}
              className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
            />
          </div>
        </div>
      </div>

      {/* ALTRES */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>ALTRES</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Pot. encoladors': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pot. encoladors:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].GlueEnhancer`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;</span>
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].GlueEnhancerTolerance`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Pot. raspalls': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pot. raspalls:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].BrushEnhancer`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;</span>
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].BrushEnhancerTolerance`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Pressió Foul.': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pressió Foul.:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].FoulardTension`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
            <span>&plusmn;</span>
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].FoulardTensionTolerance`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>

          {/* Campo 'Vaporitzador': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Vaporitzador:
          </div>
          <div className="relative flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              as="select"
              name={`RequestRams[${ramIndex}].HasVaporizer`}
              className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
            >
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </Field>
            <MdArrowDropDown
              size={25}
              className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
            />
          </div>

          {/* Campo 'Posar drap': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Posar drap:
          </div>
          <div className="relative flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
            <Field
              as="select"
              name={`RequestRams[${ramIndex}].HasRag`}
              className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
            >
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </Field>
            <MdArrowDropDown
              size={25}
              className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
            />
          </div>
        </div>
      </div>

      {/* VELOCITAT (m/min) + EXTRACCIÓ */}
      <div className="grid grid-cols-1 col-span-12 gap-y-2">
        <div className="flex flex-col col-span-4 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>VELOCITAT (m/min)</span>
          </div>

          <div className="grid grid-cols-12 p-3 gap-y-3">
            {/* Campo 'MIN': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-2 font-ms-semi print:mt-0">
              MIN:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-10">
              <Field
                type="text"
                name={`RequestRams[${ramIndex}].MinSpeed`}
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                  hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            </div>

            {/* Campo 'MAX': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-2 font-ms-semi print:mt-0">
              MAX:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:h-full print:col-span-9 lg:col-span-8 xl:col-span-10">
              <Field
                type="text"
                name={`RequestRams[${ramIndex}].MaxSpeed`}
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                  hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-4 bg-white border border-collapse border-blue-200 rounded">
          <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
            <span>EXTRACCIÓ</span>
          </div>

          <div className="grid grid-cols-1 p-3 gap-y-2">
            <div className="flex items-center h-8 col-span-6 space-x-2 cursor-not-allowed print:h-full">
              <div className="relative flex items-center w-full h-8 col-span-12 space-x-2 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9">
                <Field
                  as="select"
                  name={`RequestRams[${ramIndex}].ExtractionType`}
                  className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
                    hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
                >
                  <option value="">-</option>
                  <option value={1}>Automàtic</option>
                  <option value={2}>Manual</option>
                </Field>
                <MdArrowDropDown
                  size={25}
                  className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
                />
              </div>
              <Field
                type="text"
                name={`RequestRams[${ramIndex}].Extraction`}
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                  hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="flex flex-col col-span-12 bg-white border border-collapse border-blue-200 rounded">
        <div className="flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>OBSERVACIONS</span>
        </div>

        <div className="w-full p-3">
          {/* Campo 'Observació': */}
          <div className="relative flex items-center w-full h-8 col-span-12 space-x-2">
            <Field
              type="text"
              name={`RequestRams[${ramIndex}].Observation`}
              className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
                hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

RamForm.propTypes = {
  ramNumber: PropTypes.number,
  ramIndex: PropTypes.number
}
