import { ModalContext } from 'contexts/ModalContext'
import { useContext } from 'react'
import ReactDOM from 'react-dom'

export default function Modal() {
  const { modalContent, handleModal, modal } = useContext(ModalContext)
  if (modal) {
    return ReactDOM.createPortal(
      <div
        className="fixed inset-0 z-50 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed w-full h-full cursor-pointer"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            aria-hidden="true"
            onClick={() => handleModal()}
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block text-left align-bottom transition-all transform bg-white border-2 rounded-sm shadow-xl border-darkCharcoal sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
            style={{ marginTop: '90px' }}
          >
            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              {modalContent}
            </div>
          </div>
        </div>
      </div>,
      document.querySelector('#modal')
    )
  } else return null
}
