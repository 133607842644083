import { Field, FieldArray } from 'formik'
import PropTypes from 'prop-types'
import { BiPlusMedical } from 'react-icons/bi'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { ImCheckmark, ImCross } from 'react-icons/im'
import Select from 'react-select'

export default function ThreadTable({ threads, values, threadOptions }) {
  return (
    <table className="relative w-full px-4 border table-fixed border-primary">
      <FieldArray
        name="RequestThreads"
        render={(arrayHelpers) => (
          <>
            <thead className="w-full text-white bg-primary-dark">
              <tr className="w-full divide-x divide-primary divide-solid">
                <th className="w-1/12 text-sm"></th>
                <th className="w-6/12 text-sm">Matèria</th>
                <th className="w-1/12 text-sm">GRS</th>
                <th className="w-2/12 text-sm">Nº Cons</th>
                <th className="w-2/12 py-1.5 text-sm">Percentatge</th>
                <th className="w-1/12">
                  <div className="items-center w-full flex-justify-center tooltip">
                    <button
                      type="button"
                      className="p-2 py-1 text-sm transition-colors duration-150 rounded-lg appearance-none cursor-pointer font-ms-semi hover:bg-white hover:text-primary focus:shadow-outline"
                      onClick={() =>
                        arrayHelpers.push({
                          RequestThreadId: 0,
                          Thread: null,
                          ThreadNumber:
                            threads.length > 0
                              ? String(
                                  Number(
                                    threads[threads.length - 1].ThreadNumber
                                  ) + 1
                                )
                              : '1',
                          ThreadMatter: null,
                          NCones: null,
                          Percentage: '0',
                          TSWeavingID: values?.ArticleId,
                          ThreadTitle: null,
                          GRS: false,
                          TextGRS: '',
                          HasBeenRemoved: 0,
                          HistoryThread: null
                        })
                      }
                    >
                      <BiPlusMedical size={20} />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className="w-full divide-y divide-primary divide-solid">
              {threads?.length > 0 &&
                threads.map((thread, index) => (
                  <tr
                    className={`${
                      thread?.HasBeenRemoved && 'hidden'
                    } w-full h-16 divide-x divide-primary divide-solid`}
                    key={index}
                  >
                    {/* Campo 'Fil x': */}
                    <td className="w-1/12 text-xs text-center">Fil {index}</td>

                    {/* Campo 'Matèria': */}
                    <td className="w-6/12 text-xs">
                      {!threadOptions ? (
                        <div className="w-full p-0.5 bg-gray-100 border border-gray-300 rounded-sm">
                          <CgSpinner
                            size={20}
                            className="text-charcoal animate-spin"
                          />
                        </div>
                      ) : (
                        <Select
                          className="w-full text-xs leading-tight"
                          onChange={(e) => {
                            arrayHelpers.replace(index, {
                              ...thread,
                              Thread: e.cod_art,
                              ThreadMatter: (
                                e.cod_art +
                                ' - ' +
                                e.desc_art_sec
                              ).slice(17),
                              ThreadTitle: e.titulo,
                              PrintThread: (
                                e.cod_art +
                                ' - ' +
                                e.desc_art_sec
                              ).slice(17),
                              GRS: e.txt_GRS
                            })
                          }}
                          placeholder="Seleccionar..."
                          options={threadOptions}
                          getOptionLabel={(o) => o.desc_art_sec}
                          getOptionValue={(o) => o.cod_art}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: '0.125rem',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              minHeight: 'auto',
                              lineHeight: '20px',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: state.isFocused
                                ? '#9CA3AF'
                                : '#D1D5DB',
                              '&:hover': {
                                borderColor: '#9CA3AF'
                              }
                            }),
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              height: '100%',
                              padding: '0.125rem',
                              position: 'relative',
                              cursor: 'pointer'
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                              margin: '0',
                              paddingTop: '0',
                              paddingBottom: '0',
                              cursor: 'pointer'
                            }),
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              marginLeft: '0',
                              marginRight: '0'
                            }),
                            indicatorsContainer: (baseStyles) => ({
                              ...baseStyles,
                              cursor: 'pointer'
                            }),
                            dropdownIndicator: (baseStyles) => ({
                              ...baseStyles,
                              padding: '0px 8px'
                            })
                          }}
                          defaultValue={{
                            cod_art: thread.Thread,
                            desc_art_sec: thread.ThreadMatter,
                            titulo: thread.ThreadTitle
                          }}
                        />
                      )}
                    </td>

                    {/* Campo 'GRS': */}
                    <td className="w-2/12 text-center">
                      {values.GRS ? (
                        <div className="flex items-center justify-center">
                          <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-green-200 border-2 border-green-300 rounded-full">
                            <ImCheckmark size={12} />
                          </p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-red-200 border-2 border-red-300 rounded-full">
                            <ImCross size={12} />
                          </p>
                        </div>
                      )}
                    </td>

                    {/* Campo 'Nº Cons': */}
                    <td className="w-2/12 text-xs text-center">
                      <Field
                        type="text"
                        name={`RequestThreads[${index}].NCones`}
                        className="w-full table-input"
                        disabled={
                          thread?.ThreadMatter === null ||
                          thread?.ThreadMatter === undefined ||
                          thread?.ThreadMatter === ''
                        }
                      />
                    </td>

                    {/* Campo 'Percentatge': */}
                    {/* TODO */}
                    <td className="w-2/12 text-xs text-center">
                      <Field
                        type="text"
                        className="w-full table-input-disabled"
                        value={
                          thread?.NCones !== 0 && thread?.NCones && threads
                            ? (
                                (thread?.NCones /
                                  threads?.filter(
                                    (thread) => !thread?.HasBeenRemoved
                                  )?.length) *
                                100
                              )
                                .toFixed()
                                .toString() + ' %'
                            : 0 + ' %'
                        }
                        disabled
                      />
                    </td>

                    {/* Botón 'Eliminar': */}
                    <td className="w-1/12">
                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          className="p-2 m-auto font-medium text-black bg-white rounded-lg cursor-pointer focus:shadow-outline hover:bg-gray-300"
                          onClick={() => {
                            if (thread?.HistoryThread === null) {
                              arrayHelpers.remove(index)
                            } else {
                              arrayHelpers.replace(index, {
                                ...thread,
                                HasBeenRemoved: 1
                              })
                            }
                          }}
                        >
                          <FaTrash size={17} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </>
        )}
      />
    </table>
  )
}

ThreadTable.propTypes = {
  threads: PropTypes.any,
  values: PropTypes.object,
  threadOptions: PropTypes.any
}
