// FORMIK HOOK
import { Field } from 'formik'

// REACT ICONS
import { MdArrowDropDown } from 'react-icons/md'

export default function FoulardsFinishForm() {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* CONTINGUT CUBETA */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Contingut cubeta:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FoulardName"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* FÒRMULA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Fòrmula:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FoulardFormula"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* DOSICIFACIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Dosificació:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.IsDosificated"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* Nº RECEPTA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Nº recepta:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FoulardRecipeNumber"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FoulardObservation"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>
    </div>
  )
}

FoulardsFinishForm.propTypes = {}
