import PropTypes from 'prop-types'
import React from 'react'
import useModal from 'hooks/useModal'
import Modal from 'components/ui/Modal/Modal'

let ModalContext
const { Provider } = (ModalContext = React.createContext())

const ModalContextProvider = ({ children }) => {
  const { modal, handleModal, modalContent } = useModal()
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalContextProvider }

ModalContextProvider.propTypes = {
  children: PropTypes.any
}
