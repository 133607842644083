// PropTypes
import PropTypes from 'prop-types'

export default function FeaturesForm({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'
  const checkStyles = 'border border-gray-800 ft-check'

  return (
    <div className={blockStyle}>
      {/* AMPLE BOBINA */}
      <div className={firstLabelStyles}>Ample bobina:</div>
      <div className={firstParentStyles}>
        <div className={innerParentStyles}>{values?.OutputWidth}</div>
        <span>cm</span>
      </div>

      {/* RENDIMENT */}
      <div className={otherLabelsStyles}>Rendiment:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Performance}</div>
        <span>&plusmn;</span>
        <div className={innerParentStyles}>{values?.PerformanceTolerance}</div>
        <span>m/Kg</span>
        <div className={checkStyles}></div>
      </div>

      {/* GRAMATGE */}
      <div className={otherLabelsStyles}>Gramatge:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Weight}</div>
        <span>&plusmn;</span>
        <div className={innerParentStyles}>{values?.WeightTolerance}</div>

        {/* LINEAL */}
        <span className="text-sm font-ms-semi">Lineal:</span>
        <div className={innerParentStyles}>{values?.LinearWeight}</div>
        <span>g/m.l.</span>
        <div className={checkStyles}></div>
      </div>

      {/* MTS/BOBINA */}
      <div className={otherLabelsStyles}>Mts/bobina:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Meters}</div>
        <span>&plusmn;</span>
        <div className={innerParentStyles}>{values?.MeterTolerance}</div>
        <div className={checkStyles}></div>
      </div>

      {/* TALL AUTOMÀTIC */}
      <div className={otherLabelsStyles}>Tall automàtic:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.IsAutomatedCut ? 'Sí' : 'No'}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* BOB. AL REVÉS */}
      <div className={otherLabelsStyles}>Bob. al revés:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.IsReverseCoil ? 'Sí' : 'No'}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* Nº COSTURES */}
      <div className={otherLabelsStyles}>Nº costures:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Seams}</div>
        <div className={innerParentStyles}>{values?.SeamsObservation}</div>
        <div className={checkStyles}></div>
      </div>

      {/* MARCAR COSTURES */}
      <div className={otherLabelsStyles}>Marcar costures:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.IsSeamsMarked ? 'Sí' : 'No'}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* FREQÜÈNCIA ANÀLISI */}
      <div className={otherLabelsStyles}>Freqüència anàlisi:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {(() => {
            switch (values?.ReviewFrequenceId) {
              case '1':
                return 'SERIES: Mínim 3 assajos complets (+ 5.000m: 1 SI 2 NO)'
              case '2':
                return 'Mínim 1 SI 9 NO'
              case '3':
                return 'Mínim 1 SI 6 NO'
              case '4':
                return 'Mínim 1 SI 4 NO'
              case '5':
                return 'Mínim 1 SI 5 NO'
              case '6':
                return 'Mínim 1 SI 3 NO'
              case '7':
                return 'Mínim 1 SI 2 NO'
              case '8':
                return 'Gramatge, gruix i ample de totes les bobines i 1 anàlisi sencer de cada 4 bobines'
              default:
                return '-'
            }
          })()}
        </div>

        <div className="col-span-12 w-full p-0.5 min-h-8 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.ReviewFrequence}
        </div>

        <div className="w-1/4 border border-gray-800 ft-check"></div>
      </div>

      {/* MOSTRA LABORATORI */}
      <div className={otherLabelsStyles}>Mostra laboratori:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.LabSample}</div>
        <div className={checkStyles}></div>
      </div>

      {/* BOBINETA CLIENT */}
      <div className={otherLabelsStyles}>Bobineta client:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.ClientSmallCoil}</div>
      </div>

      {/* ESPECIFICACIONS BOBINA */}
      <div className={otherLabelsStyles}>Especificacions bobina:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.ClientNotes}</div>
      </div>

      {/* OBSERVACIONS */}
      <div className={otherLabelsStyles}>Observacions:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.FeaturesObservation}</div>
      </div>
    </div>
  )
}

FeaturesForm.propTypes = {
  values: PropTypes.object
}
