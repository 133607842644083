import pinyons from 'assets/icons/pinyons.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function SprocketsForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  // Calcular los estilos
  const getFieldStyles = (field) => {
    const hasChanged =
      valuesBefore[field] !== valuesAfter[field] ||
      valuesBefore?.Machine?.slice(0, 3) !== valuesAfter?.Machine?.slice(0, 3)

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <div
      className={`${
        valuesBefore?.Machine?.slice(0, 3) !== valuesAfter?.Machine?.slice(0, 3)
          ? side === 'left'
            ? 'bg-red-50'
            : isAnswered
            ? 'bg-emerald-50'
            : 'bg-amber-50'
          : ''
      } relative flex items-center w-full h-full`}
    >
      <img
        src={pinyons}
        alt="pinyons plegador"
        className="py-16 mx-auto 2xl:py-16 w-80"
      />

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-1 md:right-1 lg:top-12 lg:right-1 xl:right-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          style={getFieldStyles('Piston2')}
          value={side === 'left' ? valuesBefore?.Piston2 : valuesAfter?.Piston2}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          style={getFieldStyles('PistonTolerance2')}
          value={
            side === 'left'
              ? valuesBefore?.PistonTolerance2
              : valuesAfter?.PistonTolerance2
          }
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-4 md:left-1 lg:top-16 lg:left-1 xl:left-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          style={getFieldStyles('Piston1')}
          value={side === 'left' ? valuesBefore?.Piston1 : valuesAfter?.Piston1}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          style={getFieldStyles('PistonTolerance1')}
          value={
            side === 'left'
              ? valuesBefore?.PistonTolerance1
              : valuesAfter?.PistonTolerance1
          }
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-16 md:right-1 lg:top-28 lg:right-1 xl:right-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          style={getFieldStyles('Piston4')}
          value={side === 'left' ? valuesBefore?.Piston4 : valuesAfter?.Piston4}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          style={getFieldStyles('PistonTolerance4')}
          value={
            side === 'left'
              ? valuesBefore?.PistonTolerance4
              : valuesAfter?.PistonTolerance4
          }
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-20 md:left-1 lg:top-32 lg:left-1 xl:left-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          style={getFieldStyles('Piston3')}
          value={side === 'left' ? valuesBefore?.Piston3 : valuesAfter?.Piston3}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          style={getFieldStyles('PistonTolerance3')}
          value={
            side === 'left'
              ? valuesBefore?.PistonTolerance3
              : valuesAfter?.PistonTolerance3
          }
          disabled
        />
      </div>

      {/* ---------------------------------- */}

      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) !== 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) !== 'JMB')) && (
        <>
          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-28 xl:left-48 2xl:left-60"
            style={getFieldStyles('Piston7')}
            value={
              side === 'left' ? valuesBefore?.Piston7 : valuesAfter?.Piston7
            }
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-56 xl:left-72 2xl:left-80"
            style={getFieldStyles('Piston6')}
            value={
              side === 'left' ? valuesBefore?.Piston6 : valuesAfter?.Piston6
            }
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-1 xl:left-24 2xl:left-36"
            style={getFieldStyles('Piston5')}
            value={
              side === 'left' ? valuesBefore?.Piston5 : valuesAfter?.Piston5
            }
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-28 xl:left-48 2xl:left-60"
            style={getFieldStyles('Piston8')}
            value={
              side === 'left' ? valuesBefore?.Piston8 : valuesAfter?.Piston8
            }
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-8 lg:left-1 xl:left-24 2xl:left-36"
            style={getFieldStyles('Piston9')}
            value={
              side === 'left' ? valuesBefore?.Piston9 : valuesAfter?.Piston9
            }
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-8 lg:left-28 xl:left-48 2xl:left-60"
            style={getFieldStyles('Piston10')}
            value={
              side === 'left' ? valuesBefore?.Piston10 : valuesAfter?.Piston10
            }
            disabled
          />
        </>
      )}

      {/* ---------------------------------- */}

      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <>
          {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-56 xl:left-72 2xl:left-80"
            style={getFieldStyles('JUM1')}
            value={side === 'left' ? valuesBefore?.JUM1 : valuesAfter?.JUM1}
            disabled
          />

          {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-28 xl:left-48 2xl:left-60"
            style={getFieldStyles('JUM2')}
            value={side === 'left' ? valuesBefore?.JUM2 : valuesAfter?.JUM2}
            disabled
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-28 xl:left-48 2xl:left-60"
            style={getFieldStyles('JUM3')}
            value={side === 'left' ? valuesBefore?.JUM3 : valuesAfter?.JUM3}
            disabled
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-1 xl:left-24 2xl:left-36"
            style={getFieldStyles('JUM4')}
            value={side === 'left' ? valuesBefore?.JUM4 : valuesAfter?.JUM4}
            disabled
          />
        </>
      )}
    </div>
  )
}

SprocketsForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
