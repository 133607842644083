import alcades from 'assets/icons/alcades.png'
import PropTypes from 'prop-types'
import GamesTable from './gamesTable'

export default function PointGraduationForm({ fields }) {
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'

  return (
    <div className="grid w-full grid-cols-12 gap-2 p-2 xl:grid-cols-11 print:gap-1">
      {/* Columna 1: */}
      {/* Campo 'Alçada cilindre (seccions)': */}
      <div className="grid items-center grid-cols-4 col-span-4 xl:col-span-3">
        <label className="block col-span-4 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative w-40 col-span-4 print:w-36">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1 print:px-0.5 print:pb-0.5">
            <input
              type="text"
              className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.CylinderHeight1}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.CylinderHeight2}
              disabled
            />
          </div>
        </div>
      </div>

      {/* Columna 2: */}
      {/* Campo 'Posició Aro Platines': */}
      <div className="flex flex-col flex-1 col-span-4">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Platines:
        </label>

        <div
          className={`${commonInputStyles} w-full flex-1 h-auto whitespace-pre-wrap max-h-28 truncate overflow-hidden`}
        >
          {fields?.PositionTurntables}
        </div>
      </div>

      {/* Columna 3: */}
      {/* Campo 'Posició Aro Guiafils': */}
      <div className="flex flex-col flex-1 col-span-4">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Guiafils:
        </label>

        <div
          className={`${commonInputStyles} w-full flex-1 h-auto whitespace-pre-wrap max-h-28 truncate overflow-hidden`}
        >
          {fields?.PositionGuiafils}
        </div>
      </div>

      {/* Tabla 'Jocs': */}
      <div className="w-full col-span-12">
        <GamesTable games={fields.TSWeavingMachineGroups} />
      </div>

      {/* Campo 'Tensiòmetre': */}
      <div className="flex items-center col-span-12 xl:col-span-11">
        <label
          className="block pr-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PassTensiometer"
        >
          Tensiòmetre
        </label>

        <input
          type="checkbox"
          className="w-6 h-6 ml-1"
          checked={fields?.PassTensiometer}
          style={{ backgroundColor: 'white' }}
          disabled
        />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {
  fields: PropTypes.object
}
