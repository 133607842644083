// PropTypes
import PropTypes from 'prop-types'

export default function UltrasonicSettings({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const labelStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0'
  const parentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-9 xl:col-span-10'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'

  const formatDate = (dateString) => {
    const utcDate = new Date(dateString)
    const userTimeZoneOffset = new Date().getTimezoneOffset()
    const userLocalTime = new Date(
      utcDate.getTime() - userTimeZoneOffset * 60000
    )

    // Obtener los componentes de la fecha
    const day = ('0' + userLocalTime.getDate()).slice(-2)
    const month = ('0' + (userLocalTime.getMonth() + 1)).slice(-2)
    const year = userLocalTime.getFullYear()
    const hour = ('0' + userLocalTime.getHours()).slice(-2)
    const minute = ('0' + userLocalTime.getMinutes()).slice(-2)

    // Construir la cadena de fecha formateada
    const formattedLocalTime = `${day}/${month}/${year}, ${hour}:${minute} h`

    return formattedLocalTime
  }

  return (
    <div className={blockStyle}>
      {/* SOL·LICITUD */}
      <div className={labelStyles}>Sol·licitud:</div>
      <div className={parentStyles}>
        <div className={innerParentStyles}>{'#' + values.RequestId}</div>
      </div>

      {/* MOTIU */}
      <div className={labelStyles}>Motiu:</div>
      <div className={parentStyles}>
        <div className={innerParentStyles}>{values.Reason}</div>
      </div>

      {/* SOL·LICITANT */}
      <div className={labelStyles}>Sol·licitant:</div>
      <div className={parentStyles}>
        <div className={innerParentStyles}>{values.Requester.Name}</div>
      </div>

      {/* REVISOR */}
      <div className={labelStyles}>Revisor:</div>
      <div className={parentStyles}>
        <div className={innerParentStyles}>{values.Responder.Name}</div>
      </div>

      {/* DATA */}
      <div className={labelStyles}>Data:</div>
      <div className={parentStyles}>
        <div className={innerParentStyles}>{formatDate(values?.UpdatedAt)}</div>
      </div>
    </div>
  )
}

UltrasonicSettings.propTypes = {
  values: PropTypes.object
}
