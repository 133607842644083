import PropTypes from 'prop-types'

export default function FeaturesForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* AMPLE BOBINA */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Ample bobina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.OutputWidth !== valuesAfter?.OutputWidth
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.OutputWidth
            : valuesAfter?.OutputWidth}
        </div>
      </div>

      {/* RENDIMENT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Rendiment:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-1.5 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.Performance !== valuesAfter?.Performance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.Performance
            : valuesAfter?.Performance}
        </div>
        <span>&plusmn;</span>
        <div
          className={`flex items-center w-full h-full px-1.5 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.PerformanceTolerance !==
            valuesAfter?.PerformanceTolerance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.PerformanceTolerance
            : valuesAfter?.PerformanceTolerance}
        </div>
        <span>m/Kg</span>
      </div>

      {/* GRAMATGE */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Gramatge:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`w-full px-1.5 py-1 h-full leading-tight text-base text-gray-500 border ${
            valuesBefore?.Weight !== valuesAfter?.Weight
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis overflow-hidden flex items-center`}
        >
          {side === 'left' ? valuesBefore?.Weight : valuesAfter?.Weight}
        </div>
        <span>&plusmn;</span>
        <div
          className={`w-full px-1.5 py-1 h-full leading-tight text-base border-b ${
            valuesBefore?.WeightTolerance !== valuesAfter?.WeightTolerance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis overflow-hidden flex items-center`}
        >
          {side === 'left'
            ? valuesBefore?.WeightTolerance
            : valuesAfter?.WeightTolerance}
        </div>

        {/* LINEAL */}
        <span className="pl-5 text-sm font-ms-semi">Lineal:</span>
        <div
          className={`w-full px-1.5 py-1 h-full leading-tight text-base text-gray-500 border ${
            valuesBefore?.LinearWeight !== valuesAfter?.LinearWeight
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis overflow-hidden flex items-center`}
        >
          {side === 'left'
            ? valuesBefore?.LinearWeight
            : valuesAfter?.LinearWeight}
        </div>
        <span>g/m.l.</span>
      </div>

      {/* MTS/BOBINA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Mts/bobina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`w-full px-1.5 py-1 h-full leading-tight text-base text-gray-500 border ${
            valuesBefore?.Meters !== valuesAfter?.Meters
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis overflow-hidden flex items-center`}
        >
          {side === 'left' ? valuesBefore?.Meters : valuesAfter?.Meters}
        </div>
        <span>&plusmn;</span>
        <div
          className={`w-full px-1.5 py-1 h-full leading-tight text-base rounded-sm text-gray-500 border ${
            valuesBefore?.MeterTolerance !== valuesAfter?.MeterTolerance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } overflow-ellipsis overflow-hidden flex items-center`}
        >
          {side === 'left'
            ? valuesBefore?.MeterTolerance
            : valuesAfter?.MeterTolerance}
        </div>
      </div>

      {/* TALL AUTOMÀTIC */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Tall automàtic:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.IsAutomatedCut !== valuesAfter?.IsAutomatedCut
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.IsAutomatedCut
              ? 'Sí'
              : 'No'
            : valuesAfter?.IsAutomatedCut
            ? 'Sí'
            : 'No'}
        </div>
      </div>

      {/* BOB. AL REVÉS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Bob. al revés:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.IsReverseCoil !== valuesAfter?.IsReverseCoil
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.IsReverseCoil
              ? 'Sí'
              : 'No'
            : valuesAfter?.IsReverseCoil
            ? 'Sí'
            : 'No'}
        </div>
      </div>

      {/* Nº COSTURES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Nº costures:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.Seams !== valuesAfter?.Seams
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Seams : valuesAfter?.Seams}
        </div>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.SeamsObservation !== valuesAfter?.SeamsObservation
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.SeamsObservation
            : valuesAfter?.SeamsObservation}
        </div>
      </div>

      {/* MARCAR COSTURES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Marcar costures:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.IsSeamsMarked !== valuesAfter?.IsSeamsMarked
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.IsSeamsMarked
              ? 'Sí'
              : 'No'
            : valuesAfter?.IsSeamsMarked
            ? 'Sí'
            : 'No'}
        </div>
      </div>

      {/* FREQÜÈNCIA ANÀLISI */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Freqüència anàlisi:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.ReviewFrequenceId !== valuesAfter?.ReviewFrequenceId
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {(() => {
            switch (
              side === 'left'
                ? valuesBefore?.ReviewFrequenceId
                : valuesAfter?.ReviewFrequenceId
            ) {
              case '1':
                return 'SERIES: Mínim 3 assajos complets (+ 5.000m: 1 SI 2 NO)'
              case '2':
                return 'Mínim 1 SI 9 NO'
              case '3':
                return 'Mínim 1 SI 6 NO'
              case '4':
                return 'Mínim 1 SI 4 NO'
              case '5':
                return 'Mínim 1 SI 5 NO'
              case '6':
                return 'Mínim 1 SI 3 NO'
              case '7':
                return 'Mínim 1 SI 2 NO'
              case '8':
                return 'Gramatge, gruix i ample de totes les bobines i 1 anàlisi sencer de cada 4 bobines'
              default:
                return '-'
            }
          })()}
        </div>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.ReviewFrequence !== valuesAfter?.ReviewFrequence
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.ReviewFrequence
            : valuesAfter?.ReviewFrequence}
        </div>
      </div>

      {/* MOSTRA LABORATORI */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Mostra laboratori:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.LabSample !== valuesAfter?.LabSample
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.LabSample : valuesAfter?.LabSample}
        </div>
      </div>

      {/* BOBINETA CLIENT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Bobineta client:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.ClientSmallCoil !== valuesAfter?.ClientSmallCoil
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.ClientSmallCoil
            : valuesAfter?.ClientSmallCoil}
        </div>
      </div>

      {/* ESPECIFICACIONS BOBINA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Especificacions bobina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.ClientNotes !== valuesAfter?.ClientNotes
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.ClientNotes
            : valuesAfter?.ClientNotes}
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.FeaturesObservation !==
            valuesAfter?.FeaturesObservation
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.FeaturesObservation
            : valuesAfter?.FeaturesObservation}
        </div>
      </div>
    </div>
  )
}

FeaturesForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
