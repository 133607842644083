import PropTypes from 'prop-types'

export default function ClientForm({
  side,
  isAnswered,
  users,
  valuesBefore,
  valuesAfter
}) {
  const labelStyles = 'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const fieldStyles = 'flex items-center col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9'

  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* CODI */}
      <div className={labelStyles}>Codi:</div>
      <div className={`${fieldStyles} h-8`}>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Code !== valuesAfter?.Code
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Code : valuesAfter?.Code}
        </div>
      </div>

      {/* NOM */}
      <div className={labelStyles}>Nom:</div>
      <div className={`${fieldStyles} h-8`}>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Name !== valuesAfter?.Name
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Name : valuesAfter?.Name}
        </div>
      </div>

      {/* ETIQUETES */}
      <div className={labelStyles}>Etiquetes:</div>
      <div className={`${fieldStyles} h-8`}>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Tag !== valuesAfter?.Tag
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.Tag === 0
              ? ''
              : valuesBefore?.Tag
            : valuesAfter?.Tag === 0
            ? ''
            : valuesAfter?.Tag}
        </div>
      </div>

      {/* POSICIÓ */}
      <div className={labelStyles}>Posició:</div>
      <div className={`${fieldStyles} h-8`}>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Position !== valuesAfter?.Position
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Position : valuesAfter?.Position}
        </div>
      </div>

      {/* ESPECIFICACIONS BOBINA */}
      <div className={labelStyles}>Especificacions bobina:</div>
      <div className={`${fieldStyles} h-8`}>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Notes !== valuesAfter?.Notes
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Notes : valuesAfter?.Notes}
        </div>
      </div>

      {/* BOBINA */}
      <div className={labelStyles}>Bobina:</div>
      <div className={`${fieldStyles} h-8`}>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Coil !== valuesAfter?.Coil
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.Coil
              ? valuesBefore?.Coil === 'SI'
                ? 'Sí'
                : 'No'
              : 'No'
            : valuesAfter?.Coil
            ? valuesAfter?.Coil === 'SI'
              ? 'Sí'
              : 'No'
            : 'No'}
        </div>
      </div>

      {/* MANAGER */}
      <div className={labelStyles}>Responsables:</div>
      <div className={`${fieldStyles} min-h-8`}>
        <div
          className={`flex items-center w-full min-h-8 px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            JSON.stringify(valuesBefore?.Manager) !==
            JSON.stringify(valuesAfter?.Manager)
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.Manager?.length > 0
              ? valuesBefore?.Manager?.map((managerId, index, array) => {
                  const manager = users.find(
                    (user) => user.Id === Number(managerId)
                  )
                  const isLastItem = index === array.length - 1

                  return manager
                    ? !isLastItem
                      ? manager.Name + ', '
                      : manager.Name
                    : ''
                })
              : ''
            : valuesAfter?.Manager?.length > 0
            ? valuesAfter?.Manager?.map((managerId, index, array) => {
                const manager = users.find(
                  (user) => user.Id === Number(managerId)
                )
                const isLastItem = index === array.length - 1

                return manager
                  ? !isLastItem
                    ? manager.Name + ', '
                    : manager.Name
                  : ''
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

ClientForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  users: PropTypes.array,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
