// Fitxa tècnica Teixiduria - Finestra modal del botó 'Posada en Marxa'

import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import CloseMenuIcon from 'components/icons/CloseMenuIcon'
import Logo from 'components/ui/Logo/Logo'
// Modal context
import { ModalContext } from 'contexts/ModalContext'

const currentdate = new Date()
const datetime =
  String(currentdate.getDate()).padStart(2, '0') +
  '/' +
  String(currentdate.getMonth() + 1).padStart(2, '0') +
  '/' +
  currentdate.getFullYear() +
  '  -  ' +
  String(currentdate.getHours()).padStart(2, '0') +
  ':' +
  String(currentdate.getMinutes()).padStart(2, '0') +
  'h'

  // centrar ventana modal
const modalStyles={
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  }

function funcOpenWindow() {
    const myWindow = window.open("/posadamarxa", "PrnLaboratori","toolbar=no,scrollbars=0,resizable=0,top=90,left=200,width=900,height=1150")
    myWindow.opener.close()
}

function printModal(status) {
    // alert('printModal status: ',status)
    console.log('status es:', status)
    if (status)
        window.print();
}

export default function FTTeixiduriaPrintPos({    
    fieldsPos,
    isOpenPos
    })   
  {

    // cuando useEffect fields, grabar-los en Store
    const [storePrintFieldsPos, setStorePrintFieldsPos] = useState({
        semi: '',
        mach: '',
        user: ''
    })
    
    useEffect(() => {
        console.log('en FTTeixiduriaPrintPos.js ...el useEffect de fieldsPos... : ', fieldsPos)

        fieldsPos && 
        
        setStorePrintFieldsPos({
            semi: fieldsPos.SemiFinished,
            mach: fieldsPos.Machine,
            user: fieldsPos.DetectedUserName
        })
        localStorage.setItem('localPrintFieldsPos', JSON.stringify(fieldsPos))
    }, [fieldsPos]) 

    // console.log('dentro del export isOpenPos vale: ',isOpenPos)
    const { handleModal, modal } = useContext(ModalContext)

    if (!modal) {
        return null
    } else {
        return (
        <article>
            <div
            // fixed
            className="inset-0 z-10 overflow-y-auto overflow-x-auto w-full"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            >
            <div className="w-full flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity"
                aria-hidden="true"                    
                ></div>
    
                <span
                className="sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
                </span>
    
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:align-middle xs:max-w-lg w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 xs:max-w-lg w-full">

                        <header className='flex justify-between bg-blue-200 px-3 color-red-200'>                         
                            <p className='text-lg mt-2'>IMPRIMIR</p>
                            {/* <CloseMenuIcon width={40}                                   
                            />  */}
                        </header>                        
                        <div className='flex flex-col'>
                            <p>&nbsp;</p>
                            <div>
                                <div className='w-100 border border-grey-400'>
                                    {console.log('fieldsPos en FTTeixiduriaPrintPos modal...: ',fieldsPos)}                                
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='border border-grey-800 w-40 h-14'>
                                                <Logo classes="w-15 h-8 object-contain" link="/" color="black" />                                                            
                                            </td>
                                            <td className='col-span-4 text-2xl border border-grey-800 flex justify-center w-11/12 h-14 py-3'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                            
                                                POSADA EN MARXA
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;              
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                
                                            </td>
                                            <td className='text-xs text-left w-40 h-14'>Format-IATF-013-CA &nbsp;&nbsp; (11.03.22)</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>&nbsp;</p>
                                <div className='w-100 border border-grey-400'>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>SEMI: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                        
                                                <span className='text-gray-600 text-base font-normal italic'> {fieldsPos.SemiFinished}</span>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>MÀQ.: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                        
                                                <span className='text-gray-600 text-base font-normal italic'> {fieldsPos.Machine}</span>
                                            </td>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>DATA: &nbsp;                                         
                                                <span className='text-gray-600 text-base font-normal italic'> {datetime}</span>
                                            </td>                                                                                
                                        </tr>
                                        <tr>
                                            <td className='text-blue-700 text-base font-bold px-1'>Nom.: {fieldsPos.DetectedUserName}</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>                                              
                                        </tr> 
                                        </tbody>                                           
                                    </table>
                                </div>
                                <p>&nbsp;</p>
                                </div>
                                <table >
                                    <caption className='text-right text-base bg-blue-200 px-28 pt-1 font-bold'>MESES</caption>
                                    <thead className='text-base bg-blue-200 px-3'>
                                        <tr>
                                            <th>TAREAS</th>
                                            <th className='text-center'>1</th>
                                            <th className='text-center'>5</th>
                                            <th className='text-center'>9</th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='grid-row border border-gray-300 px-5'>
                                            {/* <td className='col-span-2 border border-gray-300 px-0 py-5'>Comprobar estat fileta</td> */}
                                            <td className='col-span-2 border border-gray-300 px-0 py-5'>Comprobar estado de la fileta.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>
                                        <tr className='border border-gray-300 bg-gray-200'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Comprobar funcionament i gir ventiladors</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Comprobar funcionamiento y giro de los ventiladores.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>          
                                        <tr className='border border-gray-300'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Verificar funcionament bufadors</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Verificar que los sopladores funcionen.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>                                                                                                             
                                        <tr className='border border-gray-300 bg-gray-200'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Control engreixat màquina</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Controlar el engrase de la maquina: boquillas y caudal.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>  
                                        <tr className='border border-gray-300'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Netaja calandra i plat</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Limpiar con un trapo calandra y plato por dentro</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>
                                        <tr className='border border-gray-300 bg-gray-200'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Comprobar llum central</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Comprobar funcionamiento de la luz central.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>                                                                                 
                                    </tbody>
                                    <tfoot className='text-base border border-gray-300 bg-blue-200 px-3'>                                                                  
                                        <tr>
                                            <th className='col-span-1 py-5'>Observaciones: </th>
                                            <td className='border border-gray-300 px-1 text-blue-200'>..............</td>
                                            <td className='border border-gray-300 px-1 text-blue-200'>..............</td>
                                            <td className='border border-gray-300 px-1 text-blue-200'>..............</td>
                                        </tr>                                    
                                    </tfoot>                                                            
                                </table>
                                <p>&nbsp;</p>                            
                                <div className='flex justify-center'>
                                    <div className='text-lg'>Aprovat per:</div>
                                </div>
                                <div className='flex justify-end my-5'>
                                    <a target="_blank"><button className='print:hidden text-lg bg-green-300 mr-3 py-1 px-3' 

                                            // onClick={() => printModal(true)}
                                            
                                            onClick={()=> funcOpenWindow()}
                                            data-dismiss="modal"
                                            
                                        >Imprimir</button></a>

                                    <button className='print:hidden text-lg bg-red-300 ml-3 py-1 px-3'                                                           
                                            onClick={() => handleModal()}                                                 
                                        >Cancelar</button>               
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>                
            </div>                
        </article>
        // ,
        // document.querySelector('#modal')
        )
        } 
  }

FTTeixiduriaPrintPos.propTypes = {
  fieldsPos: PropTypes.object,
  isOpenPos: true 
}
