import PropTypes from 'prop-types'
import { FaCheck, FaRegClock, FaTimes } from 'react-icons/fa'

export default function ListItem({ item, handleClick }) {
  const formatDate = (dateString) => {
    const utcDate = new Date(dateString)
    const userTimeZoneOffset = new Date().getTimezoneOffset()
    const userLocalTime = new Date(
      utcDate.getTime() - userTimeZoneOffset * 60000
    )

    // Obtener los componentes de la fecha
    const day = ('0' + userLocalTime.getDate()).slice(-2)
    const month = ('0' + (userLocalTime.getMonth() + 1)).slice(-2)
    const year = userLocalTime.getFullYear()
    const hour = ('0' + userLocalTime.getHours()).slice(-2)
    const minute = ('0' + userLocalTime.getMinutes()).slice(-2)

    // Construir la cadena de fecha formateada
    const formattedLocalTime = `${day}/${month}/${year}, ${hour}:${minute} h`

    return formattedLocalTime
  }

  return (
    <tr className="cursor-pointer hover:bg-gray-100 h-7" onClick={handleClick}>
      {/* REFERENCIA */}
      <td className="px-3 py-3 whitespace-nowrap">
        <div className="text-sm text-gray-900 font-ms-semi">
          #{item.RequestId}
        </div>
      </td>

      {/* MOTIU */}
      <td className="px-3 whitespace-nowrap">
        <div className="text-sm text-gray-900 font-ms-semi">{item.Reason}</div>
      </td>

      {/* CREAT */}
      <td className="px-3 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {formatDate(item.CreatedAt)}
        </div>
      </td>

      {/* ACTUALITZAT */}
      <td className="px-3 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {item.UpdatedAt !== null && item.UpdatedAt !== ''
            ? formatDate(item.UpdatedAt)
            : '-'}
        </div>
      </td>

      {/* SOL·LICITANT */}
      <td className="px-3 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {item?.Requester?.Name.toUpperCase() || '-'}
        </div>
      </td>

      {/* REVISOR */}
      <td className="px-3 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {item?.Responder?.Name.toUpperCase() || '-'}
        </div>
      </td>

      {/* ESTAT */}
      <td className="px-3 whitespace-nowrap">
        <div className="flex items-end justify-start text-sm uppercase font-ms-semi">
          {item.State === 'P' && (
            <span className="inline-flex items-center text-amber-500">
              <FaRegClock className="xl:mr-2" size={20} />
              Pendent
            </span>
          )}
          {item.State === 'A' && (
            <span className="inline-flex items-center text-green-500">
              <FaCheck className="xl:mr-2" size={20} />
              Acceptada
            </span>
          )}
          {item.State === 'D' && (
            <span className="inline-flex items-center text-red-500">
              <FaTimes className="xl:mr-2" size={20} />
              Denegada
            </span>
          )}
        </div>
      </td>
    </tr>
  )
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}
