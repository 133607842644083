import BlockVersion3 from 'components/FTAcabatsRequest/Request/Block/BlockCompare'
import BlockVersion2 from 'components/FTAcabatsRequest/Request/Block/BlockCreate'
import BlockVersion1 from 'components/FTAcabatsRequest/Request/Block/BlockView'
import RamsVersion3 from 'components/FTAcabatsRequest/Request/Rams/RamsCompare'
import RamsVersion2 from 'components/FTAcabatsRequest/Request/Rams/RamsCreate'
import RamsVersion1 from 'components/FTAcabatsRequest/Request/Rams/RamsView'
import RamsVersion4 from 'components/FTAcabatsRequest/RequestRam/Rams/RamsView'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { CgSpinner } from 'react-icons/cg'
import { FaCheck, FaRegClock, FaTimes } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useLocation } from 'wouter'
import * as Yup from 'yup'

export default function FTAcabatsRequest({ params }) {
  // Refs
  const submitActionRef = useRef()

  // Context:
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const [ramTab, setRamTab] = useState(1)

  // Fetch
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()

  // State:
  const [getIsOld, setIsOld] = useState(false)
  const [getIsAnswered, setIsAnswered] = useState(false)
  const [canRespond, setCanRespond] = useState(false)
  const [users, setUsers] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // Vars
  const isViewScreen = params.type === 'view'
  const [getRequestFieldsState, setRequestFieldsState] = useState({
    RequestId: 0,
    Article: '',
    State: '',
    Reason: '',
    ResponderComment: '',
    UpdatedAt: '',
    RequesterId: null,
    ResponderId: null,
    RequestArticle: null,
    RequestRams: null
  })

  // Formik validation
  const validationSchema1 = Yup.object().shape({
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    )
  })

  const validationSchema2 = Yup.object().shape({
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    ),
    ResponderComment: Yup.string().required(
      '(*No es pot respondre a una sol·licitud sense especificar un comentari.)'
    )
  })

  // ###########################################

  // Get api calls
  useEffect(() => {
    const title = {
      name: `Sol·licitud`,
      buttons: [
        {
          name: 'Tornar',
          link: isViewScreen
            ? `/ft-acabats/requests/${params.articleCode}`
            : `/ft-acabats/${params.articleCode}`
        }
      ]
    }
    setTitle(title)

    const apiCallGetUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP!'
    }
    setApiCallGetUsers(apiCallGetUsers)
  }, [])

  // Get users api call responde
  useEffect(() => {
    if (getApiCallGetUsers.data) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get user api call response
  useEffect(() => {
    if (getApiCallGetUser.data && users) {
      setCurrentUser(getApiCallGetUser.data)

      const apiCallGet = {
        url: isViewScreen
          ? `technicalsheet/request/${params.idRequest}`
          : `technicalsheet/${params.articleCode}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  // Get request api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser) {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))

      if (isViewScreen) {
        // Check if is old request version
        if (apiData?.RequestArticle.RequestArticleId === 0) {
          setIsOld(true)
        }

        // Check if current user can manage requests
        const allow = checkPermissions(apiData)
        setCanRespond(allow)

        // Create request object
        setRequestFieldsState({
          RequestId: apiData?.RequestId ?? 0,
          Article: apiData?.Article ?? '',
          State: apiData?.State ?? 'P',
          Reason: apiData?.Reason ?? '',
          ResponderComment: apiData?.ResponderComment ?? '',
          UpdatedAt: apiData?.UpdatedAt ?? '',
          RequesterId: apiData?.RequesterId ?? null,
          ResponderId: apiData?.ResponderId ?? null,
          RequestArticle: apiData?.RequestArticle ?? null,
          RequestRams: apiData?.RequestRams ?? null
        })

        // Set request state
        setIsAnswered(apiData?.State === 'A')
      } else {
        const requestArticle = buildRequestArticle(apiData)
        const requestRams = buildRequestRams(apiData)

        setRequestFieldsState({
          RequestId: 0,
          Article: apiData?.Article ?? '',
          State: 'P',
          Reason: '',
          ResponderComment: '',
          UpdatedAt: '',
          RequesterId: user.Id ?? null,
          ResponderId: null,
          RequestArticle: requestArticle,
          RequestRams: requestRams
        })
      }
    }
  }, [getApiCallGet.data, currentUser])

  const checkPermissions = (apiData) => {
    let allow = false

    const requestArticleClientManagerArray =
      apiData?.RequestArticle?.ClientManager === '' ||
      apiData?.RequestArticle?.ClientManager === null
        ? []
        : apiData?.RequestArticle?.ClientManager?.replace(' ', '').split(',')

    apiData.RequestArticle.ClientManager = requestArticleClientManagerArray

    if (apiData?.RequestArticle?.RequestArticleId !== 0) {
      const historyArticleClientManagerArray =
        apiData?.RequestArticle?.HistoryArticle?.ClientManager === '' ||
        apiData?.RequestArticle?.HistoryArticle?.ClientManager === null
          ? []
          : apiData?.RequestArticle?.HistoryArticle?.ClientManager?.replace(
              ' ',
              ''
            ).split(',')

      apiData.RequestArticle.HistoryArticle.ClientManager =
        historyArticleClientManagerArray
    }

    const userRol = user.Role.Id
    let articleEnterprice = null
    const canRespondCetriko = currentUser?.CanRespondFinishedRequestForCetriko
    const canRespondCervera = currentUser?.CanRespondFinishedRequestForCervera

    const isC = apiData.RequestArticle?.HistoryArticle?.IsFromCetriko
    const isQ = apiData.RequestArticle?.HistoryArticle?.IsFromCervera

    if (isC && !isQ) {
      articleEnterprice = 'C'
    } else if (!isC && isQ) {
      articleEnterprice = 'Q'
    } else {
      articleEnterprice = 'C + Q'
    }

    if (userRol === 1) {
      if (
        articleEnterprice === 'C + Q' &&
        (canRespondCetriko || canRespondCervera)
      ) {
        allow = true
      } else if (articleEnterprice === 'C' && canRespondCetriko) {
        allow = true
      } else if (articleEnterprice === 'Q' && canRespondCervera) {
        allow = true
      } else {
        allow = false
      }
    } else {
      allow = false
    }

    if (!allow) {
      if (requestArticleClientManagerArray.includes(user.Id.toString())) {
        allow = true
      }
    }

    return allow
  }

  const buildRequestArticle = (apiData) => {
    const requestArticle = {
      RequestArticleId: 0,
      ArticleId: apiData?.Id,
      IsFromCetriko: apiData?.IsFromCetriko,
      IsFromCervera: apiData?.IsFromCervera,
      // --------------------------------------------------------------------------------------
      // Description
      Article: apiData?.Article ?? '',
      ArticleDescription: apiData?.ArticleDescription ?? '',
      Status: apiData?.Status ?? '',
      Width: apiData?.Width ?? '',
      SemiFinished: apiData?.SemiFinished ?? '',
      SemiFinishedDescription: apiData?.SemiFinishedDescription ?? '',
      Client: apiData?.Client ?? '',
      Composition: apiData?.Composition ?? '',
      IsDryer: apiData?.IsDryer ?? false,
      ClientManager:
        apiData?.ClientManager && apiData?.ClientManager !== ''
          ? apiData?.ClientManager?.replace(' ', '').split(',')
          : [],
      EditionNumber: apiData?.EditionNumber ?? 1,
      IsOperatorHidden: apiData?.IsOperatorHidden ?? '',
      // --------------------------------------------------------------------------------------
      // Features
      OutputWidth: apiData?.OutputWidth ?? '',
      Performance: apiData?.Performance ?? '',
      PerformanceTolerance: apiData?.PerformanceTolerance ?? '',
      Weight: apiData?.Weight ?? '',
      WeightTolerance: apiData?.WeightTolerance ?? '',
      LinearWeight: apiData?.LinearWeight ?? '',
      Meters: apiData?.Meters ?? '',
      MeterTolerance: apiData?.MeterTolerance ?? '',
      IsAutomatedCut: apiData?.IsAutomatedCut ?? false,
      IsReverseCoil: apiData?.IsReverseCoil ?? false,
      Seams: apiData?.Seams ?? '',
      SeamsObservation: apiData?.SeamsObservation ?? '',
      IsSeamsMarked: apiData?.IsSeamsMarked ?? false,
      ReviewFrequence: apiData?.ReviewFrequence ?? '',
      ReviewFrequenceId: apiData?.ReviewFrequenceId ?? '',
      LabSample: apiData?.LabSample ?? '',
      ClientSmallCoil: apiData?.ClientSmallCoil ?? '',
      ClientNotes: apiData?.ClientNotes ?? '',
      FeaturesObservation: apiData?.FeaturesObservation ?? '',
      // --------------------------------------------------------------------------------------
      // Open Machine
      Folding: apiData?.Folding ?? '',
      OpenWith: apiData?.OpenWith ?? '',
      IsOpenedReverse: apiData?.IsOpenedReverse ?? false,
      Follower: apiData?.Follower ?? '',
      ObservationMachine: apiData?.ObservationMachine ?? '',
      SewingType: apiData?.SewingType ?? '',
      // --------------------------------------------------------------------------------------
      // Ultrasonic Settings
      PowerUltrasound: apiData?.PowerUltrasound ?? '',
      SpeedUltrasound: apiData?.SpeedUltrasound ?? '',
      PressureUltrasound: apiData?.PressureUltrasound ?? '',
      PowerUltrasoundTolerance: apiData?.PowerUltrasoundTolerance ?? '',
      SpeedUltrasoundTolerance: apiData?.SpeedUltrasoundTolerance ?? '',
      PressureUltrasoundTolerance: apiData?.PressureUltrasoundTolerance ?? '',
      // --------------------------------------------------------------------------------------
      // Edges
      Glue: apiData?.Glue ?? '',
      GlueProportion: apiData?.GlueProportion ?? '',
      GlueColor: apiData?.GlueColor ?? '',
      GlueMachine: apiData?.GlueMachine ?? '',
      HasToCutEdge: apiData?.HasToCutEdge ?? false,
      Cuters: apiData?.Cuters ?? '',
      AlignedSides: apiData?.AlignedSides ?? '',
      EdgeObservation: apiData?.EdgeObservation ?? '',
      // --------------------------------------------------------------------------------------
      // Finished Foulard
      FoulardName: apiData?.FoulardName ?? '',
      FoulardFormula: apiData?.FoulardFormula ?? '',
      FoulardRecipeNumber: apiData?.FoulardRecipeNumber ?? '',
      FoulardObservation: apiData?.FoulardObservation ?? '',
      IsDosificated: apiData?.IsDosificated ?? false,
      // --------------------------------------------------------------------------------------
      // Controls
      ControlStyle: apiData?.ControlStyle ?? '',
      VMT: apiData?.VMT ?? '',
      MAN: apiData?.MAN ?? '',
      OMT: apiData?.OMT ?? '',
      AUT: apiData?.AUT ?? '',
      ControlTemperature: apiData?.ControlTemperature ?? '',
      ControlTime: apiData?.ControlTime ?? '',
      ControlObservation: apiData?.ControlObservation ?? '',
      // --------------------------------------------------------------------------------------
      // Packaging
      TubeSize: apiData?.TubeSize ?? '',
      TubName: apiData?.TubName ?? '',
      HasDoubleSidedTape: apiData?.HasDoubleSidedTape ?? false,
      FinalCoilFixation: apiData?.FinalCoilFixation ?? '',
      PlasticType: apiData?.PlasticType ?? '',
      IsPolyethyleneFilm: apiData?.IsPolyethyleneFilm ?? false,
      HasCorners: apiData?.HasCorners ?? false,
      PrecinctColor: apiData?.PrecinctColor ?? '',
      TagNumber: apiData?.TagNumber ?? '',
      TagDescription: apiData?.TagDescription ?? '',
      Others: apiData?.Others ?? '',
      PackagingObservation: apiData?.PackagingObservation ?? '',
      // --------------------------------------------------------------------------------------
      // History Article
      HistoryArticle: {
        HistoryArticleId: 0,
        ArticleId: apiData?.Id ?? 0,
        IsFromCetriko: apiData?.IsFromCetriko,
        IsFromCervera: apiData?.IsFromCervera,
        // ------------------------------------------------------------------------------------
        // Description
        Article: apiData?.Article ?? '',
        ArticleDescription: apiData?.ArticleDescription ?? '',
        Status: apiData?.Status ?? '',
        Width: apiData?.Width ?? '',
        SemiFinished: apiData?.SemiFinished ?? '',
        SemiFinishedDescription: apiData?.SemiFinishedDescription ?? '',
        Client: apiData?.Client ?? '',
        Composition: apiData?.Composition ?? '',
        IsDryer: apiData?.IsDryer ?? false,
        ClientManager:
          apiData?.ClientManager && apiData?.ClientManager !== ''
            ? apiData?.ClientManager.replace(' ', '').split(',')
            : [],
        EditionNumber: apiData?.EditionNumber ?? 0,
        IsOperatorHidden: apiData?.IsOperatorHidden ?? false,
        // ------------------------------------------------------------------------------------
        // Features
        OutputWidth: apiData?.OutputWidth ?? '',
        Performance: apiData?.Performance ?? '',
        PerformanceTolerance: apiData?.PerformanceTolerance ?? '',
        Weight: apiData?.Weight ?? '',
        WeightTolerance: apiData?.WeightTolerance ?? '',
        LinearWeight: apiData?.LinearWeight ?? '',
        Meters: apiData?.Meters ?? '',
        MeterTolerance: apiData?.MeterTolerance ?? '',
        IsAutomatedCut: apiData?.IsAutomatedCut ?? false,
        IsReverseCoil: apiData?.IsReverseCoil ?? false,
        Seams: apiData?.Seams ?? '',
        SeamsObservation: apiData?.SeamsObservation ?? '',
        IsSeamsMarked: apiData?.IsSeamsMarked ?? false,
        ReviewFrequence: apiData?.ReviewFrequence ?? '',
        ReviewFrequenceId: apiData?.ReviewFrequenceId ?? '',
        LabSample: apiData?.LabSample ?? '',
        ClientSmallCoil: apiData?.ClientSmallCoil ?? '',
        ClientNotes: apiData?.ClientNotes ?? '',
        FeaturesObservation: apiData?.FeaturesObservation ?? '',
        // ------------------------------------------------------------------------------------
        // Open Machine
        Folding: apiData?.Folding ?? '',
        OpenWith: apiData?.OpenWith ?? '',
        IsOpenedReverse: apiData?.IsOpenedReverse ?? false,
        Follower: apiData?.Follower ?? '',
        ObservationMachine: apiData?.ObservationMachine ?? '',
        SewingType: apiData?.SewingType ?? '',
        // ------------------------------------------------------------------------------------
        // Ultrasonic Settings
        PowerUltrasound: apiData?.PowerUltrasound ?? '',
        SpeedUltrasound: apiData?.SpeedUltrasound ?? '',
        PressureUltrasound: apiData?.PressureUltrasound ?? '',
        PowerUltrasoundTolerance: apiData?.PowerUltrasoundTolerance ?? '',
        SpeedUltrasoundTolerance: apiData?.SpeedUltrasoundTolerance ?? '',
        PressureUltrasoundTolerance: apiData?.PressureUltrasoundTolerance ?? '',
        // ------------------------------------------------------------------------------------
        // Edges
        Glue: apiData?.Glue ?? '',
        GlueProportion: apiData?.GlueProportion ?? '',
        GlueColor: apiData?.GlueColor ?? '',
        GlueMachine: apiData?.GlueMachine ?? '',
        HasToCutEdge: apiData?.HasToCutEdge ?? false,
        Cuters: apiData?.Cuters ?? '',
        AlignedSides: apiData?.AlignedSides ?? '',
        EdgeObservation: apiData?.EdgeObservation ?? '',
        // ------------------------------------------------------------------------------------
        // Finished Foulard
        FoulardName: apiData?.FoulardName ?? '',
        FoulardFormula: apiData?.FoulardFormula ?? '',
        FoulardRecipeNumber: apiData?.FoulardRecipeNumber ?? '',
        FoulardObservation: apiData?.FoulardObservation ?? '',
        IsDosificated: apiData?.IsDosificated ?? false,
        // ------------------------------------------------------------------------------------
        // Controls
        ControlStyle: apiData?.ControlStyle ?? '',
        VMT: apiData?.VMT ?? '',
        MAN: apiData?.MAN ?? '',
        OMT: apiData?.OMT ?? '',
        AUT: apiData?.AUT ?? '',
        ControlTemperature: apiData?.ControlTemperature ?? '',
        ControlTime: apiData?.ControlTime ?? '',
        ControlObservation: apiData?.ControlObservation ?? '',
        // ------------------------------------------------------------------------------------
        // Packaging
        TubeSize: apiData?.TubeSize ?? '',
        TubName: apiData?.TubName ?? '',
        HasDoubleSidedTape: apiData?.HasDoubleSidedTape ?? false,
        FinalCoilFixation: apiData?.FinalCoilFixation ?? '',
        PlasticType: apiData?.PlasticType ?? '',
        IsPolyethyleneFilm: apiData?.IsPolyethyleneFilm ?? false,
        HasCorners: apiData?.PaHasCornersckagingObservation ?? false,
        PrecinctColor: apiData?.PrecinctColor ?? '',
        TagNumber: apiData?.TagNumber ?? '',
        TagDescription: apiData?.TagDescription ?? '',
        Others: apiData?.Others ?? '',
        PackagingObservation: apiData?.PackagingObservation ?? ''
      }
    }

    return requestArticle
  }

  const buildRequestRams = (apiData) => {
    const requestRams = []

    for (const ram of apiData?.TechnicalSheetRams) {
      requestRams.push({
        RequestRamId: 0,
        RamId: ram?.Id ?? 0,
        RamNumber: ram?.RamNumber ?? 0,
        Article: ram?.Article ?? '',
        // --------------------------------------------------------------------------------------
        // Ample cadena
        Width0: ram?.Width0 ?? '',
        Width1: ram?.Width1 ?? '',
        Width2: ram?.Width2 ?? '',
        Width3: ram?.Width3 ?? '',
        Width4: ram?.Width4 ?? '',
        Width5: ram?.Width5 ?? '',
        Width6: ram?.Width6 ?? '',
        Width7: ram?.Width7 ?? '',
        // --------------------------------------------------------------------------------------
        // Temperatura
        Temperature1: ram?.Temperature1 ?? '',
        Temperature2: ram?.Temperature2 ?? '',
        Temperature3: ram?.Temperature3 ?? '',
        Temperature4: ram?.Temperature4 ?? '',
        Temperature5: ram?.Temperature5 ?? '',
        Temperature6: ram?.Temperature6 ?? '',
        // --------------------------------------------------------------------------------------
        // Ventilació
        BottomVentilation1: ram?.BottomVentilation1 ?? '',
        BottomVentilation2: ram?.BottomVentilation2 ?? '',
        BottomVentilation3: ram?.BottomVentilation3 ?? '',
        BottomVentilation4: ram?.BottomVentilation4 ?? '',
        BottomVentilationPercentage: ram?.BottomVentilationPercentage ?? '',
        TopVentilation1: ram?.TopVentilation1 ?? '',
        TopVentilation2: ram?.TopVentilation2 ?? '',
        TopVentilation3: ram?.TopVentilation3 ?? '',
        TopVentilation4: ram?.TopVentilation4 ?? '',
        TopVentilationPercentage: ram?.TopVentilationPercentage ?? '',
        // --------------------------------------------------------------------------------------
        // Sobrealimentació
        OverFeeding: ram?.OverFeeding ?? '',
        // --------------------------------------------------------------------------------------
        // Sortida
        Tension: ram?.Tension ?? '',
        TensionTolerance: ram?.TensionTolerance ?? '',
        Potential: ram?.Potential ?? '',
        IsRefrigeration: ram?.IsRefrigeration ?? false,
        PotentialTolerance: ram?.PotentialTolerance ?? '',
        // --------------------------------------------------------------------------------------
        // Altres
        Brushes: ram?.Brushes ?? '',
        BrushesTolerance: ram?.BrushesTolerance ?? '',
        FoulardTension: ram?.FoulardTension ?? '',
        FoulardTensionTolerance: ram?.FoulardTensionTolerance ?? '',
        HasVaporizer: ram?.HasVaporizer ?? false,
        HasRag: ram?.HasRag ?? false,
        GlueEnhancer: ram?.GlueEnhancer ?? '',
        BrushEnhancer: ram?.BrushEnhancer ?? '',
        GlueEnhancerTolerance: ram?.GlueEnhancerTolerance ?? '',
        BrushEnhancerTolerance: ram?.BrushEnhancerTolerance ?? '',
        // --------------------------------------------------------------------------------------
        // Velocitat
        MinSpeed: ram?.MinSpeed ?? '',
        MaxSpeed: ram?.MaxSpeed ?? '',
        // --------------------------------------------------------------------------------------
        // Extracció
        ExtractionType: ram?.ExtractionType ?? 1,
        Extraction: ram?.Extraction ?? '',
        PlotAddress: ram?.PlotAddress ?? '',
        PlotAddresstolerance: ram?.PlotAddresstolerance ?? '',
        // --------------------------------------------------------------------------------------
        // Observacions
        Observation: ram?.Observation ?? '',
        // --------------------------------------------------------------------------------------
        // History Ram
        HistoryRam: {
          HistoryRamId: 0,
          RamId: ram?.Id ?? 0,
          RamNumber: ram?.RamNumber ?? 0,
          Article: ram?.Article ?? '',
          // ------------------------------------------------------------------------------------
          // Ample cadena
          Width0: ram?.Width0 ?? '',
          Width1: ram?.Width1 ?? '',
          Width2: ram?.Width2 ?? '',
          Width3: ram?.Width3 ?? '',
          Width4: ram?.Width4 ?? '',
          Width5: ram?.Width5 ?? '',
          Width6: ram?.Width6 ?? '',
          Width7: ram?.Width7 ?? '',
          // ------------------------------------------------------------------------------------
          // Temperatura
          Temperature1: ram?.Temperature1 ?? '',
          Temperature2: ram?.Temperature2 ?? '',
          Temperature3: ram?.Temperature3 ?? '',
          Temperature4: ram?.Temperature4 ?? '',
          Temperature5: ram?.Temperature5 ?? '',
          Temperature6: ram?.Temperature6 ?? '',
          // ------------------------------------------------------------------------------------
          // Ventilació
          BottomVentilation1: ram?.BottomVentilation1 ?? '',
          BottomVentilation2: ram?.BottomVentilation2 ?? '',
          BottomVentilation3: ram?.BottomVentilation3 ?? '',
          BottomVentilation4: ram?.BottomVentilation4 ?? '',
          BottomVentilationPercentage: ram?.BottomVentilationPercentage ?? '',
          TopVentilation1: ram?.TopVentilation1 ?? '',
          TopVentilation2: ram?.TopVentilation2 ?? '',
          TopVentilation3: ram?.TopVentilation3 ?? '',
          TopVentilation4: ram?.TopVentilation4 ?? '',
          TopVentilationPercentage: ram?.TopVentilationPercentage ?? '',
          // ------------------------------------------------------------------------------------
          // Sobrealimentació
          OverFeeding: ram?.OverFeeding ?? '',
          // ------------------------------------------------------------------------------------
          // Sortida
          Tension: ram?.Tension ?? '',
          TensionTolerance: ram?.TensionTolerance ?? '',
          Potential: ram?.Potential ?? '',
          IsRefrigeration: ram?.IsRefrigeration ?? false,
          PotentialTolerance: ram?.PotentialTolerance ?? '',
          // ------------------------------------------------------------------------------------
          // Altres
          Brushes: ram?.Brushes ?? '',
          BrushesTolerance: ram?.BrushesTolerance ?? '',
          FoulardTension: ram?.FoulardTension ?? '',
          FoulardTensionTolerance: ram?.FoulardTensionTolerance ?? '',
          HasVaporizer: ram?.HasVaporizer ?? false,
          HasRag: ram?.HasRag ?? false,
          GlueEnhancer: ram?.GlueEnhancer ?? '',
          BrushEnhancer: ram?.BrushEnhancer ?? '',
          GlueEnhancerTolerance: ram?.GlueEnhancerTolerance ?? '',
          BrushEnhancerTolerance: ram?.BrushEnhancerTolerance ?? '',
          // ------------------------------------------------------------------------------------
          // Velocitat
          MinSpeed: ram?.MinSpeed ?? '',
          MaxSpeed: ram?.MaxSpeed ?? '',
          // ------------------------------------------------------------------------------------
          // Extracció
          ExtractionType: ram?.ExtractionType ?? 1,
          Extraction: ram?.Extraction ?? '',
          PlotAddress: ram?.PlotAddress ?? '',
          PlotAddresstolerance: ram?.PlotAddresstolerance ?? '',
          // ------------------------------------------------------------------------------------
          // Observacions
          Observation: ram?.Observation ?? ''
        }
      })
    }

    return requestRams
  }

  // Post
  const handleSubmit = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))
    preparedValues.RequestArticle.ClientManager =
      preparedValues.RequestArticle.ClientManager.toString()
    preparedValues.RequestArticle.HistoryArticle.ClientManager =
      preparedValues.RequestArticle.HistoryArticle.ClientManager.toString()

    const apiCallPost = {
      url: 'technicalsheet/request',
      method: 'POST',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Put
  const handleUpdate = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))
    preparedValues.ResponderId = user.Id

    if (!getIsOld) {
      preparedValues.RequestArticle.ClientManager =
        preparedValues.RequestArticle.ClientManager.toString()
      preparedValues.RequestArticle.HistoryArticle.ClientManager =
        preparedValues.RequestArticle.ClientManager.toString()
    }

    const apiCallPost = {
      url: `technicalsheet/request-action/${
        submitActionRef.current === 'A' ? 'approve' : 'deny'
      }`,
      method: 'PUT',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Get post/put response
  useEffect(() => {
    if (getApiCallPost.data) {
      setLocation(
        isViewScreen
          ? `/ft-acabats/requests/${params.articleCode}`
          : `/ft-acabats/${params.articleCode}`
      )
    }
  }, [getApiCallPost.data])

  return (
    <>
      {getIsOld ||
      (!getIsOld &&
        getRequestFieldsState.RequestArticle !== null &&
        getRequestFieldsState.RequestRams !== null &&
        users) ? (
        getIsOld ? (
          <Formik
            enableReinitialize={true}
            validationSchema={
              isViewScreen ? validationSchema2 : validationSchema1
            }
            initialValues={getRequestFieldsState}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true)

              // if (isViewScreen) {
              //   handleUpdate(values)
              // } else {
              //   handleSubmit(values)
              // }
            }}
          >
            {({
              values,
              errors,
              touched,
              props,
              handleBlur,
              handleChange,
              submitForm,
              isSubmitting
            }) => (
              <Form className="relative grid grid-flow-row grid-cols-12 p-4 gap-y-2 gap-x-4 auto-rows-max">
                {/* MOTIU */}
                <div className="flex flex-col col-span-12 bg-white border-2 border-blue-200 rounded shadow-md">
                  <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                    MOTIU
                  </div>
                  <div className="p-3">
                    {isViewScreen ? (
                      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                          {values?.Reason}
                        </div>
                      </div>
                    ) : (
                      <>
                        <Field
                          type="text"
                          name="Reason"
                          className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                          focus:outline-none focus:shadow-outline ${
                            errors.Reason
                              ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                              : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                          }`}
                        />
                        <ErrorMessage
                          name="Reason"
                          render={(message) => (
                            <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                              {message}
                            </span>
                          )}
                        />
                      </>
                    )}
                  </div>
                </div>

                {/* COMMENT */}
                <div className="flex flex-col col-span-12 bg-white border-2 border-blue-200 rounded shadow-md">
                  <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                    Comentari del sol·licitant
                  </div>
                  <div className="p-3">
                    {isViewScreen ? (
                      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                          {values?.Observation}
                        </div>
                      </div>
                    ) : (
                      <>
                        <Field
                          type="text"
                          name="Observation"
                          className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                            focus:outline-none focus:shadow-outline border-gray-300 bg-white 
                            hover:border-gray-400 focus:border-gray-400`}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="col-span-12">
                  <hr
                    className={`w-3/4 h-1 mx-auto mt-12 
                    ${
                      isViewScreen
                        ? values.State === 'P'
                          ? canRespond
                            ? 'bg-gray-100'
                            : 'bg-amber-500'
                          : values.State === 'A'
                          ? 'bg-teal-500'
                          : 'bg-red-500'
                        : 'bg-gray-100'
                    } border-0 rounded`}
                  />
                </div>

                {isViewScreen ? (
                  values.State === 'P' ? (
                    canRespond ? (
                      <>
                        <div className="flex justify-end col-span-6 my-6">
                          <button
                            title="Acceptar"
                            type="submit"
                            className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                            onClick={() => (submitActionRef.current = 'A')}
                            disabled={isSubmitting}
                          >
                            <FaCheck className="xl:mr-2" size={25} />
                            Acceptar
                          </button>
                        </div>

                        <div className="flex justify-start col-span-6 my-6">
                          <button
                            title="Denegar"
                            type="submit"
                            className={`text-red-500 bg-white hover:bg-red-500 border-2 border-red-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                            onClick={() => (submitActionRef.current = 'D')}
                            disabled={isSubmitting}
                          >
                            <FaTimes className="xl:mr-2" size={25} />
                            Denegar
                          </button>
                        </div>

                        {/* COMMENT */}
                        <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                          <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                            Comentari del revisor
                          </div>
                          <div className="p-3">
                            <Field
                              type="text"
                              name="ResponderComment"
                              className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                                errors.ResponderComment
                                  ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                                  : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                              }`}
                            />
                            <ErrorMessage
                              name="ResponderComment"
                              render={(message) => (
                                <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                                  {message}
                                </span>
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-center col-span-12 my-6">
                        <div className="cursor-default text-amber-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                          <FaRegClock className="xl:mr-2" size={25} />
                          Pendent
                        </div>
                      </div>
                    )
                  ) : values.State === 'A' ? (
                    <>
                      <div className="flex justify-center col-span-12 my-6">
                        <div className="cursor-default text-teal-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                          <FaCheck className="xl:mr-2" size={25} />
                          Acceptada
                        </div>
                      </div>

                      {/* COMMENT */}
                      <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                          Comentari del revisor
                        </div>
                        <div className="p-3">
                          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                            <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                              {values?.ResponderComment}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center col-span-12 my-6">
                        <div className="cursor-default text-red-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                          <FaTimes className="xl:mr-2" size={25} />
                          Denegada
                        </div>
                      </div>

                      {/* COMMENT */}
                      <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                          Comentari del revisor
                        </div>
                        <div className="p-3">
                          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                            <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                              {values?.ResponderComment}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <div className="flex items-start justify-center col-span-12 mt-12 mb-10 space-x-2 uppercase">
                    <button
                      title="Sol·licitar"
                      type="submit"
                      className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                      disabled={isSubmitting}
                    >
                      <MdEdit className="xl:mr-2" size={25} />
                      Sol·licitar
                    </button>
                  </div>
                )}

                <div className="col-span-12">
                  <hr
                    className={`w-3/4 h-1 mx-auto mb-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
                  />
                </div>

                <RamsVersion4
                  ramTab={values?.RequestRams[0]?.RamNumber}
                  values={values?.RequestRams[0]}
                />
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            enableReinitialize={true}
            validationSchema={
              isViewScreen ? validationSchema2 : validationSchema1
            }
            initialValues={getRequestFieldsState}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true)
              if (isViewScreen) {
                handleUpdate(values)
              } else {
                handleSubmit(values)
              }
            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              props,
              handleBlur,
              handleChange,
              submitForm,
              isSubmitting
            }) => (
              <Form className="relative grid grid-flow-row grid-cols-12 p-4 gap-y-2 gap-x-4 auto-rows-max">
                {/* MOTIU */}
                <div className="flex flex-col col-span-12 bg-white border-2 border-blue-200 rounded shadow-md">
                  <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                    MOTIU
                  </div>

                  <div className="p-3">
                    {isViewScreen ? (
                      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                          {values?.Reason}
                        </div>
                      </div>
                    ) : (
                      <>
                        <Field
                          type="text"
                          name="Reason"
                          className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                          hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                            errors.Reason
                              ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                              : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                          }`}
                        />
                        <ErrorMessage
                          name="Reason"
                          render={(message) => (
                            <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                              {message}
                            </span>
                          )}
                        />{' '}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-span-12">
                  <hr
                    className={`w-3/4 h-1 mx-auto mt-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
                  />
                </div>

                {isViewScreen ? (
                  values.State === 'P' ? (
                    canRespond ? (
                      <>
                        <div className="flex justify-end col-span-6 mt-6 mb-3">
                          <button
                            title="Acceptar"
                            type="submit"
                            className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                            onClick={() => (submitActionRef.current = 'A')}
                            disabled={isSubmitting}
                          >
                            <FaCheck className="xl:mr-2" size={25} />
                            Acceptar
                          </button>
                        </div>

                        <div className="flex justify-start col-span-6 mt-6 mb-3">
                          <button
                            title="Denegar"
                            type="submit"
                            className={`text-red-500 bg-white hover:bg-red-500 border-2 border-red-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                            onClick={() => (submitActionRef.current = 'D')}
                            disabled={isSubmitting}
                          >
                            <FaTimes className="xl:mr-2" size={25} />
                            Denegar
                          </button>
                        </div>

                        {/* COMMENT */}
                        <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                          <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                            Comentari del revisor
                          </div>
                          <div className="p-3">
                            <Field
                              type="text"
                              name="ResponderComment"
                              className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                                errors.ResponderComment
                                  ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                                  : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                              }`}
                            />
                            <ErrorMessage
                              name="ResponderComment"
                              render={(message) => (
                                <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                                  {message}
                                </span>
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-center col-span-12 my-6">
                        <div className="cursor-default text-amber-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                          <FaRegClock className="xl:mr-2" size={25} />
                          Pendent
                        </div>
                      </div>
                    )
                  ) : values.State === 'A' ? (
                    <>
                      <div className="flex justify-center col-span-12 mt-6 mb-3">
                        <div className="cursor-default text-teal-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                          <FaCheck className="xl:mr-2" size={25} />
                          Acceptada
                        </div>
                      </div>

                      {/* COMMENT */}
                      <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                          Comentari del revisor
                        </div>
                        <div className="p-3">
                          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                            <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                              {values?.ResponderComment}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center col-span-12 mt-6 mb-3">
                        <div className="cursor-default text-red-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                          <FaTimes className="xl:mr-2" size={25} />
                          Denegada
                        </div>
                      </div>

                      {/* COMMENT */}
                      <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                          Comentari resposta
                        </div>
                        <div className="p-3">
                          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                            <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                              {values?.ResponderComment}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <div className="flex items-start justify-center col-span-12 mt-12 mb-10 space-x-2 uppercase">
                    <button
                      title="Sol·licitar"
                      type="submit"
                      className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                      disabled={isSubmitting}
                    >
                      <MdEdit className="xl:mr-2" size={25} />
                      Sol·licitar
                    </button>
                  </div>
                )}

                <div className="col-span-12">
                  <hr
                    className={`w-3/4 h-1 mx-auto mb-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
                  />
                </div>

                {isViewScreen ? (
                  <>
                    {/* DADES ACTUALS | AMAGAR A OPERARIS */}
                    <div className="flex justify-center col-span-6 cursor-not-allowed">
                      <div
                        className={`inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 ${
                          values?.RequestArticle.IsOperatorHidden !==
                          values?.RequestArticle.HistoryArticle.IsOperatorHidden
                            ? 'bg-red-100 border-red-300'
                            : 'border-gray-300'
                        } rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          type="checkbox"
                          className={`w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox`}
                          name="RequestArticle.HistoryArticle.IsOperatorHidden"
                          disabled
                        />
                        <label className="flex items-center justify-between w-full text-sm text-gray-900 uppercase cursor-not-allowed print:py-1 font-ms-bold">
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Amagar per Operaris
                        </label>
                      </div>
                    </div>

                    {/* DADES SOL·LICITUD | AMAGAR A OPERARIS */}
                    <div className="flex justify-center col-span-6 cursor-not-allowed">
                      <div
                        className={`inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 ${
                          values?.RequestArticle.IsOperatorHidden !==
                          values?.RequestArticle.HistoryArticle.IsOperatorHidden
                            ? getIsAnswered
                              ? 'bg-emerald-100 border-emerald-300'
                              : 'bg-amber-100 border-amber-300'
                            : 'border-blue-200'
                        } rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          type="checkbox"
                          className={`w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox`}
                          name="RequestArticle.IsOperatorHidden"
                          disabled
                        />
                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="RequestArticle.IsOperatorHidden"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Amagar per Operaris
                        </label>
                      </div>
                    </div>

                    {/* DADES ACTUALS | CETRIKO - CERVERA */}
                    <div className="grid grid-cols-12 col-span-6 gap-2">
                      {/* CETRIKO */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 ${
                          values?.RequestArticle.IsFromCetriko !==
                          values?.RequestArticle.HistoryArticle.IsFromCetriko
                            ? 'bg-red-100 border-red-300'
                            : 'border-gray-300'
                        } rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          id="IsFromCetriko"
                          type="checkbox"
                          className={`w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox`}
                          name="RequestArticle.HistoryArticle.IsFromCetriko"
                          disabled
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCetriko"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cetriko
                        </label>
                      </div>

                      {/* CERVERA */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 ${
                          values?.RequestArticle.IsFromCervera !==
                          values?.RequestArticle.HistoryArticle.IsFromCervera
                            ? 'bg-red-100 border-red-300'
                            : 'border-gray-300'
                        } rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          id="IsFromCervera"
                          type="checkbox"
                          className={`w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox`}
                          name="RequestArticle.HistoryArticle.IsFromCervera"
                          disabled
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCervera"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cervera
                        </label>
                      </div>
                    </div>

                    {/* DADES SOL·LICITUD | CETRIKO - CERVERA */}
                    <div className="grid grid-cols-12 col-span-6 gap-2">
                      {/* CETRIKO */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 ${
                          values?.RequestArticle.IsFromCetriko !==
                          values?.RequestArticle.HistoryArticle.IsFromCetriko
                            ? getIsAnswered
                              ? 'bg-emerald-100 border-emerald-300'
                              : 'bg-amber-100 border-amber-300'
                            : 'border-blue-200'
                        } rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          type="checkbox"
                          className={`w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox`}
                          name="RequestArticle.IsFromCetriko"
                          disabled
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCetriko"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cetriko
                        </label>
                      </div>

                      {/* CERVERA */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 ${
                          values?.RequestArticle.IsFromCervera !==
                          values?.RequestArticle.HistoryArticle.IsFromCervera
                            ? getIsAnswered
                              ? 'bg-emerald-100 border-emerald-300'
                              : 'bg-amber-100 border-amber-300'
                            : 'border-blue-200'
                        } rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          type="checkbox"
                          className={`w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox`}
                          name="RequestArticle.IsFromCervera"
                          disabled
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCervera"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cervera
                        </label>
                      </div>
                    </div>

                    {/* DADES ACTUALS | DESCRIPCIÓ */}
                    <BlockVersion3
                      title="Descripció"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="left"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES SOL·LICITUD | DESCRIPCIÓ */}
                    <BlockVersion3
                      title="Descripció"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="right"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | CARACTERÍSTIQUES */}
                    <BlockVersion3
                      title="Característiques"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="left"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES SOL·LICITUD | CARACTERÍSTIQUES */}
                    <BlockVersion3
                      title="Característiques"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="right"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    <div
                      className={`col-span-12 lg:col-span-6 flex flex-col space-y-2`}
                    >
                      {/* DADES SOL·LICITUD | MÀQUINA D'OBRIR */}
                      <BlockVersion3
                        title="Màquina d'obrir"
                        className="flex flex-col flex-grow col-span-12"
                        side="left"
                        isAnswered={getIsAnswered}
                        users={users}
                        valuesBefore={values.RequestArticle.HistoryArticle}
                        valuesAfter={values.RequestArticle}
                      />

                      {/* DADES SOL·LICITUD | REGLATGES ULTRASONS */}
                      <BlockVersion3
                        title="Reglatges ultrasons"
                        className="flex flex-col col-span-12"
                        side="left"
                        isAnswered={getIsAnswered}
                        users={users}
                        valuesBefore={values.RequestArticle.HistoryArticle}
                        valuesAfter={values.RequestArticle}
                      />
                    </div>

                    <div
                      className={`col-span-12 lg:col-span-6 flex flex-col space-y-2`}
                    >
                      {/* DADES SOL·LICITUD | MÀQUINA D'OBRIR */}
                      <BlockVersion3
                        title="Màquina d'obrir"
                        className="flex flex-col flex-grow col-span-12"
                        side="right"
                        isAnswered={getIsAnswered}
                        users={users}
                        valuesBefore={values.RequestArticle.HistoryArticle}
                        valuesAfter={values.RequestArticle}
                      />

                      {/* DADES SOL·LICITUD | REGLATGES ULTRASONS */}
                      <BlockVersion3
                        title="Reglatges ultrasons"
                        className="flex flex-col col-span-12"
                        side="right"
                        isAnswered={getIsAnswered}
                        users={users}
                        valuesBefore={values.RequestArticle.HistoryArticle}
                        valuesAfter={values.RequestArticle}
                      />
                    </div>

                    {/* DADES ACTUALS | ACABATS FOULARD */}
                    <BlockVersion3
                      title="Acabats foulard"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="left"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES SOL·LICITUD | ACABATS FOULARD */}
                    <BlockVersion3
                      title="Acabats foulard"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="right"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | VORES */}
                    <BlockVersion3
                      title="Vores"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="left"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES SOL·LICITUD | VORES */}
                    <BlockVersion3
                      title="Vores"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="right"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | CONTROLS */}
                    <BlockVersion3
                      title="Controls"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="left"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES SOL·LICITUD | CONTROLS */}
                    <BlockVersion3
                      title="Controls"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="right"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | EMBALATGE */}
                    <BlockVersion3
                      title="Embalatge"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="left"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES SOL·LICITUD | EMBALATGE */}
                    <BlockVersion3
                      title="Embalatge"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      side="right"
                      isAnswered={getIsAnswered}
                      users={users}
                      valuesBefore={values.RequestArticle.HistoryArticle}
                      valuesAfter={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | REGLATGES (RAMS) */}
                    <RamsVersion3
                      ramTab={ramTab}
                      setRamTab={setRamTab}
                      side="left"
                      isAnswered={getIsAnswered}
                      valuesBefore={values.RequestRams}
                      valuesAfter={values.RequestRams}
                    />

                    {/* DADES SOL·LICITUD | REGLATGES (RAMS) */}
                    <RamsVersion3
                      ramTab={ramTab}
                      setRamTab={setRamTab}
                      side="right"
                      isAnswered={getIsAnswered}
                      valuesBefore={values.RequestRams}
                      valuesAfter={values.RequestRams}
                    />
                  </>
                ) : (
                  <>
                    {/* DADES ACTUALS | AMAGAR A OPERARIS */}
                    <div className="flex justify-center col-span-6 cursor-not-allowed">
                      <div className="inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-gray-300 rounded shadow-md w-fit text-md font-ms-bold">
                        <Field
                          type="checkbox"
                          className="w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox"
                          name="RequestArticle.HistoryArticle.IsOperatorHidden"
                          disabled
                        />
                        <label className="flex items-center justify-between w-full text-sm text-gray-900 uppercase cursor-not-allowed print:py-1 font-ms-bold">
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Amagar per Operaris
                        </label>
                      </div>
                    </div>

                    {/* DADES SOL·LICITUD | AMAGAR A OPERARIS */}
                    <div className="flex justify-center col-span-6">
                      <div className="inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-blue-200 rounded shadow-md w-fit text-md font-ms-bold">
                        <Field
                          type="checkbox"
                          className="w-6 h-6 p-2 px-4 mr-2 cursor-pointer form-checkbox"
                          name="RequestArticle.IsOperatorHidden"
                        />
                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="RequestArticle.IsOperatorHidden"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Amagar per Operaris
                        </label>
                      </div>
                    </div>

                    {/* DADES ACTUALS | CETRIKO - CERVERA */}
                    <div className="grid grid-cols-12 col-span-6 gap-2">
                      {/* CETRIKO */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-gray-300 rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          id="IsFromCetriko"
                          type="checkbox"
                          className="w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox"
                          name="RequestArticle.HistoryArticle.IsFromCetriko"
                          disabled
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCetriko"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cetriko
                        </label>
                      </div>

                      {/* CERVERA */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-gray-300 rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          id="IsFromCervera"
                          type="checkbox"
                          className="w-6 h-6 p-2 px-4 mr-2 cursor-not-allowed form-checkbox"
                          name="RequestArticle.HistoryArticle.IsFromCervera"
                          disabled
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCervera"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cervera
                        </label>
                      </div>
                    </div>

                    {/* DADES SOL·LICITUD | CETRIKO - CERVERA */}
                    <div className="grid grid-cols-12 col-span-6 gap-2">
                      {/* CETRIKO */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-blue-200 rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          id="IsFromCetriko"
                          type="checkbox"
                          className="w-6 h-6 p-2 px-4 mr-2 cursor-pointer form-checkbox"
                          name="RequestArticle.IsFromCetriko"
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCetriko"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cetriko
                        </label>
                      </div>

                      {/* CERVERA */}
                      <div
                        className={`col-span-6 inline-flex items-center justify-center p-2 leading-normal text-center text-gray-600 uppercase transition duration-300 border-2 border-blue-200 rounded shadow-md w-fit text-md font-ms-bold`}
                      >
                        <Field
                          id="IsFromCervera"
                          type="checkbox"
                          className="w-6 h-6 p-2 px-4 mr-2 cursor-pointer form-checkbox"
                          name="RequestArticle.IsFromCervera"
                        />

                        <label
                          className="flex items-center justify-between w-full text-sm text-gray-900 uppercase print:py-1 font-ms-bold"
                          htmlFor="IsFromCervera"
                        >
                          <AiOutlineEyeInvisible
                            className="xl:hidden"
                            size={25}
                          />
                          Cervera
                        </label>
                      </div>
                    </div>

                    {/* DADES ACTUALS | DESCRIPCIÓ */}
                    <BlockVersion1
                      title="Descripció"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle.HistoryArticle}
                    />

                    {/* DADES SOL·LICITUD | DESCRIPCIÓ */}
                    <BlockVersion2
                      title="Descripció"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | CARACTERÍSTIQUES */}
                    <BlockVersion1
                      title="Característiques"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle.HistoryArticle}
                    />

                    {/* DADES SOL·LICITUD | CARACTERÍSTIQUES */}
                    <BlockVersion2
                      title="Característiques"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle}
                    />

                    <div
                      className={`col-span-12 lg:col-span-6 flex flex-col space-y-2`}
                    >
                      {/* DADES ACTUALS | MÀQUINA D'OBRIR */}
                      <BlockVersion1
                        title="Màquina d'obrir"
                        className="flex flex-col flex-grow col-span-12"
                        users={users}
                        values={values.RequestArticle.HistoryArticle}
                      />

                      {/* DADES ACTUALS | REGLATGES ULTRASONS */}
                      <BlockVersion1
                        title="Reglatges ultrasons"
                        className="flex flex-col col-span-12"
                        users={users}
                        values={values.RequestArticle.HistoryArticle}
                      />
                    </div>

                    <div
                      className={`col-span-12 lg:col-span-6 flex flex-col space-y-2`}
                    >
                      {/* DADES SOL·LICITUD | MÀQUINA D'OBRIR */}
                      <BlockVersion2
                        title="Màquina d'obrir"
                        className="flex flex-col flex-grow col-span-12"
                        users={users}
                        values={values.RequestArticle}
                      />

                      {/* DADES SOL·LICITUD | REGLATGES ULTRASONS */}
                      <BlockVersion2
                        title="Reglatges ultrasons"
                        className="flex flex-col col-span-12"
                        users={users}
                        values={values.RequestArticle}
                      />
                    </div>

                    {/* DADES ACTUALS | ACABATS FOULARD */}
                    <BlockVersion1
                      title="Acabats foulard"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle.HistoryArticle}
                    />

                    {/* DADES SOL·LICITUD | ACABATS FOULARD */}
                    <BlockVersion2
                      title="Acabats foulard"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | VORES */}
                    <BlockVersion1
                      title="Vores"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle.HistoryArticle}
                    />

                    {/* DADES SOL·LICITUD | VORES */}
                    <BlockVersion2
                      title="Vores"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | CONTROLS */}
                    <BlockVersion1
                      title="Controls"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle.HistoryArticle}
                    />

                    {/* DADES SOL·LICITUD | CONTROLS */}
                    <BlockVersion2
                      title="Controls"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | EMBALATGE */}
                    <BlockVersion1
                      title="Embalatge"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle.HistoryArticle}
                    />

                    {/* DADES SOL·LICITUD | EMBALATGE */}
                    <BlockVersion2
                      title="Embalatge"
                      className="flex flex-col col-span-12 lg:col-span-6"
                      users={users}
                      values={values.RequestArticle}
                    />

                    {/* DADES ACTUALS | REGLATGES (RAMS) */}
                    <RamsVersion1
                      ramTab={ramTab}
                      setRamTab={setRamTab}
                      values={values.RequestRams}
                    />

                    {/* DADES SOL·LICITUD | REGLATGES (RAMS) */}
                    <RamsVersion2
                      ramTab={ramTab}
                      setRamTab={setRamTab}
                      values={values.RequestRams}
                    />
                  </>
                )}
              </Form>
            )}
          </Formik>
        )
      ) : (
        <div className="inline-flex items-center justify-start w-full p-4">
          <CgSpinner size={40} className="text-charcoal animate-spin" />
          <p className="pl-2 text-lg font-semibold text-charcoal">
            Carregant...
          </p>
        </div>
      )}
    </>
  )
}

FTAcabatsRequest.propTypes = {
  params: PropTypes.any.isRequired
}
