import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { TitleContext } from 'contexts/TitleContext'

import { useLocation } from 'wouter'
import { ModalContext } from 'contexts/ModalContext'

import useFetch from 'hooks/useFetch'
import ListTable from 'components/RRHH/Versatility/ListTable'

import TableLoader from 'components/ui/Table/TableLoader'
import { ToastContainer } from 'react-toastify'

// import * as XLSX from 'xlsx';
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'

import logoCetriko from 'assets/img/logo-white.png'
import { WbShade } from '@mui/icons-material'

const VersatilityList = () => {
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)
  const [receivedVersatilityApiParams, setReceivedVersatilityApiParams] =
    useFetch()
  const [receivedTreballadorApiParams, setReceivedTreballadorApiParams] =
    useFetch()
  const [receivedAreaApiParams, setReceivedAreaApiParams] = useFetch()
  const [getTableFilteredValues, setTableFilteredValues] = useState([])
  const [getFilteredJobValues, setFilteredJobValues] = useState([])

  const [getFilteredTreballadorValues, setFilteredTreballadorValues] = useState(
    []
  )
  const [getFilteredAreaValues, setFilteredAreaValues] = useState([])
  const [getFilteredValues, setFilteredValues] = useState([])

  const btnPrintVersatilityRef = useRef(null)

  const today = new Date()
  const time =
    String(today.getHours()).padStart(2, '0') +
    '-' +
    String(today.getMinutes()).padStart(2, '0') +
    '-' +
    String(today.getSeconds()).padStart(2, '0')

  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')

  useEffect(async () => {
    // Esteblecemos la información que se mostrará en la barra del título:
    const pageTitle = {
      name: 'Polivalències',
      buttons: [
        {
          name: 'Nova polivalencia',
          link: `/polivalencies/nou/`
        }
      ]
    }
    setTitle(pageTitle)

    // Llamada a la API para recoger todos los registros de la base de datos:
    const getApiVersatilityParams = {
      url: `VersatilityMatrix`,
      method: 'GET',
      messageKo: 'Error al recuperar les polivalencies'
    }

    // Llamada a la API para recoger todos los registros de la base de datos:
    const getApiTreballadorParams = {
      url: `treballadorForMatrixTable`,
      method: 'GET',
      messageKo: 'Error al recuperar els Treballadors'
    }

    // Llamada a la API para recoger todos los registros de la base de datos:
    const getApiAreaParams = {
      url: `areaForMatrixTable`,
      method: 'GET',
      messageKo: 'Error al recuperar les Àrees'
    }

    // 'Promise' en este caso, se usa para hacer múltiples llamadas a la API en fila, una tras otra.
    // Mejora el tiempo de carga de la página. Si hacemos cada llamada a la API por separado,
    // provoca una carga más lenta de la página, para poner en contexto, el 'Promise' ahorra 3s de carga:
    await Promise.all([
      setReceivedVersatilityApiParams(getApiVersatilityParams)
      // setReceivedTreballadorApiParams(getApiTreballadorParams),
      // setReceivedAreaApiParams(getApiAreaParams)
    ]).catch((e) => {
      console.log(e)
    })

    //* Avoid bug that when item is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/polivalencies/${item.Id}`)
  }

  async function downloadMatrixTableAsExcel(
    table,
    rows,
    headerRow,
    colsHeader,
    subHeaderRow,
    colsSubHeader
  ) {
    // Create a new workbook and worksheet
    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet('Polivalències 1')

    ws.views = [
      {
        state: 'frozen',
        ySplit: 6,
        zoomScale: 70,
        zoomScaleNormal: 70,
        showGridLines: true
      }
    ]

    // Set the paper size to A3
    ws.pageSetup.paperSize = 8
    ws.pageSetup.orientation = 'landscape'
    ws.pageSetup.fitToPage = true
    ws.pageSetup.fitToHeight = 0
    ws.pageSetup.fitToWidth = 1
    ws.pageSetup.printTitlesRow = '6:7'
    ws.pageSetup.horizontalCentered = true

    // adjust pageSetup settings afterwards
    ws.pageSetup.margins = {
      left: 0.1,
      right: 0.1,
      top: 0.1,
      bottom: 0.1,
      header: 0.1,
      footer: 0.1
    }

    const setColorForArea = (idArea) => {
      console.log('IdArea: ', idArea)
      console.log('Tipus idArea: ', typeof idArea)

      switch (idArea) {
        case 1:
          return 'FFB6C1' // light pink
        case 2:
          return 'FFDAB9' // light peach
        case 3:
          return 'B5EAD7' // light green
        case 4:
          return 'E0BBE4' // lavender
        case 5:
          return 'ADD8E6' // light blue
        case 6:
          return 'FFC8AF' // light coral
        case 7:
          return 'D3D3D3' // light gray
        case 8:
          return 'D8BFD8' // light purple
        case 9:
          return 'FFF0AA' // light yellow
        case 10:
          return '98FB98' // light green
        case 11:
          return 'C4C4C4' // light gray
        case 12:
          return 'F0E68C' // khaki
        case 13:
          return '87CEFA' // light sky blue
        case 14:
          return 'FFA07A' // light salmon
        case 15:
          return 'EE82EE' // violet
        case 16:
          return '87CEEB' // sky blue
        case 17:
          return 'F08080' // light coral
        case 18:
          return 'FFB347' // light orange
        default:
          return 'F5F5F5' // default light gray
      }
    }

    // #################################################################################################

    // Bordes:
    const borderThin = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } }
    }

    // #################################################################################################

    // Estilos:
    const styleTitleCell = {
      font: {
        name: 'Candara',
        bold: true,
        italic: true,
        size: 22,
        color: { argb: '000000' }
      },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '153A5B' } },
      alignment: { vertical: 'middle', horizontal: 'center' }
    }

    const styleSubTitleCell = {
      font: { bold: true, italic: false, size: 20, color: { argb: '000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BF0043' } },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
    }

    const styleSecondSubTitleCell = {
      font: { bold: true, italic: false, size: 14, color: { argb: '000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
    }

    const styleSecondSubTitleDataCell = {
      font: { bold: true, italic: false, size: 14, color: { argb: '000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'left', wrapText: true }
    }

    const styleValidationTitles = {
      font: { bold: true, size: 16, color: { argb: 'FFFFFF' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'center' }
    }

    const styleValidationData = {
      font: { bold: true, size: 14, color: { argb: '000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } },
      alignment: { vertical: 'middle', horizontal: 'center' }
    }

    const styleLegendData = {
      font: { bold: true, size: 16, color: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
    }

    const styleCriteriaTitles = {
      font: { bold: true, size: 16, color: { argb: 'FFFFFF' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'center' }
    }

    const styleCriteriaSubTitle = {
      font: { bold: true, size: 16, color: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'left', wrapText: true }
    }

    const styleCriteriaData = {
      font: { bold: false, size: 16, color: { argb: '000000' } },
      alignment: { vertical: 'middle', horizontal: 'left', wrapText: true }
    }

    // #################################################################################################

    ws.mergeCells(1, 1, 4, 3)
    ws.getCell(1, 1).value = 'Cetriko Gestió'
    ws.getCell(1, 1).border = borderThin
    ws.getCell(1, 1).font = styleTitleCell.font
    ws.getCell(1, 1).alignment = styleTitleCell.alignment

    ws.mergeCells(1, 4, 2, 13)
    ws.getCell(1, 4).value = 'MATRIU DE POLIVALÈNCIES DELS TREBALLADORS'
    ws.getCell(1, 4).border = borderThin
    ws.getCell(1, 4).font = styleSubTitleCell.font
    ws.getCell(1, 4).alignment = styleSubTitleCell.alignment

    ws.mergeCells(3, 4, 4, 13)
    ws.getCell(3, 4).value = 'CTK-PLA-IATF-001-A'
    ws.getCell(3, 4).border = borderThin
    ws.getCell(3, 4).font = styleSecondSubTitleCell.font
    ws.getCell(3, 4).alignment = styleSecondSubTitleCell.alignment

    ws.mergeCells(1, 15, 4, 19)
    ws.getCell(1, 15).value = 'Data: '
    ws.getCell(1, 15).font = styleSecondSubTitleDataCell.font
    ws.getCell(1, 15).alignment = styleSecondSubTitleDataCell.alignment

    // #################################################################################################

    // Cuardo revisión:
    ws.mergeCells(rows.length + 10, 2, rows.length + 11, 3)
    ws.getCell(rows.length + 10, 2).value = 'REVISAT'
    ws.getCell(rows.length + 10, 2).fill = styleValidationTitles.fill
    ws.getCell(rows.length + 10, 2).border = borderThin
    ws.getCell(rows.length + 10, 2).font = styleValidationTitles.font
    ws.getCell(rows.length + 10, 2).alignment = styleValidationTitles.alignment

    ws.mergeCells(rows.length + 10, 4, rows.length + 10, 7)
    ws.getCell(rows.length + 10, 4).value = datetime
    ws.getCell(rows.length + 10, 4).fill = styleValidationTitles.fill
    ws.getCell(rows.length + 10, 4).border = borderThin
    ws.getCell(rows.length + 10, 4).font = styleValidationTitles.font
    ws.getCell(rows.length + 10, 4).alignment = styleValidationTitles.alignment

    ws.mergeCells(rows.length + 11, 4, rows.length + 13, 7)
    ws.getCell(rows.length + 11, 4).fill = styleValidationData.fill
    ws.getCell(rows.length + 11, 4).border = borderThin
    ws.getCell(rows.length + 11, 4).font = styleValidationData.font
    ws.getCell(rows.length + 11, 4).alignment = styleValidationData.alignment

    // #################################################################################################

    // Cuardo validación:
    ws.mergeCells(rows.length + 10, 10, rows.length + 11, 11)
    ws.getCell(rows.length + 10, 10).value = 'VALIDAT'
    ws.getCell(rows.length + 10, 10).fill = styleValidationTitles.fill
    ws.getCell(rows.length + 10, 10).border = borderThin
    ws.getCell(rows.length + 10, 10).font = styleValidationTitles.font
    ws.getCell(rows.length + 10, 10).alignment = styleValidationTitles.alignment

    ws.mergeCells(rows.length + 10, 12, rows.length + 10, 15)
    ws.getCell(rows.length + 10, 12).value = datetime
    ws.getCell(rows.length + 10, 12).fill = styleValidationTitles.fill
    ws.getCell(rows.length + 10, 12).border = borderThin
    ws.getCell(rows.length + 10, 12).font = styleValidationTitles.font
    ws.getCell(rows.length + 10, 12).alignment = styleValidationTitles.alignment

    ws.mergeCells(rows.length + 11, 12, rows.length + 13, 15)
    ws.getCell(rows.length + 11, 12).fill = styleValidationData.fill
    ws.getCell(rows.length + 11, 12).border = borderThin
    ws.getCell(rows.length + 11, 12).font = styleValidationData.font
    ws.getCell(rows.length + 11, 12).alignment = styleValidationData.alignment

    // #################################################################################################

    // Leyenda:
    ws.getCell(rows.length + 10, 18).value = 'F'
    ws.getCell(rows.length + 10, 18).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FBBF24' }
    }
    ws.getCell(rows.length + 10, 18).border = borderThin
    ws.getCell(rows.length + 10, 18).font = styleLegendData.font
    ws.getCell(rows.length + 10, 18).alignment = styleLegendData.alignment

    ws.mergeCells(rows.length + 10, 19, rows.length + 10, 21)
    ws.getCell(rows.length + 10, 19).value = 'FORMACIÓ'
    ws.getCell(rows.length + 10, 19).border = borderThin
    ws.getCell(rows.length + 10, 19).font = styleLegendData.font
    ws.getCell(rows.length + 10, 19).alignment = styleLegendData.alignment

    ws.getCell(rows.length + 11, 18).value = 'I'
    ws.getCell(rows.length + 11, 18).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '139669' }
    }
    ws.getCell(rows.length + 11, 18).border = borderThin
    ws.getCell(rows.length + 11, 18).font = styleLegendData.font
    ws.getCell(rows.length + 11, 18).alignment = styleLegendData.alignment

    ws.mergeCells(rows.length + 11, 19, rows.length + 11, 21)
    ws.getCell(rows.length + 11, 19).value = 'INDEPENDENT'
    ws.getCell(rows.length + 11, 19).border = borderThin
    ws.getCell(rows.length + 11, 19).font = styleLegendData.font
    ws.getCell(rows.length + 11, 19).alignment = styleLegendData.alignment

    ws.getCell(rows.length + 12, 18).value = 'M'
    ws.getCell(rows.length + 12, 18).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '6EE7B7' }
    }
    ws.getCell(rows.length + 12, 18).border = borderThin
    ws.getCell(rows.length + 12, 18).font = styleLegendData.font
    ws.getCell(rows.length + 12, 18).alignment = styleLegendData.alignment

    ws.mergeCells(rows.length + 12, 19, rows.length + 12, 21)
    ws.getCell(rows.length + 12, 19).value = 'MESTRE'
    ws.getCell(rows.length + 12, 19).border = borderThin
    ws.getCell(rows.length + 12, 19).font = styleLegendData.font
    ws.getCell(rows.length + 12, 19).alignment = styleLegendData.alignment

    // #################################################################################################

    // Cuadro criterios:
    ws.mergeCells(rows.length + 10, 24, rows.length + 11, 28)
    ws.getCell(rows.length + 10, 24).value = 'CRITERIS CANVI DE NIVELL'
    ws.getCell(rows.length + 10, 24).fill = styleCriteriaTitles.fill
    ws.getCell(rows.length + 10, 24).border = borderThin
    ws.getCell(rows.length + 10, 24).font = styleCriteriaTitles.font
    ws.getCell(rows.length + 10, 24).alignment = styleCriteriaTitles.alignment

    ws.mergeCells(rows.length + 12, 24, rows.length + 12, 28)
    ws.getCell(rows.length + 12, 24).value = 'FORMACIÓ ➡ INDEPENDENT'
    ws.getCell(rows.length + 12, 24).border = borderThin
    ws.getCell(rows.length + 12, 24).font = styleCriteriaSubTitle.font
    ws.getCell(rows.length + 12, 24).alignment = styleCriteriaSubTitle.alignment

    ws.mergeCells(rows.length + 12, 29, rows.length + 12, 34)
    ws.getCell(rows.length + 12, 29).value = 'CHECKLIST DE FORMACIÓ REALITZAT'
    ws.getCell(rows.length + 12, 29).border = borderThin
    ws.getCell(rows.length + 12, 29).font = styleCriteriaData.font
    ws.getCell(rows.length + 12, 29).alignment = styleCriteriaData.alignment

    ws.mergeCells(rows.length + 13, 24, rows.length + 13, 28)
    ws.getCell(rows.length + 13, 24).value = 'INDEPENDENT ➡ MESTRE'
    ws.getCell(rows.length + 13, 24).border = borderThin
    ws.getCell(rows.length + 13, 24).font = styleCriteriaSubTitle.font
    ws.getCell(rows.length + 13, 24).alignment = styleCriteriaSubTitle.alignment

    ws.mergeCells(rows.length + 13, 29, rows.length + 13, 34)
    ws.getCell(rows.length + 13, 29).value = 'PASSA A CATEGORIA OFICIAL'
    ws.getCell(rows.length + 13, 29).border = borderThin
    ws.getCell(rows.length + 13, 29).font = styleCriteriaData.font
    ws.getCell(rows.length + 13, 29).alignment = styleCriteriaData.alignment

    // #################################################################################################

    // Cabecera (Areas):
    let endIndex = 0

    colsHeader.forEach((col, i) => {
      const style = {
        font: { bold: true, size: 18 },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: setColorForArea(parseInt(col.getAttribute('data-area')))
          }
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
          textRotation: 90
        }
      }

      if (i === 0) {
        ws.getColumn(endIndex + 1).width = 45
      }

      if (col.colSpan > 1) {
        // Comprobar uniones de celdas:
        // console.log("ColHeaders merge values: ", 5, endIndex + 1, 5, endIndex + col.colSpan)

        // Juntar celdas:
        ws.mergeCells(6, endIndex + 1, 6, endIndex + col.colSpan)
        ws.getCell(6, endIndex + col.colSpan).value =
          col.innerText.toUpperCase()
        ws.getCell(6, endIndex + col.colSpan).fill =
          endIndex + col.colSpan > 1 && style.fill
        ws.getCell(6, endIndex + col.colSpan).border = borderThin
        ws.getCell(6, endIndex + col.colSpan).font = style.font
        ws.getCell(6, endIndex + col.colSpan).alignment = style.alignment

        // Guardar índice de la columna de la última celda puesta:
        endIndex = endIndex + col.colSpan
      } else {
        ws.getCell(6, endIndex + 1).value = col.innerText.toUpperCase()
        ws.getCell(6, endIndex + 1).fill = endIndex + 1 > 1 && style.fill
        ws.getCell(6, endIndex + 1).border = endIndex + 1 > 1 && borderThin
        ws.getCell(6, endIndex + 1).font = style.font
        ws.getCell(6, endIndex + 1).alignment = style.alignment

        endIndex = endIndex + 1
      }
    })

    ws.getRow(6).height = 150

    // #################################################################################################

    // Segunda Cabecera (Jobs):
    colsSubHeader.forEach((col, i) => {
      // Comprobar 'Llocs Treball':
      const style = {
        font: { bold: true, size: 14 },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: setColorForArea(parseInt(col.getAttribute('data-area')))
          }
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
          textRotation: 90
        }
      }

      if (i === 0) {
        ws.getColumn(i + 1).width = 50
      } else {
        ws.getColumn(i + 1).width = 10
      }

      ws.getCell(7, i + 1).value = col.innerText.toUpperCase()
      ws.getCell(7, i + 1).fill = i + 1 > 1 && style.fill
      ws.getCell(7, i + 1).border = i + 1 > 1 && borderThin
      ws.getCell(7, i + 1).font = style.font
      ws.getCell(7, i + 1).alignment = style.alignment
    })

    ws.getRow(7).height = 200

    // #################################################################################################

    // Registros
    rows.forEach((row, i) => {
      ws.getRow(i + 8).height = 16

      const cells = row.querySelectorAll('td')
      // Comprobar 'Nivells':
      cells.forEach((cell, j) => {
        const style = {
          font: { bold: true, size: 16 },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb:
                cell.id === 'workerName'
                  ? setColorForArea(parseInt(cell.getAttribute('data-area')))
                  : cell.innerText === 'M'
                  ? '6EE7B7'
                  : cell.innerText === 'I'
                  ? '139669'
                  : cell.innerText === 'F' && 'FBBF24'
            }
          },
          alignment: {
            vertical: 'middle',
            horizontal: j === 0 ? 'start' : 'center'
          }
        }

        ws.getCell(i + 8, j + 1).value =
          cell.innerText !== null && cell.innerHTML !== ''
            ? cell.innerText.toUpperCase()
            : null
        ws.getCell(i + 8, j + 1).fill = style.fill
        ws.getCell(i + 8, j + 1).border = borderThin
        ws.getCell(i + 8, j + 1).font = style.font
        ws.getCell(i + 8, j + 1).alignment = style.alignment
      })
    })

    // Save the workbook as an xlsx file
    wb.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      FileSaver.saveAs(blob, 'Polivalències_' + datetime + '_' + time)
    })
  }

  // Ponemos este condicional para que en caso de que la tabla a la que apuntamos en la base de datos está vacía
  // no deje con la pantalla cargando y nos muestre un aviso:
  return receivedVersatilityApiParams.data === null ||
    receivedVersatilityApiParams.data === undefined ||
    receivedVersatilityApiParams.data[0] === null ? (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />

        {/* Usamos la extensión 'react-toastify' para mostrar las notificaciones, esta extensión tiene la opción de
        crear manualmente una notificación: */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="colored"
        >
          No existeixen registres!
        </ToastContainer>
      </div>
    </div>
  ) : (
    <div className="flex flex-col w-full p-4">
      <ListTable
        items={receivedVersatilityApiParams.data}
        handleClick={handleClick}
        downloadMatrixTableAsExcel={downloadMatrixTableAsExcel}
      />
    </div>
  )
}

export default VersatilityList
