import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Id',
      accessor: 'ControlVision_Id',
      Filter: ColumnFilter
    },
    {
      Header: 'Data',
      accessor: 'ControlVision_Date',
      Filter: ColumnFilter
    },
    {
      Header: 'OF',
      accessor: 'ControlVision_OF',
      Filter: ColumnFilter
    },
    {
      Header: 'RAM',
      accessor: 'ControlVision_EquipmentName',
      Filter: ColumnFilter
    },
    {
      Header: 'Usuari',
      accessor: 'ControlVision_UserName',
      Filter: ColumnFilter
    },
    {
      Header: 'Taques',
      accessor: 'ControlVision_Verification1',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    },
    {
      Header: 'Linia',
      accessor: 'ControlVision_Verification2',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    },
    {
      Header: 'Forat',
      accessor: 'ControlVision_Verification3',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    },
    // {
    //   Header: 'Verificacio4',
    //   accessor: 'Verification4',
    //   Filter: ColumnFilter,
    //   Cell: ({ value }) =>
    //   value  ? (
    //     <AiOutlineCheck
    //       color="green"
    //       className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
    //       size={23}
    //     />
    //   ) : (
    //     <AiOutlineClose
    //       className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
    //       color="#be0043"
    //       size={23}
    //     />
    //   )
    // },
    {
      Header: 'Descripció',
      accessor: 'ControlVision_Description',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id:                          item.Id,
          ControlVision_Id:            item.Id,
          ControlVision_Date:          item.Date,
          ControlVision_OF:            item.OF,
          ControlVision_EquipmentName: item.EquipmentName,
          ControlVision_UserName:      item.UserName,
          ControlVision_Verification1: item.Verification1,
          ControlVision_Verification2: item.Verification2,
          ControlVision_Verification3: item.Verification3,
          ControlVision_Description:   item.Description,                              
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
          {tableData.length > 0 && (
            <Table
              tableData={tableData}
              tableColumns={tableColumns}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}