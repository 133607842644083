import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function HeightForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  // Calcular los estilos
  const getFieldStyles = (field) => {
    const hasChanged = valuesBefore[field] !== valuesAfter[field]

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <div className="grid items-center grid-cols-12 p-2 auto-rows-min md:gap-y-1 lg:gap-y-3">
      {/* Sincronit. */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Sincronit.:</div>

        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('Synchronization')}
          value={
            side === 'left'
              ? valuesBefore?.Synchronization
              : valuesAfter?.Synchronization
          }
          disabled
        />
      </div>

      {/* Formació */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Formació:</div>

        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('Formation')}
          value={
            side === 'left' ? valuesBefore?.Formation : valuesAfter?.Formation
          }
          disabled
        />
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
          Alçada plat:
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="pinyons plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              className="w-16 block-input-disabled"
              style={getFieldStyles('DishHeight1')}
              value={
                side === 'left'
                  ? valuesBefore?.DishHeight1
                  : valuesAfter?.DishHeight1
              }
              disabled
            />

            <Field
              type="text"
              className="w-16 block-input-disabled"
              style={getFieldStyles('DishHeight2')}
              value={
                side === 'left'
                  ? valuesBefore?.DishHeight2
                  : valuesAfter?.DishHeight2
              }
              disabled
            />
          </div>
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
          Desplaçament plat:
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              className="w-16 block-input-disabled"
              style={getFieldStyles('DishDisplacement1')}
              value={
                side === 'left'
                  ? valuesBefore?.DishDisplacement1
                  : valuesAfter?.DishDisplacement1
              }
              disabled
            />

            <Field
              type="text"
              className="w-16 block-input-disabled"
              style={getFieldStyles('DishDisplacement2')}
              value={
                side === 'left'
                  ? valuesBefore?.DishDisplacement2
                  : valuesAfter?.DishDisplacement2
              }
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
