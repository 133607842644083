import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import PrevenOthersForm from 'forms/preventive/PrevenOthersForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { useLocation } from 'wouter'

export default function PrevenOthersDetails({ params }) {
  // Context
  const { setTitle } = useContext(TitleContext)
  const { handleModal } = useContext(ModalContext)
  const [, setLocation] = useLocation()

  // Fetch
  const [equipmentList, equipmentListAction] = useFetch()
  const [fetchedPrevenData, fetchPrevenDataAction] = useFetch()
  const [fetchedCorrective, fetchCorrectiveAction] = useFetch()
  const [savedRecord, fetchSaveRecord] = useFetch()
  const [, deleteWorkAction] = useFetch()
  const [saveWork, saveWorkAction] = useFetch()
  const [, deleteRecordAction] = useFetch()
  const [, editRecordAction] = useFetch()
  const [recordAction, getRecordAction] = useFetch()
  const [userList, userListAction] = useFetch()
  const [, deleteDocumentAction] = useFetch()
  const [documentAction, saveDocumentAction] = useFetch()

  // State
  const [type, setType] = useState('')
  const [historicOptions, setHistoric] = useState([])
  const [correctiveOptions, setCorrective] = useState([])
  const [allowCreateWork, setAllowCreateWork] = useState(true)
  const [works, setWorks] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [documentsArray, setDocumentsArray] = useState([])

  // Vars
  const equipmentOptions = []
  const userOptions = []
  const isNew = params.id === 'nou'
  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')

  const [fields, setFields] = useState({ NoticeDate: datetime })

  useEffect(() => {
    const eqListAPIParams = {
      url: `inventorymachine`,
      method: 'GET',
      messageKo: 'Error recuperant Equips del ECAP'
    }
    equipmentListAction(eqListAPIParams)

    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar Users del ECAP'
    }
    userListAction(userListAPIParams)

    isNew ? newPreventive() : updatePreventive()
    if (!isNew) {
      const fetchAPIParams = {
        url: `preventive/${params.id}`,
        method: 'GET',
        messageKo: 'Error recuperant Preventius del ECAP'
      }
      fetchPrevenDataAction(fetchAPIParams)
    }
  }, [])

  useEffect(() => {
    if (equipmentList.data) {
      equipmentList.data.forEach((equipment) => {
        equipmentOptions.push({
          value: equipment.Id,
          label: `${equipment.Description}`
        })
      })
    }
  }, [equipmentList])

  useEffect(() => {
    if (userList.data) {
      userList.data.forEach((user) => {
        userOptions.push({
          value: user.Id,
          label: user.Name
        })
      })
    }
  }, [userList])

  useEffect(() => {
    if (fetchedPrevenData.data) {
      setFields({
        Comment: fetchedPrevenData.data.Comment,
        Description: fetchedPrevenData.data.Description,
        Frequency: fetchedPrevenData.data.Frequency,
        Id: fetchedPrevenData.data.Id,
        IsCritical: fetchedPrevenData.data.IsCritical,
        Level: fetchedPrevenData.data.Level,
        EquipmentId: fetchedPrevenData.data.EquipmentId,
        EquipmentName: fetchedPrevenData.data.EquipmentName,
        MonthFrequency: fetchedPrevenData.data.MonthFrequency,
        NoticeDate: fetchedPrevenData.data.NoticeDate.slice(0, 10),
        LastModificationDate: fetchedPrevenData?.data.LastModificationDate,
        Record: fetchedPrevenData.data.Record,
        Shed: fetchedPrevenData.data.Shed,
        UserResponsableId: fetchedPrevenData.data.UserResponsableId,
        UserResponsableName: fetchedPrevenData.data.UserResponsableName,
        Works: fetchedPrevenData.data.Preventive
      })
      setWorks(fetchedPrevenData.data.PreventiveWorks)

      const correctiveAPIParams = {
        url: `preventive/${fetchedPrevenData.data.EquipmentId}/maintenance`,
        method: 'GET',
        messageKo: 'Error al recuperar Correctius del ECAP'
      }
      fetchCorrectiveAction(correctiveAPIParams)

      const historicData = []
      fetchedPrevenData.data.PreventiveRecords?.forEach((historic) => {
        historicData.push({
          Id: historic.Id,
          IsPending: historic.IsPending,
          PreventiveDate: historic.PreventiveDate.slice(0, 10),
          RealDate: historic.RealDate.slice(0, 10),
          PreventiveTime: historic.PreventiveTime,
          PreventiveId: historic.PreventiveId,
          Observations: historic.Observations
        })
      })
      setHistoric(historicData)

      if (!fetchedPrevenData.data) {
        setFields({
          Comment: '',
          Description: '',
          Frequency: '',
          Id: '',
          IsCritical: '',
          Level: '',
          EquipmentId: '',
          EquipmentName: '',
          MonthFrequency: '',
          NoticeDate: datetime.slice(0, 10),
          LastModificationDate: null,
          Record: '',
          Shed: '',
          UserResponsableId: '',
          UserResponsableName: '',
          Works: ''
        })
      }
    }

    fetchedPrevenData.data &&
      setDocumentsArray(fetchedPrevenData.data.PreventiveDocuments)
  }, [fetchedPrevenData.data])

  useEffect(() => {
    if (fetchedCorrective.data) {
      setCorrective(fetchedCorrective.data)
    }
  }, [fetchedCorrective])

  const newPreventive = () => {
    const title = {
      name: `Crear Actuació Preventiva Acabats`,
      buttons: [
        {
          name: 'Tornar',
          link: '/prevenacabats'
        }
      ]
    }
    setTitle(title)
  }

  const handleClick = (name) => {
    setType(name)
    handleModal('hide')
  }

  const updatePreventive = () => {
    const title = {
      name: 'Preventiu Acabats',
      buttons: [
        {
          name: 'Tornar',
          link: '/prevenacabats'
        }
      ],
      clonePreventiveAcabatsBtn: true,
      btnPAdel: true,
      codPAid: params.id,
      button: 'print'
    }
    setTitle(title)
  }

  const handleSubmit = async (values) => {
    values.PreventiveDocuments = null

    let url = `preventive`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    } else {
      values.Department = type
    }

    values.LastModificationDate = datetime.slice(0, 10)

    const preventiveApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error gravant Preventius al ECAP',
      messageOk: 'Act.Preventiva actualitzada!'
    }
    if (params.id === 'nou')
      preventiveApiParams.messageOk = 'Act.Preventiva creada!'
    await fetchPrevenDataAction(preventiveApiParams)
    if (!fetchedPrevenData.error) setLocation('/prevenacabats')
  }

  const renderTableData = () => {
    if (documentsArray?.length > 0)
      return documentsArray.map((document) => {
        return (
          <td key={document.Id} className="border border-blue-400">
            <button className="block w-auto p-1 m-2 text-sm rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
              <a
                href={`https://apipreprod.cetriko.com/uploads/preventive/${
                  params.id
                }/documents/${encodeURIComponent(document.Name)}`}
                target="_blank"
                rel="noreferrer"
              >
                {document.Name}
              </a>
            </button>
            <button
              className={`m-2 mx-auto p-2 rounded-lg w-auto block font-medium text-sm     focus:shadow-outline hover:bg-gray-300 `}
              onClick={() =>
                handleModal(
                  <DeleteModal
                    deleteUserOrFT={deleteDocument}
                    element="fitxer"
                    id={document.Id}
                    closeModal={() => handleModal('hide')}
                  />
                )
              }
            >
              <FaTrash size={17} />
            </button>
          </td>
        )
      })
  }

  const findDeletedDocument = (id) => {
    const newDocumentsArray = documentsArray.filter(
      (document) => document.Id !== id
    )
    setDocumentsArray(newDocumentsArray)
  }

  const deleteDocument = (id) => {
    const deleteDocumentParams = {
      url: `preventive/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Documento eliminado!',
      messageKo: 'Error al eliminar documentos'
    }
    deleteDocumentAction(deleteDocumentParams)
    findDeletedDocument(id)
    handleModal('hide')
  }

  const handleInsertPrevRecord = async (fields) => {
    fields.PreventiveDocuments = null

    let url = `preventive`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    } else {
      fields.Department = type
    }
    const preventiveApiParams1 = {
      url,
      method,
      body: fields,
      messageKo: 'Error gravant Preventius al ECAP',
      messageOk: 'Act.Preventiva actualitzada!'
    }
    if (params.id === 'nou')
      preventiveApiParams1.messageOk = 'Act.Preventiva creada!'
    await fetchPrevenDataAction(preventiveApiParams1)

    // --------------------------------------------------------------------------------

    const tupla = {
      Id: 0,
      IsPending: 0,
      PreventiveDate: fields.NoticeDate,
      RealDate: datetime,
      PreventiveTime: 0,
      PreventiveId: fields.Id,
      Observations: ''
    }

    const preventiveApiParams = {
      url: `preventive/preventiverecord`,
      method: 'post',
      body: tupla,
      messageKo: 'Error al gravar dades!',
      messageOk: 'Registre generat OK!'
    }
    await fetchSaveRecord(preventiveApiParams)
    if (!fetchSaveRecord.error) setLocation(`/prevenacabats/${params.id}`)
  }

  const uploadDocument = (e) => {
    const files = e.currentTarget.files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    const jobParams = {
      url: `preventive/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Documento subido!',
      messageKo: 'Error al subir documentos'
    }

    saveDocumentAction(jobParams)

    setIsUploading(true)
  }

  useEffect(() => {
    if (documentAction.data && isUploading === true) {
      const newDocuments = JSON.parse(documentAction.data)
      setDocumentsArray(newDocuments)
      setIsUploading(false)
    }
  }, [documentAction])

  const handleWorkDelete = (id) => {
    const newWorks = works.filter((work) => work.Id !== id)
    setWorks(newWorks)

    const deleteWorkParams = {
      url: `preventive/${params.id}/preventivework/${id}`,
      method: 'DELETE',
      messageOk: 'sub-actuació Preventiva eliminada!',
      messageKo: id === 0 ? '' : 'Error al eliminar sub-actuació'
    }
    deleteWorkAction(deleteWorkParams)
    if (id === 0) setAllowCreateWork(true)
  }

  const handleDeleteRecord = async (fields) => {
    const deleteRecordParams = {
      url: `preventive/${params.id}/preventiverecord/${fields}`,
      method: 'DELETE',
      messageOk: 'Registre eliminat!'
    }
    deleteRecordAction(deleteRecordParams)
  }

  const handleEditRecord = async (fields) => {
    const editRecordParams = {
      url: `/preventive/preventiverecord`,
      method: 'POST',
      body: fields,
      messageOk: 'Registre editat!',
      messageKo: params.id === 0 ? '' : 'Error al editar'
    }
    editRecordAction(editRecordParams)
  }

  const handleGetRecord = async (fields) => {
    const getRecordParams = {
      headers: {
        'content-type': 'application/json'
      },
      url: `/preventive/records/${fields}`,
      method: 'GET',
      messageKo: 'Error al Consultar el registre'
    }
    await getRecordAction(getRecordParams)
  }

  const handleEditCorrective = async (fields) => {
    const url = `/mantothers/${fields}`
    setLocation(url)
  }

  function handleWorkAppend() {
    if (works?.length > 0) {
      setWorks((prevWorks) => [
        ...prevWorks,
        {
          Id: 0,
          PreventiveId: fetchedPrevenData.data?.Id,
          Section: '', // fetchedPrevenData.data?.Section,
          Work: '', // fetchedPrevenData.data?.Work,
          Description: '', // fetchedPrevenData.data?.Description,
          WorkNumber:
            prevWorks.length > 0
              ? String(Number(prevWorks[prevWorks.length - 1].WorkNumber) + 1)
              : '1'
        }
      ])
      setAllowCreateWork(false)
    } else if (fetchedPrevenData.data?.Id > 0) {
      setWorks([
        {
          Id: 0,
          PreventiveId: fetchedPrevenData.data?.Id,
          WorkNumber: '1'
        }
      ])
      setAllowCreateWork(false)
    }
  }

  // Función que se ejecuta al rellenar una nueva fila o también llamado 'actuación' en la tabla de actuaciones en "PrevenOthersWorksTable.js":
  const handleWorkSubmit = async (values) => {
    // Comprobamos si nos llegan algunos valores, es decir, si el usuario ha rellenado algún campo de la nueva actuación:
    if (values) {
      // Comprobamos que los campos no estén vacíos:
      const sect = values.Section ?? ''
      const desc = values.Description ?? ''
      const work = values.Work ?? ''

      // Cuando se rellenen todos los campos haremos un 'POST' con la nueva actuación:
      if (sect !== '' && desc !== '' && work !== '') {
        let changedWork = works.find((work) => work.Id === values.Id)
        changedWork = { ...values }

        // Guardamos la nueva actuación en la base de datos:
        const workAPIParams = {
          url: `preventive/preventivework`,
          method: 'POST',
          body: values,
          messageKo: `No s'ha guardat el nou treball!`,
          messageOk: 'Nou treball guardat OK'
        }
        await saveWorkAction(workAPIParams)

        // activar el botón biMedicalPlus de Añadir
        if (changedWork.Id === 0) setAllowCreateWork(true)

        // Volvemos a llamar todas los registros para refrescar la lista de actuaciones:
        const fetchAPIParams = {
          url: `preventive/${params.id}`,
          method: 'GET',
          messageKo: 'Error recuperant Preventius del ECAP'
        }
        fetchPrevenDataAction(fetchAPIParams)
      }
    }
  }

  return fetchedPrevenData.data || isNew ? (
    <div className="w-full p-4 bg-white print:p-0">
      <PrevenOthersForm
        type={type}
        machines={equipmentOptions}
        handleClick={handleClick}
        fields={fields}
        historicOptions={historicOptions}
        correctiveOptions={correctiveOptions}
        handleSubmit={handleSubmit}
        allowCreateWork={allowCreateWork}
        handleWorkDelete={handleWorkDelete}
        handleWorkAppend={handleWorkAppend}
        handleWorkSubmit={handleWorkSubmit}
        handleInsertPrevRecord={handleInsertPrevRecord}
        handleEditRecord={handleEditRecord}
        handleGetRecord={handleGetRecord}
        handleDeleteRecord={handleDeleteRecord}
        handleEditCorrective={handleEditCorrective}
        works={works}
        users={userOptions}
        records={recordAction}
        uploadDocument={uploadDocument}
        renderTableData={renderTableData}
        documentsLength={documentsArray?.length}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

PrevenOthersDetails.propTypes = {
  params: PropTypes.any.isRequired
}
