import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Descripció',
      accessor: 'Inventory_Description',
      Filter: ColumnFilter
    },
    {
      Header: 'Localització',
      accessor: 'Inventory_Location',
      Filter: ColumnFilter
    },
    {
      Header: 'És una eina?',
      accessor: 'Inventory_IsTool',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <span className="">
          <AiOutlineCheck color="green"
            className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
            size={23}/>
        </span>
      ) : (
        <span className="">
          <AiOutlineClose  className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
            color="#be0043"
            size={23}/>
        </span>
      )
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id:                    item.Id,
          Inventory_Description: item.Description,
          Inventory_Location:    item.Location,
          Inventory_IsTool:      item.IsTool
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 && (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}