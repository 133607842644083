// PropTypes
import PropTypes from 'prop-types'

export default function UltrasonicSettings({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2">
      {/* POTÈNCIA */}
      <div className="col-span-12 text-sm print:col-span-2 md:col-span-4 lg:col-span-3 xl:col-span-2 font-ms-semi">
        Potència:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.PowerUltrasound}
        </div>
        <span>&plusmn;</span>
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.PowerUltrasoundTolerance}
        </div>
      </div>

      {/* VELOCITAT */}
      <div className="col-span-12 mt-5 text-sm md:mt-0 print:col-span-2 md:col-span-4 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
        Velocitat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.SpeedUltrasound}
        </div>
        <span>&plusmn;</span>
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.SpeedUltrasoundTolerance}
        </div>
      </div>

      {/* PRESSIÓ */}
      <div className="col-span-12 mt-5 text-sm md:mt-0 print:col-span-2 md:col-span-4 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
        Pressió:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.PressureUltrasound}
        </div>
        <span>&plusmn;</span>
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.PressureUltrasoundTolerance}
        </div>
      </div>
    </div>
  )
}

UltrasonicSettings.propTypes = {
  values: PropTypes.object
}
