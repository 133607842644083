// PropTypes & HOOKS
import PropTypes from "prop-types"
import { createContext, useState, useEffect, useCallback, useMemo } from "react"

export const UserContext = createContext(null)

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const localUser = localStorage.getItem("user")
    return localUser ? JSON.parse(localUser) : null
  })

  const saveUserToLocalStorage = useCallback(() => {
    localStorage.setItem("user", JSON.stringify(user))
  }, [user])

  useEffect(() => {
    saveUserToLocalStorage()
  }, [saveUserToLocalStorage])

  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser])

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  )
}
UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
