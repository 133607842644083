import { useState } from 'react'
import { useLocation } from 'wouter'
import { toast } from 'react-toastify'

export default function useFetch() {
  // Wouter redirect init
  const [, setLocation] = useLocation()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  async function fetchAction(params) {
    // Init toast OK
    let messageOk
    if (params.messageOk) {
      messageOk = () =>
        toast.success(params.messageOk, {
          position: toast.POSITION.TOP_CENTER
        })
    }
    // Init toast KO
    let messageKo
    if (params.messageKo) {
      messageKo = () =>
        toast.error(params.messageKo, {
          position: toast.POSITION.TOP_CENTER
        })
    }
    setData(null)
    setError(null)

    setLoading(true)
    const apiCredentials = `Basic ${btoa(
      process.env.REACT_APP_API_USERNAME +
        ':' +
        process.env.REACT_APP_API_PASSWORD
    )}`

    let response
    if (params.formData) {
      // FETCH MULTIPART/FORM-DATA (to upload images)
      try {
        // Set headers
        const headers = new Headers()
        headers.append('Accept', 'multipart/form-data')
        headers.append('Authorization', apiCredentials)

        // console.log('Param Signal: ', params.signal)

        // Fetch
        response = params.signal
          ? await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
              method: params.method,
              headers: headers,
              body: params.formData ? params.formData : null,
              signal: params.signal
            })
          : await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
              method: params.method,
              headers: headers,
              body: params.formData ? params.formData : null
            })

        if (!response.ok) {
          // If fetch url is not for auth and response code is 401
          if (!params.url.includes('auth') && response.status === 401) {
            setLocation('/login')
          } else {
            throw new Error(response.statusText)
          }
        } else {
          const data = await response.text()
          setData(data)

          // Toast OK (if defined)
          messageOk && messageOk()
        }
      } catch (error) {
        // Toast KO (if defined)
        messageKo && messageKo()

        setError(error)
      } finally {
        setLoading(false)
      }
    } else {
      // console.log('Param Signal: ', params.signal)

      // Fetch APPLICATION/JSON (normal fetch)
      try {
        response = params.signal
          ? await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
              method: params.method,
              headers: {
                'Content-Type': 'application/json',
                Authorization: apiCredentials
              },
              body: params.body ? JSON.stringify(params.body) : null,
              signal: params.signal
            })
          : await fetch(`${process.env.REACT_APP_API_URL}/${params.url}`, {
              method: params.method,
              headers: {
                'Content-Type': 'application/json',
                Authorization: apiCredentials
              },
              body: params.body ? JSON.stringify(params.body) : null
            })

        if (!response.ok) {
          // If fetch url is not for auth and response code is 401
          if (!params.url.includes('auth') && response.status === 401) {
            setLocation('/login')
          } else {
            throw new Error(response.statusText)
          }
        } else {
          const data = await response.json()
          setData(data)

          // Toast OK (if defined)
          messageOk && messageOk()
        }
      } catch (error) {
        console.log("Fetch error: ", error)
        if(error.name !== "AbortError") {
          // Toast KO (if defined)
          messageKo && messageKo()

          setError(error)
        }
      } finally {
        setLoading(false)
      }
    }

    // console.log('Param Signal: ', params.signal)
  }

  return [{ loading, data, error }, fetchAction]
}
