// PropTypes
import PropTypes from 'prop-types'

export default function EdgesForm({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'
  const checkStyles = 'border border-gray-800 ft-check'

  return (
    <div className={blockStyle}>
      {/* NOM COLA */}
      <div className={firstLabelStyles}>Nom cola:</div>
      <div className={firstParentStyles}>
        <div className={innerParentStyles}>{values?.Glue}</div>
        <div className={checkStyles}></div>
      </div>

      {/* PROPORCIÓ */}
      <div className={otherLabelsStyles}>Proporció:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.GlueProportion}</div>
        <div className={checkStyles}></div>
      </div>

      {/* COLOR */}
      <div className={otherLabelsStyles}>Color:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {(() => {
            switch (values?.GlueColor) {
              case '1':
                return 'Incolor'
              case '2':
                return 'Blau'
              case '3':
                return 'Vermell'
              case '4':
                return 'Marró'
              case '5':
                return 'Verd'
              case '6':
                return 'Groc'
              case '7':
                return 'Mixte'
              default:
                return '-'
            }
          })()}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* ENCOLADOR */}
      <div className={otherLabelsStyles}>Encolador:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {(() => {
            switch (values?.GlueMachine) {
              case '1':
                return 'Estret'
              case '2':
                return 'Ample'
              default:
                return '-'
            }
          })()}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* TALLAR VORES */}
      <div className={otherLabelsStyles}>Tallar Vores:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.HasToCutEdge ? 'Sí' : 'No'}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* TALLADORS */}
      <div className={otherLabelsStyles}>Talladors:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Cuters}</div>
        <div className={checkStyles}></div>
      </div>

      {/* LATERALS ALINEATS */}
      <div className={otherLabelsStyles}>Laterals alineats:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.AlignedSides}</div>
        <div className={checkStyles}></div>
      </div>

      {/* OBSERVACIONS */}
      <div className={otherLabelsStyles}>Observacions:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.EdgeObservation}</div>
      </div>
    </div>
  )
}

EdgesForm.propTypes = {
  values: PropTypes.object
}
