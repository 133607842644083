import React from 'react'
import ReactDOM from 'react-dom'
import './fonts/faden.ttf'

import './index.css'
import App from './pages/App'

// Context
import { UserContextProvider } from 'contexts/UserContext'
import { TitleContextProvider } from 'contexts/TitleContext'
import { ModalContextProvider } from 'contexts/ModalContext'
import { MTContextProvider } from 'contexts/MaintenanceContext'

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <TitleContextProvider>
        <ModalContextProvider>
          <MTContextProvider>
            <App />
          </MTContextProvider>
        </ModalContextProvider>
      </TitleContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
