import { useState } from 'react'
import PropTypes from 'prop-types'

import LoginOperatorForm from 'forms/login/loginOperatorForm'

export default function OperatorLogin({
  handleSubmitOperatorLogin,
  loading,
  error
}) {
  const [pin, setPin] = useState('')
  const [company, setCompany] = useState('C') // Cetriko por defecto

  // Array d'objectes per a la maquetació del teclat amb css flexbox
  const keyBoard = [
    { value: '7', text: '7' },
    { value: '8', text: '8' },
    { value: '9', text: '9' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: '6', text: '6' },
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: 'DELETE', text: 'Borrar' },
    { value: '0', text: '0' },
    { value: 'OK', text: 'OK' }
  ]

  /**
   * Funció que es crida al fer click a qualsevol botó del teclat.
   *
   * Depenent del value del botó es fa una acció o una altra:
   * >= 0: Botons del 0 al 9 (es concatenen i guarden al state)
   * === -1: Botó Borrar (es crida a la funció per eliminar l'últim dígit del state)
   * === -2: Botó Iniciar sessió (es crida a la funció de crida a API Auth)
   *
   * @param {*} evt Event que es rep del click al botó del teclat
   */
  const handleClickPin = (evt) => {
    evt.preventDefault()

    const selectedDigit = evt.target.value

    if (selectedDigit === 'OK') {
      // Enviem petició a API auth passant com a paràmetre el PIN introduit
      handleSubmitOperatorLogin(`${company}${pin}`)
    } else if (selectedDigit === 'DELETE') {
      // Borrar últim dígit de PIN
      deleteLastPinDigit()
    } else {
      // Si es fa clic a qualsevol dígit numèric, afegir dígit a PIN
      addPinDigit(selectedDigit)
    }
  }

  /**
   * Afegir dígit al PIN
   */
  const addPinDigit = (selectedDigit) => {
    // Controlar que el pin no superi els 6 dígits
    if (pin.length < 6) {
      // Si existeix un pin, concatenar numeros. Si no, creem un pin
      setPin((currentPin) =>
        currentPin ? currentPin + selectedDigit : selectedDigit
      )
    }
  }

  /**
   * Eliminar últim dígit del PIN
   */
  const deleteLastPinDigit = () => {
    // Si existeix el PIN, eliminar últim dígit
    setPin(
      (currentPin) =>
        currentPin && currentPin.length > 0 && currentPin.slice(0, -1)
    )
  }

  /**
   * Canviar empresa per concatenar amb pin
   */

  const handleChangeCompany = (company) => {
    setCompany(company)
  }

  return (
    <div className="self-stretch m-3 w-full md:w-96 bg-white shadow-md rounded px-8 py-2 flex flex-col">
      <div className="pt-3 text-center uppercase font-ms-bold leading-6 pb-6 sm:text-2xl sm:leading-7">
        <h2>Accés Operaris</h2>
      </div>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">Pin incorrecte</span>
        </div>
      )}

      <LoginOperatorForm
        loading={loading}
        handleClickPin={handleClickPin}
        pin={pin}
        keyBoard={keyBoard}
        handleSubmitOperatorLogin={handleSubmitOperatorLogin}
        handleChangeCompany={handleChangeCompany}
      />
    </div>
  )
}

OperatorLogin.propTypes = {
  handleSubmitOperatorLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object
}
