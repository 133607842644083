// PropTypes
import PropTypes from 'prop-types'

export default function PackagingForm({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'
  const checkStyles = 'border border-gray-800 ft-check'

  return (
    <div className={blockStyle}>
      {/* TIPUS TUB */}
      <div className={firstLabelStyles}>Tipus tub:</div>
      <div className={firstParentStyles}>
        <div className={innerParentStyles}>{values?.TubeSize}</div>
        <div className={checkStyles}></div>
      </div>

      {/* COMENTARI TUB */}
      <div className={otherLabelsStyles}>Comentari Tub:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.TubName}</div>
      </div>

      {/* CINTA DOBLE CARA TUB */}
      <div className={otherLabelsStyles}>Cinta doble cara tub:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.HasDoubleSidedTape ? 'Sí' : 'No'}
        </div>
      </div>

      {/* FIXACIÓ FI BOB */}
      <div className={otherLabelsStyles}>Fixació fi bob:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.FinalCoilFixation}</div>
        <div className={checkStyles}></div>
      </div>

      {/* TIPUS PLÀSTIC */}
      <div className={otherLabelsStyles}>Tipus plàstic:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.PlasticType}</div>
      </div>

      {/* FILM POLIETILÈ */}
      <div className={otherLabelsStyles}>Film Polietilè:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.IsPolyethyleneFilm ? 'Sí' : 'No'}
        </div>
      </div>

      {/* CANTONERES */}
      <div className={otherLabelsStyles}>Cantoneres:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.HasCorners ? 'Sí' : 'No'}
        </div>
      </div>

      {/* PRECINTE COLOR */}
      <div className={otherLabelsStyles}>Precinte color:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {(() => {
            switch (values?.PrecinctColor) {
              case 'no':
                return 'No'
              case 'blau':
                return 'Blau'
              case 'groc':
                return 'Groc'
              case 'verd':
                return 'Verd'
              case 'vermell':
                return 'Vermell'
              case 'marro':
                return 'Marró'
              case 'negre':
                return 'Negre'
              case 'taronja':
                return 'Taronja'
              case 'blaugroc':
                return 'Blau + Groc'
              case 'blauverd':
                return 'Blau + Verd'
              case 'blauvermell':
                return 'Blau + Vermell'
              case 'grocverd':
                return 'Groc + Verd'
              default:
                return '-'
            }
          })()}
        </div>
      </div>

      {/* ALTRES */}
      <div className={otherLabelsStyles}>Altres:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Others}</div>
      </div>

      {/* ETIQUETES */}
      <div className={otherLabelsStyles}>Etiquetes:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.TagNumber}</div>
        <div className={innerParentStyles}>{values?.TagDescription}</div>
      </div>

      {/* OBSERVACIONS */}
      <div className={otherLabelsStyles}>Observacions:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.PackagingObservation}</div>
      </div>
    </div>
  )
}

PackagingForm.propTypes = {
  values: PropTypes.object
}
