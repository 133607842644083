import ListTable from 'components/RRHH/Jobs/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const jobList = () => {
  const { setTitle } = useContext(TitleContext)
  const [jobList, jobListAction] = useFetch()
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  useEffect(() => {
    // Set title
    const jobTitle = {
      name: 'Lloc de treball',
      buttons: [
        {
          name: 'Nou Lloc de Treball',
          link: `/lloc-treball/nou/`
        }
      ]
    }
    setTitle(jobTitle)

    const jobListAPIParams = {
      url: `job`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del lloc de treball'
    }

    jobListAction(jobListAPIParams)

    //* Avoid bug that when inventory is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/lloc-treball/${item.Id}`)
  }

  return jobList.data !== null && jobList.data !== undefined ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={jobList.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default jobList
