import ListTable from 'components/FTTeixiduria/ListTable/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

export default function FTTeixiduriaList() {
  const [localData, setLocalData] = useState([])
  const [loading, setLoading] = useState(true)
  // Set FTTeixiduria Title
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const [, setLocation] = useLocation()
  // Fetch get ft list
  const [ftList, ftListAction] = useFetch()
  const { handleModal } = useContext(ModalContext)

  useEffect(() => {
    let mounted = true

    // Fetch api FT List
    const ftListAPIParams = {
      url: `tsweaving`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    const fetchData = async () => {
      setLocalData(JSON.parse(localStorage.getItem('ftTeixiduria') || '[]'))
      if (mounted) {
        ftListAction(ftListAPIParams)
      }
    }
    fetchData()

    handleModal('hide')

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (ftList.loading) setLoading(true)
  }, [ftList.loading])

  useEffect(() => {
    const FTTeixiduriaTitle = {
      name: 'Fitxes Tècniques Teixiduria',
      buttons:
        user.Role.Id === 1 &&
        (user.Id === 818 ||
          user.Id === 7 ||
          user.Id === 4 ||
          user.Id === 11 ||
          user.Id === 8)
          ? [
              {
                name: 'Nova FT Teixiduria',
                link: `/ft-teixiduria/nou/`
              }
            ]
          : [],
      loading: loading
    }
    setTitle(FTTeixiduriaTitle)
  }, [loading])

  useEffect(() => {
    // console.log('FTTeixiduriaList ftList.data: ', ftList.data)
    if (ftList.data) {
      setLocalData(ftList.data)
      localStorage.setItem('ftTeixiduria', JSON.stringify(ftList.data))
      setLoading(false)
    }
  }, [ftList.data])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/ft-teixiduria/${item.Id}`)
  }

  return localData.length > 0 ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={localData} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}
