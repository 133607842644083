import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import NCForm from 'forms/NC/NCForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

localStorage.setItem('acumHours', 0)
localStorage.setItem('acumMinutes', 0)

export default function UserDetail({ params }) {
  const { user } = useContext(UserContext)
  const { handleModal } = useContext(ModalContext)
  const mtOptions = []
  const machineOptions = []
  const articleOptions = []
  const semiFinishedOptions = []
  const threadOptions = []
  const userOptions = []
  const [, setLocation] = useLocation()
  const [userList, userListAction] = useFetch()
  const [semisList, semisListAction] = useFetch()
  const [threadList, threadListAction] = useFetch()
  const [mtList, mtListAction] = useFetch()
  const [type, setType] = useState('')
  const currentdate = new Date()
  // const datetime =
  //   String(currentdate.getDate()).padStart(2, '0')
  //   + '-' +
  //   String(currentdate.getMonth() + 1).padStart(2, '0')
  //   + '-' +
  //   currentdate.getFullYear()
  //   // + '  ' +
  //   // String(currentdate.getHours()).padStart(2, '0') +
  //   // ':' +
  //   // String(currentdate.getMinutes()).padStart(2, '0') +
  //   // 'h'

  const datetime =
    currentdate.getFullYear() +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')

  const timedate =
    String(currentdate.getHours()).padStart(2, '0') +
    ':' +
    String(currentdate.getMinutes()).padStart(2, '0') +
    ':' +
    String(currentdate.getSeconds()).padStart(2, '0')

  const [fields, setFields] = useState({
    DateNC: datetime,
    HourNC: timedate
  })
  const { setTitle } = useContext(TitleContext)
  const [machineList, machineListAction] = useFetch()
  const [articleList, articleListAction] = useFetch()
  const [ncDetails, saveNCDetails] = useFetch()
  const isNew = params.id === 'nou'
  useEffect(() => {
    isNew ? newUser() : updateUser()

    if (!isNew) {
      const ncDetailsAPIParams = {
        url: `noconformitie/${params.id}`,
        method: 'GET'
        //   messageKo: 'Error al recuperar dades del ECAP'
      }

      saveNCDetails(ncDetailsAPIParams)
    }
    //* Get users to display in select
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    userListAction(userListAPIParams)

    //* Get semis to display in select
    const semiFinishedListAPIParams = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    semisListAction(semiFinishedListAPIParams)

    const threadListAPIParams = {
      url: `thread`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    threadListAction(threadListAPIParams)

    const machineListAPIParams = {
      url: `tsweaving/machines`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    machineListAction(machineListAPIParams)

    const articleListAPIParams = {
      url: `technicalsheet`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    articleListAction(articleListAPIParams)

    const mtListAPIParams = {
      url: `inventorymachine`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    mtListAction(mtListAPIParams)
  }, [])

  // Save title and state when GET FT details from API
  useEffect(() => {
    const Title = {
      name: `Modificar parte de no conformitat`,
      buttons: [
        {
          name: 'Tornar',
          link: '/no-conformitats'
        }
      ],
      deleteNCBtn: true,
      // cloneBtn: true,
      ncId: params.id,
      button: 'print'
    }
    // Set title
    setTitle(Title)

    if (ncDetails.data) {
      setFields(ncDetails.data)
    }
  }, [ncDetails.data])

  useEffect(() => {
    if (mtList.data) {
      mtList.data.forEach((mt) => {
        mtOptions.push({
          value: mt.Id,
          label: `${mt.Description}`
        })
      })
    }
  }, [mtList])

  useEffect(() => {
    if (semisList.data) {
      semisList.data.forEach((semiFinished) => {
        semiFinishedOptions.push({
          value: semiFinished.semi,
          label: `${semiFinished.semi}-${semiFinished.cod_art}`
        })
      })
    }
  }, [semisList])

  useEffect(() => {
    if (userList.data) {
      userList.data.forEach((user) => {
        userOptions.push({
          value: user.Id,
          label: user.Name
        })
      })
    }
  }, [userList])

  useEffect(() => {
    if (machineList.data) {
      machineList.data.forEach((machine) => {
        machineOptions.push({
          value: machine.cod_maq,
          label: machine.cod_maq + '-' + machine.desc_sec
        })
      })
    }
  }, [machineList])

  useEffect(() => {
    // console.log(articleList.data)
    if (articleList.data) {
      articleList.data.forEach((article) => {
        articleOptions.push({
          value: article.articleCode,
          label: article.description,
          client: article.client
        })
      })
    }
  }, [articleList])

  useEffect(() => {
    if (threadList.data) {
      threadList.data.forEach((thread) => {
        threadOptions.push({
          value: thread.cod_art,
          label: `${thread.cod_art}`
        })
      })
    }
  }, [threadList])

  useEffect(() => {
    if (ncDetails.data) {
      setFields({
        ...ncDetails.data
      })
    }
  }, [ncDetails.data])
  const newUser = () => {
    const title = {
      name: `Crear parte no conformitat`,
      buttons: [
        {
          name: 'Tornar',
          link: '/no-conformitats'
        }
      ]
    }
    setTitle(title)
  }
  const handleClick = (name) => {
    setType(name)
    handleModal('hide')
  }
  const updateUser = () => {
    const title = {
      name: 'Modificar parte no conformitat',
      buttons: [
        {
          name: 'Tornar',
          link: '/no-conformitats'
        }
      ],
      deleteNCBtn: true,
      ncId: params.id
    }

    setTitle(title)
  }
  const handleSubmit = async (values) => {
    let url = `noconformitie`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    } else {
      values.Department = type
    }
    const userApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Parte actualitzat!'
    }
    if (params.id === 'nou') userApiParams.messageOk = 'Parte creat!'
    await saveNCDetails(userApiParams)
    if (!ncDetails.error) setLocation('/no-conformitats')
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  const handleSemiChange = (e) => {
    setFields((prevFields) => ({ ...prevFields, SemiFinished: e.value }))
  }
  return ncDetails.data || isNew ? (
    <div className="w-full p-4 mb-3 bg-white">
      <NCForm
        type={type}
        handleClick={handleClick}
        mtList={mtOptions}
        users={userOptions}
        threads={threadOptions}
        semis={semiFinishedOptions}
        machines={machineOptions}
        articles={articleOptions}
        fields={fields}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleSemiChange={handleSemiChange}
        isNew={isNew}
        user={user}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

UserDetail.propTypes = {
  params: PropTypes.any.isRequired
}
