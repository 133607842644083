import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import GamesTable from './gamesTableView'

export default function PointGraduationForm({ values, games }) {
  return (
    <div className="grid w-full grid-cols-12 gap-2 p-2 xl:grid-cols-11">
      {/* Columna 1: */}
      {/* Campo 'Alçada cilindre (seccions)': */}
      <div className="grid items-center grid-cols-4 col-span-12">
        <label className="block col-span-4 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              className="w-16 block-input-disabled"
              value={values?.CylinderHeight1}
              disabled
            />

            <Field
              type="text"
              className="w-16 block-input-disabled"
              value={values?.CylinderHeight2}
              disabled
            />
          </div>
        </div>
      </div>

      {/* Columna 2: */}
      {/* Campo 'Posició Aro Platines': */}
      <div className="flex flex-col flex-1 col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Platines:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={{
            backgroundColor: '#F2F2F2',
            color: '#999999'
          }}
          value={values?.PositionTurntables}
          disabled
        />
      </div>

      {/* Columna 3: */}
      {/* Campo 'Posició Aro Guiafils': */}
      <div className="flex flex-col flex-1 col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Posició Aro Guiafils:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={{
            backgroundColor: '#F2F2F2',
            color: '#999999'
          }}
          value={values?.PositionGuiafils}
          disabled
        />
      </div>

      {/* Campo 'Tensiòmetre': */}
      <div className="flex items-center col-span-12 space-x-1">
        <label
          className="block pr-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PassTensiometer"
        >
          Tensiòmetre
        </label>

        <Field
          type="checkbox"
          className={`px-2 py-0.5 border text-sm border-gray-300 rounded-sm outline-none focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400 w-6 h-6 ml-1`}
          value={values?.PassTensiometer}
          disabled
        />
      </div>

      {/* Tabla 'Jocs': */}
      <div className="w-full col-span-12">
        <GamesTable games={games} />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {
  values: PropTypes.object,
  games: PropTypes.any
}
