import { useLocation, Link } from 'wouter'
import { useContext, useState } from 'react'

// Menú Items Component
import IndividualItemMenuComp from './IndividualItemMenuComp/IndividualItemMenuComp';

// Data
import { MenuOficina } from 'data/OfficeMenuItems'
import { MenuOperari } from 'data/OperatorMenuItems'
import PropTypes from 'prop-types'
// User Context
import { UserContext } from 'contexts/UserContext'
import { MTContext } from 'contexts/MaintenanceContext'

// llogica
export default function Menu({ device, handleMobileMenu }) {
  // Recogemos la URL en la que nos situamos:
  const [location] = useLocation()

  // Recogemos el contexto MT:
  const { MT } = useContext(MTContext)

  // Recogemos el usuario con el que hemos iniciado sesión:
  const { user } = useContext(UserContext)

  // Dependiendo del usuario con el que estemos tendremos un menú u otro, si somos operarios tendremos menos opciones:
  const menu = user.Role.Id === 2 ? MenuOperari : MenuOficina

  // Menú para móbil (Falta desarrollar):
  if (device === 'mobile') {
    return (
      <>
        {menu &&
          menu.map((item) => (
            <>
              <Link
                key={item.slug}
                href={item.slug}
                onClick={() => handleMobileMenu()}
              >
                <a
                  className={`${
                    item.slug === location.trim()
                      ? 'bg-secondary text-white'
                      : 'text-white hover:bg-secondary hover:text-white'
                  } block px-3 py-3 rounded-md text-base font-medium`}
                >
                  {item.title}
                </a>
              </Link>
            </>
          ))
        }
      </>
    )
  } 
  // Menú para pantalla de ordenador:
  else {
    return (      
      // Lista de menú, por cada elemento en el menú creamos un componente 'IndividualItemMenuComp':
      <ul className="space-y-2 font-medium list-none">  
        {menu && menu.map((item, index) => { 
            return <IndividualItemMenuComp item={item} user={user} location={location} key={index} />   
        })}
      </ul>
    )
  }
}

Menu.propTypes = {
  device: PropTypes.any,
  handleMobileMenu: PropTypes.any
}
