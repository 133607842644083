import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useLocation } from 'wouter'

export default function CloneFTModal({ id, closeModal }) {
  const [getApiCallPutCopy, setApiCallPutCopy] = useFetch()
  const [, setLocation] = useLocation()

  return (
    <div className="w-full h-full">
      <div className="relative w-full h-full">
        <div className="relative bg-white">
          <div className="flex items-center justify-between pb-2 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900">
              Copiar Fitxa Tècnica
            </h3>
          </div>

          <div className="px-4 py-6 space-y-2">
            <p className="text-base leading-relaxed text-gray-500">
              Estás segur/a que vols crear una nova fitxa tècnica amb el
              contingut de la fitxa tècnica actual?
            </p>
          </div>

          <div className="flex flex-row-reverse items-center pt-4 border-t border-gray-200">
            <button
              onClick={closeModal}
              className="h-10 px-3 ml-2 font-medium text-black transition-colors duration-150 bg-white border border-gray-300 rounded focus:shadow-outline hover:bg-gray-300"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={async () => {
                const apiParams = {
                  url: `tsweaving/clone/${id}`,
                  method: 'POST',
                  messageOk: 'FT duplicada!',
                  messageKo: 'Error al recuperar dades del ECAP'
                }
                await setApiCallPutCopy(apiParams)

                setLocation('/ft-teixiduria')
              }}
              className="h-10 px-4 font-medium text-white transition-colors duration-150 border rounded bg-primary border-primary focus:shadow-outline hover:bg-secondary hover:text-white"
            >
              Clonar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

CloneFTModal.propTypes = {
  id: PropTypes.any,
  closeModal: PropTypes.func
}
