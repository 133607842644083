import BlockVersion3 from 'components/ClientsRequest/Request/Block/BlockCompare'
import BlockVersion2 from 'components/ClientsRequest/Request/Block/BlockCreate'
import BlockVersion1 from 'components/ClientsRequest/Request/Block/BlockView'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaCheck, FaRegClock, FaTimes } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useLocation } from 'wouter'
import * as Yup from 'yup'

export default function ClientsRequest({ params }) {
  // Refs
  const submitActionRef = useRef()

  // Context:
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)

  // Fetch
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()

  // State:
  const [getIsAnswered, setIsAnswered] = useState(false)
  const [canRespond, setCanRespond] = useState(false)
  const [users, setUsers] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // Vars
  const isViewScreen = params.type === 'view'
  const [getRequestFieldsState, setRequestFieldsState] = useState({
    RequestId: 0,
    ClientId: 0,
    Name: '',
    Code: '',
    Tag: '',
    Position: '',
    Coil: 'NO',
    Notes: '',
    EditionNumber: '',
    Manager: [],
    State: '',
    Reason: '',
    ResponderComment: '',
    CreatedAt: '',
    UpdatedAt: '',
    RequesterId: null,
    ResponderId: null,
    History: null
  })

  const validationSchema1 = Yup.object().shape({
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    )
  })

  const validationSchema2 = Yup.object().shape({
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    ),
    ResponderComment: Yup.string().required(
      '(*No es pot respondre a una sol·licitud sense especificar un comentari.)'
    )
  })

  // Get all users
  useEffect(() => {
    const title = {
      name: `Sol·licitud`,
      buttons: [
        {
          name: 'Tornar',
          link: isViewScreen
            ? `/client/requests/${params.id}`
            : `/client/${params.id}`
        }
      ]
    }
    setTitle(title)

    const apiCallGetUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP!'
    }
    setApiCallGetUsers(apiCallGetUsers)
  }, [])

  // Get current user
  useEffect(() => {
    if (getApiCallGetUsers.data) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get current client or current request
  useEffect(() => {
    if (getApiCallGetUser.data && users) {
      setCurrentUser(getApiCallGetUser.data)

      const apiCallGet = {
        url: isViewScreen
          ? `client/request/${params.idRequest}`
          : `client/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  // Set permissions and current client info or request info
  useEffect(() => {
    if (getApiCallGet.data && currentUser) {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))

      if (isViewScreen) {
        // Check if current user can manage requests
        const allow = checkPermissions()
        setCanRespond(allow)

        if (apiData?.History) {
          apiData.History.Manager =
            apiData?.History?.Manager && apiData?.History?.Manager !== ''
              ? apiData?.History?.Manager?.replace(' ', '').split(',')
              : []
        }

        // Create request object
        setRequestFieldsState({
          RequestId: apiData?.RequestId ?? 0,
          ClientId: apiData?.ClientId ?? 0,
          Name: apiData?.Name ? apiData?.Name.trim() : '',
          Code: apiData?.Code ? apiData?.Code.trim() : '',
          Tag: apiData?.Tag ?? '',
          Position: apiData?.Position ? apiData?.Position.trim() : '',
          Coil: apiData?.Coil ?? 'NO',
          Notes: apiData?.Notes ?? '',
          EditionNumber: apiData?.EditionNumber ?? 1,
          Manager:
            apiData?.Manager && apiData?.Manager !== ''
              ? apiData?.Manager?.replace(' ', '').split(',')
              : [],
          State: apiData?.State ?? 'P',
          Reason: apiData?.Reason ?? '',
          ResponderComment: apiData?.ResponderComment ?? '',
          CreatedAt: apiData?.CreatedAt ?? '',
          UpdatedAt: apiData?.UpdatedAt ?? '',
          RequesterId: apiData?.RequesterId ?? null,
          ResponderId: apiData?.ResponderId ?? null,
          History: apiData?.History ?? null
        })

        // Set request state
        setIsAnswered(apiData?.State === 'A')
      } else {
        setRequestFieldsState({
          RequestId: 0,
          ClientId: apiData?.Id ?? 0,
          Name: apiData?.Name ? apiData?.Name.trim() : '',
          Code: apiData?.Code ? apiData?.Code.trim() : '',
          Tag: apiData?.Tag ?? '',
          Position: apiData?.Position ? apiData?.Position.trim() : '',
          Coil: apiData?.Coil ?? 'NO',
          Notes: apiData?.Notes ?? '',
          EditionNumber: apiData?.EditionNumber ?? 1,
          Manager:
            apiData?.Manager && apiData?.Manager !== ''
              ? apiData?.Manager?.replace(' ', '').split(',')
              : [],
          State: 'P',
          Reason: '',
          ResponderComment: '',
          CreatedAt: '',
          UpdatedAt: '',
          RequesterId: user.Id ?? null,
          ResponderId: null,
          History: {
            HistoyId: 0,
            Name: apiData?.Name ? apiData?.Name.trim() : '',
            Code: apiData?.Code ? apiData?.Code.trim() : '',
            Tag: apiData?.Tag !== 0 ? apiData?.Tag : '',
            Position: apiData?.Position ? apiData?.Position.trim() : '',
            Coil: apiData?.Coil ?? 'NO',
            Notes: apiData?.Notes ?? '',
            EditionNumber: apiData?.EditionNumber ?? 1,
            Manager:
              apiData?.Manager && apiData?.Manager !== ''
                ? apiData?.Manager?.replace(' ', '').split(',')
                : []
          }
        })
      }
    }
  }, [getApiCallGet.data, currentUser])

  const checkPermissions = () => {
    let allow = false
    const userRol = user.Role.Id
    const canRespond = currentUser?.CanRespondClientRequest

    if (userRol === 1) {
      if (canRespond) {
        allow = true
      } else {
        allow = false
      }
    } else {
      allow = false
    }

    return allow
  }

  // Post
  const handleSubmit = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))
    preparedValues.Manager = preparedValues.Manager.toString()
    preparedValues.History.Manager = preparedValues.History.Manager.toString()

    const apiCallPost = {
      url: 'client/request',
      method: 'POST',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Put
  const handleUpdate = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))
    preparedValues.ResponderId = user.Id
    preparedValues.Manager = preparedValues.Manager.toString()

    if (preparedValues.History) {
      preparedValues.History.Manager = preparedValues.History.Manager.toString()
    }

    const apiCallPost = {
      url: `client/request-action/${
        submitActionRef.current === 'A' ? 'approve' : 'deny'
      }`,
      method: 'PUT',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Get post/put response
  useEffect(() => {
    if (getApiCallPost.data) {
      setLocation(
        isViewScreen ? `/client/requests/${params.id}` : `/client/${params.id}`
      )
    }
  }, [getApiCallPost.data])

  return getApiCallGet.data !== null ? (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={isViewScreen ? validationSchema2 : validationSchema1}
        initialValues={getRequestFieldsState}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true)
          if (isViewScreen) {
            handleUpdate(values)
          } else {
            handleSubmit(values)
          }
        }}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          props,
          handleBlur,
          handleChange,
          submitForm,
          isSubmitting
        }) => (
          <Form className="relative grid grid-flow-row grid-cols-12 p-4 gap-y-2 gap-x-4 auto-rows-max">
            {/* MOTIU */}
            <div className="flex flex-col col-span-12 bg-white border-2 border-blue-200 rounded shadow-md">
              <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                MOTIU
              </div>

              <div className="p-3">
                {isViewScreen ? (
                  <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                    <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                      {values?.Reason}
                    </div>
                  </div>
                ) : (
                  <>
                    <Field
                      type="text"
                      name="Reason"
                      className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                          hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                            errors.Reason
                              ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                              : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                          }`}
                    />
                    <ErrorMessage
                      name="Reason"
                      render={(message) => (
                        <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />{' '}
                  </>
                )}
              </div>
            </div>

            <div className="col-span-12">
              <hr
                className={`w-3/4 h-1 mx-auto mt-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
              />
            </div>

            {isViewScreen ? (
              values.State === 'P' ? (
                canRespond ? (
                  <>
                    <div className="flex justify-end col-span-6 mt-6 mb-3">
                      <button
                        title="Acceptar"
                        type="submit"
                        className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                        onClick={() => (submitActionRef.current = 'A')}
                        disabled={isSubmitting}
                      >
                        {submitActionRef.current === 'A' && isSubmitting ? (
                          <CgSpinner
                            size={25}
                            className="animate-spin xl:mr-2"
                          />
                        ) : (
                          <FaCheck className="xl:mr-2" size={25} />
                        )}
                        <span className="hidden xl:inline-flex">Acceptar</span>
                      </button>
                    </div>

                    <div className="flex justify-start col-span-6 mt-6 mb-3">
                      <button
                        title="Denegar"
                        type="submit"
                        className={`text-red-500 bg-white hover:bg-red-500 border-2 border-red-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                        onClick={() => (submitActionRef.current = 'D')}
                        disabled={isSubmitting}
                      >
                        {submitActionRef.current === 'D' && isSubmitting ? (
                          <CgSpinner
                            size={25}
                            className="animate-spin xl:mr-2"
                          />
                        ) : (
                          <FaTimes className="xl:mr-2" size={25} />
                        )}
                        <span className="hidden xl:inline-flex">Denegar</span>
                      </button>
                    </div>

                    {/* COMMENT */}
                    <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                      <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                        Comentari del revisor
                      </div>
                      <div className="p-3">
                        <Field
                          type="text"
                          name="ResponderComment"
                          className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                                errors.ResponderComment
                                  ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                                  : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                              }`}
                        />
                        <ErrorMessage
                          name="ResponderComment"
                          render={(message) => (
                            <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                              {message}
                            </span>
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center col-span-12 my-6">
                    <div className="cursor-default text-amber-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                      <FaRegClock className="xl:mr-2" size={25} />
                      Pendent
                    </div>
                  </div>
                )
              ) : values.State === 'A' ? (
                <>
                  <div className="flex justify-center col-span-12 mt-6 mb-3">
                    <div className="cursor-default text-teal-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                      <FaCheck className="xl:mr-2" size={25} />
                      Acceptada
                    </div>
                  </div>

                  {/* COMMENT */}
                  <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                    <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                      Comentari del revisor
                    </div>
                    <div className="p-3">
                      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                          {values?.ResponderComment}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify-center col-span-12 mt-6 mb-3">
                    <div className="cursor-default text-red-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                      <FaTimes className="xl:mr-2" size={25} />
                      Denegada
                    </div>
                  </div>

                  {/* COMMENT */}
                  <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                    <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                      Comentari resposta
                    </div>
                    <div className="p-3">
                      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                          {values?.ResponderComment}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            ) : (
              <div className="flex items-start justify-center col-span-12 mt-12 mb-10 space-x-2 uppercase">
                <button
                  title="Sol·licitar"
                  type="submit"
                  className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CgSpinner size={25} className="animate-spin xl:mr-2" />
                  ) : (
                    <MdEdit className="xl:mr-2" size={25} />
                  )}
                  <span className="hidden xl:inline-flex">Sol·licitar</span>
                </button>
              </div>
            )}

            <div className="col-span-12">
              <hr
                className={`w-3/4 h-1 mx-auto mb-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
              />
            </div>

            {isViewScreen ? (
              <>
                {/* DADES ACTUALS | DESCRIPCIÓ */}
                <BlockVersion3
                  title="Dades"
                  className="flex flex-col col-span-12 lg:col-span-6"
                  side="left"
                  users={users}
                  isAnswered={getIsAnswered}
                  valuesBefore={values.History}
                  valuesAfter={values}
                />

                {/* DADES SOL·LICITUD | DESCRIPCIÓ */}
                <BlockVersion3
                  title="Dades"
                  className="flex flex-col col-span-12 lg:col-span-6"
                  side="right"
                  users={users}
                  isAnswered={getIsAnswered}
                  valuesBefore={values.History}
                  valuesAfter={values}
                />
              </>
            ) : (
              <>
                {/* DADES ACTUALS | DESCRIPCIÓ */}
                <BlockVersion1
                  title="Dades"
                  className="flex flex-col col-span-12 lg:col-span-6"
                  users={users}
                  values={values.History}
                />

                {/* DADES SOL·LICITUD | DESCRIPCIÓ */}
                <BlockVersion2
                  title="Dades"
                  className="flex flex-col col-span-12 lg:col-span-6"
                  setFieldValue={setFieldValue}
                  users={users}
                  values={values}
                />
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

ClientsRequest.propTypes = {
  params: PropTypes.any.isRequired
}
