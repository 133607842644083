import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function ObservationsForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  // Calcular los estilos
  const getFieldStyles = (field) => {
    const hasChanged = valuesBefore[field] !== valuesAfter[field]

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Observació */}
      <div className="flex flex-col col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          Observació:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={getFieldStyles('Obseravtion')}
          value={
            side === 'left'
              ? valuesBefore?.Obseravtion
              : valuesAfter?.Obseravtion
          }
          disabled
        />
      </div>

      {/* NOTA IMPORTANT */}
      <div className="flex flex-col col-span-12">
        <label className="text-sm text-grey-darker font-ms-semi">
          NOTA IMPORTANT:
        </label>

        <Field
          as="textarea"
          className="w-full text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
          style={getFieldStyles('DestacableText')}
          value={
            side === 'left'
              ? valuesBefore?.DestacableText
              : valuesAfter?.DestacableText
          }
          disabled
        />
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
