// PropTypes
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Field } from 'formik'

export default function UltrasonicSettings() {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2">
      {/* POTÈNCIA */}
      <div className="col-span-12 text-sm print:col-span-2 md:col-span-4 lg:col-span-3 xl:col-span-2 font-ms-semi">
        Potència:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-10 md:col-span-8 lg:col-span-9 xl:col-span-10">
        <Field
          type="text"
          name="RequestArticle.PowerUltrasound"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.PowerUltrasoundTolerance"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* VELOCITAT */}
      <div className="col-span-12 mt-5 text-sm md:mt-0 print:col-span-2 md:col-span-4 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
        Velocitat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-10 md:col-span-8 lg:col-span-9 xl:col-span-10">
        <Field
          type="text"
          name="RequestArticle.SpeedUltrasound"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.SpeedUltrasoundTolerance"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* PRESSIÓ */}
      <div className="col-span-12 mt-5 text-sm md:mt-0 print:col-span-2 md:col-span-4 lg:col-span-3 xl:col-span-2 font-ms-semi print:mt-0">
        Pressió:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-10 md:col-span-8 lg:col-span-9 xl:col-span-10">
        {' '}
        <Field
          type="text"
          name="RequestArticle.PressureUltrasound"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.PressureUltrasoundTolerance"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>
    </div>
  )
}

UltrasonicSettings.propTypes = {}
