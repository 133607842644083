import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
  pin: Yup.string()
    .required('Introdueixi un email')
    .min(6, 'El PIN és massa curt. 6 caràcters mínim')
})

export default function LoginOperatorForm({
  loading,
  handleClickPin,
  pin,
  keyBoard,
  handleSubmitOperatorLogin,
  handleChangeCompany
}) {
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={LoginSchema}
      onSubmit={(values) => {}}
    >
      {() => (
        <Form>
          <div className="mb-2 flex">
            <Field
              as="select"
              name="company"
              onChange={(e) => {
                handleChangeCompany(e.target.value)
              }}
              className="form-select block w-24 mr-2 bg-white shadow border border-gray-300 rounded text-grey-darker text-center"
              defaultValue="C"
            >
              <option value="C">Cetriko</option>
              <option value="Q">4 Knitting Fabrics</option>
            </Field>
            <Field
              name="pin"
              className="bg-white shadow appearance-none border border-gray-300 rounded w-full py-2 text-grey-darker text-center text-lg"
              autoComplete="pin"
              maxLength={6}
              value={pin}
              disabled
            />
          </div>
          <div className="grid grid-cols-3">
            {keyBoard.map((digit) => (
              <button
                key={digit.value}
                className={`bg-primary text-white shadow  hover:bg-secondary rounded text-center py-3 m-1 ${
                  digit.value >= 0 && 'text-3xl'
                }`}
                value={digit.value}
                onClick={handleClickPin}
                disabled={loading && 'disabled'}
              >
                {digit.text}
              </button>
            ))}
          </div>
        </Form>
      )}
    </Formik>
  )
}

LoginOperatorForm.propTypes = {
  loading: PropTypes.bool,
  handleClickPin: PropTypes.func.isRequired,
  pin: PropTypes.string,
  keyBoard: PropTypes.array.isRequired,
  handleSubmitOperatorLogin: PropTypes.func.isRequired,
  handleChangeCompany: PropTypes.func
}
