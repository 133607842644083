import { useState } from 'react'

export default () => {
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState()

  const handleModal = (content = false) => {
    if (content === 'hide') setModal(false)
    else {
      setModal(!modal)
      if (content) {
        setModalContent(content)
      }
    }
  }
  return { modal, handleModal, modalContent }
}
