import EditIcon from '@mui/icons-material/Edit'
import HistoryOutlined from '@mui/icons-material/HistoryOutlined'
import Home from '@mui/icons-material/Home'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { UserContext } from 'contexts/UserContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { MdOutlineUploadFile } from 'react-icons/md'
import Select from 'react-select'
import PrevenOthersWorksTable from './PrevenOthersWorksTable'

export default function PrevenOthersForm({
  handleSubmit,
  handleWorkDelete,
  handleWorkAppend,
  handleWorkSubmit,
  allowCreateWork,
  handleInsertPrevRecord,
  fields,
  historicOptions,
  correctiveOptions,
  handleDeleteRecord,
  handleEditCorrective,
  handleEditRecord,
  handleGetRecord,
  users,
  machines,
  records,
  works,
  uploadDocument,
  renderTableData,
  documentsLength
}) {
  const hiddenFileInput = useRef(null)
  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const { user } = useContext(UserContext)
  const [machineOptions, setMachineOptions] = useState([])
  const [machineOption, setMachineOption] = useState({})

  const [defaultShed, setDefaultShed] = useState({})
  const ShedOptions = [
    { label: 'Cetriko', value: 'Cetriko' },
    { label: '4KF', value: '4KF' }
  ]

  const [defaultRecord, setDefaultRecord] = useState({})
  const RecordOptions = [
    { label: 'BBDD', value: 'BBDD' },
    { label: 'Quadernet', value: 'Quadernet' }
  ]

  const [defaultLevel, setDefaultLevel] = useState({})
  const LevelOptions = [
    { label: '1r Nivell', value: '1r Nivell' },
    { label: '2n Nivell', value: '2n Nivell' },
    { label: '3r Nivell', value: '3r Nivell' },
    { label: 'Predictiu', value: 'Predictiu' }
  ]

  const [defaultFreq, setDefaultFreq] = useState({})
  const FreqOptions = [
    { label: 'Control', value: 'Control' },
    { label: 'Diari', value: 'Diari' },
    { label: 'Cada 1000 h', value: 'Cada 1000 h' },
    { label: 'Cada 6500 h', value: 'Cada 6500 h' },
    { label: 'Setmanal', value: 'Setmanal' },
    { label: 'Quinzenal', value: 'Quinzenal' },
    { label: 'Mensual', value: 'Mensual' },
    { label: 'Bimensual', value: 'Bimensual' },
    { label: 'Trimestral', value: 'Trimestral' },
    { label: 'Quatrimestral', value: 'Quatrimestral' },
    { label: 'Semestral', value: 'Semestral' },
    { label: 'Cada 8 mesos', value: 'Cada 8 mesos' },
    { label: 'Cada 16 mesos', value: 'Cada 16 mesos' },
    { label: 'Anual', value: 'Anual' },
    { label: 'Bianual', value: 'Bianual' },
    { label: 'Trianual', value: 'Trianual' },
    { label: 'Quadriennal', value: 'Quadriennal' },
    { label: 'Quinquenal', value: 'Quinquenal' },
    { label: 'Sexennal', value: 'Sexennal' },
    { label: 'Septennal', value: 'Septennal' },
    { label: 'Octonnal', value: 'Octonnal' },
    { label: 'Novonnal', value: 'Novonnal' },
    { label: 'Decennal', value: 'Decennal' },
    { label: 'Undecennal', value: 'Undecennal' },
    { label: 'Duodecennal', value: 'Duodecennal' }
  ]

  const sectionOptions = [
    { label: 'Acabats', value: 'Acabats' },
    { label: 'Administració', value: 'Administració' },
    { label: 'Compres/Finances', value: 'Compres/Finances' },
    { label: 'Direcció', value: 'Direcció' },
    { label: 'Expedició', value: 'Expedició' },
    { label: 'Instal·lació', value: 'Instal·lació' },
    { label: 'Laboratori', value: 'Laboratori' },
    { label: 'Magatzem', value: 'Magatzem' },
    { label: 'Manteniment', value: 'Manteniment' },
    { label: 'Neteja', value: 'Neteja' },
    { label: 'Qualitat', value: 'Qualitat' },
    { label: 'Recepció', value: 'Recepció' },
    { label: 'Rentat', value: 'Rentat' },
    { label: 'Repàs', value: 'Repàs' },
    { label: 'Resp./Tècnics', value: 'Resp./Tècnics' },
    { label: 'Teixiduria', value: 'Teixiduria' },
    { label: 'Tintura', value: 'Tintura' },
    { label: 'Vigilància', value: 'Vigilància' }
  ]

  const [userOptions, setUserOptions] = useState([])
  const [userOption, setUserOption] = useState({})
  const [recordsOption, setRecords] = useState()

  // Cálculo nueva fecha notificacion cuando se genere un nuevo registro
  // verificar si es un nou formulari o una edicio per habilitar o no el boto de GENERAR
  let itsNew = true
  if (fields && fields.Id) {
    itsNew = false
  }

  // fin verificar si es un nou formulari o una edicio per habilitar o no el boto de GENERAR
  const noticedate = new Date(fields.NoticeDate)
  const ND = new Date(
    noticedate.setMonth(noticedate.getMonth() + fields.MonthFrequency)
  )
  const newNoticeDate =
    String(ND.getFullYear()) +
    '-' +
    String(ND.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(ND.getDate()).padStart(2, '0')

  useEffect(() => {
    if (fields) {
      setDefaultShed(ShedOptions.find((option) => option.value === fields.Shed))
      setDefaultFreq(
        FreqOptions.find((option) => option.value === fields.Frequency)
      )
      setDefaultLevel(
        LevelOptions.find((option) => option.value === fields.Level)
      )
      setDefaultRecord(
        RecordOptions.find((option) => option.value === fields.Record)
      )
      if (users) {
        setUserOption(
          userOptions.find(
            (option) => option.value === fields.UserResponsableId
          )
        )
      }
    }
  }, [])

  useEffect(() => {
    setUserOptions(users)
    if (fields?.UserResponsableId) {
      setUserOption(
        userOptions.find((option) => option.value === fields.UserResponsableId)
      )
    }
  }, [users])

  useEffect(() => {
    setDefaultShed(ShedOptions.find((option) => option.value === fields.Shed))
    setDefaultFreq(
      FreqOptions.find((option) => option.value === fields.Frequency)
    )
    setDefaultLevel(
      LevelOptions.find((option) => option.value === fields.Level)
    )
    setDefaultRecord(
      RecordOptions.find((option) => option.value === fields.Record)
    )
  }, [fields])

  useEffect(() => {
    setMachineOptions(machines)
    if (fields?.EquipmentId) {
      setMachineOption(
        machineOptions.find((option) => option.value === fields.EquipmentId)
      )
    }
  }, [machines])

  useEffect(() => {
    setRecords(records.data)
  }, [records])

  // para TABS
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  // Creació data actual:
  const current = new Date()
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`

  // Pestaña 'General':
  const TabPanelGeneral = () => {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...fields }}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            submitForm
          }) => (
            <div
              id="idTabGeneral"
              className="grid grid-cols-1 pt-4 pb-10 gap-x-4"
            >
              {/* Filtros: */}
              <div
                id="idForm"
                className="rounded-lg shadow-xl print:shadow-none"
              >
                <Form className="flex flex-col col-span-3 bg-blue-100 rounded-lg print:rounded-md">
                  <div className="relative flex flex-col justify-around px-5 py-5 mx-2 container-fluid print:m-1 print:p-3">
                    {/* Filtros: */}
                    <div className="grid grid-cols-2 2xl:gap-x-56 xl:gap-x-32 lg:gap-x-28 md:gap-x-20 gap-y-3 print:gap-x-24">
                      {/* Campo Select -> 'Equip': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <label
                          className="block text-grey-darker text-md font-ms-semi"
                          htmlFor="EquipmentId"
                        >
                          Equip
                          {/* &nbsp;&nbsp; {fields.EquipmentId} */}
                        </label>
                        {/* {(machineOption || isNew) && ( */}
                        <Select
                          className="col-span-2 shadow rounded w-60 appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker 
                          print:shadow-none print:border-none print:h-auto
                          selectOnPrint"
                          name="EquipmentId"
                          defaultValue={machineOption}
                          options={machineOptions}
                          onChange={(e) => {
                            setFieldValue('EquipmentId', e.value)
                            setFieldValue('EquipmentName', e.label)
                          }}
                          placeholder="Selecciona"
                        />
                        {/* )} */}
                      </div>

                      {/* Campo Select -> 'Nivell': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="m-0 font-ms-semi">Nivell</p>
                        <Select
                          className="col-span-2 shadow rounded w-60 appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker 
                        print:shadow-none print:border-none print:h-auto
                        selectOnPrint"
                          onChange={(e) => setFieldValue('Level', e.value)}
                          name="Level"
                          defaultValue={defaultLevel}
                          placeholder="Selecciona..."
                          options={LevelOptions}
                          value={LevelOptions.find(
                            (option) => option.value === values.Level
                          )}
                        />
                      </div>

                      {/* Campo Checkbox -> 'Equip crític' + 'Nau': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="flex flex-row items-center justify-center col-span-2 m-0 font-ms-semi">
                          Equip crític{' '}
                          <Field
                            className="w-6 h-4 px-3 pl-10 ml-1 border border-gray-300 rounded text-grey-darker print:shadow-none print:h-auto print:ml-2 print:mr-1"
                            name="IsCritical"
                            type="checkbox"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          />
                        </p>

                        {' | '}

                        <p className="self-center m-0 ml-8 font-ms-semi print:ml-1">
                          Nau
                        </p>
                        <Select
                          className="col-span-2 shadow rounded w-60 appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker 
                        print:shadow-none print:border-none focus:border-none focus:outline-none print:h-auto
                        selectOnPrint"
                          onChange={(e) => setFieldValue('Shed', e.value)}
                          name="Shed"
                          defaultValue={defaultShed}
                          placeholder="Selecciona..."
                          options={ShedOptions}
                          value={ShedOptions.find(
                            (option) => option.value === values.Shed
                          )}
                        />
                      </div>

                      {/* Campo Select -> 'Freqüència': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="m-0 font-ms-semi">Freqüència</p>

                        <Select
                          className="col-span-2 shadow rounded w-60 appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker 
                        print:shadow-none print:border-none print:h-auto
                        selectOnPrint"
                          onChange={(e) => setFieldValue('Frequency', e.value)}
                          name="Frequency"
                          defaultValue={defaultFreq}
                          placeholder="Selecciona..."
                          options={FreqOptions}
                          value={FreqOptions.find(
                            (option) => option.value === values.Freq
                          )}
                        />
                      </div>

                      {/* Campo Select -> 'Responsable': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="m-0 font-ms-semi">Responsable</p>

                        <Select
                          name="Name"
                          defaultValue={userOption}
                          options={userOptions}
                          onChange={(e) => {
                            setFieldValue('UserResponsableId', e.value)
                            setFieldValue('UserResponsableName', e.label)
                          }}
                          className="col-span-2 shadow rounded w-60 appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker 
                        print:shadow-none print:border-none print:h-auto
                        selectOnPrint"
                        ></Select>
                      </div>

                      {/* Campo Select -> 'Mesos freqüència': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="m-0 font-ms-semi">Mesos freqüència</p>

                        <Field
                          className="pl-2 border border-gray-300 py-2 px-3 col-span-2 shadow rounded w-60 appearance-none h-9 text-grey-darker 
                        print:shadow-none print:border-none print:h-auto print:py-3.5"
                          name="MonthFrequency"
                          type="number"
                          required="required"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>

                      {/* Campo Select -> 'Registre preventius': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="m-0 font-ms-semi">Registre preventius</p>

                        <Select
                          className="col-span-2 shadow rounded w-60 appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker 
                          print:shadow-none print:border-none print:h-auto
                          selectOnPrint"
                          onChange={(e) => setFieldValue('Record', e.value)}
                          name="Record"
                          defaultValue={defaultRecord}
                          placeholder="Selecciona..."
                          options={RecordOptions}
                          value={RecordOptions.find(
                            (option) => option.value === values.Record
                          )}
                        />
                      </div>

                      {/* Campo Select -> 'Data avís': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="m-0 font-ms-semi">Data avís</p>
                        <Field
                          className="pl-2 border border-gray-300 py-2 px-3 col-span-2 shadow rounded w-60 appearance-none h-9 text-grey-darker 
                        print:shadow-none print:border-none print:h-auto print:py-3.5
                        selectOnPrint"
                          name="NoticeDate"
                          type="date"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>

                    {/* Campos 'Descripció' y 'Comentari': */}
                    <div className="grid grid-cols-2 pt-3 2xl:gap-x-56 xl:gap-x-42 lg:gap-x-28 md:gap-x-20 print:gap-x-24">
                      {/* Campo TextArea -> 'Descripció': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="self-start mt-2 mr-10 font-ms-semi">
                          Descripció
                        </p>
                        <Field
                          className="w-full h-20 px-3 py-2 pl-2 border border-gray-300 rounded shadow appearance-none text-grey-darker print:shadow-none print:border-none print:resize-none"
                          name="Description"
                          type="text"
                          as="textarea"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>

                      {/* Campo TextArea -> 'Comentari': */}
                      <div className="flex flex-row items-center justify-between w-full m-auto">
                        <p className="self-start mt-2 mr-10 text-right font-ms-semi">
                          Comentari
                        </p>
                        <Field
                          className="self-start w-full h-20 px-3 py-2 pl-2 border border-gray-300 rounded shadow appearance-none w-100 text-grey-darker print:shadow-none print:border-none print:resize-none"
                          name="Comment"
                          type="text"
                          as="textarea"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Botones de 'Guardar' y 'Generar': */}
                  <div
                    id="preven-guardar"
                    className="flex flex-row justify-center col-span-1 pt-3 pb-5 print:hidden"
                  >
                    {/* Botón 'Guardar': */}
                    <div>
                      <button
                        className="block h-10 px-4 py-1 ml-auto mr-2 text-white transition duration-300 border rounded 2xl:mr-5 border-primary hover:border-blue-200 bg-primary font-ms-semi w-28 hover:bg-blue-200 hover:text-primary print:hidden"
                        type="submit"
                      >
                        GUARDAR
                      </button>
                    </div>

                    {/* Botón 'Generar': */}
                    <div>
                      <button
                        className="block h-10 px-4 py-1 ml-auto text-white transition duration-300 bg-green-500 border rounded border-bg-green-300 hover:border-blue-200 font-ms-semi w-28 hover:bg-blue-200 hover:text-green-600 print:hidden"
                        type="button"
                        disabled={itsNew}
                        onClick={(e) => {
                          fields.NoticeDate = newNoticeDate
                          values.NoticeDate = newNoticeDate
                          handleInsertPrevRecord(values)
                        }}
                      >
                        GENERAR
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
              {/* Filtros: */}

              {/* Tabla de actuaciones: */}
              <div
                id="idTableOthersWorks"
                className="grid items-center grid-cols-1 mt-2 mb-3 shadow-xl print:shadow-none"
              >
                <PrevenOthersWorksTable
                  editable={true}
                  works={works}
                  fields={fields}
                  handleWorkDelete={handleWorkDelete}
                  handleWorkAppend={handleWorkAppend}
                  handleWorkSubmit={handleWorkSubmit}
                  allowCreateWork={allowCreateWork}
                  sectionOptions={sectionOptions}
                />
              </div>
              {/* Fin tabla actuaciones. */}

              {/* Ficheros adjuntos: */}
              <table
                className={`print:hidden bg-white mt-3 w-full mr-10 border shadow-md rounded-lg border-gray-300 ${
                  user.Role.Id === 2 && 'hidden'
                }`}
              >
                <thead>
                  <tr>
                    <th
                      className="relative h-3 px-4 text-sm text-left text-white print:hidden font-ms-bold w-100vw bg-primary"
                      colSpan={documentsLength}
                    >
                      <button
                        className={`right-2 bottom-2 align-right p-1 font-ms-semi m-3 px-1 rounded-lg transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                        onClick={handleClick}
                      >
                        <MdOutlineUploadFile size={25} />
                      </button>
                      <a className="text-lg">FITXERS ADJUNTS</a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>{renderTableData()}</tr>
                </tbody>
              </table>

              <input
                ref={hiddenFileInput}
                accept=".pdf, .xlsx, .jpeg, .png"
                type="file"
                name="file"
                multiple="multiple"
                className="h-1/6 hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
                onChange={(e) => uploadDocument(e)}
              />
              {/* Fin ficheros adjuntos. */}

              <div className="flex flex-col items-center justify-center w-full bottomBoxes">
                {/* Separador para la impresión: */}
                <hr
                  className="hidden w-9/12 mx-auto mt-20 mb-20 rounded print:inline"
                  style={{
                    borderTop: '3px solid #BE0043',
                    borderRadius: '50%'
                  }}
                ></hr>

                {/* Campos de 'Fecha', 'Operador', 'Firma' para la impresión: */}
                <div className="relative flex-row justify-between hidden w-full h-24 print:flex">
                  <div className="flex flex-col -inside-avoid">
                    <label>Data</label>
                    <p className="mt-2">{date}</p>
                  </div>

                  <div className="flex flex-col">
                    <label>Operari</label>
                    <div className="mt-3 border border-gray-300 rounded w-80 h-96"></div>
                  </div>

                  <div className="flex flex-col">
                    <label>Signatura</label>
                    <div className="mt-3 border border-gray-300 rounded w-80 h-96"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </>
    )
  }

  // Pestaña 'Historic':
  const TabPanelHistoric = () => {
    return (
      <>
        <div className="grid grid-cols-1 pt-4 pb-10 gap-x-4">
          {/* <p>panell de HISTORIC - en construcció</p> */}
          <div className="grid grid-cols-1 gap-x-4">
            <table className="col-span-1 text-center shadow-2xl">
              <tr className="h-12 text-white border-b border-gray-500 bg-primary">
                <th>Codi</th>
                <th>Pendent</th>
                <th>Data preventiu</th>
                <th>Data real</th>
                <th>Temps</th>
                <th className="pl-8 text-left">Observacions</th>
                <th></th>
              </tr>
              {historicOptions.map((historic) => {
                return (
                  <tr
                    key={historic}
                    className={`h-12 hover:bg-gray-100 hover:font-bold cursor-pointer border-gray-300 border`}
                    onClick={(e) => {
                      //  alert(historic.Id)
                      handleGetRecord(historic.Id)
                      // setTimeout( handleSubmit(fields), 0)
                      handleChangeTab(event, 3)
                    }}
                  >
                    <td>{historic.Id}</td>
                    <td>
                      {historic.IsPending === false ? (
                        // ? "FALSE"
                        // : "TRUE"
                        <AiOutlineClose
                          className="p-1 my-0 ml-4 bg-red-200 rounded-full 2xl:ml-6"
                          color="#be0043"
                          size={23}
                        />
                      ) : (
                        <AiOutlineCheck
                          color="green"
                          className="p-1 my-0 ml-4 bg-green-200 rounded-full 2xl:ml-6 "
                          size={23}
                        />
                      )}
                    </td>
                    <td>{historic.PreventiveDate}</td>
                    <td>{historic.RealDate}</td>
                    <td>{historic.PreventiveTime}</td>
                    <td className={`text-left pl-8 pr-4`}>
                      {historic.Observations}
                    </td>
                    <td>
                      {/* <FaEdit
                        title="Editar"
                        className="p-2 rounded-lg cursor-pointer hover:bg-white"
                        size={36}                        
                        onClick={(e) => {                      
                          //  alert(historic.Id)
                           handleGetRecord(historic.Id)
                          // setTimeout( handleSubmit(fields), 0)
                          handleChangeTab(event,3)
                          }                          
                        }                        
                      />   */}
                      <FaTrash
                        title="Eliminar"
                        className="p-2 rounded-lg cursor-pointer hover:bg-white"
                        size={36}
                        onClick={(e) => {
                          // alert("ELIMINANDO")
                          return handleDeleteRecord(historic.Id)
                          // setTimeout(handleSubmit, 0)
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
              <td></td>
            </table>
          </div>
        </div>
      </>
    )
  }

  // Pestaña 'Correctius':
  const TabPanelCorrectius = () => {
    return (
      <>
        <div className="grid grid-cols-1 pt-4 pb-10 gap-x-4">
          {/* <p>panell de CORRECTIUS - en construcció</p> */}
          <div className="grid grid-cols-1 gap-x-4 ">
            <table className="col-span-1 text-center shadow-2xl">
              <tr className="h-12 text-white border-b border-gray-500 bg-primary">
                <th>Num</th>
                <th>Equip</th>
                <th>Data Solicitud</th>
                <th>Data</th>
                <th>Area</th>
                <th>Descripció</th>
                <th>Com està</th>
                <th>Hores</th>
                <th>Minuts</th>
              </tr>
              {correctiveOptions.map((corrective) => {
                return (
                  <tr
                    key={corrective}
                    onClick={(e) => {
                      // alert(historic.Id)
                      handleEditCorrective(corrective.Id)
                      // setTimeout( handleSubmit(fields), 0)
                    }}
                    className={`h-12 hover:bg-gray-100 hover:font-bold cursor-pointer border-gray-300 border`}
                  >
                    <td>{corrective.Id}</td>
                    <td>{corrective.EquipmentName}</td>
                    <td>{corrective.RequestDate}</td>
                    <td>{corrective.CorrectiveDate}</td>
                    <td>{corrective.Area}</td>
                    <td>{corrective.Description}</td>
                    <td>{corrective.WhatStatus}</td>
                    <td>{corrective.CorrectiveTimeHours}</td>
                    <td>{corrective.CorrectiveTimeMinutes}</td>
                  </tr>
                )
              })}
              <td></td>
            </table>
          </div>
        </div>
      </>
    )
  }

  // new xavi edit prev.teixiduria
  const TabPanelEdit = () => {
    if (recordsOption) {
      const noticedate2 = recordsOption.PreventiveDate.slice(0, 10)
      recordsOption.PreventiveDate = recordsOption.PreventiveDate.slice(0, 10)

      const noticedate3 = recordsOption.RealDate.slice(0, 10)
      recordsOption.RealDate = recordsOption.RealDate.slice(0, 10)
    }

    return (
      <>
        <Formik
          initialValues={{ ...recordsOption }}
          onSubmit={(values) => {
            // handleEditRecord(historic.Id)
            handleEditRecord(values)
            handleChangeTab(event, 1)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            submitForm
          }) => (
            <div
              id="idTabGeneral"
              className="grid grid-cols-1 pt-4 pb-10 gap-x-4"
            >
              <div id="idForm" className="grid grid-cols-3 shadow-2xl">
                <Form className="flex flex-col col-span-3 bg-blue-100 rounded-lg">
                  <div className="container relative grid grid-cols-3 px-5 pt-1 mx-2">
                    <div id="esquerra" className="mx-2">
                      <div
                        className={`w-full flex col-span-2 my-3 items-center justify-between `}
                      >
                        <p className="mb-2 font-ms-semi">Codi</p>

                        <Field
                          className={`pl-2 shadow rounded w-60  appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                          defaultValue={recordsOption}
                          name="Id"
                          type="number"
                          disabled="true"
                        />
                      </div>
                      <div
                        className={`w-full flex col-span-2 items-center justify-between`}
                      >
                        <p className="mb-2 font-ms-semi">
                          Pendent
                          {/* &nbsp;{fields.UserResponsableId} */}
                        </p>
                        <Field
                          // disabled={readOnly || !canEditTeixi}
                          as="select"
                          name="IsPending"
                          onChange={(e) => {
                            handleChange(e)
                            // setTimeout(submitForm, 0)
                          }}
                          className="block h-8 ml-16 mr-1 border border-gray-300 rounded form-select 2xl:ml-0"
                        >
                          <option
                            selected={
                              values.Verification1 === false && 'selected'
                            }
                            value={false}
                          >
                            No
                          </option>
                          <option
                            selected={
                              values.Verification1 === true && 'selected'
                            }
                            value={true}
                          >
                            Si
                          </option>
                        </Field>
                      </div>
                      <div
                        className={`w-full flex col-span-2 my-3 items-center justify-between `}
                      >
                        <p className="mb-2 font-ms-semi">Data preventiu</p>
                        <Field
                          className={`pl-2 shadow rounded w-60  appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="PreventiveDate"
                          type="date"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                      <div
                        className={`w-full flex col-span-2 my-3 items-center justify-between `}
                      >
                        <p className="mb-2 font-ms-semi">Data real</p>
                        <Field
                          className={`pl-2 shadow rounded w-60  appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="RealDate"
                          type="date"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>

                    <div id="meitat" className="mx-2">
                      <div
                        className={`w-full flex my-3 items-center justify-between `}
                      >
                        <p className="mb-2 font-ms-semi">Temps</p>
                        <Field
                          className={`pl-2 shadow rounded w-60  appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                          defaultValue={recordsOption}
                          name="PreventiveTime"
                          type="number"
                        />
                      </div>
                      <div
                        className={`w-full flex col-span-2 my-3 items-center justify-between `}
                      >
                        <p className="mb-2 font-ms-semi">Observacions</p>
                        <Field
                          className={`h-24 pl-2 shadow rounded w-80 appearance-none border border-gray-300 py-2 px-3 text-grey-darker`}
                          defaultValue={recordsOption}
                          name="Observations"
                          type="text"
                          as="textarea"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    id="preven-guardar"
                    className="flex flex-row justify-center col-span-1"
                  >
                    <div>
                      <button
                        className="block h-10 px-4 py-1 mt-1 mb-2 ml-auto mr-2 text-white transition duration-300 bg-green-500 border rounded 2xl:mr-5 border-bg-green-300 hover:border-blue-200 font-ms-semi w-28 hover:bg-blue-200 hover:text-green-600 print:hidden"
                        type="submit"
                      >
                        EDITAR
                      </button>
                    </div>
                    <div>
                      <button
                        className="block h-10 px-4 py-1 mt-1 mb-2 ml-auto mr-2 text-white transition duration-300 border rounded 2xl:mr-5 border-primary hover:border-blue-200 bg-primary font-ms-semi w-28 hover:bg-blue-200 hover:text-primary print:hidden"
                        type="button"
                        onClick={(e) => {
                          handleChangeTab(event, 1)
                        }}
                      >
                        CANCELAR
                      </button>
                    </div>
                  </div>
                </Form>
              </div>

              {/* fin idGeneral */}
            </div>
          )}
        </Formik>
      </>
    )
  }

  return (
    <>
      <div className="grid grid-cols-3 print:mb-0 print:flex print:justify-center print:items-center">
        <div className="grid grid-cols-1 print:hidden">
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="icon label tabs example"
          >
            <Tab icon={<Home />} label="GENERAL" />
            <Tab icon={<HistoryOutlined />} label="HISTORIC" />
            <Tab icon={<EditIcon />} label="CORRECTIUS" />
            <Tab icon={<EditIcon />} label="Edit" style={{ display: 'none' }} />
          </Tabs>
        </div>
        <div className="grid grid-cols-none print:mb-0 print:flex print:justify-center print:items-center">
          <p className="mb-6 text-3xl text-center 2xl:mr-36 font-ms-bold print:hidden">
            PREV. ACABATS
          </p>
          <p className="hidden mb-5 text-3xl text-center 2xl:mr-36 font-ms-bold print:block">
            PREVENTIU ACABATS
          </p>
        </div>
      </div>

      {/* Dependiendo de si se selecciona el tab 'General, 'Historic'
      o 'Correctius' se mostrarà un TabPanel u otro: */}
      {selectedTab === 0 && <TabPanelGeneral />}
      {selectedTab === 1 && <TabPanelHistoric />}
      {selectedTab === 2 && <TabPanelCorrectius />}
      {selectedTab === 3 && <TabPanelEdit />}
    </>
  )
}

PrevenOthersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleWorkDelete: PropTypes.func.isRequired,
  handleWorkAppend: PropTypes.func.isRequired,
  handleWorkSubmit: PropTypes.func.isRequired,
  allowCreateWork: PropTypes.bool,
  handleInsertPrevRecord: PropTypes.func.isRequired,
  fields: PropTypes.object,
  historicOptions: PropTypes.array,
  correctiveOptions: PropTypes.array,
  handleDeleteRecord: PropTypes.func.isRequired,
  handleEditCorrective: PropTypes.func.isRequired,
  handleEditRecord: PropTypes.func.isRequired,
  handleGetRecord: PropTypes.func.isRequired,
  users: PropTypes.array,
  machines: PropTypes.array,
  records: PropTypes.object,
  works: PropTypes.array,
  uploadDocument: PropTypes.func,
  renderTableData: PropTypes.func,
  documentsLength: PropTypes.number
}
