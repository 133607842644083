import PropTypes from 'prop-types'

export default function ObservationsForm({ fields }) {
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'

  return (
    <div className="grid grid-cols-2 gap-2 p-2 print:gap-1">
      {/* Campo 'Observació': */}
      <div className="flex flex-col flex-1 col-span-1">
        <label className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Observació:
        </label>

        <div
          className={`${commonInputStyles} w-full flex-1 whitespace-pre-wrap max-h-28 truncate overflow-hidden`}
        >
          {fields?.Obseravtion && fields?.Obseravtion !== ''
            ? fields?.Obseravtion
            : ' '}
        </div>
      </div>

      {/* Campo 'NOTA IMPORTANT': */}
      <div className="flex flex-col flex-1 col-span-1">
        <label className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          NOTA IMPORTANT:
        </label>

        <div
          className={`${commonInputStyles} w-full flex-1 whitespace-pre-wrap max-h-28 truncate overflow-hidden`}
          style={{
            backgroundColor: 'rgba(233, 255, 0, 0.5)',
            color: '#333333',
            borderColor: '#D3E600'
          }}
        >
          {fields?.DestacableText && fields?.DestacableText !== ''
            ? fields?.DestacableText
            : ' '}
        </div>
      </div>

      {/* Campo 'Edició': */}
      <div className="grid grid-cols-12 col-span-1">
        <label className="self-center block col-span-3 col-start-2 text-sm text-grey-darker font-ms-semi col-start-full">
          Edició:
        </label>

        <input
          type="text"
          className={`${commonInputStyles} col-span-7`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.EditionNumber ?? ''}
          disabled
        />
      </div>

      {/* Campo 'Data edició': */}
      <div className="grid grid-cols-12 col-span-1">
        <label className="self-center block col-span-3 col-start-2 text-sm text-grey-darker font-ms-semi col-start-full">
          Data edició:
        </label>

        <input
          type="text"
          className={`${commonInputStyles} col-span-7`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.EditionDate ?? ''}
          disabled
        />
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {
  fields: PropTypes.object
}
