import PropTypes from 'prop-types'

// Images
import logoGestinet from 'assets/img/logo-gestinet.png'

export default function LogoGestinet({ classes }) {
  return (
    <a href="https://www.gestinet.com" className='w-full' target="_BLANK" rel="noreferrer">
      <img className={`${ classes }`} src={logoGestinet}  alt="Gestinet" />
    </a>
  )
}
LogoGestinet.propTypes = {
  classes: PropTypes.string.isRequired
}
