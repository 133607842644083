import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { BiPlusMedical } from 'react-icons/bi'
import { FaTrash } from 'react-icons/fa'
import Select from 'react-select'
// import ListItem from './ListItem'

export default function PrevenOthersWorksTable({
    handleWorkDelete,
    handleWorkAppend,
    handleWorkSubmit,
    allowCreateWork,
    // fieldsWorks,
    workId,
    editable,
    readOnly,
    works,
    fields,
    sectionOptions
}) {

  // Función para recoger el texto del campo 'Description' y pasarselo a la etiqueta '<pre>', así podremos ver todo el contenido 
  // de este campo. En caso de que no se use esta función no se veria todo el texto del campo 'Descripción':
  function updatePrint(sID) {
    document.getElementById("message-print").innerHTML = document.getElementById(sID).value
  }

  // Sirve para crear una fila en el formulario. La tabla que hay más abajo tiene un bucle y por cada iteración se llama esta función
  // y se crea una fila-formulario:
  const recordset = (
    setFieldValue,
    submitForm,
    handleBlur,
    index,
    work,
    workId,
    values,
    sectionOptions
  ) => {
    return (
      <tr className='w-full h-14 print:shadow-none printTr print:border-x-2'>
        {/* Campo/Celda 'Id'. Este campo no se mostrarà al imprimir: */}
        <td className="print:hidden ml-2 pl-2 lg:ml-2 w-1/12 font-normal sm:text-sm">
          <Field
            type="text"
            name="Id"
            disabled="true"              
            className="appearance-none rounded print:my-2 w-8/12 ml-0 h-9 mt-1 border border-gray-300 px-2 py-1 leading-tight focus:outline-none focus:shadow-outline printField"
          />          
        </td>

        {/* Campo/Celda 'CheckBox vacía'. Este campo solamente se mostrarà al imprimir: */}
        <td className="w-1/12 hidden print:flex pl-2" style={{ marginTop: '7px' }}>
          <Field
            className="appearance-none rounded h-6 w-6 border border-gray-300 py-1 leading-tight
            focus:outline-none focus:shadow-outline"
            type="checkbox"
          />         
        </td>

        {/* Campo/Celda 'Section' - 'Secció': */}
        <td className="w-3/12">
          <Field
            type="text"
            name="Section"
            // disabled={readOnly || !editable}
            onBlur={(e) => {
              handleBlur(e)
              setTimeout(submitForm, 0)
            }}
            className="appearance-none rounded w-11/12 h-9 mt-1 border border-gray-300 px-2 py-1 leading-tight 
            focus:outline-none focus:shadow-outline 
            printField"
          />
        </td>

        {/* Campo/Celda 'Work' - 'Actuació a realitzar': */}
        <td className="w-3/12">
          <Field
            type="text"
            name="Work"
            // disabled={readOnly || !editable}
            onBlur={(e) => {
              debugger

              handleBlur(e)
              setTimeout(submitForm, 0)
            }}
            className="appearance-none rounded w-11/12 h-9 mt-1 border border-gray-300 px-2 py-1 leading-tight 
            focus:outline-none focus:shadow-outline 
            printField"
          />
        </td>

        {/* Campo/Celda 'Description' - 'Descripción'. Este campo no se mostrarà al imprimir: */}
        <td className="w-4/12 print:hidden">
          <Field
            type="text"
            name="Description"
            // disabled={readOnly || !editable}
            onBlur={(e) => {
              debugger

              handleBlur(e)
              setTimeout(submitForm, 0)
            }}
            className="appearance-none rounded w-11/12 h-9 mt-1 border border-gray-300 px-2 py-1 leading-tight 
            focus:outline-none focus:shadow-outline
            printField printDesc"
          />
        </td>

        {/* Campo/Celda 'Description' - 'Descripción'. Este campo es el mismo que el anterior pero adaptado a la impresión, 
        solamente se mostrarà al imprimir: */}
        <td className="w-6/12 hidden print:inline">
          {/* Este 'Field' nunca aparece por pantalla pero debe existir para poder recoger el value 'Description' en el momento
          de imprimit y luego pasarlo a la etiqueta 'pre' que está más abajo: */}
          <Field
            id="textAreaResize"
            type="text"
            as="textarea"
            name="Description"
            // disabled={readOnly || !editable}
            onBlur={(e) => {
              debugger

              handleBlur(e)
              setTimeout(submitForm, 0)
              updatePrint('#textAreaResize')
            }}
            className="appearance-none rounded w-11/12 h-9 mt-1 border border-gray-300 py-1 leading-tight 
            focus:outline-none focus:shadow-outline
            print:hidden"
          />

          {/* Con la función 'updatePrint()' recogemos el texto del campo 'Description' y lo mostramos aquí: */}
          <pre id="message-print" className="w-6/12 print hidden print:block print:my-2 mt-1 py-1 leading-tight focus:outline-none focus:shadow-outline">
            { values.Description }
          </pre>
        </td>

        {/* Campo/Celda 'Añadir' - 'Icono de añadir'. Este campo no se mostrará al imprimir: */}
        <td className="text-center w-1/12 border-r print:hidden">          
          <button
            hidden={readOnly || !editable}            
            type="button"
            onClick={(e) => 
              handleWorkDelete(work.Id)
            }
            className={`p-2 font-medium rounded-lg print:hidden text-black bg-white focus:shadow-outline ${
              readOnly
                ? 'bg-gray-300 cursor-default'
                : 'cursor-pointer hover:bg-gray-300'
            }`}
          >
            <FaTrash size={20} />
          </button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <div id="idTable" className="grid grid-cols-1 items-center mt-5 print:shadow-none">
        <table className="w-full px-4">
          <thead className='bg-primary text-white'>
            <tr className="flex justify-between items-center text-left">
              {/* Celda 'Cod. Prev.': */}
              <th id="txtCod" className="print:hidden pl-3 w-1/12 font-normal sm:text-sm">
                Cod. Prev.
              </th>

              {/* Celda de 'CheckBox' que aparece solamente cuando se imprime el documento: */}
              <th id="txtCB" className="hidden print:inline w-1/12 font-normal sm:text-sm print:py-3">
                
              </th>

              {/* Celda 'Secció': */}
              <th id="txtSeccio" className="w-3/12 font-normal sm:text-sm print:py-3 print:font-bold">
                Secció
              </th>

              {/* Celda 'Actuació a realitzar': */}
              <th id="txtExplicacio" className="w-3/12 font-normal sm:text-sm print:py-3 print:font-bold">
                Actuació a realitzar
              </th>

              {/* Celda 'Descripció': */}
              <th id="txtActuacio" className="w-4/12 print:w-6/12 font-normal sm:text-sm print:py-3 print:font-bold">
                Descripció
              </th>             

              {/* Celda con botón para eliminar: */}
              <div className="flex items-start justify-center w-1/12 print:hidden" >
                <th id="btnAppend" className="m-1">
                  <div className="tooltip">
                    <span className={`tooltip-text ${allowCreateWork && 'hidden'}`}>
                      Completa totes les dades!
                    </span>
                    <button
                      hidden={readOnly || !editable}
                      disabled={!allowCreateWork}
                      onClick={() => handleWorkAppend()}
                      className={`appearance-none print:hidden text-sm rounded-lg font-ms-semi transition-colors hover:bg-white hover:text-primary duration-150 py-1 focus:shadow-outline                             
                      ${
                        !allowCreateWork
                          ? 'bg-secondary cursor-not-allowed '
                          : 'cursor-pointer'
                      }`}                      
                    >
                      <BiPlusMedical size={25} />
                    </button>
                  </div>
                </th>
              </div>
            </tr>
          </thead>
          <tbody className='divide-y border-b'>
            {/* Lista de actuaciones. Por cada actuación creamos una fila con un formulario editable: */}
            {works?.length > 0
            ? (      
                works.map((work, i)=> (    
                  <tr key={i} style={{ backgroundColor: "#ffffff" }}>
                    <Formik
                      enableReinitialize={true}
                      key={work.Id}
                      initialValues={{
                        ...work
                      }}                
                      onSubmit={(values) => {
                        handleWorkSubmit(values)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        submitForm
                      }) => (
                        // Función para rellenar la fila:
                        recordset(                                
                          setFieldValue,submitForm,handleBlur,
                          i + 1,work,workId,values,sectionOptions
                        )
                      )}
                    </Formik>
                  </tr>
                ))
            ):(
              <tr className={`w-full h-14 print:shadow-none printTr`} 
                style={{ borderBottom: "1px solid #f6f6f6", borderLeft: "1px solid #f6f6f6", borderRight: "1px solid #f6f6f6" }}>
                <td className="print:hidden ml-2 pl-2 lg:ml-2 w-1/12 font-normal sm:text-sm">
                  <p>No hi ha actuacions a realitzar</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

PrevenOthersWorksTable.propTypes = {
  handleWorkDelete: PropTypes.func.isRequired,
  handleWorkAppend: PropTypes.func.isRequired,
  handleWorkSubmit: PropTypes.func.isRequired,
  allowCreateWork:  PropTypes.bool,
  sectionOptions:   PropTypes.array,  
  workId:   PropTypes.any,
  editable: PropTypes.bool,
  readOnly: PropTypes.bool,
  works:    PropTypes.array,
  fields:   PropTypes.object
}
