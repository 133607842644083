import InferiorIcon from 'assets/icons/inferior.png'
import SuperiorIcon from 'assets/icons/superior.png'
import PropTypes from 'prop-types'

// REACT ICONS

export default function RamForm({
  ramNumber,
  ramIndex,
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid w-full grid-cols-12 gap-2 auto-rows-min">
      {/* AMPLE CADENA */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>AMPLE CADENA (cm)</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-fr print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Ample 0': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 0:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Width0 !==
                valuesAfter[ramIndex]?.Width0
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Width0
                : valuesAfter[ramIndex]?.Width0}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 1': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 1:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Width1 !==
                valuesAfter[ramIndex]?.Width1
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Width1
                : valuesAfter[ramIndex]?.Width1}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 2': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 2:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Width2 !==
                valuesAfter[ramIndex]?.Width2
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Width2
                : valuesAfter[ramIndex]?.Width2}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 3': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 3:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Width3 !==
                valuesAfter[ramIndex]?.Width3
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Width3
                : valuesAfter[ramIndex]?.Width3}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 4': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 4:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Width4 !==
                valuesAfter[ramIndex]?.Width4
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Width4
                : valuesAfter[ramIndex]?.Width4}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 5': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Ample 5:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Width5 !==
                valuesAfter[ramIndex]?.Width5
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Width5
                : valuesAfter[ramIndex]?.Width5}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Ample 6': */}
          {ramNumber !== 3 && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Ample 6:
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div
                  className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                    valuesBefore[ramIndex]?.HistoryRam?.Width6 !==
                    valuesAfter[ramIndex]?.Width6
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300'
                  } overflow-ellipsis`}
                >
                  {side === 'left'
                    ? valuesBefore[ramIndex]?.HistoryRam?.Width6
                    : valuesAfter[ramIndex]?.Width6}
                </div>
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}

          {/* Campo 'Ample 7': */}
          {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Ample 7:
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div
                  className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                    valuesBefore[ramIndex]?.HistoryRam?.Width7 !==
                    valuesAfter[ramIndex]?.Width7
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300'
                  } overflow-ellipsis`}
                >
                  {side === 'left'
                    ? valuesBefore[ramIndex]?.HistoryRam?.Width7
                    : valuesAfter[ramIndex]?.Width7}
                </div>
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}
        </div>
      </div>

      {/* TEMPERATURA */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>TEMPERATURA (ºC)</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Camp 1': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 1
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Temperature1 !==
                valuesAfter[ramIndex]?.Temperature1
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Temperature1
                : valuesAfter[ramIndex]?.Temperature1}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 2': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 2
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Temperature2 !==
                valuesAfter[ramIndex]?.Temperature2
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Temperature2
                : valuesAfter[ramIndex]?.Temperature2}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 3': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 3
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Temperature3 !==
                valuesAfter[ramIndex]?.Temperature3
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Temperature3
                : valuesAfter[ramIndex]?.Temperature3}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 4': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Camp 4
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Temperature4 !==
                valuesAfter[ramIndex]?.Temperature4
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Temperature4
                : valuesAfter[ramIndex]?.Temperature4}
            </div>
            <span>&plusmn;&nbsp;5</span>
          </div>

          {/* Campo 'Camp 5': */}
          {ramNumber !== 3 && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Camp 5
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div
                  className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                    valuesBefore[ramIndex]?.HistoryRam?.Temperature5 !==
                    valuesAfter[ramIndex]?.Temperature5
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300'
                  } overflow-ellipsis`}
                >
                  {side === 'left'
                    ? valuesBefore[ramIndex]?.HistoryRam?.Temperature5
                    : valuesAfter[ramIndex]?.Temperature5}
                </div>
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}

          {/* Campo 'Camp 6': */}
          {(ramNumber === 2 || ramNumber === 4 || ramNumber === 5) && (
            <>
              <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
                Camp 6
              </div>
              <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                <div
                  className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                    valuesBefore[ramIndex]?.HistoryRam?.Temperature6 !==
                    valuesAfter[ramIndex]?.Temperature6
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300'
                  } overflow-ellipsis`}
                >
                  {side === 'left'
                    ? valuesBefore[ramIndex]?.HistoryRam?.Temperature6
                    : valuesAfter[ramIndex]?.Temperature6}
                </div>
                <span>&plusmn;&nbsp;5</span>
              </div>
            </>
          )}
        </div>
      </div>

      {/* VENTILACIÓ */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>VENTILACIÓ (%)</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 auto-rows-fr print:p-2 gap-x-3 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Input 1': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            {ramNumber !== 3 && (
              <div
                className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                  valuesBefore[ramIndex]?.HistoryRam
                    ?.TopVentilationPercentage !==
                  valuesAfter[ramIndex]?.TopVentilationPercentage
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300'
                } cursor-not-allowed overflow-ellipsis`}
              >
                {side === 'left'
                  ? valuesBefore[ramIndex]?.HistoryRam?.TopVentilationPercentage
                  : valuesAfter[ramIndex]?.TopVentilationPercentage}
              </div>
            )}
          </div>

          {/* Campo 'Imagen SUP': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <img className="m-auto" src={SuperiorIcon} alt="sup" width={25} />
          </div>

          {/* Campo 'Input 2': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.BottomVentilation1 !==
                valuesAfter[ramIndex]?.BottomVentilation1
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } cursor-not-allowed overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.BottomVentilation1
                : valuesAfter[ramIndex]?.BottomVentilation1}
            </div>
          </div>

          {/* Campo 'Input 3': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.TopVentilation1 !==
                valuesAfter[ramIndex]?.TopVentilation1
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } cursor-not-allowed overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.TopVentilation1
                : valuesAfter[ramIndex]?.TopVentilation1}
            </div>
          </div>

          {/* Campo 'Input 4': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.BottomVentilation2 !==
                valuesAfter[ramIndex]?.BottomVentilation2
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } cursor-not-allowed overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.BottomVentilation2
                : valuesAfter[ramIndex]?.BottomVentilation2}
            </div>
          </div>

          {/* Campo 'Input 5': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.TopVentilation2 !==
                valuesAfter[ramIndex]?.TopVentilation2
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } cursor-not-allowed overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.TopVentilation2
                : valuesAfter[ramIndex]?.TopVentilation2}
            </div>
          </div>

          {/* Campo 'Input 6': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.BottomVentilation3 !==
                valuesAfter[ramIndex]?.BottomVentilation3
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } cursor-not-allowed overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.BottomVentilation3
                : valuesAfter[ramIndex]?.BottomVentilation3}
            </div>
          </div>

          {/* Campo 'Input 7': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.TopVentilation3 !==
                valuesAfter[ramIndex]?.TopVentilation3
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } cursor-not-allowed overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.TopVentilation3
                : valuesAfter[ramIndex]?.TopVentilation3}
            </div>
          </div>

          {/* Campo 'Imagen INF': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            <img className="m-auto" src={InferiorIcon} alt="inf" width={25} />
          </div>

          {/* Campo 'Input 8': */}
          <div className="flex items-center h-8 col-span-6 space-x-2 print:h-full">
            {ramNumber === 3 && (
              <div
                className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                  valuesBefore[ramIndex]?.HistoryRam
                    ?.BottomVentilationPercentage !==
                  valuesAfter[ramIndex]?.BottomVentilationPercentage
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300'
                } cursor-not-allowed overflow-ellipsis`}
              >
                {side === 'left'
                  ? valuesBefore[ramIndex]?.HistoryRam
                      ?.BottomVentilationPercentage
                  : valuesAfter[ramIndex]?.BottomVentilationPercentage}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* SOBREALIMENTACIÓ */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>SOBREALIMENTACIÓ (%)</span>
        </div>

        {/* Campo 'SUP / INF': */}
        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-2 print:gap-y-2 print:auto-rows-auto">
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            SUP/INF:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.OverFeeding !==
                valuesAfter[ramIndex]?.OverFeeding
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.OverFeeding
                : valuesAfter[ramIndex]?.OverFeeding}
            </div>
          </div>
        </div>
      </div>

      {/* SORTIDA */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>SORTIDA</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Tensió': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Tensió:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Tension !==
                valuesAfter[ramIndex]?.Tension
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Tension
                : valuesAfter[ramIndex]?.Tension}
            </div>
            <span>&plusmn;</span>
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.TensionTolerance !==
                valuesAfter[ramIndex]?.TensionTolerance
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.TensionTolerance
                : valuesAfter[ramIndex]?.TensionTolerance}
            </div>
          </div>

          {/* Campo 'Potenciòm.': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Potenciòm.:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Potential !==
                valuesAfter[ramIndex]?.Potential
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Potential
                : valuesAfter[ramIndex]?.Potential}
            </div>
            <span>&plusmn;</span>
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.PotentialTolerance !==
                valuesAfter[ramIndex]?.PotentialTolerance
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.PotentialTolerance
                : valuesAfter[ramIndex]?.PotentialTolerance}
            </div>
          </div>

          {/* Campo 'Refrigeració': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Refrigeració:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.IsRefrigeration !==
                valuesAfter[ramIndex]?.IsRefrigeration
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.IsRefrigeration
                  ? 'Sí'
                  : 'No'
                : valuesAfter[ramIndex]?.IsRefrigeration
                ? 'Sí'
                : 'No'}
            </div>
          </div>
        </div>
      </div>

      {/* ALTRES */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>ALTRES</span>
        </div>

        <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
          {/* Campo 'Pot. encoladors': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pot. encoladors:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.GlueEnhancer !==
                valuesAfter[ramIndex]?.GlueEnhancer
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.GlueEnhancer
                : valuesAfter[ramIndex]?.GlueEnhancer}
            </div>
            <span>&plusmn;</span>
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.GlueEnhancerTolerance !==
                valuesAfter[ramIndex]?.GlueEnhancerTolerance
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.GlueEnhancerTolerance
                : valuesAfter[ramIndex]?.GlueEnhancerTolerance}
            </div>
          </div>

          {/* Campo 'Pot. raspalls': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pot. raspalls:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.BrushEnhancer !==
                valuesAfter[ramIndex]?.BrushEnhancer
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.BrushEnhancer
                : valuesAfter[ramIndex]?.BrushEnhancer}
            </div>
            <span>&plusmn;</span>
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.BrushEnhancerTolerance !==
                valuesAfter[ramIndex]?.BrushEnhancerTolerance
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.BrushEnhancerTolerance
                : valuesAfter[ramIndex]?.BrushEnhancerTolerance}
            </div>
          </div>

          {/* Campo 'Pressió Foul.': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Pressió Foul.:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.FoulardTension !==
                valuesAfter[ramIndex]?.FoulardTension
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.FoulardTension
                : valuesAfter[ramIndex]?.FoulardTension}
            </div>
            <span>&plusmn;</span>
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.FoulardTensionTolerance !==
                valuesAfter[ramIndex]?.FoulardTensionTolerance
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.FoulardTensionTolerance
                : valuesAfter[ramIndex]?.FoulardTensionTolerance}
            </div>
          </div>

          {/* Campo 'Vaporitzador': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Vaporitzador:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.HasVaporizer !==
                valuesAfter[ramIndex]?.HasVaporizer
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.HasVaporizer
                  ? 'Sí'
                  : 'No'
                : valuesAfter[ramIndex]?.HasVaporizer
                ? 'Sí'
                : 'No'}
            </div>
          </div>

          {/* Campo 'Posar drap': */}
          <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
            Posar drap:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.HasRag !==
                valuesAfter[ramIndex]?.HasRag
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.HasRag
                  ? 'Sí'
                  : 'No'
                : valuesAfter[ramIndex]?.HasRag
                ? 'Sí'
                : 'No'}
            </div>
          </div>
        </div>
      </div>

      {/* VELOCITAT (m/min) + EXTRACCIÓ */}
      <div className="grid grid-cols-1 col-span-12 gap-y-2">
        <div
          className={`flex flex-col col-span-4 bg-white border border-collapse ${
            side === 'left' ? 'border-gray-300' : 'border-blue-200'
          } rounded`}
        >
          <div
            className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
              side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
            } print:py-1 font-ms-bold`}
          >
            <span>VELOCITAT (m/min)</span>
          </div>

          <div className="grid grid-cols-12 p-3 gap-y-2">
            {/* Campo 'MIN': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-2 font-ms-semi print:mt-0">
              MIN:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
              <div
                className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                  valuesBefore[ramIndex]?.HistoryRam?.MinSpeed !==
                  valuesAfter[ramIndex]?.MinSpeed
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300'
                } overflow-ellipsis`}
              >
                {side === 'left'
                  ? valuesBefore[ramIndex]?.HistoryRam?.MinSpeed
                  : valuesAfter[ramIndex]?.MinSpeed}
              </div>
            </div>

            {/* Campo 'MAX': */}
            <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-2 font-ms-semi print:mt-0">
              MAX:
            </div>
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-10">
              <div
                className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                  valuesBefore[ramIndex]?.HistoryRam?.MaxSpeed !==
                  valuesAfter[ramIndex]?.MaxSpeed
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300'
                } overflow-ellipsis`}
              >
                {side === 'left'
                  ? valuesBefore[ramIndex]?.HistoryRam?.MaxSpeed
                  : valuesAfter[ramIndex]?.MaxSpeed}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex flex-col col-span-4 bg-white border border-collapse ${
            side === 'left' ? 'border-gray-300' : 'border-blue-200'
          } rounded`}
        >
          <div
            className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
              side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
            } print:py-1 font-ms-bold`}
          >
            <span>EXTRACCIÓ</span>
          </div>

          <div className="grid items-center grid-cols-12 p-3 gap-y-3">
            <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed">
              <div
                className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                  valuesBefore[ramIndex]?.HistoryRam?.ExtractionType !==
                  valuesAfter[ramIndex]?.ExtractionType
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300'
                } overflow-ellipsis`}
              >
                {(() => {
                  switch (
                    side === 'left'
                      ? valuesBefore[ramIndex]?.HistoryRam?.ExtractionType
                      : valuesAfter[ramIndex]?.ExtractionType
                  ) {
                    case 1:
                      return 'Automàtic'
                    case 2:
                      return 'Manual'
                    default:
                      return '-'
                  }
                })()}
              </div>
              <div
                className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                  valuesBefore[ramIndex]?.HistoryRam?.Extraction !==
                  valuesAfter[ramIndex]?.Extraction
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300'
                } overflow-ellipsis`}
              >
                {side === 'left'
                  ? valuesBefore[ramIndex]?.HistoryRam?.Extraction
                  : valuesAfter[ramIndex]?.Extraction}
              </div>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div
        className={`flex flex-col col-span-12 bg-white border border-collapse ${
          side === 'left' ? 'border-gray-300' : 'border-blue-200'
        } rounded`}
      >
        <div
          className={`flex items-center justify-between w-full px-2 py-1.5 text-sm uppercase ${
            side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
          } print:py-1 font-ms-bold`}
        >
          <span>OBSERVACIONS</span>
        </div>

        <div className="w-full p-3">
          {/* Campo 'Observació': */}
          <div className="flex items-center w-full h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
                valuesBefore[ramIndex]?.HistoryRam?.Observation !==
                valuesAfter[ramIndex]?.Observation
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300'
              } overflow-ellipsis`}
            >
              {side === 'left'
                ? valuesBefore[ramIndex]?.HistoryRam?.Observation
                : valuesAfter[ramIndex]?.Observation}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RamForm.propTypes = {
  ramNumber: PropTypes.number,
  ramIndex: PropTypes.number,
  side: PropTypes.string.isRequired,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.array,
  valuesAfter: PropTypes.array
}
