import PropTypes from 'prop-types'

export default function ClientView({ users, fields }) {
  const parentStyles = 'items-center block col-span-6 print:bg-white'
  const labelStyles =
    'block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full'
  const fieldStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerFieldStyles =
    'flex items-center w-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 min-h-8 overflow-ellipsis'

  return (
    <div className="grid w-full grid-cols-12 gap-6 rounded">
      <div className={parentStyles}>
        <div className={labelStyles}>Edició:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>{fields?.EditionNumber}</div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Codi:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>{fields?.Code}</div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Nom:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>{fields?.Name}</div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Etiquetes:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>{fields?.Tag}</div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Posició:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>{fields?.Position}</div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Especificacions Bobina:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>{fields?.Notes}</div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Bobina:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>
            {fields?.Coil ? (fields?.Coil === 'SI' ? 'Sí' : 'No') : 'No'}
          </div>
        </div>
      </div>

      <div className={parentStyles}>
        <div className={labelStyles}>Responsables:</div>
        <div className={fieldStyles}>
          <div className={innerFieldStyles}>
            {fields?.Manager?.length > 0
              ? fields?.Manager?.map((managerId, index, array) => {
                  const manager = users.find(
                    (user) => user.Id === Number(managerId)
                  )
                  const isLastItem = index === array.length - 1

                  return manager
                    ? !isLastItem
                      ? manager.Name + ', '
                      : manager.Name
                    : ''
                })
              : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

ClientView.propTypes = {
  users: PropTypes.array,
  fields: PropTypes.object
}
