import pinyons from 'assets/icons/pinyons.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function SprocketsForm({ values, machineCode }) {
  return (
    <div className="relative flex items-center w-full h-full">
      <img
        src={pinyons}
        alt="pinyons plegador"
        className="py-16 mx-auto 2xl:py-16 w-80"
      />

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-1 md:right-1 lg:top-12 lg:right-1 xl:right-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          value={values?.Piston2}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          value={values?.PistonTolerance2}
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-4 md:left-1 lg:top-16 lg:left-1 xl:left-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          value={values?.Piston1}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          value={values?.PistonTolerance1}
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-16 md:right-1 lg:top-28 lg:right-1 xl:right-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          value={values?.Piston4}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          value={values?.PistonTolerance4}
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-20 md:left-1 lg:top-32 lg:left-1 xl:left-20">
        <Field
          type="text"
          className="w-20 block-input-disabled"
          value={values?.Piston3}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          className="w-16 block-input-disabled"
          value={values?.PistonTolerance3}
          disabled
        />
      </div>

      {/* ---------------------------------- */}

      {machineCode !== 'JMB' && (
        <>
          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-28 xl:left-48 2xl:left-60"
            value={values?.Piston7}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-56 xl:left-72 2xl:left-80"
            value={values?.Piston6}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-1 xl:left-24 2xl:left-36"
            value={values?.Piston5}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-28 xl:left-48 2xl:left-60"
            value={values?.Piston8}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-8 lg:left-1 xl:left-24 2xl:left-36"
            value={values?.Piston9}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-8 lg:left-28 xl:left-48 2xl:left-60"
            value={values?.Piston10}
            disabled
          />
        </>
      )}

      {/* ---------------------------------- */}

      {machineCode === 'JMB' && (
        <>
          {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-56 xl:left-72 2xl:left-80"
            value={values?.JUM1}
            disabled
          />

          {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-24 lg:left-28 xl:left-48 2xl:left-60"
            value={values?.JUM2}
            disabled
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-28 xl:left-48 2xl:left-60"
            value={values?.JUM3}
            disabled
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            className="absolute w-20 block-input-disabled bottom-16 lg:left-1 xl:left-24 2xl:left-36"
            value={values?.JUM4}
            disabled
          />
        </>
      )}
    </div>
  )
}

SprocketsForm.propTypes = {
  values: PropTypes.object,
  machineCode: PropTypes.string
}
