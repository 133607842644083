import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import TrainingPlan from 'forms/RRHH/TrainingPlans/TrainingPlanForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

export default function TrainingPlanDetail({ params }) {
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const { setTitle } = useContext(TitleContext)
  const [getClient, setGetClient] = useFetch()
  const [client, saveClient] = useFetch()
  const { handleModal } = useContext(ModalContext)
  const [deletedDocument, deleteDocumentAction] = useFetch()
  const [deleteAction, deleteUserAction] = useFetch()
  const [documentAction, saveDocumentAction] = useFetch()
  const [saveAction, saveUserAction] = useFetch()
  const [updateAction, updateUserAction] = useFetch()
  const [addDocumentsToTable, setAddDocumentsToTable] = useState([])
  const [addUsersToTable, setAddUsersToTable] = useState([])
  const [addTreballadorsToSelectList, setAddTreballadorsToSelectList] =
    useState([])

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API:
  const [fields, setFields] = useState({
    Id: 0,
    DetailCourse: '',
    Description: '',
    Entity: '',
    Hours: 0,
    Minutes: 0,
    Price: null,
    StartDate: null,
    EndDate: null,
    CourseState: 1,
    Priority: 0,
    CourseModifiedStateDate: false,
    ForecastTraining_Id: null,
    Treballadors: []
  })

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // modificar un registro estableceremos como título de la página 'Modificar':
  const titleEdit = {
    name: `Modificar registre`,
    buttons: [
      {
        name: 'Tornar',
        link: `/seguiment-formacio`
      }
    ],
    deleteTrainingPlanBtn: true,
    trainingplanId: params.id
  }

  useEffect(() => {
    // Pondremos el título de editar:
    setTitle(titleEdit)

    // Llamamos a la API para recoger el registro que se quiere editar:
    const findClientById = {
      url: `trainingplan/${params.id}`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    setGetClient(findClientById)
  }, [])

  // useEffect() para crear un objeto con los datos que nos llegan de la API, para eso se usa el useState() [fields, setFields]:
  useEffect(() => {
    if (getClient.data) {
      if (getClient.data.TrainingPlan) {
        setFields({
          Id: getClient.data.TrainingPlan.Id,
          DetailCourse: getClient.data.TrainingPlan.DetailCourse,
          Description: getClient.data.TrainingPlan.Description,
          Entity: getClient.data.TrainingPlan.Entity,
          Hours: getClient.data.TrainingPlan.Hours,
          Minutes: getClient.data.TrainingPlan.Minutes,
          Price: getClient.data.TrainingPlan.Price,
          StartDate: getClient?.data?.TrainingPlan?.StartDate?.slice(0, 10),
          EndDate: getClient?.data?.TrainingPlan?.EndDate?.slice(0, 10),
          CourseModifiedStateDate:
            getClient?.data?.TrainingPlan?.CourseModifiedStateDate?.slice(
              0,
              10
            ),
          CourseState: getClient.data.TrainingPlan.CourseState,
          ForecastTraining_Id: getClient.data.TrainingPlan.ForecastTraining_Id,
          TrainingPlanDocuments:
            getClient.data.TrainingPlan.TrainingPlanDocuments,
          Treballadors: getClient.data.TrainingPlan.Treballadors
        })

        setAddDocumentsToTable(
          getClient.data.TrainingPlan.TrainingPlanDocuments
        )
        setAddUsersToTable(
          getClient.data.TrainingPlan.TrainingPlanUsers.reverse()
        )
      }

      if (getClient.data.Treballadors) {
        setAddTreballadorsToSelectList(getClient.data.Treballadors)
      }
    }
  }, [getClient.data])

  // Función para guardar el documento:
  const handleSubmit = async (values) => {
    values.TrainingPlanDocuments = null

    // Send PUT or POST //
    const clientApiParams = {
      url: `trainingplan/${values.Id}`,
      method: 'PUT',
      body: values,
      messageKo: 'Error al actualitzar el seguiment de formació!',
      messageOk: 'Seguiment actualitzat!'
    }
    await saveClient(clientApiParams)

    if (!client.error) setLocation('/seguiment-formacio')
  }

  // Función para añadir un nuevo documento:
  const uploadDocument = async (e) => {
    const files = e.currentTarget.files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    // Llamada a la API para añadir el nuevo archivo:
    const documentSaveToDataBase = {
      url: `trainingplan/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Document guardat!',
      messageKo: 'Error al guardar el document!'
    }
    await saveDocumentAction(documentSaveToDataBase)
  }

  // useEffect() para la función uploadDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (documentAction.data) {
      const stringToJsonParseDocuments = JSON.parse(documentAction.data)
      setAddDocumentsToTable(stringToJsonParseDocuments)
    }
  }, [documentAction.data])

  // Función para eliminar un fichero de la tabla de ficheros adjuntos:
  const deleteDocument = async (id) => {
    // Llamada a la API para eliminar el documento seleccionado:
    const deleteDocumentParams = {
      url: `trainingplan/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Document eliminat!',
      messageKo: 'Error al eliminar el document'
    }
    await deleteDocumentAction(deleteDocumentParams)

    handleModal('hide')
  }

  // useEffect() para la función deleteDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (deletedDocument.data) {
      setAddDocumentsToTable(deletedDocument.data)
    }
  }, [deletedDocument.data])

  // Función para añadir un nuevo trabajador:
  const uploadUser = async (idTreballador, idTrainingPlan) => {
    const trainingPlanUser = {
      UserId: user.Id,
      UserName: user.Name,
      ValuationState: 3,
      Comment: null,
      TrainingPlanID: idTrainingPlan,
      Treballador_Id: idTreballador
    }

    // Llamada a la API para añadir el nuevo archivo:
    const userSaveToDatabase = {
      url: `trainingplan/${params.id}/trainingPlanUser`,
      method: 'POST',
      body: trainingPlanUser,
      messageOk: 'Treballador guardat!',
      messageKo: 'Error al guardar el treballador!'
    }
    await saveUserAction(userSaveToDatabase)
  }

  // useEffect() para la función uploadUser para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (saveAction.data) {
      setAddUsersToTable(saveAction.data.TrainingPlanUsers.reverse())
      setAddTreballadorsToSelectList(saveAction.data.Treballadors)
    }
  }, [saveAction.data])

  // Función para introducir 'Valoració' y 'Comentari' a un trabajador:
  const updateUser = async (values) => {
    // Importante poner 'null' a '*Documents' sinó cada vez que se modifica un registro
    // se duplican todos los registros:
    const trainingPlanFieldsToSave = {
      UserId: user.Id,
      UserName: user.Name,
      ValuationState: values.ValuationState,
      Comment: values.Comment,
      TrainingPlanID: values.TrainingPlanID,
      Treballador_Id: values.Treballador_Id
    }

    // Llamada a la API para añadir el nuevo archivo:
    const userUpdateInDatabase = {
      url: `trainingPlanUser/${values.Id}`,
      method: 'PUT',
      body: trainingPlanFieldsToSave,
      messageOk: 'Treballador actualitzat!',
      messageKo: 'Error al actualitzar el treballador!'
    }
    await updateUserAction(userUpdateInDatabase)
  }

  // useEffect() para la función updateUser, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (updateAction.data) {
      const updateUsersTable = addUsersToTable

      updateUsersTable.forEach((user, index) => {
        if (user.Id === updateAction.data.Id) {
          updateUsersTable[index] = updateAction.data
        }
      })

      setAddUsersToTable(updateUsersTable)
    }

    if (updateAction.error) {
      setAddUsersToTable((prevUsersToTable) => prevUsersToTable)
    }
  }, [updateAction.data])

  // Función para eliminar un trabajador de la tabla de trabajadores apuntados:
  const deleteUser = async (id, trainingPlanId) => {
    // Llamada a la API para eliminar el trabajador seleccionado:
    const deleteUserParams = {
      url: `trainingplan/${params.id}/trainingPlanUser/${id}`,
      method: 'DELETE',
      messageOk: 'Treballador eliminat!',
      messageKo: 'Error al eliminar el treballador'
    }
    await deleteUserAction(deleteUserParams)

    handleModal('hide')
  }

  // useEffect() para la función deleteUser, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (deleteAction.data) {
      setAddUsersToTable(deleteAction.data.TrainingPlanUsers.reverse())
      setAddTreballadorsToSelectList(deleteAction.data.Treballadors)
    }
  }, [deleteAction.data])

  return (
    <div className="w-full p-3 bg-white">
      <TrainingPlan
        fields={fields}
        handleSubmit={handleSubmit}
        uploadDocument={uploadDocument}
        uploadUser={uploadUser}
        updateUser={updateUser}
        deleteDocument={deleteDocument}
        deleteUser={deleteUser}
        addDocumentsToTable={addDocumentsToTable}
        addUsersToTable={addUsersToTable}
        addTreballadorsToSelectList={addTreballadorsToSelectList}
        params={params}
      />
    </div>
  )
}

TrainingPlanDetail.propTypes = {
  params: PropTypes.any
}
