import Logo from 'components/ui/Logo/Logo'
import { ModalContext } from 'contexts/ModalContext'
import { useContext, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

export default function LabAccess() {
  // Context
  const { handleModal, modal } = useContext(ModalContext)

  // Var
  const currentdate = new Date()
  const datetime =
    String(currentdate.getDate()).padStart(2, '0') +
    '/' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '/' +
    currentdate.getFullYear() +
    '  -  ' +
    String(currentdate.getHours()).padStart(2, '0') +
    ':' +
    String(currentdate.getMinutes()).padStart(2, '0') +
    'h'

  // Información necesária al usar 'react-to-print':
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Análisis Laboratori de Fitxa Técnica Proto Acabats'
  })

  return (
    modal && (
      <div
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        className="inset-0 z-10 w-full overflow-x-auto overflow-y-auto"
      >
        <div className="flex items-end justify-center w-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-100"
            aria-hidden="true"
          ></div>

          <span className="sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>

          {/* A este contenedor le ponemos la referencia 'componentRef' porque es el que vamos a imprimir: */}
          <div
            ref={componentRef}
            className="inline-block w-full overflow-hidden text-left align-bottom transition-all transform bg-white rounded-sm shadow-xl sm:my-2 sm:align-middle xs:max-w-lg print:shadow-none print:border-none print:m-0 pring:p-0"
          >
            <div className="flex flex-col w-full px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4 xs:max-w-lg print:m-0 print:p-0">
              {/* HEADER & SUBHEADER */}
              <div className="border-t border-l border-r divide-y divide-gray-200 border-grey-200 divide">
                {/* HEADER */}
                <div className="grid w-full grid-cols-12 px-4 py-8 print:py-2 print:px-2">
                  <div className="self-center col-span-4 justify-self-start">
                    <Logo
                      classes="w-15 h-8 object-contain"
                      link="/"
                      color="black"
                    />
                  </div>

                  <div className="col-span-4 place-self-center">
                    ANÀLISI TEIXIT SEMIELABORAT
                  </div>

                  <div className="self-center col-span-4 justify-self-end">
                    <div className="flex flex-col items-start justify-center">
                      <span>Format-IATF-007-A/5</span>
                      <span>(11.03.22)</span>
                    </div>
                  </div>
                </div>

                {/* SUBHEADER */}
                <div className="grid w-full grid-cols-12 gap-2 p-2 print:py-1 print:px-2">
                  <div className="col-span-4 text-base font-bold text-blue-700">
                    SEMI:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    MÀQ.:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    DATA:{' '}
                    <span className="italic font-normal text-gray-600">
                      {datetime}
                    </span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    LLIGAT:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    GALGA - &Oslash;:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    Nom...:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    PROTO:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    Nº. PEÇ:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>

                  <div className="col-span-4 text-base font-bold text-blue-700">
                    FIL...:{' '}
                    <span className="italic font-normal text-gray-600"></span>
                  </div>
                </div>
              </div>

              {/* TABLE */}
              <table>
                <caption className="px-3 py-2 text-base font-bold bg-blue-200">
                  TAULA DE VALORS
                </caption>

                {/* TABLE HEADER */}
                <thead className="text-base bg-blue-100 border-l border-r border-blue-200 divide-y divide-blue-200 divide">
                  <tr className="divide-x divide-blue-200 divide">
                    <th
                      className="w-3/12 px-1 py-0 text-left print:w-2/12"
                      rowSpan={2}
                    >
                      Paràmetres
                    </th>
                    <th className="w-10/12 px-1 py-0 text-left" colSpan={7}>
                      Normes
                    </th>
                  </tr>

                  <tr className="divide-x divide-blue-200 divide">
                    <th className="w-2/12 px-1 py-0 font-normal text-center border-l border-blue-200">
                      <span>Ideal</span>
                    </th>
                    <th className="w-2/12 px-1 py-0 font-normal text-center">
                      <span>Tol. Màx.</span>
                    </th>
                    <th className="w-1/12 px-1 py-0 text-center text-blue-300"></th>
                    <th className="w-1/12 px-1 py-0 text-center text-blue-300"></th>
                    <th className="w-1/12 px-1 py-0 text-center text-blue-300"></th>
                    <th className="w-1/12 px-1 py-0 text-center text-blue-300"></th>
                    <th className="w-1/12 px-1 py-0 text-center text-blue-300"></th>
                  </tr>
                </thead>

                {/* TABLE BODY */}
                <tbody>
                  {/* PASSADES */}
                  <tr className="h-12 px-5 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Passades (u/cm)
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* COLUMNES */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Columnes (u/cm)
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* GRAMATGE */}
                  <tr className="h-12 border border-gray-300">
                    <td className="px-1 py-2 pl-1 border border-gray-300">
                      Gramatge (gr/m2)
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                      &nbsp;&nbsp;
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* DINAMÒMETRE */}
                  <tr className="h-12 border border-blue-200">
                    <th colSpan={8} className="pl-1 bg-blue-100">
                      Dinamòmetre que s&#39;usa:
                    </th>
                  </tr>

                  {/* FORÇA MÀX. (KG) L */}
                  <tr className="h-12 border border-gray-300 bg-gray-000">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Força màx. (kg) L:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* FORÇA MÀX. (KG) T */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Força màx. (kg) T:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARG. FORÇA MÀX. (%) L */}
                  <tr className="h-12 border border-gray-300 bg-gray-000">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allarg. Força màx. (%) L:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARG. FORÇA MÀX. (%) T */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allarg. Força màx. (%) T:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARGAMENT (%) 2,5 KG L */}
                  <tr className="h-12 border border-gray-300 bg-gray-000">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allargament (%) 2,5 Kg L:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARGAMENT (%) 5,0 KG L */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allargament (%) 5,0 Kg L:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARGAMENT (%) 10,0 KG L */}
                  <tr className="h-12 border border-gray-300 bg-gray-000">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allargament (%) 10,0 Kg L:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARGAMENT (%) 2,5 KG T */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allargament (%) 2,5 Kg T:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARGAMENT (%) 5,0 KG T */}
                  <tr className="h-12 border border-gray-300 bg-gray-000">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allargament (%) 5,0 Kg T:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ALLARGAMENT (%) 10,0 KG T */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Allargament (%) 10,0 Kg T:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      info
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ELONG. ESTÀTICA (%) L */}
                  <tr className="h-12 border border-gray-300 bg-gray-000">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Elong. estàtica (%) L:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      no analitzar
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      no analitzar
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* ELONG. ESTÀTICA (%) T */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Elong. estàtica (%) T:
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      no analitzar
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      no analitzar
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* AMPLE */}
                  <tr className="h-12 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Ample (cm)
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>

                  {/* GRUIX */}
                  <tr className="h-12 bg-gray-200 border border-gray-300">
                    <td className="px-0 py-2 pl-1 border border-gray-300">
                      Gruix (mm)
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300">
                      <span></span>
                      <span>&#177;</span>
                      <span></span>
                    </td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                    <td className="px-0.5 space-x-1 text-center text-sm border border-gray-300"></td>
                  </tr>
                </tbody>

                {/* TABLE FOOTER */}
                <tfoot className="px-3 text-base bg-blue-100 border border-blue-100">
                  {/* ASPECTE I DEFECTES DEL TEIXIT */}
                  <tr className="h-16 border">
                    <td className="px-0 py-2 pl-1 border border-blue-200">
                      Aspecte i defectes del teixit:
                    </td>

                    <td
                      colSpan={2}
                      className="px-1 py-2 border border-blue-200"
                    >
                      <div className="flex flex-col items-start justify-center">
                        <label className="flex flex-row items-center pb-0.5">
                          <span className="w-6 h-6 mx-1 border border-black"></span>
                          <span className="text-sm">
                            Aspecte OK i sense defectes
                          </span>
                        </label>

                        <label className="flex flex-row items-center pt-1">
                          <span className="w-6 h-6 mx-1 border border-black"></span>
                          <span className="text-sm">
                            Aspecte NOK i/o amb defectes
                          </span>
                        </label>
                      </div>
                    </td>

                    <td
                      colSpan={5}
                      className="w-px h-16 p-1 overflow-hidden align-top border border-blue-200 overflow-ellipsis"
                    >
                      <div className="h-16 overflow-hidden text-sm">
                        Comentaris:
                      </div>
                    </td>
                  </tr>

                  {/* OBSERVACIONS LABORTAORI */}
                  <tr className="h-24">
                    <th className="col-span-1 py-2 pl-1 border border-blue-200">
                      Observacions de Laboratori:
                    </th>
                    <td
                      colSpan={7}
                      className="w-px col-span-1 p-1 align-top border border-blue-200"
                    >
                      <div className="pb-24 text-sm">{'\u00A0'}</div>
                    </td>
                  </tr>
                </tfoot>
              </table>

              {/* NOTA ADVERTENCIA */}
              <p className="text-left text-red-600">
                * Encara que posi INFO s&apos;han d&apos;analitzar els
                paràmetres amb les condicions que s&apos;indiquen. No s&apos;ha
                d&apos;analitzar només en el cas que posi explícitament NO
                ANALITZAR.
              </p>

              {/* FIRMA */}
              <div className="flex flex-row items-center justify-center mt-5">
                <label className="mr-5 text-sm ">Anàlisi aprovat per:</label>
                <div className="border border-gray-300 rounded w-96 h-14"></div>
              </div>

              {/* BOTONES */}
              <div className="flex justify-end my-5 print:hidden">
                {/*  Botón de 'Imprimir': */}
                <button
                  type="button"
                  className="px-3 py-1 mr-3 text-lg bg-green-300"
                  onClick={() => handlePrint()}
                  data-dismiss="modal"
                >
                  Imprimir
                </button>

                {/* Botón de 'Cancelar': */}
                <button
                  type="button"
                  className="px-3 py-1 ml-3 text-lg bg-red-300"
                  onClick={() => handleModal()}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

LabAccess.propTypes = {}
