import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const EditProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required('Introdueixi un nom complet')
    .min(3, 'El nom és massa curt. 3 caràcters mínim'),
  email: Yup.string().email('Email invàlid').required('Introdueixi un email'),
  photo: Yup.mixed()
})

export default function EditProfileForm({ user, loading, handleSubmit }) {
  const [photo, setPhoto] = useState()

  // Check if user has a current user photo inside Context
  const currentUserPhoto = user.Photo
    ? process.env.REACT_APP_BASE_URL + user.Photo
    : null
  const [photoPreview, setPhotoPreview] = useState(currentUserPhoto)

  // Use effect to control the preview of the photo
  useEffect(() => {
    if (photo) {
      // Load FileReader
      const reader = new FileReader()
      // When FileReader has loaded
      reader.onloadend = () => {
        // Set Photo Preview
        setPhotoPreview(reader.result)
      }
      // Read data and load Photo
      reader.readAsDataURL(photo)
    }
  }, [photo])

  // console.log("USUARI: ", user)
  
  return (
    <Formik
      initialValues={{
        name: user.Name,
        email: user.Email,
        photo: null,        
      }}        
      validationSchema={EditProfileSchema}
      onSubmit={(values) => {        
        handleSubmit(values)
      }}
    >      
      {({ errors, touched, setFieldValue }) => (
        <Form>          
          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="name"
            >
              Nom complet
            </label>
            <div className="relative inline-block w-full text-gray-700">
              <Field
                type="text"
                name="name"
                autoComplete="name"
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
              />
            </div>
            {touched.name && errors.name && (
              <p className="text-red-500 text-xs italic">{errors.name}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="email"
            >

              Email
            </label>
            <div className="relative inline-block w-full text-gray-700">
              <Field
                name="email"
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
                autoComplete="email"
              />
            </div>
            {touched.email && errors.email && (
              <p className="text-red-500 text-xs italic">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="photo"
            >
              Foto
            </label>
            {photoPreview ? (
              <div>
                <img src={photoPreview} width={150} />
              </div>
            ) : (
              'No foto'
            )}
            <div className="relative inline-block w-full text-gray-700">
              <input
                type="file"
                name="photo"
                accept="image/*"
                className="appearance-none rounded w-full py-2 text-grey-darker"
                onChange={(event) => {
                  // Set photo into state to preview
                  setPhoto(event.currentTarget.files[0])
                  // Set photo to form field value
                  setFieldValue('photo', event.currentTarget.files[0])
                }}
              />
            </div>
            {touched.photo && errors.photo && (
              <p className="text-red-500 text-xs italic">{errors.photo}</p>
            )}
          </div>

          <div className="mb-4">
            <button
              type="submit"
              className="h-10 px-5 my-2 w-full text-white transition-colors duration-150 bg-primary rounded-lg focus:shadow-outline hover:bg-secondary"
              disabled={loading && 'disabled'}
            >
              Modificar perfil
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

EditProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  user: PropTypes.object
}
