// PropTypes + HOOKS
import PropTypes from 'prop-types'
import { Link } from 'wouter'

const Dropdown = ({ submenu, user, slug }) => {
  let checkSubMenuPermissions = true

  if (submenu.requiredPermissions !== null) {
    checkSubMenuPermissions = submenu.requiredPermissions.every((permission) =>
      user.Permissions.includes(permission)
    )
  }

  // Alberto Ruini permission to edit 'Preventiu Acabats' entering like an operator user. It's an exception:
  let show = false
  if (user.Role.Id === 2) {
    if (submenu.title === 'Preventiu' && submenu.slug === '/prevenacabats') {
      if (user.Role.Id === 2 && user.Id === 829) {
        show = true
      } else {
        show = false
      }
    } else {
      show = true
    }
  } else {
    show = true
  }

  return (
    checkSubMenuPermissions &&
    show && (
      <li key={slug}>
        <Link
          href={submenu.slug}
          className="flex flex-row items-center w-full p-2 rounded hover:bg-gray-900"
        >
          <img
            src={submenu.icon}
            alt="customers"
            className="w-5 h-5 mr-2 ml-9"
          />

          {/* Nombre de la página: */}
          <span className="text-gray-100">{submenu.title}</span>
        </Link>
      </li>
    )
  )
}

Dropdown.propTypes = {
  submenu: PropTypes.object,
  user: PropTypes.object,
  slug: PropTypes.string
}

export default Dropdown
