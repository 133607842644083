import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { UserContext } from 'contexts/UserContext'
import { Field, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { MdOutlineUploadFile } from 'react-icons/md'
import Select from 'react-select'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation } from 'wouter'

const VersatilityForm = ({
  handleSubmit,
  fields,
  uploadDocument,
  deleteDocument,
  addDocumentsToTable,
  params,
  isNew
}) => {
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const hiddenFileInput = useRef(null)
  const { handleModal } = useContext(ModalContext)
  const [localValuationState, setLocalValuationStates] = useState()

  // useFetch() para las llamadas a la API:
  const [apiAreaOptions, setApiAreaOptions] = useFetch()
  const [apiJobs, setApiJobs] = useFetch()
  const [apiJob, setApiJob] = useFetch()
  const [apiProcesos, setApiProcesos] = useFetch()
  const [apiTreballadors, setApiTreballadors] = useFetch()
  const [apiTreballador, setApiTreballador] = useFetch()
  const [localNivell, setLocalNivell] = useState()

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const nivells = [
    { Id: 1, Name: 'Independent' },
    { Id: 2, Name: 'Maestría' },
    { Id: 3, Name: 'Formació' }
  ]

  // El campo 'ValuationState' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'ValuationState':
  const valuationStates = [
    { Id: 1, Name: 'Correcte' },
    { Id: 2, Name: 'Incorrecte' },
    { Id: 3, Name: 'Pendent' }
  ]

  useEffect(() => {
    setLocalValuationStates(valuationStates)
  }, [])

  // Este useEffect() se hizo para recoger la información, que iria en los filtros del formulario, directamente de la base de datos:
  useEffect(async () => {
    // Llamada a la API para recoger todas las 'Areas' de la base de datos ('dbo.Areas'):
    const getApiAreaOptions = {
      url: `area`,
      method: 'GET',
      messageKo: 'Error al recuperar les Arees!'
    }

    // Llamada a la API para recoger todas las 'Lugares de trabajo' de la base de datos ('dbo.Jobs'):
    const getApiJobs = {
      url: `job`,
      method: 'GET',
      messageKo: 'Error al recuperar els Llocs de Treball!'
    }

    // Llamada a la API para recoger todos las 'Procesos' de la base de datos ('dbo.Procesos'):
    const getApiProcesos = {
      url: `procesos`,
      method: 'GET',
      messageKo: 'Error al recuperar els Procesos!'
    }

    // Llamada a la API para recoger todas los 'Trabajadores' de la base de datos ('dbo.Treballadors'):
    const getApiTreballadors = {
      url: `treballador`,
      method: 'GET',
      messageKo: 'Error al recuperar els Treballadors!'
    }

    setLocalNivell(nivells)

    // 'Promise' en este caso, se usa para hacer múltiples llamadas a la API en fila, una tras otra.
    // Mejora el tiempo de carga de la página. Si hacemos cada llamada a la API por separado,
    // provoca una carga más lenta de la página, para poner en contexto, el 'Promise' ahorra 3s de carga:
    await Promise.all([
      setApiAreaOptions(getApiAreaOptions),
      setApiJobs(getApiJobs),
      setApiProcesos(getApiProcesos),
      setApiTreballadors(getApiTreballadors)
    ]).catch((e) => {
      console.log(e)
    })
  }, [])

  // Función para recoger de la base de datos el 'Trabajador' seleccionado en la 'Select' 'Nom':
  async function getApiTreballadorById(id) {
    // Llamada a la API para recoger un 'Trabajador' por id:
    const getApiTreballador = {
      url: `treballador/${id}`,
      method: 'GET',
      messageKo: 'Error al recuperar el Treballador!'
    }
    await setApiTreballador(getApiTreballador)
  }

  // useEffect() para rellenar los demás datos de un trabajador a partir de los datos que nos llegan tras la llamada a la API:
  useEffect(() => {
    if (apiTreballador.data) {
      fields.IdTreb = apiTreballador.data.Id
      fields.TrebName = apiTreballador.data.Name
      fields.TipusTreb = apiTreballador.data.Tipu
      fields.Nau = apiTreballador.data.Nau
      fields.Ett = apiTreballador.data.Ett
      fields.Vigent = apiTreballador.data.Vigent
      fields.fixe = apiTreballador.data.fixe
      fields.Temporal = apiTreballador.data.Temporal
      fields.IdCheck = apiTreballador.data.IdCheck
      fields.ValidatFaI = apiTreballador.data.ValidFaI
      fields.IsRemoved = apiTreballador.data.IsRemoved
    }
  }, [apiTreballador.data])

  // Función para recoger de la base de datos el 'Lugar de Trabajp' seleccionado en la 'Select' 'Llod de treball':
  async function getApiJobById(id) {
    // Llamada a la API para recoger un 'Trabajador' por id:
    const getApiJob = {
      url: `job/${id}`,
      method: 'GET',
      messageKo: 'Error al recuperar el Lloc de Treball!'
    }
    await setApiJob(getApiJob)
  }

  // useEffect() para rellenar los demás datos de un lugar de trabajo a partir de los datos que nos llegan tras la llamada a la API:
  useEffect(() => {
    if (apiJob.data) {
      fields.IdLlocTreball = apiJob.data.Id
      fields.LlocTreballName = apiJob.data.Description
      fields.IdArea = apiJob.data.AreaId
      fields.AreaName = apiJob.data.Area ? apiJob.data.Area.Name : ''
      fields.IdCheck = apiJob.data.IdCheck
    }
  }, [apiJob.data])

  // Al hacer múltiples llamadas a la API, la información puede tardar en llegar, por eso ponemos estos condicionales que indican
  // que hasta que no nos lleguen todos los datos, no se muestre la tabla, porque nos puede dar problemas:
  return apiAreaOptions.data === undefined ||
    !apiAreaOptions.data ||
    apiJobs.data === undefined ||
    !apiJobs.data ||
    apiTreballadors.data === undefined ||
    !apiTreballadors.data ||
    localNivell === undefined ||
    !localNivell ? (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        // validationSchema
        // validationOnChange={false}
        // validationOnBlur={false}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <div
            id="idTabGeneral"
            className="grid grid-cols-1 mb-4 rounded-lg gap-x-4"
          >
            <div id="idForm" className="rounded-lg shadow-md print:shadow-none">
              <Form
                className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded-lg"
                id="formTreballadorEdit"
              >
                <h1 className="col-span-4 py-2 text-2xl text-center bg-blue-200 print:hidden font-ms-bold">
                  {!isNew
                    ? 'CHECKLIST FITXA DEL LLOC DE TREBALL (POLIVALÈNCIA #' +
                      values.Id +
                      ')'
                    : 'CHECKLIST FITXA DEL LLOC DE TREBALL (POLIVALÈNCIA)'}
                </h1>

                {/* Contenedor con todos los inputs: */}
                <div className="grid grid-cols-12 px-6 pt-6 mx-2 gap-x-5 gap-y-5 print:m-1 print:p-3 ">
                  {/* Información del trabajador: */}
                  <div className="col-span-12 mx-6 mt-6 mb-3">
                    <div className="relative grid items-start h-full bg-white border-2 border-blue-200 rounded-lg">
                      <div
                        className="flex flex-row items-center justify-around w-full rounded-t-md"
                        style={{ backgroundColor: '#f1f1f1' }}
                      >
                        <h4 className="h-full col-span-4 p-2 ml-2 text-lg text-center print:hidden font-ms-bold rounded-t-md">
                          Treballador
                        </h4>

                        {/* <button
                          className={`py-1 px-2 my-1 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                          onClick={() =>
                            setLocation("/treballadors/" + values.IdTreb)
                          }
                        >
                          <MdOutlineOpenInNew size={25} />
                        </button> */}
                      </div>

                      <div className="grid grid-cols-6 divide-x divide-double">
                        <div
                          className="h-full col-span-2 rounded-bl-md"
                          style={{ backgroundColor: '#f9f9f9' }}
                        >
                          <div className="flex flex-col items-center justify-around h-full p-5 divide-y divide-blue-200 divide-dashed">
                            {/* Número de trabajador: */}
                            <div
                              className={`h-full flex flex-row justify-between w-full items-center px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Nº:
                              </label>
                              <div className="flex">
                                {!isNaN(+values.IdTreb) ? values.IdTreb : ''}
                              </div>
                            </div>

                            {/* Check 'Vigente': */}
                            <div
                              className={`h-full flex flex-row justify-between w-full items-center px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Vigent:
                              </label>
                              <div className="flex rounded">
                                <input
                                  type="checkbox"
                                  // defaultValue="No"
                                  value="Sí"
                                  className="w-6 h-6 ml-1"
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked
                                      }
                                    })
                                    // setTimeout(submitForm, 0)
                                  }}
                                  name="Vigent"
                                  checked={values.Vigent}
                                  disabled
                                />
                              </div>
                            </div>

                            {/* Check 'ETT': */}
                            <div
                              className={`h-full flex flex-row justify-between w-full items-center px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                ETT:
                              </label>
                              <div className="flex rounded">
                                <input
                                  type="checkbox"
                                  defaultValue="No"
                                  className="w-6 h-6 ml-1"
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked
                                      }
                                    })
                                    // setTimeout(submitForm, 0)
                                  }}
                                  name="Ett"
                                  checked={values.Ett}
                                  disabled
                                />
                              </div>
                            </div>

                            {/* Check 'Temp': */}
                            <div
                              className={`h-full flex flex-row justify-between w-full items-center px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Temporal:
                              </label>
                              <div className="flex rounded">
                                <input
                                  type="checkbox"
                                  defaultValue="No"
                                  className="w-6 h-6 ml-1"
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked
                                      }
                                    })
                                    // setTimeout(submitForm, 0)
                                  }}
                                  name="Temporal"
                                  checked={values.Temporal}
                                  disabled
                                />
                              </div>
                            </div>

                            {/* Check 'Fix': */}
                            <div
                              className={`h-full flex flex-row justify-between w-full items-center px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Fixe:
                              </label>
                              <div className="flex rounded">
                                <input
                                  type="checkbox"
                                  defaultValue="No"
                                  className="w-6 h-6 ml-1"
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked
                                      }
                                    })
                                    // setTimeout(submitForm, 0)
                                  }}
                                  name="fixe"
                                  checked={values.fixe}
                                  disabled
                                />
                              </div>
                            </div>

                            {/* Check 'Borrat': */}
                            <div
                              className={`h-full flex flex-row justify-between w-full items-center px-2 py-4 print:bg-white ${
                                values.IsRemoved ? '' : 'hidden'
                              }`}
                            >
                              <label
                                className="block leading-4 text-red-600 font-ms-semi text-md col-start-full"
                                htmlFor="Dependence"
                              >
                                Borrat:
                              </label>
                              <div className="flex rounded">
                                <input
                                  type="checkbox"
                                  defaultValue="No"
                                  className="w-6 h-6 ml-1"
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked
                                      }
                                    })
                                    // setTimeout(submitForm, 0)
                                  }}
                                  name="IsRemoved"
                                  checked={values.IsRemoved === true}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="h-full col-span-4">
                          <div className="flex flex-col items-center justify-between h-full p-5 divide-y divide-blue-200 divide-dashed">
                            {/* Campo 'Nom': */}
                            <div
                              className={`h-full w-full items-center block px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Nom
                              </label>
                              <Select
                                className="rounded text-grey-darker"
                                onChange={(e) => {
                                  setFieldValue('IdTreb', e.Name)
                                  getApiTreballadorById(e.Id)
                                }}
                                name="IdTreb"
                                placeholder="Selecciona..."
                                options={apiTreballadors.data}
                                getOptionLabel={(o) => o.Name}
                                getOptionValue={(o) => o.Id}
                                value={apiTreballadors?.data?.find(
                                  (option) => option.Id === values.IdTreb
                                )}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: '2px solid #bfdbfe',
                                    cursor: isNew ? 'pointer' : 'default'
                                  })
                                }}
                                isDisabled={!isNew}
                              />
                            </div>

                            {/* Campo 'Tipus': */}
                            <div
                              className={`h-full w-full items-center block px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Tipus
                              </label>
                              <Field
                                className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                                text-grey print:shadow-none print:border-none print:h-auto print:py-3.5`}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                name="TipusTreb"
                                type="text"
                                disabled
                              />
                            </div>

                            {/* Campo 'Nau': */}
                            <div
                              className={`h-full w-full items-center block px-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Nau
                              </label>
                              <Field
                                // className={`pl-2 shadow rounded w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                                className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                                text-grey print:shadow-none print:border-none print:h-auto print:py-3.5`}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                name="Nau"
                                type="text"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Información del lugar de trabajo: */}
                  <div className="col-span-12 mx-6 my-3">
                    <div className="relative grid items-start h-full bg-white border-2 border-blue-200 rounded-lg">
                      <div
                        className="flex flex-row items-center justify-around w-full rounded-t-md"
                        style={{ backgroundColor: '#f1f1f1' }}
                      >
                        <h4 className="h-full col-span-4 p-2 ml-2 text-lg text-center print:hidden font-ms-bold rounded-t-md">
                          Lloc de Treball
                        </h4>
                      </div>

                      <div className="grid">
                        <div className="h-full">
                          <div className="flex flex-col items-center justify-center px-5 divide-y divide-blue-200 divide-dashed auto-rows-min">
                            {/* Campo 'Lloc de Treball': */}
                            <div
                              className={`h-fit w-full items-center block px-2 py-8 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Lloc de treball
                              </label>
                              {/* {fields.Dependence} */}
                              <Select
                                className={`rounded text-grey-darker ${
                                  errors.IdLlocTreball ? 'animate-bounce' : ''
                                }`}
                                onChange={(e) => {
                                  setFieldValue('IdLlocTreball', e.Id)
                                  getApiJobById(e.Id)
                                }}
                                name="IdLlocTreball"
                                placeholder="Selecciona..."
                                options={apiJobs.data}
                                getOptionLabel={(o) => o.Description}
                                getOptionValue={(o) => o.Id}
                                value={apiJobs?.data?.find(
                                  (option) => option.Id === values.IdLlocTreball
                                )}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: '2px solid #bfdbfe'
                                  })
                                }}
                                // isDisabled={!isNew}
                              />
                            </div>

                            {/* Campo 'Àrea': */}
                            <div
                              className={`h-fit w-full items-center block px-2 py-8 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Àrea
                              </label>
                              <Field
                                className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                                text-grey print:shadow-none print:border-none print:h-auto print:py-3.5`}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                name="AreaName"
                                type="text"
                                disabled
                              />
                            </div>

                            {/* Campo 'Procés': */}
                            <div
                              className={`h-fit w-full items-center block px-2 py-8 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Procés
                              </label>
                              <Select
                                className="rounded text-grey-darker"
                                onChange={(e) => {
                                  return setFieldValue('Idproces', e.IdProces)
                                }}
                                name="Idproces"
                                options={apiProcesos.data}
                                getOptionLabel={(o) => o.Name}
                                getOptionValue={(o) => o.IdProces}
                                value={apiProcesos?.data?.find(
                                  (option) =>
                                    option.IdProces === values.Idproces
                                )}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: '2px solid #bfdbfe'
                                  })
                                }}
                                // isDisabled={!isNew}
                              />
                            </div>

                            <div className="flex flex-col items-center justify-center w-full">
                              <div className="grid w-full grid-cols-2 gap-x-8">
                                {/* Campo 'Funcions': */}
                                <div
                                  className={`h-fit w-full items-center block px-2 pt-8 print:bg-white`}
                                >
                                  <label
                                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                    htmlFor="Dependence"
                                  >
                                    Funcions
                                  </label>
                                  <Field
                                    className={`h-40 pl-2 rounded-t w-full appearance-none pt-1.5 px-3 border-2 border-blue-200 outline-none 
                                    text-grey print:shadow-none print:border-none print:h-auto print:py-3.5`}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                    name="LlocTreballFunctions"
                                    placeholder="Introdueix..."
                                    type="text"
                                    component="textarea"
                                    disabled={!isNew}
                                    style={{
                                      backgroundColor: '#F2F2F2',
                                      color: '#999999'
                                    }}
                                  />
                                </div>

                                {/* Campo 'Responsabilitats': */}
                                <div
                                  className={`h-fit w-full items-center block px-2 py-8 print:bg-white`}
                                >
                                  <label
                                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                    htmlFor="Dependence"
                                  >
                                    Responsabilitats
                                  </label>
                                  <Field
                                    className={`h-40 pl-2 rounded-t w-full appearance-none pt-1.5 px-3 border-2 border-blue-200 outline-none 
                                    text-grey print:shadow-none print:border-none print:h-auto print:py-3.5`}
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                    name="LlocTreballResponsabilities"
                                    placeholder="Introdueix..."
                                    type="text"
                                    component="textarea"
                                    disabled={!isNew}
                                    style={{
                                      backgroundColor: '#F2F2F2',
                                      color: '#999999'
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col items-center justify-center w-full">
                              {/* Campo 'Competències': */}
                              <div
                                className={`h-full w-full items-center block px-2 py-8 mb-0 print:bg-white`}
                              >
                                <label
                                  className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                  htmlFor="Dependence"
                                >
                                  Competències
                                </label>
                                <Field
                                  className={`h-40 pl-2 rounded-t w-full appearance-none pt-1.5 pb-0 mb-0 px-3 border-2 border-blue-200 outline-none 
                                  text-grey print:shadow-none print:border-none print:h-auto print:py-3.5`}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                  name="LlocTreballCompetences"
                                  placeholder="Introdueix..."
                                  type="text"
                                  component="textarea"
                                  disabled={!isNew}
                                  style={{
                                    backgroundColor: '#F2F2F2',
                                    color: '#999999'
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Relación 'Trabajador' - 'Lugar de Trabajo': */}
                  <div className="col-span-12 mx-6 mt-3 mb-3">
                    <div className="relative grid items-start h-full bg-white border-2 border-blue-200 rounded-lg">
                      <div className="w-full">
                        <h4
                          className="h-full py-2 text-lg text-center print:hidden font-ms-bold rounded-t-md"
                          style={{ backgroundColor: '#f1f1f1' }}
                        >
                          <nobr>{values.TrebName}</nobr> &#8660;{' '}
                          <nobr>{values.LlocTreballName}</nobr>
                        </h4>
                      </div>

                      <div className="grid">
                        <div className="h-full">
                          <div className="grid h-full grid-cols-2 p-5 divide-x divide-blue-200 divide-dashed">
                            {/* Campo 'Nivell': */}
                            <div
                              className={`h-full w-full items-center block pr-12 pl-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Nivell
                              </label>
                              <Select
                                className="rounded text-grey-darker"
                                onChange={(e) => setFieldValue('Nivell', e.Id)}
                                name="Nivell"
                                placeholder="Selecciona..."
                                options={localNivell}
                                getOptionLabel={(o) => o.Name}
                                getOptionValue={(o) => o.Id}
                                value={localNivell?.find(
                                  (option) =>
                                    option.Id === parseInt(values.Nivell, 10)
                                )}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: '2px solid #bfdbfe'
                                  })
                                }}
                              />
                            </div>

                            {/* Campo 'Data inici': */}
                            <div
                              className={`h-full w-full items-center block pl-12 pr-2 py-4 print:bg-white`}
                            >
                              <label
                                className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                                htmlFor="Dependence"
                              >
                                Data d&apos;inici
                              </label>
                              <Field
                                className="pl-2 rounded w-full appearance-none py-1.5 px-3 border-2 border-blue-200 outline-none 
                                text-grey-darker print:shadow-none print:border-none print:h-auto print:py-3.5"
                                name="Datact"
                                type="date"
                                style={{
                                  paddingTop: '5px',
                                  paddingBottom: '5px'
                                }}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="preven-guardar"
                  className="flex flex-row justify-center col-span-1 mt-6 mb-9"
                >
                  <div>
                    <button
                      className="block h-10 px-4 py-1 ml-auto text-white transition duration-300 border rounded border-primary hover:border-blue-200 bg-primary font-ms-semi w-28 hover:bg-blue-200 hover:text-primary print:hidden"
                      type="submit"
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </Form>
            </div>
            {/* Fin idGeneral */}
          </div>
        )}
      </Formik>

      {/* FITXERS ADJUNTS */}
      <table
        className={`print:hidden mt-3 shadow-md bg-white w-full mr-10 border-spacing-0 border-collapse rounded-lg border-0 overflow-hidden`}
      >
        <thead>
          <tr>
            <th
              className="relative text-sm text-left text-white print:hidden font-ms-bold h-14 w-100vw bg-primary"
              colSpan={2}
            >
              <div className="flex flex-row items-center jusitfy-center">
                <div className="px-5 py-3 bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={handleClick}
                  >
                    <MdOutlineUploadFile size={25} />
                  </button>
                </div>
                <div className="flex items-center w-auto h-full ml-4">
                  <a className="text-lg">
                    FITXERS ADJUNTS (CV, Firmes, Confidencialitat, Altres....)
                  </a>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del trabajador, en el campo 'TreballadorDocuments' nos llegan
          todos los documentos que tiene adjuntos: */}
          {addDocumentsToTable?.length > 0 ? (
            addDocumentsToTable.map((doc) => (
              <tr key={doc.Id} className="border-primary-dark">
                <td>
                  <button className="block w-auto p-1 m-2 text-base rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
                    <a
                      className="text-md"
                      href={`https://apipreprod.cetriko.com/uploads/treballadors/${
                        params.id
                      }/documents/${encodeURIComponent(doc.Name)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.Name}
                    </a>
                  </button>
                </td>
                <td>
                  <button
                    className={`p-3 m-2 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteDocument}
                          element="fitxer"
                          id={doc.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat fitxers adjunts.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <input
        ref={hiddenFileInput}
        accept=".pdf, .xlsx"
        type="file"
        name="file"
        multiple="multiple"
        className="h-1/6 hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
        onChange={(e) => uploadDocument(e)}
      />
      {/* FITXERS ADJUNTS */}
    </>
  )
}

VersatilityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  uploadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  addDocumentsToTable: PropTypes.array,
  params: PropTypes.object,
  isNew: PropTypes.bool
}

export default VersatilityForm
