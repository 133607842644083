// PropTypes
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Field } from 'formik'

// REACT ICONS
import { MdArrowDropDown } from 'react-icons/md'

export default function FeaturesForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* AMPLE BOBINA */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Ample bobina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.OutputWidth"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <span>cm</span>
      </div>

      {/* RENDIMENT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Rendiment:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center text-gray-500 rounded-sm w-full h-full px-1.5 py-1 overflow-hidden text-base leading-tight bg-gray-100 border border-gray-300 overflow-ellipsis">
          {values?.Performance}
        </div>
        <span>&plusmn;</span>
        <div className="flex items-center text-gray-500 rounded-sm w-full h-full px-1.5 py-1 overflow-hidden text-base leading-tight bg-gray-100 border border-gray-300 overflow-ellipsis">
          {values?.PerformanceTolerance}
        </div>
        <span>m/Kg</span>
      </div>

      {/* GRAMATGE */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Gramatge:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 rounded-sm text-gray-500 bg-gray-100 h-full leading-tight text-base border border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.Weight}
        </div>
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.WeightTolerance"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />

        {/* LINEAL */}
        <span className="pl-5 text-sm font-ms-semi">Lineal:</span>
        <div className="w-full px-1.5 text-gray-500 py-1 rounded-sm bg-gray-100 h-full leading-tight text-base border border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.LinearWeight}
        </div>
        <span>g/m.l.</span>
      </div>

      {/* MTS/BOBINA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Mts/bobina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 rounded-sm py-1 h-full leading-tight text-base border text-gray-500 bg-gray-100 border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.Meters}
        </div>
        <span>&plusmn;</span>
        <div className="w-full px-1.5 rounded-sm py-1 h-full leading-tight text-base border text-gray-500 bg-gray-100 border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.MeterTolerance}
        </div>
      </div>

      {/* TALL AUTOMÀTIC */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Tall automàtic:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.IsAutomatedCut"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* BOB. AL REVÉS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Bob. al revés:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.IsReverseCoil"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* Nº COSTURES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Nº costures:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Seams}
        </div>
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.SeamsObservation}
        </div>
      </div>

      {/* MARCAR COSTURES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Marcar costures:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.IsSeamsMarked"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* FREQÜÈNCIA ANÀLISI */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Freqüència anàlisi:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="relative flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
          <Field
            as="select"
            name="RequestArticle.ReviewFrequenceId"
            className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
          >
            <option value="">-</option>
            <option value={1}>
              SERIES: Mínim 3 assajos complets (+ 5.000m: 1 SI 2 NO)
            </option>
            <option value={2}>Mínim 1 SI 9 NO</option>
            <option value={3}>Mínim 1 SI 6 NO</option>
            <option value={4}>Mínim 1 SI 4 NO</option>
            <option value={5}>Mínim 1 SI 5 NO</option>
            <option value={6}>Mínim 1 SI 3 NO</option>
            <option value={7}>Mínim 1 SI 2 NO</option>
            <option value={8}>
              Gramatge, gruix i ample de totes les bobines i 1 anàlisi sencer de
              cada 4 bobines
            </option>
          </Field>
          <MdArrowDropDown
            size={25}
            className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
          />
        </div>
        <Field
          type="text"
          name="RequestArticle.ReviewFrequence"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* MOSTRA LABORATORI */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Mostra laboratori:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.LabSample"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* BOBINETA CLIENT */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Bobineta client:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 rounded-sm bg-gray-100 h-full leading-tight text-base border text-gray-500 border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.ClientSmallCoil}
        </div>
      </div>

      {/* ESPECIFICACIONS BOBINA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Especificacions bobina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 rounded-sm bg-gray-100 h-full leading-tight text-base border text-gray-500 border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.ClientNotes}
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FeaturesObservation"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>
    </div>
  )
}

FeaturesForm.propTypes = {
  values: PropTypes.object
}
