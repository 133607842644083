import { useContext, useEffect, useState } from 'react'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import ListTable from 'components/Dashboard/ListTable'
import { useLocation } from 'wouter'

export default function DashboardPage() {
  const { user } = useContext(UserContext)
  const { setTitle } = useContext(TitleContext)
  const [, createNotificationAction] = useFetch()
  const [notificationsData, getNotificationsDataAction] = useFetch()
  const [notifications, setNotifications] = useState([])
  const [, setLocation] = useLocation()

  useEffect(() => {
    const currentDate = new Date()
    const hourOfTheDay = currentDate.getHours()
    const dashboardTitle = {
      name: `${hourOfTheDay < 12 ? 'Bon dia' : 'Bona tarda'}, ${user.Name}`,
      buttons: [{ name: 'Perfil', link: '/perfil' }]
    }
    setTitle(dashboardTitle)

    const notificationsAPIParams = {
      url: `notification/viewnotifications/${user.Id}`,
      method: 'GET',
      messageKo: `No s'ha pogut recuperar les notificacions.`
    }
    // Fetch api FT Acabats
    getNotificationsDataAction(notificationsAPIParams)
  }, [])

  useEffect(() => {
    if (notificationsData.data) {
      const sortedNotis = notificationsData.data.sort((x, y) => x.Read - y.Read)
      setNotifications(sortedNotis)
    }
  }, [notificationsData.data])
  const handleRead = (e, item) => {
    const changedNoti = notifications.find((noti) => noti.Id === item.Id)
    const newNoti = { ...changedNoti, Read: !changedNoti.Read }
    const otherNotis = notifications.filter((noti) => noti.Id !== item.Id)
    const sortedNotis = [...otherNotis, newNoti].sort((x, y) => x.Read - y.Read)
    setNotifications(sortedNotis)
    //* Update Read Field when Notification icon is clicked
    const notificationAPIParams = {
      url: `notification/${item.Id}`,
      method: 'PUT',
      body: { ...item, Read: !item.Read },
      messageOk: 'Notificació actualitzada',
      messageKo: "No s'ha pogut actualitzar"
    }
    createNotificationAction(notificationAPIParams)
    e.preventDefault()
  }
  const handleDelete = (e, item) => {
    setNotifications(notifications.filter((noti) => noti.Id !== item.Id))
    //* Delete notification
    const notificationAPIParams = {
      url: `notification/${item.Id}`,
      method: 'DELETE',
      messageOk: 'Notificació esborrada',
      messageKo: 'Error al esborrar'
    }
    createNotificationAction(notificationAPIParams)
    e.preventDefault()
  }

  const handleClick = (e, item) => {
    e.preventDefault()

    setLocation(item.Dashboard_Link)
  }

  return (
    notifications?.length > 0 && (
      <div className="flex flex-col w-full p-4 bg-white">
        <ListTable
          handleClick={handleClick}
          handleDelete={handleDelete}
          handleRead={handleRead}
          items={notifications}
        />
      </div>
    )
  )
}
