// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useState, useEffect, useContext, useMemo } from 'react'

// COMPONENTS
import Table from 'components/ui/Table/Table'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

// CONTEXTS
import { UserContext } from 'contexts/UserContext'

// ASSETS
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

export default function ListTable({ items, handleClick }) {
  const { user } = useContext(UserContext)
  const [getTableData, setTableData] = useState([])

  // Columnas
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Article',
        accessor: 'FTAcabats_Description',
        Filter: ColumnFilter
      },    
      {
        Header: 'Client',
        accessor: 'FTAcabats_Client',
        Filter: ColumnFilter
      },
      {
        Header: 'Ample',
        accessor: 'FTAcabats_Width',
        Filter: ColumnFilter
      },
      {
        Header: 'Semielaborat',
        accessor: 'FTAcabats_SemiFinished',
        Filter: ColumnFilter
      },
      {
        Header: 'Estat',
        accessor: 'FTAcabats_Status',
        Filter: ColumnFilter,
        Cell: ({ value }) =>
          value === 'A' ? (
            <span className="2xl:ml-6 px-2 py-1  font-ms-bold rounded-full bg-indigo-200 text-charcoal">
              A
            </span>
          ) : (
            <span className="2xl:ml-6 px-2 py-1 font-ms-bold rounded-full bg-yellow-200 text-yellow-700">
              P
            </span>
          )
      },
      {
        Header: 'Amagada',
        accessor: 'FTAcabats_IsOperatorHidden',
        Filter: ColumnFilter,
        Cell: ({ value }) =>
          value ? (
            <div className="flex justify-center items-center">
              <p className="flex justify-center items-center text-lg font-bold w-6 h-6 
                border border-green-200 bg-green-200 rounded-full">
                  <AiOutlineCheck color="green" size={14}
                  />
              </p>
            </div> 
          ) : (
            <div className="flex justify-center items-center">
                <p className="flex justify-center items-center text-lg font-bold w-6 h-6 
                  border border-red-200 bg-red-200 rounded-full">
                  <AiOutlineClose color="#be0043" size={14} />
              </p>
            </div> 
          )
      }  
    ], []
  )

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          FTAcabats_ArticleCode:      item.articleCode,
          FTAcabats_Description:      item.description,
          FTAcabats_Client:           item.client,
          FTAcabats_Width:            item.width,
          FTAcabats_SemiFinished:     item.semiFinished,
          FTAcabats_Status:           item.status === 'A' ? 'A' : 'P',
          FTAcabats_Image:            "",
          FTAcabats_IsOperatorHidden: item.isOperatorHidden
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return getTableData.length > 0 && (
    <Table
      tableData={getTableData}
      tableColumns={
        user.Role.Id === 1
          ? tableColumns
          : tableColumns.slice(0, tableColumns.length - 1)
      }
      handleClick={handleClick}
      isFinishedTS={true}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
