import Form1 from 'forms/clientsrequest/clientCompare'
import PropTypes from 'prop-types'

export default function Block({
  title,
  className,
  side,
  isAnswered,
  users,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md ${
        side === 'left' ? 'border-gray-300' : 'border-blue-200'
      }`}
    >
      <div
        className={`flex items-center justify-between w-full px-2 py-2 text-sm uppercase ${
          side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
        } print:py-1 font-ms-bold`}
      >
        <span>{title}</span>
      </div>

      <Form1
        side={side}
        isAnswered={isAnswered}
        users={users}
        valuesBefore={valuesBefore}
        valuesAfter={valuesAfter}
      />
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  isAnswered: PropTypes.bool,
  users: PropTypes.array,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
