import { Field, FieldArray } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { BiPlusMedical } from 'react-icons/bi'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import Select from 'react-select'

export default function GameTable({ threadOptions, setFieldValue, allValues }) {
  const [action, setAction] = useState()

  useEffect(() => {
    if (action === 'delete') {
      updateSheet()
      setAction(null)
    }
  }, [allValues?.RequestMachineGroups])

  // Método para actualizar una máquina individual:
  const updateMachine = (currentMachineGroup, index) => {
    // Recogemos el número de 'Agujas':
    const needles = getNumber(allValues?.RequestArticle?.Needle || '0')
    setFieldValue('RequestArticle.Needle', needles.toString())

    // Recogemos y guardamos 'RPM' por si está en un formato incorrecto que afecte los cálculos.
    const rpmValue = allValues?.RequestArticle?.Rpm || '0'
    const rpm = parseFloat(rpmValue?.toString()?.replace(/,/g, '.') ?? 0)
    const formattedRpm = rpm % 1 === 0 ? rpm.toFixed(0) : rpm.toFixed(1)
    setFieldValue('RequestArticle.Rpm', formattedRpm)

    // Calculation LFA
    const LFA =
      currentMachineGroup?.Spending && needles
        ? roundToDecimals((currentMachineGroup.Spending / needles) * 100, 2)
        : 0

    // Recogemos los campos 'Title' y 'Heads'
    const numCabos = currentMachineGroup?.Heads || 0
    const numTitle = getNumber(currentMachineGroup?.Title || 0)

    // Comprobamos la unidad de medida de la máquina (TEX, DTEX, DNS, NM, NE, NA o NB):
    const measureUnitTitle =
      currentMachineGroup?.Title?.split(' ').pop()?.toUpperCase() || ''

    // Fórmulas según Excel: Cálculo producciones (Tickets relacionados: #29473, #33208, #89001).
    // - Más información sobre las fórmulas y las conversiones en el Jedi: https://jedi.gestinet.com/books/cetriko/page/formules-de-produccio-fitxes-tecniques-teixiduria
    const conversionMap = {
      TEX: numTitle * numCabos,
      DTEX: (numTitle * numCabos) / 10,
      DNS: (numTitle * numCabos) / 9,
      NM: 1000 / (numTitle / numCabos),
      NE: (453.6 / 768) * (1000 / (numTitle / numCabos)),
      NA: (440 / 777.5) * (1000 / (numTitle / numCabos)),
      NB: (8.33 / 777.5) * (1000 / (numTitle / numCabos))
    }

    const convertToTex = roundToDecimals(
      conversionMap[measureUnitTitle?.toUpperCase()] || 0,
      10
    )

    // Ticket relacionat: #33208
    // Función nueva para calcular Kg/h y redondeamos a 10 decimales:
    const updateKgH =
      rpm !== 0
        ? roundToDecimals(
            (convertToTex *
              currentMachineGroup?.Spending *
              rpm *
              60 *
              currentMachineGroup?.Cones) /
              100000000,
            10
          )
        : 0

    currentMachineGroup.KgH = updateKgH
    currentMachineGroup.LFA = LFA

    allValues.RequestMachineGroups[index] = currentMachineGroup
    setFieldValue(
      'RequestArticle.RequestMachineGroups',
      allValues.RequestMachineGroups
    )

    updateSheet()
  }

  // Método para actualizar campos de la ficha técnica:
  const updateSheet = () => {
    // #################################################################################################
    // Inicio cálculos de 'Vueltas'
    // #################################################################################################

    // Recogemos todas las máquinas relacionadas con el 'id' especificado y sumamos los 'Kg' por 'Hora'
    // de todas las máquinas que se devuelvan, juntas:
    let sumKgH100 = 0
    allValues?.RequestMachineGroups?.forEach((machine, index) => {
      if (!machine.HasBeenRemoved) {
        // Redondeamos a 10 para que todos los cálculos se hagan con 10 decimales:
        const roundedMachineGroupKgH = roundToDecimals(machine.KgH, 10)
        sumKgH100 = roundToDecimals(sumKgH100 + roundedMachineGroupKgH, 10)
      }
    })

    // Recogemos y guardamos 'RPM' por si está en un formato incorrecto que afecte los cálculos:
    const rpmValue = allValues?.RequestArticle?.Rpm || '0'
    const rpm = parseFloat(rpmValue?.toString()?.replace(/,/g, '.') ?? 0)
    const formattedRpm = rpm % 1 === 0 ? rpm.toFixed(0) : rpm.toFixed(1)
    setFieldValue('RequestArticle.Rpm', formattedRpm)

    // Recogemos y guardamos 'KgPart' por si está en un formato incorrecto que afecte los cálculos:
    const partKgValue = allValues?.RequestArticle?.KgPart || '0'
    const partKg = roundToDecimals(parseFloat(partKgValue).toFixed(1), 1)
    setFieldValue('RequestArticle.KgPart', partKg)

    // Si sumKgH100 > 0, indica que hay máquinas especificadas en la tabla de 'Graduació del punt i consum', y que los campos 'RPM' y 'Kg peça' en 'Descripció' están definidos y son mayores que 0:
    if (sumKgH100 > 0) {
      // Convertimos la suma de 'Kg' por 'Hora' de las máquinas que se nos devuelve de la Base de Datos
      // a un número con 2 decimales y otro con 1 decimal:
      const roundedSumKgH10Decimals = roundToDecimals(sumKgH100, 10)

      if (rpm !== 0) {
        // Total 'Vueltas' por 'Kg' y por 'Pieza'
        const turnsPerKg = roundToDecimals(
          (rpm * 60) / roundedSumKgH10Decimals,
          10
        )
        const turnsPerPart = roundToDecimals(turnsPerKg * partKg, 10)

        setFieldValue(
          'RequestArticle.TurnsPerKg',
          roundToDecimals(
            Number(turnsPerKg?.toString().replace(/,/g, '.') ?? 0),
            0
          )
        )
        setFieldValue(
          'RequestArticle.TurnsPerPart',
          roundToDecimals(
            Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0),
            0
          )
        )
        setFieldValue(
          'RequestArticle.TurnsPerPartByOperator',
          roundToDecimals(
            Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0),
            0
          )
        )
      } else {
        setFieldValue('RequestArticle.TurnsPerKg', '0')
        setFieldValue('RequestArticle.TurnsPerPart', '0')
        setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
      }

      // #################################################################################################
      // Fin cálculos de 'Vueltas'
      // #################################################################################################

      // #################################################################################################
      // Inicio cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
      // #################################################################################################

      // Total 'Kg' por 'Hora' cuando la máquina está al 100% y redondeamos a 10 decimales:
      const totalKgH100 = roundToDecimals(roundedSumKgH10Decimals, 2)

      // Total 'Kg' por 'Hora' cuando la máquina está al 80% y redondeamos a 10 decimales:
      const totalKgH80 = roundToDecimals(roundedSumKgH10Decimals * 0.8, 2)

      // Total 'Kg' por 'Dia' cuando la máquina está al 100% y redondeamos a 10 decimales:
      const totalKgD100 = roundToDecimals(roundedSumKgH10Decimals * 24, 2)

      // Total 'Kg' por 'Dia' cuando la máquina está al 80% y redondeamos a 10 decimales:
      const totalKgD80 = roundToDecimals(roundedSumKgH10Decimals * 24 * 0.8, 2)

      setFieldValue('RequestArticle.KgH_100', totalKgH100.toString())
      setFieldValue('RequestArticle.KgH_80', totalKgH80.toString())
      setFieldValue('RequestArticle.KgD_100', totalKgD100.toString())
      setFieldValue('RequestArticle.KgD_80', totalKgD80.toString())

      // #################################################################################################
      // Fin cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
      // #################################################################################################
    }
    // Si sumKgH100 <= 0, indica que no hay máquinas en 'Graduació del punt i consum' o que los campos 'RPM' o 'Kg peça' en 'Descripció' no están definidos o son 0.
    else {
      setFieldValue('RequestArticle.TurnsPerKg', '0')
      setFieldValue('RequestArticle.TurnsPerPart', '0')
      setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
      setFieldValue('RequestArticle.KgH_100', '0')
      setFieldValue('RequestArticle.KgH_80', '0')
      setFieldValue('RequestArticle.KgD_100', '0')
      setFieldValue('RequestArticle.KgD_80', '0')
    }
  }

  const getNumber = (text) => {
    let b = '' // Initialize an empty string to collect digits
    let val = 0 // Initialize the return value to 0

    // Loop through each character in the input text
    for (let i = 0; i < text?.length; i++) {
      // Check if the character is a digit
      if (/\d/.test(text[i])) {
        b += text[i] // Append digit to string `b`
      }
    }

    // If `b` contains any digits, parse it to an integer
    if (b.length > 0) {
      val = parseInt(b, 10)
    }

    return val // Return the parsed number
  }

  const roundToDecimals = (number, decimals) => {
    const factor = Math.pow(10, decimals)
    return Math.round(number * factor) / factor
  }

  return (
    <table className="relative w-full px-4 border table-fixed border-primary">
      <FieldArray
        name="RequestMachineGroups"
        render={(arrayHelpers) => (
          <>
            <thead className="w-full text-white bg-primary-dark">
              <tr className="w-full border-b divide-x divide-primary divide-solid border-primary">
                <th className="w-1/12 overflow-hidden text-sm truncate">Joc</th>
                <th className="w-6/12 overflow-hidden text-sm truncate">
                  Matèria
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  Caps
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  Cons
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  Tipus
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  Consum
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  Tol.
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  Tensió
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  NonC
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate">
                  NonP
                </th>
                <th className="w-1/12 overflow-hidden text-sm truncate py-1.5">
                  Kg/h
                </th>

                <th className="w-1/12 overflow-hidden text-sm truncate">
                  <div className="flex items-center justify-center w-full tooltip">
                    <button
                      type="button"
                      className="p-2 py-1 text-sm transition-colors duration-150 rounded-sm appearance-none cursor-pointer font-ms-semi hover:bg-white hover:text-primary focus:shadow-outline"
                      onClick={() =>
                        arrayHelpers.push({
                          RequestMachineGroupId: 0,
                          TSWeavingID: 0,
                          IdThread: 0,
                          KgH: 0,
                          LFA: 0,
                          IntMachineGroup: 0,
                          Cones: 0,
                          NoniusC: 0,
                          NoniusP: 0,
                          Spending: 0,
                          Heads: 1,
                          TolSpending: '7',
                          Pressure: '3 - 10',
                          ThreadMatter: null,
                          Size: 'LFA',
                          Game: '0',
                          GameNumber:
                            allValues?.RequestMachineGroups?.length > 0
                              ? String(
                                  Number(
                                    allValues?.RequestMachineGroups[
                                      allValues?.RequestMachineGroups?.length -
                                        1
                                    ]?.GameNumber
                                  ) + 1
                                )
                              : '1',
                          Title: null,
                          HasBeenRemoved: 0,
                          HistoryMachineGroup: null
                        })
                      }
                    >
                      <BiPlusMedical size={20} />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className="w-full divide-y divide-primary divide-solid">
              {allValues?.RequestMachineGroups?.length > 0 &&
                allValues?.RequestMachineGroups.map((game, index) => (
                  <tr
                    className={`${
                      game?.HasBeenRemoved && 'hidden'
                    } w-full h-16 divide-x divide-primary divide-solid`}
                    key={index}
                  >
                    {/* Campo 'Joc': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].Game`}
                          className="w-full table-input"
                        />
                      </div>
                    </td>

                    {/* Campo 'Matèria': */}
                    <td className="w-6/12 py-1 text-xs">
                      <div className="flex items-center">
                        {!threadOptions ? (
                          <div className="w-full px-2 py-0.5 bg-gray-100 border border-gray-300 rounded-sm">
                            <CgSpinner
                              size={20}
                              className="text-charcoal animate-spin"
                            />
                          </div>
                        ) : (
                          <Select
                            className="w-full text-xs leading-tight"
                            onChange={(e) =>
                              arrayHelpers.replace(index, {
                                ...game,
                                IdThread: e.ThreadId,
                                ThreadMatter: e.ThreadMatter,
                                Title: e.ThreadTitle
                              })
                            }
                            onBlur={() => {
                              setAction('update')
                              updateMachine(game, index)
                            }}
                            placeholder="Seleccionar..."
                            options={threadOptions}
                            getOptionLabel={(o) => o.ThreadMatter}
                            getOptionValue={(o) => o.ThreadId}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: '0.125rem',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                minHeight: 'auto',
                                lineHeight: '20px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: state.isFocused
                                  ? '#9CA3AF'
                                  : '#D1D5DB',
                                '&:hover': {
                                  borderColor: '#9CA3AF'
                                }
                              }),
                              valueContainer: (baseStyles) => ({
                                ...baseStyles,
                                height: '100%',
                                padding: '0.125rem',
                                position: 'relative',
                                cursor: 'pointer'
                              }),
                              input: (baseStyles) => ({
                                ...baseStyles,
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                margin: '0',
                                paddingTop: '0',
                                paddingBottom: '0',
                                cursor: 'pointer'
                              }),
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                marginLeft: '0',
                                marginRight: '0'
                              }),
                              indicatorsContainer: (baseStyles) => ({
                                ...baseStyles,
                                cursor: 'pointer'
                              }),
                              dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                padding: '0px 8px'
                              })
                            }}
                            value={threadOptions.find((option) => {
                              return (
                                option.ThreadMatter === game.ThreadMatter &&
                                option.ThreadId === game.IdThread
                              )
                            })}
                          />
                        )}
                      </div>
                    </td>

                    {/* Campo 'Caps': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].Heads`}
                          className="w-full table-input"
                          onBlur={() => {
                            setAction('update')
                            updateMachine(game, index)
                          }}
                        />
                      </div>
                    </td>

                    {/* Campo 'Cons': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].Cones`}
                          className="w-full px-0.5 py-1 border border-gray-300 rounded-sm outline-none appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline truncate"
                          onBlur={() => {
                            setAction('update')
                            updateMachine(game, index)
                          }}
                        />
                      </div>
                    </td>

                    {/* Campo 'Tipus': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center">
                        <Field
                          as="select"
                          type="text"
                          name={`RequestMachineGroups[${index}].Size`}
                          className="w-full table-input"
                        >
                          <option value="LFA">LFA</option>
                          <option value="W">W</option>
                          <option value="MRA">MRA</option>
                          <option value="SCH">SCH</option>
                        </Field>
                      </div>
                    </td>

                    {/* Campo 'Consum': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].Spending`}
                          className="w-full table-input"
                          onBlur={() => {
                            setAction('update')
                            updateMachine(game, index)
                          }}
                        />
                      </div>
                    </td>

                    {/* Campo 'Tol.': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex flex-row items-center">
                        <span>&plusmn;</span>
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].TolSpending`}
                          className="w-full table-input"
                        />
                      </div>
                    </td>

                    {/* Campo 'Tensió': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center justify-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].Pressure`}
                          className="w-full table-input"
                        />
                      </div>
                    </td>

                    {/* Campo 'NonC': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center justify-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].NoniusC`}
                          className="w-full table-input"
                        />
                      </div>
                    </td>

                    {/* Campo 'NonP': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center justify-center">
                        <Field
                          type="text"
                          name={`RequestMachineGroups[${index}].NoniusP`}
                          className="w-full table-input"
                        />
                      </div>
                    </td>

                    {/* Campo 'Kg/H': */}
                    <td className="w-1/12 text-xs">
                      <div className="flex items-center">
                        <Field
                          type="text"
                          className="w-full table-input-disabled"
                          value={allValues?.RequestMachineGroups[index]?.KgH}
                          disabled
                        />
                      </div>
                    </td>

                    {/* Botón eliminar: */}
                    <td className="w-1/12">
                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          className="text-black bg-white rounded-sm cursor-pointer focus:shadow-outline hover:border-gray-400 focus:border-gray-400 focus:outline-none"
                          onClick={() => {
                            setAction('delete')

                            if (game?.HistoryMachineGroup === null) {
                              arrayHelpers.remove(index)
                            } else {
                              arrayHelpers.replace(index, {
                                ...game,
                                HasBeenRemoved: 1
                              })
                            }
                          }}
                        >
                          <FaTrash size={15} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </>
        )}
      />
    </table>
  )
}

GameTable.propTypes = {
  threadOptions: PropTypes.any,
  setFieldValue: PropTypes.func,
  allValues: PropTypes.object,
  validateForm: PropTypes.func
}
