import pinyons from 'assets/icons/pinyons.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function SprocketsForm({ machineCode }) {
  return (
    <div className="relative flex items-center w-full h-full">
      <img
        src={pinyons}
        alt="pinyons plegador"
        className="py-16 mx-auto 2xl:py-16 w-80"
      />

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-1 md:right-1 lg:top-12 lg:right-1 xl:right-20">
        <Field
          type="text"
          name="RequestArticle.Piston2"
          className="w-20 block-input"
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          name="RequestArticle.PistonTolerance2"
          className="w-16 block-input"
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-4 md:left-1 lg:top-16 lg:left-1 xl:left-20">
        <Field
          type="text"
          name="RequestArticle.Piston1"
          className="w-20 block-input"
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          name="RequestArticle.PistonTolerance1"
          className="w-16 block-input"
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-16 md:right-1 lg:top-28 lg:right-1 xl:right-20">
        <Field
          type="text"
          name="RequestArticle.Piston4"
          className="w-20 block-input"
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          name="RequestArticle.PistonTolerance4"
          className="w-16 block-input"
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-20 md:left-1 lg:top-32 lg:left-1 xl:left-20">
        <Field
          type="text"
          name="RequestArticle.Piston3"
          className="w-20 block-input"
        />

        <span className="px-1">&plusmn;</span>

        <Field
          type="text"
          name="RequestArticle.PistonTolerance3"
          className="w-16 block-input"
        />
      </div>

      {/* ---------------------------------- */}

      {machineCode !== 'JMB' && (
        <>
          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.Piston7"
            className="absolute w-20 block-input bottom-24 lg:left-28 xl:left-48 2xl:left-60"
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.Piston6"
            className="absolute w-20 block-input bottom-24 lg:left-56 xl:left-72 2xl:left-80"
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.Piston5"
            className="absolute w-20 block-input bottom-16 lg:left-1 xl:left-24 2xl:left-36"
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.Piston8"
            className="absolute w-20 block-input bottom-16 lg:left-28 xl:left-48 2xl:left-60"
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.Piston9"
            className="absolute w-20 block-input bottom-8 lg:left-1 xl:left-24 2xl:left-36"
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.Piston10"
            className="absolute w-20 block-input bottom-8 lg:left-28 xl:left-48 2xl:left-60"
          />
        </>
      )}

      {/* ---------------------------------- */}

      {machineCode === 'JMB' && (
        <>
          {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.JUM1"
            className="absolute w-20 block-input bottom-24 lg:left-56 xl:left-72 2xl:left-80"
          />

          {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.JUM2"
            className="absolute w-20 block-input bottom-24 lg:left-28 xl:left-48 2xl:left-60"
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.JUM3"
            className="absolute w-20 block-input bottom-16 lg:left-28 xl:left-48 2xl:left-60"
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <Field
            type="text"
            name="RequestArticle.JUM4"
            className="absolute w-20 block-input bottom-16 lg:left-1 xl:left-24 2xl:left-36"
          />
        </>
      )}
    </div>
  )
}

SprocketsForm.propTypes = {
  machineCode: PropTypes.string
}
