import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import PrevenTeixForm from 'forms/preventive/PrevenTeixForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'
import { FaTrash } from 'react-icons/fa'

export default function PrevenTeixDetails({ params }) {
  const machineOptions = []
  const userOptions = []
  const { handleModal } = useContext(ModalContext)
  const [, setLocation] = useLocation()
  const [type, setType] = useState('')

  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')
  // currentdate.getFullYear() +
  // '  ' +
  // String(currentdate.getHours()).padStart(2, '0') +
  // ':' +
  // String(currentdate.getMinutes()).padStart(2, '0') +
  // 'h'

  const [fields, setFields] = useState({ NoticeDate: datetime })

  // const [fields, setFields] = useState()
  const { setTitle } = useContext(TitleContext)
  const [machineList, machineListAction] = useFetch()
  const [fetchedPrevenData, fetchPrevenDataAction] = useFetch()

  // const [fetchedHistoric, fetchHistoricAction] = useFetch()
  const [fetchedCorrective, fetchCorrectiveAction] = useFetch()

  const [savedRecord, fetchSaveRecord] = useFetch()

  const [historicOptions, setHistoric] = useState([])
  const [correctiveOptions, setCorrective] = useState([])

  const [allowCreateWork, setAllowCreateWork] = useState(true)
  const [works, setWorks] = useState([])
  const [, deleteWorkAction] = useFetch()
  const [workId, setWorkId] = useState()
  const [saveWork, saveWorkAction] = useFetch()

  const [, deleteRecordAction] = useFetch()
  const [, editCorrectiveAction] = useFetch()
  const [, editRecordAction] = useFetch()
  const [recordAction, getRecordAction] = useFetch()

  const [userList, userListAction] = useFetch()

  const isNew = params.id === 'nou'

  // Attachs
  const [documentsArray, setDocumentsArray] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [, deleteDocumentAction] = useFetch()
  const [documentAction, saveDocumentAction] = useFetch()
  // Attachs

  useEffect(() => {
    const machineListAPIParams = {
      url: `tsweaving/machines`,
      method: 'GET',
      messageKo: 'Error al recuperar màquines del ECAP'
    }
    machineListAction(machineListAPIParams)

    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar Users del ECAP'
    }
    userListAction(userListAPIParams)

    isNew ? newPreventive() : updatePreventive()
    if (!isNew) {
      const fetchAPIParams = {
        url: `preventiveweaving/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      fetchPrevenDataAction(fetchAPIParams)
    }
  }, [])

  useEffect(() => {
    if (machineList.data) {
      machineList.data.forEach((machine) => {
        machineOptions.push({
          value: machine.cod_maq,
          // label: machine.cod_maq + '-' + machine.desc_sec
          label: machine.desc_sec
        })
      })
      // console.log('PrevenTeixDetails - machineOptions: ',machineOptions)
    }
  }, [machineList])

  useEffect(() => {
    if (userList.data) {
      userList.data.forEach((user) => {
        userOptions.push({
          value: user.Id,
          // label: user.Id + '-' + user.Name
          label: user.Name
        })
      })
    }
  }, [userList])

  useEffect(() => {
    if (fetchedPrevenData?.data) {
      // console.log('fetchedPrevenData.data: ',fetchedPrevenData.data)
      setFields({
        Comment: fetchedPrevenData?.data.Comment,
        Description: fetchedPrevenData?.data.Description,
        Frequency: fetchedPrevenData?.data.Frequency,
        Id: fetchedPrevenData?.data.Id,
        IsCritical: fetchedPrevenData?.data.IsCritical,
        Level: fetchedPrevenData?.data.Level,
        MachineId: fetchedPrevenData?.data.MachineId,
        MachineName: fetchedPrevenData?.data.MachineName,
        MonthFrequency: fetchedPrevenData?.data.MonthFrequency,
        NoticeDate: fetchedPrevenData?.data.NoticeDate.slice(0, 10),
        LastModificationDate: fetchedPrevenData?.data.LastModificationDate,
        Record: fetchedPrevenData?.data.Record,
        Shed: fetchedPrevenData?.data.Shed,
        UserResponsableId: fetchedPrevenData?.data.UserResponsableId,
        UserResponsableName: fetchedPrevenData?.data.UserResponsableName,
        Works: fetchedPrevenData?.data.PreventiveWeavingWorks
      })
      setWorks(fetchedPrevenData.data.PreventiveWeavingWorks)

      // console.log("Fields: ", fetchedPrevenData)
      // console.log("Params: ", params)

      // // Corrective maquina
      const correctiveAPIParams = {
        url: `preventiveweaving/${fetchedPrevenData.data.MachineId}/maintenance`,
        method: 'GET',
        messageKo: 'Error al recuperar Correctius del ECAP'
      }
      fetchCorrectiveAction(correctiveAPIParams)

      // console.log("Maintenance: ", fetchCorrectiveAction)
      // // Corrective maquina

      fetchedPrevenData.data.PreventiveWeavingRecords?.forEach((historic) => {
        historicOptions.push({
          Id: historic.Id,
          IsPending: historic.IsPending,
          PreventiveDate: historic.PreventiveDate.slice(0, 10),
          RealDate: historic.RealDate.slice(0, 10),
          PreventiveTime: historic.PreventiveTime,
          PreventiveWeavingId: historic.PreventiveWeavingId,
          Observations: historic.Observations
        })
        setHistoric(historicOptions)
      })

      // }
      if (!fetchedPrevenData.data) {
        setFields({
          Comment: '',
          Description: '',
          Frequency: '',
          Id: '',
          IsCritical: '',
          Level: '',
          MachineId: '',
          MachineName: '',
          MonthFrequency: '',
          NoticeDate: datetime.slice(0, 10),
          LastModificationDate: null,
          Record: '',
          Shed: '',
          UserResponsableId: '',
          UserResponsableName: '',
          Works: ''
        })
      }
    }
    // Attachs
    fetchedPrevenData.data &&
      setDocumentsArray(fetchedPrevenData.data.PreventiveWeavingDocuments)
    // Attachs
  }, [fetchedPrevenData.data])

  useEffect(() => {
    if (fetchedCorrective.data) {
      setCorrective(fetchedCorrective.data)
    }
  }, [fetchedCorrective])

  useEffect(() => {
    // console.log("enter useEffect Bueno 8");
  }, [recordAction])
  // console.log("FetchedCorrectiveData: ", fetchedCorrective.data)
  // // console.log("SetCorrective: ", setCorrective)
  // console.log("CorrectiveOptions: ",correctiveOptions)

  const newPreventive = () => {
    const title = {
      name: `Crear Actuació Preventiva Teixiduria`,
      buttons: [
        {
          name: 'Tornar',
          link: '/preventeix'
        }
      ]
    }
    setTitle(title)
  }
  const handleClick = (name) => {
    setType(name)
    handleModal('hide')
  }
  const updatePreventive = () => {
    const title = {
      // name: 'Editar Actuació Preventiva Teixiduria',
      name: 'Preventiu Teixiduria',
      buttons: [
        {
          name: 'Tornar',
          link: '/preventeix'
        }
      ],
      // Boton clonar
      clonePreventiveTeixBtn: true,
      // Boton clonar

      btnPTdel: true,
      codPTid: params.id,
      button: 'print'
    }
    setTitle(title)
  }
  const handleSubmit = async (values) => {
    // Attachs
    values.PreventiveWeavingDocuments = null
    // Attachs

    // console.log('handleSubmit a PrevenTeixDetails!! params.id y values: ',params.id + ' ' + values)

    let url = `preventiveweaving`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    } else {
      values.Department = type
    }

    values.LastModificationDate = datetime.slice(0, 10)

    const preventiveApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Act.Preventiva actualitzada!'
    }
    if (params.id === 'nou')
      preventiveApiParams.messageOk = 'Act.Preventiva creada!'
    await fetchPrevenDataAction(preventiveApiParams)
    if (!fetchedPrevenData.error) setLocation('/preventeix')
  }

  // Attachs
  const renderTableData = () => {
    if (documentsArray?.length > 0)
      return documentsArray.map((document) => {
        return (
          <td key={document.Id} className="border border-blue-400">
            <button className="block w-auto p-1 m-2 text-sm rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
              <a
                href={`https://apipreprod.cetriko.com/uploads/preventiveweaving/${
                  params.id
                }/documents/${encodeURIComponent(document.Name)}`}
                target="_blank"
                rel="noreferrer"
              >
                {document.Name}
              </a>
            </button>
            <button
              className={`m-2 mx-auto p-2 rounded-lg w-auto block font-medium text-sm     focus:shadow-outline hover:bg-gray-300 `}
              onClick={() =>
                handleModal(
                  <DeleteModal
                    deleteUserOrFT={deleteDocument}
                    element="fitxer"
                    id={document.Id}
                    closeModal={() => handleModal('hide')}
                  />
                )
              }
            >
              <FaTrash size={17} />
            </button>
          </td>
        )
      })
  }

  const findDeletedDocument = (id) => {
    const newDocumentsArray = documentsArray.filter(
      (document) => document.Id !== id
    )
    setDocumentsArray(newDocumentsArray)
  }

  const deleteDocument = (id) => {
    const deleteDocumentParams = {
      url: `preventiveweaving/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Documento eliminado!',
      messageKo: 'Error al eliminar documentos'
    }
    deleteDocumentAction(deleteDocumentParams)
    findDeletedDocument(id)
    handleModal('hide')
  }
  // Attachs

  const handleInsertPrevWeavingRecord = async (fields) => {
    // const url = `preventiveweaving/preventiveweavingrecord`
    // const method = 'post'

    // const tupla = {
    //   Id : 0,
    //   IsPending : 0,
    //   PreventiveDate : fields.NoticeDate,
    //   RealDate : datetime,
    //   PreventiveTime : 0,
    //   PreventiveWeavingId : fields.Id,
    //   Observations : ""
    // }

    // console.log("PreventiveDate: ", tupla.PreventiveDate)
    // // console.log("RealDate: ", tupla.RealDate)

    // const preventiveApiParams = {
    //   url,
    //   method,
    //   body: tupla,
    //   messageKo: 'Error al gravar dades!',
    //   messageOk: 'Registre generat OK!'
    // }
    // await fetchSaveRecord(preventiveApiParams)
    // // if (!fetchSaveRecord.error) setLocation('/preventeix')

    // Attachs
    fields.PreventiveWeavingDocuments = null
    // Attachs

    // console.log('handleSubmit a PrevenTeixDetails!! params.id y values: ',params.id + ' ' + values)

    let url = `preventiveweaving`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    } else {
      fields.Department = type
    }
    const preventiveApiParams = {
      url,
      method,
      body: fields,
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Act.Preventiva actualitzada!'
    }
    if (params.id === 'nou')
      preventiveApiParams.messageOk = 'Act.Preventiva creada!'
    await fetchPrevenDataAction(preventiveApiParams)
    // if (!fetchedPrevenData.error) setLocation('/preventeix')
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  // Attachs
  const uploadDocument = (e) => {
    const files = e.currentTarget.files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    const jobParams = {
      url: `preventiveweaving/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Documento subido!',
      messageKo: 'Error al subir documentos'
    }

    saveDocumentAction(jobParams)

    setIsUploading(true)
  }

  useEffect(() => {
    if (documentAction.data && isUploading === true) {
      const newDocuments = JSON.parse(documentAction.data)
      setDocumentsArray(newDocuments)
      setIsUploading(false)
    }
  }, [documentAction])
  // Attachs

  const handleWorkDelete = (id) => {
    // console.log('deleting id: ',id)

    const newWorks = works.filter((work) => work.Id !== id)
    setWorks(newWorks)

    const deleteWorkParams = {
      url: `preventiveweaving/${params.id}/preventiveweavingwork/${id}`,
      method: 'DELETE',
      messageOk: 'sub-actuació Preventiva eliminada!',
      messageKo: id === 0 ? '' : 'Error al eliminar sub-actuació'
    }
    deleteWorkAction(deleteWorkParams)
    if (id === 0) setAllowCreateWork(true)
  }

  // Delete Record
  const handleDeleteRecord = async (fields) => {
    // console.log("ID PREV", params.id)
    // console.log("ID RECORD", fields)

    const deleteRecordParams = {
      url: `preventiveweaving/${params.id}/preventiveweavingrecord/${fields}`,
      method: 'DELETE',
      messageOk: 'Registre eliminat!'
    }
    deleteRecordAction(deleteRecordParams)
  }

  // EDIT Record
  const handleEditRecord = async (fields) => {
    // console.log("ID EDIT", params.id)
    // console.log("ID RECORD", fields)
    // setLocation(`/preventiveweaving/records//${fields}`)
    const editRecordParams = {
      url: `/preventiveweaving/preventiveweavingrecord`,
      method: 'POST',
      body: fields,
      messageOk: 'Registre editat!',
      messageKo: params.id === 0 ? '' : 'Error al editar'
    }
    editRecordAction(editRecordParams)
  }
  // Get Record
  const handleGetRecord = async (fields) => {
    // console.log("ID EDIT", params.id)
    // console.log("ID RECORD", fields)
    // setLocation(`/preventiveweaving/records/${fields}`)
    // OK
    // const url = `/preventiveweaving/records/${fields}`
    //   setLocation(url)
    const getRecordParams = {
      headers: {
        'content-type': 'application/json'
      },
      url: `/preventiveweaving/records/${fields}`,
      method: 'GET',
      // messageOk: 'Registre OK!',
      messageKo: 'Error al Consultar el registre'
    }
    await getRecordAction(getRecordParams)
  }

  // Edit corrective
  const handleEditCorrective = async (fields) => {
    const url = `/mantteix/${fields}`
    setLocation(url)
  }

  // CRUD DEL FICHERO LINEAS - works: -------------------------
  // nuevo registro para rellenar datos...
  function handleWorkAppend() {
    if (works?.length > 0) {
      // console.log('entra en length')
      // alert('mirar consola funcion handleAddThread')
      setWorks((prevWorks) => [
        ...prevWorks,
        {
          Id: 0,
          PreventiveWeavingId: fetchedPrevenData.data?.Id,
          Section: '', // fetchedPrevenData.data?.Section,
          Work: '', // fetchedPrevenData.data?.Work,
          Description: '', // fetchedPrevenData.data?.Description,
          WorkNumber:
            prevWorks.length > 0
              ? String(Number(prevWorks[prevWorks.length - 1].WorkNumber) + 1)
              : '1'
        }
      ])
      // alert('array de works: ',works)
      setAllowCreateWork(false)
    } else if (fetchedPrevenData.data?.Id > 0) {
      // console.log('NO entra en length')
      // alert('mirar consola funcion handleAddThread')
      setWorks([
        {
          Id: 0,
          PreventiveWeavingId: fetchedPrevenData.data?.Id,
          WorkNumber: '1'
        }
      ])
      // alert('array de works: ',works)
      setAllowCreateWork(false)
    }
  }

  // Función que se ejecuta al rellenar una nueva fila o también llamado 'actuación' en la tabla de actuaciones en "PrevenOthersWorksTable.js":
  const handleWorkSubmit = async (values) => {
    // Comprobamos si nos llegan algunos valores, es decir, si el usuario ha rellenado algún campo de la nueva actuación:
    if (values) {
      const sect = values.Section ?? ''
      const desc = values.Description ?? ''
      const work = values.Work ?? ''

      if (sect !== '' && desc !== '' && work !== '') {
        let changedWork = works.find((work) => work.Id === values.Id)
        setWorkId(values.Id)
        changedWork = { ...values }

        // console.log('G R A V A N T - handleWorkSubmit...values: ',values)

        // Guardamos la nueva actuación en la base de datos:
        const workAPIParams = {
          url: `preventiveweaving/preventiveweavingwork`,
          // url: `preventiveweaving/${values.PreventiveWeavingId}/preventiveweavingwork/${fields.Works.length +1}`,
          method: 'POST',
          body: values,
          messageKo: `No s'ha guardat el nou treball!`,
          messageOk: 'Nou treball guardat OK'
        }
        await saveWorkAction(workAPIParams)

        // activar el botón biMedicalPlus de Añadir
        if (changedWork.Id === 0) setAllowCreateWork(true)

        // Volvemos a llamar todas los registros para refrescar la lista de actuaciones:
        const fetchAPIParams = {
          url: `preventive/${params.id}`,
          method: 'GET',
          messageKo: 'Error recuperant Preventius del ECAP'
        }
        fetchPrevenDataAction(fetchAPIParams)
      }
    } else {
      console.log('omplir tots els valors de la fila Actuació')
    }
  }

  return fetchedPrevenData.data || isNew ? (
    <div className="w-full p-4 bg-white print:p-0">
      <PrevenTeixForm
        type={type}
        machines={machineOptions}
        handleClick={handleClick}
        fields={fields}
        historicOptions={historicOptions}
        correctiveOptions={correctiveOptions}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isNew={isNew}
        allowCreateWork={allowCreateWork}
        handleWorkDelete={handleWorkDelete}
        handleWorkAppend={handleWorkAppend}
        handleWorkSubmit={handleWorkSubmit}
        handleInsertPrevWeavingRecord={handleInsertPrevWeavingRecord}
        handleDeleteRecord={handleDeleteRecord}
        handleEditRecord={handleEditRecord}
        handleGetRecord={handleGetRecord}
        handleEditCorrective={handleEditCorrective}
        works={works}
        users={userOptions}
        records={recordAction}
        // Attachs
        uploadDocument={uploadDocument}
        renderTableData={renderTableData}
        documentsLength={documentsArray?.length}
        // Attachs

        // {
        // Id: userList.data.Id,
        // Name: userList.data.Name,
        // value: userList.data.Id,
        // label: userList.data.Name
        // }
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

PrevenTeixDetails.propTypes = {
  params: PropTypes.any.isRequired
}
