import { Field } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { ImCheckmark, ImCross } from 'react-icons/im'

export default function ThreadTable({ threads, side, isAnswered }) {
  const [different, setDifferent] = useState(false)

  useEffect(() => {
    if (
      threads &&
      JSON.stringify(threads.filter((t) => t.HistoryThread)) ===
        JSON.stringify(threads.filter((t) => !t.HasBeenRemoved))
    ) {
      const isDifferent = threads.some((thread) => {
        const history = {
          Id: thread?.HistoryThread?.HistoryThreadId,
          ThreadId: thread?.HistoryThread?.ThreadId,
          Thread: thread?.HistoryThread?.Thread,
          ThreadNumber: thread?.HistoryThread?.ThreadNumber,
          ThreadMatter: thread?.HistoryThread?.ThreadMatter,
          NCones: thread?.HistoryThread?.NCones,
          Percentage: thread?.HistoryThread?.Percentage,
          TSWeavingID: thread?.HistoryThread?.TSWeavingID,
          ThreadTitle: thread?.HistoryThread?.ThreadTitle,
          GRS: thread?.HistoryThread?.GRS,
          TextGRS: thread?.HistoryThread?.TextGRS
        }

        const current = {
          Id: thread?.RequestThreadId,
          ThreadId: thread?.ThreadId,
          Thread: thread?.Thread,
          ThreadNumber: thread?.ThreadNumber,
          ThreadMatter: thread?.ThreadMatter,
          NCones: thread?.NCones,
          Percentage: thread?.Percentage,
          TSWeavingID: thread?.TSWeavingID,
          ThreadTitle: thread?.ThreadTitle,
          GRS: thread?.GRS,
          TextGRS: thread?.TextGRS
        }

        return JSON.stringify(history) !== JSON.stringify(current)
      })

      setDifferent(isDifferent)
    } else {
      setDifferent(true)
    }
  }, [threads])

  // Calcular los estilos
  const getFieldStyles = (valueBefore, valueAfter, hasBeenRemoved) => {
    const hasChanged = hasBeenRemoved ? true : valueBefore !== valueAfter

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <table
      className={`relative w-full px-4 border table-fixed ${
        different
          ? side === 'left'
            ? 'border-red-300'
            : isAnswered
            ? 'border-emerald-300'
            : 'border-amber-300'
          : 'border-gray-300'
      }`}
    >
      <thead
        className={`w-full ${
          different
            ? side === 'left'
              ? 'bg-red-100 border-red-300'
              : isAnswered
              ? 'bg-emerald-100 border-emerald-300'
              : 'bg-amber-100 border-amber-300'
            : 'bg-gray-100 border-gray-300'
        }`}
      >
        <tr
          className={`w-full border-b divide-x divide-solid ${
            different
              ? side === 'left'
                ? 'divide-red-300 border-red-300'
                : isAnswered
                ? 'divide-emerald-300 border-emerald-300'
                : 'divide-amber-300 border-amber-300'
              : 'divide-gray-300 border-gray-300'
          }`}
        >
          <th className="w-1/12 text-sm"></th>
          <th className="w-6/12 text-sm">Matèria</th>
          <th className="w-1/12 text-sm">GRS</th>
          <th className="w-2/12 text-sm">Nº Cons</th>
          <th className="w-2/12 p-1.5 text-sm">Percentatge</th>
        </tr>
      </thead>

      <tbody
        className={`w-full divide-y divide-solid ${
          different
            ? side === 'left'
              ? 'divide-red-300'
              : isAnswered
              ? 'divide-emerald-300'
              : 'divide-amber-300'
            : 'divide-gray-300'
        }`}
      >
        {threads?.length > 0 &&
          threads
            ?.filter((thread) =>
              side === 'left' ? thread.HistoryThread : !thread.HasBeenRemoved
            )
            .map((thread, index) => (
              <tr
                className={`w-full h-16 divide-x divide-solid ${
                  different
                    ? side === 'left'
                      ? 'divide-red-300'
                      : isAnswered
                      ? 'divide-emerald-300'
                      : 'divide-amber-300'
                    : 'divide-gray-300'
                } ${
                  thread?.HasBeenRemoved || thread?.HistoryThread === null
                    ? side === 'left'
                      ? 'bg-red-50'
                      : isAnswered
                      ? 'bg-emerald-50'
                      : 'bg-amber-50'
                    : ''
                }`}
                key={index}
              >
                {/* Campo 'Fil x': */}
                <td className="w-1/12 text-xs text-center">Fil {index}</td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 text-xs">
                  <div className="flex flex-row items-center justify-center">
                    <Field
                      type="text"
                      className="w-full px-0.5 py-1 border border-gray-300 rounded-sm outline-none truncate"
                      style={getFieldStyles(
                        thread?.HistoryThread?.ThreadMatter,
                        thread?.ThreadMatter,
                        thread?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? thread?.HistoryThread?.ThreadMatter
                          : thread?.ThreadMatter
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'GRS': */}
                <td className="w-2/12 text-center">
                  {side === 'left' ? (
                    thread?.HistoryThread?.GRS ? (
                      <div className="flex items-center justify-center">
                        <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                          <ImCheckmark size={12} />
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                          <ImCross size={12} />
                        </p>
                      </div>
                    )
                  ) : thread?.GRS ? (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                        <ImCheckmark size={12} />
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                        <ImCross size={12} />
                      </p>
                    </div>
                  )}
                </td>

                {/* Campo 'Nº Cons': */}
                <td className="w-2/12 text-xs text-center">
                  <Field
                    type="text"
                    className="w-full px-0.5 py-1 border border-gray-300 rounded-sm outline-none truncate"
                    style={getFieldStyles(
                      thread?.HistoryThread?.NCones,
                      thread?.NCones,
                      thread?.HasBeenRemoved
                    )}
                    value={
                      side === 'left'
                        ? thread?.HistoryThread?.NCones
                        : thread?.NCones
                    }
                    disabled
                  />
                </td>

                {/* Campo 'Percentatge': */}
                <td className="w-2/12 text-xs text-center">
                  <Field
                    type="text"
                    className="w-full px-0.5 py-1 border border-gray-300 rounded-sm outline-none truncate"
                    style={getFieldStyles(
                      thread?.HistoryThread?.NCones,
                      thread?.NCones,
                      thread?.HasBeenRemoved
                    )}
                    value={
                      side === 'left'
                        ? thread?.HistoryThread?.NCones !== 0 &&
                          thread?.HistoryThread?.NCones &&
                          threads
                          ? (
                              (thread?.HistoryThread?.NCones /
                                threads?.filter(
                                  (thread) => thread.HistoryThread
                                )?.length) *
                              100
                            )
                              .toFixed()
                              .toString() + ' %'
                          : 0 + ' %'
                        : thread?.NCones !== 0 && thread?.NCones && threads
                        ? (
                            (thread?.NCones /
                              threads?.filter(
                                (thread) => !thread?.HasBeenRemoved
                              )?.length) *
                            100
                          )
                            .toFixed()
                            .toString() + ' %'
                        : 0 + ' %'
                    }
                    disabled
                  />
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

ThreadTable.propTypes = {
  threads: PropTypes.any,
  side: PropTypes.string,
  isAnswered: PropTypes.bool
}
