import pinyons from 'assets/icons/pinyons.png'
import PropTypes from 'prop-types'

export default function SprocketsForm({ fields, machineCode }) {
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'

  return (
    // TODO: Falta poner estilos de tablet y de móvil
    <div className="relative flex items-center w-full h-full">
      <img
        src={pinyons}
        alt="pinyons plegador"
        className="w-full py-16 mx-auto print:py-10 print:w-72 xl:w-72"
      />

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-1 md:right-1 lg:top-4 lg:right-1 xl:top-12 xl:right-1 print:top-8 print:right-1 ">
        <input
          type="text"
          className={`${commonInputStyles} w-8 lg:w-12 print:w-14`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.Piston2}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <input
          type="text"
          className={`${commonInputStyles} w-6 lg:w-8 print:w-10`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.PistonTolerance2}
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-4 md:left-1 lg:top-8 lg:left-1 xl:top-16 xl:left-1 print:top-12 print:left-1">
        <input
          type="text"
          className={`${commonInputStyles} w-8 lg:w-12 print:w-14`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.Piston1}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <input
          type="text"
          className={`${commonInputStyles} w-6 lg:w-8 print:w-10`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.PistonTolerance1}
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
      <div className="absolute flex items-center md:top-16 md:right-1 lg:top-20 lg:right-1 xl:top-28 xl:right-1 print:top-24 print:right-1">
        <input
          type="text"
          className={`${commonInputStyles} w-8 lg:w-12 print:w-14`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.Piston4}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <input
          type="text"
          className={`${commonInputStyles} w-6 lg:w-8 print:w-10`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.PistonTolerance4}
          disabled
        />
      </div>

      {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
      <div className="absolute flex items-center pt-4 md:top-20 md:left-1 lg:top-24 lg:left-1 xl:top-32 xl:left-1 print:top-28 print:left-1">
        <input
          type="text"
          className={`${commonInputStyles} w-8 lg:w-12 print:w-14`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.Piston3}
          disabled
        />

        <span className="px-1">&plusmn;</span>

        <input
          type="text"
          className={`${commonInputStyles} w-6 lg:w-8 print:w-10`}
          style={{ backgroundColor: 'white', color: 'black' }}
          value={fields?.PistonTolerance3}
          disabled
        />
      </div>

      {/* ---------------------------------- */}

      {machineCode !== 'JMB' && (
        <>
          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-32 md:left-14 lg:top-36 lg:left-14 xl:top-48 xl:left-28 2xl:top-48 2xl:left-44 print:top-40 print:left-32`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.Piston7}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-32 md:left-28 lg:top-36 lg:left-28 xl:top-48 xl:left-44 2xl:top-48 2xl:left-60 print:top-40 print:left-48`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.Piston6}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-40 md:left-1 lg:top-44 lg:left-1 xl:top-56 xl:left-12 2xl:top-56 2xl:left-28 print:top-48 print:left-16`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.Piston5}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-40 md:left-14 lg:top-44 lg:left-14 xl:top-56 xl:left-28 2xl:top-56 2xl:left-44 print:top-48 print:left-32`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.Piston8}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-48 md:left-1 lg:top-52 lg:left-1 xl:top-64 xl:left-12 2xl:top-64 2xl:left-28 print:top-56 print:left-16`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.Piston9}
            disabled
          />

          {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-48 md:left-14 lg:top-52 lg:left-14 xl:top-64 xl:left-28 2xl:top-64 2xl:left-44 print:top-56 print:left-32`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.Piston10}
            disabled
          />
        </>
      )}

      {/* ---------------------------------- */}

      {machineCode === 'JMB' && (
        <>
          {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-40 md:left-28 md:ml-2 lg:top-44 lg:left-28 lg:ml-2 xl:top-48 xl:left-44 xl:ml-0 2xl:top-48 2xl:left-60 print:top-44 print:left-44`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.JUM1}
            disabled
          />

          {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-40 md:left-16 lg:top-44 lg:left-16 xl:top-48 xl:left-24 2xl:top-48 2xl:left-28 print:top-44 print:left-16`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.JUM2}
            disabled
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-48 md:left-16 lg:top-52 lg:left-16 xl:top-56 xl:left-24 2xl:top-56 2xl:left-28 print:top-52 print:left-16`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.JUM3}
            disabled
          />

          {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
          <input
            type="text"
            className={`${commonInputStyles} w-8 lg:w-12 print:w-14 absolute md:top-48 md:left-1 lg:top-52 lg:left-1 xl:top-56 xl:left-1 2xl:top-56 2xl:left-1 print:top-52 print:left-1`}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={fields?.JUM4}
            disabled
          />
        </>
      )}
    </div>
  )
}

SprocketsForm.propTypes = {
  fields: PropTypes.object,
  machineCode: PropTypes.string
}
