import ColumnFilter from 'components/ui/Table/ColumnFilter'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Codi',
      accessor: 'Clients_Code',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom',
      accessor: 'Clients_Name',
      Filter: ColumnFilter
    },
    {
      Header: 'Etiquetes',
      accessor: 'Clients_Tag',
      Filter: ColumnFilter,
      Cell: ({ value }) => (
        <span className="px-2 py-1 rounded-full 2xl:ml-4 font-ms-bold">
          {value}
        </span>
      )
    },
    {
      Header: 'Posició',
      accessor: 'Clients_Position',
      Filter: ColumnFilter
    },
    {
      Header: 'Bobina',
      accessor: 'Clients_Coil',
      Filter: ColumnFilter
    },
    {
      Header: 'Especificacions ',
      accessor: 'Clients_Notes',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items
        .sort((a, b) => a.Name.localeCompare(b.Name))
        .forEach((item) => {
          itemsData.push({
            Id: item.Id,
            Clients_Code: item.Code,
            Clients_Name: item.Name,
            Clients_Position: item.Position,
            Clients_Tag: item.Tag,
            Clients_Coil:
              item.Coil === null || item.Coil === ''
                ? null
                : item.Coil === 'SI',
            Clients_Notes: item.Notes
          })
        })

      setTableData(itemsData)
    }
  }, [items])

  return (
    tableData.length > 0 && (
      <Table
        tableData={tableData}
        tableColumns={tableColumns}
        handleClick={handleClick}
      />
    )
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
