import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

localStorage.setItem('acumHours',0)
localStorage.setItem('acumMinutes',0)

export default function ListTable({ items, handleClick, users }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'NC',
      accessor: 'NC_Id',
      Filter: ColumnFilter
    },
    {
      Header: 'N8D',
      accessor: 'NC_N8D',
      Filter: ColumnFilter
    },
    {
      Header: 'Departament',
      accessor: 'NC_Department',
      Filter: ColumnFilter
    },
    {
      Header: 'Data',
      accessor: 'NC_Date',
      Filter: ColumnFilter
    },
    {
      Header: 'Article',
      accessor: 'NC_Article',
      Filter: ColumnFilter
    },
    {
      Header: 'Semielaborat',
      accessor: 'NC_SemiFinished',
      Filter: ColumnFilter
    },
    {
      Header: 'Àrea',
      accessor: 'NC_Area',
      Filter: ColumnFilter
    },
    {
      Header: 'Nau',
      accessor: 'NC_Shed',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Usuari',
    //   accessor: 'usuariId',
    //   Filter: ColumnFilter
    // },
    {
      Header: 'Màq.Teix.',
      accessor: 'NC_Machine',
      Filter: ColumnFilter
    },
    {
      Header: 'Altres',
      accessor: 'NC_EquipmentName',
      Filter: ColumnFilter
    },    
    {
      Header: 'Descripció',
      accessor: 'NC_Info',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []
      
      items.forEach((item) => {
        itemsData.push({
          Id:               item.Id,
          NC_Id:            item.Id,
          NC_IsMatter:      item.IsMatter,
          NC_IsSemi:        item.IsSemi,
          NC_Date:          item.DateNC ? item.HourNC ? item.DateNC + ' ' + item.HourNC : item.DateNC : '',
          NC_Info:          item.Description,
          NC_IsFinish:      item.IsFinish,
          NC_IsOthers:      item.IsOthers,
          NC_SemiFinished:  item.SemiFinished,
          NC_Article:       item.Article,
          NC_UsuariId:      item.DetectedUserName,
          NC_Area:          item.Area,
          NC_N8D:           item.N8D,
          NC_Machine:       item.Machine,
          NC_Shed:          item.Shed,
          NC_Department:    item.Department,
          NC_IdEquipment:   item.IdEquipment? item.IdEquipment : '',
          NC_EquipmentName: item.EquipmentName? item.EquipmentName : ''
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 && (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  users: PropTypes.array
}
