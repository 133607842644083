import esquemaControlVisio from 'assets/img/ControlVisio.png'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { CgSpinner } from 'react-icons/cg'
import { MdEdit } from 'react-icons/md'
import Select from 'react-select'
import * as Yup from 'yup'

export default function ControlVisionForm({
  fields,
  users,
  equipments,
  handleSubmit
}) {
  const validationSchema = Yup.object().shape({
    UserId: Yup.number()
      .required('* Especificar un operari')
      .positive('* Especificar un operari')
      .integer('* Especificar un operari'),
    EquipmentId: Yup.number()
      .required('* Especificar un RAM')
      .positive('* Especificar un RAM')
      .integer('* Especificar un RAM'),
    Date: Yup.string().nullable().required('* Especificar una data')
  })

  const optionsYesNo = [
    { value: true, label: 'Si' },
    { value: false, label: 'No' }
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...fields }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm,
        isSubmitting
      }) => (
        <Form className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md print:shadow-none print:bg-white print:border-0">
          <div className="flex justify-start w-full p-2 text-sm bg-blue-200 print:border-b print:border-blue-300 print:justify-center print:bg-transparent font-ms-bold print:text-2xl">
            CONTROL VISIÓ
          </div>

          <div className="grid grid-cols-12 gap-4 p-4 print:px-0 print:pb-0 print:pt-12">
            <div className="flex flex-col items-start col-span-12 space-y-4 print:col-span-6 lg:col-span-6">
              {/* OPERARI */}
              <div className="items-center block w-full">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Operari:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full leading-tight print:hidden text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('UserName', e.Name)
                    setFieldValue('UserId', e.Id)
                  }}
                  name="UserId"
                  placeholder="Seleccionar..."
                  options={users}
                  getOptionLabel={(o) => o.Name}
                  getOptionValue={(o) => o.Id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused
                        ? errors.UserId && touched.UserId
                          ? '#f87171'
                          : '#9CA3AF'
                        : errors.UserId && touched.UserId
                        ? '#fecaca'
                        : '#D1D5DB',
                      '&:hover': {
                        borderColor:
                          errors.UserId && touched.UserId
                            ? '#f87171'
                            : '#9CA3AF'
                      },
                      backgroundColor:
                        errors.UserId && touched.UserId ? '#fef2f2' : '#ffffff'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={users?.find((option) => option.Id === values?.UserId)}
                />
                <ErrorMessage
                  name="UserId"
                  render={(message) => (
                    <span className="text-sm text-red-600">{message}</span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {users?.find((option) => option.Id === values?.UserId)?.Name}
                </div>
              </div>

              {/* OF */}
              <div className="items-center block w-full">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  OF:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="text"
                  name="OF"
                  className="w-full input-editable-interactive print:hidden"
                  autoComplete="off"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.OF}
                </div>
              </div>

              {/* ESTIL */}
              <div className="items-center block w-full">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Estil:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="text"
                  name="Style"
                  className="w-full input-editable-interactive print:hidden"
                  autoComplete="off"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.Style}
                </div>
              </div>

              {/* RAM */}
              <div className="items-center block w-full">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  RAM:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full leading-tight print:hidden text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('EquipmentName', e.Description)
                    setFieldValue('EquipmentId', e.Id)
                  }}
                  name="EquipmentId"
                  placeholder="Seleccionar..."
                  options={equipments}
                  getOptionLabel={(o) => o.Description}
                  getOptionValue={(o) => o.Id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused
                        ? errors.EquipmentId && touched.EquipmentId
                          ? '#f87171'
                          : '#9CA3AF'
                        : errors.EquipmentId && touched.EquipmentId
                        ? '#fecaca'
                        : '#D1D5DB',
                      '&:hover': {
                        borderColor:
                          errors.EquipmentId && touched.EquipmentId
                            ? '#f87171'
                            : '#9CA3AF'
                      },
                      backgroundColor:
                        errors.EquipmentId && touched.EquipmentId
                          ? '#fef2f2'
                          : '#ffffff'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={equipments?.find(
                    (option) => option.Id === values?.EquipmentId
                  )}
                />
                <ErrorMessage
                  name="EquipmentId"
                  render={(message) => (
                    <span className="text-sm text-red-600">{message}</span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {
                    equipments?.find(
                      (option) => option.Id === values?.EquipmentId
                    )?.Description
                  }
                </div>
              </div>

              {/* LA VISIÓ CAPTA LES 2 TAQUES EN MOVIMENT */}
              <div className="flex flex-row items-center justify-start w-full space-x-2">
                <div className="items-center block text-sm text-grey-darker font-ms-semi">
                  La visió capta les 2 taques en moviment
                </div>

                <div className="relative flex items-center bg-white">
                  {/* NORMAL VERSION */}
                  <Select
                    className="w-20 leading-tight print:hidden text-grey-darker"
                    onChange={(e) => {
                      setFieldValue('Verification1', e.value)
                    }}
                    name="Verification1"
                    options={optionsYesNo}
                    getOptionLabel={(o) => o.label}
                    getOptionValue={(o) => o.value}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '0.125rem',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        minHeight: 'auto',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                        '&:hover': {
                          borderColor: '#9CA3AF'
                        }
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        height: '100%',
                        padding: '0.25rem 0.5rem',
                        position: 'static',
                        cursor: 'pointer',
                        rowGap: '0.25rem'
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        margin: '0',
                        paddingTop: '0',
                        paddingBottom: '0',
                        cursor: 'pointer'
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        marginLeft: '0',
                        marginRight: '0'
                      }),
                      indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        cursor: 'pointer'
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        padding: '0px 8px'
                      })
                    }}
                    isSearchable={false}
                    value={optionsYesNo?.find(
                      (option) => option.value === values?.Verification1
                    )}
                  />

                  {/* PRINT VERSION */}
                  <div className="hidden print:flex items-center w-20 p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                    {values?.Verification1 ? 'Sí' : 'No'}
                  </div>
                </div>
              </div>

              {/* LA VISIÓ CAPTA 1 LÍNIA AMB PERMANENT */}
              <div className="flex flex-row items-center justify-start w-full space-x-2">
                <div className="items-center block text-sm text-grey-darker font-ms-semi">
                  La visió capta 1 línia amb permanent
                </div>

                <div className="relative flex items-center bg-white">
                  {/* NORMAL VERSION */}
                  <Select
                    className="w-20 leading-tight print:hidden text-grey-darker"
                    onChange={(e) => {
                      setFieldValue('Verification2', e.value)
                    }}
                    name="Verification2"
                    options={optionsYesNo}
                    getOptionLabel={(o) => o.label}
                    getOptionValue={(o) => o.value}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '0.125rem',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        minHeight: 'auto',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                        '&:hover': {
                          borderColor: '#9CA3AF'
                        }
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        height: '100%',
                        padding: '0.25rem 0.5rem',
                        position: 'static',
                        cursor: 'pointer',
                        rowGap: '0.25rem'
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        margin: '0',
                        paddingTop: '0',
                        paddingBottom: '0',
                        cursor: 'pointer'
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        marginLeft: '0',
                        marginRight: '0'
                      }),
                      indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        cursor: 'pointer'
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        padding: '0px 8px'
                      })
                    }}
                    isSearchable={false}
                    value={optionsYesNo?.find(
                      (option) => option.value === values?.Verification2
                    )}
                  />

                  {/* PRINT VERSION */}
                  <div className="hidden print:flex items-center w-20 p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                    {values?.Verification2 ? 'Sí' : 'No'}
                  </div>
                </div>
              </div>

              {/* LA VISIÓ CAPTA EL FORAT */}
              <div className="flex flex-row items-center justify-start w-full space-x-2">
                <div className="items-center block text-sm text-grey-darker font-ms-semi">
                  La visió capta el forat
                </div>

                <div className="relative flex items-center bg-white">
                  {/* NORMAL VERSION */}
                  <Select
                    className="w-20 leading-tight print:hidden text-grey-darker"
                    onChange={(e) => {
                      setFieldValue('Verification3', e.value)
                    }}
                    name="Verification3"
                    options={optionsYesNo}
                    getOptionLabel={(o) => o.label}
                    getOptionValue={(o) => o.value}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '0.125rem',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        minHeight: 'auto',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                        '&:hover': {
                          borderColor: '#9CA3AF'
                        }
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        height: '100%',
                        padding: '0.25rem 0.5rem',
                        position: 'static',
                        cursor: 'pointer',
                        rowGap: '0.25rem'
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        margin: '0',
                        paddingTop: '0',
                        paddingBottom: '0',
                        cursor: 'pointer'
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        marginLeft: '0',
                        marginRight: '0'
                      }),
                      indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        cursor: 'pointer'
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        padding: '0px 8px'
                      })
                    }}
                    isSearchable={false}
                    value={optionsYesNo?.find(
                      (option) => option.value === values?.Verification3
                    )}
                  />

                  {/* PRINT VERSION */}
                  <div className="hidden print:flex items-center w-20 p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                    {values?.Verification3 ? 'Sí' : 'No'}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-start col-span-12 space-y-4 print:col-span-6 lg:col-span-6">
              {/* DATA */}
              <div className="items-center block w-full text-base">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Data:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="date"
                  name="Date"
                  className={`w-full leading-snug input-editable-standard print:hidden ${
                    errors.Date && touched.Date
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                  }`}
                />
                <ErrorMessage
                  name="Date"
                  render={(message) => (
                    <span className="text-sm text-red-600">{message}</span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.Date}
                </div>
              </div>

              {/* ESQUEMA */}
              <div className="block w-full">
                <img
                  src={esquemaControlVisio}
                  className="w-full xl:w-3/4"
                  alt="control visio"
                />
              </div>
            </div>

            <div className="grid grid-cols-12 col-span-12 gap-4">
              {/* DESCRIPCIÓ */}
              <div className="items-center block col-span-10 text-base xl:col-span-8 print:col-span-12 print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Descripció
                </div>

                {/* NORMAL VERSION */}
                <Field
                  as="textarea"
                  name="Description"
                  className="block w-full h-48 text-sm whitespace-pre-wrap input-editable-interactive print:hidden print:resize-none"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.Description}
                </div>
              </div>

              {/* GUARDAR */}
              <div className="flex items-end justify-end col-span-2 xl:col-span-4 print:hidden">
                <button
                  className={`inline-flex items-center h-10 px-4 py-1 text-white transition duration-300 bg-teal-500 border border-teal-500 rounded-sm font-ms-semi ${
                    !isSubmitting &&
                    'hover:border-teal-500 hover:bg-white hover:text-teal-500'
                  }`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CgSpinner size={22} className="animate-spin xl:mr-2" />
                  ) : (
                    <MdEdit className="xl:mr-2" size={22} />
                  )}

                  <span className="hidden xl:inline-flex">GUARDAR</span>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ControlVisionForm.propTypes = {
  fields: PropTypes.object,
  users: PropTypes.array,
  equipments: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired
}
