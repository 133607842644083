// PropTypes
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Field } from 'formik'

// REACT ICONS
import { MdArrowDropDown } from 'react-icons/md'

export default function PackagingForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-1 lg:gap-3 print:gap-2 print:auto-rows-auto">
      {/* TIPUS TUB */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus tub:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowedprint:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 bg-gray-100 text-gray-500 h-full rounded-sm leading-tight text-base border border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TubeSize}
        </div>
      </div>

      {/* COMENTARI TUB */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Comentari Tub:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TubName"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* CINTA DOBLE CARA TUB */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Cinta doble cara tub:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.HasDoubleSidedTape"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* FIXACIÓ FI BOB */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Fixació fi bob:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FinalCoilFixation"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* TIPUS PLÀSTIC */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Tipus plàstic:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.PlasticType"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* FILM POLIETILÈ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Film Polietilè:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.IsPolyethyleneFilm"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* CANTONERES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Cantoneres:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.HasCorners"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* PRECINTE COLOR */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Precinte color:
      </div>
      <div className="relative flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          as="select"
          name="RequestArticle.PrecinctColor"
          className={`z-10 w-full h-full px-2 py-1 leading-tight border bg-transparent border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline cursor-pointer`}
        >
          <option value="">-</option>
          <option value={'no'}>No</option>
          <option value={'blau'}>Blau</option>
          <option value={'groc'}>Groc</option>
          <option value={'verd'}>Verd</option>
          <option value={'vermell'}>Vermell</option>
          <option value={'marro'}>Marró</option>
          <option value={'negre'}>Negre</option>
          <option value={'taronja'}>Taronja</option>
          <option value={'blaugroc'}>Blau + Groc</option>
          <option value={'blauverd'}>Blau + Verd</option>
          <option value={'blauvermell'}>Blau + Vermell</option>
          <option value={'grocverd'}>Groc + Verd</option>
        </Field>
        <MdArrowDropDown
          size={25}
          className="absolute right-0 text-gray-400 transform -translate-y-1/2 top-1/2"
        />
      </div>

      {/* ALTRES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Altres:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Others"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* ETIQUETES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Etiquetes:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowedprint:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 text-gray-500 bg-gray-100 h-full rounded-sm leading-tight text-base border border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TagNumber}
        </div>
        <div className="w-full px-1.5 py-1 text-gray-500 bg-gray-100 h-full rounded-sm leading-tight text-base border border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TagDescription}
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.PackagingObservation"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>
    </div>
  )
}

PackagingForm.propTypes = {
  values: PropTypes.object
}
