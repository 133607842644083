import ListTable from 'components/RRHH/Treballadors/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const TreballadorsList = () => {
  const { setTitle } = useContext(TitleContext)
  const [jobList, jobListAction] = useFetch()
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  useEffect(() => {
    // Set title
    const jobTitle = {
      name: 'Treballadors',
      buttons: [
        {
          name: 'Nou Treballador',
          link: `/treballadors/nou/`
        }
      ]
    }
    setTitle(jobTitle)

    const jobListAPIParams = {
      url: `treballador`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del treballador'
    }

    jobListAction(jobListAPIParams)

    //* Avoid bug that when item is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  // Función para que al hacer click sobre un elemento este se abra
  // en una pestaña nueva:
  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/treballadors/${item.Id}`)
  }

  return jobList.data !== null && jobList.data !== undefined ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={jobList.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default TreballadorsList
