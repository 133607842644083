import ListTable from 'components/Inventory/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const Inventory = () => {
  const { setTitle } = useContext(TitleContext)
  const [mtList, mtListAction] = useFetch()
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  useEffect(() => {
    // Set title
    const mtTitle = {
      name: 'Inventari',
      buttons: [
        {
          name: 'Nou registre',
          link: `/inventari/nou/`
        }
      ]
    }
    setTitle(mtTitle)

    const mtListAPIParams = {
      url: `inventorymachine`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    mtListAction(mtListAPIParams)

    //* Avoid bug that when inventory is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/inventari/${item.Id}`)
  }
  return mtList.data ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={mtList.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default Inventory
