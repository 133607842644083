import PropTypes from 'prop-types'

// Login Form
import LoginForm from 'forms/login/loginForm'

export default function OfficeLogin({
  loading,
  error,
  handleSubmitOfficeLogin
}) {
  return (
    <>
      <div className="w-full md:w-96 bg-white shadow-md rounded px-8 py-2 flex flex-col m-3 self-stretch">
        <div className="pt-3 text-center leading-6 uppercase font-ms-bold pb-6 sm:text-2xl sm:leading-7">
          <h2>Accés Oficina</h2>
        </div>
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">
              Usuari i contrasenya incorrectes
            </span>
          </div>
        )}
        <LoginForm loading={loading} handleSubmit={handleSubmitOfficeLogin} />
        {/* <div className="mb-4">
          <a
            className="block text-center text-sm text-blue hover:text-blue-darker"
            href="#"
          >
            Recuperar contrasenya
          </a>
        </div>
        */}
      </div>
    </>
  )
}

OfficeLogin.propTypes = {
  handleSubmitOfficeLogin: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
}
