import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

export default function ListTable({ items, handleClick }) {
  const currentYear = new Date().getFullYear()
  const [tableData, setTableData] = useState([])
  const [selectedYear, setSelectedYear] = useState(
    localStorage.getItem('ForecastTraining_SelectedYear')
      ? Number(localStorage.getItem('ForecastTraining_SelectedYear'))
      : currentYear
  )

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Formador',
      accessor: 'ForecastTraining_Entity',
      Filter: ColumnFilter
    },
    {
      Header: 'Curs',
      accessor: 'ForecastTraining_DetailCourse',
      Filter: ColumnFilter
    },
    {
      Header: 'Previsió',
      accessor: 'ForecastTraining_PrevisionDate',
      Filter: ColumnFilter
    },
    {
      Header: 'Implicats',
      accessor: 'ForecastTraining_Involved',
      Filter: ColumnFilter
    },
    {
      Header: 'Prioritat',
      accessor: 'ForecastTraining_Priority',
      Filter: ColumnFilter
    },
    {
      Header: 'Hores',
      accessor: 'ForecastTraining_Hours',
      Filter: ColumnFilter
    },
    {
      Header: 'Preu',
      accessor: 'ForecastTraining_Price',
      Filter: ColumnFilter
    },
    {
      Header: 'Estadi',
      accessor: 'ForecastTraining_CourseState',
      Filter: ColumnFilter
    },
    {
      Header: 'Actiu',
      accessor: 'ForecastTraining_MonitoringActivated',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id: item.Id,
          ForecastTraining_DetailCourse: item.DetailCourse,
          ForecastTraining_Observations: item.Description,
          ForecastTraining_Involved: item.Involved,
          ForecastTraining_Entity: item.Entity,
          ForecastTraining_Hours: item.Hours,
          ForecastTraining_Price: item.Price,
          ForecastTraining_PrevisionDate: item.PrevisionDate
            ? formatDate(item.PrevisionDate)
            : null,
          ForecastTraining_Priority: item.Priority,
          ForecastTraining_MonitoringActivated: item.MonitoringActivated,
          ForecastTraining_CourseYear: item.CourseYear,
          ForecastTraining_CourseState: item.CourseState
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  const formatDate = (date) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = newDate.toLocaleDateString('es-ES', options)

    return formattedDate
  }

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-12 p-4 border-2 border-red-800">
        <div className="self-center col-span-4 transition-all duration-700 ease-in-out justify-self-start">
          <button
            type="button"
            className={`p-2 rounded-full cursor-pointer text-white transition duration-300 bg-red-800 border border-red-800 outline-none ${
              selectedYear > currentYear - 1
                ? 'cursot-not-allowed'
                : 'cursor-pointer hover:border-red-900 hover:bg-white hover:text-red-900'
            }`}
            onClick={() => {
              localStorage.setItem(
                'ForecastTraining_SelectedYear',
                selectedYear + 1
              )
              setSelectedYear((currentValue) => currentValue + 1)
            }}
            disabled={selectedYear > currentYear - 1}
          >
            <FaArrowLeft size={22} />
          </button>
        </div>

        <div className="grid grid-cols-12 col-span-4 transition-all duration-700 ease-in-out cursor-default gap-x-6 place-self-center">
          <div className="col-span-4 text-sm text-gray-300 font-ms-bold place-self-center">
            <span className="inline-block">
              {selectedYear + 1 > currentYear ? '-' : selectedYear + 1}
            </span>
          </div>

          <div className="col-span-4 text-2xl font-ms-bold place-self-center">
            <span className="inline-block">{selectedYear}</span>
          </div>

          <div className="col-span-4 text-sm text-gray-300 font-ms-bold place-self-center">
            <span className="inline-block">
              {selectedYear - 1 < 2008 ? '-' : selectedYear - 1}
            </span>
          </div>
        </div>

        <div className="self-center col-span-4 transition-all duration-700 ease-in-out justify-self-end">
          <button
            type="button"
            className={`p-2 rounded-full cursor-pointer text-white transition duration-300 bg-red-800 border border-red-800 outline-none ${
              selectedYear < 2009
                ? 'cursot-not-allowed'
                : 'cursor-pointer hover:border-red-900 hover:bg-white hover:text-red-900'
            }`}
            onClick={() => {
              localStorage.setItem(
                'ForecastTraining_SelectedYear',
                selectedYear - 1
              )
              setSelectedYear((currentValue) => currentValue - 1)
            }}
            disabled={selectedYear < 2009}
          >
            <FaArrowRight size={22} />
          </button>
        </div>
      </div>

      {tableData.length > 0 && (
        <Table
          tableData={tableData.filter(
            (item) => item.ForecastTraining_CourseYear === selectedYear
          )}
          tableColumns={tableColumns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
