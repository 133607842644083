import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function ListTable({
  items,
  handleClick,
  downloadMatrixTableAsExcel
}) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Procés',
      accessor: 'Versatility_IdProces',
      Filter: ColumnFilter
    },
    {
      Header: 'Nau',
      accessor: 'Versatility_Nau',
      Filter: ColumnFilter
    },
    {
      Header: 'Àrea',
      accessor: 'Versatility_AreaName',
      Filter: ColumnFilter
    },
    {
      Header: 'Lloc de Treball',
      accessor: 'Versatility_LlocTreballName',
      Filter: ColumnFilter
    },
    {
      Header: 'Treballador',
      accessor: 'Versatility_TrebName',
      Filter: ColumnFilter
    },
    {
      Header: 'Tipus',
      accessor: 'Versatility_Tipu',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'CheckList',
    //   accessor: 'IdCheckVersatility',
    //   disableFilters: true,
    //   disableSortBy: true
    // },
    {
      Header: 'Nivell',
      accessor: 'Versatility_Nivell',
      Filter: ColumnFilter
    },
    {
      Header: 'Inici',
      accessor: 'Versatility_Datact',
      Filter: ColumnFilter
    },
    {
      Header: 'Vigent',
      accessor: 'Versatility_IsRemoved',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach(function (item, index) {
        if (item.IsRemoved === false) {
          itemsData.push({
            Id: item.Id,
            Versatility_IdTreb: item.Idtreb,
            Versatility_TrebName: item.Idtreb_Id ? item.Idtreb_Id.Name : null,
            Versatility_TrebIdArea: item.Idtreb_Id
              ? item.Idtreb_Id.areaactual
              : null,
            Versatility_Tipu: item.Idtreb_Id ? item.Idtreb_Id.Tipu : null,
            Versatility_Ett: item.Idtreb_Id ? item.Idtreb_Id.Ett : null,
            Versatility_Nau: item.Idtreb_Id ? item.Idtreb_Id.Nau : null,
            Versatility_IdProces: item.IdProces_Id
              ? item.IdProces_Id.Name
              : null,
            Versatility_IdArea: item.Idarea,
            Versatility_AreaName: item.Idarea_Id ? item.Idarea_Id.Name : null,
            Versatility_IdLlocTreball: item.Idlloctreball,
            Versatility_LlocTreballName: item.Idlloctreball_Id
              ? item.Idlloctreball_Id.Description
              : null,
            Versatility_LlocTreballIdArea: item.Idlloctreball_Id
              ? item.Idlloctreball_Id.AreaId
              : null,
            Versatility_Nivell: item.Nivell,
            Versatility_Datact: item.Datact ? formatDate(item.Datact) : null,
            Versatility_IdCheckVersatility: item.Idcheck,
            Versatility_ValidatFaI: item.ValidatFaI,
            Versatility_IsRemoved: item.Idtreb_Id ? item.Idtreb_Id.Vigent : null
          })
        }
      })

      setTableData(itemsData)
    }
  }, [items])

  const formatDate = (date) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = newDate.toLocaleDateString('es-ES', options)

    return formattedDate
  }

  return (
    tableData.length > 0 && (
      <Table
        tableData={tableData}
        tableColumns={tableColumns}
        handleClick={handleClick}
        downloadMatrixTableAsExcel={downloadMatrixTableAsExcel}
      />
    )
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  downloadMatrixTableAsExcel: PropTypes.func
}
