import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { AiOutlineCheck, AiOutlineClose, AiOutlineUnlock, AiOutlineLock  } from 'react-icons/ai'

export default function ListTable({ items, handleClick }) {
  const [totalHours,setTotalHours] = useState([])
  const [totalMinutes,setTotalMinutes] = useState([])

  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    // {
    //   Header: 'Cod.Màq.',
    //   accessor: 'machine',
    //   Filter: ColumnFilter
    // },
    {
      Header: ' ',
      accessor: 'Maintenance_State',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineLock
          color="#be0043green"
          className="2xl:ml-1 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={30}
        />
      ) : (
        <AiOutlineUnlock
          className="2xl:ml-1 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="green"
          size={30}
        />
      )
    },
    {
      Header: 'Màquina',
      accessor: 'Maintenance_MachineName',
      Filter: ColumnFilter
    },
    {
      Header: 'Data Solic.',
      accessor: 'Maintenance_Requestdate',
      Filter: ColumnFilter
    },
    {
      Header: 'Data Corr.',
      accessor: 'Maintenance_CorrectiveDate',
      Filter: ColumnFilter
    },    
    {
      Header: 'Num',
      accessor: 'Maintenance_Id',
      Filter: ColumnFilter
    },    
    {
      Header: 'Àrea',
      accessor: 'Maintenance_Area',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom',
      accessor: 'Maintenance_Usuari',
      Filter: ColumnFilter
    },
    {
      Header: 'Nau',
      accessor: 'Maintenance_Shed',
      Filter: ColumnFilter
    },
    {
      Header: 'Descripció',
      accessor: 'Maintenance_Information',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Arregla',
    //   accessor: 'repair',
    //   Filter: ColumnFilter
    // },    
    {
      Header: "Com s'arregla",
      accessor: 'Maintenance_WhatDoes',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Recanvis',
    //   accessor: 'spentpart',
    //   Filter: ColumnFilter
    // },    
    // ,
    // {
    //   Header: 'Altres',
    //   accessor: 'EquipmentName',
    //   Filter: ColumnFilter
    // }
    {
      Header: 'Hores',
      accessor: 'Maintenance_CorrectiveTimeHours',
      Filter: ColumnFilter
    },
    {
      Header: 'Min.',
      accessor: 'Maintenance_CorrectiveTimeMinutes',
      Filter: ColumnFilter
    },
    {
      Header: 'Millora',
      accessor: 'Maintenance_IsMachineImprovement',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    },
    {
      Header: 'Print',
      accessor: 'Maintenance_IsPrinted',
      Filter: ColumnFilter,
      Cell: ({ value }) =>
      value  ? (
        <AiOutlineCheck
          color="green"
          className="2xl:ml-6 ml-4 bg-green-200 rounded-full my-0 p-1 "
          size={23}
        />
      ) : (
        <AiOutlineClose
          className="2xl:ml-6 ml-4 bg-red-200 rounded-full p-1 my-0"
          color="#be0043"
          size={23}
        />
      )
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {        
        itemsData.push({
          Id:                                 item.Id,
          Maintenance_Id:                     item.Id,
          Maintenance_State:                  item.CorrectiveDate,
          Maintenance_Usuari:                 item.RequestUserName,
          Maintenance_CorrectiveDate:         item.CorrectiveDate,
          Maintenance_Requestdate:            item.RequestDate,
          Maintenance_Information:            item.Description,
          Maintenance_IsSemi:                 item.IsSemi,
          Maintenance_IsFinish:               item.IsFinish,
          Maintenance_IsOthers:               item.IsOthers,
          Maintenance_SemiFinished:           item.SemiFinished,
          Maintenance_Article:                item.Article,
          Maintenance_UserId:                 item.DetectedUserId,
          Maintenance_Area:                   item.Area,
          Maintenance_Machine:                item.Machine,  
          Maintenance_MachineName:            item.MachineName? item.MachineName : '',        
          Maintenance_Shed:                   item.Shed,
          Maintenance_Repair:                 item.TechnicalUserName,
          Maintenance_WhatDoes:               item.WhatDoes,
          Maintenance_SpentPart:              item.SpentPart,
          Maintenance_CorrectiveTimeHours:    item.CorrectiveTimeHours,
          Maintenance_CorrectiveTimeMinutes:  item.CorrectiveTimeMinutes,
          Maintenance_IdEquipment:            item.IdEquipment? item.IdEquipment : '',
          Maintenance_EquipmentName:          item.EquipmentName? item.EquipmentName : '',
          Maintenance_IsMachineImprovement:   item.IsMachineImprovement, 
          Maintenance_IsPrinted:              item.IsPrinted                   
        })      
      })

      setTableData(itemsData.reverse())
    }
  }, [items])
  
  return tableData.length > 0 && (
    <Table
      tableData           = {tableData}
      tableColumns        = {tableColumns}
      handleClick         = {handleClick}
      txtHours            = {totalHours}
      txtMinutes          = {totalMinutes}
      isMaintenance       = {true}
      isMaintenanceOthers = {false}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any
}
