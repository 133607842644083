import ListTable from 'components/FTTeixiduriaRequest/ListTable/ListTable'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function FTTeixiduriaRequestList({ params }) {
  //*  Set FTTeixiduria Title
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const [ftList, ftListAction] = useFetch()

  //* Fetch get ft list
  useEffect(() => {
    const ftTeixiduriaRequestsTitle = {
      name: 'Historial',
      buttons: [{ name: 'Tornar', link: `/ft-teixiduria/${params.id}` }]
    }
    setTitle(ftTeixiduriaRequestsTitle)
    // Fetch api FT List
    const ftListAPIParams = {
      url: `tsweaving/${params.id}/requests`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    ftListAction(ftListAPIParams)
  }, [])

  //* Handle click function to redirect to FTTeixiduria details
  function handleClick(e, item) {
    e.preventDefault()
    setLocation(`/ft-teixiduria/request-view/${params.id}/${item.RequestId}`)
  }

  return ftList.data ? (
    <div className="w-full p-4">
      <ListTable items={ftList.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

FTTeixiduriaRequestList.propTypes = {
  params: PropTypes.any.isRequired
}
