import { useState, useEffect, useContext } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import { UserContext } from 'contexts/UserContext'

export default function ListTable({ items, handleClick, userRoleId, currentUser }) {
  const { user } = useContext(UserContext)
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = currentUser.NotifyOnSuggestionChange && userRoleId === 1
    ? [
        {
          Header: 'Emisor',
          accessor: 'Suggestions_UserName',
          Filter: ColumnFilter
        },
        {
          Header: 'Descripció',
          accessor: 'Suggestions_Reason',
          Filter: ColumnFilter
        },
        {
          Header: 'Nau',
          accessor: 'Suggestions_Shed',
          Filter: ColumnFilter
        },
        {
          Header: 'Àrea',
          accessor: 'Suggestions_Area',
          Filter: ColumnFilter
        },
        {
          Header: 'Creat',
          accessor: 'Suggestions_CreatedAt',
          Filter: ColumnFilter
        },
        {
          Header: 'Respós',
          accessor: 'Suggestions_Response',
          Filter: ColumnFilter
        },
        {
          Header: 'Actiu',
          accessor: 'Suggestions_IsActive',
          Filter: ColumnFilter
        }
      ]
    : [
        {
          Header: 'Emisor',
          accessor: 'Suggestions_UserName',
          Filter: ColumnFilter
        },
        {
          Header: 'Descripció',
          accessor: 'Suggestions_Reason',
          Filter: ColumnFilter
        },
        {
          Header: 'Nau',
          accessor: 'Suggestions_Shed',
          Filter: ColumnFilter
        },
        {
          Header: 'Àrea',
          accessor: 'Suggestions_Area',
          Filter: ColumnFilter
        },
        {
          Header: 'Creat',
          accessor: 'Suggestions_CreatedAt',
          Filter: ColumnFilter
        },
        {
          Header: 'Respós',
          accessor: 'Suggestions_Response',
          Filter: ColumnFilter
        }
      ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Id: item.Id,
          Suggestions_UserName:
            Number(item.RequesterId) === Number(user.Id)
              ? 'Jo'
              : item?.Requester?.Name,
          Suggestions_Reason: item.Reason
            ? item.Reason.length >= 30
              ? item.Reason.slice(0, 30).trim() + '...'
              : item.Reason
            : '-',
          Suggestions_Shed: item.Shed ? item.Shed : '-',
          Suggestions_Area: item.Area ? item.Area : '-',
          Suggestions_CreatedAt: item.CreatedAt
            ? formatDate(item.CreatedAt)
            : '-',
          Suggestions_Response:
            item.Response !== null && item.Response.trim().length !== 0,
          Suggestions_IsActive: item?.IsActive
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  const formatDate = (date) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const formattedDate = newDate.toLocaleDateString('es-ES', options)

    return formattedDate
  }

  return tableData.length > 0 ? (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  ) : (
    'No hi ha suggeriments de moment.'
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  userRoleId: PropTypes.number,
  currentUser: PropTypes.object,
  value: PropTypes.any
}
