import { ErrorMessage, Form, Formik, Field } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import Select from 'react-select'
import { CgSpinner } from 'react-icons/cg'
import { MdEdit } from 'react-icons/md'

export default function ClientCreate({ users, fields, handleSubmit }) {
  console.log('🚀 ~ ClientCreate ~ users:', users)
  const parentStyles = 'items-center block col-span-6 print:bg-white'
  const labelStyles =
    'block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full'
  const fieldStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerFieldStyles =
    'flex items-center w-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 min-h-8 overflow-ellipsis'

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required(
      '* No es pot crear una client sense especificar un nom.'
    )
  })

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={fields}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        handleSubmit(values)
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        props,
        handleBlur,
        handleChange,
        submitForm,
        isSubmitting
      }) => (
        <Form className="grid w-full grid-cols-12 gap-6 rounded">
          {/* EDICIÓ */}
          <div className={parentStyles}>
            <div className={labelStyles}>Edició:</div>
            <div className={fieldStyles}>
              <div className={innerFieldStyles}>{fields?.EditionNumber}</div>
            </div>
          </div>

          {/* CODI */}
          <div className={parentStyles}>
            <div className={labelStyles}>Codi:</div>
            <div className={`${fieldStyles} h-8`}>
              <Field
                type="text"
                name="Code"
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            </div>
          </div>

          {/* NOM */}
          <div className={parentStyles}>
            <div className={labelStyles}>Nom:</div>
            <div className={`${fieldStyles} h-8 flex-col`}>
              <Field
                type="text"
                name="Name"
                className={`w-full h-8 px-2 py-1 leading-tight border rounded-sm appearance-none focus:outline-none focus:shadow-outline ${
                  errors.Name
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                }`}
              />
            </div>

            <ErrorMessage
              name="Name"
              render={(message) => (
                <span className="block w-full text-sm text-red-600 text-grey-darker font-ms-semi">
                  {message}
                </span>
              )}
            />
          </div>

          {/* ETIQUETES */}
          <div className={parentStyles}>
            <div className={labelStyles}>Etiquetes:</div>
            <div className={`${fieldStyles} h-8`}>
              <Field
                type="text"
                name="Tag"
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            </div>
          </div>

          {/* POSICIÓ */}
          <div className={parentStyles}>
            <div className={labelStyles}>Posició:</div>
            <div className={`${fieldStyles} h-8`}>
              <Field
                type="text"
                name="Position"
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            </div>
          </div>

          {/* ESPECIFICACIONS BOBINA */}
          <div className={parentStyles}>
            <div className={labelStyles}>Especificacions bobina:</div>
            <div className={`${fieldStyles} h-8`}>
              <Field
                type="text"
                name="Notes"
                className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
              />
            </div>
          </div>

          {/* BOBINA */}
          <div className={parentStyles}>
            <div className={labelStyles}>Bobina:</div>
            <div className={`${fieldStyles} h-8`}>
              <Field
                className="w-5 h-5 text-gray-600 cursor-pointer"
                type="checkbox"
                name="Coil"
                onChange={(e) => {
                  setFieldValue('Coil', e.target.checked ? 'SI' : 'NO')
                }}
                checked={values.Coil === 'SI'}
              />
            </div>
          </div>

          {/* RESPONSABLES */}
          <div className={parentStyles}>
            <div className={labelStyles}>Responsables:</div>
            <div className={`${fieldStyles} min-h-8`}>
              <Select
                className={`text-grey-darker w-full`}
                onChange={(e) => {
                  values.Manager = []
                  for (const manager of e) {
                    values.Manager.push(manager.Id.toString())
                  }
                  setFieldValue(values.Manager)
                }}
                name="Manager"
                placeholder="Selecciona..."
                options={users}
                isMulti
                value={users?.filter((option) =>
                  values?.Manager?.includes(option.Id.toString())
                )}
                getOptionLabel={(o) => o.Name}
                getOptionValue={(o) => o.Id}
                closeMenuOnSelect={false}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: '0.125rem',
                    minHeight: '2rem',
                    cursor: 'pointer',
                    boxShadow: 'none'
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    height: '100%',
                    padding: '0.25rem 0.5rem',
                    position: 'static',
                    cursor: 'pointer',
                    rowGap: '0.25rem'
                  }),
                  multiValue: (baseStyles) => ({
                    ...baseStyles,
                    height: '100%',
                    margin: '0 0.25rem 0 0',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: '0',
                    paddingTop: '0',
                    paddingBottom: '0',
                    cursor: 'pointer'
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    marginLeft: '0',
                    marginRight: '0'
                  }),
                  indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    height: '2rem',
                    cursor: 'pointer'
                  })
                }}
              />
            </div>
          </div>

          {/* GUARDAR */}
          <div className={`${parentStyles} col-start-7`}>
            <button
              className={`float-right inline-flex items-center h-10 px-4 py-1 mt-1 text-white transition duration-300 bg-teal-500 border border-teal-500 rounded-sm font-ms-semi ${
                !isSubmitting &&
                'hover:border-teal-400 hover:bg-white hover:text-teal-500'
              }`}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CgSpinner size={22} className="animate-spin xl:mr-2" />
              ) : (
                <MdEdit className="xl:mr-2" size={22} />
              )}

              <span className="hidden xl:inline-flex">GUARDAR</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ClientCreate.propTypes = {
  users: PropTypes.array,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func
}
