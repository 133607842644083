import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { CgSpinner } from 'react-icons/cg'
import { MdEdit } from 'react-icons/md'
import Select from 'react-select'
import * as Yup from 'yup'

export default function CheckListForm({
  users,
  equipments,
  fields,
  handleSubmit
}) {
  const validationSchema = Yup.object().shape({
    UserId: Yup.number()
      .required('* Especificar un operari')
      .positive('* Especificar un operari')
      .integer('* Especificar un operari'),
    EquipmentId: Yup.number()
      .required('* Especificar un RAM')
      .positive('* Especificar un RAM')
      .integer('* Especificar un RAM'),
    Date: Yup.string().nullable().required('* Especificar una data')
  })

  const shedOptions = [
    { label: 'Cetriko', value: 'Cetriko' },
    { label: '4KF', value: '4KF' }
  ]

  const turnOptions = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' }
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...fields }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm,
        isSubmitting
      }) => (
        <Form className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md print:shadow-none print:bg-white print:border-0">
          <div className="flex justify-start w-full p-2 text-sm bg-blue-200 print:border-b print:border-blue-300 print:justify-center print:bg-transparent font-ms-bold print:text-2xl">
            CHECKLIST RAM
          </div>

          <div className="flex flex-col p-4 space-y-12 print:px-0 print:pb-0 print:pt-12">
            <div className="grid grid-cols-12 gap-4">
              {/* OPERARI */}
              <div className="items-center block col-span-4">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Operari:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full leading-tight print:hidden text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('UserId', e.Id)
                    setFieldValue('UserName', e.Name)
                  }}
                  name="UserId"
                  placeholder="Seleccionar..."
                  options={users}
                  getOptionLabel={(o) => o.Name}
                  getOptionValue={(o) => o.Id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused
                        ? errors.UserId && touched.UserId
                          ? '#f87171'
                          : '#9CA3AF'
                        : errors.UserId && touched.UserId
                        ? '#fecaca'
                        : '#D1D5DB',
                      '&:hover': {
                        borderColor:
                          errors.UserId && touched.UserId
                            ? '#f87171'
                            : '#9CA3AF'
                      },
                      backgroundColor:
                        errors.UserId && touched.UserId ? '#fef2f2' : '#ffffff'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={users?.find((option) => option.Id === values?.UserId)}
                />
                <ErrorMessage
                  name="UserId"
                  render={(message) => (
                    <span className="text-sm text-red-600">{message}</span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {users?.find((option) => option.Id === values?.UserId)?.Name}
                </div>
              </div>

              {/* RAM */}
              <div className="items-center block col-span-4">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  RAM:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full leading-tight print:hidden text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('EquipmentName', e.Description)
                    setFieldValue('EquipmentId', e.Id)
                  }}
                  name="EquipmentId"
                  placeholder="Seleccionar..."
                  options={equipments}
                  getOptionLabel={(o) => o.Description}
                  getOptionValue={(o) => o.Id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused
                        ? errors.EquipmentId && touched.EquipmentId
                          ? '#f87171'
                          : '#9CA3AF'
                        : errors.EquipmentId && touched.EquipmentId
                        ? '#fecaca'
                        : '#D1D5DB',
                      '&:hover': {
                        borderColor:
                          errors.EquipmentId && touched.EquipmentId
                            ? '#f87171'
                            : '#9CA3AF'
                      },
                      backgroundColor:
                        errors.EquipmentId && touched.EquipmentId
                          ? '#fef2f2'
                          : '#ffffff'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={equipments?.find(
                    (option) => option.Id === values?.EquipmentId
                  )}
                />
                <ErrorMessage
                  name="EquipmentId"
                  render={(message) => (
                    <span className="text-sm text-red-600">{message}</span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {
                    equipments?.find(
                      (option) => option.Id === values?.EquipmentId
                    )?.Description
                  }
                </div>
              </div>

              {/* DATA */}
              <div className="items-center block col-span-4 ">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Data:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="date"
                  name="Date"
                  className={`w-full leading-snug input-editable-standard print:hidden ${
                    errors.Date && touched.Date
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                  }`}
                />
                <ErrorMessage
                  name="Date"
                  render={(message) => (
                    <span className="text-sm text-red-600">{message}</span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.Date}
                </div>
              </div>

              {/* NAU */}
              <div className="items-center block col-span-4">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Nau:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full leading-tight print:hidden text-grey-darker"
                  onChange={(e) => setFieldValue('Shed', e.value)}
                  name="Shed"
                  placeholder="Seleccionar..."
                  options={shedOptions}
                  getOptionLabel={(o) => o.label}
                  getOptionValue={(o) => o.value}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                      '&:hover': {
                        borderColor: '#9CA3AF'
                      }
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={shedOptions?.find(
                    (option) => option.value === values?.Shed
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {
                    shedOptions?.find((option) => option.value === values?.Shed)
                      ?.label
                  }
                </div>
              </div>

              {/* TORN */}
              <div className="items-center block col-span-4">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Torn:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full leading-tight print:hidden text-grey-darker"
                  onChange={(e) => setFieldValue('Turn', e.value)}
                  name="Turn"
                  placeholder="Seleccionar..."
                  options={turnOptions}
                  getOptionLabel={(o) => o.label}
                  getOptionValue={(o) => o.value}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '0.125rem',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minHeight: 'auto',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                      '&:hover': {
                        borderColor: '#9CA3AF'
                      }
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    })
                  }}
                  value={turnOptions?.find(
                    (option) => option.value === values?.Turn
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {
                    turnOptions?.find((option) => option.value === values?.Turn)
                      ?.label
                  }
                </div>
              </div>

              {/* OF */}
              <div className="items-center block col-span-4">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  OF:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="text"
                  name="OF"
                  className="w-full input-editable-interactive print:hidden"
                  autoComplete="off"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.OF}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
              {/* NETEJA FILTRES */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  NETEJA FILTRES
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification1"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    S&apos;elimina Restos-Borra
                  </div>
                </div>
              </div>

              {/* MÀQUINA DE COSIR */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  MÀQUINA DE COSIR
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification2"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    En bon estat
                  </div>
                </div>
              </div>

              {/* VERIFICACIÓ PRIMERS METRES */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  VERIFICACIÓ PRIMERS METRES
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification15"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Estat agulles de la cadena OK
                  </div>
                </div>
              </div>

              {/* NETEJA FOULAR */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  NETEJA FOULAR
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification6"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es buida i es neteja
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification7"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Hi ha producte (Neteja final prod.)
                  </div>
                </div>
              </div>

              {/* VISIÓ ARTIFICIAL */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  VISIÓ ARTIFICIAL
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification8"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Està en bon estat
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification9"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es bufa ordinador, leds, càmeres, ...
                  </div>
                </div>
              </div>

              {/* COMPROBAR ESTAT DRAP */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  COMPROBAR ESTAT DRAP
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification11"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Està en bones condicions
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification10"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es canvia (dilluns matí / dijous tarda)
                  </div>
                </div>
              </div>

              {/* NETEJA CORRONS */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  NETEJA CORRONS
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification3"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    S&apos;elimina Restos-Borra
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification4"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Les gomes estan en bon estat
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification5"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Les gomes s&apos;han de canviar
                  </div>
                </div>
              </div>

              {/* COMPROBAR ENCOLADOR */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  COMPROBAR ENCOLADOR
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification12"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es canvien
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification13"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es netejen
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification14"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Estan en bones condicions
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification16"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Estan ajustats a les vores
                  </div>
                </div>
              </div>

              {/* VERIFICACIÓ USUARI */}
              <div className="items-center block col-span-4">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  VERIFICACIÓ USUARI
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="IsOK"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Verificació usuari
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4">
              {/* DESCRIPCIÓ */}
              <div className="items-center block col-span-8 print:col-span-12">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Descripció:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  as="textarea"
                  name="Description"
                  className="w-full h-48 text-sm whitespace-pre-wrap input-editable-interactive print:hidden print:resize-none"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full p-0.5 text-base leading-tight border rounded-sm border-gray-300 min-h-8">
                  {values?.Description}
                </div>
              </div>

              {/* GUARDAR */}
              <div className="flex items-end justify-end col-span-4 print:hidden">
                <button
                  className={`inline-flex items-center h-10 px-4 py-1 text-white transition duration-300 bg-teal-500 border border-teal-500 rounded-sm font-ms-semi ${
                    !isSubmitting &&
                    'hover:border-teal-500 hover:bg-white hover:text-teal-500'
                  }`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CgSpinner size={22} className="animate-spin xl:mr-2" />
                  ) : (
                    <MdEdit className="xl:mr-2" size={22} />
                  )}

                  <span className="hidden xl:inline-flex">GUARDAR</span>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

CheckListForm.propTypes = {
  users: PropTypes.array,
  equipments: PropTypes.array,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired
}
