import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import UserForm from 'forms/user/userform'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { toast } from 'react-toastify'
import { useLocation } from 'wouter'

export default function UserDetail({ params }) {
  // useLocation():
  const [, setLocation] = useLocation()

  // useContext():
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)

  // useFetch():
  const [getSaveNotification, setSaveNotification] = useFetch()
  const [getUsers, setUsers] = useFetch()
  const [getUser, setUser] = useFetch()
  const [getOperators, setOperators] = useFetch()
  const [getSavedRecord, saveRecord] = useFetch()

  // useState():
  const [getUsersWhoReceiveNotification, setUsersWhoReceiveNotification] =
    useState(0)
  const [getSaveNotificationErrors, setSaveNotificationErrors] = useState(0)
  const [getSaveNotificationSent, setSaveNotificationSent] = useState(0)
  const [getUserToCreateOrModify, setUserToCreateOrModify] = useState()
  const [getNotificationReason, setNotificationReason] = useState()

  const isNew = params.id === 'nou'

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API:
  const [fields, setFields] = useState({
    Id: 0,
    Email: '',
    Name: '',
    Password: null,
    Photo: null,
    Pin: '',
    IdProfile: 2,
    NotifyFinishedTechnicalSheetRamRequest: false,
    NotifyFinishedTechnicalSheetChange: false,
    NotifyOnWeavingTechnicalSheetChange: false,
    NotifyOnClientChange: false,
    NotifyOnUserChange: false,
    CanEditWeavingTechnicalSheet: false,
    FinishedTechnicalSheetEditPermission: false,
    IsRemoved: false
  })

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // modificar un registro estableceremos como título de la página 'Modificar':
  const titleEdit = {
    name: `Modificar registre`,
    buttons: [
      {
        name: 'Tornar',
        link: `/usuaris`
      }
    ],
    userId: params.id
  }

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // crear un nuevo registro estableceremos como título de la página 'Crear':
  const titleCreate = {
    name: `Crear registre`,
    buttons: [
      {
        name: 'Tornar',
        link: `/usuaris`
      }
    ]
  }

  useEffect(async () => {
    // Si el id del registro no es 'nou' significa que el usuario quiere editar un registro ya existente:
    if (params.id !== 'nou') {
      // Pondremos el título de editar:
      setTitle(titleEdit)

      const userAPIParams = {
        url: `user/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      setUser(userAPIParams)
    } else {
      // Pondremos el título de crear:
      setTitle(titleCreate)
    }

    const operatorsAPIParams = {
      url: `operator`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    setOperators(operatorsAPIParams)

    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    setUsers(userListAPIParams)
  }, [])

  useEffect(() => {
    if (getUser.data) {
      setFields({
        Id: getUser.data.Id,
        Email: getUser.data.Email,
        Name: getUser.data.Name,
        Photo: getUser.data.Photo,
        Pin: getUser.data.Pin,
        IdProfile: getUser.data.IdProfile,
        NotifyFinishedTechnicalSheetRamRequest:
          getUser.data.NotifyFinishedTechnicalSheetRamRequest,
        NotifyFinishedTechnicalSheetChange:
          getUser.data.NotifyFinishedTechnicalSheetChange,
        NotifyOnWeavingTechnicalSheetChange:
          getUser.data.NotifyOnWeavingTechnicalSheetChange,
        NotifyOnClientChange: getUser.data.NotifyOnClientChange,
        NotifyOnUserChange: getUser.data.NotifyOnUserChange,
        CanEditWeavingTechnicalSheet: getUser.data.CanEditWeavingTechnicalSheet,
        FinishedTechnicalSheetEditPermission:
          getUser.data.FinishedTechnicalSheetEditPermission,
        IsRemoved: getUser.data.IsRemoved
      })
    }
  }, [getUser.data])

  const handleSubmit = async (values) => {
    const urlParams = isNew ? '' : values.Id
    const method = isNew ? 'POST' : 'PUT'
    const action = isNew ? 'creat' : 'actualitzat'

    // Establecemos los usuarios a los que se les debe crear una notificación:
    setUsersWhoReceiveNotification(
      getUsers.data?.filter((user) => user.NotifyOnUserChange)
    )

    // Establecemos el usuario que creemos crear o editar:
    setUserToCreateOrModify(values)

    // Establecemos el tipo de modificación:
    setNotificationReason(1)

    const saveRecordApi = {
      url: `user/${urlParams}`,
      method: method,
      body: values,
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: `Usuari ${action}!`
    }
    await saveRecord(saveRecordApi)
  }

  // Función para habilitar/deshabilitar un usuario:
  const handleChangeState = async (values) => {
    // Establecemos los usuarios a los que se les debe crear una notificación:
    setUsersWhoReceiveNotification(
      getUsers.data?.filter((user) => user.NotifyOnUserChange)
    )

    // Establecemos el usuario que creemos crear o editar:
    setUserToCreateOrModify(values)

    // Establecemos el tipo de modificación:
    setNotificationReason(2)

    const changeState = {
      url: `user/${values.IsRemoved ? 'enableUser' : 'disableUser'}/${
        params.id
      }`,
      method: 'PUT',
      messageKo: `Error al ${
        values.IsRemoved ? 'habilitar' : 'deshabilitar'
      } l'usuari!`,
      messageOk: `Usuari ${values.IsRemoved ? 'habilitat' : 'deshabilitat'}!`
    }
    await saveRecord(changeState)
  }

  const handleResetPass = async (values) => {
    // Establecemos los usuarios a los que se les debe crear una notificación:
    setUsersWhoReceiveNotification(
      getUsers.data?.filter((user) => user.NotifyOnUserChange)
    )

    // Establecemos el usuario que creemos crear o editar:
    setUserToCreateOrModify(values)

    // Establecemos el tipo de modificación:
    setNotificationReason(3)

    values.Password = 'cetriko2021'

    const resetPassApiParams = {
      url: `user/resetpass/${params.id}`,
      method: 'PUT',
      body: values,
      messageKo: 'Error al reiniciar la contrasenya!',
      messageOk: 'Contrasenya reiniciada correctament!'
    }
    await saveRecord(resetPassApiParams)
  }

  useEffect(async () => {
    if (getSavedRecord.data) {
      if (getSavedRecord.error === null) {
        // 'Promise' en este caso, se usa para hacer múltiples llamadas a la API en fila, una tras otra.
        // Mejora el tiempo de carga de la página. Si hacemos cada llamada a la API por separado,
        // provoca una carga más lenta de la página, para poner en contexto, el 'Promise' ahorra 3s de carga:
        await Promise.all([
          getUsersWhoReceiveNotification.map(async (usuari) => {
            // Creamos una notificación
            const notification = {
              Date: null,
              NotificationName: getUserToCreateOrModify.Name,
              Description:
                getNotificationReason === 1
                  ? `S'ha modificat l'usuari ${getUserToCreateOrModify.Name}`
                  : getNotificationReason === 2
                  ? `S'ha ${
                      getUserToCreateOrModify.IsRemoved
                        ? 'habilitat'
                        : 'deshabilitat'
                    } l'usuari ${getUserToCreateOrModify.Name}`
                  : getNotificationReason === 3
                  ? `S'ha resetejat la contrasenya a l'usuari ${getUserToCreateOrModify.Name}`
                  : '',
              UserId: usuari.Id,
              UserDone: user.Name,
              Read: false,
              Section: 'Usuaris',
              Link: `/usuari/${params.id}`
            }

            // Llamada a la API para guardar una notificación:
            const saveNotificacion = {
              url: 'notification',
              method: 'POST',
              body: notification,
              messageKo: `Error al enviar la notificació a ${usuari.Name}`
            }
            await setSaveNotification(saveNotificacion)
          })
        ]).catch((e) => {
          console.log(e)
        })
      }
    }
  }, [getSavedRecord.data])

  // Función para informar que todas las notificaciones se crean correctamente:
  useEffect(() => {
    if (getSaveNotification.data) {
      let errorsCount = getSaveNotificationErrors
      const sentNotificationsCount = getSaveNotificationSent

      // Si una llamada a la API da error contamos los errores:
      if (getSaveNotification.error !== null) {
        errorsCount = errorsCount + 1
        setSaveNotificationErrors(errorsCount)
      }

      // Si es la última llamada a la API, mostramos un mensaje y redirigimos a otra pantalla:
      if (
        getSaveNotificationSent ===
        getUsersWhoReceiveNotification.length - 1
      ) {
        if (errorsCount === 0) {
          toast.success(`Notificacions enviades correctament!`, {
            position: toast.POSITION.TOP_CENTER
          })
        } else if (errorsCount === getUsersWhoReceiveNotification.length - 1) {
          toast.error(`Ha fallat l'enviament de les notificacions!`, {
            position: toast.POSITION.TOP_CENTER
          })
        } else {
          toast.warning(
            `Enviades totes les notificacions menys ${errorsCount}!`,
            {
              position: toast.POSITION.TOP_CENTER
            }
          )
        }

        setLocation('/usuaris')
      }

      // Al hacer las llamadas a la API con un 'Promise.all()' se ejecutan todas las llamadas una tras otra y luego cuando se terminan
      // todas se entra en este 'useEffect()' una vez por cada llamada a la API, por eso necesitamos un contador extra para saber cuándo se
      // entrará por última vez en este 'useEffect()':
      setSaveNotificationSent(sentNotificationsCount + 1)
    }
  }, [getSaveNotification.data])

  return fields === null ||
    getOperators.data === null ||
    getOperators.data === undefined ||
    getOperators.data[0] === null ? (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  ) : (
    <div className="w-full p-4">
      <UserForm
        fields={fields}
        setFields={setFields}
        operatorsList={getOperators.data}
        handleSubmit={handleSubmit}
        handleChangeState={handleChangeState}
        handleResetPass={handleResetPass}
      />
    </div>
  )
}

UserDetail.propTypes = {
  params: PropTypes.any.isRequired
}
