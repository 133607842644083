import ListTable from 'components/ClientsRequest/ListTable/ListTable'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function FTAcabatsRequestList({ params }) {
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const [clients, clientsAction] = useFetch()

  useEffect(async () => {
    const requestsTitle = {
      name: 'Historial',
      buttons: [{ name: 'Tornar', link: `/client/${params.id}` }]
    }
    setTitle(requestsTitle)

    const clientsAPIParams = {
      url: `client/${params.id}/requests`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    await clientsAction(clientsAPIParams)
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/client/request-view/${params.id}/${item.RequestId}`)
  }

  return clients.data ? (
    <div className="w-full p-4">
      <ListTable items={clients.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

FTAcabatsRequestList.propTypes = {
  params: PropTypes.any.isRequired
}
