// FORMIK HOOK
import { Field } from 'formik'

export default function ControlsForm() {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* V.A. (EVS) ESTIL */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V.A. (EVS) Estil:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.ControlStyle"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* CONTROL Tª */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Control Tª:
      </div>
      <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="grid items-center w-full grid-cols-12 gap-3 auto-rows-min">
          {/* VMT */}
          <div className="col-span-2 text-sm print:col-span-2">
            VMT
          </div>
          <Field
            type="text"
            name="RequestArticle.VMT"
            className={`col-span-10 h-8 w-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          />

          {/* OMT */}
          <div className="col-span-2 text-sm print:col-span-2">
            OMT
          </div>
          <Field
            type="text"
            name="RequestArticle.OMT"
            className={`col-span-10 w-full h-8 px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          />

          {/* MAN */}
          <div className="col-span-2 text-sm print:col-span-2">
            MAN
          </div>
          <Field
            type="text"
            name="RequestArticle.MAN"
            className={`col-span-10 w-full h-8 px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          />

          {/* AUT */}
          <div className="col-span-2 text-sm print:col-span-2">
            AUT
          </div>
          <Field
            type="text"
            name="RequestArticle.AUT"
            className={`col-span-10 w-full h-8 px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          />
        </div>
      </div>

      {/* TEMPS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Temps:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.ControlTime"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <span>{`"`}</span>
      </div>

      {/* TEMPERATURA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Temperatura:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.ControlTemperature"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <span>ºC</span>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.ControlObservation"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>
    </div>
  )
}

ControlsForm.propTypes = {}
