import { useLocation } from 'wouter'
import { useContext, useEffect, useState } from 'react'
import TreballadorForm from 'forms/RRHH/Treballadors/TreballadorForm'
import PropTypes from 'prop-types'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { ModalContext } from 'contexts/ModalContext'

export default function TreballadorsDetail({ params }) {
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [getClient, setGetClient] = useFetch()
  const [client, saveClient] = useFetch()
  const { handleModal } = useContext(ModalContext)
  const [deletedDocument, deleteDocumentAction] = useFetch()
  const [documentAction, saveDocumentAction] = useFetch()
  const [addDocumentsToTable, setAddDocumentsToTable] = useState([])
  const isNew = params.id === 'nou'

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API: 
  const [fields, setFields] = useState({
    Id:                   0, 
    Name:                 "",
    Tipu:                 "",
    Ett:                  false,
    Nau:                  "",
    Vigent:               false,
    fixe:                 false,
    Temporal:             false,
    Empresa:              null,
    areaactual:           null,
    Area_Id:              null,
    JC:                   0,
    Jornada:              "",
    IsRemoved:            false,
    TreballadorDocuments: [],
    Versatilities:        [],
    TrainingPlansUser:    []
  })

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // modificar un registro estableceremos como título de la página 'Modificar':
  const titleEdit = {
    name: `Modificar registre `,
    buttons: [      
      {
        name: 'Tornar',
        link: `/treballadors`
      }
    ],
    deleteTreballadorBtn: true,
    jobId: params.id
  }

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // crear un nuevo registro estableceremos como título de la página 'Crear':
  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/treballadors`
      }
    ]
  }

  useEffect(async () => {
    // Si el id del registro no es 'nou' significa que el usuario quiere editar un registro ya existente:
    if (params.id !== 'nou') {
      // Pondremos el título de editar:
      setTitle(titleEdit)

      // Llamamos a la API para recoger el registro que se quiere editar:
      const findClientById = {
        url: `treballador/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      setGetClient(findClientById)
    } else {
      // Pondremos el título de crear:
      setTitle(titleCreate)
    }
  }, [])  

  // useEffect() para crear un objeto con los datos que nos llegan de la API, para eso se usa el useState() [fields, setFields]:
  useEffect(() => {
    if (getClient.data){
      setFields({
        Id:                   getClient.data.Id,
        Name:                 getClient.data.Name,
        Tipu:                 getClient.data.Tipu,
        Ett:                  getClient.data.Ett,
        Nau:                  getClient.data.Nau,
        Vigent:               getClient.data.Vigent,
        fixe:                 getClient.data.fixe,
        Temporal:             getClient.data.Temporal,
        Empresa:              getClient.data.Empresa_Id ? getClient.data.Empresa_Id.Id : null,
        areaactual:           getClient.data.Area_Id ? getClient.data.Area_Id.Id : null,
        Area_Id:              getClient.data.Area_Id ? getClient.data.Area_Id : null,
        JC:                   getClient.data.JC,
        Jornada:              getClient.data.Jornada,
        IsRemoved:            getClient.data.IsRemoved,
        TreballadorDocuments: getClient.data.TreballadorDocuments,
        Versatilities:        getClient.data.Versatilities,
        TrainingPlansUser:    getClient.data.TrainingPlansUser
      })
      
      setAddDocumentsToTable(getClient.data.TreballadorDocuments)
    }    
  }, [getClient.data])

  // Función para guardar el documento:
  const handleSubmit = async (values) => {
    // Importante poner 'null' a '*Documents' sinó cada vez que se modifica un registro
    // se duplican todos los registros:
    const treballadorFieldsToSave = ({
      Id:                   params.id, 
      Name:                 values.Name,
      Tipu:                 values.Tipu,
      Ett:                  values.Ett,
      Nau:                  values.Nau,
      Vigent:               values.Vigent,
      fixe:                 values.fixe,
      Temporal:             values.Temporal,
      Empresa:              values.Empresa,
      areaactual:           values.areaactual,
      JC:                   values.JC,
      Jornada:              values.Jornada,
      IsRemoved:            values.IsRemoved,
      TreballadorDocuments: null
    })

    let url = `treballador`
    let method = 'post'

    if (treballadorFieldsToSave.Id !== 'nou') {
      method = 'put'
      url += `/${treballadorFieldsToSave.Id}`
    }
    
    // Send PUT //
    const clientApiParams = {
      url,
      method,
      body: treballadorFieldsToSave,
      messageKo: 'Error al actualitzar el treballador!',
      messageOk: 'Treballador actualitzat!'
    }
    await saveClient(clientApiParams)

    if(!client.error)setLocation('/treballadors')
  }

  // Función para añadir un nuevo documento:
  const uploadDocument = async (e) => {
    const files = e.currentTarget.files  

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    // Llamada a la API para añadir el nuevo archivo:
    const jobParams = {
      url: `treballador/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Document guardat!',
      messageKo: 'Error al guardar el document!'
    }
    await saveDocumentAction(jobParams)
  }

  // useEffect() para la función uploadDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if(documentAction.data) {
      const stringToJsonParseDocuments = JSON.parse(documentAction.data)
      setAddDocumentsToTable(stringToJsonParseDocuments)
    }
  }, [documentAction.data])

  // Función para eliminar un fichero de la tabla de ficheros adjuntos:
  const deleteDocument = async (id) => {
    // Llamada a la API para eliminar el documento seleccionado:
    const deleteDocumentParams = {
      url: `treballador/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Document eliminat!',
      messageKo: 'Error al eliminar el document!'
    }
    await deleteDocumentAction(deleteDocumentParams)

    handleModal('hide')
  }

  // useEffect() para la función deleteDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if(deletedDocument.data) {
      setAddDocumentsToTable(deletedDocument.data)
    }
  }, [deletedDocument.data])
  
  return (
    <>
      <div className="w-full">
        <div className="w-full p-4 bg-white borde-2 rounded-lg" style={{ borderColor: "whitesmoke" }}>
          {
            <TreballadorForm        
              handleSubmit={handleSubmit}
              fields={fields}
              uploadDocument={uploadDocument}
              deleteDocument={deleteDocument}
              addDocumentsToTable={addDocumentsToTable}
              params={params}
              isNew={isNew}
            />
          }  
        </div>
      </div>
    </>
  )
}

TreballadorsDetail.propTypes = {
  params: PropTypes.any
}