import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import { Field } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { CgSpinner } from 'react-icons/cg'
import ThreadTable from './threadTableCompare'

export default function DescriptionForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter,
  threads
}) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([setApiCallSemiFinished(apiCallGetSemiFinished)]).catch(
      (e) => {
        console.log(e)
      }
    )
  }, [])

  // Calcular los estilos
  const getFieldStyles = (field) => {
    const hasChanged = valuesBefore[field] !== valuesAfter[field]

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Semielaborat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Semielaborat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('SemiFinished')}
          value={
            side === 'left'
              ? valuesBefore?.SemiFinished
              : valuesAfter?.SemiFinished
          }
          disabled
        />
      </div>

      {/* Tipus lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus lligat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        {!getApiCallGetSemiFinished?.data ? (
          <div className="w-full px-2 py-1 bg-gray-100 border border-gray-300 rounded-sm">
            <CgSpinner size={20} className="text-charcoal animate-spin" />
          </div>
        ) : (
          <Field
            type="text"
            className="w-full block-input-disabled"
            style={{
              backgroundColor:
                getApiCallGetSemiFinished?.data?.find(
                  (option) => option.semi === valuesBefore.SemiFinished
                )?.ligado !==
                getApiCallGetSemiFinished?.data?.find(
                  (option) => option.semi === valuesAfter.SemiFinished
                )?.ligado
                  ? side === 'left'
                    ? '#FEE2E2'
                    : isAnswered
                    ? '#D1FAE5'
                    : '#FEF3C7'
                  : '#F3F4F6',
              border:
                getApiCallGetSemiFinished?.data?.find(
                  (option) => option.semi === valuesBefore.SemiFinished
                )?.ligado !==
                getApiCallGetSemiFinished?.data?.find(
                  (option) => option.semi === valuesAfter.SemiFinished
                )?.ligado
                  ? side === 'left'
                    ? '1px solid #FCA5A5'
                    : isAnswered
                    ? '1px solid #FFFFFF'
                    : '1px solid #FCD34D'
                  : '1px solid #D1D5DB'
            }}
            value={
              side === 'left'
                ? getApiCallGetSemiFinished?.data?.find(
                    (option) => option.semi === valuesBefore.SemiFinished
                  )?.ligado
                : getApiCallGetSemiFinished?.data?.find(
                    (option) => option.semi === valuesAfter.SemiFinished
                  )?.ligado
            }
            disabled
          />
        )}
      </div>

      {/* Tabla: Ideal | Tol.Màx. */}
      <div className="grid grid-cols-12 col-span-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                Ideal
              </div>

              <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                Tol. Màx.
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Passades': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Passades:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid w-full grid-cols-12 col-span-10 text-sm">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('Repetitions')}
                  value={
                    side === 'left'
                      ? valuesBefore?.Repetitions
                      : valuesAfter?.Repetitions
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RepetitionsTolerance')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RepetitionsTolerance
                      : valuesAfter?.RepetitionsTolerance
                  }
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RepetitionsV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RepetitionsV2
                      : valuesAfter?.RepetitionsV2
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RepetitionsToleranceV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RepetitionsToleranceV2
                      : valuesAfter?.RepetitionsToleranceV2
                  }
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Columnes': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Columnes:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid w-full grid-cols-12 col-span-10 text-sm">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('Columns')}
                  value={
                    side === 'left'
                      ? valuesBefore?.Columns
                      : valuesAfter?.Columns
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('ColumnsTolerance')}
                  value={
                    side === 'left'
                      ? valuesBefore?.ColumnsTolerance
                      : valuesAfter?.ColumnsTolerance
                  }
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('ColumnsV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.ColumnsV2
                      : valuesAfter?.ColumnsV2
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('ColumnsToleranceV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.ColumnsToleranceV2
                      : valuesAfter?.ColumnsToleranceV2
                  }
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gramatge': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gramatge:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid w-full grid-cols-12 col-span-10 text-sm">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('Weight')}
                  value={
                    side === 'left' ? valuesBefore?.Weight : valuesAfter?.Weight
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('WeightTolerance')}
                  value={
                    side === 'left'
                      ? valuesBefore?.WeightTolerance
                      : valuesAfter?.WeightTolerance
                  }
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('WeightV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.WeightV2
                      : valuesAfter?.WeightV2
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('WeightToleranceV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.WeightToleranceV2
                      : valuesAfter?.WeightToleranceV2
                  }
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>g/m2</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Ample': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Ample:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid w-full grid-cols-12 col-span-10 text-sm">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('Width')}
                  value={
                    side === 'left' ? valuesBefore?.Width : valuesAfter?.Width
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('WidthTolerance')}
                  value={
                    side === 'left'
                      ? valuesBefore?.WidthTolerance
                      : valuesAfter?.WidthTolerance
                  }
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('WidthV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.WidthV2
                      : valuesAfter?.WidthV2
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('WidthToleranceV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.WidthToleranceV2
                      : valuesAfter?.WidthToleranceV2
                  }
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gruix': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gruix:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid w-full grid-cols-12 col-span-10 text-sm">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('Thickness')}
                  value={
                    side === 'left'
                      ? valuesBefore?.Thickness
                      : valuesAfter?.Thickness
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('ThicknessTolerance')}
                  value={
                    side === 'left'
                      ? valuesBefore?.ThicknessTolerance
                      : valuesAfter?.ThicknessTolerance
                  }
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('ThicknessV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.ThicknessV2
                      : valuesAfter?.ThicknessV2
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('ThicknessToleranceV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.ThicknessToleranceV2
                      : valuesAfter?.ThicknessToleranceV2
                  }
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>mm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Rdt Nominal': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Rdt Nominal:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid w-full grid-cols-12 col-span-10 text-sm">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RDTNominal')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RDTNominal
                      : valuesAfter?.RDTNominal
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RDTNominalTolerance')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RDTNominalTolerance
                      : valuesAfter?.RDTNominalTolerance
                  }
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RDTNominalV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RDTNominalV2
                      : valuesAfter?.RDTNominalV2
                  }
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  className={`w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none`}
                  style={getFieldStyles('RDTNominalToleranceV2')}
                  value={
                    side === 'left'
                      ? valuesBefore?.RDTNominalToleranceV2
                      : valuesAfter?.RDTNominalToleranceV2
                  }
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>m/kg</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Comentari': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="block col-span-12 my-1 text-sm text-grey-darker font-ms-semi">
            Comentari:
          </div>

          <Field
            as="textarea"
            className="col-span-12 text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
            style={getFieldStyles('LaboratoryComment')}
            value={
              side === 'left'
                ? valuesBefore?.LaboratoryComment
                : valuesAfter?.LaboratoryComment
            }
            disabled
          />
        </div>
      </div>

      {/* Lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Lligat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('TiedDescription')}
          value={
            side === 'left'
              ? valuesBefore?.TiedDescription
              : valuesAfter?.TiedDescription
          }
          disabled
        />
      </div>

      {/* Diagrama: */}
      <div className="flex col-span-12 rounded">
        <p className="mr-1 text-right">
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
          10
          <br />
          11
          <br />
          12
          <br />
        </p>

        <Field
          as="textarea"
          className="w-full px-2 py-1 pt-0 overflow-hidden leading-6 whitespace-pre-line border rounded-sm resize-none faden"
          style={getFieldStyles('EncryptedCode')}
          value={
            side === 'left'
              ? valuesBefore?.EncryptedCode
              : valuesAfter?.EncryptedCode
          }
          disabled
        />

        <div
          onClick={() =>
            handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
          }
          className="cursor-pointer"
          style={{ height: 'fit-content' }}
        >
          <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
        </div>
      </div>

      {/* Màquina */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Màquina:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-4/12 block-input-disabled"
          style={getFieldStyles('MachineCode')}
          value={
            side === 'left'
              ? valuesBefore?.MachineCode
              : valuesAfter?.MachineCode
          }
          disabled
        />

        <Field
          type="text"
          className="w-8/12 block-input-disabled"
          style={getFieldStyles('Machine')}
          value={side === 'left' ? valuesBefore?.Machine : valuesAfter?.Machine}
          disabled
        />
      </div>

      {/* Plegador */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Plegador:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('FoldType')}
          value={
            side === 'left' ? valuesBefore?.FoldType : valuesAfter?.FoldType
          }
          disabled
        />
      </div>

      {/* GG - ø */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        GG - ø:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('GG')}
          value={side === 'left' ? valuesBefore?.GG : valuesAfter?.GG}
          disabled
        />

        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('O')}
          value={side === 'left' ? valuesBefore?.O : valuesAfter?.O}
          disabled
        />
      </div>

      {/* Nº Jocs */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nº Jocs:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('NGame')}
          value={side === 'left' ? valuesBefore?.NGame : valuesAfter?.NGame}
          disabled
        />
      </div>

      {/* Agulles */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Agulles:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('Needle')}
          value={side === 'left' ? valuesBefore?.Needle : valuesAfter?.Needle}
          disabled
        />
      </div>

      {/* Rpm */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('Rpm')}
          value={side === 'left' ? valuesBefore?.Rpm : valuesAfter?.Rpm}
          disabled
        />
      </div>

      {/* Rpm Mín. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm Mín.:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('RpmMin')}
          value={side === 'left' ? valuesBefore?.RpmMin : valuesAfter?.RpmMin}
          disabled
        />
      </div>

      {/* V. / Kg */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Kg:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('TurnsPerKg')}
          value={
            side === 'left' ? valuesBefore?.TurnsPerKg : valuesAfter?.TurnsPerKg
          }
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('TurnsPerKgTolerance')}
          value={
            side === 'left'
              ? valuesBefore?.TurnsPerKgTolerance
              : valuesAfter?.TurnsPerKgTolerance
          }
          disabled
        />
      </div>

      {/* V. / Peça (Reals) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Reals):
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('TurnsPerPart')}
          value={
            side === 'left'
              ? valuesBefore?.TurnsPerPart
              : valuesAfter?.TurnsPerPart
          }
          disabled
        />
      </div>

      {/* V. / Peça (Fitxa) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Fitxa):
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('TurnsPerPartByOperator')}
          value={
            side === 'left'
              ? valuesBefore?.TurnsPerPartByOperator
              : valuesAfter?.TurnsPerPartByOperator
          }
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('TurnsPerPartByOperatorTolerance')}
          value={
            side === 'left'
              ? valuesBefore?.TurnsPerPartByOperatorTolerance
              : valuesAfter?.TurnsPerPartByOperatorTolerance
          }
          disabled
        />
      </div>

      {/* Kg peça */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Kg peça:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('KgPart')}
          value={side === 'left' ? valuesBefore?.KgPart : valuesAfter?.KgPart}
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          className="w-full block-input-disabled"
          style={getFieldStyles('KgPartTolerance')}
          value={
            side === 'left'
              ? valuesBefore?.KgPartTolerance
              : valuesAfter?.KgPartTolerance
          }
          disabled
        />
      </div>

      {/* Pass. Seguretat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Pass. Seguretat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className={`${
            valuesBefore?.SecurityStep === 'true' ||
            valuesBefore?.SecurityStep ||
            valuesAfter?.SecurityStep === 'true' ||
            valuesAfter?.SecurityStep
              ? 'w-4/12'
              : 'w-full'
          } block-input-disabled`}
          style={getFieldStyles('SecurityStep')}
          value={
            side === 'left'
              ? valuesBefore?.SecurityStep
                ? 'Sí'
                : 'No'
              : valuesAfter?.SecurityStep
              ? 'Sí'
              : 'No'
          }
          disabled
        />

        {(valuesBefore?.SecurityStep === 'true' ||
          valuesBefore?.SecurityStep ||
          valuesAfter?.SecurityStep === 'true' ||
          valuesAfter?.SecurityStep) && (
          <Field
            type="text"
            className="w-full block-input-disabled"
            style={getFieldStyles('SecurityStepDescription')}
            value={
              side === 'left'
                ? valuesBefore?.SecurityStepDescription === undefined ||
                  !valuesBefore?.SecurityStepDescription
                  ? 'Segons cartell'
                  : valuesBefore?.SecurityStepDescription
                : valuesAfter?.SecurityStepDescription === undefined ||
                  !valuesAfter?.SecurityStepDescription
                ? 'Segons cartell'
                : valuesAfter?.SecurityStepDescription
            }
            disabled
          />
        )}
      </div>

      {/* Tabla: Hilos */}
      <div className="w-full col-span-12">
        <ThreadTable
          threads={threads}
          side={side}
          isAnswered={isAnswered}
        />
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object,
  threads: PropTypes.any
}
