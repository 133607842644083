// PropTypes
import PropTypes from 'prop-types'

export default function ClientForm({ users, values }) {
  const labelStyles = 'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const fieldStyles = 'flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9 cursor-not-allowed'
  const innerFieldStyles = 'flex items-center w-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis'

  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* CODI */}
      <div className={labelStyles}>Code:</div>
      <div className={`${fieldStyles} h-8`}>
        <div className={`${innerFieldStyles} h-full`}>{values?.Code}</div>
      </div>

      {/* NOM */}
      <div className={labelStyles}>Nom:</div>
      <div className={`${fieldStyles} h-8`}>
        <div className={`${innerFieldStyles} h-full`}>{values?.Name}</div>
      </div>

      {/* ETIQUETES */}
      <div className={labelStyles}>Etiquetes:</div>
      <div className={`${fieldStyles} h-8`}>
        <div className={`${innerFieldStyles} h-full`}>
          {values?.Tag === 0 ? '' : values?.Tag}
        </div>
      </div>

      {/* POSICIÓ */}
      <div className={labelStyles}>Posició:</div>
      <div className={`${fieldStyles} h-8`}>
        <div className={`${innerFieldStyles} h-full`}>{values?.Position}</div>
      </div>

      {/* ESPECIFICACIONS BOBINA */}
      <div className={labelStyles}>Especificacions Bobina:</div>
      <div className={`${fieldStyles} h-8`}>
        <div className={`${innerFieldStyles} h-full`}>{values?.Notes}</div>
      </div>

      {/* BOBINA */}
      <div className={labelStyles}>Bobina:</div>
      <div className={`${fieldStyles} h-8`}>
        <div className={`${innerFieldStyles} h-full`}>
          {values?.Coil ? (values?.Coil === 'SI' ? 'Sí' : 'No') : 'No'}
        </div>
      </div>

      {/* MANAGER */}
      <div className={labelStyles}>Responsables:</div>
      <div className={`${fieldStyles} min-h-8`}>
        <div className={`${innerFieldStyles} min-h-8`}>
          {values?.Manager?.length > 0
            ? values?.Manager?.map((managerId, index, array) => {
                const manager = users.find(
                  (user) => user.Id === Number(managerId)
                )
                const isLastItem = index === array.length - 1

                return (
                  <span className="capitalize" key={manager?.Id ?? index}>
                    {manager?.Name}
                    {!isLastItem && ','}&nbsp;
                  </span>
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )
}

ClientForm.propTypes = {
  users: PropTypes.array,
  values: PropTypes.object
}
