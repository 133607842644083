import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { CgSpinner } from 'react-icons/cg'
import { MdSend } from 'react-icons/md'
import Select from 'react-select'
import * as Yup from 'yup'

export default function SuggestionForm({
  handleSubmit,
  fields,
  isNew,
  userInfo,
  areas,
  warehouses,
  user
}) {
  const canRespond = userInfo.NotifyOnSuggestionChange && user.Role.Id === 1

  const validationSchemaRequester = Yup.object().shape({
    Area: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar una àrea.)'
    ),
    Shed: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar una nau.)'
    ),
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    )
  })

  const validationSchemaResponder = Yup.object().shape({
    Response: Yup.string()
      .nullable()
      .required(
        '(*No es pot respondre a una sol·licitud sense especificar la resposta.)'
      )
  })

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={
        canRespond ? validationSchemaResponder : validationSchemaRequester
      }
      initialValues={{ ...fields }}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm,
        isSubmitting
      }) => (
        <Form className="grid w-full grid-cols-12 gap-4">
          <div className="flex flex-col col-span-12 bg-blue-100 border border-blue-200 rounded-sm shadow-md">
            <div className="flex justify-between w-full p-2 text-sm bg-blue-200 font-ms-bold">
              <div>
                {!isNew ? 'SUGGERIMENT #' + values?.Id : 'NOU SUGGERIMENT'}
              </div>
              <div>{values?.CreatedAt}</div>
            </div>

            <div className="grid grid-cols-12 gap-4 p-4">
              {/* OPERARI */}
              {canRespond && (
                <div className="items-center block col-span-12 text-base print:bg-white">
                  <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                    Operari:
                  </div>

                  <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
                    <div className="flex items-center w-full overflow-hidden leading-tight border-b border-blue-200 min-h-8 overflow-ellipsis">
                      {values?.Requester?.Name}
                    </div>
                  </div>
                </div>
              )}

              {/* AREA */}
              <div className="items-center block col-span-6 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Àrea:
                </div>

                {isNew ? (
                  <>
                    <Select
                      className="w-full text-base text-grey-darker"
                      onChange={(e) => setFieldValue('Area', e.Name)}
                      name="Area"
                      placeholder="Selecciona..."
                      options={areas}
                      getOptionLabel={(o) => o.Name}
                      getOptionValue={(o) => o.Name}
                      value={areas.find(
                        (option) => option.Name === values?.Area
                      )}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: '1px solid #bfdbfe',
                          borderRadius: '0.125rem',
                          boxShadow: state.isFocused ? 'none' : 'none',
                          '&:hover': {
                            border: '1px solid #9ca3af'
                          },
                          minHeight: '2rem',
                          cursor: 'pointer'
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          padding: '0.25rem 0.5rem',
                          position: 'static',
                          cursor: 'pointer',
                          rowGap: '0.25rem'
                        }),
                        multiValue: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          margin: '0 0.25rem 0 0',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                          margin: '0',
                          paddingTop: '0',
                          paddingBottom: '0',
                          cursor: 'pointer'
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          marginLeft: '0',
                          marginRight: '0'
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          cursor: 'pointer'
                        })
                      }}
                    />
                    <ErrorMessage
                      name="Area"
                      render={(message) => (
                        <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />
                  </>
                ) : (
                  <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
                    <div className="flex items-center w-full overflow-hidden leading-tight border-b border-blue-200 min-h-8 overflow-ellipsis">
                      {values?.Area}
                    </div>
                  </div>
                )}
              </div>

              {/* NAU */}
              <div className="items-center block col-span-6 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Nau:
                </div>

                {isNew ? (
                  <>
                    <Select
                      className="text-base rounded text-grey-darker"
                      onChange={(e) => setFieldValue('Shed', e.Name)}
                      name="Shed"
                      placeholder="Selecciona..."
                      options={warehouses}
                      getOptionLabel={(o) => o.Name}
                      getOptionValue={(o) => o.Id}
                      value={warehouses.find(
                        (option) => option.Name === values?.Shed
                      )}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: '1px solid #bfdbfe',
                          borderRadius: '0.125rem',
                          boxShadow: state.isFocused ? 'none' : 'none',
                          '&:hover': {
                            border: '1px solid #9ca3af'
                          },
                          minHeight: '2rem',
                          cursor: 'pointer'
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          padding: '0.25rem 0.5rem',
                          position: 'static',
                          cursor: 'pointer',
                          rowGap: '0.25rem'
                        }),
                        multiValue: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          margin: '0 0.25rem 0 0',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                          margin: '0',
                          paddingTop: '0',
                          paddingBottom: '0',
                          cursor: 'pointer'
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          marginLeft: '0',
                          marginRight: '0'
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          cursor: 'pointer'
                        })
                      }}
                    />
                    <ErrorMessage
                      name="Shed"
                      render={(message) => (
                        <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />
                  </>
                ) : (
                  <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
                    <div className="flex items-center w-full overflow-hidden leading-tight border-b border-blue-200 min-h-8 overflow-ellipsis">
                      {values?.Shed}
                    </div>
                  </div>
                )}
              </div>

              {/* DESCRIPCIÓ */}
              <div className="items-center block col-span-12 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Descripció:
                </div>

                {isNew ? (
                  <>
                    <Field
                      className={`leading-tight focus:outline-none focus:shadow-outline block h-40 pl-2 rounded-sm text-base w-full appearance-none py-1.5 px-3 p-2 border outline-none text-grey ${
                        errors.Reason
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-blue-200 bg-white hover:border-blue-400 focus:border-blue-400'
                      }`}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      name="Reason"
                      placeholder="Introdueix..."
                      type="text"
                      component="textarea"
                    />
                    <ErrorMessage
                      name="Reason"
                      render={(message) => (
                        <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />
                  </>
                ) : (
                  <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
                    <div className="flex items-center w-full overflow-hidden leading-tight border-b border-blue-200 min-h-8 overflow-ellipsis">
                      {values?.Reason}
                    </div>
                  </div>
                )}
              </div>

              {isNew && (
                <div className="flex items-center justify-end col-span-12">
                  <button
                    className={`inline-flex items-center h-10 px-4 py-1 text-white transition duration-300 bg-teal-500 border border-teal-500 rounded-sm font-ms-semi ${
                      !isSubmitting &&
                      'hover:border-teal-500 hover:bg-white hover:text-teal-500'
                    }`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CgSpinner size={22} className="animate-spin xl:mr-2" />
                    ) : (
                      <MdSend className="xl:mr-2" size={22} />
                    )}

                    <span className="hidden xl:inline-flex">ENVIAR</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          {!isNew && (
            <div
              className={`col-span-12 flex flex-col rounded-sm border shadow-md ${
                values.State === 'P' ||
                values?.Response === null ||
                values?.Response === ''
                  ? 'border-amber-200 bg-amber-100'
                  : 'border-green-200 bg-green-100'
              }`}
            >
              <div
                className={`flex justify-end w-full p-2 text-sm ${
                  values.State === 'P' ||
                  values?.Response === null ||
                  values?.Response === ''
                    ? 'bg-amber-200'
                    : 'bg-green-200'
                } font-ms-bold`}
              >
                {values?.UpdatedAt === 'dd/mm/aaaa'
                  ? 'Pendent...'
                  : values?.UpdatedAt}
              </div>

              <div className="flex flex-col items-center justify-center p-4">
                {/* Campo 'Resposta': */}
                <div className="items-center block w-full text-base print:bg-white">
                  <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                    Resposta:
                  </div>

                  {canRespond &&
                  (values?.State === 'P' ||
                    values?.Response === null ||
                    values?.Response === '') ? (
                    <>
                      <Field
                        className={`leading-tight bg-white focus:outline-none focus:shadow-outline block h-40 pl-2 rounded text-base w-full appearance-none py-1.5 px-3 p-2 border outline-none text-grey ${
                          errors.Response
                            ? 'border-red-200 hover:border-red-400 focus:border-red-400'
                            : 'border-amber-200 hover:border-amber-400 focus:border-amber-400'
                        }`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="Response"
                        placeholder="Introdueix..."
                        type="text"
                        component="textarea"
                      />
                      <ErrorMessage
                        name="Response"
                        render={(message) => (
                          <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </>
                  ) : (
                    <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:col-span-9 lg:col-span-8 xl:col-span-9">
                      <div
                        className={`flex items-center w-full overflow-hidden leading-tight border-b min-h-8 overflow-ellipsis ${
                          values?.Response === null || values?.Response === ''
                            ? 'border-amber-200'
                            : 'border-green-200'
                        }`}
                      >
                        {values?.Response ?? ''}
                      </div>
                    </div>
                  )}
                </div>

                {/* Botón de 'Enviar': */}
                {canRespond &&
                  (values?.Response === null ||
                    values.State === 'P' ||
                    values?.Response === '') && (
                    <div className="flex items-center justify-end w-full pt-4">
                      <button
                        className={`inline-flex items-center h-10 px-4 py-1 text-white transition duration-300 bg-teal-500 border border-teal-500 rounded-sm font-ms-semi ${
                          !isSubmitting &&
                          'hover:border-teal-400 hover:bg-white hover:text-teal-500'
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CgSpinner
                            size={22}
                            className="animate-spin xl:mr-2"
                          />
                        ) : (
                          <MdSend className="xl:mr-2" size={22} />
                        )}

                        <span className="hidden xl:inline-flex">ENVIAR</span>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

SuggestionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isUpdate: PropTypes.any,
  isNew: PropTypes.bool,
  userInfo: PropTypes.object,
  areas: PropTypes.array,
  warehouses: PropTypes.array,
  user: PropTypes.object
}
