import { UserContext } from 'contexts/UserContext'
import { useContext, useEffect } from 'react'

export default function Logout() {
  // Get user context
  const { setUser } = useContext(UserContext)

  // If Logout load
  useEffect(() => {
    setUser({})
  }, [])

  return ''
}
