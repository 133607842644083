import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import MaintOthers from 'forms/Maintenance/MtFormOthers'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { useLocation } from 'wouter'

export default function InventoryDetail({ params }) {
  const machineOptions = []
  const mtOptions = []
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [client, saveClient] = useFetch()
  const [documentsArray, setDocumentsArray] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const { handleModal } = useContext(ModalContext)
  const [, deleteDocumentAction] = useFetch()
  const userOptions = []
  const isNew = params.id === 'nou'
  const [machineList, machineListAction] = useFetch()
  const [mtList, mtListAction] = useFetch()

  const [documentAction, saveDocumentAction] = useFetch()
  const [userList, userListAction] = useFetch()
  const currentdate = new Date()
  const datetime =
    String(currentdate.getDate()).padStart(2, '0') +
    '/' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '/' +
    currentdate.getFullYear() +
    '  ' +
    String(currentdate.getHours()).padStart(2, '0') +
    ':' +
    String(currentdate.getMinutes()).padStart(2, '0') +
    'h'

  const [fields, setFields] = useState({
    // Id:              client.data.id,
    RequestDate: datetime,
    Area: '',
    CorrectiveDate: '',
    CorrectiveTimeHours: '0',
    CorrectiveTimeMinutes: '0',
    Description: '',
    RequestUserId: '',
    RequestUserName: '',
    Machine: '0',
    IdEquipment: '0',
    EquipmentName: '',
    TechnicalUserId: '',
    TechnicalUserName: '',
    External: '',
    WhatStatus: '',
    WhatDoes: '',
    Recommendations: '',
    SpentPart: '',
    RepairCost: '0',
    Shed: '',
    IsLostProduction: '0',
    IsUrgent: '0',
    Tools: '',
    IsMachineImprovement: '',
    IsPrinted: ''
    // MaintenacePartDocuments:  client.data.MaintenacePartDocuments,
  })

  const titleEdit = {
    name: `Modificar el registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/mantothers`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id,
    button: 'print'
  }

  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/mantothers`
      }
    ]
  }

  useEffect(() => {
    if (mtList.data) {
      mtList.data.forEach((mt) => {
        mtOptions.push({
          value: mt.Id,
          label: `${mt.Description}`
        })
      })
    }
  }, [mtList])

  useEffect(() => {
    const mtListAPIParams = {
      url: `inventorymachine`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    mtListAction(mtListAPIParams)

    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    userListAction(userListAPIParams)
    const machineListAPIParams = {
      url: `tsweaving/machines`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    machineListAction(machineListAPIParams)

    // console.log('params.id', params.id)
    if (params.id !== 'nou') {
      setTitle(titleEdit)
      const findClientById = {
        url: `maintenancepart/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }

      saveClient(findClientById)
    } else setTitle(titleCreate)
  }, [])

  useEffect(() => {
    if (machineList.data) {
      machineList.data.forEach((machine) => {
        machineOptions.push({
          value: machine.cod_maq,
          label: machine.cod_maq + '-' + machine.desc_sec
        })
      })
    }
  }, [machineList])

  useEffect(() => {
    // Set initialValues
    // console.log('useEffect de client.data .....', client.data)

    // console.log('client.data: ',client.data)

    client.data &&
      setFields({
        RequestDate: client.data.RequestDate, // ok
        RequestUserId: client.data.RequestUserId, // ok
        RequestUserName: client.data.RequestUserName, // ok <---
        Area: client.data.Area, // ok
        CorrectiveDate: client.data.CorrectiveDate, // ok
        CorrectiveTimeHours: client.data.CorrectiveTimeHours, // ok
        CorrectiveTimeMinutes: client.data.CorrectiveTimeMinutes, // ok
        Description: client.data.Description, // ok
        External: client.data.External, // ok
        Id: client.data.Id, // no mostrar
        IdEquipment: client.data.IdEquipment, // ok
        EquipmentName: client.data.EquipmentName,
        IsLostProduction: client.data.IsLostProduction, // not used
        IsUrgent: client.data.IsUrgent, // not used
        Machine: client.data.Machine, // ok
        // MaintenacePartDocuments:  client.data.MaintenacePartDocuments,
        Recommendations: client.data.Recommendations, // ok
        RepairCost: client.data.RepairCost, // ok
        Shed: client.data.Shed, // ok
        SpentPart: client.data.SpentPart, // ok
        TechnicalUserId: client.data.TechnicalUserId, // ok
        TechnicalUserName: client.data.TechnicalUserName, // ok <---
        Tools: client.data.Tools, // not used
        WhatDoes: client.data.WhatDoes, // ok
        WhatStatus: client.data.WhatStatus, // ok
        IsMachineImprovement: client.data.IsMachineImprovement,
        IsPrinted: client.data.IsPrinted
      })

    client.data && setDocumentsArray(client.data.MaintenacePartDocuments)
  }, [client.data])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  const uploadDocument = (e) => {
    const files = e.currentTarget.files
    //* Handle multiple files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    const tsweavingParams = {
      url: `maintenancepart/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Documento subido!',
      messageKo: 'Error al subir documentos'
    }

    saveDocumentAction(tsweavingParams)

    setIsUploading(true)
  }

  useEffect(() => {
    if (userList.data) {
      userList.data.forEach((user) => {
        userOptions.push({
          value: user.Id,
          label: user.Name
        })
      })
    }
  }, [userList])

  useEffect(() => {
    if (documentAction.data && isUploading === true) {
      const newDocuments = JSON.parse(documentAction.data)
      setDocumentsArray(newDocuments)
      setIsUploading(false)
    }
  }, [documentAction])

  const handleSubmit = async (values) => {
    values.MaintenacePartDocuments = null

    let url = `maintenancepart`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    // Send PUT //
    const clientApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al recuperar dades del ECAP',
      messageOk: 'Parte de manteniment actualitzat!'
    }
    await saveClient(clientApiParams)
    if (!client.error) setLocation('/mantothers')
  }

  const renderTableData = () => {
    if (documentsArray?.length > 0)
      return documentsArray.map((document) => {
        return (
          <td key={document.Id} className="border border-blue-400">
            <button className="block w-auto p-1 m-2 text-sm rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
              <a
                href={`https://apipreprod.cetriko.com/uploads/maintenancepart/${
                  params.id
                }/documents/${encodeURIComponent(document.Name)}`}
                target="_blank"
                rel="noreferrer"
              >
                {document.Name}
              </a>
            </button>
            <button
              className={`m-2 mx-auto p-2 rounded-lg w-auto block font-medium text-sm     focus:shadow-outline hover:bg-gray-300 `}
              onClick={() =>
                handleModal(
                  <DeleteModal
                    deleteUserOrFT={deleteDocument}
                    element="fitxer"
                    id={document.Id}
                    closeModal={() => handleModal('hide')}
                  />
                )
              }
            >
              <FaTrash size={17} />
            </button>
          </td>
        )
      })
  }
  const findDeletedDocument = (id) => {
    const newDocumentsArray = documentsArray.filter(
      (document) => document.Id !== id
    )
    setDocumentsArray(newDocumentsArray)
  }

  const deleteDocument = (id) => {
    const deleteDocumentParams = {
      url: `maintenancepart/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Documento eliminado!',
      messageKo: 'Error al eliminar documentos'
    }
    deleteDocumentAction(deleteDocumentParams)
    findDeletedDocument(id)
    handleModal('hide')
  }

  return userList.data ? (
    <div className="w-full p-4 bg-white">
      <p className="mb-6 text-3xl text-center 2xl:mr-36 font-ms-bold ">
        PARTE DE MANTENIMENT ALTRES EQUIPS
      </p>
      <MaintOthers
        users={userOptions}
        fields={fields}
        mtList={mtOptions}
        machines={machineOptions}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        uploadDocument={uploadDocument}
        isNew={isNew}
        renderTableData={renderTableData}
        documentsLength={documentsArray?.length}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

InventoryDetail.propTypes = {
  params: PropTypes.any
}
