// Fitxa tècnica Teixiduria - Nova finestra auto-print 'Posada en marxa'

import Printer, { print } from 'react-pdf-print'
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'
import Logo from 'components/ui/Logo/Logo'

const currentdate = new Date()
const datetime =
  String(currentdate.getDate()).padStart(2, '0') +
  '/' +
  String(currentdate.getMonth() + 1).padStart(2, '0') +
  '/' +
  currentdate.getFullYear() +
  '  -  ' +
  String(currentdate.getHours()).padStart(2, '0') +
  ':' +
  String(currentdate.getMinutes()).padStart(2, '0') +
  'h'

const ids = ['1']
let fields = []

export default function FTTeixiduriaPosToPDF() {     

    // const localPrintFields = localStorage.getItem('localPrintFields') 
    const [localPrintData, setLocalPrintData] = useState({})

    useEffect ( ()=> {
        // hay que usar el LocalStorage:       
        setLocalPrintData(JSON.parse(localStorage.getItem('localPrintFieldsPos')))
        // console.log('LabToPDF useEffect[] - fields: ',fields)
        console.log('LabToPDF useEffect[] - localPrintData: ',localPrintData)
    },[])

    useEffect ( ()=> {
        fields = localPrintData
        console.log('LabToPDF useEffect[localPrintData] - fields: ',fields)
        console.log('LabToPDF useEffect[localPrintData] - localPrintData: ',localPrintData)
    },[localPrintData])

    // console.log('function sendToPDF - fields: ',fields)

        const ids = ['1']
        console.log('ids: ',ids)
        return (
            <div id='checkListLaboratori' className='h-full w-full text-color-primary'> 
                <h1 className='justify-center'>FITXA CHECKLIST POSADA EN MARXA</h1>
                <hr/>
                <p> &nbsp; </p>
                <Printer>    
                    {/* <div id={ids[0]} style={{ width:'210mm', height: '10mm' }}>
                        CheckList Laboratori - Machine: {fields.MachineCode + ' ' + fields.Machine}
                    </div>                  */}
                    <div id={ids[0]} style={{ width:'220mm', height: '320mm' }}>

                        <div className='flex flex-col mx-3'>
                            <p>&nbsp;</p>
                            <div>
                                <div className='w-100 border border-grey-400'>
                                    {console.log('fields en FTTeixiduriaPosToPDF: ',fields)}                                
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='border border-grey-800 w-40 h-14'>
                                                <Logo classes="w-15 h-8 object-contain" link="/" color="black" />                                                            
                                            </td>
                                            <td className='col-span-4 text-2xl border border-grey-800 flex justify-center w-11/12 h-14 py-3'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                            
                                                POSADA EN MARXA
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;              
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                
                                            </td>
                                            <td className='text-xs text-left w-40 h-14'>Format-IATF-013-CA &nbsp;&nbsp; (11.03.22)</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>&nbsp;</p>
                                <div className='w-90 border border-grey-400'>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>SEMI: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                        
                                                <span className='text-gray-600 text-base font-normal italic'> {fields.SemiFinished}</span>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>MÀQ.: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                        
                                                <span className='text-gray-600 text-base font-normal italic'> {fields.Machine}</span>
                                            </td>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>DATA: &nbsp;                                         
                                                <span className='text-gray-600 text-base font-normal italic'> {datetime}</span>
                                            </td>                                                                                
                                        </tr>
                                        <tr>
                                            <td className='text-blue-700 text-base font-bold px-1'>Nom.: {fields.DetectedUserName}</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>                                              
                                        </tr> 
                                        </tbody>                                           
                                    </table>
                                </div>
                                <p>&nbsp;</p>
                                {/* </div> */}
                                <table className='w-100'>
                                    <caption className='text-right text-base bg-blue-200 px-28 pt-1 font-bold'>MESES</caption>
                                    <thead className='text-base bg-blue-200 px-3'>
                                        <tr>
                                            <th className='w-5/12'>TAREAS</th>
                                            <th className='text-center'>1</th>
                                            <th className='text-center'>5</th>
                                            <th className='text-center'>9</th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='grid-row border border-gray-300 px-5'>
                                            {/* <td className='col-span-2 border border-gray-300 px-0 py-5'>Comprobar estat fileta</td> */}
                                            <td className='col-span-2 border border-gray-300 px-0 py-5'>Comprobar estado de la fileta.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>
                                        <tr className='border border-gray-300 bg-gray-200'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Comprobar funcionament i gir ventiladors</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Comprobar funcionamiento y giro de los ventiladores.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>          
                                        <tr className='border border-gray-300'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Verificar funcionament bufadors</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Verificar que los sopladores funcionen.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>                                                                                                             
                                        <tr className='border border-gray-300 bg-gray-200'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Control engreixat màquina</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Controlar el engrase de la maquina: boquillas y caudal.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>  
                                        <tr className='border border-gray-300'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Netaja calandra i plat</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Limpiar con un trapo calandra y plato por dentro</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>
                                        <tr className='border border-gray-300 bg-gray-200'>
                                            {/* <td className='border border-gray-300 px-0 py-5'>Comprobar llum central</td> */}
                                            <td className='border border-gray-300 px-0 py-5'>Comprobar funcionamiento de la luz central.</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                            <td className='border border-gray-300 px-1'>&nbsp;</td>
                                        </tr>                                                                                 
                                    </tbody>
                                    <tfoot className='text-base border border-gray-300 bg-blue-200 px-3'>                                                                  
                                        <tr>
                                            <th className='col-span-1 py-5'>Observaciones: </th>
                                            <td className='border border-gray-300 px-1 text-blue-200'>..............</td>
                                            <td className='border border-gray-300 px-1 text-blue-200'>..............</td>
                                            <td className='border border-gray-300 px-1 text-blue-200'>..............</td>
                                        </tr>                                    
                                    </tfoot>                                                            
                                </table>
                                <p>&nbsp;</p>                            
                                <div className='flex justify-center'>
                                    <div className='text-lg'>Aprovat per:</div>
                                </div>                                                 
                            </div>
                        </div>                 
                        {/* <input  type='button' 
                            style={{ position: 'relative', float: 'right', border: '5', margin: '8px', padding: '5px'}}
                            className='bg-green-300'
                            onClick={() => print(ids)} value='Impresora' 
                        /> */}
                    </div> 
                </Printer>   
                {/* {window.onloadend() => print(ids)}   */}

                <div onloadend={print(ids)}>.
                </div>
                    {/* <input  type='button' 
                            style={{ position: 'relative', float: 'right', border: '5', margin: '8px', padding: '5px'}}
                            className='bg-green-300'
                            onClick={() => print(ids)} value='Impresora'                             
                    /> */}
                {/* </div>            */}
            </div>
        )
}

FTTeixiduriaPosToPDF.propTypes = {}
