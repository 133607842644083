import ListTable from 'components/RRHH/Suggestions/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const SuggestionList = () => {
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()

  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGet, setApiCallGet] = useFetch()

  const { user } = useContext(UserContext)
  const { handleModal } = useContext(ModalContext)

  useEffect(async () => {
    const apiCallGetUser = {
      url: `user/${user.Id}`,
      method: 'GET',
      messageKo: `Error al recuperar l'Usuari!`
    }
    setApiCallGetUser(apiCallGetUser)

    handleModal('hide')
  }, [])

  useEffect(async () => {
    if (getApiCallGetUser.data !== null) {
      const title = {
        name: 'Suggeriment',
        buttons:
          getApiCallGetUser.data.NotifyOnSuggestionChange && user.Role.Id === 1
            ? null
            : [
                {
                  name: 'Nou Suggeriment',
                  link: `/sugerencia/nou/`
                }
              ]
      }
      setTitle(title)

      const apiCallGet = {
        url:
          getApiCallGetUser.data.NotifyOnSuggestionChange && user.Role.Id === 1
            ? `suggestion`
            : `suggestion/user/${user.Id}`,
        method: 'GET',
        messageKo: `Error al recuperar Suggeriments!`
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/sugerencia/${item.Id}`)
  }

  return getApiCallGet.data !== null &&
    getApiCallGet.data !== undefined &&
    getApiCallGet.data[0] !== null &&
    getApiCallGetUser.data !== null &&
    getApiCallGetUser.data !== undefined ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable
        items={getApiCallGet.data}
        handleClick={handleClick}
        userRoleId={user.Role.Id}
        currentUser={getApiCallGetUser.data}
      />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default SuggestionList
