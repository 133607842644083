// PropTypes
import PropTypes from 'prop-types'

export default function UltrasonicSettings({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'

  return (
    <div className={blockStyle}>
      {/* POTÈNCIA */}
      <div className={firstLabelStyles}>Potència:</div>
      <div className={firstParentStyles}>
        <div className={innerParentStyles}>{values?.PowerUltrasound}</div>
        <span>&plusmn;</span>
        <div className={innerParentStyles}>
          {values?.PowerUltrasoundTolerance}
        </div>
      </div>

      {/* VELOCITAT */}
      <div className={otherLabelsStyles}>Velocitat:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.SpeedUltrasound}</div>
        <span>&plusmn;</span>
        <div className={innerParentStyles}>
          {values?.SpeedUltrasoundTolerance}
        </div>
      </div>

      {/* PRESSIÓ */}
      <div className={otherLabelsStyles}>Pressió:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.PressureUltrasound}</div>
        <span>&plusmn;</span>
        <div className={innerParentStyles}>
          {values?.PressureUltrasoundTolerance}
        </div>
      </div>
    </div>
  )
}

UltrasonicSettings.propTypes = {
  values: PropTypes.object
}
