export default function Sign() {
  return (
    <div className="col-span-2 flex list-none mt-2 ft-sign">
      <div className="flex-auto flex">
        <p>Operari:</p>
        <input
          type="text"
          className="appearance-none rounded border border-gray-300 px-2 py-1 w-full leading-tight focus:outline-none focus:shadow-outline ml-2 mr-12"
        />
      </div>
      <div className="flex-auto flex">
        <p>Data:</p>
        <input
          type="text"
          className="appearance-none rounded border border-gray-300 px-2 py-1 w-full leading-tight focus:outline-none focus:shadow-outline ml-2 mr-12"
        />
      </div>
      <div className="flex-auto flex">
        <p>OF:</p>
        <input
          type="text"
          className="appearance-none rounded border border-gray-300 px-2 py-1 w-full leading-tight focus:outline-none focus:shadow-outline ml-2 last:mr-12"
        />
      </div>
    </div>
  )
}
