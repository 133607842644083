import RamForm from 'forms/ftacabatsrequest/ramCreate'
import PropTypes from 'prop-types'

export default function RamsVersion2({ ramTab, setRamTab, values }) {
  return (
    values !== undefined &&
    values !== null && (
      <div className="flex flex-col col-span-6 bg-white border-2 border-collapse border-blue-200 rounded shadow-md print:shadow-none">
        <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          <span>REGLATGES RAM</span>
        </div>

        <div className="flex flex-wrap w-full p-2">
          <ul className="flex flex-row w-full pb-0 list-none" role="tablist">
            {/* Mostrar 'RAM 1': */}
            <li className="flex-auto mr-1 text-center">
              <a
                className={`border-2 border-blue-300 hover:bg-blue-300 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
                  ${
                    ramTab === 1
                      ? 'text-white bg-blue-300'
                      : 'text-blue-300 bg-white'
                  }
                `}
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(1)
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                RAM 1
              </a>
            </li>

            {/* Mostrar 'RAM 2': */}
            <li className="flex-auto mx-1 text-center">
              <a
                className={`border-2 border-blue-300 hover:bg-blue-300 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
                  ${
                    ramTab === 2
                      ? 'text-white bg-blue-300'
                      : 'text-blue-300 bg-white'
                  }
                `}
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(2)
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                RAM 2
              </a>
            </li>

            {/* Mostrar 'RAM 3': */}
            <li className="flex-auto mx-1 text-center">
              <a
                className={`border-2 border-blue-300 hover:bg-blue-300 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
                  ${
                    ramTab === 3
                      ? 'text-white bg-blue-300'
                      : 'text-blue-300 bg-white'
                  }
                `}
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(3)
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                RAM 3
              </a>
            </li>

            {/* Mostrar 'RAM 4': */}
            <li className="flex-auto mx-1 text-center">
              <a
                className={`border-2 border-blue-300 hover:bg-blue-300 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
                  ${
                    ramTab === 4
                      ? 'text-white bg-blue-300'
                      : 'text-blue-300 bg-white'
                  }
                `}
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(4)
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                RAM 4
              </a>
            </li>

            {/* Mostrar 'RAM 5': */}
            <li className="flex-auto ml-1 text-center">
              <a
                className={`border-2 border-blue-300 hover:bg-blue-300 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center 
                  ${
                    ramTab === 5
                      ? 'text-white bg-blue-300'
                      : 'text-blue-300 bg-white'
                  }
                `}
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(5)
                }}
                data-toggle="tab"
                href="#link5"
                role="tablist"
              >
                RAM 5
              </a>
            </li>
          </ul>

          <div className="relative flex flex-col w-full min-w-0 mt-2 break-words bg-white">
            <div className="tab-content tab-space">
              {/* Mostrar 'RAM 1': */}
              <div className={ramTab === 1 ? 'grid' : 'hidden'} id="link1">
                <RamForm
                  ramNumber={1}
                  ramIndex={values?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 1
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '1'
                    }
                    return -1
                  })}
                />
              </div>

              {/* Mostrar 'RAM 2': */}
              <div className={ramTab === 2 ? 'grid' : 'hidden'} id="link2">
                <RamForm
                  ramNumber={2}
                  ramIndex={values?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 2
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '2'
                    }
                    return -1
                  })}
                />
              </div>

              {/* Mostrar 'RAM 3': */}
              <div className={ramTab === 3 ? 'grid' : 'hidden'} id="link3">
                <RamForm
                  ramNumber={3}
                  ramIndex={values?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 3
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '3'
                    }
                    return -1
                  })}
                />
              </div>

              {/* Mostrar 'RAM 4': */}
              <div className={ramTab === 4 ? 'grid' : 'hidden'} id="link4">
                <RamForm
                  ramNumber={4}
                  ramIndex={values?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 4
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '4'
                    }
                    return -1
                  })}
                />
              </div>

              {/* Mostrar 'RAM 5': */}
              <div className={ramTab === 5 ? 'grid' : 'hidden'} id="link5">
                <RamForm
                  ramNumber={5}
                  ramIndex={values?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 5
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '5'
                    }
                    return -1
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

RamsVersion2.propTypes = {
  ramTab: PropTypes.number,
  setRamTab: PropTypes.func,
  values: PropTypes.array
}
