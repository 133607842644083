import { TitleContext } from 'contexts/TitleContext'
import CheckListForm from 'forms/Control/CheckList/CheckListForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function CheckListDetail({ params }) {
  // Context
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()

  // useFetch() - get
  const [fetchData, setFetchData] = useFetch()
  const [fetchUsers, setFetchUsers] = useFetch()
  const [fetchEquipments, setFetchEquipments] = useFetch()

  // useFetch() - post
  const [postData, setPostData] = useFetch()

  // Var
  const isNew = !!(params.id === 'nou')

  // Estados
  const [fields, setFields] = useState({
    Id: 0,
    EquipmentId: 0,
    EquipmentName: '',
    Turn: '',
    Shed: '',
    UserId: 0,
    UserName: '',
    OF: '',
    Date: '',
    Verification1: false,
    Verification2: false,
    Verification3: false,
    Verification4: false,
    Verification5: false,
    Verification6: false,
    Verification7: false,
    Verification8: false,
    Verification9: false,
    Verification10: false,
    Verification11: false,
    Verification12: false,
    Verification13: false,
    Verification14: false,
    Verification15: false,
    Verification16: false,
    IsOK: false,
    Description: ''
  })

  useEffect(() => {
    // Establecer el título basado en si es un nuevo registro
    const title = {
      name: isNew ? `Crear registre` : `Modificar registre`,
      deleteCheckListBtn: !isNew,
      checklistId: !isNew ? params.id : null,
      button: !isNew ? 'print' : null,
      buttons: [
        {
          name: 'Tornar',
          link: '/checklist'
        }
      ]
    }
    setTitle(title)

    const fetchData = async () => {
      // Definir las llamadas a la API
      const fetchDataParams = {
        url: `checklist/${params.id}`,
        method: 'GET',
        messageKo: "No s'han pogut recuperar les dades"
      }

      const fetchUsersParams = {
        url: `user`,
        method: 'GET',
        messageKo: "No s'han pogut recuperar els usuaris"
      }

      const fetchEquipmentsParams = {
        url: `inventorymachine/block/checklistram`,
        method: 'GET',
        messageKo: "No s'ha pogut recuperar l'inventari"
      }

      // Ejecutar todas las solicitudes de fetch
      // - Si no es un nuevo registro, se hacen 3 llamadas: 1 para obtener el registro, 1 para usuarios y 1 para inventario.
      // - Si es un nuevo registro, se hacen 2 llamadas: 1 para usuarios y 1 para inventario.
      if (!isNew) {
        await Promise.all([
          setFetchData(fetchDataParams),
          setFetchUsers(fetchUsersParams),
          setFetchEquipments(fetchEquipmentsParams)
        ]).catch((e) => {
          console.log(e)
        })
      } else {
        await Promise.all([
          setFetchUsers(fetchUsersParams),
          setFetchEquipments(fetchEquipmentsParams)
        ]).catch((e) => {
          console.log(e)
        })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (fetchData.data) {
      setFields({
        Id: fetchData.data?.Id,
        EquipmentId: fetchData.data?.EquipmentId,
        EquipmentName: fetchData.data?.EquipmentName,
        Turn: fetchData.data?.Turn,
        Shed: fetchData.data?.Shed,
        UserId: fetchData.data?.UserId,
        UserName: fetchData.data?.UserName,
        OF: fetchData.data?.OF,
        Date: fetchData.data?.Date,
        Verification1: fetchData.data?.Verification1,
        Verification2: fetchData.data?.Verification2,
        Verification3: fetchData.data?.Verification3,
        Verification4: fetchData.data?.Verification4,
        Verification5: fetchData.data?.Verification5,
        Verification6: fetchData.data?.Verification6,
        Verification7: fetchData.data?.Verification7,
        Verification8: fetchData.data?.Verification8,
        Verification9: fetchData.data?.Verification9,
        Verification10: fetchData.data?.Verification10,
        Verification11: fetchData.data?.Verification11,
        Verification12: fetchData.data?.Verification12,
        Verification13: fetchData.data?.Verification13,
        Verification14: fetchData.data?.Verification14,
        Verification15: fetchData.data?.Verification15,
        Verification16: fetchData.data?.Verification16,
        Description: fetchData.data?.Description,
        IsOK: fetchData.data?.IsOK
      })
    }
  }, [fetchData.data])

  const handleSubmit = async (values) => {
    let url = `checklist`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values,
      messageKo: isNew
        ? 'Error guardant el registre!'
        : 'Error actualitzant el registre!',
      messageOk: isNew ? 'Registre guardat!' : 'Registre actualitzat!'
    }
    await setPostData(apiCallPost)
    if (!postData.error) setLocation('/checklist')
  }

  return (isNew &&
    fetchUsers.data !== null &&
    fetchUsers.data !== undefined &&
    fetchUsers.data[0] !== null &&
    fetchEquipments.data !== null &&
    fetchEquipments.data !== undefined &&
    fetchEquipments.data[0] !== null) ||
    (!isNew && fields.Id !== 0) ? (
    <div className="w-full p-4 bg-white">
      <CheckListForm
        users={fetchUsers.data}
        equipments={fetchEquipments.data}
        fields={fields}
        handleSubmit={handleSubmit}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

CheckListDetail.propTypes = {
  params: PropTypes.any
}
