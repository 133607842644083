import PropTypes from 'prop-types'
import { useState } from 'react'
import RamForm from 'forms/ftacabats/ram'

export default function Rams({ values }) {
  const [ramTab, setRamTab] = useState(1)

  return (
    values !== undefined &&
    values !== null && (
      <div className="flex flex-col col-span-12 bg-white border-2 border-collapse border-blue-200 rounded shadow-md print:shadow-none">
        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
          REGLATGES RAM
        </div>

        <div className="flex flex-wrap w-full p-2">
          <ul className="flex flex-row w-full pb-0 list-none" role="tablist">
            {/* Mostrar 'RAM 1': */}
            <li className="flex-auto mr-1 text-center">
              <a
                className={
                  'text-md font-ms-bold border-2 transition duration-300 border-primary hover:bg-primary hover:text-white uppercase px-5 py-3 block leading-normal rounded ' +
                  (ramTab === 1
                    ? 'text-white bg-primary'
                    : 'text-primary bg-white')
                }
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(1)
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                RAM 1
              </a>
            </li>

            {/* Mostrar 'RAM 2': */}
            <li className="flex-auto mx-1 text-center">
              <a
                className={
                  'text-md font-ms-bold uppercase border-2 transition duration-300 border-primary hover:bg-primary hover:text-white  px-5 py-3 block leading-normal rounded ' +
                  (ramTab === 2
                    ? 'text-white bg-primary'
                    : 'text-primary bg-white')
                }
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(2)
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                RAM 2
              </a>
            </li>

            {/* Mostrar 'RAM 3': */}
            <li className="flex-auto mx-1 text-center">
              <a
                className={
                  'text-md font-ms-bold border-2 transition duration-300 border-primary hover:bg-primary hover:text-white  uppercase px-5 py-3 block leading-normal rounded ' +
                  (ramTab === 3
                    ? 'text-white bg-primary'
                    : 'text-primary bg-white')
                }
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(3)
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                RAM 3
              </a>
            </li>

            {/* Mostrar 'RAM 4': */}
            <li className="flex-auto mx-1 text-center">
              <a
                className={
                  'text-md font-ms-bold border-2 transition duration-300 border-primary hover:bg-primary hover:text-white  uppercase px-5 py-3 block leading-normal rounded ' +
                  (ramTab === 4
                    ? 'text-white bg-primary'
                    : 'text-primary bg-white')
                }
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(4)
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                RAM 4
              </a>
            </li>

            {/* Mostrar 'RAM 5': */}
            <li className="flex-auto ml-1 text-center">
              <a
                className={
                  'text-md font-ms-bold border-2 transition duration-300 border-primary hover:bg-primary hover:text-white uppercase px-5 py-3 block leading-normal rounded ' +
                  (ramTab === 5
                    ? 'text-white bg-primary'
                    : 'text-primary bg-white')
                }
                onClick={(e) => {
                  e.preventDefault()
                  setRamTab(5)
                }}
                data-toggle="tab"
                href="#link5"
                role="tablist"
              >
                RAM 5
              </a>
            </li>
          </ul>

          <div className="relative flex flex-col w-full min-w-0 mt-2 break-words bg-white">
            <div className="tab-content tab-space">
              {/* Mostrar 'RAM 1': */}
              <div className={ramTab === 1 ? 'grid' : 'hidden'} id="link1">
                <RamForm
                  ramNumber={1}
                  ramIndex={values?.TechnicalSheetRams?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 1
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '1'
                    }
                    return -1
                  })}
                  values={values}
                />
              </div>

              {/* Mostrar 'RAM 2': */}
              <div className={ramTab === 2 ? 'grid' : 'hidden'} id="link2">
                <RamForm
                  ramNumber={2}
                  ramIndex={values?.TechnicalSheetRams?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 2
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '2'
                    }
                    return -1
                  })}
                  values={values}
                />
              </div>

              {/* Mostrar 'RAM 3': */}
              <div className={ramTab === 3 ? 'grid' : 'hidden'} id="link3">
                <RamForm
                  ramNumber={3}
                  ramIndex={values?.TechnicalSheetRams?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 3
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '3'
                    }
                    return -1
                  })}
                  values={values}
                />
              </div>

              {/* Mostrar 'RAM 4': */}
              <div className={ramTab === 4 ? 'grid' : 'hidden'} id="link4">
                <RamForm
                  ramNumber={4}
                  ramIndex={values?.TechnicalSheetRams?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 4
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '4'
                    }
                    return -1
                  })}
                  values={values}
                />
              </div>

              {/* Mostrar 'RAM 5': */}
              <div className={ramTab === 5 ? 'grid' : 'hidden'} id="link5">
                <RamForm
                  ramNumber={5}
                  ramIndex={values?.TechnicalSheetRams?.findIndex((ram) => {
                    if (typeof ram.RamNumber === 'number') {
                      return ram.RamNumber === 5
                    } else if (typeof ram.RamNumber === 'string') {
                      return Number(ram.RamNumber) === '5'
                    }
                    return -1
                  })}
                  values={values}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
Rams.propTypes = {
  values: PropTypes.object
}
