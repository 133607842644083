import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import { Field } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { CgSpinner } from 'react-icons/cg'
import ThreadTable from './threadTableView'

export default function DescriptionForm({ values, threads }) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([setApiCallSemiFinished(apiCallGetSemiFinished)]).catch(
      (e) => {
        console.log(e)
      }
    )
  }, [])

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Semielaborat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Semielaborat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.SemiFinished}
          disabled
        />
      </div>

      {/* Tipus lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus lligat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        {!getApiCallGetSemiFinished?.data ? (
          <div className="w-full px-2 py-1 bg-gray-100 border border-gray-300 rounded-sm">
            <CgSpinner size={20} className="text-charcoal animate-spin" />
          </div>
        ) : (
          <Field
            type="text"
            className="w-full block-input-disabled"
            value={
              getApiCallGetSemiFinished?.data?.find(
                (option) => option.semi === values.SemiFinished
              )?.ligado ?? ''
            }
            disabled
          />
        )}
      </div>

      {/* Tabla: Ideal | Tol.Màx. */}
      <div className="grid grid-cols-12 col-span-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                Ideal
              </div>

              <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                Tol. Màx.
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Passades': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Passades:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.Repetitions}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.RepetitionsTolerance}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.RepetitionsV2}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.RepetitionsToleranceV2}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Columnes': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Columnes:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.Columns}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.ColumnsTolerance}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.ColumnsV2}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.ColumnsToleranceV2}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gramatge': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gramatge:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.Weight}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.WeightTolerance}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.WeightV2}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.WeightToleranceV2}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>g/m2</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Ample': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Ample:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.Width}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.WidthTolerance}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.WidthV2}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.WidthToleranceV2}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gruix': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gruix:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.Thickness}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.ThicknessTolerance}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.ThicknessV2}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.ThicknessToleranceV2}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>mm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Rdt Nominal': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Rdt Nominal:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.RDTNominal}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-non"
                  value={values?.RDTNominalTolerance}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.RDTNominalV2}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none"
                  value={values?.RDTNominalToleranceV2}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>m/kg</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Comentari': */}
        <div className="grid h-full grid-cols-12 col-span-12">
          <div className="block w-full h-auto my-1 text-sm text-grey-darker font-ms-semi">
            Comentari:
          </div>

          <Field
            as="textarea"
            className="col-span-12 text-sm whitespace-pre-wrap resize-none block-input-disabled h-52"
            style={{
              backgroundColor: '#F2F2F2',
              color: '#999999'
            }}
            value={values?.LaboratoryComment}
            disabled
          />
        </div>
      </div>

      {/* Lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Lligat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.TiedDescription}
          disabled
        />
      </div>

      {/* Diagrama: */}
      <div className="flex col-span-12 rounded">
        <p className="mr-1 text-right">
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
          10
          <br />
          11
          <br />
          12
          <br />
        </p>

        <Field
          as="textarea"
          className="w-full px-2 py-1 pt-0 overflow-hidden leading-6 whitespace-pre-line bg-blue-100 border border-gray-300 rounded-sm appearance-none resize-none faden"
          style={{
            backgroundColor: '#F2F2F2',
            color: '#999999'
          }}
          value={values?.EncryptedCode}
          disabled
        />

        <div
          onClick={() =>
            handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
          }
          className="cursor-pointer"
          style={{ height: 'fit-content' }}
        >
          <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
        </div>
      </div>

      {/* Màquina */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Màquina:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-4/12 block-input-disabled"
          value={values?.MachineCode}
          disabled
        />

        <Field
          type="text"
          className="w-8/12 block-input-disabled"
          value={values?.Machine}
          disabled
        />
      </div>

      {/* Plegador */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Plegador:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.FoldType}
          disabled
        />
      </div>

      {/* GG - ø */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        GG - ø:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.GG}
          disabled
        />

        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.O}
          disabled
        />
      </div>

      {/* Nº Jocs */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nº Jocs:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.NGame}
          disabled
        />
      </div>

      {/* Agulles */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Agulles:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.Needle}
          disabled
        />
      </div>

      {/* Rpm */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.Rpm}
          disabled
        />
      </div>

      {/* Rpm Mín. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm Mín.:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.RpmMin}
          disabled
        />
      </div>

      {/* V. / Kg */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Kg:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.TurnsPerKg}
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.TurnsPerKgTolerance}
          disabled
        />
      </div>

      {/* V. / Peça (Reals) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Reals):
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.TurnsPerPart}
          disabled
        />
      </div>

      {/* V. / Peça (Fitxa) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Fitxa):
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.TurnsPerPartByOperator}
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.TurnsPerPartByOperatorTolerance}
          disabled
        />
      </div>

      {/* Kg peça */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Kg peça:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.KgPart}
          disabled
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.KgPartTolerance}
          disabled
        />
      </div>

      {/* Pass. Seguretat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Pass. Seguretat:
      </div>
      <div className="flex items-center col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className={`block-input-disabled ${
            values?.SecurityStep === 'true' || values?.SecurityStep
              ? 'w-4/12'
              : 'w-full'
          }`}
          value={values?.SecurityStep ? 'Sí' : 'No'}
          disabled
        />

        {(values?.SecurityStep === 'true' || values?.SecurityStep) && (
          <Field
            type="text"
            className="w-8/12 block-input-disabled"
            value={
              values?.SecurityStepDescription === undefined ||
              !values?.SecurityStepDescription
                ? 'Segons cartell'
                : values?.SecurityStepDescription
            }
            disabled
          />
        )}
      </div>

      {/* Tabla: Hilos */}
      <div className="w-full col-span-12">
        <ThreadTable
          threads={threads}
        />
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  values: PropTypes.object,
  threads: PropTypes.any
}
