import ListTable from 'components/Users/ListTable/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

export default function UsersList() {
  // Set FTTeixiduria Title
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  // Fetch get ft list
  const [userList, userListAction] = useFetch()
  const { handleModal } = useContext(ModalContext)
  useEffect(() => {
    // Set title
    const UsersTitle = {
      name: 'Usuaris',
      buttons: [
        {
          name: 'Crear',
          link: `/usuari/nou/`
        }
      ]
    }

    // Fetch api FT List
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    setTitle(UsersTitle)
    userListAction(userListAPIParams)
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/usuari/${item.Id}`)
  }

  return userList.data ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable items={userList.data} handleClick={handleClick} />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}
