import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function HeightForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-2 auto-rows-min md:gap-y-1 lg:gap-y-3">
      {/* Sincronit. */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Sincronit.:</div>

        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.Synchronization}
          disabled
        />
      </div>

      {/* Formació */}
      <div className="flex flex-col items-start justify-start col-span-12">
        <div className="col-span-12 text-sm font-ms-semi">Formació:</div>

        <Field
          type="text"
          className="w-full block-input-disabled"
          value={values?.Formation}
          disabled
        />
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
          Alçada plat:
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="pinyons plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              className="w-16 block-input-disabled"
              value={values?.DishHeight1}
              disabled
            />

            <Field
              type="text"
              className="w-16 block-input-disabled"
              value={values?.DishHeight2}
              disabled
            />
          </div>
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
          Desplaçament plat:
        </label>

        <div className="relative w-40 col-span-4">
          <img src={alcades} alt="Pinyons Plegador" className="w-full" />

          <div className="absolute bottom-0 left-0 flex items-center justify-between w-full px-1 pb-1">
            <Field
              type="text"
              className="w-16 block-input-disabled"
              value={values?.DishDisplacement1}
              disabled
            />

            <Field
              type="text"
              className="w-16 block-input-disabled"
              value={values?.DishDisplacement2}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {
  values: PropTypes.object
}
