import { UserContext } from 'contexts/UserContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import Select from 'react-select'

import {
  MdOutlineGppBad,
  MdOutlineGppGood,
  MdOutlineRestore,
  MdOutlineSave
} from 'react-icons/md'

export default function UserForm({
  fields,
  operatorsList,
  handleSubmit,
  handleChangeState,
  handleResetPass
}) {
  const { user } = useContext(UserContext)

  const permissions = [
    { value: 1, label: 'Administració' },
    { value: 2, label: 'Operari' }
  ]

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
      initialValues={fields}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm
      }) => (
        <Form className="flex flex-wrap">
          {/* Apartado 'Targeta usuario': */}
          <div className="flex flex-col w-full px-5 py-5 bg-gray-100 border-2 border-gray-300 divide-y divide-gray-300 rounded divide-dashed">
            {/* Fila 1: */}
            <div className="grid grid-cols-5 divide-x divide-gray-300 divide-dashed">
              {/* Columna 1: */}
              <div className="flex items-center justify-center h-full col-span-1 pt-1 pb-6 pl-2 pr-7">
                <div className="relative flex items-center justify-center w-full h-full bg-white border-2 border-gray-300 rounded-full">
                  {/* Campo 'Imagen': */}
                  <div className="flex items-center justify-center w-full h-full p-1 print:bg-white">
                    {values.Photo ? (
                      <img
                        className="object-cover w-full h-full rounded-full"
                        src={`https://apipreprod.cetriko.com${values.Photo}`}
                        alt={fields.Name}
                      />
                    ) : (
                      <img
                        className="object-cover w-full h-full rounded-full"
                        src={
                          'https://apipreprod.cetriko.com/uploads/user/profile/default_image.jpg'
                        }
                        alt={fields.Name}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* Columna 2 */}
              <div className="container col-span-4 pl-5">
                {/* Campo 'Nom': */}
                <div className="items-center block w-full px-2 py-3 print:bg-white">
                  <label
                    className="block mb-2 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="name"
                  >
                    Nom
                  </label>
                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-gray-300 outline-none 
                      text-grey-darker print:shadow-none print:border-none print:h-auto print:py-3.5`}
                    type="text"
                    name="Name"
                  />
                </div>

                {/* Campo 'Pin': */}
                <div className="items-center block w-full px-2 py-3 print:bg-white">
                  <label
                    className="block mb-2 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="name"
                  >
                    Pin
                  </label>
                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-gray-300 outline-none 
                      text-grey-darker print:shadow-none print:border-none print:h-auto print:py-3.5`}
                    type="text"
                    name="Pin"
                  />
                </div>

                {/* Campo 'Email': */}
                <div className="items-center block w-full px-2 pt-3 pb-6 print:bg-white">
                  <label
                    className="block mb-2 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="name"
                  >
                    Email
                  </label>
                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-gray-300 outline-none 
                      text-grey-darker print:shadow-none print:border-none print:h-auto print:py-3.5`}
                    type="email"
                    name="Email"
                  />
                </div>
              </div>
            </div>

            {/* Fila 2: */}
            <div className="grid grid-cols-2 gap-x-12">
              {/* Campo 'Vinculació ECAP': */}
              <div className="items-center block w-full px-2 pt-6 pb-3 print:bg-white">
                <label
                  className="block mb-2 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="name"
                >
                  Vinculació ECAP
                </label>
                <Select
                  className="col-span-5 rounded text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('Pin', e.value)
                    setFieldValue('Name', e.opName)
                  }}
                  name="Pin"
                  placeholder="Seleccionar..."
                  options={operatorsList}
                  value={operatorsList.find(
                    (option) => option.Code === values.Pin
                  )}
                  getOptionLabel={(o) => '(' + o.Code + ') - ' + o.Name}
                  getOptionValue={(o) => o.Code}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '2px solid #d1d5db',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '2px solid #9ca3af'
                      }
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      color: '#333333',
                      backgroundColor: state.isFocused && '#d1d5db',
                      '&:hover': {
                        backgroundColor: '#f3f4f6'
                      }
                    })
                  }}
                />
              </div>

              {/* Campo 'Permisos': */}
              <div className="items-center block w-full px-2 pt-6 pb-3 print:bg-white">
                <label
                  className="block mb-2 text-grey-darker text-md font-ms-semi col-start-full"
                  htmlFor="name"
                >
                  Permisos
                </label>
                <Select
                  className="col-span-5 rounded text-grey-darker"
                  name="IdProfile"
                  onChange={(e) => {
                    setFieldValue('IdProfile', e.value)
                  }}
                  placeholder="Seleccionar..."
                  options={permissions}
                  value={permissions.find(
                    (option) => option.value === values.IdProfile
                  )}
                  getOptionLabel={(o) => o.label}
                  getOptionValue={(o) => o.value}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '2px solid #d1d5db',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '2px solid #9ca3af'
                      }
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      color: '#333333',
                      backgroundColor: state.isFocused && '#d1d5db',
                      '&:hover': {
                        backgroundColor: '#f3f4f6'
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>

          {/* Apartado 'Configuració adicional' + 'Botones': */}
          <div className="flex flex-col w-full">
            <div className="w-full mt-10">
              <label
                className="block mb-2 text-grey-darker text-md font-ms-semi col-start-full"
                htmlFor="name"
              >
                Configuració adicional
              </label>
            </div>

            {/* Apartado 'Configuració adicional': */}
            <div className="grid w-full grid-cols-12">
              <div className="grid w-full grid-cols-2 col-span-10">
                {/* Camp 'Rebre sol·licituds de modificació': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="NotifyFinishedTechnicalSheetRamRequest"
                      onChange={(e) =>
                        setFieldValue(
                          'NotifyFinishedTechnicalSheetRamRequest',
                          e.target.checked
                        )
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Notificar <i>Sol·licituds de Modificació</i>
                    </span>
                  </label>
                </div>

                {/* Camp 'Rebre notificacions de canvis a FT Acabats': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="NotifyFinishedTechnicalSheetChange"
                      onChange={(e) =>
                        setFieldValue(
                          'NotifyFinishedTechnicalSheetChange',
                          e.target.checked
                        )
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Notificar canvi a <i>FT Acabats</i>
                    </span>
                  </label>
                </div>

                {/* Camp 'Rebre notificacions de canvis a FT Teixiduria': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="NotifyOnWeavingTechnicalSheetChange"
                      onChange={(e) =>
                        setFieldValue(
                          'NotifyOnWeavingTechnicalSheetChange',
                          e.target.checked
                        )
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Notificar canvi a <i>FT Teixiduria</i>
                    </span>
                  </label>
                </div>

                {/* Camp 'Rebre notificacions de canvis a Clients': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="NotifyOnClientChange"
                      onChange={(e) =>
                        setFieldValue('NotifyOnClientChange', e.target.checked)
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Notificar canvi a <i>Clients</i>
                    </span>
                  </label>
                </div>

                {/* Camp 'Rebre notificacions de canvis a Usuaris': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="NotifyOnUserChange"
                      onChange={(e) =>
                        setFieldValue('NotifyOnUserChange', e.target.checked)
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Notificar canvi a <i>Usuaris</i>
                    </span>
                  </label>
                </div>

                {/* Camp 'Pot editar FT Teixiduria': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="CanEditWeavingTechnicalSheet"
                      onChange={(e) =>
                        setFieldValue(
                          'CanEditWeavingTechnicalSheet',
                          e.target.checked
                        )
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Pot editar <i>FT Teixiduria</i>
                    </span>
                  </label>
                </div>

                {/* Camp 'Pot editar FT Acabats': */}
                <div className="w-full p-1 pl-3 mt-2">
                  <label className="inline-flex items-center mt-0">
                    <Field
                      className="w-5 h-5 text-gray-600 form-checkbox"
                      type="checkbox"
                      name="FinishedTechnicalSheetEditPermission"
                      onChange={(e) =>
                        setFieldValue(
                          'FinishedTechnicalSheetEditPermission',
                          e.target.checked
                        )
                      }
                    />
                    <span className="ml-2 text-gray-700">
                      Pot editar <i>FT Acabats</i>
                    </span>
                  </label>
                </div>
              </div>

              {/* Botones: */}
              <div className="flex flex-col items-end justify-center w-full col-span-2 bg-gray-100 border-2 border-gray-300 divide-y divide-gray-300 rounded divide-dashed">
                <div className="flex items-center justify-around w-full h-full px-3">
                  <button
                    className="block w-full h-10 text-white transition duration-300 bg-yellow-500 border border-yellow-500 rounded hover:border-yellow-200 hover:bg-yellow-200 hover:text-yellow-600 font-ms-semi"
                    type="button"
                    onClick={(e) => {
                      handleResetPass(values)
                    }}
                    disabled={values.Id === 0 || user.Id === values.Id}
                  >
                    <span className="grid w-full grid-cols-3">
                      <div className="flex flex-row items-center justify-center col-span-1">
                        <MdOutlineRestore size={30} />
                      </div>

                      <div className="flex flex-row items-center justify-start col-span-2">
                        <span>REST. CONTRA.</span>
                      </div>
                    </span>
                  </button>
                </div>

                <div className="flex items-center justify-center w-full h-full px-3">
                  <button
                    className={`w-full block transition duration-300 
                    ${
                      values.IsRemoved
                        ? 'border-green-500 hover:border-green-200 bg-green-500 hover:bg-green-200 hover:text-green-600'
                        : 'border-red-500 hover:border-red-200 bg-red-500 hover:bg-red-200 hover:text-red-600 '
                    }
                    text-white font-ms-semi border rounded h-10`}
                    type="button"
                    onClick={(e) => {
                      handleChangeState(values)
                    }}
                    disabled={values.Id === 0 || user.Id === values.Id}
                  >
                    {values.IsRemoved ? (
                      <span className="grid w-full grid-cols-3">
                        <div className="flex flex-row items-center justify-center col-span-1">
                          <MdOutlineGppGood size={30} />
                        </div>

                        <div className="flex flex-row items-center justify-start col-span-2">
                          <span>HABILITAR</span>
                        </div>
                      </span>
                    ) : (
                      <span className="grid w-full grid-cols-3">
                        <div className="flex flex-row items-center justify-center col-span-1">
                          <MdOutlineGppBad size={30} />
                        </div>

                        <div className="flex flex-row items-center justify-start col-span-2">
                          <span>DESHABILITAR</span>
                        </div>
                      </span>
                    )}
                  </button>
                </div>

                <div className="flex items-center justify-center w-full h-full px-3">
                  <button
                    className="block w-full h-10 text-white transition duration-300 bg-green-500 border border-green-500 rounded hover:border-green-200 hover:bg-green-200 hover:text-green-600 font-ms-semi"
                    type="submit"
                    disabled={user.Id === values.Id}
                  >
                    <span className="grid w-full grid-cols-3">
                      <div className="flex flex-row items-center justify-center col-span-1">
                        <MdOutlineSave size={30} />
                      </div>

                      <div className="flex flex-row items-center justify-start col-span-2">
                        <span>GUARDAR</span>
                      </div>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

UserForm.propTypes = {
  fields: PropTypes.object,
  operatorsList: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func,
  handleResetPass: PropTypes.func
}
