import { useLocation } from 'wouter'
import { useContext, useEffect, useState } from 'react'
import JobForm from 'forms/RRHH/Jobs/JobForm'
import PropTypes from 'prop-types'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { ModalContext } from 'contexts/ModalContext'

export default function JobDetail({ params }) {
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [getClient, setGetClient] = useFetch()
  const [client, saveClient] = useFetch()
  const { handleModal } = useContext(ModalContext)
  const [deletedDocument, deleteDocumentAction] = useFetch()
  const [documentAction, saveDocumentAction] = useFetch()
  const [addDocumentsToTable, setAddDocumentsToTable] = useState([])

  const isNew = params.id === 'nou'

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API:
  const [fields, setFields] = useState({
    Id: 0,
    Edition: 1,
    Date: '',
    Description: '',
    AreaId: null,
    JobDependenceId: null,
    Functions: '',
    Responsibilities: '',
    Competences: ''
  })

  useEffect(() => {
    // Si el id del registro no es 'nou' significa que el usuario quiere editar un registro ya existente:
    if (params.id !== 'nou') {
      // Llamamos a la API para recoger el registro que se quiere editar:
      const findClientById = {
        url: `job/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      setGetClient(findClientById)
    } else {
      setTitle({
        name: 'Crear registre ',
        buttons: [
          {
            name: 'Tornar',
            link: `/lloc-treball`
          }
        ]
      })
    }
  }, [])

  // useEffect() para crear un objeto con los datos que nos llegan de la API, para eso se usa el useState() [fields, setFields]:
  useEffect(() => {
    if (getClient.data) {
      setTitle({
        name: 'Modificar registre ',
        buttons: [
          {
            name: 'Tornar',
            link: `/lloc-treball`
          }
        ],
        stateBtn: true,
        button: 'print',
        registerType: 'job',
        registerId: params.id,
        registerState: !getClient.data.IsRemoved
      })

      setFields({
        Id: getClient.data.Id,
        Edition: getClient.data.Edition,
        Date: getClient.data.Date,
        Description: getClient.data.Description,
        AreaId: getClient.data.AreaId,
        JobDependenceId: getClient.data.JobDependenceId,
        Functions: getClient.data.Functions,
        Responsibilities: getClient.data.Responsibilities,
        Competences: getClient.data.Competences
      })

      setAddDocumentsToTable(getClient.data.JobDocuments)
    }
  }, [getClient.data])

  // Función para guardar el documento:
  const handleSubmit = async (values) => {
    values.JobDocuments = null

    let url = `job`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    // Send PUT or POST //
    const clientApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al actualitzar el lloc de treball',
      messageOk: 'Lloc de treball actualitzat!'
    }
    await saveClient(clientApiParams)

    if (!client.error) setLocation('/lloc-treball')
  }

  // Función para añadir un nuevo documento:
  const uploadDocument = async (e) => {
    const files = e.currentTarget.files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    // Llamada a la API para añadir el nuevo archivo:
    const documentSaveToDataBase = {
      url: `job/${params.id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Document guardat!',
      messageKo: 'Error al guardar el document!'
    }
    await saveDocumentAction(documentSaveToDataBase)
  }

  // useEffect() para la función uploadDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (documentAction.data) {
      const stringToJsonParseDocuments = JSON.parse(documentAction.data)
      setAddDocumentsToTable(stringToJsonParseDocuments)
    }
  }, [documentAction.data])

  // Función para eliminar un fichero de la tabla de ficheros adjuntos:
  const deleteDocument = async (id) => {
    // Llamada a la API para eliminar el documento seleccionado:
    const deleteDocumentParams = {
      url: `job/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Document eliminat!',
      messageKo: 'Error al eliminar el document'
    }
    await deleteDocumentAction(deleteDocumentParams)

    handleModal('hide')
  }

  // useEffect() para la función deleteDocument, para recoger los datos de la API cuando se acaben de cargar:
  useEffect(() => {
    if (deletedDocument.data) {
      setAddDocumentsToTable(deletedDocument.data)
    }
  }, [deletedDocument.data])

  return (
    <>
      <div className="w-full print:h-full">
        <div
          className="w-full p-4 bg-white rounded-lg borde-2"
          style={{ borderColor: 'whitesmoke' }}
        >
          {
            <JobForm
              handleSubmit={handleSubmit}
              fields={fields}
              uploadDocument={uploadDocument}
              deleteDocument={deleteDocument}
              addDocumentsToTable={addDocumentsToTable}
              params={params}
              isNew={isNew}
            />
          }
        </div>
      </div>
    </>
  )
}

JobDetail.propTypes = {
  params: PropTypes.any
}
