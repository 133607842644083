// Fitxa tècnica Teixiduria - Nova finestra auto-print 'Accés Laboratori'

import Printer, { print } from 'react-pdf-print'
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'
import Logo from 'components/ui/Logo/Logo'

const currentdate = new Date()
const datetime =
  String(currentdate.getDate()).padStart(2, '0') +
  '/' +
  String(currentdate.getMonth() + 1).padStart(2, '0') +
  '/' +
  currentdate.getFullYear() +
  '  -  ' +
  String(currentdate.getHours()).padStart(2, '0') +
  ':' +
  String(currentdate.getMinutes()).padStart(2, '0') +
  'h'

const ids = ['1']
let fields = []

export default function FTTeixiduriaLabToPDF() {     

    // const localPrintFields = localStorage.getItem('localPrintFields') 
    // const [storePrintFields, setStorePrintFields] = useState({})
    const [localPrintData, setLocalPrintData] = useState({})


    useEffect ( ()=> {
        // getFields(storePrintFields)  <-- no fa falta, podem utilitzar storePrintFields del STATE directament sense GET
        // fields = storePrintFields 
        // MENTIRA !! hay que usar el LocalStorage:       
        setLocalPrintData(JSON.parse(localStorage.getItem('localPrintFields')))

        // console.log('LabToPDF useEffect[] - storePrintFields: ',storePrintFields)
        console.log('LabToPDF useEffect[] - fields: ',fields)
        console.log('LabToPDF useEffect[] - localPrintData: ',localPrintData)
    },[])

    useEffect ( ()=> {
        // getFields(storePrintFields)  <-- no fa falta, podem utilitzar storePrintFields del STATE directament sense GET
        fields = localPrintData
        console.log('LabToPDF useEffect[localPrintData] - fields: ',fields)
        console.log('LabToPDF useEffect[localPrintData] - localPrintData: ',localPrintData)
    },[localPrintData])

    console.log('function sendToPDF - fields: ',fields)

        const ids = ['1']
        console.log('ids: ',ids)
        return (
            <div id='checkListLaboratori' className='h-full w-full text-color-primary'> 
                <h1 className='justify-center'>FITXA CHECKLIST DE LABORATORI</h1>
                <hr/>
                <p> &nbsp; </p>
                <Printer>    
                    {/* <div id={ids[0]} style={{ width:'210mm', height: '10mm' }}>
                        CheckList Laboratori - Machine: {fields.MachineCode + ' ' + fields.Machine}
                    </div>                  */}
                    <div id={ids[0]} style={{ width:'220mm', height: '320mm' }}>
                        <div className='flex flex-col mx-6'>
                            {/* <p>&nbsp;</p> */}
                            <div>
                                <div className='w-100 border border-grey-400'>
                                    {/* {console.log('fields en FTTeixiduriaPrintLab modal...: ',fields)}                                 */}
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='border border-grey-800 w-40 h-14'>
                                                <Logo classes="w-15 h-8 object-contain" link="/" color="black" />                                                            
                                            </td>
                                            <td className='col-span-4 text-2xl border border-grey-800 flex justify-center w-11/12 h-14 py-3'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                            
                                                ANÀLISI TEIXIT SEMIELABORAT
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                                                    
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                                
                                            </td>
                                            <td className='text-xs text-left w-40 h-14'>Format-IATF-007-A/5 &nbsp;&nbsp; (11.03.22)</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <p>&nbsp;</p> */}
                                <div className='w-100 border border-grey-400'>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>SEMI: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                        
                                                <span className='text-gray-600 text-base font-normal italic'> {fields.SemiFinished}</span>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>MÀQ.: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                        
                                                <span className='text-gray-600 text-base font-normal italic'> {fields.Machine}</span>
                                            </td>
                                            <td className='w-4/12 text-blue-700 text-base font-bold px-1'>DATA: &nbsp;                                         
                                                <span className='text-gray-600 text-base font-normal italic'> {datetime}</span>
                                            </td>                                                                                
                                        </tr>
                                        <tr>                                                
                                            <td className='text-blue-700 text-base font-bold px-1'>LLIGAT: &nbsp;
                                                <span className='text-gray-600 text-base font-normal italic'> {fields.TiedDescription}</span>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td className='text-blue-700 text-base font-bold px-1'>GALGA: &nbsp;
                                                <span className='text-gray-600 text-base font-normal italic'> {fields.GG}-{fields.O}</span>
                                            </td>
                                            <td className='text-blue-700 text-base font-bold px-1'>Nom...: {fields.DetectedUserName}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-blue-700 text-base font-bold px-1'>PROTO: </td>
                                            <td>&nbsp;</td>
                                            <td className='text-blue-700 text-base font-bold px-1'>Nº.Peç: </td>                                        
                                            <td className='text-blue-700 text-base font-bold px-1'>FIL...: </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <p>&nbsp;</p> */}
                            {/* <Printer> */}
                            <table id={ids[0]} >
                                <caption className='text-base bg-blue-200 px-3 pt-1 font-bold'>TAULA DE VALORS</caption>
                                <thead className='text-base bg-blue-200 px-3'>
                                    <tr>
                                        <th>PARÀMETRES</th>
                                        <th>NORMES</th>
                                        <th className='text-blue-200'>&nbsp;__________</th>
                                        <th className='text-blue-200'>&nbsp;__________</th>
                                        <th className='text-blue-200'>&nbsp;__________</th>
                                        <th className='text-blue-200'>&nbsp;__________</th>
                                        <th className='text-blue-200'>&nbsp;__________</th>
                                        <th className='text-blue-200'>&nbsp;__________</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border border-gray-300 px-5'>
                                        <td className='border border-gray-300 px-0 py-2'>Passades (u/cm)</td>
                                        <td className='border border-gray-300 px-0 text-right'>{fields.Repetitions} &nbsp; &#177; {fields.RepetitionsTolerance}&nbsp;&nbsp;</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Columnes (u/cm)</td>
                                        <td className='border border-gray-300 px-0 text-right'>{fields.Columns} &nbsp; &#177; {fields.ColumnsTolerance}&nbsp;&nbsp;</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>          
                                    <tr className='border border-gray-300'>
                                        <td className='border border-gray-300 px-0 py-2'>Gramatge (gr/m2)</td>
                                        <td className='border border-gray-300 px-0 text-right'>{fields.Weight} &nbsp; &#177; {fields.WeightTolerance}&nbsp;&nbsp;</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>                    
                                    <tr>
                                        <th className='col-span-1 bg-blue-200 text-'>DINAMÒMETRE QUE S&#39;USA:</th>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                        <td className='col-span-1 bg-blue-200'>&nbsp; </td>
                                    </tr>      

                                    <tr className='border border-gray-300 bg-gray-000'>
                                        <td className='border border-gray-300 px-0 py-2'>Força màx. (kg) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; L: </td>
                                        {/* <td className='grid border border-gray-300 py-0 px-0'> 
                                            <td className='bg-gray-000 py-2'>L: </td>
                                            <td className='bg-gray-200 py-2'>T: </td>
                                        </td> */}
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>  
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Força màx. (kg) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; T: </td>
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>                                            
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>  

                                    <tr className='border border-gray-300 bg-gray-000'>
                                        <td className='border border-gray-300 px-0 py-2'>Allarg. Força màx.(%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; L: </td>
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Allarg. Força màx.(%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; T: </td>
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>                                        
                                    <tr className='border border-gray-300 bg-gray-000'>
                                        <td className='border border-gray-300 px-0 py-2'>Allargament (%) &nbsp;2,5 Kg &nbsp;&nbsp;&nbsp;&nbsp; L: </td>                                            
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>            
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Allargament (%) 5,0 Kg &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; L: </td>                                            
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>
                                    <tr className='border border-gray-300 bg-gray-000'>
                                        <td className='border border-gray-300 px-0 py-2'>Allargament (%) 10,0 Kg &nbsp;&nbsp; L: </td>                                            
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Allargament (%) &nbsp;2,5 Kg &nbsp;&nbsp;&nbsp;&nbsp; T: </td>                                            
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>
                                    <tr className='border border-gray-300 bg-gray-000'>
                                        <td className='border border-gray-300 px-0 py-2'>Allargament (%) 5,0 Kg &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; T: </td>                                            
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>    
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Allargament (%) 10,0 Kg  &nbsp;&nbsp; T: </td>                                            
                                        <td className='border border-gray-300 px-1 text-center'>info</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>                                    
                                    <tr className='border border-gray-300 bg-gray-000'>
                                        <td className='border border-gray-300 px-0 py-2'>Elong.estàtica(%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; L:</td>
                                        <td className='border border-gray-300 px-1 text-center'>no analitzar</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>                                      
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Elong.estàtica(%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; T:</td>
                                        <td className='border border-gray-300 px-1 text-center'>no analitzar</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                    </tr>                                            
                                    <tr className='border border-gray-300'>
                                        <td className='border border-gray-300 px-0 py-2'>Ample(cm)</td>
                                        <td className='border border-gray-300 px-0 text-right'>{fields.Width} &nbsp;&nbsp;&nbsp; &#177;{fields.WidthTolerance}&nbsp;&nbsp;</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>                                       
                                    </tr>                         
                                    <tr className='border border-gray-300 bg-gray-200'>
                                        <td className='border border-gray-300 px-0 py-2'>Gruix (mm)</td>
                                        <td className='border border-gray-300 px-0 text-right'>{fields.Thickness}&nbsp; &#177;{fields.ThicknessTolerance}&nbsp;&nbsp;</td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>
                                        <td className='border border-gray-300 px-1'></td>                                         
                                    </tr>                         
                                </tbody>
                                <tfoot className='text-base border border-gray-300 bg-blue-200 px-3'>                                                                     
                                    <tr>
                                        <th className='col-span-1 py-2'>Observacions de Laboratori: </th>
                                        <td className='col-span-1'>&nbsp; </td>
                                        <td className='col-span-1'>&nbsp; </td>
                                        <td className='col-span-1'>&nbsp; </td>
                                        <td className='col-span-1'>&nbsp; </td>
                                        <td className='col-span-1'>&nbsp; </td>
                                        <td className='col-span-1'>&nbsp; </td>
                                        <td className='col-span-1'>&nbsp; </td>
                                    </tr>                                    
                                </tfoot>                                                            
                            </table>
                            {/* </Printer> */}

                            <p className='text-red-600 text-left'> * &nbsp;&nbsp;Encara que posi INFO s&apos;han d&apos;analitzar els paràmetres amb les condicions que s&apos;indiquen. No s&apos;ha d&apos;analitzar només en el cas que posi explícitament NO ANALITZAR.</p>
                            {/* <p>&nbsp;</p> */}
                            <div className='flex justify-center'>
                                <div className='text-sm'>Anàlisi aprovat per: &nbsp;</div>
                            </div>                             
                        </div>                    
                        {/* <input  type='button' 
                            style={{ position: 'relative', float: 'right', border: '5', margin: '8px', padding: '5px'}}
                            className='bg-green-300'
                            onClick={() => print(ids)} value='Impresora' 
                        /> */}
                    </div> 
                </Printer>   
                {/* {window.onloadend() => print(ids)}   */}

                {/* {{if (fields) { 
                    print(ids[1])
                    window.close()
                    }
                }} */}

                <div onloadend={print(ids)}>.
                </div>
                    {/* <input  type='button' 
                            style={{ position: 'relative', float: 'right', border: '5', margin: '8px', padding: '5px'}}
                            className='bg-green-300'
                            onClick={() => print(ids)} value='Impresora'                             
                    /> */}
                {/* </div>            */}
            </div>
        )
}

FTTeixiduriaLabToPDF.propTypes = {}
