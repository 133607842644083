import PropTypes from 'prop-types'
// import Navbar from './Navbar/Navbar'
import Title from './Title/Title'
import Sidebar from './Sidebar/Sidebar'
import Footer from './Footer/Footer'

export default function Layout({ children }) {
  return (
    <>
      <section className="flex flex-col w-full mx-auto scroll-smooth print:m-0 print:p-0">
        {/* <Navbar /> */}
        <main className="flex flex-grow w-full print:m-0 print:p-0">
          <Sidebar />

          <div className="flex flex-col w-full h-screen overflow-y-hidden sm:ml-64 print:h-full print:p-0 print:m-0">
            <nav className="w-full py-3 border-l border-blue-600 border-dashed bg-charcoal print:hidden">
              <div className="w-full px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center justify-start w-full h-8">
                    <Title />
                  </div>
                </div>
              </div>
            </nav>

            {/* <div id="scrollable-parent" className="flex-grow p-4 overflow-y-auto print:p-0 print:m-0"> */}
            <div id="scrollable-parent" className="flex-grow overflow-y-auto print:p-0 print:m-0">
              {/* <Title /> */}
              <div className='print:p-0 print:m-0'>{children}</div>
            </div>
          </div>
        </main>
        <Footer />
      </section>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
