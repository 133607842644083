import PropTypes from 'prop-types'

export default function EdgesForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:auto-rows-auto print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2">
      {/* NOM COLA */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nom cola:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Glue !== valuesAfter?.Glue
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Glue : valuesAfter?.Glue}
        </div>
      </div>

      {/* PROPORCIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Proporció:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.GlueProportion !== valuesAfter?.GlueProportion
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.GlueProportion
            : valuesAfter?.GlueProportion}
        </div>
      </div>

      {/* COLOR */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Color:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.GlueColor !== valuesAfter?.GlueColor
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {(() => {
            switch (
              side === 'left' ? valuesBefore?.GlueColor : valuesAfter?.GlueColor
            ) {
              case '1':
                return 'Incolor'
              case '2':
                return 'Blau'
              case '3':
                return 'Vermell'
              case '4':
                return 'Marró'
              case '5':
                return 'Verd'
              case '6':
                return 'Groc'
              case '7':
                return 'Mixte'
              default:
                return '-'
            }
          })()}
        </div>
      </div>

      {/* ENCOLADOR */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Encolador:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.GlueMachine !== valuesAfter?.GlueMachine
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {(() => {
            switch (
              side === 'left'
                ? valuesBefore?.GlueMachine
                : valuesAfter?.GlueMachine
            ) {
              case '1':
                return 'Estret'
              case '2':
                return 'Ample'
              default:
                return '-'
            }
          })()}
        </div>
      </div>

      {/* TALLAR VORES */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Tallar Vores:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.HasToCutEdge !== valuesAfter?.HasToCutEdge
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.HasToCutEdge
              ? 'Sí'
              : 'No'
            : valuesAfter?.HasToCutEdge
            ? 'Sí'
            : 'No'}
        </div>
      </div>

      {/* TALLADORS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Talladors:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.Cuters !== valuesAfter?.Cuters
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Cuters : valuesAfter?.Cuters}
        </div>
      </div>

      {/* LATERALS ALINEATS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Laterals alineats:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.AlignedSides !== valuesAfter?.AlignedSides
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.AlignedSides
            : valuesAfter?.AlignedSides}
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b ${
            valuesBefore?.EdgeObservation !== valuesAfter?.EdgeObservation
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300'
          } overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.EdgeObservation
            : valuesAfter?.EdgeObservation}
        </div>
      </div>
    </div>
  )
}

EdgesForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
