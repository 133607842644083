import PropTypes from 'prop-types'
import Form1 from 'forms/ftacabatsrequest/descriptionCompare'
import Form2 from 'forms/ftacabatsrequest/featuresCompare'
import Form3 from 'forms/ftacabatsrequest/openMachineCompare'
import Form4 from 'forms/ftacabatsrequest/ultrasonicSettingsCompare'
import Form5 from 'forms/ftacabatsrequest/foulardsCompare'
import Form6 from 'forms/ftacabatsrequest/edgesCompare'
import Form7 from 'forms/ftacabatsrequest/controlsCompare'
import Form8 from 'forms/ftacabatsrequest/packagingCompare'

export default function Block({
  title,
  className,
  side,
  isAnswered,
  users,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md ${
        side === 'left' ? 'border-gray-300' : 'border-blue-200'
      }`}
    >
      <div
        className={`flex items-center justify-between w-full px-2 py-2 text-sm uppercase ${
          side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
        } print:py-1 font-ms-bold`}
      >
        <span>{title}</span>
        {/* <span className="text-xs italic text-gray-900">{side === 'left' ? 'Abans' : 'Després'}</span> */}
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return (
              <Form1
                side={side}
                isAnswered={isAnswered}
                users={users}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Característiques':
            return (
              <Form2
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case "Màquina d'obrir":
            return (
              <Form3
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Reglatges ultrasons':
            return (
              <Form4
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Acabats foulard':
            return (
              <Form5
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Vores':
            return (
              <Form6
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Controls':
            return (
              <Form7
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Embalatge':
            return (
              <Form8
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  isAnswered: PropTypes.bool,
  users: PropTypes.array,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
