import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'

export default function MTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  rows,
  isMaintenanceOthers
}) {
  const [hoursState, setHoursState] = useState(0)
  const [minutesState, setMinutesState] = useState(0)

  // useEffect() para el recuento de horas, se ejecuta cada vez que cambie el número de filas de la tabla,
  // para eso sirve la variable 'rows' que es una variable interna del 'React Table' que recoge las filas
  // de la tabla a tiempo real, incluso cuando se usan filtros:
  useEffect(() => {
    // Variables donde guardaremos las horas y los minutos de cada registro:
    let getHours   = 0
    let getMinutes = 0

    // Variables donde acumularemos la suma de las horas y de los minutos:
    let totalSumHours   = 0
    let totalSumMinutes = 0

    // Variable para resetear los 'useState()' donde guardamos el total de horas y minutos
    // porque si no se resetea la variable se acumula infinitamente:
    const initializeValues = 0

    // Si existen filas en la tabla entramos:
    if(rows) {
      // Reseteamos los 'useState()' para que no se nos acumulen las horas infinitamente:
      setHoursState(initializeValues)
      setMinutesState(initializeValues)

      // Recorremos el array de filas:
      rows.forEach((row) => {
        // El componente 'MTableBar' se usa para Mantenimiento de 'Acabats' y de 'Teixiduria', por esta razón
        // creamos este condicional, dependiendo de en qué componente padre nos encontremos recogeremos las horas 
        // de 'Acabats' o de 'Teixiduria' y los acumulamos en la variable 'totalSumHours':
        if(isMaintenanceOthers) {
          getHours = parseInt(parseInt(row.values.MaintenanceOthers_CorrectiveTimeHours)).toFixed()
        } else {
          getHours = parseInt(parseInt(row.values.Maintenance_CorrectiveTimeHours)).toFixed()
        }
        totalSumHours = totalSumHours + parseInt(getHours)

        // Dependiendo de en qué componente padre nos encontremos recogeremos los minutos de 'Acabats' o de 'Teixiduria'
        // y los acumulamos en la variable 'totalSumMinutes':
        if(isMaintenanceOthers) {
          getMinutes = parseInt(parseInt(row.values.MaintenanceOthers_CorrectiveTimeMinutes)).toFixed()
        } else {
          getMinutes = parseInt(parseInt(row.values.Maintenance_CorrectiveTimeMinutes)).toFixed()
        }
        totalSumMinutes = totalSumMinutes + parseInt(getMinutes)

        // 1 hora = 60 minutos, por lo tanto, en caso de que la suma total de minutos exceda los 60 minutos
        // este condicional convertirá los minutos en horas:
        if (totalSumMinutes > 59) {
          const convertedHours = Math.floor(parseInt(totalSumMinutes) / 60);
          const convertedMinutes = parseInt(totalSumMinutes) % 60;

          totalSumHours = totalSumHours + parseInt(convertedHours)
          totalSumMinutes = parseInt(convertedMinutes)  
        }
      })
    }

    // Guardamos el total de horas y el total de minutos en variables 'useState()' para que se modifique el resultado por 
    // pantalla a tiempo real:
    setHoursState(parseInt(totalSumHours))
    setMinutesState(parseInt(totalSumMinutes))
  }, [rows])
  
  return (    
    <div className="w-full text-center print:hidden px-2 py-3 bg-blue-200 grid grid-cols-3">
      <div className="flex justify-start items-center">
        <p className="float-left text-left font-bold">
          Temps total: &nbsp;
          <span className="text-primary">
            {hoursState}h {minutesState}min
            {/* {txtHours? txtHours : `00`}h. {txtMinutes? txtMinutes : `00`} m.{' '}  */}
          </span>
        </p>
      </div>

      <div className="mx-auto flex flex-row justify-center items-center">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="font-bold rounded-full text-white disabled:opacity-0 text-center align-middle flex justify-center items-center my-auto"
          style={{ width: '24px', marginRight: '7px', height: '24px' }}
        >
          <BsArrowLeftCircle size={36} color="#be0043" />
        </button>

        <span>
          Pàgina{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </span>

        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="font-bold rounded-full text-white disabled:opacity-0 text-center align-middle flex justify-center items-center my-auto"
          style={{ width: '24px', marginLeft: '7px', height: '24px' }}
        >
          <BsArrowRightCircle size={26} color="#be0043" />
        </button>
      </div>

      <select
        className="float-right rounded border-2 border-charcoal p-1 ml-auto mr-0"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >          
        {[25, 50, 100, 200, 500].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize} registres
          </option>
        ))}
      </select>        
    </div>      
  )
}
MTableBar.propTypes = {
  previousPage:        PropTypes.func,
  canPreviousPage:     PropTypes.bool,
  pageIndex:           PropTypes.number,
  pageOptions:         PropTypes.array,
  nextPage:            PropTypes.func,
  canNextPage:         PropTypes.bool,
  pageSize:            PropTypes.number,
  setPageSize:         PropTypes.func,
  rows:                PropTypes.array,
  isMaintenanceOthers: PropTypes.bool
}
