// PropTypes
import PropTypes from 'prop-types'

export default function OpenMachineForm({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'
  const checkStyles = 'border border-gray-800 ft-check'

  return (
    <div className={blockStyle}>
      {/* TIPUS PLEGADOR */}
      <div className={firstLabelStyles}>Tipus plegador:</div>
      <div className={firstParentStyles}>
        <div className={innerParentStyles}>{values?.Folding}</div>
      </div>

      {/* OBRIR AMB */}
      <div className={otherLabelsStyles}>Obrir amb:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.OpenWith}</div>
        <div className={checkStyles}></div>
      </div>

      {/* OBRIR AL REVÉS */}
      <div className={otherLabelsStyles}>Obrir del Revés:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {values?.IsOpenedReverse ? 'Sí' : 'No'}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* ACOMPANYADOR */}
      <div className={otherLabelsStyles}>Acompanyador:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.Follower}</div>
        <div className={checkStyles}></div>
      </div>

      {/* OBSERVACIONS */}
      <div className={otherLabelsStyles}>Observacions:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.ObservationMachine}</div>
      </div>

      {/* TIPUS COSTURA */}
      <div className={otherLabelsStyles}>Tipus costura:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>
          {(() => {
            switch (values?.SewingType) {
              case '1':
                return 'Ultrasons'
              case '2':
                return 'Màquina cosir fil'
              default:
                return '-'
            }
          })()}
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* COSTURA OK */}
      <div className={otherLabelsStyles}>Costura &apos;Ok&apos;:</div>
      <div className={`${otherParentStyles} justify-end`}>
        <div className={checkStyles}></div>
      </div>
    </div>
  )
}

OpenMachineForm.propTypes = {
  values: PropTypes.object
}
