import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function GameTable({ games }) {
  const sizeOptions = [
    { value: 'LFA', label: 'LFA' },
    { value: 'W', label: 'W' },
    { value: 'MRA', label: 'MRA' },
    { value: 'SCH', label: 'SCH' }
  ]

  return (
    <table className="relative w-full px-4 border table-fixed border-primary">
      <thead className="w-full text-white bg-primary-dark">
        <tr className="w-full border-b divide-x divide-primary divide-solid border-primary">
          <th className="w-1/12 overflow-hidden text-sm truncate">Joc</th>
          <th className="w-6/12 overflow-hidden text-sm truncate">Matèria</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Caps</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Cons</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tipus</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Consum</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tol.</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tensió</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonC</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonP</th>
          <th className="w-1/12 overflow-hidden text-sm truncate py-1.5">
            Kg/h
          </th>
        </tr>
      </thead>

      <tbody className="w-full divide-y divide-primary divide-solid">
        {games?.length > 0 &&
          games
            .filter((game) => game.HistoryMachineGroup)
            .map((game, index) => (
              <tr
                className="w-full h-16 divide-x divide-primary divide-solid"
                key={index}
              >
                {/* Campo 'Joc': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.Game}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 py-0.5 text-xs">
                  <div className="flex items-center">
                    <div className="w-full px-0.5 py-1 border-gray-300 whitespace-normal">
                      {game?.HistoryMachineGroup?.ThreadMatter}
                    </div>
                  </div>
                </td>

                {/* Campo 'Caps': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.Heads}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Cons': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.Cones}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Tipus': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={
                        sizeOptions?.find(
                          (option) =>
                            option.value === game.HistoryMachineGroup.Size
                        )?.label
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Consum': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.Spending}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Tol.': */}
                <td className="w-1/12 text-xs">
                  <div className="flex flex-row items-center">
                    <span>&plusmn;</span>
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.TolSpending}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Tensió': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.Pressure}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'NonC': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.NoniusC}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'NonP': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.NoniusP}
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Kg/H': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      value={game?.HistoryMachineGroup?.KgH}
                      disabled
                    />
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

GameTable.propTypes = {
  games: PropTypes.array
}
