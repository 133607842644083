import DropdownComp from './DropdownComp/DropdownComp';
import PropTypes from 'prop-types'

// Hooks:
import { useLocation, Link } from 'wouter'
import { useContext, useState } from "react";

// Icons:
import customersIcon from 'assets/img/people.png'
import usersIcon from 'assets/img/people (1).png'
import weavingIcon from 'assets/img/weaving.png'
import finishedIcon from 'assets/img/fabric.png'
import homeIcon from 'assets/img/home.png'
import NCIcon from 'assets/img/contact-form (2).png'
import mtIcon from 'assets/img/maintenance.png'
import preventiuIcon from 'assets/img/medical-insurance.png'
import inventory from 'assets/img/check-list.png'
import nc from 'assets/img/nc.png'
import dashboard from 'assets/img/iconDashboard.png'
import rrhh from 'assets/img/rrhh.png'
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { UserContext } from 'contexts/UserContext';

const MenuItems = ({ item, user, location }) => {

  // Creamos una variable que decidirá cuando abrir y cerrar un menú deplegable, cada elemento
  // del menú tendrà su propia variable para decirid cuándo se desplegará y cuando se cerrará:
  const [dropdown, setDropdown] = useState(false)
  
  // Variable para desplegar un menú y así mostrar la página en la que nos encontramos: 
  const [firstTimeAutoOpen, setFirstTimeAutoOpen] = useState(true)  

  let checkMenuPermissions = true
  
  if(item.requiredPermissions !== null) {
    checkMenuPermissions = item.requiredPermissions.every((permission) => user.Permissions.includes(permission))
  }

  return checkMenuPermissions && (
    <>  
      {/* Si el elemento del menú debe ser desplegable porque tiene elementos de submenú entramos aquí: */}
      {item.subMenu ? (
        <>  
          <li className='flex flex-row items-center justify-between' key={item.slug}>
            <Link 
              id={item.title} 
              className="flex flex-row items-center justify-between w-full p-2 text-gray-100 rounded hover:bg-gray-900"
              href={item.slug}
              onClick={() => {setDropdown((state) => !state)}}
            >
              <div className='flex items-center'>
                <img
                  src={item.icon}
                  alt="customers"
                  className="w-5 h-5 mr-2"
                />
                
                <span className="ml-3">{item.title}</span>
              </div>

              {/* Icono de deplegable: */}
              <MdOutlineArrowDropDownCircle size={22} style={{ color: "white", position: "relative", right: "0", transform: dropdown ? 'rotate(180deg)' : '' }} />
            </Link>
          </li>   

          <ul className={`pb-2 space-y-2 font-medium list-none ${!dropdown && "hidden"}`}>  
            {item.subMenu.map((submenu, index) => {
              // Si la URL coincide con un elemento que haya en el menú y este se encuentra oculto en un submenú, lo que haremos
              // será obligar a que se abra este desplegable solo 1 vez al recargar una página para que así el usuario sepa dónde se 
              // ubica en la aplicación: 
              if(location === submenu.slug && firstTimeAutoOpen) {
                setDropdown(true)
                setFirstTimeAutoOpen(false)
              }

              return (
                <DropdownComp submenu={submenu} user={user} key={submenu.slug} />  
              )
            })} 
          </ul>   

          {/* Submenú: */}
          {/* Lista de submenú. Nos llega la variable 'dropdown' del componente padre 'MenuItems.js' que decidirá si este menú estará abierto o cerrado, 
          al ser una variable 'useState' cuando el usuari haga click sobre un elemento esta variable se modificará en el componente padre y automáticamente
          se cambiará aquí, en el hijo: */}
          {/* <ul id={item.title} className={`${dropdown ? "block divToHide" : "hidden divToHide"}`}>
            {item.subMenu.map((submenu, index) => {
              // Si la URL coincide con un elemento que haya en el menú y este se encuentra oculto en un submenú, lo que haremos
              // será obligar a que se abra este desplegable solo 1 vez al recargar una página para que así el usuario sepa dónde se 
              // ubica en la aplicación: 
              if(location === submenu.slug && firstTimeAutoOpen) {
                setDropdown(true)
                setFirstTimeAutoOpen(false)
              }

              return (
                <DropdownComp submenu={submenu} user={user} location={location} key={submenu.slug} />  
              )
            })}
          </ul> */}

        </>
      ) : (
        <li key={item.slug}>
          <Link 
            id={item.title} 
            className="flex items-center p-2 text-gray-100 rounded hover:bg-gray-900"
            href={item.slug}
            onClick={() => {setDropdown((state) => !state)}}
          >
            <img
              src={item.icon}
              alt="customers"
              className="w-5 h-5 mr-2"
            />
            <span className="ml-3">{item.title}</span>
          </Link>
        </li>
        
        // Si el elemento no va a ser desplegable entramos aquí, solo crearemos un botón con un enlace:
        // <Link href={item.slug} key={item.slug}>
        //   <button
        //     className={`flex px-3 py-3 text-xs 2xl:text-sm item-center font-ms-semi text-white border-t-2
        //       hover:bg-opacity-50 hover:bg-cyan_200 cursor-pointer  
        //       ${
        //         // Sirve para destacar en el menú el nombre de la página en la que nos encontramos:
        //         item.slug === location
        //         ? 'bg-cyan-200 bg-opacity-100'
        //         : 'bg-transparent'
        //       }                  
        //       ${
        //         item.active ? 'hover:bg-opacity-50  hover:bg-cyan-300 hover:border-2 hover:border-cyan-400 cursor-pointer' : 'cursor-default'
        //       }
        //     `}
        //   >
        //     <img
        //       src={item.icon}
        //       alt="customers"
        //       className="w-5 h-5 mr-2"
        //     />

        //     {/* Nombre de la página: */}
        //     <span>{item.title.toUpperCase()}</span>  
        //   </button>
        // </Link>
      )}
    </>
  )
}

MenuItems.propTypes = {
  item:     PropTypes.object,
  user:     PropTypes.object,
  location: PropTypes.string
}

export default MenuItems