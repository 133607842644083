// PropTypes
import PropTypes from 'prop-types'

export default function ControlsForm({ values }) {
  const blockStyle =
    'grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'
  const checkStyles = 'border border-gray-800 ft-check'

  return (
    <div className={blockStyle}>
      {/* V.A. (EVS) ESTIL */}
      <div className={firstLabelStyles}>V.A. (EVS) Estil:</div>
      <div className={firstParentStyles}>
        <div className={innerParentStyles}>{values?.ControlStyle}</div>
        <div className={checkStyles}></div>
      </div>

      {/* CONTROL Tª */}
      <div className={otherLabelsStyles}>Control Tª:</div>
      <div className={otherParentStyles}>
        <div className="grid items-center w-full h-full grid-cols-12 gap-0.5 xl:gap-2 auto-rows-min pl-2 lg:pl-0 print:pl-0">
          {/* VMT */}
          <div className="col-span-12 text-sm xl:col-span-1 print:col-span-2">
            VMT
          </div>
          <div className="col-span-12 xl:col-span-5 print:col-span-4 w-full p-0.5 min-h-8 print:h-full leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
            {values?.VMT}
          </div>

          {/* OMT */}
          <div className="col-span-12 mt-3 text-sm xl:col-span-1 print:col-span-2 print:m-0 lg:mt-4 xl:mt-0">
            OMT
          </div>
          <div className="col-span-12 xl:col-span-5 print:col-span-4 w-full p-0.5 min-h-8 print:h-full leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
            {values?.OMT}
          </div>

          {/* MAN */}
          <div className="col-span-12 mt-3 text-sm xl:col-span-1 print:col-span-2 print:m-0 lg:mt-4 xl:mt-0">
            MAN
          </div>
          <div className="col-span-12 xl:col-span-5 print:col-span-4 w-full p-0.5 min-h-8 print:h-full leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
            {values?.MAN}
          </div>

          {/* AUT */}
          <div className="col-span-12 mt-3 text-sm xl:col-span-1 print:col-span-2 print:m-0 lg:mt-4 xl:mt-0">
            AUT
          </div>
          <div className="col-span-12 xl:col-span-5 print:col-span-4 w-full p-0.5 min-h-8 print:h-full leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
            {values?.AUT}
          </div>
        </div>
        <div className={checkStyles}></div>
      </div>

      {/* TEMPS */}
      <div className={otherLabelsStyles}>Temps:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.ControlTime}</div>
        <span>{`"`}</span>
        <div className={checkStyles}></div>
      </div>

      {/* TEMPERATURA */}
      <div className={otherLabelsStyles}>Temperatura:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.ControlTemperature}</div>
        <span>ºC</span>
        <div className={checkStyles}></div>
      </div>

      {/* OBSERVACIONS */}
      <div className={otherLabelsStyles}>Observacions:</div>
      <div className={otherParentStyles}>
        <div className={innerParentStyles}>{values?.ControlObservation}</div>
      </div>
    </div>
  )
}

ControlsForm.propTypes = {
  values: PropTypes.object
}
