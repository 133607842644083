// PropTypes
import PropTypes from 'prop-types'

export default function FoulardsFinishForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 lg:auto-rows-min print:p-2 md:gap-y-1 lg:gap-y-3 print:gap-y-2 print:auto-rows-auto">
      {/* CONTINGUT CUBETA */}
      <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Contingut cubeta:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.FoulardName}
        </div>
      </div>

      {/* FÒRMULA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Fòrmula:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.FoulardFormula}
        </div>
      </div>

      {/* DOSICIFACIÓ */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Dosificació:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.IsDosificated ? 'Sí' : 'No'}
        </div>
      </div>

      {/* Nº RECEPTA */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Nº recepta:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.FoulardRecipeNumber}
        </div>
      </div>

      {/* OBSERVACIONS */}
      <div className="col-span-12 mt-5 text-sm print:col-span-3 lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0">
        Observacions:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
          {values?.FoulardObservation}
        </div>
      </div>
    </div>
  )
}

FoulardsFinishForm.propTypes = {
  values: PropTypes.object
}
