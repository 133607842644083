import Form1 from 'forms/ftteixiduriarequest/descriptionCreate'
import Form2 from 'forms/ftteixiduriarequest/folderCreate'
import Form5 from 'forms/ftteixiduriarequest/heightCreate'
import Form6 from 'forms/ftteixiduriarequest/observationsCreate'
import Form4 from 'forms/ftteixiduriarequest/pointGraduationCreate'
import Form3 from 'forms/ftteixiduriarequest/sprocketsCreate'
import PropTypes from 'prop-types'

export default function Block({
  title,
  className,
  setFieldValue,
  values,
  allValues,
  threads
}) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md border-blue-200`}
    >
      <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-blue-200 print:py-1 font-ms-bold">
        <span>{title}</span>
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return (
              <Form1
                values={values}
                setFieldValue={setFieldValue}
                threads={threads}
                allValues={allValues}
              />
            )
          case 'Plegador / Calandra':
            return (
              <Form2
                machineCode={values?.Machine?.slice(0, 3)}
                // machineCode="PLT"
              />
            )
          case 'Pinyons / Politja':
            return (
              <Form3
                machineCode={values?.Machine?.slice(0, 3)}
                // machineCode="PLT"
              />
            )
          case 'Graduació del punt i consum':
            return (
              <Form4
                setFieldValue={setFieldValue}
                threads={threads}
                allValues={allValues}
              />
            )
          case 'Alçada i sincronisme':
            return <Form5 />
          case 'Observacions / Notes':
            return <Form6 />
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  allValues: PropTypes.object,
  threads: PropTypes.any
}
