import { Field } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export default function GameTable({ games, side, isAnswered }) {
  const [different, setDifferent] = useState(false)

  useEffect(() => {
    if (
      games &&
      JSON.stringify(games.filter((t) => t.HistoryMachineGroup)) ===
        JSON.stringify(games.filter((t) => !t.HasBeenRemoved))
    ) {
      const isDifferent = games.some((game) => {
        const history = {
          Id: game?.HistoryMachineGroup?.HistoryMachineGroupId,
          IdThread: game?.HistoryMachineGroup?.IdThread,
          ThreadMatter: game?.HistoryMachineGroup?.ThreadMatter,
          Spending: game?.HistoryMachineGroup?.Spending,
          TolSpending: game?.HistoryMachineGroup?.TolSpending,
          Size: game?.HistoryMachineGroup?.Size,
          Pressure: game?.HistoryMachineGroup?.Pressure,
          Game: game?.HistoryMachineGroup?.Game,
          NoniusC: game?.HistoryMachineGroup?.NoniusC,
          NoniusP: game?.HistoryMachineGroup?.NoniusP,
          LFA: game?.HistoryMachineGroup?.LFA,
          KgH: game?.HistoryMachineGroup?.KgH,
          Cones: game?.HistoryMachineGroup?.Cones,
          TSWeavingID: game?.HistoryMachineGroup?.TSWeavingID,
          GameNumber: game?.HistoryMachineGroup?.GameNumber,
          Heads: game?.HistoryMachineGroup?.Heads,
          Title: game?.HistoryMachineGroup?.Title,
          IntMachineGroup: game?.HistoryMachineGroup?.IntMachineGroup
        }

        const current = {
          Id: game?.RequestMachineGroupId,
          IdThread: game?.IdThread,
          ThreadMatter: game?.ThreadMatter,
          Spending: game?.Spending,
          TolSpending: game?.TolSpending,
          Size: game?.Size,
          Pressure: game?.Pressure,
          Game: game?.Game,
          NoniusC: game?.NoniusC,
          NoniusP: game?.NoniusP,
          LFA: game?.LFA,
          KgH: game?.KgH,
          Cones: game?.Cones,
          TSWeavingID: game?.TSWeavingID,
          GameNumber: game?.GameNumber,
          Heads: game?.Heads,
          Title: game?.Title,
          IntMachineGroup: game?.IntMachineGroup
        }

        return JSON.stringify(history) !== JSON.stringify(current)
      })

      setDifferent(isDifferent)
    } else {
      setDifferent(true)
    }
  }, [games])

  const sizeOptions = [
    { value: 'LFA', label: 'LFA' },
    { value: 'W', label: 'W' },
    { value: 'MRA', label: 'MRA' },
    { value: 'SCH', label: 'SCH' }
  ]

  // Calcular los estilos
  const getFieldStyles = (valueBefore, valueAfter, hasBeenRemoved) => {
    const hasChanged = hasBeenRemoved ? true : valueBefore !== valueAfter

    return {
      backgroundColor: hasChanged
        ? side === 'left'
          ? '#FEE2E2' // Rojo si cambió en el lado izquierdo
          : isAnswered
          ? '#D1FAE5' // Verde si está respondido
          : '#FEF3C7' // Amarillo en caso contrario
        : '#F3F4F6', // Fondo por defecto
      border: hasChanged
        ? side === 'left'
          ? '1px solid #FCA5A5' // Borde rojo si cambió en el lado izquierdo
          : isAnswered
          ? '1px solid #6EE7B7' // Borde blanco si está respondido
          : '1px solid #FCD34D' // Borde amarillo en caso contrario
        : '1px solid #D1D5DB', // Borde por defecto
      color: '#999999'
    }
  }

  return (
    <table
      className={`relative w-full px-4 border table-fixed  ${
        different
          ? side === 'left'
            ? 'border-red-300'
            : isAnswered
            ? 'border-emerald-300'
            : 'border-amber-300'
          : 'border-gray-300'
      }`}
    >
      <thead
        className={`w-full ${
          different
            ? side === 'left'
              ? 'bg-red-100 border-red-300'
              : isAnswered
              ? 'bg-emerald-100 border-emerald-300'
              : 'bg-amber-100 border-amber-300'
            : 'bg-gray-100 border-gray-300'
        }`}
      >
        <tr
          className={`w-full border-b divide-x divide-solid ${
            different
              ? side === 'left'
                ? 'divide-red-300 border-red-300'
                : isAnswered
                ? 'divide-emerald-300 border-emerald-300'
                : 'divide-amber-300 border-amber-300'
              : 'divide-gray-300 border-gray-300'
          }`}
        >
          <th className="w-1/12 overflow-hidden text-sm truncate">Joc</th>
          <th className="w-6/12 overflow-hidden text-sm truncate">Matèria</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Caps</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Cons</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tipus</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Consum</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tol.</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tensió</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonC</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonP</th>
          <th className="w-1/12 overflow-hidden text-sm truncate py-1.5">
            Kg/h
          </th>
        </tr>
      </thead>

      <tbody
        className={`w-full divide-y divide-solid ${
          different
            ? side === 'left'
              ? 'divide-red-300'
              : isAnswered
              ? 'divide-emerald-300'
              : 'divide-amber-300'
            : 'divide-gray-300'
        }`}
      >
        {games?.length > 0 &&
          games
            ?.filter((game) =>
              side === 'left' ? game.HistoryMachineGroup : !game.HasBeenRemoved
            )
            .map((game, index) => (
              <tr
                className={`w-full h-16 divide-x divide-solid ${
                  different
                    ? side === 'left'
                      ? 'divide-red-300'
                      : isAnswered
                      ? 'divide-emerald-300'
                      : 'divide-amber-300'
                    : 'divide-gray-300'
                } ${
                  game?.HasBeenRemoved || game?.HistoryMachineGroup === null
                    ? side === 'left'
                      ? 'bg-red-50'
                      : isAnswered
                      ? 'bg-emerald-50'
                      : 'bg-amber-50'
                    : ''
                }`}
                key={index}
              >
                {/* Campo 'Joc': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.Game,
                        game?.Game,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.Game
                          : game?.Game
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 py-1 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.ThreadMatter,
                        game?.ThreadMatter,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.ThreadMatter
                          : game?.ThreadMatter
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Caps': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.Heads,
                        game?.Heads,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.Heads
                          : game?.Heads
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Cons': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.Cones,
                        game?.Cones,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.Cones
                          : game?.Cones
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Tipus': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.Size,
                        game?.Size,
                        game?.HasBeenRemoved
                      )}
                      value={
                        sizeOptions?.find(
                          (option) =>
                            option.value ===
                            (side === 'left'
                              ? game?.HistoryMachineGroup?.Size
                              : game?.Size)
                        )?.label
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Consum': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.Spending,
                        game?.Spending,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.Spending
                          : game?.Spending
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Tol.': */}
                <td className="w-1/12 text-xs">
                  <div className="flex flex-row items-center">
                    <span>&plusmn;</span>
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.TolSpending,
                        game?.TolSpending,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.TolSpending
                          : game?.TolSpending
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Tensió': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.Pressure,
                        game?.Pressure,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.Pressure
                          : game?.Pressure
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'NonC': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.NoniusC,
                        game?.NoniusC,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.NoniusC
                          : game?.NoniusC
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'NonP': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.NoniusP,
                        game?.NoniusP,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.NoniusP
                          : game?.NoniusP
                      }
                      disabled
                    />
                  </div>
                </td>

                {/* Campo 'Kg/H': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center">
                    <Field
                      type="text"
                      className="w-full table-input-disabled"
                      style={getFieldStyles(
                        game?.HistoryMachineGroup?.KgH,
                        game?.KgH,
                        game?.HasBeenRemoved
                      )}
                      value={
                        side === 'left'
                          ? game?.HistoryMachineGroup?.KgH
                          : game?.KgH
                      }
                      disabled
                    />
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

GameTable.propTypes = {
  games: PropTypes.any,
  side: PropTypes.string,
  isAnswered: PropTypes.bool
}
