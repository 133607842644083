import ListTableOthers from 'components/Maintenance/ListTableOthers'
import TableLoader from 'components/ui/Table/TableLoader'
import { MTContext } from 'contexts/MaintenanceContext'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

const comprobaHours = localStorage.getItem('acumHours')
if (comprobaHours !== 0) localStorage.setItem('acumHours', 0)

const mtList = () => {
  const { setTitle } = useContext(TitleContext)
  const [mtList, mtListAction] = useFetch()
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)
  const { setMT } = useContext(MTContext)
  useEffect(() => {
    // Set title
    const mtTitle = {
      name: 'Partes manteniment - Altres equips',
      buttons: [
        {
          // name: 'Nova FT Teixiduria',
          // link: `/manteniment/nou/`
          name: 'Nou Parte Altres Equips',
          link: `/mantothers/nou/`
        }
      ]
    }
    setTitle(mtTitle)

    const mtListAPIParams = {
      url: `maintenancepart`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    mtListAction(mtListAPIParams)
    handleModal('hide')
  }, [])

  useEffect(() => {
    if (mtList.data) {
      setMT(mtList.data.filter((mt) => mt.CorrectiveDate !== null).length)
    }
  }, [mtList.data])

  const [checked, setChecked] = useState()
  const handleCheck = () => {
    setChecked(!checked)
  }

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/mantothers/${item.Id}`)
  }

  return mtList.data ? (
    <div className="w-full p-4 bg-white">
      <label className="text-lg text-primary">
        <input
          type="checkbox"
          //  checked='true'
          onChange={handleCheck}
          className="w-10"
        ></input>{' '}
        Mostrar pendents
      </label>

      <div className="h-3 text-white">.</div>
      {checked ? (
        <ListTableOthers
          items={mtList.data
            .filter((mt) => mt.Machine === '0')
            .filter((mt) => mt.CorrectiveDate === '')}
          handleClick={handleClick}
        />
      ) : (
        <ListTableOthers
          items={
            mtList.data.filter((mt) => mt.Machine === '0')
            // .filter(mt=>mt.CorrectiveDate!=='')
          }
          handleClick={handleClick}
        />
      )}
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default mtList
