// PropTypes
import PropTypes from 'prop-types'
// import Select from 'react-select'

export default function DescriptionForm({ users, values }) {
  const blockStyle =
    'grid items-center grid-cols-12 lg:auto-rows-min print:p-0 md:gap-y-1 lg:gap-y-3 print:gap-y-1.5 print:auto-rows-auto'
  const innerBlockStyle =
    'grid items-center grid-cols-12 col-span-6 p-3 auto-rows-min grow divide-dashed print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-2'
  const firstLabelStyles =
    'col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi'
  const otherLabelsStyles =
    'col-span-12 mt-5 text-sm print:col-span-3 lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi print:mt-0'
  const firstParentStyles =
    'flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const otherParentStyles =
    'flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 print:mt-0 min-h-8 print:h-full print:col-span-9 lg:col-span-8 xl:col-span-9'
  const innerParentStyles =
    'w-full p-0.5 print:p-0 print:h-full leading-tight print:leading-none text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end'

  return (
    <div className={blockStyle}>
      <div
        className={`${innerBlockStyle} border-r border-dashed border-blue-1`}
      >
        {/* ARTICLE */}
        <div className={firstLabelStyles}>
          Article:{' '}
          {values?.Status === 'A' ? (
            <span className="text-green-600">(A - Aprovat)</span>
          ) : (
            <span className="text-red-600">(P - Prototip)</span>
          )}
        </div>
        <div className={firstParentStyles}>
          <div className={innerParentStyles}>{values?.ArticleDescription}</div>
        </div>

        {/* Ample */}
        <div className={otherLabelsStyles}>Ample:</div>
        <div className={otherParentStyles}>
          <div className={innerParentStyles}>{values?.Width}</div>
        </div>

        {/* SEMIELABORAT */}
        <div className={otherLabelsStyles}>Semielaborat:</div>
        <div className={otherParentStyles}>
          <div className={innerParentStyles}>
            {values?.SemiFinishedDescription}
            <span className="mt-1 ml-2 text-sm italic text-gray-400">
              {values?.SemiFinished}
            </span>
          </div>
        </div>

        {/* COMPOSICIÓ */}
        <div className={otherLabelsStyles}>Composició:</div>
        <div className={otherParentStyles}>
          <div className={innerParentStyles}>{values?.Composition}</div>
        </div>
      </div>

      <div className={innerBlockStyle}>
        {/* CLIENT */}
        <div className={firstLabelStyles}>Client:</div>
        <div className={firstParentStyles}>
          <div className={innerParentStyles}>{values?.Client}</div>
        </div>

        {/* SECADORA */}
        <div className={otherLabelsStyles}>Secadora:</div>
        <div className={otherParentStyles}>
          <div className={innerParentStyles}>
            {values?.IsDryer ? 'Sí' : 'No'}
          </div>
        </div>

        {/* RESP. CLIENT */}
        <div className={otherLabelsStyles}>Resp. client:</div>
        <div className={otherParentStyles}>
          <div className={innerParentStyles}>
            {values?.ClientManager.length > 0
              ? values?.ClientManager.map((managerId, index, array) => {
                  const manager = users.find(
                    (user) => user.Id === Number(managerId)
                  )
                  const isLastItem = index === array.length - 1

                  return isLastItem ? manager?.Name : manager?.Name + ', '
                })
              : ''}
          </div>
        </div>

        {/* EDICIÓ */}
        <div className={otherLabelsStyles}>Edició:</div>
        <div className={otherParentStyles}>
          <div className={innerParentStyles}>{values?.EditionNumber}</div>
        </div>
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  users: PropTypes.array,
  values: PropTypes.object
}
