import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext, useRef, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import {
  MdOutlineAdd,
  MdOutlineUploadFile,
  MdPersonAdd,
  MdRemove
} from 'react-icons/md'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useLocation } from 'wouter'

const TrainingPlanForm = ({
  handleSubmit,
  fields,
  uploadDocument,
  uploadUser,
  updateUser,
  deleteDocument,
  deleteUser,
  addDocumentsToTable,
  addUsersToTable,
  addTreballadorsToSelectList,
  params
}) => {
  const [, setLocation] = useLocation()
  const hiddenFileInput = useRef(null)
  const { handleModal } = useContext(ModalContext)
  const [changedCourseState, setChangedCourseState] = useState(null)
  const [addTreballadorsOpenState, setAddTreballadorsOpenState] =
    useState(false)
  const [getSelectedTreballador, setSelectedTreballador] = useState(null)
  const [getSelectedTreballadorId, setSelectedTreballadorId] = useState(null)

  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const clearSelectTreballadors = () => {
    setSelectedTreballador(null)
  }

  // El campo 'CourseState' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'CourseState':
  const courseStates = [
    { Id: 1, Name: 'Obert' },
    { Id: 2, Name: 'Tancat' }
  ]

  // El campo 'ValuationState' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'ValuationState':
  const valuationState = [
    { Id: 1, Name: 'Correcte' },
    { Id: 2, Name: 'Incorrecte' },
    { Id: 3, Name: 'Pendent' }
  ]

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  return fields.Id !== 0 ? (
    <div className="space-y-3">
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form
            onKeyDown={onKeyDown}
            className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md"
          >
            <div className="flex flex-row items-center justify-between w-full text-sm uppercase bg-blue-200 font-ms-bold">
              <span className="py-2 pl-2">
                {'SEGUIMENT DE FORMACIÓ #' + values.Id}
              </span>
            </div>

            <div className="p-3 space-y-3">
              {/* Dirty: Devuelte 'true' si los 'values' no son exactamente iguales que los 'initialValues', en caso contrario devuelve 'false'. 
              Es una propiedad del 'Formik' de solo 'readonly' y no se debe modificar manualment. */}
              {dirty && (
                <div
                  className="flex items-center p-3 mx-auto text-white bg-red-500 border border-red-600 rounded-sm w-12/12"
                  role="alert"
                >
                  <svg
                    className="inline w-4 h-4 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>

                  <span className="sr-only">Info</span>

                  <div>Heu modificat alguns camps, no oblideu guardar-los.</div>
                </div>
              )}

              <div className="grid w-full grid-cols-12 gap-y-3 gap-x-9">
                {/* FORMADOR ('Entitat') */}
                <div className="w-full col-span-6">
                  <label className="flex text-sm font-ms-semi" htmlFor="Entity">
                    Formador
                  </label>

                  <Field
                    type="text"
                    name="Entity"
                    className="w-full input-disabled"
                    disabled
                  />
                </div>

                {/* CURS */}
                <div className="w-full col-span-6">
                  <label
                    className="flex text-sm font-ms-semi"
                    htmlFor="DetailCourse"
                  >
                    Curs
                  </label>

                  <Field
                    type="text"
                    name="DetailCourse"
                    className="w-full input-disabled"
                    disabled
                  />
                </div>

                {/* HORES */}
                <div className="w-full col-span-6">
                  <label className="flex text-sm font-ms-semi" htmlFor="Hours">
                    Duració
                  </label>

                  <div className="flex flex-row w-full space-x-3">
                    <div className="flex flex-row items-center w-full">
                      <Field
                        type="text"
                        name="Hours"
                        className="w-full input-disabled"
                        disabled
                      />
                      <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                        h
                      </label>
                    </div>

                    <div className="flex flex-row items-center w-full">
                      <Field
                        type="text"
                        name="Minutes"
                        className="w-full input-disabled"
                        disabled
                      />
                      <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                        min
                      </label>
                    </div>
                  </div>
                </div>

                {/* DATA INICI */}
                <div className="w-full col-span-6">
                  <label
                    className="flex text-sm font-ms-semi"
                    htmlFor="StartDate"
                  >
                    Inici
                  </label>

                  <Field
                    type="date"
                    name="StartDate"
                    placeholder={
                      values.CourseState !== 1 ? '' : 'Introdueix...'
                    }
                    className={`w-full leading-snug transition-all duration-200 ease-linear ${
                      values.CourseState !== 1
                        ? 'input-disabled'
                        : 'input-editable-interactive cursor-text'
                    }`}
                    disabled={values.CourseState !== 1}
                  />
                </div>

                {/* PREU */}
                <div className="w-full col-span-6">
                  <label className="flex text-sm font-ms-semi" htmlFor="Price">
                    Preu
                  </label>

                  <div className="flex flex-row items-center justify-center">
                    <Field
                      type="text"
                      name="Price"
                      className="w-full input-disabled"
                      disabled
                    />
                    <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                      €
                    </label>
                  </div>
                </div>

                {/* DATA FINAL */}
                <div className="w-full col-span-6">
                  <label
                    className="flex text-sm font-ms-semi"
                    htmlFor="EndDate"
                  >
                    Final
                  </label>

                  <Field
                    type="date"
                    name="EndDate"
                    placeholder={
                      values.CourseState !== 1 ? '' : 'Introdueix...'
                    }
                    className={`w-full leading-snug transition-all duration-200 ease-linear ${
                      values.CourseState !== 1
                        ? 'input-disabled'
                        : 'input-editable-interactive cursor-text'
                    }`}
                    onChange={(e) => {
                      handleChange(e)

                      !e.target.value && (values.CourseState = 1)
                      !e.target.value && (values.VerificationDate = false)
                    }}
                    disabled={values.CourseState !== 1}
                  />
                </div>

                {/* CUADRO DE VERIFICACIÓN | ¡NO SIEMPRE ESTARÁ VISIBILE, LÉAN LA DESCRIPCIÓN! */}
                {/*
                  Este código muestra el cuadro de verificación de la formación si:
                  1. Existe una fecha de finalización.
                  2. La fecha de finalización es anterior a la fecha actual.

                  Esto garantiza que solo se pueda verificar una formación que ha finalizado. 
                */}
                <div
                  className={`col-span-12 py-1 ${
                    !values.EndDate || values.EndDate > datetime ? 'hidden' : ''
                  }`}
                >
                  <div
                    className={`w-10/12 mx-auto h-full rounded-sm grid items-start relative border transition-all duration-200 ease-linear ${
                      values.CourseState === 2
                        ? 'bg-red-200 border-red-600'
                        : 'bg-green-200 border-green-600'
                    }`}
                  >
                    <div
                      className={`w-full h-full p-3 grid grid-cols-2 divide-x divide-dashed transition-all duration-200 ease-linear ${
                        values.CourseState === 2
                          ? 'divide-red-600'
                          : 'divide-green-600'
                      }`}
                    >
                      {/* VALORACIÓ */}
                      <div className="items-center block w-full py-2 pl-2 pr-6">
                        <label
                          className="flex text-sm font-ms-semi"
                          htmlFor="CourseState"
                        >
                          Estadi
                        </label>

                        <Select
                          name="CourseState"
                          placeholder="Selecciona..."
                          className="w-full leading-tight"
                          onChange={(e) => {
                            setFieldValue('CourseState', e.Id)

                            setChangedCourseState(e.Id)

                            // Si la opción que se elige es 'Correcte' o 'Incorrecte' pondremos la fecha de la verificación,
                            // en cambio, si se elige 'Pendent' no guardaremos ninguna fecha:
                            // e.Id === 1 || e.Id === 2 ? setFieldValue('CourseModifiedStateDate', datetime) : setFieldValue('CourseModifiedStateDate', '')
                            setFieldValue('CourseModifiedStateDate', datetime)
                          }}
                          options={courseStates}
                          getOptionLabel={(o) => o.Name}
                          getOptionValue={(o) => o.Id}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: '0.125rem',
                              cursor:
                                values.CourseState === 2
                                  ? 'cursor-not-allowed'
                                  : 'pointer',
                              boxShadow: 'none',
                              minHeight: 'auto',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: state.isFocused
                                ? values.CourseState === 2
                                  ? '#991B1C'
                                  : '#1AB981'
                                : values.CourseState === 2
                                ? '#DC2627'
                                : '#139669',
                              '&:hover': {
                                borderColor:
                                  values.CourseState === 2
                                    ? '#991B1C'
                                    : '#1AB981'
                              },
                              backgroundColor: '#ffffff'
                            }),
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              height: '100%',
                              padding: '0.25rem 0.5rem',
                              position: 'static',
                              cursor: 'pointer',
                              rowGap: '0.25rem'
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                              margin: '0',
                              paddingTop: '0',
                              paddingBottom: '0',
                              cursor: 'pointer'
                            }),
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              marginLeft: '0',
                              marginRight: '0'
                            }),
                            indicatorsContainer: (baseStyles) => ({
                              ...baseStyles,
                              cursor: 'pointer'
                            }),
                            dropdownIndicator: (baseStyles) => ({
                              ...baseStyles,
                              padding: '0px 8px'
                            })
                          }}
                          value={courseStates.find(
                            (option) => option.Id === values.CourseState
                          )}
                        />
                      </div>

                      {/* DATA VERIFICACIÓ */}
                      <div className="items-center block w-full py-2 pl-6 pr-2">
                        <label
                          className="flex text-sm font-ms-semi"
                          htmlFor="CourseModifiedStateDate"
                        >
                          Última modificació
                        </label>

                        <Field
                          type="date"
                          name="CourseModifiedStateDate"
                          className={`w-full leading-snug px-2 py-0.5 border border-gray-300 rounded-sm appearance-none ${
                            values.CourseState === 2
                              ? 'border-red-600'
                              : 'border-green-600'
                          }`}
                          style={{
                            background: '#F2F2F2',
                            color: '#999999'
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* OBSERVACIONS */}
                <div className="w-full col-span-12">
                  <label
                    className="flex text-sm font-ms-semi"
                    htmlFor="Description"
                  >
                    Observacions
                  </label>

                  <Field
                    as="textarea"
                    name="Description"
                    placeholder={
                      values.CourseState !== 1 ? '' : 'Introdueix...'
                    }
                    className={`h-40 w-full align-top transition-all duration-200 ease-linear ${
                      values.CourseState !== 1
                        ? 'input-disabled'
                        : 'input-editable-interactive'
                    }`}
                    disabled={values.CourseState !== 1}
                  />
                </div>
              </div>

              {/* GUARDAR / ACTIVAR */}
              <div className="flex flex-row justify-center col-span-1">
                <div>
                  <button
                    type="submit"
                    className={`transition duration-300 text-white font-ms-semi py-1 px-4 ml-auto border rounded-sm h-10 w-28 border-primary bg-primary hover:bg-blue-200 hover:text-primary ${
                      values.MonitoringActivated ? '' : 'mr-2'
                    }`}
                  >
                    GUARDAR
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className={`transition duration-300 text-white font-ms-semi py-1 px-4 ml-auto border rounded-sm h-10 border-green-600 bg-green-600 hover:bg-blue-200 hover:text-green-600 ${
                      values.MonitoringActivated ? 'hidden' : ''
                    }`}
                    onClick={(e) => {
                      setLocation('/pla-formacio/' + values.ForecastTraining_Id)
                    }}
                  >
                    PLA FORMACIÓ
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        INICIO TABLA TRABAJADORES APUNTADOS:
      -------------------------------------------------------------------------------------------------------------------------------------- */}
      <table className="w-full mt-4 mr-10 bg-white border-0 border-collapse rounded-lg shadow-md print:hidden border-spacing-0">
        <thead className="rounded-t-lg">
          <tr className="rounded-t-lg">
            <th
              className={`print:hidden h-14 w-100vw relative text-left rounded-t-lg
              ${
                addTreballadorsOpenState
                  ? 'bg-primary-dark duration-1000 ease-out'
                  : 'bg-primary duration-1000 ease-out'
              }`}
              colSpan={3}
            >
              <div
                className={`flex flex-row jusitfy-center items-center rounded-t-lg ${
                  addTreballadorsOpenState
                    ? 'divide-x divide-dashed divide-white'
                    : ''
                }`}
              >
                <div className="px-5 py-3 rounded-tl-lg bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={(e) => {
                      setAddTreballadorsOpenState(!addTreballadorsOpenState)
                    }}
                  >
                    <MdOutlineAdd
                      className={`${addTreballadorsOpenState ? 'hidden' : ''}`}
                      size={25}
                    />
                    <MdRemove
                      className={`${addTreballadorsOpenState ? '' : 'hidden'}`}
                      size={25}
                    />
                  </button>
                </div>

                <div
                  className={`bg-transparent h-full w-auto flex items-center ml-4 font-ms-bold text-sm text-white ${
                    addTreballadorsOpenState ? 'hidden' : ''
                  }`}
                >
                  <a className="text-lg">LLISTA DE TREBALLADORS APUNTATS</a>
                </div>

                <div
                  className={`bg-transparent h-full w-full px-20 grid grid-cols-12 font-normal ${
                    addTreballadorsOpenState ? 'pl-16' : 'hidden'
                  }`}
                >
                  <div className="flex items-center col-span-11">
                    <Select
                      className="w-full font-normal leading-tight rounded"
                      onChange={(e) => {
                        setSelectedTreballador(e)
                        setSelectedTreballadorId(e.Id)
                      }}
                      placeholder="Selecciona..."
                      options={addTreballadorsToSelectList}
                      getOptionLabel={(o) => o.Name}
                      getOptionValue={(o) => o.Id}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: '0.125rem',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          minHeight: 'auto',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: state.isFocused ? '#9CA3AF' : '#D1D5DB',
                          '&:hover': {
                            borderColor: '#9CA3AF'
                          }
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          padding: '0.25rem 0.5rem',
                          position: 'static',
                          cursor: 'pointer',
                          rowGap: '0.25rem'
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                          margin: '0',
                          paddingTop: '0',
                          paddingBottom: '0',
                          cursor: 'pointer'
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          marginLeft: '0',
                          marginRight: '0'
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          cursor: 'pointer'
                        }),
                        dropdownIndicator: (baseStyles) => ({
                          ...baseStyles,
                          padding: '0px 8px'
                        })
                      }}
                      value={getSelectedTreballador}
                    />
                  </div>

                  <div className="flex items-center justify-end w-auto col-span-1">
                    <button
                      className={`p-1.5 mt-auto mb-auto font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                      onClick={() => {
                        const message = () =>
                          toast.warn('Selecciona un treballador!', {
                            position: toast.POSITION.TOP_CENTER
                          })

                        if (getSelectedTreballadorId) {
                          uploadUser(getSelectedTreballadorId, fields.Id)
                          clearSelectTreballadors()
                          setSelectedTreballadorId(null)
                        } else {
                          message()
                        }
                      }}
                    >
                      <MdPersonAdd size={25} />
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del seguimiento, en el campo 'TrainingPlanUsers' nos llegan
          todos los trabajadores que tiene adjuntos: */}
          {addUsersToTable?.length > 0 ? (
            addUsersToTable.map((doc) => (
              <tr
                key={doc.Id}
                className="grid grid-cols-12 border-primary-dark"
              >
                <td className="flex flex-row items-center justify-start col-span-2 p-3 pl-4 m-2">
                  {doc.Treballador.Name}
                </td>

                <td
                  className={`bg-gray-200 border-gray-400 border col-span-9 rounded-sm
                  transition-all duration-200 ease-linear 
                  ${
                    changedCourseState
                      ? changedCourseState === 1
                        ? 'hidden'
                        : 'm-4 p-2'
                      : fields.CourseState === 1
                      ? 'hidden'
                      : 'm-4 p-2'
                  }`}
                >
                  <Formik
                    enableReinitialize={true}
                    initialValues={{ ...doc, Comment: doc?.Comment || '' }}
                    onSubmit={(values) => {
                      updateUser(values)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      submitForm
                    }) => (
                      <Form
                        onKeyDown={onKeyDown}
                        className="grid items-center w-full h-auto grid-cols-12"
                      >
                        {/* Campo 'Valoració': */}
                        <div className="items-center block w-full h-full col-span-2 px-2 py-2 print:bg-white">
                          <label
                            className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                            htmlFor="Dependence"
                          >
                            Valoració
                          </label>

                          <Select
                            name="ValuationState"
                            placeholder="Selecciona..."
                            className="leading-tight rounded text-grey-darker"
                            onChange={(e) =>
                              setFieldValue('ValuationState', e.Id)
                            }
                            options={valuationState}
                            getOptionLabel={(o) => o.Name}
                            getOptionValue={(o) => o.Id}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: '0.125rem',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                minHeight: 'auto',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: state.isFocused
                                  ? '#9CA3AF'
                                  : '#D1D5DB',
                                '&:hover': {
                                  borderColor: '#9CA3AF'
                                }
                              }),
                              valueContainer: (baseStyles) => ({
                                ...baseStyles,
                                height: '100%',
                                padding: '0.25rem 0.5rem',
                                position: 'static',
                                cursor: 'pointer',
                                rowGap: '0.25rem'
                              }),
                              input: (baseStyles) => ({
                                ...baseStyles,
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                margin: '0',
                                paddingTop: '0',
                                paddingBottom: '0',
                                cursor: 'pointer'
                              }),
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                marginLeft: '0',
                                marginRight: '0'
                              }),
                              indicatorsContainer: (baseStyles) => ({
                                ...baseStyles,
                                cursor: 'pointer'
                              }),
                              dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                padding: '0px 8px'
                              })
                            }}
                            value={valuationState.find(
                              (option) => option.Id === values.ValuationState
                            )}
                          />
                        </div>

                        {/* Campo 'Comentari': */}
                        <div className="items-center block w-full h-full col-span-9 px-2 py-2 transition-all duration-200 ease-linear print:bg-white">
                          <label
                            className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                            htmlFor="Dependence"
                          >
                            Comentari
                          </label>

                          <Field
                            type="text"
                            name="Comment"
                            placeholder="Introdueix..."
                            className="w-full input-editable-interactive"
                            autoComplete="off"
                          />
                        </div>

                        {/* Botón 'Guardar': */}
                        <div className="flex items-center justify-end w-full h-full col-span-1 px-2 py-2 print:bg-white">
                          <button
                            className="w-10 h-10 text-white transition duration-300 border rounded-sm cursor-pointer border-primary bg-primary hover:bg-blue-200 hover:text-primary print:hidden"
                            type="submit"
                          >
                            <FaPencilAlt className="m-auto" size={17} />
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </td>
                <td className="flex flex-row items-center justify-end col-span-1 col-start-12">
                  <button
                    className={`p-3 m-2 mr-2 ml-auto w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteUser}
                          element="fitxer"
                          id={doc.Id}
                          trainingPlanId={fields.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat treballadors apuntats per a aquesta
                  formació.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        FIN TABLA TRABAJADORES APUNTADOS.
      -------------------------------------------------------------------------------------------------------------------------------------- */}

      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        INICIO TABLA FICHEROS ADJUNTOS: 
      -------------------------------------------------------------------------------------------------------------------------------------- */}
      <table
        className={`print:hidden mt-4 shadow-md bg-white w-full mr-10 border-spacing-0 border-collapse rounded-lg border-0 overflow-hidden`}
      >
        <thead>
          <tr>
            <th
              className="relative text-sm text-left text-white print:hidden font-ms-bold h-14 w-100vw bg-primary"
              colSpan={2}
            >
              <div className="flex flex-row items-center jusitfy-center">
                <div className="px-5 py-3 bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={handleClick}
                  >
                    <MdOutlineUploadFile size={25} />
                  </button>
                </div>
                <div className="flex items-center w-auto h-full ml-4">
                  <a className="text-lg">
                    FITXERS ADJUNTS (CV, Firmes, Confidencialitat, Altres....)
                  </a>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del trabajador, en el campo 'TreballadorDocuments' nos llegan
          todos los documentos que tiene adjuntos: */}
          {addDocumentsToTable?.length > 0 ? (
            addDocumentsToTable.map((doc) => (
              <tr key={doc.Id} className="border-primary-dark">
                <td>
                  <button className="block w-auto p-3 m-2 text-base rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
                    <a
                      className="text-md"
                      href={`https://apipreprod.cetriko.com/uploads/trainingPlan/${
                        params.id
                      }/documents/${encodeURIComponent(doc.Name)}`}
                      target="_blank"
                      rel="noreferrer"
                      download={
                        'Seguiment_Formacio_' +
                        params.id +
                        '_Document_' +
                        doc.Name
                      }
                    >
                      {doc.Name}
                    </a>
                  </button>
                </td>
                <td>
                  <button
                    className={`p-3 m-2 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteDocument}
                          element="fitxer"
                          id={doc.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat fitxers adjunts.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <input
        ref={hiddenFileInput}
        accept=".pdf, .xlsx"
        type="file"
        name="file"
        multiple="multiple"
        className="h-1/6 hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
        onChange={(e) => uploadDocument(e)}
      />
      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        FIN TABLA FICHEROS ADJUNTOS. 
      -------------------------------------------------------------------------------------------------------------------------------------- */}
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

TrainingPlanForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  uploadDocument: PropTypes.func,
  uploadUser: PropTypes.func,
  updateUser: PropTypes.func,
  deleteDocument: PropTypes.func,
  deleteUser: PropTypes.func,
  addDocumentsToTable: PropTypes.array,
  addUsersToTable: PropTypes.array,
  addTreballadorsToSelectList: PropTypes.array,
  params: PropTypes.object
}

export default TrainingPlanForm
