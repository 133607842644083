import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Routing
import { Redirect, Route, Switch } from 'wouter'

// Context
import { MTContext } from 'contexts/MaintenanceContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext } from 'react'

// Layouts
import Layout from 'components/layout/Layout'

// Pages
import Login from 'pages/Login/Login'
import Logout from 'pages/Login/Logout'

import Dashboard from 'pages/Dashboard/Dashboard'
import Profile from 'pages/Profile/Profile'

// FT Acabats
import FTAcabatsDetail from 'pages/FTAcabats/FTAcabatsDetail'
import FTAcabatsList from 'pages/FTAcabats/FTAcabatsList'
import FTAcabatsRequest from 'pages/FTAcabats/FTAcabatsRequest'
import FTAcabatsRequestList from 'pages/FTAcabats/FTAcabatsRequestList'

// Operaris

// RRHH
// 1. Llocs Treball (Job)
import JobDetails from './RRHH/Jobs/JobDetails'
import JobList from './RRHH/Jobs/JobList'

// 2. Mapa Empresa
import MapaEmpresesList from './RRHH/MapaEmpreses/MapaEmpresesList'

// 3. Fitxes Treballadors
import TreballadorsDetails from './RRHH/Treballadors/TreballadorsDetails'
import TreballadorsList from './RRHH/Treballadors/TreballadorsList'

// 4. Suggeriments (Suggestion)
import SuggestionDetails from './RRHH/Suggestions/SuggestionDetails'
import SuggestionList from './RRHH/Suggestions/SuggestionList'

// 5. Previsió Formació
import ForecastTrainingDetails from './RRHH/ForecastTrainings/ForecastTrainingDetails'
import ForecastTrainingList from './RRHH/ForecastTrainings/ForecastTrainingList'

// 6. Pla Formació
import TrainingPlanDetails from './RRHH/TrainingPlans/TrainingPlanDetails'
import TrainingPlanList from './RRHH/TrainingPlans/TrainingPlanList'

// 7. CheckList Formació
// import CheckListTrainingPlanList from './RRHH/CheckListTrainingPlan/CheckListTrainingPlanList'
// import CheckListTrainingPlanDetails from './RRHH/CheckListTrainingPlan/CheckListTrainingPlanDetails'

// 8. Enquesta de satisfaccio
// import SatisfactionSurveyList from './RRHH/SatisfactionSurveys/SatisfactionSurveyList'
// import SatisfactionSurveyDetails from './RRHH/SatisfactionSurveys/SatisfactionSurveyDetails'

// 9. Polivalencies
import VersatilityDetails from './RRHH/Versatility/VersatilityDetails'
import VersatilityList from './RRHH/Versatility/VersatilityList'

// RRHH

// CONTROL
// 1. Control Vision
import ControlVisionDetails from './Control/ControlVision/ControlVisionDetails'
import ControlVisionList from './Control/ControlVision/ControlVisionList'

// 2.- CheckList RAM
import CheckListDetails from './Control/CheckList/CheckListDetails'
import CheckListList from './Control/CheckList/CheckListList'

// 3.- CheckList MAQUINA
import CheckListMaqDetails from './Control/CheckListMaq/CheckListMaqDetails'
import CheckListMaqList from './Control/CheckListMaq/CheckListMaqList'
// CONTROL

// Clients
import ClientsDetail from './Clients/ClientsDetail'
import ClientsList from './Clients/ClientsList'
import ClientsRequest from './Clients/ClientsRequest'
import ClientsRequestList from './Clients/ClientsRequestList'

// Users
import UsersList from 'pages/Users/UsersList'
import UserDetail from './Users/UserDetail'

// Semielaborats

// FT Teixiduria
import FTTeixiduriaDetail from './FTTeixiduria/FTTeixiduriaDetail'
import FTTeixiduriaProtoDetail from './FTTeixiduria/ProtoDetail'
import FTTeixiduriaList from './FTTeixiduria/FTTeixiduriaList'
import FTTeixiduriaRequest from 'pages/FTTeixiduria/FTTeixiduriaRequest'
import FTTeixiduriaRequestList from 'pages/FTTeixiduria/FTTeixiduriaRequestList'
import Inventory from './Inventory/Inventory'
import InventoryDetails from './Inventory/InventoryDetails'
import MaintOthersDetails from './Maintenance/MaintOthersDetails'
import MaintOthersList from './Maintenance/MaintOthersList'
import MaintenanceDetails from './Maintenance/MaintenanceDetails'
import MaintenanceList from './Maintenance/MaintenanceList'
import NCDetails from './NC/NCDetails'
import NCList from './NC/NCList'

import PrevenOthersDetails from './PreventiveWeaving/PrevenOthersDetails'
import PrevenOthersList from './PreventiveWeaving/PrevenOthersList'
import PrevenTeixDetails from './PreventiveWeaving/PrevenTeixDetails'
import PrevenTeixList from './PreventiveWeaving/PrevenTeixList'

import FTTeixiduriaLabToPDF from '../forms/ftteixiduria/FTTeixiduriaLabToPDF'
import FTTeixiduriaPosToPDF from '../forms/ftteixiduria/FTTeixiduriaPosToPDF'

// Private Routes (only authenticated!)
const PrivateRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? (
    <Layout>
      <Route {...props} />
    </Layout>
  ) : (
    <Redirect to="/login" />
  )

// Login Route (only if not authenticated!)
const LoginRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? <Redirect to="/" /> : <Route path="/login" component={Login} />

export default function App() {
  const { setMT } = useContext(MTContext)
  const [mtList, mtListAction] = useFetch()
  const { user } = useContext(UserContext)
  // const isLoggedIn = user && !!user.Token && 'Permissions' in user
  const isLoggedIn = user && !!user?.Token

  // useEffect(() => {
  //   const mtListAPIParams = {
  //   url: `maintenancepart`,
  //   method: 'GET',
  //   messageKo: 'Error al recuperar dades del ECAP'
  // }
  // mtListAction(mtListAPIParams)
  // }, []);

  // useEffect(() => {
  //   if (mtList.data) {
  //     setMT(mtList.data.filter((mt) => mt.CorrectiveDate === null).length)
  //   }
  // }, [mtList.data])

  return (
    <>
      <Switch>
        <LoginRoute isLoggedIn={isLoggedIn} path="/login" component={Login} />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/logout"
          component={Logout}
        />
        <PrivateRoute isLoggedIn={isLoggedIn} path="/" component={Dashboard} />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/perfil"
          component={Profile}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/clients"
          component={ClientsList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/client/:id"
          component={ClientsDetail}
        />

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        {/* Inicio lista de rutas que permiten acceder al componente 'ClientsRequest': */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/client/request-check/:id/:idRequest"
        >
          {(params) => <ClientsRequest type="Check" params={params} />}
        </PrivateRoute>

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/client/request-:type/:id/:idRequest?"
        >
          {(params) => <ClientsRequest params={params} />}
        </PrivateRoute>
        {/* Fin lista de rutas que permiten acceder al componente 'ClientsRequest'. */}

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        {/* Inicio lista de rutas que permiten acceder al componente 'ClientsRequestList': */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/client/requests/:id"
          component={ClientsRequestList}
        />
        {/* Fin lista de rutas que permiten acceder al componente 'ClientsRequestList'. */}

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/usuaris"
          component={UsersList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/usuari/:id"
          component={UserDetail}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-acabats"
          component={FTAcabatsList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-teixiduria"
          component={FTTeixiduriaList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-proto-teixiduria"
          component={FTTeixiduriaProtoDetail}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-teixiduria/:id"
          component={FTTeixiduriaDetail}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-acabats/:id"
          component={FTAcabatsDetail}
        />

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        {/* Inicio lista de rutas que permiten acceder al componente 'FTAcabatsRequest': */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-acabats/request-check/:articleCode/:idRequest"
        >
          {(params) => <FTAcabatsRequest type="Check" params={params} />}
        </PrivateRoute>

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-acabats/request-:type/:articleCode/:idRequest?"
        >
          {(params) => <FTAcabatsRequest params={params} />}
        </PrivateRoute>
        {/* Fin lista de rutas que permiten acceder al componente 'FTAcabatsRequest'. */}

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        {/* Inicio lista de rutas que permiten acceder al componente 'FTAcabatsRequestList': */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-acabats/requests/:id"
          component={FTAcabatsRequestList}
        />
        {/* Fin lista de rutas que permiten acceder al componente 'FTAcabatsRequestList'. */}

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        {/* Inicio lista de rutas que permiten acceder al componente 'FTTeixiduriaRequest': */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-teixiduria/request-check/:articleId/:idRequest"
        >
          {(params) => <FTTeixiduriaRequest type="Check" params={params} />}
        </PrivateRoute>

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-teixiduria/request-:type/:id/:idRequest?"
        >
          {(params) => <FTTeixiduriaRequest params={params} />}
        </PrivateRoute>
        {/* Fin lista de rutas que permiten acceder al componente 'FTTeixiduriaRequest'. */}

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        {/* Inicio lista de rutas que permiten acceder al componente 'FTTeixiduriaRequestList': */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/ft-teixiduria/requests/:id"
          component={FTTeixiduriaRequestList}
        />
        {/* Fin lista de rutas que permiten acceder al componente 'FTTeixiduriaRequestList'. */}

        {/* ------------------------------------------------------------------------------------------------------------------ */}

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/no-conformitats"
          component={NCList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/no-conformitats/:id"
          component={NCDetails}
        />
        {/* End of routes list to access FTAcabatsRequest component. */}

        {/* INI desdoblament pantalles Manteniment */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mantteix"
          component={MaintenanceList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mantteix/:id"
          component={MaintenanceDetails}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mantothers"
          component={MaintOthersList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mantothers/:id"
          component={MaintOthersDetails}
        />
        {/* FI desdoblament pantalles Manteniment */}

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/inventari"
          component={Inventory}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/inventari/:id"
          component={InventoryDetails}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/preventeix"
          component={PrevenTeixList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/preventeix/:id"
          component={PrevenTeixDetails}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/prevenacabats"
          component={PrevenOthersList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/prevenacabats/:id"
          component={PrevenOthersDetails}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/laboratori"
          component={FTTeixiduriaLabToPDF}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/posadamarxa"
          component={FTTeixiduriaPosToPDF}
        />

        {/* RRHH */}
        {/* Llocs de treball */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/lloc-treball"
          component={JobList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/lloc-treball/:id"
          component={JobDetails}
        />
        {/* Llocs de treball */}

        {/* Suggeriment */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/sugerencia"
          component={SuggestionList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/sugerencia/:id"
          component={SuggestionDetails}
        />
        {/* Suggeriment */}

        {/* Pla Formació */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/pla-formacio"
          component={ForecastTrainingList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/pla-formacio/:id"
          component={ForecastTrainingDetails}
        />
        {/* Pla Formació */}

        {/* Seguiment Formació */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/seguiment-formacio"
          component={TrainingPlanList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/seguiment-formacio/:id"
          component={TrainingPlanDetails}
        />
        {/* Seguiment Formació */}

        {/* CheckList Formació */}
        {/* <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/checklist-formacio"
          component={CheckListTrainingPlanList}
        /> */}
        {/* <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/checklist-formacio/:id"
          component={CheckListTrainingPlanDetails}
        /> */}
        {/* CheckList Formació */}

        {/* Treballadors */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/treballadors"
          component={TreballadorsList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/treballadors/:id"
          component={TreballadorsDetails}
        />
        {/* Treballadors */}

        {/* Polivalencies */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/polivalencies"
          component={VersatilityList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/polivalencies/:id"
          component={VersatilityDetails}
        />
        {/* Polivalencies */}

        {/* Mapa Empresas */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mapa-empreses"
          component={MapaEmpresesList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mapa-empreses/:id"
          component={TreballadorsDetails}
        />
        {/* Mapa Empresas */}

        {/* SatisfactionSurveys */}
        {/* <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/enquesta-satisfaccio"
          component={SatisfactionSurveyList}
        />
         <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/enquesta-satisfaccio/:id"
          component={SatisfactionSurveyDetails}
        /> */}
        {/* SatisfactionSurveys */}

        {/* RRHH */}

        {/* CONTROL */}
        {/* ControlVision */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/visioncontrol"
          component={ControlVisionList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/visioncontrol/:id"
          component={ControlVisionDetails}
        />
        {/* ControlVision */}

        {/* CheckList (RAM) */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/checklist"
          component={CheckListList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/checklist/:id"
          component={CheckListDetails}
        />
        {/* CheckList (RAM) */}

        {/* CheckList (MAQ) */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/checklistmaq"
          component={CheckListMaqList}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/checklistmaq/:id"
          component={CheckListMaqDetails}
        />
        {/* CheckList (MAQ) */}
        {/* CONTROL */}
      </Switch>
      <ToastContainer />
    </>
  )
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}

LoginRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}
