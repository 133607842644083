import PropTypes from 'prop-types'
import Form1 from 'forms/ftacabatsrequest/descriptionView'
import Form2 from 'forms/ftacabatsrequest/featuresView'
import Form3 from 'forms/ftacabatsrequest/openMachineView'
import Form4 from 'forms/ftacabatsrequest/ultrasonicSettingsView'
import Form5 from 'forms/ftacabatsrequest/foulardsView'
import Form6 from 'forms/ftacabatsrequest/edgesView'
import Form7 from 'forms/ftacabatsrequest/controlsView'
import Form8 from 'forms/ftacabatsrequest/packagingView'

export default function Block({ title, className, users, values }) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md border-gray-300`}
    >
      <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-gray-300 print:py-1 font-ms-bold">
        <span>{title}</span>
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return <Form1 users={users} values={values} />
          case 'Característiques':
            return <Form2 values={values} />
          case "Màquina d'obrir":
            return <Form3 values={values} />
          case 'Reglatges ultrasons':
            return <Form4 values={values} />
          case 'Acabats foulard':
            return <Form5 values={values} />
          case 'Vores':
            return <Form6 values={values} />
          case 'Controls':
            return <Form7 values={values} />
          case 'Embalatge':
            return <Form8 values={values} />
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  users: PropTypes.array,
  values: PropTypes.object
}
