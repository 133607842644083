import {BsExclamationTriangle} from 'react-icons/bs'
import PropTypes from 'prop-types'
export default function DeleteModal({ deleteUserOrFT, id, trainingPlanId, element, closeModal}) {
  return (
    <div>
      <BsExclamationTriangle className='text-primary bg-red-100 absolute rounded-full p-2' size={40} />

      <div className="flex h-48 pl-12 justify-between flex-col">
        <h3 className=' font-lato-bold  mt-1 text-lg'>Eliminar {element} </h3>
        <p className=" font-la-light font-bold text-base text-gray-500 ">
          Estàs segur que el vols eliminar? Si l&apos;elimines no podràs tornar-lo a recuperar. Aquesta acció no es pot desfer.
        </p>

        <div className='flex justify-end'>
          <button
            onClick={closeModal}
            className="h-10 w-1/6  mx-4 font-medium transition-colors duration-150 bg-white text-black border border-gray-300 rounded-lg focus:shadow-outline hover:bg-gray-300 "
          >
            Cancelar
          </button>

          <button
            onClick={() => {trainingPlanId !== null && trainingPlanId !== undefined ? deleteUserOrFT(id, trainingPlanId) : deleteUserOrFT(id)}}
            className="h-10 w-1/6   text-white font-medium transition-colors duration-150 bg-primary border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  )
}

DeleteModal.propTypes = {
  deleteUserOrFT: PropTypes.func,
  id: PropTypes.any,
  trainingPlanId: PropTypes.any,
  element: PropTypes.any,
  closeModal: PropTypes.any
}
