// ICONS
import ICON_SHEET from 'assets/img/check-list.png'
import ICON_FINISHED from 'assets/img/fabric.png'
import ICON_HOME from 'assets/img/home.png'
import ICON_MAINTENANCE from 'assets/img/maintenance.png'
import ICON_PREVENTIVE from 'assets/img/medical-insurance.png'
import ICON_NC from 'assets/img/nc.png'
import ICON_CUSTOMER from 'assets/img/people.png'
import ICON_RRHH from 'assets/img/rrhh.png'
import ICON_SUGGESTION from 'assets/img/suggestion.svg'
import ICON_WEAVING from 'assets/img/weaving.png'

export const MenuOperari = [
  {
    title: 'Panell',
    slug: '/',
    active: true,
    icon: ICON_HOME,
    requiredPermissions: null
  },
  {
    title: 'Acabats',
    slug: '',
    active: false,
    icon: ICON_FINISHED,
    requiredPermissions: null,
    subMenu: [
      {
        title: 'Fitxa Tècnica',
        slug: '/ft-acabats',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      },
      {
        title: 'Preventiu',
        slug: '/prevenacabats',
        active: true,
        icon: ICON_PREVENTIVE,
        requiredPermissions: null
      },
      {
        title: 'Manteniment',
        slug: '/mantothers',
        active: true,
        icon: ICON_MAINTENANCE,
        requiredPermissions: null
      }
    ]
  },
  {
    title: 'Teixiduria',
    slug: '',
    active: false,
    icon: ICON_WEAVING,
    requiredPermissions: null,
    subMenu: [
      {
        title: 'Fitxa Tècnica',
        slug: '/ft-teixiduria',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      },
      {
        title: 'Proto',
        slug: '/ft-proto-teixiduria',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      },
      {
        title: 'Manteniment',
        slug: '/mantteix',
        active: true,
        icon: ICON_MAINTENANCE,
        requiredPermissions: null
      }
    ]
  },
  {
    title: 'NC',
    slug: '',
    active: false,
    icon: ICON_NC,
    requiredPermissions: null,
    subMenu: [
      {
        title: 'Fitxa',
        slug: '/no-conformitats',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      }
    ]
  },
  {
    title: 'RRHH',
    slug: '',
    active: false,
    icon: ICON_RRHH,
    requiredPermissions: null,
    subMenu: [
      {
        title: 'Suggeriment',
        slug: '/sugerencia',
        active: true,
        icon: ICON_SUGGESTION,
        requiredPermissions: null
      }
    ]
  },
  {
    title: 'Control',
    slug: '',
    active: false,
    icon: ICON_SHEET,
    requiredPermissions: null,
    subMenu: [
      {
        title: 'Control Visió',
        slug: '/visioncontrol',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      },
      {
        title: 'Checklist (RAM)',
        slug: '/checklist',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      },
      {
        title: 'Checklist (MAQ)',
        slug: '/checklistmaq',
        active: true,
        icon: ICON_SHEET,
        requiredPermissions: null
      }
    ]
  },
  {
    title: 'Clients',
    slug: '/clients',
    active: true,
    icon: ICON_CUSTOMER,
    requiredPermissions: null
  }
]
