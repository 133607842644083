import PropTypes from 'prop-types'
import DescriptionForm from 'forms/ftacabats/description'
import FeaturesForm from 'forms/ftacabats/features'
import OpenMachineForm from 'forms/ftacabats/openMachine'
import FoulardsFinishForm from 'forms/ftacabats/foulardsFinish'
import EdgesForm from 'forms/ftacabats/edges'
import ControlsForm from 'forms/ftacabats/controls'
import PackagingForm from 'forms/ftacabats/packaging'
import UltrasonicSettings from 'forms/ftacabats/ultrasonicSettings'
import RamForm from 'forms/ftacabats/ram'
import LastAcceptedRequest from 'forms/ftacabats/lastAcceptedRequest'

export default function Block({ title, className, users, values }) {
  return (
    <div
      className={`${className && className} 
      bg-white rounded border-2 print:border border-blue-200 border-collapse shadow-md print:shadow-none`}
      style={{
        pageBreakInside: 'avoid'
      }}
    >
      <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 print:py-1 print:px-1 font-ms-bold">
        {title}
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return <DescriptionForm users={users} values={values} />
          case 'Característiques':
            return <FeaturesForm values={values} />
          case "Màquina d'obrir":
            return <OpenMachineForm values={values} />
          case 'Reglatges ultrasons':
            return <UltrasonicSettings values={values} />
          case 'Acabats foulard':
            return <FoulardsFinishForm values={values} />
          case 'Vores':
            return <EdgesForm values={values} />
          case 'Controls':
            return <ControlsForm values={values} />
          case 'Embalatge':
            return <PackagingForm values={values} />
          case 'Reglatges RAM':
            return <RamForm values={values} />
          case 'Última modificació':
            return <LastAcceptedRequest values={values} />
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  users: PropTypes.array,
  values: PropTypes.object
}
