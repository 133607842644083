import ListTable from 'components/RRHH/ForecastTrainings/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const forecastTrainingList = () => {
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)
  const [receivedApiParams, setReceivedApiParams] = useFetch()

  useEffect(() => {
    // Esteblecemos la información que se mostrará en la barra del título:
    const pageTitle = {
      name: 'Plans de Formació',
      buttons: [
        {
          name: 'Nou Pla de Formació',
          link: `/pla-formacio/nou/`
        }
      ]
    }
    setTitle(pageTitle)

    // Llamada a la API para recoger todos los registros de la base de datos:
    const getApiParams = {
      url: `forecasttraining`,
      method: 'GET',
      messageKo: 'Error al recuperar els Plans de Formació'
    }
    setReceivedApiParams(getApiParams)

    //* Avoid bug that when inventory is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/pla-formacio/${item.Id}`)
  }

  return receivedApiParams.data ? (
    receivedApiParams.data[0] !== null ? (
      <div className="flex flex-col w-full p-4 bg-white">
        <ListTable items={receivedApiParams.data} handleClick={handleClick} />
      </div>
    ) : (
      <div className="flex justify-center w-full p-4">
        <div
          className="w-full p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
          role="alert"
        >
          <span className="font-ms-semi">Actualment només es mostraràn els plans de formació corresponents a {(new Date().getFullYear())}.</span> En aquest moment, no heu creat cap pla de formació disponible per a {(new Date().getFullYear())}.
        </div>
      </div>
    )
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default forecastTrainingList
